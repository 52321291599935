import React from 'react';
import {
  Form, Row, Button, Col,
  Accordion,
  Container,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function FiltriRicercaIdentita({
  onChangeFiltriIdentita,
  getListaIdentita,
}) {
  function onEnterPress(e) {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      getListaIdentita(0, 10, 1);
    } else {
      onChangeFiltriIdentita(e);
    }
  }

  return (
    <Accordion className="mb-3">
      <Accordion.Item eventKey="0">
        <Accordion.Header>Filtri di ricerca</Accordion.Header>
        <Accordion.Body>
          <Form className="mt-3">
            <Row className="mb-3">
              <Form.Group as={Col} lg>
                <Form.Label>
                  Nome identità
                </Form.Label>
                <Form.Control
                  placeholder="Inserisci il nome dell'identità"
                  onChange={onChangeFiltriIdentita}
                  onKeyDown={onEnterPress}
                />
              </Form.Group>
              <Container style={{ padding: '1rem' }}>
                <Button
                  variant="primary"
                  className="float-end"
                  onClick={() => getListaIdentita(0, 10, 1)}
                  aria-label="Ricerca le informazioni secondo i filtri inseriti"
                >
                  <FontAwesomeIcon aria-hidden="true" icon={['fas', 'search']} />
                  {' '}
                  CERCA
                </Button>
              </Container>
            </Row>
          </Form>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default FiltriRicercaIdentita;
