import React, { useContext, useEffect, useState } from 'react';
import { Container, Image } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { Context } from '../../../utils/Context';
import SistemaProfessionaleService from '../../../services/sistemaProfessionaleService';
import checkStorage from '../../../utils/checkSessionStorage';
import { getItem, setItem } from '../../../utils/storage';
import TitlePage from '../../../components/GestisciUtenti/TitoloPagina';
import Table from '../../../components/Shared/Table';
import ModaleCentroVerticale from '../../../components/modali/ModaleCentroVerticale';
import ModaleConferma from '../../../components/modali/ModaleConferma';
import FormCompetenza from './FormCompetenza';
import fieldsCompetenze from './fieldsTable';
import FiltriCompetenze from './filtriCompetenze';
import EmptyState from '../../../components/EmptyState';
import { controlla } from '../../../utils/verificaPermessi';
import history from '../../../utils/history';
import logoAI from '../../../images/LogoAi.svg';
import SpinnerBody from '../../../components/Shared/SpinnerBody';

function Competenze() {
  const [context, setContext] = useContext(Context);
  const { tipoCompetenza } = useParams();
  const [competenzeList, setCompetenzeList] = useState([]);
  const [areeCompetenzeList, setAreeCompetenzeList] = useState([]);
  const [competenzaSelected, setCompetenzaSelected] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [modalShow, setModalShow] = useState(false);
  const [modalShowView, setModalShowView] = useState(false);
  const [modalShowModify, setModalShowModify] = useState(false);
  const [modalShowDelete, setModalShowDelete] = useState(false);
  const [errors, setErrors] = useState({});
  const { idAmministrazione } = JSON.parse(getItem('identita'));
  const [stateCompetenza, setStateCompetenza] = useState({
    desc_titolo: '',
    desc_descrizione: '',
    codi_tipo_competenza: '',
    fk_sequ_area_competenze: 0,
    desc_descrittore: '',
    desc_codice: '',
  });

  const [filtri, setFiltri] = useState({
    codi_tipo_competenza: tipoCompetenza,
  });
  const [pagination, setPagination] = useState({
    page: 1,
    sizePerPage: 10,
    totalSize: 0,
  });

  // Validazione per la creazione/modifica di una competenza.
  const validateCompetenza = (state, error) => {
    const temp = { ...error };
    temp.desc_titolo = state.desc_titolo === '';
    temp.desc_descrizione = state.desc_descrizione === '';
    setErrors((prevState) => ({
      ...prevState,
      ...temp,
    }));
    return Object.values(temp).every((x) => x === false);
  };

  // function collegata al servizio per ottenere la lista delle competenze
  async function getCompetenze(offset, limit, page, filters) {
    try {
      const filterSession = filters || filtri;
      const response = await SistemaProfessionaleService
        .getCompetenze(filterSession, offset, limit);
      setCompetenzeList(response.rows);
      setPagination((prevPag) => ({
        ...prevPag,
        page,
        sizePerPage: limit,
        totalSize: response.totalRowCount,
      }));
      setIsLoading(false);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // function collegata al servizio per ottenere la lista delle aree delle competenze
  async function getAreeCompetenze(offset, limit, filters) {
    try {
      const response = await SistemaProfessionaleService
        .getAreeCompetenze(filters, offset, limit);
      setAreeCompetenzeList(response.rows);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // function attivata al cambio pagina della tabella
  const handleTableChange = (type, {
    page, sizePerPage, sortField, sortOrder,
  }) => {
    const newFilters = {
      ...filtri,
      sortField: type === 'sort' ? sortField : 'desc_codice',
      sortOrder: type === 'sort' ? sortOrder : 'asc',
    };
    let currentIndex;
    if (page !== 0) {
      currentIndex = (page - 1) * sizePerPage;
    }
    getCompetenze(
      currentIndex,
      sizePerPage,
      page,
      newFilters,
    );
  };

  // useEffect
  useEffect(() => {
    let offset = 0;
    const storage = checkStorage(filtri, setFiltri, setPagination);
    if (storage.page > 1) {
      handleTableChange(null, { page: storage.page, sizePerPage: pagination.sizePerPage });
      offset = (storage.page - 1) * pagination.sizePerPage;
    }
    getCompetenze(offset, 10, storage.page, storage.filtri);
  }, []);

  // useEffect per settare nello storage la pagina corrente
  useEffect(() => {
    setItem('page', pagination.page);
  }, [pagination.page]);

  // useEffect per caricare i dati presenti nella tabella.
  useEffect(() => {
    setFiltri({
      ...filtri,
      desc_codice: '',
      desc_titolo: '',
      fk_sequ_area_competenze: 0,
      codi_tipo_competenza: tipoCompetenza,
    });
    getCompetenze(0, 10, 1, { codi_tipo_competenza: tipoCompetenza });
    // IMPOSTATO LIMIT 1000 MOMENTANEO
    getAreeCompetenze(0, 1000, { codi_tipo_competenza: tipoCompetenza });
    setStateCompetenza({ ...stateCompetenza, codi_tipo_competenza: tipoCompetenza });
  }, [tipoCompetenza]);

  // function per modificare lo stato della competenza che l'utente sta modificando.
  const onChange = (e, config) => {
    if (config) {
      const newState = { ...stateCompetenza, [config.name]: e?.sequ_k_area_competenze };
      setStateCompetenza(newState);
      validateCompetenza(newState, errors);
    } else {
      const { name, value } = e.target;
      setStateCompetenza({ ...stateCompetenza, [name]: value });
      validateCompetenza({ ...stateCompetenza, [name]: value }, errors);
    }
  };

  // function per modificare lo stato che gestisce i filtri
  function onChangeFiltri(e, config) {
    if (config) {
      if (config.name === 'fk_sequ_area_competenze') {
        setFiltri({
          ...filtri, [config.name]: e?.sequ_k_area_competenze,
        });
      } else {
        setFiltri({
          ...filtri, [config.name]: e?.sequ_k_comparto,
        });
      }
    } else {
      const { value, name } = e.target;
      setFiltri({
        ...filtri, [name]: value,
      });
    }
  }

  // function per effettuare il refresh della pagina
  function refresh() {
    getCompetenze(0, 10, 1, filtri);
  }

  // function collegata al servizio di creazione di una nuova competenza
  async function nuovaCompetenza() {
    if (validateCompetenza(stateCompetenza, errors)) {
      SistemaProfessionaleService.nuovaCompetenza(stateCompetenza)
        .then((res) => {
          setContext(() => ({
            ...context, openConferma: true, testo: res.message,
          }));
          setModalShow(false);
          refresh();
        })
        .catch((err) => {
          setContext(() => ({
            ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
          }));
        });
    }
  }

  // function collegata al servizio di eliminazione di una competenza
  async function eliminaCompetenza(idCompetenza) {
    SistemaProfessionaleService.eliminaCompetenza(idCompetenza)
      .then((res) => {
        setContext(() => ({
          ...context, openConferma: true, testo: res.message,
        }));
        setModalShowDelete(false);
        refresh();
      })
      .catch((err) => {
        setContext(() => ({
          ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
        }));
      });
  }

  // function collegata al servizio di modifica di una competenza
  async function modificaCompetenza(idCompetenza) {
    if (validateCompetenza(stateCompetenza, errors)) {
      SistemaProfessionaleService.modificaCompetenza(
        idCompetenza,
        stateCompetenza,
      )
        .then((res) => {
          setContext(() => ({
            ...context, openConferma: true, testo: res.message,
          }));
          setModalShowModify(false);
          refresh();
        })
        .catch((err) => {
          setContext(() => ({
            ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
          }));
        });
    }
  }

  // function collegata al servizio di get di una particolare competenza
  async function getCompetenzaById(id) {
    try {
      const response = await SistemaProfessionaleService.getCompetenzaById(id);
      setStateCompetenza({
        desc_titolo: response.desc_titolo,
        desc_descrizione: response.desc_descrizione,
        desc_descrittore: response.desc_descrittore ?? '',
        codi_tipo_competenza: response.codi_tipo_competenza,
        fk_sequ_area_competenze: response.fk_sequ_area_competenze,
        desc_codice: response.desc_codice,
      });
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // Funzione attivata al click dell'icona di eliminazione
  const onDelete = (competenza) => {
    setModalShowDelete(true);
    setCompetenzaSelected(competenza);
  };

  // Funzione attivata al click dell'icona di visualizzazione
  const onView = (competenza) => {
    getCompetenzaById(competenza.id);
    setCompetenzaSelected(competenza);
    setModalShowView(true);
  };

  // Funzione attivata al click dell'icona di modifica
  const onModify = (modify, competenza) => {
    getCompetenzaById(competenza.id);
    setCompetenzaSelected(competenza);
    if (modify) {
      setModalShowModify(true);
    } else {
      setModalShowView(false);
      setModalShowModify(true);
    }
  };

  // Funzione per ottenere titolo corretto
  const getTitle = () => {
    if (tipoCompetenza === 'CTP') return 'Competenze Tecnico Professionali';
    if (tipoCompetenza === 'CTS') return 'Competenze Tecnico Specialistiche';
    return 'Competenze Comportamentali';
  };

  return (
    isLoading
      ? (
        <SpinnerBody />
      )
      : (
        <>
          <Container className="p-0">
            <TitlePage
              title={getTitle()}
              showRefresh
              functionRefresh={refresh}
              showAdd={controlla('X24')}
              functionAdd={() => setModalShow(true)}
              showAI={controlla('X51')}
              variantAI="outline-AI"
              functionAI={() => history.push(`/area-riservata/competenze/${tipoCompetenza}/ricerca-ai`)}
              imageAI={<Image src={logoAI} width="22.5" height="22.5" />}
              titleAI="RICERCA con AI"
            />
            <FiltriCompetenze
              filtri={filtri}
              setFiltri={setFiltri}
              onChangeFiltri={onChangeFiltri}
              getCompetenze={getCompetenze}
              optionsAreeCompetenze={areeCompetenzeList}
              key={tipoCompetenza}
            />
            {competenzeList.length > 0 ? (
              <Table
                data={competenzeList}
                fields={fieldsCompetenze(onDelete, onView, onModify, idAmministrazione)}
                pagination={pagination}
                onTableChange={handleTableChange}
                keyField={`competenze_${tipoCompetenza}_list`}
              />
            ) : (
              <EmptyState marginTop="3rem" />
            )}
          </Container>
          <ModaleCentroVerticale
            modalTitle="Crea una nuova Competenza"
            modalBody={(
              <FormCompetenza
                stateCompetenza={stateCompetenza}
                onChange={onChange}
                optionsAreeCompetenze={areeCompetenzeList}
                errors={errors}
              />
        )}
            buttonType="submit"
            show={modalShow}
            onConfirm={() => nuovaCompetenza()}
            labelConfirm="Aggiungi"
            onClose={() => setModalShow(false)}
          />
          <ModaleConferma
            labelBottone="Elimina"
            coloreAzione="primary"
            title="Sei sicuro di procedere?"
            body={`L'operazione eliminerà la competenza ${competenzaSelected?.codice}`}
            show={modalShowDelete}
            handleClose={() => setModalShowDelete(false)}
            handleOk={() => eliminaCompetenza(competenzaSelected.id)}
          />
          <ModaleCentroVerticale
            modalTitle="Competenza"
            modalBody={(
              <FormCompetenza
                stateCompetenza={stateCompetenza}
                optionsAreeCompetenze={areeCompetenzeList}
                disabled
              />
        )}
            buttonType="submit"
            show={modalShowView}
            onConfirm={() => onModify(false, competenzaSelected)}
            labelConfirm="Modifica"
            disabledConfirm={(!controlla('X24') || idAmministrazione !== competenzaSelected?.idAmministrazione)}
            onClose={() => setModalShowView(false)}
          />
          <ModaleCentroVerticale
            modalTitle="Modifica Competenza"
            modalBody={(
              <FormCompetenza
                stateCompetenza={stateCompetenza}
                onChange={onChange}
                optionsAreeCompetenze={areeCompetenzeList}
                errors={errors}
              />
        )}
            buttonType="submit"
            show={modalShowModify}
            onConfirm={() => modificaCompetenza(competenzaSelected.id)}
            labelConfirm="Conferma"
            onClose={() => setModalShowModify(false)}
          />
        </>
      )
  );
}

export default Competenze;
