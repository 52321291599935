import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Container } from 'react-bootstrap';

function EmptyState({
  title,
  subtitle,
  icon,
  marginBottom,
  marginTop,
}) {
  return (
    <Container
      style={{
        flexDirection: 'column',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        height: '100%',
        marginBottom: marginBottom || '1rem',
        marginTop: marginTop || '1rem',
      }}
    >
      <Container>
        {icon ?? <FontAwesomeIcon aria-hidden="true" icon="fa-solid fa-circle-exclamation" size="2x" />}
      </Container>
      <Container>
        <h3>{title ?? 'Nessun risultato trovato'}</h3>
        <h5 className="font-weight-normal lead">
          {subtitle ?? 'Nessun risultato coerente con i criteri di ricerca. Rimuovi i filtri di ricerca o crea un nuovo elemento'}
        </h5>
      </Container>
    </Container>
  );
}

export default EmptyState;
