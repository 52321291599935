import { Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';

function FormDuplicazione({
  stateProfiloProfessionale,
  optionsFamiglieProfessionali,
  onChange,
}) {
  return (
    <Form.Group as={Col} lg controlId="duplica-profilo-professionale">
      <Form.Label>
        <div style={{ fontSize: '1rem' }}>
          Si sta creando un duplicato del Profilo Professionale
          {' '}
          <b>
            {stateProfiloProfessionale.desc_titolo}
          </b>
          . Selezionare la nuova famiglia professionale tra quelle presenti:
        </div>
      </Form.Label>
      <Select
        id="fk_sequ_famiglia_professionale"
        name="fk_sequ_famiglia_professionale"
        options={optionsFamiglieProfessionali}
        placeholder="Scegli Famiglia Professionale"
        getOptionLabel={(option) => `${option.desc_codice} - ${option.desc_titolo}`}
        getOptionValue={(option) => option.sequ_k_famiglia_professionale}
        value={optionsFamiglieProfessionali?.filter(
          (el) => el.sequ_k_famiglia_professionale
            === stateProfiloProfessionale.fk_sequ_famiglia_professionale,
        )?.[0]}
        onChange={(selectedOption, config) => { onChange(selectedOption, config); }}
      />
    </Form.Group>
  );
}

export default FormDuplicazione;
