import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, ProgressBar } from 'react-bootstrap';
import Select from 'react-select';
import getVariantColor from '../../../../utils/getVariantColor';
import { getColor, getIcon } from '../../../../utils/getEsiti';

const fieldsCompetenzeAnalisiEsitiAssessment = (
  competenzeList,
  optionsGrading,
  statoAssessment,
) => {
  // function per settare il valore corretto selezionato nella Select
  const getSelectValue = (row, type) => {
    const gradingSelected = competenzeList.filter(
      (el) => el.fk_sequ_competenza === row.fk_sequ_competenza,
    )[0]?.[type];
    return optionsGrading?.filter(
      (el) => el.codi_valore === gradingSelected,
    )?.[0];
  };

  // Function per settare le opzioni di grading corrette a seconda della competenza
  const optionsGradingByType = (type) => {
    const newList = optionsGrading.filter((grading) => grading.codi_tipo_competenza === type);
    return newList;
  };

  if (statoAssessment === 1) {
    return (
      [
        {
          dataField: 'comp_desc_codice',
          text: 'Codice',
          // sort: true,
          classes: 'column-data',
          headerStyle: () => ({ width: '10%' }),
        },
        {
          dataField: 'comp_desc_descrizione',
          text: 'Competenza',
          // sort: true,
          classes: 'column-data',
          headerStyle: () => ({ width: '75%' }),
        },
        {
          dataField: 'codi_grading_atteso',
          text: 'Grading Atteso',
          // sort: true,
          classes: 'column-data',
          headerStyle: () => ({ width: '15%' }),
          formatter: (e, row) => (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Select
                isDisabled
                styles={{ indicatorsContainer: (styles) => ({ ...styles, display: 'none' }) }}
                className="w-100"
                id="codi_grading_atteso"
                name="codi_grading_atteso"
                options={optionsGradingByType(row.comp_tipo_competenza)}
                getOptionLabel={(option) => (
                  <Container className="p-0">
                    {option.desc_titolo}
                    <ProgressBar
                      variant={
                        getVariantColor((
                          option.codi_valore / optionsGradingByType(row.comp_tipo_competenza).length
                        ) * 100)
                      }
                      label={option.codi_valore}
                      now={
                        (option.codi_valore / optionsGradingByType(row.comp_tipo_competenza).length)
                        * 100
                      }
                    />
                  </Container>
                )}
                getOptionValue={(option) => option.codi_valore}
                value={getSelectValue(row, 'codi_grading_atteso')}
              />
            </div>
          ),
        },
      ]
    );
  } if (statoAssessment === 2) {
    return (
      [
        {
          dataField: 'comp_desc_codice',
          text: 'Codice',
          // sort: true,
          classes: 'column-data',
          headerStyle: () => ({ width: '10%' }),
        },
        {
          dataField: 'comp_desc_descrizione',
          text: 'Competenza',
          // sort: true,
          classes: 'column-data',
          headerStyle: () => ({ width: '60%' }),
        },
        {
          dataField: 'codi_grading_atteso',
          text: 'Grading Atteso',
          // sort: true,
          classes: 'column-data',
          headerStyle: () => ({ width: '15%' }),
          formatter: (e, row) => (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Select
                isDisabled
                styles={{ indicatorsContainer: (styles) => ({ ...styles, display: 'none' }) }}
                className="w-100"
                id="codi_grading_atteso"
                name="codi_grading_atteso"
                options={optionsGradingByType(row.comp_tipo_competenza)}
                getOptionLabel={(option) => (
                  <Container className="p-0">
                    {option.desc_titolo}
                    <ProgressBar
                      variant={
                          getVariantColor((
                            option.codi_valore
                            / optionsGradingByType(row.comp_tipo_competenza).length
                          ) * 100)
                      }
                      label={option.codi_valore}
                      now={
                        (option.codi_valore / optionsGradingByType(row.comp_tipo_competenza).length)
                        * 100
                      }
                    />
                  </Container>
                )}
                getOptionValue={(option) => option.codi_valore}
                value={getSelectValue(row, 'codi_grading_atteso')}
              />
            </div>
          ),
        },
        {
          dataField: 'codi_grading_autovalutazione',
          text: 'Auto Valutazione',
          // sort: true,
          classes: 'column-data',
          headerStyle: () => ({ width: '15%' }),
          formatter: (e, row) => (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <FontAwesomeIcon
                aria-hidden="true"
                icon={['fas', getIcon(row.codi_grading_atteso, row.codi_grading_autovalutazione)]}
                size="lg"
                id={`Risultato_Autovalutazione_${row.sequ_k_competenza_assesment}`}
                style={{
                  color: getColor(row.codi_grading_atteso, row.codi_grading_autovalutazione),
                  marginRight: 5,
                }}
              />
              <Select
                isDisabled
                styles={{ indicatorsContainer: (styles) => ({ ...styles, display: 'none' }) }}
                className="w-100"
                id="codi_grading_autovalutazione"
                name="codi_grading_autovalutazione"
                options={optionsGradingByType(row.comp_tipo_competenza)}
                getOptionLabel={(option) => (
                  <Container className="p-0">
                    {option.desc_titolo}
                    <ProgressBar
                      variant={
                          getVariantColor((
                            option.codi_valore
                            / optionsGradingByType(row.comp_tipo_competenza).length
                          ) * 100)
                        }
                      label={option.codi_valore}
                      now={(
                        option.codi_valore / optionsGradingByType(row.comp_tipo_competenza).length
                      ) * 100}
                    />
                  </Container>
                )}
                getOptionValue={(option) => option.codi_valore}
                value={getSelectValue(row, 'codi_grading_autovalutazione')}
              />
            </div>
          ),
        },
      ]
    );
  }
  return (
    [
      {
        dataField: 'comp_desc_codice',
        text: 'Codice',
        // sort: true,
        classes: 'column-data',
        headerStyle: () => ({ width: '10%' }),
      },
      {
        dataField: 'comp_desc_descrizione',
        text: 'Competenza',
        // sort: true,
        classes: 'column-data',
        headerStyle: () => ({ width: '45%' }),
      },
      {
        dataField: 'codi_grading_atteso',
        text: 'Grading Atteso',
        // sort: true,
        classes: 'column-data',
        headerStyle: () => ({ width: '15%' }),
        formatter: (e, row) => (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Select
              isDisabled
              styles={{ indicatorsContainer: (styles) => ({ ...styles, display: 'none' }) }}
              className="w-100"
              id="codi_grading_atteso"
              name="codi_grading_atteso"
              options={optionsGradingByType(row.comp_tipo_competenza)}
              getOptionLabel={(option) => (
                <Container className="p-0">
                  {option.desc_titolo}
                  <ProgressBar
                    variant={
                        getVariantColor((
                          option.codi_valore / optionsGradingByType(row.comp_tipo_competenza).length
                        ) * 100)
                      }
                    label={option.codi_valore}
                    now={
                        (option.codi_valore / optionsGradingByType(row.comp_tipo_competenza).length)
                        * 100
                      }
                  />
                </Container>
              )}
              getOptionValue={(option) => option.codi_valore}
              value={getSelectValue(row, 'codi_grading_atteso')}
            />
          </div>
        ),
      },
      {
        dataField: 'codi_grading_autovalutazione',
        text: 'Auto Valutazione',
        // sort: true,
        classes: 'column-data',
        headerStyle: () => ({ width: '15%' }),
        formatter: (e, row) => (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <FontAwesomeIcon
              aria-hidden="true"
              icon={['fas', getIcon(row.codi_grading_atteso, row.codi_grading_autovalutazione)]}
              size="lg"
              id={`Risultato_Autovalutazione_${row.sequ_k_competenza_assesment}`}
              style={{
                color: getColor(row.codi_grading_atteso, row.codi_grading_autovalutazione),
                marginRight: 5,
              }}
            />
            <Select
              isDisabled
              styles={{ indicatorsContainer: (styles) => ({ ...styles, display: 'none' }) }}
              className="w-100"
              id="codi_grading_autovalutazione"
              name="codi_grading_autovalutazione"
              options={optionsGradingByType(row.comp_tipo_competenza)}
              getOptionLabel={(option) => (
                <Container className="p-0">
                  {option.desc_titolo}
                  <ProgressBar
                    variant={
                        getVariantColor((
                          option.codi_valore / optionsGradingByType(row.comp_tipo_competenza).length
                        ) * 100)
                      }
                    label={option.codi_valore}
                    now={
                        (option.codi_valore / optionsGradingByType(row.comp_tipo_competenza).length)
                        * 100
                      }
                  />
                </Container>
              )}
              getOptionValue={(option) => option.codi_valore}
              value={getSelectValue(row, 'codi_grading_autovalutazione')}
            />
          </div>
        ),
      },
      {
        dataField: 'codi_grading_assegnato',
        text: 'Etero Valutazione',
        // sort: true,
        classes: 'column-data',
        headerStyle: () => ({ width: '15%' }),
        formatter: (e, row) => (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <FontAwesomeIcon
              aria-hidden="true"
              icon={['fas', getIcon(row.codi_grading_atteso, row.codi_grading_assegnato)]}
              size="lg"
              id={`Risultato_Eterovalutazione_${row.sequ_k_competenza_assesment}`}
              style={{
                color: getColor(row.codi_grading_atteso, row.codi_grading_assegnato),
                marginRight: 5,
              }}
            />
            <Select
              isDisabled
              styles={{ indicatorsContainer: (styles) => ({ ...styles, display: 'none' }) }}
              className="w-100"
              id="codi_grading_assegnato"
              name="codi_grading_assegnato"
              options={optionsGradingByType(row.comp_tipo_competenza)}
              getOptionLabel={(option) => (
                <Container className="p-0">
                  {option.desc_titolo}
                  <ProgressBar
                    variant={
                        getVariantColor((
                          option.codi_valore / optionsGradingByType(row.comp_tipo_competenza).length
                        ) * 100)
                      }
                    label={option.codi_valore}
                    now={
                        (option.codi_valore / optionsGradingByType(row.comp_tipo_competenza).length)
                        * 100
                      }
                  />
                </Container>
              )}
              getOptionValue={(option) => option.codi_valore}
              value={getSelectValue(row, 'codi_grading_assegnato')}
            />
          </div>
        ),
      },
    ]
  );
};

export default fieldsCompetenzeAnalisiEsitiAssessment;
