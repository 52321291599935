import React from 'react';
import {
  Button, Card, Col, Form, Row,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function NewFormSection({
  showNewFormSezioneHandle,
  onChangeSezione,
  addOrEdit,
  newSezione,
  editedSezione,
  addSezione,
  setFunzioneModaleConferma,
  setTestoModaleConferma,
  handleStateModaleConferma,
  modificaSezione,
}) {
  return (
    <Card className="mb-3">
      <Card.Header className="d-flex justify-content-between align-items-center">
        <Card.Title className="mb-0">Nuova Sezione</Card.Title>
        <Button
          variant="danger"
          onClick={showNewFormSezioneHandle}
          title="Annulla"
        >
          <FontAwesomeIcon aria-hidden="true" icon="times-circle" />
        </Button>
      </Card.Header>
      <Card.Body className="py-1">
        <Card.Text>
          <Form>
            <Row>
              <Form.Group as={Col} lg controlId="titolo">
                <Form.Label>Titolo Sezione</Form.Label>
                <Form.Control
                  placeholder="Inserisci il titolo della sezione"
                  onChange={onChangeSezione}
                  value={addOrEdit ? newSezione.titolo : editedSezione.titolo}
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} lg controlId="descrizione">
                <Form.Label>Descrizione Sezione</Form.Label>
                <Form.Control
                  placeholder="Inserisci la descrizione della sezione"
                  onChange={onChangeSezione}
                  value={addOrEdit ? newSezione.descrizione : editedSezione.descrizione}
                />
              </Form.Group>
            </Row>
          </Form>
          <Row className="d-flex justify-content-end my-2">
            <Col xs="auto">
              {addOrEdit ? (
                <Button
                  disabled={!newSezione.titolo || !newSezione.descrizione}
                  onClick={() => {
                    setFunzioneModaleConferma(() => addSezione);
                    setTestoModaleConferma('creerà');
                    handleStateModaleConferma();
                  }}
                >
                  <FontAwesomeIcon aria-hidden="true" icon="plus" className="me-2" />
                  AGGIUNGI
                </Button>
              ) : (
                <Button
                  onClick={modificaSezione}
                  disabled={!editedSezione.titolo || !editedSezione.descrizione}
                >
                  <FontAwesomeIcon aria-hidden="true" icon="plus" className="me-2" />
                  MODIFICA
                </Button>
              )}
            </Col>
          </Row>
        </Card.Text>
      </Card.Body>
    </Card>
  );
}

export default NewFormSection;
