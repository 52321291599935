/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Col } from 'react-bootstrap';
import compartiService from '../../services/compartiService';

function ModaleAccettazioneRichiesta({
  richiestaIdentita,
  onChange,
  evasione,
  handleStateModale,
  show,
}) {
  const [comparti, setComparti] = useState([]);

  async function getComparti() {
    setComparti(await compartiService.getAllComparti());
  }

  useEffect(() => {
    getComparti();
  }, []);

  return (
    <Modal
      role="dialog"
      size="lg"
      centered
      show={show}
      onHide={handleStateModale}
    >
      <Modal.Header closeButton closeLabel="Chiudi">
        <Modal.Title id="contained-modal-title-vcenter">
          Accettazione richiesta
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group as={Col} lg controlId="fk_sequ_comparto">
          <Form.Label>Comparto</Form.Label>
          <Form.Select
            value={richiestaIdentita.fk_sequ_comparto}
            onChange={onChange}
          >
            <option> </option>
            {comparti.map((comparto) => (
              <option
                key={comparto.sequ_k_comparto}
                value={comparto.sequ_k_comparto}
              >
                {comparto.desc_comparto}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="grey" onClick={handleStateModale}>Chiudi</Button>
        <Button variant="primary" onClick={evasione} disabled={!richiestaIdentita.fk_sequ_comparto}>Accetta</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModaleAccettazioneRichiesta;
