import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Col, Form, Row, Button, Accordion,
  Container,
} from 'react-bootstrap';
import { setItem } from '../../../utils/storage';

function FiltriAreaContrattuale({
  filtri,
  setFiltri,
  onChangeFiltri,
  getAreaContrattuale,
}) {
  const initialState = {
    ...filtri,
    desc_codice: '',
    desc_titolo: '',
  };

  return (
    <Accordion className="mb-3">
      <Accordion.Item eventKey="0">
        <Accordion.Header>Filtri di ricerca</Accordion.Header>
        <Accordion.Body>
          <Form className="mt-3">
            <Row className="mb-3">
              <Form.Group as={Col} lg>
                <Form.Label>
                  Codice
                </Form.Label>
                <Form.Control
                  name="desc_codice"
                  value={filtri.desc_codice}
                  placeholder="Filtra per codice"
                  onChange={onChangeFiltri}
                  maxLength={100}
                  required
                />
              </Form.Group>
              <Form.Group as={Col} lg>
                <Form.Label>
                  Titolo
                </Form.Label>
                <Form.Control
                  name="desc_titolo"
                  value={filtri.desc_titolo}
                  placeholder="Filtra per titolo"
                  onChange={onChangeFiltri}
                  maxLength={200}
                  required
                />
              </Form.Group>
            </Row>
          </Form>
          <Container style={{ padding: 0, textAlign: 'end' }}>
            <Button
              variant="outline-dark"
              onClick={() => {
                setFiltri(initialState);
              }}
            >
              <FontAwesomeIcon aria-hidden="true" icon={['fas', 'eraser']} style={{ marginRight: '2px' }} />
              PULISCI CAMPI
            </Button>
            {' '}
            <Button
              variant="outline-primary"
              onClick={() => {
                setItem('filtri', JSON.stringify(filtri));
                getAreaContrattuale(0, 10, 1);
              }}
            >
              <FontAwesomeIcon aria-hidden="true" icon={['fas', 'search']} style={{ marginRight: '2px' }} />
              CERCA
            </Button>
          </Container>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default FiltriAreaContrattuale;
