import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown } from 'react-bootstrap';

function ExportDropdownButton({
  dropdownTitle,
  items,
}) {
  async function handleDownload(item) {
    const link = document.createElement('a');
    link.href = item.href;
    link.download = item.fileName;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return (
    <div>
      <Dropdown>
        <Dropdown.Toggle variant="success" style={{ color: 'white' }}>
          <span>
            <FontAwesomeIcon aria-hidden="true" icon="file-download" />
            {' '}
            {dropdownTitle}
          </span>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {items?.map((item) => (
            <Dropdown.Item onClick={() => handleDownload(item)}>{item.title}</Dropdown.Item>
          ))}

        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}

export default ExportDropdownButton;
