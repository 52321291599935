import React, { useContext, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { format } from 'date-fns';
import TitlePage from '../../../components/GestisciUtenti/TitoloPagina';
import { Context } from '../../../utils/Context';
import EmptyState from '../../../components/EmptyState';
import fieldsFabbisogni from './fieldsTable';
import Table from '../../../components/Shared/Table';
import ModaleCentroVerticale from '../../../components/modali/ModaleCentroVerticale';
import FormNewFabbisogno from './FormNewFabbisogno';
import history from '../../../utils/history';
import { getItem } from '../../../utils/storage';
import AssessmentService from '../../../services/assessmentService';
import nomiProcessi from './aggiungi-piano/data/nomiProcessi';
import passiAggiungi from './aggiungi-piano/passiAggiungi';
import utenteService from '../../../services/utentiService';
import SpinnerBody from '../../../components/Shared/SpinnerBody';

function DemoBpm() {
  const [context, setContext] = useContext(Context);
  const { idIdentita } = JSON.parse(getItem('identita'));
  const { idAmministrazione } = JSON.parse(getItem('identita'));
  const [formError, setFormError] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [fabbisogni, setFabbisogni] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    sizePerPage: 10,
    totalSize: 0,
  });
  const [utente, setUtente] = useState({});
  const [stateFabbisogno, setStateFabbisogno] = useState({
    idAmministrazione,
    nome_processo: '',
    anno_riferimento: '',
    stato_processo: `${nomiProcessi[0]} - ${passiAggiungi[0]}`, // Inizializzo il processo a 'In Corso'
    fase: 0,
    aree_contrattuali: [],
    strutture_organizzative: [],
  });
  const currentYear = format(new Date(), 'yyyy');

  // function collegata al servizio per ottenere la lista dei fabbisogni
  async function getProcessi(limit, offset, page) {
    try {
      let newResponse;
      const response = await AssessmentService
        .getProcessi(limit, offset);
      if (idIdentita === 10) {
        const data = response.data.filter(
          (piano) => piano.strutture_organizzative.find(
            (so) => so.struttura_organizzativa === utente.utenteSO,
          ),
        );
        newResponse = {
          data,
          totalRowCount: data.length,
        };
        setFabbisogni(newResponse.data);
      } else {
        newResponse = response;
        setFabbisogni(newResponse.data);
      }
      setPagination((prevPag) => ({
        ...prevPag,
        page,
        sizePerPage: limit,
        totalSize: newResponse.totalRowCount,
      }));
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // Function collegata al servizio per ottenere la struttura organizzativa dell'utente
  async function getUtenteByIdUtente() {
    try {
      const response = await utenteService.getUtenteByIdUtente();
      const newUtente = {
        utenteSO: response.desc_struttura_organizzativa_ii_liv
          ?? response.desc_struttura_organizzativa_i_liv,
        id_pm: response.sequ_k_utente_trusted,
      };
      setUtente(newUtente);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // function per validare i campi inseriti nel form della modale
  const validateForm = () => {
    const newErrorObj = {};
    if (stateFabbisogno.anno_riferimento < currentYear || stateFabbisogno.anno_riferimento > 9999) {
      newErrorObj.date = true;
    }

    if (stateFabbisogno.nome_processo === '') {
      newErrorObj.nome_processo = true;
    }

    setFormError(newErrorObj);
    return newErrorObj;
  };

  // function collegata al servizio per aggiungere un nuovo piano di fabbisogno
  async function nuovoProcesso() {
    try {
      const errorObj = validateForm();
      if (Object.keys(errorObj).length !== 0) {
        return;
      }
      AssessmentService.nuovoProcesso(stateFabbisogno)
        .then((res) => {
          setContext(() => ({
            ...context, openConferma: true, testo: res.message,
          }));
          const { _id } = res;
          const step = 0;
          history.push(
            '/area-riservata/demo-bpm/aggiungi-piano',
            {
              _id, utente, idIdentita, step,
            },
          );
        })
        .catch((err) => {
          setContext(() => ({
            ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
          }));
        });
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // function collegata al servizio bottone "lente di ingrandimento" nelle azioni
  const onView = (_id) => {
    history.push('/area-riservata/demo-bpm/visualizza-piano', { _id, utente, idIdentita });
  };

  // function collegata al servizio bottone "modifica" nelle azioni
  const onModify = (_id) => {
    let step;
    if (idIdentita === 10) {
      step = 2;
    } else {
      step = 3;
    }
    history.push(
      '/area-riservata/demo-bpm/aggiungi-piano',
      {
        idIdentita, _id, utente, step,
      },
    );
  };

  // function collegata al servizio bottone "modifica" nelle azioni
  const onContinue = (_id, fase) => {
    const step = fase;
    history.push(
      '/area-riservata/demo-bpm/aggiungi-piano',
      {
        idIdentita, _id, utente, step,
      },
    );
  };

  // function per cambiare lo stato collegato ai campi del form della modale
  const onChange = (e) => {
    const { name, value } = e.target;
    if (name === 'anno_riferimento') {
      setStateFabbisogno({ ...stateFabbisogno, [name]: parseInt(value, 10) });
      return;
    }
    setStateFabbisogno({ ...stateFabbisogno, [name]: value });
  };

  // function attivata al cambio pagina della tabella
  const handleTableChange = (type, { page, sizePerPage }) => {
    let currentIndex;
    if (page !== 0) {
      currentIndex = (page - 1) * sizePerPage;
    }
    getProcessi(currentIndex, sizePerPage, page, {});
  };

  // function per effettuare il refresh della pagina
  function refresh() {
    getProcessi(10, 0, 1);
  }

  // hook per prelevare la lista dei fabbisogni
  useEffect(() => {
    getUtenteByIdUtente();
    // getProcessi(10, 0, 1);
  }, []);

  useEffect(() => {
    getProcessi(10, 0, 1);
    if (idIdentita === 3) {
      setStateFabbisogno({
        ...stateFabbisogno,
        id_pm: utente.id_pm,
      });
    }
    setIsLoading(false);
  }, [utente]);

  return (
    isLoading
      ? (
        <SpinnerBody />
      )
      : (
        <Container className="p-0">
          <TitlePage
            title={idIdentita === 10 ? 'Richieste di Fabbisogno di Personale' : 'Piani di Fabbisogno di Personale'}
            showAdd={idIdentita === 3}
            functionAdd={() => setModalShow(true)}
            showRefresh
            functionRefresh={refresh}
          />
          {fabbisogni?.length > 0 ? (
            <Table
              data={fabbisogni}
              fields={fieldsFabbisogni(
                onView,
                onModify,
                idIdentita,
                utente.utenteSO,
                onContinue,
              )}
              pagination={pagination}
              onTableChange={handleTableChange}
              keyField="_id"
            />
          ) : (
            <EmptyState
              marginTop="3rem"
              title="Nessun Piano di Fabbisogno presente"
              subtitle="Procedere con l'aggiunta di un nuovo piano di fabbisogno"
            />
          )}
          <ModaleCentroVerticale
            modalTitle="Aggiungi un nuovo Piano"
            modalBody={(
              <FormNewFabbisogno
                onChange={onChange}
                stateFabbisogno={stateFabbisogno}
                formError={formError}
              />
          )}
            buttonType="submit"
            show={modalShow}
            onConfirm={() => nuovoProcesso()}
            labelConfirm="Aggiungi"
            onClose={() => setModalShow(false)}
          />
        </Container>
      )
  );
}

export default DemoBpm;
