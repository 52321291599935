import { Form, InputGroup } from 'react-bootstrap';
import formatCentinaia from '../../../../../utils/formatCentinaia';

const fieldsDefinizioneCosti = (
  areeContrattuali,
  onChange,
  disabled,
) => (
  [
    {
      dataField: 'nome_area',
      text: 'Area CCNL',
      classes: 'column-data',
      headerStyle: () => ({ width: '50%' }),
    },
    {
      dataField: 'costo_area',
      text: 'Costo Annuale',
      classes: 'column-data',
      headerStyle: () => ({ width: '50%' }),
      formatter: (e, row) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <InputGroup className="mb-3">
            <InputGroup.Text>€</InputGroup.Text>
            <Form.Control
              className="border-custom"
              type="text"
              maxLength={15}
              min="0"
              placeholder="Inserisci costo in Euro"
              aria-label="costo_annuale_area_contrattuale"
              name="costo_area"
              defaultValue={formatCentinaia(areeContrattuali?.find(
                (el) => el.nome_area === row.nome_area,
              )?.costo_area, true)}
              onBlur={(event) => onChange(event, row)}
              disabled={disabled}
            />
            <InputGroup.Text>,00</InputGroup.Text>
          </InputGroup>
        </div>
      ),
    },
  ]
);

export default fieldsDefinizioneCosti;
