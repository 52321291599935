import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import themeColors from '../../../utils/contants/theme-colors';
import { formatoData } from '../../../utils/formatData';

const fieldsCicliAssessment = (onDelete, onView, onModify, onAvvia, onChiudi) => (
  [
    {
      dataField: 'fk_codi_stato_d_stato_ciclo_assesment.desc_stato',
      text: 'Stato',
      // sort: true,
      classes: 'column-data',
      headerStyle: () => ({ width: '15%' }),
    },
    {
      dataField: 'desc_titolo',
      text: 'Titolo',
      // sort: true,
      classes: 'column-data',
      headerStyle: () => ({ width: '30%' }),
    },
    {
      dataField: 'data_avvio',
      text: 'Data Avvio',
      // sort: true,
      classes: 'column-data',
      headerStyle: () => ({ width: '15%' }),
      formatter: (e, row) => formatoData(e),
    },
    {
      dataField: 'data_chiusura',
      text: 'Data Chiusura',
      // sort: true,
      classes: 'column-data',
      headerStyle: () => ({ width: '15%' }),
      formatter: (e, row) => formatoData(e),
    },
    {
      dataField: 'df1',
      isDummyField: true,
      text: 'Azioni',
      headerStyle: () => ({ width: '25%' }),
      formatter: (e, row) => (
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <FontAwesomeIcon
            aria-hidden="true"
            icon={['fas', 'search']}
            size="lg"
            title="Visualizza"
            id={`Visualizza${row.sequ_k_ciclo_assesment}`}
            style={{ color: themeColors.primary }}
            onClick={() => onView(row)}
          />
          {row.fk_codi_stato === 1 && (
            <>
              <FontAwesomeIcon
                aria-hidden="true"
                icon={['fas', 'edit']}
                size="lg"
                title="Modifica"
                id={`Modifica${row.sequ_k_ciclo_assesment}`}
                style={{ color: themeColors.primary }}
                onClick={() => onModify(true, row)}
              />
              <FontAwesomeIcon
                aria-hidden="true"
                icon={['fas', 'play']}
                size="lg"
                title="Avvia"
                id={`Avvia${row.sequ_k_ciclo_assesment}`}
                style={{ color: themeColors.success }}
                onClick={() => onAvvia(row)}
              />
              <FontAwesomeIcon
                aria-hidden="true"
                icon={['fas', 'trash']}
                size="lg"
                title="Elimina"
                id={`Elimina${row.sequ_k_ciclo_assesment}`}
                style={{ color: themeColors.danger }}
                onClick={() => onDelete(row)}
              />
            </>
          )}
          {row.fk_codi_stato === 2 && (
          <FontAwesomeIcon
            aria-hidden="true"
            icon={['fas', 'stop']}
            size="lg"
            title="Chiudi"
            id={`Chiudi${row.sequ_k_ciclo_assesment}`}
            style={{ color: themeColors.danger }}
            onClick={() => onChiudi(row)}
          />
          )}
        </div>
      ),
    },
  ]
);

export default fieldsCicliAssessment;
