import { addTodaysDate } from '../../../../utils/formatData';

const demoCicloAssessment = {
  data_avvio: addTodaysDate(0),
  data_chiusura: addTodaysDate(7),
  desc_titolo: 'Assessment Demo',
  fk_codi_stato_d_stato_ciclo_assesment: {
    desc_stato: 'In corso',
  },
};

export default demoCicloAssessment;
