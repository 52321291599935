/* eslint-disable react/no-unknown-property */
import {
  Button,
  Card,
  Col, Container, Form, Image,
  Row,
} from 'react-bootstrap';
import { useContext, useEffect, useState } from 'react';
import logoAI from '../../../../images/LogoAi.svg';
import TitlePage from '../../../../components/GestisciUtenti/TitoloPagina';
import SistemaProfessionaleService from '../../../../services/sistemaProfessionaleService';
import { Context } from '../../../../utils/Context';
import Table from '../../../../components/Shared/Table';
import EmptyState from '../../../../components/EmptyState';
import history from '../../../../utils/history';
import { getItem } from '../../../../utils/storage';
import fieldsProfiliProfessionaliAI from './fieldsProfiloProfessionaleAI';
import ModaleCentroVerticale from '../../../../components/modali/ModaleCentroVerticale';
import FormProfiloProfessionale from '../FormProfiloProfessionale';
import SpinnerBody from '../../../../components/Shared/SpinnerBody';
import FiltriAIProfiliProfessionali from './filtriAIProfiliProfessionali';
import themeColors from '../../../../utils/contants/theme-colors';
import { controlla } from '../../../../utils/verificaPermessi';

function RicercaAIProfiloProfessionale() {
  const [context, setContext] = useContext(Context);
  const { sequ_k_comparto, desc_comparto } = getItem('compartoSelected') ? JSON.parse(getItem('compartoSelected')) : 0;
  const { idAmministrazione } = JSON.parse(getItem('identita'));
  const [isLoading, setIsLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [isCerca, setIsCerca] = useState(false);
  const [filtri, setFiltri] = useState({
    query: '',
    nome_comparto: desc_comparto,
    competences: [],
    area_contrattuale: [],
    dimensione_professionale: [],
    famiglia_professionale: [],
  });
  const [profiliProfessionaliList, setProfiliProfessionaliList] = useState([]);
  const [stateProfiloProfessionale, setStateProfiloProfessionale] = useState({});
  const [gradingList, setGradingList] = useState([]);
  const [ambitiRuoloList, setAmbitiRuoloList] = useState([]);
  const [areeContrattualiList, setAreeContrattualiList] = useState([]);
  const [dimensioniProfessionaliList, setDimensioniProfessionaliList] = useState([]);
  const [famiglieProfessionaliList, setFamiglieProfessionaliList] = useState([]);
  const [competenzeList, setCompetenzeList] = useState([]);

  // Function collegata al servizio per ottenere la lista delle competenze
  async function getProfiliProfessionaliAI(filters) {
    try {
      const response = await SistemaProfessionaleService
        .getProfiliProfessionaliAI(filters);
      setProfiliProfessionaliList(response.hits);
      // setProfiliProfessionaliList([
      //   {
      //     title: 'Project Manager - Senior',
      //     descriptor: 'Controlla tutto.',
      //     profile_id: 'ELL_PP0001',
      //     famiglia_professionale: 'ELL_FP004 - Servizi di funzionamento',
      //     dimensione_professionale: 'ELL_DP003 - Controllo e valutazione',
      //     area_contrattuale: 'ELL_CCNL001 - FUNZIONARI ED E.Q.',
      //     similarity_score: 84.73,
      //     codice_riva: 1,
      //     comparto: 'Enti Locali',
      //     codice_amministrazione: null,
      //   },
      //   {
      //     title: 'IT Security',
      //     descriptor: 'Controlla tutto.',
      //     profile_id: 'ELL_PP0002',
      //     famiglia_professionale: 'ELL_FP003 - Risorse tecnologiche e transizione digitale',
      //     dimensione_professionale: 'ELL_DP003 - Controllo e valutazione',
      //     area_contrattuale: 'ELL_CCNL004 - OPERATORE',
      //     similarity_score: 70.50,
      //     codice_riva: 2,
      //     comparto: 'Enti Locali',
      //     codice_amministrazione: 'arpam',
      //   },
      // ]);
      setIsCerca(true);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // function collegata al servizio per ottenere la lista dei grading
  async function getGrading(offset, limit, filters) {
    try {
      const response = await SistemaProfessionaleService
        .getGrading(filters, offset, limit);
      setGradingList(response.rows);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // Function collegata al servizio per ottenere la lista degli ambiti di ruolo
  async function getAmbitiRuolo(offset, limit, filters) {
    try {
      const response = await SistemaProfessionaleService
        .getAmbitiRuolo(filters, offset, limit);
      setAmbitiRuoloList(response.rows);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // Function per rimuovere le competenze duplicate.
  function removeDuplicatesComps(list) {
    const compsMap = {};
    list.forEach((el) => {
      if (
        !compsMap[el.fk_sequ_competenza] || compsMap[el.fk_sequ_competenza].grading < el.grading
      ) {
        compsMap[el.fk_sequ_competenza] = el;
      }
    });
    return Object.values(compsMap);
  }

  // Function per avere la lista degli ambiti relativi alla famiglia professionale selezionata
  const getAmbitiRuoloList = (list, idFamigliaProfessionale) => {
    if (list.length > 0) {
      return list.filter((el) => el.fk_sequ_famiglia_professionale === idFamigliaProfessionale);
    } return [];
  };

  // Function collegata al servizio di get di un particolare profilo professionale
  async function getProfiloProfessionaleById(id) {
    try {
      const response = await SistemaProfessionaleService.getProfiloProfessionaleById(id);
      const listCompetenzeMap = response.d_rel_profilo_prof_comps.map(
        (el) => ({
          ...el.fk_sequ_competenza_d_competenza,
          fk_sequ_competenza: el.fk_sequ_competenza,
          grading: el.codi_grading,
          grading_base: el.codi_grading_base,
          provenienza: el.provenienza,
        }),
      );
      setStateProfiloProfessionale({
        ...response,
        fk_sequ_competenze_totali: listCompetenzeMap,
        fk_sequ_competenze_iniziali: listCompetenzeMap,
        fk_sequ_competenze: removeDuplicatesComps(listCompetenzeMap),
        fk_sequ_ambiti_ruoli: response.d_rel_profilo_prof_ambitos.map(
          (el) => el.fk_sequ_ambito_ruolo,
        ),
      });
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // Function collegata al servizio per ottenere la lista delle aree contrattuali
  async function getAreeContrattuali(offset, limit, filters) {
    try {
      const response = await SistemaProfessionaleService
        .getAreaContrattuale(filters, offset, limit);
      const areeMapped = response.rows.map(
        (el) => ({
          area_contrattuale: el.desc_titolo,
        }),
      );
      setAreeContrattualiList(areeMapped);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // Function collegata al servizio per ottenere la lista delle dimensioni professionali
  async function getDimensioniProfessionali(offset, limit, filters) {
    try {
      const response = await SistemaProfessionaleService
        .getDimensioniProfessionali(filters, offset, limit);
      const dimensioniMapped = response.rows.map(
        (el) => ({
          dimensione_professionale: el.desc_titolo,
        }),
      );
      setDimensioniProfessionaliList(dimensioniMapped);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // Function collegata al servizio per ottenere la lista delle famiglie professionali
  async function getFamiglieProfessionali(offset, limit, page, filters) {
    try {
      const filterSession = filters || filtri;
      const response = await SistemaProfessionaleService
        .getFamiglieProfessionali(filterSession, offset, limit);
      const famiglieMapped = response.rows.map(
        (el) => ({
          famiglia_professionale: el.desc_titolo,
        }),
      );
      setFamiglieProfessionaliList(famiglieMapped);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // Function collegata al servizio per ottenere la lista delle competenze
  async function getCompetenze(offset, limit, page, filters) {
    try {
      const filterSession = filters || filtri;
      const response = await SistemaProfessionaleService
        .getCompetenze(filterSession, offset, limit);
      const competenzeMapped = response.rows.map(
        (el) => ({
          competences: `${el.desc_codice} - ${el.desc_titolo}`,
        }),
      );
      setCompetenzeList(competenzeMapped);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // Function per modificare lo stato della ricerca
  const onChange = (e, config) => {
    if (config) {
      if (config.name === 'competences') {
        if (config.action === 'select-option') {
          setFiltri({ ...filtri, [config.name]: e?.map((el) => el?.[config.name].split(' - ')[0]) });
        }
        if (config.action === 'remove-value') {
          const valueUpdated = filtri[config.name].filter(
            (el) => el !== config.removedValue[config.name].split(' - ')[0],
          );
          setFiltri({ ...filtri, [config.name]: valueUpdated });
        }
      } else {
        if (config.action === 'select-option') {
          setFiltri({ ...filtri, [config.name]: e?.map((el) => el?.[config.name]) });
        }
        if (config.action === 'remove-value') {
          const valueUpdated = filtri[config.name].filter(
            (el) => el !== config.removedValue[config.name],
          );
          setFiltri({ ...filtri, [config.name]: valueUpdated });
        }
      }
      if (config.action === 'clear') {
        setFiltri({ ...filtri, [config.name]: [] });
      }
    } else {
      const { name, value } = e.target;
      setFiltri({ ...filtri, [name]: value });
    }
  };

  // Function per la ricerca dei dati
  const onCercaProfili = () => {
    getProfiliProfessionaliAI(filtri);
  };

  // Function per visualizzare il dettaglio della competenze
  const onView = (row) => {
    getProfiloProfessionaleById(row.codice_riva);
    setModalShow(true);
  };

  useEffect(() => {
    getFamiglieProfessionali(0, 1000, 1, { fk_sequ_comparto: sequ_k_comparto });
    getAreeContrattuali(0, 1000, { fk_sequ_comparto: sequ_k_comparto });
    getDimensioniProfessionali(0, 1000, {
      fk_sequ_comparto: sequ_k_comparto,
      banca_dati_unica: !idAmministrazione ? true : undefined,
    });
    getCompetenze(0, 1000, 1, {});
    getAmbitiRuolo(0, 1000, { fk_sequ_comparto: sequ_k_comparto });
    getGrading(0, 1000, {});
    setIsLoading(false);
  }, []);

  return (
    isLoading ? (
      <SpinnerBody />
    ) : (
      <Container className="p-0">
        <TitlePage
          title={(
            <>
              Ricerca Profili Professionali
              {' '}
              <Image src={logoAI} width="30" height="30" />
            </>
            )}
          showIndietro
          functionIndietro={() => history.goBack()}
        />
        <Row className="justify-content-center mt-3">
          <div as={Col} style={{ width: '70%' }} className="d-flex flex-column align-items-center">
            <Form.Group className="w-100 mb-3">
              <Form.Control
                name="query"
                value={filtri.query}
                type="text"
                placeholder="Inserisci un suggerimento per la ricerca... "
                maxLength={500}
                onChange={onChange}
                style={{ width: '100%' }}
              />
            </Form.Group>
            <Button
              variant="primary"
              onClick={() => onCercaProfili()}
              disabled={filtri.query.length === 0}
            >
              CERCA
            </Button>
          </div>
        </Row>
        <Row className="justify-content-center mt-3">
          {isCerca && (
            <FiltriAIProfiliProfessionali
              filtri={filtri}
              setFiltri={setFiltri}
              onChangeFiltri={onChange}
              getProfiliProfessionaliAI={getProfiliProfessionaliAI}
              optionsAreeContrattuali={areeContrattualiList}
              optionsDimensioniProfessionali={dimensioniProfessionaliList}
              optionsFamiglieProfessionali={famiglieProfessionaliList}
              optionsCompetenze={competenzeList}
            />
          )}
          {profiliProfessionaliList.length > 0 ? (
            <>
              <Table
                data={profiliProfessionaliList}
                fields={fieldsProfiliProfessionaliAI(onView)}
                keyField="similarity_score"
              />
              {(sequ_k_comparto && controlla('X25')) && (
              <Card
                bg={themeColors.secondary}
                text="dark"
                style={{ width: '50rem' }}
                className="mb-2"
              >
                <Card.Body>
                  <Card.Text>
                    Se i risultati non sono soddisfacenti, vuoi aggiungere un nuovo profilo?
                    {' '}
                    <Button
                      variant="primary"
                      onClick={() => history.push(
                        '/area-riservata/profili-professionali',
                        { modalShow: true },
                      )}
                      size="sm"
                    >
                      AGGIUNGI PROFILO
                    </Button>
                  </Card.Text>
                </Card.Body>
              </Card>
              )}
            </>
          ) : (
            <EmptyState
              subtitle="Procedi con la ricerca per visualizzare i dati compatibili."
            />
          )}
        </Row>
        <ModaleCentroVerticale
          modalTitle="Profilo Professionale"
          modalBody={(
            <FormProfiloProfessionale
              stateProfiloProfessionale={stateProfiloProfessionale}
              onChange={() => {}}
              optionsAmbitiRuolo={getAmbitiRuoloList(
                ambitiRuoloList,
                stateProfiloProfessionale.fk_sequ_famiglia_professionale,
              )}
              optionsGrading={gradingList}
              isDisabled
              setModalShowCtp={() => {}}
              setModalShowCc={() => {}}
              setModalProfiloRuolo={() => {}}
              setStateProfiloRuolo={() => {}}
              onDeleteCompetenza={() => {}}
              errors={{}}
              handleTableChange={() => {}}
              isAI
            />
          )}
          size="xl"
          show={modalShow}
          disabledConfirm
          onClose={() => setModalShow(false)}
        />
      </Container>
    )
  );
}

export default RicercaAIProfiloProfessionale;
