import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { Container, Image } from 'react-bootstrap';
import { Context } from '../../../utils/Context';
import SistemaProfessionaleService from '../../../services/sistemaProfessionaleService';
import checkStorage from '../../../utils/checkSessionStorage';
import { getItem, removeItem, setItem } from '../../../utils/storage';
import TitlePage from '../../../components/GestisciUtenti/TitoloPagina';
import Table from '../../../components/Shared/Table';
import EmptyState from '../../../components/EmptyState';
import ModaleCentroVerticale from '../../../components/modali/ModaleCentroVerticale';
import ModaleConferma from '../../../components/modali/ModaleConferma';
import fieldsProfiliProfessionali from './fieldsTable';
import FormProfiloProfessionale from './FormProfiloProfessionale';
import history from '../../../utils/history';
import FiltriProfiliProfessionali from './filtriProfiliProfessionali';
import { controlla } from '../../../utils/verificaPermessi';
import ExportButtonExcel from '../../../components/ExportButton/ExportButtonExcel';
import logoAI from '../../../images/LogoAi.svg';

function ProfiliProfessionali() {
  const sistemaProfessionaleUrl = process.env.REACT_APP_SISTEMA_PROFESSIONALE_URL;
  const { state } = useLocation();
  const [context, setContext] = useContext(Context);
  const [profiliProfessionaliList, setProfiliProfessionaliList] = useState([]);
  const [dimensioniProfessionaliList, setDimensioniProfessionaliList] = useState([]);
  const [famiglieProfessionaliList, setFamiglieProfessionaliList] = useState([]);
  const [areeContrattualiList, setAreeContrattualiList] = useState([]);
  const [profiloProfessionaleSelected, setProfiloProfessionaleSelected] = useState();
  const [modalShow, setModalShow] = useState(state?.modalShow ?? false);
  const [modalShowDelete, setModalShowDelete] = useState(false);
  const [errors, setErrors] = useState({});
  const { idAmministrazione } = JSON.parse(getItem('identita'));
  const { sequ_k_comparto } = getItem('compartoSelected') ? JSON.parse(getItem('compartoSelected')) : 0;
  const { desc_comparto } = getItem('compartoSelected') ? JSON.parse(getItem('compartoSelected')) : 0;
  const [stateProfiloProfessionale, setStateProfiloProfessionale] = useState({
    desc_titolo: '',
    desc_scopo: '',
    desc_attivita: '',
    fk_sequ_comparto: sequ_k_comparto,
    comparto: desc_comparto,
    fk_sequ_area_contrattuale: 0,
    fk_sequ_dimensione_professionale: 0,
    fk_sequ_famiglia_professionale: 0,
    fk_sequ_codi_amministrazione: 0,
    fk_sequ_ambiti_ruoli: [],
    fk_sequ_competenze: [],
    flag_cerniera: false,
    famiglia_professionale: '',
    area_contrattuale: '',
    dimensione_professionale: '',
  });
  const [filtri, setFiltri] = useState({});
  const [pagination, setPagination] = useState({
    page: 1,
    sizePerPage: 10,
    totalSize: 0,
  });

  // Validazione per la creazione di un profilo professionale
  const validateCreaProfiloProfessionale = (stato, error) => {
    const temp = { ...error };
    temp.desc_titolo = stato.desc_titolo === '';
    temp.fk_sequ_area_contrattuale = stato.fk_sequ_area_contrattuale === 0;
    temp.fk_sequ_dimensione_professionale = (stato.fk_sequ_dimensione_professionale === 0
      && (stato.fk_sequ_comparto === 1 || stato.fk_sequ_comparto === 2));
    temp.fk_sequ_famiglia_professionale = stato.fk_sequ_famiglia_professionale === 0;
    temp.desc_scopo = stato.desc_scopo === '';
    temp.desc_attivita = stato.desc_attivita === '' || stato.desc_attivita === stato.desc_suggerimento;
    setErrors((prevState) => ({
      ...prevState,
      ...temp,
    }));
    return Object.values(temp).every((x) => x === false);
  };

  // function collegata al servizio per ottenere la lista dei profili professionali
  async function getProfiliProfessionali(offset, limit, page, filters) {
    try {
      const filterSession = filters || filtri;
      const response = await SistemaProfessionaleService
        .getProfiliProfessionali(filterSession, offset, limit);
      setProfiliProfessionaliList(response.rows);
      setPagination((prevPag) => ({
        ...prevPag,
        page,
        sizePerPage: limit,
        totalSize: response.totalRowCount,
      }));
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // function collegata al servizio per ottenere la lista delle dimensioni professionali
  async function getDimensioniProfessionali(offset, limit, filters) {
    try {
      const response = await SistemaProfessionaleService
        .getDimensioniProfessionali(filters, offset, limit);
      setDimensioniProfessionaliList(response.rows);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // function collegata al servizio per ottenere la lista delle famiglie professionali
  async function getFamiglieProfessionali(offset, limit, filters) {
    try {
      const response = await SistemaProfessionaleService
        .getFamiglieProfessionali(filters, offset, limit);
      setFamiglieProfessionaliList(response.rows);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // function collegata al servizio per ottenere la lista delle aree contrattuali
  async function getAreeContrattuali(offset, limit, filters) {
    try {
      const response = await SistemaProfessionaleService
        .getAreaContrattuale(filters, offset, limit);
      setAreeContrattualiList(response.rows);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // function attivata al cambio pagina della tabella
  const handleTableChange = (type, {
    page, sizePerPage, sortField, sortOrder,
  }) => {
    const newFilters = {
      ...filtri,
      fk_sequ_comparto: sequ_k_comparto,
      sortField: type === 'sort' ? sortField : 'desc_codice',
      sortOrder: type === 'sort' ? sortOrder : 'asc',
    };
    let currentIndex;
    if (page !== 0) {
      currentIndex = (page - 1) * sizePerPage;
    }
    getProfiliProfessionali(
      currentIndex,
      sizePerPage,
      page,
      newFilters,
    );
  };

  // useEffect
  useEffect(() => {
    let offset = 0;
    const storage = checkStorage(filtri, setFiltri, setPagination);
    if (storage.page > 1) {
      handleTableChange(null, { page: storage.page, sizePerPage: pagination.sizePerPage });
      offset = (storage.page - 1) * pagination.sizePerPage;
    }
    getProfiliProfessionali(
      offset,
      10,
      storage.page,
      { ...storage.filtri, fk_sequ_comparto: sequ_k_comparto },
    );
    removeItem('stateProfiloProfessionale');
  }, []);

  // useEffect per settare nello storage la pagina corrente
  useEffect(() => {
    setItem('page', pagination.page);
  }, [pagination.page]);

  // useEffect per caricare i dati presenti nella tabella.
  useEffect(() => {
    setFiltri({ ...filtri, fk_sequ_comparto: sequ_k_comparto });
    getProfiliProfessionali(0, 10, 1, { ...filtri, fk_sequ_comparto: sequ_k_comparto });
    setStateProfiloProfessionale({
      ...stateProfiloProfessionale,
      fk_sequ_comparto: sequ_k_comparto,
    });
    // IMPOSTATI LIMIT A 1000
    getDimensioniProfessionali(0, 1000, {
      fk_sequ_comparto: sequ_k_comparto,
      banca_dati_unica: !idAmministrazione ? true : undefined,
    });
    getFamiglieProfessionali(0, 1000, {
      fk_sequ_comparto: sequ_k_comparto,
      banca_dati_unica: !idAmministrazione ? true : undefined,
    });
    getAreeContrattuali(0, 1000, { fk_sequ_comparto: sequ_k_comparto });
  }, [sequ_k_comparto]);

  // function per modificare lo stato del profilo professionale che l'utente sta modificando.
  const onChange = (e, config) => {
    const newState = { ...stateProfiloProfessionale };
    if (config) {
      if (e?.sequ_k_area_contrattuale) {
        newState[config.name] = e?.sequ_k_area_contrattuale;
        newState.desc_suggerimento = e.desc_suggerimento;
        newState.desc_attivita = e.desc_suggerimento;
        newState.area_contrattuale = `${e.desc_codice} - ${e.desc_titolo}`;
      } else if (config?.name === 'fk_sequ_dimensione_professionale') {
        const mapCompetenzeD = e ? e.d_rel_dimensione_prof_comps.map((el) => ({
          desc_codice: el?.competenza?.desc_codice,
          desc_titolo: el?.competenza?.desc_titolo,
          desc_descrizione: el?.competenza?.desc_descrizione,
          fk_sequ_competenza: el.fk_sequ_competenza,
          grading: el.codi_grading,
          codi_grading_base: el.codi_grading,
          provenienza: 'D',
          codi_tipo_competenza: el.competenza.codi_tipo_competenza,
        })) : [];
        newState[config.name] = e ? e.sequ_k_dimensione_professionale : 0;
        newState.fk_sequ_competenze_D = mapCompetenzeD;
        newState.dimensione_professionale = `${e.desc_codice} - ${e.desc_titolo}`;
        const competenzeUpdateD = [...newState.fk_sequ_competenze, ...mapCompetenzeD];
        newState.fk_sequ_competenze = competenzeUpdateD;
      } else if (config?.name === 'fk_sequ_famiglia_professionale') {
        const mapCompetenzeF = e ? e.d_rel_famiglia_prof_comps.map((el) => ({
          desc_codice: el?.competenza?.desc_codice,
          desc_titolo: el?.competenza?.desc_titolo,
          desc_descrizione: el?.competenza?.desc_descrizione,
          fk_sequ_competenza: el.fk_sequ_competenza,
          grading: el.codi_grading,
          codi_grading_base: el.codi_grading,
          provenienza: 'F',
          codi_tipo_competenza: el.competenza.codi_tipo_competenza,
        })) : [];
        newState[config.name] = e ? e.sequ_k_famiglia_professionale : 0;
        newState.fk_sequ_competenze_F = mapCompetenzeF;
        newState.famiglia_professionale = `${e.desc_codice} - ${e.desc_titolo}`;
        const competenzeUpdateF = [...newState.fk_sequ_competenze, ...mapCompetenzeF];
        newState.fk_sequ_competenze = competenzeUpdateF;
      } else {
        newState[config.name] = e?.sequ_k_comparto;
      }
    } else {
      const { name, value, checked } = e.target;
      if (name === 'flag_cerniera') {
        newState[name] = checked;
      } else {
        newState[name] = value;
      }
    }
    setStateProfiloProfessionale(newState);
    validateCreaProfiloProfessionale(newState, errors);
  };

  // function per modificare lo stato che gestisce i filtri
  function onChangeFiltri(e) {
    const { value, name, checked } = e.target;
    if (name === 'banca_dati_unica') {
      filtri[name] = checked;
    } else {
      setFiltri({
        ...filtri,
        [name]: value,
      });
    }
  }

  // function per effettuare il refresh della pagina
  function refresh() {
    getProfiliProfessionali(0, 10, 1, { ...filtri, fk_sequ_comparto: sequ_k_comparto });
  }

  const handleExport = (path) => {
    let params = '';
    if (filtri.desc_titolo) {
      params += `desc_titolo=${filtri.desc_titolo}&`;
    }
    if (filtri.desc_codice) {
      params += `desc_codice=${filtri.desc_codice}&`;
    }
    if (filtri.fk_sequ_comparto) {
      params += `fk_sequ_comparto=${filtri.fk_sequ_comparto}&`;
    }
    if (filtri.banca_dati_unica || filtri.banca_dati_unica === false) {
      params += `banca_dati_unica=${filtri.banca_dati_unica}&`;
    }
    if (filtri.sortField) {
      params += `sortField=${filtri.sortField}&`;
    }
    if (filtri.sortOrder) {
      params += `sortOrder=${filtri.sortOrder}&`;
    }
    const a = document.createElement('A');
    a.href = `${sistemaProfessionaleUrl}/profilo-professionale/export/${path}?${params}`;
    a.target = '_blank';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  // function collegata al servizio di eliminazione di un profilo professionale
  async function eliminaProfiloProfessionale(idProfiloProfessionale) {
    SistemaProfessionaleService.eliminaProfiloProfessionale(idProfiloProfessionale)
      .then((res) => {
        setContext(() => ({
          ...context, openConferma: true, testo: res.message,
        }));
        setModalShowDelete(false);
        refresh();
      })
      .catch((err) => {
        setContext(() => ({
          ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
        }));
      });
  }

  // Funzione attivata al click dell'icona di eliminazione
  const onDelete = (profiloProfessionale) => {
    setModalShowDelete(true);
    setProfiloProfessionaleSelected(profiloProfessionale);
  };

  // Funzione attivata al click dell'icona di visualizzazione
  const onView = (idProfiloProfessionale) => {
    history.push(
      `/area-riservata/profili-professionali/${idProfiloProfessionale}`,
      { isModify: false, isNew: false },
    );
  };

  // Funzione attivata al click dell'icona di modifica
  const onModify = (idProfiloProfessionale) => {
    history.push(
      `/area-riservata/profili-professionali/${idProfiloProfessionale}`,
      { isModify: true, isNew: false },
    );
  };

  // Funzione attivata al conferma dell'aggiungi un nuovo profilo professionale
  const onConfirm = () => {
    if (validateCreaProfiloProfessionale(stateProfiloProfessionale, errors)) {
      setItem('stateProfiloProfessionale', JSON.stringify(stateProfiloProfessionale));
      history.push(
        `/area-riservata/profili-professionali/${0}`,
        { isModify: true, isNew: true },
      );
    }
  };

  return (
    <>
      <Container className="p-0">
        <TitlePage
          title="Profili Professionali"
          showRefresh
          functionRefresh={refresh}
          showButtonExport
          buttonExport={(
            <ExportButtonExcel dropdown functionExport={handleExport} />
          )}
          showAdd={sequ_k_comparto && controlla('X25')}
          functionAdd={() => setModalShow(true)}
          showAI={controlla('X51')}
          variantAI="outline-AI"
          functionAI={() => history.push('/area-riservata/profili-professionali/ricerca-ai')}
          imageAI={<Image src={logoAI} width="22.5" height="22.5" />}
          titleAI="RICERCA con AI"
        />
        <FiltriProfiliProfessionali
          filtri={filtri}
          setFiltri={setFiltri}
          onChangeFiltri={onChangeFiltri}
          getProfiliProfessionali={getProfiliProfessionali}
        />
        {profiliProfessionaliList.length > 0 ? (
          <Table
            data={profiliProfessionaliList}
            fields={fieldsProfiliProfessionali(onDelete, onView, onModify, idAmministrazione)}
            pagination={pagination}
            onTableChange={handleTableChange}
            keyField="profili_professionali_list"
          />
        ) : (
          <EmptyState marginTop="3rem" />
        )}
      </Container>
      <ModaleCentroVerticale
        modalTitle="Crea un nuovo profilo professionale"
        modalBody={(
          <FormProfiloProfessionale
            stateProfiloProfessionale={stateProfiloProfessionale}
            onChange={onChange}
            optionsDimensioniProfessionali={dimensioniProfessionaliList}
            optionsFamiglieProfessionali={famiglieProfessionaliList}
            optionsAreeContrattuali={areeContrattualiList}
            disabledSelect={sequ_k_comparto}
            errors={errors}
            isNew
          />
        )}
        buttonType="submit"
        show={modalShow}
        onConfirm={() => onConfirm()}
        labelConfirm="INSERISCI COMPETENZE"
        onClose={() => setModalShow(false)}
      />
      <ModaleConferma
        labelBottone="Elimina"
        coloreAzione="primary"
        title="Sei sicuro di procedere?"
        body={`L'operazione eliminerà il profilo professionale ${profiloProfessionaleSelected?.codice}`}
        show={modalShowDelete}
        handleClose={() => setModalShowDelete(false)}
        handleOk={() => eliminaProfiloProfessionale(profiloProfessionaleSelected.id)}
      />
    </>
  );
}

export default ProfiliProfessionali;
