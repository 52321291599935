import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import Table from '../Shared/Table';
import { fields, selectRows } from './fieldsTableIdentitaModifica';
import FiltriRicercaIdentita from './FiltriRicercaIdentita';
import EmptyState from '../EmptyState';

function ModaleRicercaIdentitaModifica({
  show,
  handleClose,
  identita,
  identitaScelte,
  setIdentitaScelte,
  onChangeFiltriIdentita,
  getListaIdentita,
  identitaRimosse,
  setIdentitaRimosse,
  identitaAggiunte,
  setIdentitaAggiunte,
  handleTableChangeIdentita,
  paginationIdentita,
}) {
  return (
    <Modal show={show} onHide={handleClose} centered size="xl" role="dialog">
      <Modal.Header closeButton closeLabel="Chiudi">
        <Modal.Title>Ricerca identità da associare</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FiltriRicercaIdentita
          onChangeFiltriIdentita={onChangeFiltriIdentita}
          getListaIdentita={getListaIdentita}
        />
        {identita.length > 0 ? (
          <Table
          title="Ricerca identita"
          data={identita}
          fields={fields}
          pagination={paginationIdentita}
          onTableChange={handleTableChangeIdentita}
          keyField="sequ_k_identita"
          selectRow={selectRows(
            setIdentitaScelte,
            identitaScelte,
            identitaRimosse,
            setIdentitaRimosse,
            identitaAggiunte,
            setIdentitaAggiunte,
          )}
        />) : (
          <EmptyState title="Nessuna identità trovata"/>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="grey" onClick={handleClose}>
          Chiudi
        </Button>
        <Button variant="primary" onClick={handleClose}>
          Conferma
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModaleRicercaIdentitaModifica;
