/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import optionsTorta from './optionsTortaAssessment';
import AssessmentService from '../../services/assessmentService';
import { getItem } from '../../utils/storage';

function ReportAssessment() {
  const [assessment, setAssessment] = useState([]);
  const { sequ_k_comparto } = JSON.parse(getItem('compartoSelected'));

  async function fetchReportData() {
    try {
      setAssessment(await AssessmentService.getReportAssessment(sequ_k_comparto));
    } catch (error) {
      console.error('Errore durante il recupero dei dati del report:', error);
    }
  }

  useEffect(() => {
    fetchReportData();
  }, []);

  useEffect(() => {
    fetchReportData();
  }, [sequ_k_comparto]);

  return (
    <div className="container mt-5">
      {!!assessment.length && (
      <div className="row mt-5 mb-5">
        <ReactECharts
          option={optionsTorta(assessment)}
          style={{ height: 400 }}
        />
      </div>
      )}
    </div>
  );
}

export default ReportAssessment;
