import {
  Card,
  Col, Form, Row, Button,
} from 'react-bootstrap';
import { useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from 'react-select';
import { formatoData } from '../../utils/formatData';
import richiestIdentitaService from '../../services/richiestaIdentitaService';
import UploadComponent from './UploadFile';

const accreditamentoUrl = process.env.REACT_APP_ACCREDITAMENTO_URL;
const flagFiltroPrimeAmministrazioni = process.env.REACT_APP_FLAG_FILTRO_PRIME_AMMINISTRAZIONI === 'true';

function FormRichiestaIdentita({
  richiestaIdentita,
  onChange,
  disabled,
  disabledRichiedente,
  context,
  setContext,
  campiCompilati,
  setRichiestaIdentita,
  isModify,
  stati,
  isAdmin,
  options,
  setOptions,
  isDetail,
}) {
  const [isLoadingSelect, setIsLoadingSelect] = useState(false);
  const inputRef = useRef(null);

  const loadOptions = async (input) => {
    if (input.length >= 3) {
      try {
        setIsLoadingSelect(true);
        const response = isAdmin
          ? await richiestIdentitaService.getListaIpaAdmin(input)
          : await richiestIdentitaService.getListaIpa(input);
        setOptions(response);
      } catch (err) {
        setContext(() => ({
          ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
        }));
      } finally {
        setIsLoadingSelect(false);
      }
    }
  };

  async function uploadPdf(filename) {
    if (filename) {
      richiestIdentitaService.salvaRichiesta(richiestaIdentita)
        .then(async (response) => {
          try {
            const file = inputRef.current.files[0];
            const res = await richiestIdentitaService
              .uploadPdf(richiestaIdentita.sequ_k_richiesta, file);
            setContext(() => ({
              ...context, openConferma: true, testo: res.message,
            }));
            setRichiestaIdentita({ ...richiestaIdentita, codi_pdf_minio: filename });
          } catch (err) {
            setContext(() => ({
              ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
            }));
          }
        }).catch((err) => {
          setContext(() => ({
            ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
          }));
        });
    }
  }

  async function downloadPdf() {
    richiestIdentitaService.salvaRichiesta(richiestaIdentita)
      .then((res) => {
        const a = document.createElement('A');
        a.href = `${accreditamentoUrl}/gestione-richiesta-identita-utente/${richiestaIdentita.sequ_k_richiesta}/generate-file`;
        a.target = '_blank';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        setContext(() => ({
          ...context, openConferma: true, testo: 'Il download del file è stato effettuato correttamente',
        }));
      })
      .catch((err) => {
        setContext(() => ({
          ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
        }));
      });
  }

  async function deletePdf() {
    try {
      const res = await richiestIdentitaService
        .deleteFileRichiesta(richiestaIdentita.sequ_k_richiesta);
      setContext(() => ({
        ...context, openConferma: true, testo: res.message,
      }));
      setRichiestaIdentita({ ...richiestaIdentita, codi_pdf_minio: null });
      inputRef.current.value = null;
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  function messageForEmptyIpaRisearch() {
    if (flagFiltroPrimeAmministrazioni) {
      return 'Nessun risultato trovato. L\'amministrazione cercata potrebbe non essere ancora abilitata.';
    }
    return 'Nessun risultato trovato.';
  }

  return (
    <Form className="mt-3">
      {(richiestaIdentita.data_ultima_modifica || richiestaIdentita.data_invio_richiesta) && (
      <Row className="mb-3">
        {richiestaIdentita.data_ultima_modifica && (
        <Form.Group as={Col} md={6} controlId="data_ultima_modifica">
          <Form.Label>Data ultima modifca</Form.Label>
          <Form.Control
            placeholder="Data ultima modifca"
            value={formatoData(richiestaIdentita.data_ultima_modifica)}
            onChange={onChange}
            disabled={disabled || disabledRichiedente}
          />
        </Form.Group>
        )}
        {richiestaIdentita.data_invio_richiesta && (
        <Form.Group as={Col} md={6} controlId="data_invio_richiesta">
          <Form.Label>Data invio</Form.Label>
          <Form.Control
            placeholder="Data invio"
            value={formatoData(richiestaIdentita.data_invio_richiesta)}
            onChange={onChange}
            disabled={disabled || disabledRichiedente}
          />
        </Form.Group>
        )}
      </Row>
      )}
      <Row className="mb-3">
        <Form.Group as={Col} lg controlId="codi_id_fiscale">
          <Form.Label>Richiedente</Form.Label>
          <Form.Control
            placeholder="Inserisci nome"
            value={`${richiestaIdentita.desc_nome} ${richiestaIdentita.desc_cognome}`}
            onChange={onChange}
            disabled={disabled || disabledRichiedente}
          />
        </Form.Group>

        <Form.Group as={Col} lg controlId="desc_qualifica_richiedente">
          <Form.Label>
            In qualità di
            {!disabled ? '*' : ''}
          </Form.Label>
          <Form.Control
            placeholder="Inserisci il tuo ruolo"
            value={richiestaIdentita.desc_qualifica_richiedente}
            onChange={onChange}
            disabled={disabled}
            maxLength={100}
          />
        </Form.Group>

        <Form.Group as={Col} lg controlId="desc_email">
          <Form.Label>
            Indirizzo mail
            {!disabled ? '*' : ''}
          </Form.Label>
          <Form.Control
            placeholder="Inserisci il tuo indirizzo mail"
            value={richiestaIdentita.desc_email}
            onChange={onChange}
            disabled={disabled}
            maxLength={100}
            pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}"
            style={{
              borderColor: !richiestaIdentita?.desc_email
              || richiestaIdentita?.desc_email?.match(/[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}/)
                ? '' : 'red',
            }}
          />
        </Form.Group>
        {richiestaIdentita.fk_codi_stato}
        {!disabledRichiedente ? (
          <Form.Group as={Col} lg controlId="fk_codi_stato">
            <Form.Label>Stato richiesta</Form.Label>
            <Form.Select
              disabled
              value={richiestaIdentita.codi_stato_richiesta}
            >
              <option> </option>
              {stati.map((stato) => (
                <option
                  key={stato.codi_k_stato}
                  value={stato.codi_k_stato}
                >
                  {stato.desc_stato}

                </option>
              ))}
            </Form.Select>
          </Form.Group>
        ) : null }
      </Row>

      <Row className="mb-3">

        <Form.Group as={Col} lg controlId="desc_msg_richiesta">
          <Form.Label>
            Messaggio di richiesta
          </Form.Label>
          <Form.Control
            placeholder="Inserisci descrizione"
            as="textarea"
            value={richiestaIdentita.desc_msg_richiesta}
            onChange={onChange}
            disabled={disabled}
            maxLength={1000}
          />
        </Form.Group>
      </Row>

      {(!disabledRichiedente && richiestaIdentita.desc_msg_risposta) && (
      <Row className="mb-3">

        <Form.Group as={Col} lg controlId="desc_msg_risposta">
          <Form.Label>Messaggio di risposta</Form.Label>
          <Form.Control
            placeholder="Risposta"
            as="textarea"
            value={richiestaIdentita.desc_msg_risposta}
            onChange={onChange}
            disabled={disabled}
          />
        </Form.Group>
      </Row>
      ) }
      <Row className="mb-3">
        <Form.Group as={Col} lg controlId="codi_ipa">
          <Form.Label>
            Seleziona Amministrazione
            {!disabled ? '*' : ''}
          </Form.Label>
          <Select
            id="codi_ipa"
            name="codi_ipa"
            options={options}
            onInputChange={(input) => {
              loadOptions(input);
            }}
            onChange={(selectedOption, config) => { onChange(selectedOption, config); }}
            placeholder="Inserisci almeno 3 caratteri"
            isLoading={isLoadingSelect}
            isDisabled={disabled}
            getOptionLabel={(option) => option.des_amm}
            getOptionValue={(option) => option.cod_amm}
            value={options?.filter(
              (el) => el.cod_amm === richiestaIdentita.codi_ipa,
            )?.[0]}
            noOptionsMessage={messageForEmptyIpaRisearch}
          />
          <Form.Text className="text-muted">
            Ricerca l&apos;amministrazione per denominazione.
          </Form.Text>
        </Form.Group>
      </Row>

      <Card className="mb-3" body border="primary" style={{ borderRadius: '15px' }}>
        <Card.Text style={{ fontWeight: 'bold' }}>
          Inserisci le informazioni del responsabile firmatario dell&apos;atto di nomina
        </Card.Text>
        <Row>
          <Form.Group as={Col} lg controlId="desc_nome_responsabile">
            <Form.Label>
              Nome Responsabile
              {!disabled ? '*' : ''}
            </Form.Label>
            <Form.Control
              placeholder="Inserisci nome del responsabile"
              value={richiestaIdentita.desc_nome_responsabile}
              onChange={onChange}
              disabled={disabled}
              maxLength={50}
            />
          </Form.Group>
          <Form.Group as={Col} lg controlId="desc_cognome_responsabile">
            <Form.Label>
              Cognome Responsabile
              {!disabled ? '*' : ''}
            </Form.Label>
            <Form.Control
              placeholder="Inserisci cognome del responsabile"
              value={richiestaIdentita.desc_cognome_responsabile}
              onChange={onChange}
              disabled={disabled}
              maxLength={50}
            />
          </Form.Group>
          <Form.Group as={Col} lg controlId="desc_cf_responsabile">
            <Form.Label>
              CF Responsabile
              {!disabled ? '*' : ''}
            </Form.Label>
            <Form.Control
              placeholder="Inserisci CF del responsabile"
              value={richiestaIdentita.desc_cf_responsabile}
              onChange={onChange}
              disabled={disabled}
              maxLength={16}
              pattern="^[A-Z]{6}\d{2}[A-Z]\d{2}[A-Z]\d{3}[A-Z]$"
              style={{
                borderColor: !richiestaIdentita?.desc_cf_responsabile
                || richiestaIdentita?.desc_cf_responsabile?.match(/^[A-Z]{6}\d{2}[A-Z]\d{2}[A-Z]\d{3}[A-Z]$/)
                  ? '' : 'red',
              }}
            />
          </Form.Group>

          <Form.Group as={Col} lg controlId="desc_qualifica_responsabile">
            <Form.Label>
              In qualità di
              {!disabled ? '*' : ''}
            </Form.Label>
            <Form.Control
              placeholder="Inserisci il ruolo del responsabile"
              value={richiestaIdentita.desc_qualifica_responsabile}
              onChange={onChange}
              disabled={disabled}
              maxLength={100}
            />
          </Form.Group>
        </Row>
      </Card>
      {!disabledRichiedente && (campiCompilati()) ? (
        <Card className="mb-3" body border="primary" style={{ borderRadius: '15px' }}>
          <Card.Text style={{ fontWeight: 'bold' }}>Atto di nomina</Card.Text>
          {isAdmin
            ? (
              <Card.Text>
                Scarica l&apos;atto di nomina cliccando sul pulsante di seguito
              </Card.Text>
            )
            : isDetail && !richiestaIdentita.codi_pdf_minio
              ? (
                <strong>
                  Non hai ancora caricato l&apos;atto di nomina.
                </strong>
              )
              : isDetail && richiestaIdentita.codi_pdf_minio
                ? (
                  <Card.Text>
                    Scarica il PDF caricato
                  </Card.Text>
                )
                : (
                  <Card.Text>
                    Scarica il PDF precompilato cliccando su
                    {' '}
                    <strong>&quot;Genera e scarica atto di nomina&quot;</strong>
                    .
                    Firmalo con la firma digitale a disposizione e
                    ricaricalo utilizzando il pulsante
                    {' '}
                    <strong>&quot;Carica atto di nomina firmato&quot;</strong>
                    .
                  </Card.Text>
                )}
          <Row>
            {!isAdmin && !isDetail && (
            <Col>
              <button type="button" className="btn btn-primary" onClick={downloadPdf}>
                Genera e scarica atto di nomina
              </button>
            </Col>
            )}
            {(!isAdmin || isModify) && (
            <Col>
              <UploadComponent
                deleteFile={deletePdf}
                richiestaIdentita={richiestaIdentita}
                uploadPdf={uploadPdf}
                inputRef={inputRef}
                isDetail={isDetail}
              />
            </Col>
            )}

            {isAdmin && (
              <Col>
                <Button variant="success" className="me-3 mb-3">
                  <a
                    target="_blank"
                    href={`${accreditamentoUrl}/gestione-richiesta-identita-admin/${richiestaIdentita.sequ_k_richiesta}/download-file`}
                    style={{ color: 'white', textDecoration: 'none' }}
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon aria-hidden="true" icon={['fa', 'download']} />
                    {' '}
                    Download
                  </a>
                </Button>
              </Col>
            )}

          </Row>
        </Card>
      )
        : (
          <p>
            Per scaricare l&apos;atto di nomina è necessario salvare la richiesta di accreditamento
            con tutti i campi obbligatori.
          </p>
        )}
    </Form>
  );
}

export default FormRichiestaIdentita;
