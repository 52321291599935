import React, { useContext, useEffect, useState } from 'react';
import {
  Button, Col, Container, Image,
} from 'react-bootstrap';
import { useLocation, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SistemaProfessionaleService from '../../../../services/sistemaProfessionaleService';
import TitlePage from '../../../../components/GestisciUtenti/TitoloPagina';
import { Context } from '../../../../utils/Context';
import FormProfiloProfessionale from '../FormProfiloProfessionale';
import ModaleCentroVerticale from '../../../../components/modali/ModaleCentroVerticale';
import FormSelezioneCompetenze from '../../../../components/Form/SelezioneCompetenze';
import FormProfiliRuolo from '../../profili-ruolo/FormProfiliRuolo';
import history from '../../../../utils/history';
import { getItem } from '../../../../utils/storage';
import FormDuplicazione from '../FormDuplicazione';
import { controlla } from '../../../../utils/verificaPermessi';
import checkCompsModified from '../../../../utils/checkCompsModified';
import logoAI from '../../../../images/LogoAi.svg';
import SpinnerBody from '../../../../components/Shared/SpinnerBody';
import RicercaSimiliAI from './RicercaSimiliAI';
import ModaleConferma from '../../../../components/modali/ModaleConferma';

function ProfiloProfessionaleDettaglio() {
  const [context, setContext] = useContext(Context);
  const { idProfiloProfessionale } = useParams();
  const { state } = useLocation();
  const [stateProfiloProfessionale, setStateProfiloProfessionale] = useState({});
  const [stateProfiloRuolo, setStateProfiloRuolo] = useState({});
  const [dimensioniProfessionaliList, setDimensioniProfessionaliList] = useState([]);
  const [famiglieProfessionaliFilteredList, setFamiglieProfessionaliFilteredList] = useState([]);
  const [famiglieProfessionaliList, setFamiglieProfessionaliList] = useState([]);
  const [areeContrattualiList, setAreeContrattualiList] = useState([]);
  const [ambitiRuoloList, setAmbitiRuoloList] = useState([]);
  const [gradingList, setGradingList] = useState([]);
  const [competenzeListSelected, setCompetenzeListSelected] = useState([]);
  const [isModify, setIsModify] = useState(false);
  const [isNew, setIsNew] = useState(state.isNew);
  const [modalShowCtp, setModalShowCtp] = useState(false);
  const [modalShowCc, setModalShowCc] = useState(false);
  const [modalShowDuplicate, setModalShowDuplicate] = useState(false);
  const [modalProfiloRuolo, setModalProfiloRuolo] = useState(false);
  const [modalAIRicerca, setModalAIRicerca] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [errors, setErrors] = useState({});
  const [errorsRuolo, setErrorsRuolo] = useState({});
  const [filtri, setFiltri] = useState({});
  const [modalSave, setModalSave] = useState(false);
  const { sequ_k_comparto, desc_comparto } = getItem('compartoSelected') ? JSON.parse(getItem('compartoSelected')) : 0;
  const { idAmministrazione } = JSON.parse(getItem('identita'));

  // Validazione per la modifica di un profilo professionale
  const validateModificaProfiloProfessionale = (stato, error) => {
    const temp = { ...error };
    temp.desc_titolo = stato.desc_titolo === '';
    temp.fk_sequ_competenze = stato.fk_sequ_competenze.some((el) => el.grading === 0);
    temp.desc_scopo = stato.desc_scopo === '';
    temp.desc_attivita = stato.desc_attivita === '';
    setErrors((prevState) => ({
      ...prevState,
      ...temp,
    }));
    return Object.values(temp).every((x) => x === false);
  };

  // Validazione per la modifica di un profilo professionale
  const validateCreazioneProfiloRuolo = (stato, error) => {
    const temp = { ...error };
    temp.desc_attivita = stato.desc_attivita === '';
    setErrorsRuolo((prevState) => ({
      ...prevState,
      ...temp,
    }));
    return Object.values(temp).every((x) => x === false);
  };

  // Function per rimuovere le competenze duplicate.
  function removeDuplicatesComps(list) {
    const compsMap = {};
    list.forEach((el) => {
      if (
        !compsMap[el.fk_sequ_competenza] || compsMap[el.fk_sequ_competenza].grading < el.grading
      ) {
        compsMap[el.fk_sequ_competenza] = el;
      }
    });
    return Object.values(compsMap);
  }

  // function attivata al cambio pagina della tabella
  const handleTableChange = (type, { sortField, sortOrder, data }) => {
    const newList = data;
    newList.sort((a, b) => {
      if (sortOrder === 'desc') {
        if (a[sortField] > b[sortField]) return -1;
        if (a[sortField] < b[sortField]) return 1;
        return 0;
      }
      if (a[sortField] < b[sortField]) return -1;
      if (a[sortField] > b[sortField]) return 0;
      return 0;
    });
  };

  // function collegata al servizio per ottenere la lista delle famiglie professionali restanti
  async function getFamiglieProfessionaliByIdProfiloProfessionale(code, offset, limit, filters) {
    try {
      const response = await SistemaProfessionaleService
        .getFamiglieProfessionaliByIdProfiloProfessionale(code, filters, offset, limit);
      setFamiglieProfessionaliFilteredList(response);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // Function collegata al servizio per ottenere la lista delle famiglie professionali
  async function getFamiglieProfessionaliList(offset, limit, filters) {
    try {
      const response = await SistemaProfessionaleService
        .getFamiglieProfessionali(filters, offset, limit);
      setFamiglieProfessionaliList(response.rows);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // function collegata al servizio di get di un particolare profilo professionale
  async function getProfiloProfessionaleById(id) {
    try {
      const response = await SistemaProfessionaleService.getProfiloProfessionaleById(id);
      const listCompetenzeMap = response.d_rel_profilo_prof_comps.map(
        (el) => ({
          ...el.fk_sequ_competenza_d_competenza,
          fk_sequ_competenza: el.fk_sequ_competenza,
          grading: el.codi_grading,
          grading_base: el.codi_grading_base,
          provenienza: el.provenienza,
        }),
      );
      setStateProfiloProfessionale({
        ...response,
        fk_sequ_competenze_totali: listCompetenzeMap,
        fk_sequ_competenze_iniziali: listCompetenzeMap,
        fk_sequ_competenze: removeDuplicatesComps(listCompetenzeMap),
        fk_sequ_ambiti_ruoli: response.d_rel_profilo_prof_ambitos.map(
          (el) => el.fk_sequ_ambito_ruolo,
        ),
      });
      setCompetenzeListSelected(removeDuplicatesComps(listCompetenzeMap));
      getFamiglieProfessionaliByIdProfiloProfessionale(
        response.desc_codice,
        0,
        1000,
        {
          fk_sequ_comparto: sequ_k_comparto,
          banca_dati_unica: !idAmministrazione ? true : undefined,
        },
      );
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // function collegata al servizio per ottenere la lista delle dimensioni professionali
  async function getDimensioniProfessionali(offset, limit, filters) {
    try {
      const response = await SistemaProfessionaleService
        .getDimensioniProfessionali(filters, offset, limit);
      setDimensioniProfessionaliList(response.rows);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // function collegata al servizio per ottenere la lista delle aree contrattuali
  async function getAreeContrattuali(offset, limit, filters) {
    try {
      const response = await SistemaProfessionaleService
        .getAreaContrattuale(filters, offset, limit);
      setAreeContrattualiList(response.rows);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // Function collegata al servizio per ottenere la lista degli ambiti di ruolo
  async function getAmbitiRuolo(offset, limit, filters) {
    try {
      const response = await SistemaProfessionaleService
        .getAmbitiRuolo(filters, offset, limit);
      setAmbitiRuoloList(response.rows);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // function collegata al servizio per ottenere la lista dei grading
  async function getGrading(offset, limit, filters) {
    try {
      const response = await SistemaProfessionaleService
        .getGrading(filters, offset, limit);
      setGradingList(response.rows);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // useEffect per caricare i dati presenti nella tabella.
  useEffect(() => {
    setIsModify(state?.isModify ?? false);
    if (isNew) {
      const stateProfilo = JSON.parse(getItem('stateProfiloProfessionale'));
      setStateProfiloProfessionale({
        ...stateProfilo,
        fk_sequ_competenze_totali: [],
        fk_sequ_competenze_iniziali: [],
        fk_sequ_ambiti_ruoli: [],
      });
      setFiltri({
        query: stateProfilo.desc_titolo,
        nome_comparto: desc_comparto,
        area_contrattuale: [stateProfilo.area_contrattuale.split(' - ')[1]],
        dimensione_professionale: [stateProfilo.dimensione_professionale.split(' - ')[1]],
        famiglia_professionale: [stateProfilo.famiglia_professionale.split(' - ')[1]],
      });
    } else {
      getProfiloProfessionaleById(idProfiloProfessionale);
    }
    // IMPOSTATO LIMIT 1000
    getDimensioniProfessionali(0, 1000, {
      fk_sequ_comparto: sequ_k_comparto,
      banca_dati_unica: !idAmministrazione ? true : undefined,
    });
    getFamiglieProfessionaliList(0, 1000, {
      fk_sequ_comparto: sequ_k_comparto,
      banca_dati_unica: !idAmministrazione ? true : undefined,
    });
    getAreeContrattuali(0, 1000, { fk_sequ_comparto: sequ_k_comparto });
    getAmbitiRuolo(0, 1000, { fk_sequ_comparto: sequ_k_comparto });
    getGrading(0, 1000, {});
    setIsLoading(false);
  }, [idProfiloProfessionale]);

  // function per effettuare il refresh della pagina
  function refresh(idProfilo) {
    getProfiloProfessionaleById(idProfilo);
    setIsModify(false);
    setIsNew(false);
    window.scrollTo({ behavior: 'smooth', top: '0px' });
  }

  // function per modificare lo stato del profilo professionale che l'utente sta modificando.
  const onChange = (e, config, row) => {
    if (config) {
      if (e?.sequ_k_area_contrattuale) {
        setStateProfiloProfessionale({
          ...stateProfiloProfessionale,
          [config.name]: e?.sequ_k_area_contrattuale,
          area_contrattuale: `${e.desc_codice} - ${e.desc_titolo}`,
        });
        const newFiltri = { ...filtri, area_contrattuale: [e.desc_titolo] };
        setFiltri(newFiltri);
      } else if (e?.sequ_k_dimensione_professionale) {
        const removedList = stateProfiloProfessionale.fk_sequ_competenze_totali.filter((el) => el.provenienza !== 'D');
        const mapCompetenzeD = e ? e.d_rel_dimensione_prof_comps.map((el) => ({
          ...el.competenza,
          fk_sequ_competenza: el.fk_sequ_competenza,
          grading: el.codi_grading,
          codi_grading_base: el.codi_grading,
          provenienza: 'D',
        })) : [];
        setStateProfiloProfessionale({
          ...stateProfiloProfessionale,
          [config.name]: e?.sequ_k_dimensione_professionale,
          dimensione_professionale: `${e.desc_codice} - ${e.desc_titolo}`,
          fk_sequ_competenze_totali: [...removedList, ...mapCompetenzeD],
          fk_sequ_competenze: removeDuplicatesComps([...removedList, ...mapCompetenzeD]),
        });
        const newFiltri = { ...filtri, dimensione_professionale: [e.desc_titolo] };
        setFiltri(newFiltri);
        setCompetenzeListSelected(removeDuplicatesComps([...removedList, ...mapCompetenzeD]));
      } else if (e?.sequ_k_famiglia_professionale) {
        const removedList = stateProfiloProfessionale.fk_sequ_competenze_totali.filter((el) => el.provenienza !== 'F');
        const mapCompetenzeF = e ? e.d_rel_famiglia_prof_comps.map((el) => ({
          ...el.competenza,
          fk_sequ_competenza: el.fk_sequ_competenza,
          grading: el.codi_grading,
          codi_grading_base: el.codi_grading,
          provenienza: 'F',
        })) : [];
        setStateProfiloProfessionale({
          ...stateProfiloProfessionale,
          fk_sequ_famiglia_professionale: e?.sequ_k_famiglia_professionale,
          famiglia_professionale: `${e.desc_codice} - ${e.desc_titolo}`,
          fk_sequ_competenze_totali: [...removedList, ...mapCompetenzeF],
          fk_sequ_competenze: removeDuplicatesComps([...removedList, ...mapCompetenzeF]),
        });
        const newFiltri = { ...filtri, famiglia_professionale: [e.desc_titolo] };
        setFiltri(newFiltri);
        setCompetenzeListSelected(removeDuplicatesComps([...removedList, ...mapCompetenzeF]));
      } else if (config.name === 'fk_sequ_ambiti_ruoli') {
        setStateProfiloProfessionale({
          ...stateProfiloProfessionale, [config.name]: e?.map((el) => el.sequ_k_ambito_ruolo),
        });
      } else if (config.name === 'grading') {
        setStateProfiloProfessionale((prevState) => ({
          ...prevState,
          fk_sequ_competenze: prevState.fk_sequ_competenze.map(
            (el) => {
              if (el.fk_sequ_competenza === row.fk_sequ_competenza) {
                return ({
                  ...el,
                  grading: e.codi_valore,
                  provenienza: row.provenienza ?? 'P',
                  grading_base: row.grading_base ?? e.codi_grading,
                });
              } return el;
            },
          ),
          fk_sequ_competenze_totali: prevState.fk_sequ_competenze_totali.map(
            (el) => {
              if (el.fk_sequ_competenza === row.fk_sequ_competenza) {
                return ({
                  ...el,
                  grading: e.codi_valore,
                  provenienza: row.provenienza ?? 'P',
                  grading_base: row.grading_base ?? e.codi_grading,
                });
              } return el;
            },
          ),
        }));
        setCompetenzeListSelected((prevState) => prevState.map((comp) => {
          if (comp.fk_sequ_competenza === row.fk_sequ_competenza) {
            return ({
              ...comp,
              grading: e.codi_valore,
              provenienza: row.provenienza ?? 'P',
              grading_base: row.grading_base ?? e.codi_grading,
            });
          }
          return comp;
        }));
      }
    } else {
      const { name, value, checked } = e.target;
      if (name === 'flag_cerniera') {
        setStateProfiloProfessionale({ ...stateProfiloProfessionale, [name]: checked });
        validateModificaProfiloProfessionale({
          ...stateProfiloProfessionale, [name]: checked,
        }, errors);
      } else {
        if (name === 'desc_titolo') {
          const newFiltri = { ...filtri, query: value };
          setFiltri(newFiltri);
        }
        setStateProfiloProfessionale({ ...stateProfiloProfessionale, [name]: value });
        validateModificaProfiloProfessionale({
          ...stateProfiloProfessionale, [name]: value,
        }, errors);
      }
    }
  };

  // function per modificare lo state del profilo di ruolo che l'utente sta creando
  const onChangeProfiloRuolo = (e) => {
    const { name, value } = e.target;
    setStateProfiloRuolo({ ...stateProfiloRuolo, [name]: value });
    validateCreazioneProfiloRuolo(stateProfiloRuolo, errorsRuolo);
  };

  // function collegata al servizio di modifica di un profilo professionale
  async function modificaProfiloProfessionale(newState) {
    if (validateModificaProfiloProfessionale(newState, errors)) {
      const mapNewState = {
        ...newState,
        fk_sequ_competenze: newState.fk_sequ_competenze_totali,
      };
      const newProfilo = checkCompsModified(mapNewState, newState.fk_sequ_competenze_iniziali);
      SistemaProfessionaleService.modificaProfiloProfessionale(
        newProfilo.sequ_k_profilo_professionale,
        newProfilo,
      )
        .then((res) => {
          setContext(() => ({
            ...context, openConferma: true, testo: res.message,
          }));
          refresh(newProfilo.sequ_k_profilo_professionale);
        })
        .catch((err) => {
          setContext(() => ({
            ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
          }));
        });
    } else if (errors.fk_sequ_competenze) {
      setContext(() => ({
        ...context, open: true, testoErrore: 'Errore: associare un grading ad ogni competenza inserita', statusCode: 400,
      }));
    }
  }

  // function collegata al servizio di creazione di un nuovo profilo di ruolo
  async function nuovoProfiloRuolo() {
    if (validateCreazioneProfiloRuolo(stateProfiloRuolo, errorsRuolo)) {
      SistemaProfessionaleService.nuovoProfiloRuolo({
        ...stateProfiloRuolo,
        comparto: desc_comparto,
      })
        .then((res) => {
          setContext(() => ({
            ...context, openConferma: true, testo: res.message,
          }));
          setModalProfiloRuolo(false);
          if (res.data?.sequ_k_profilo_ruolo) {
            history.push(`/area-riservata/profili-ruolo/${res.data.sequ_k_profilo_ruolo}`);
          }
        })
        .catch((err) => {
          setContext(() => ({
            ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
          }));
        });
    }
  }

  // function collegata al servizio di creazione di un nuovo profilo professionale (DUPLICA)
  async function duplicaProfiloProfessionale() {
    const newState = { ...stateProfiloProfessionale };
    delete newState.fk_sequ_ambiti_ruoli;
    delete newState.d_rel_profilo_prof_ambitos;
    delete newState.sequ_k_profilo_professionale;
    SistemaProfessionaleService.nuovoProfiloProfessionale(newState)
      .then((res) => {
        setContext(() => ({
          ...context, openConferma: true, testo: res.message,
        }));
        setModalShowDuplicate(false);
        if (res.data?.sequ_k_profilo_professionale) {
          history.push(`/area-riservata/profili-professionali/${res.data.sequ_k_profilo_professionale}`);
        }
      })
      .catch((err) => {
        setContext(() => ({
          ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
        }));
      });
  }

  // function collegata al servizio di creazione di un nuovo profilo professionale
  async function nuovoProfiloProfessionale() {
    if (stateProfiloProfessionale.fk_sequ_comparto === 3
           || stateProfiloProfessionale.fk_sequ_comparto === 4) {
      delete stateProfiloProfessionale.fk_sequ_dimensione_professionale;
    }
    const mapRequest = {
      ...stateProfiloProfessionale,
      fk_sequ_competenze: [
        ...(stateProfiloProfessionale.fk_sequ_competenze_D || []),
        ...stateProfiloProfessionale.fk_sequ_competenze_F,
      ],
    };
    SistemaProfessionaleService.nuovoProfiloProfessionale(mapRequest)
      .then((res) => {
        const newState = {
          ...stateProfiloProfessionale,
          sequ_k_profilo_professionale: res.data.sequ_k_profilo_professionale,
        };
        modificaProfiloProfessionale(newState);
        setContext(() => ({
          ...context, openConferma: true, testo: res.message,
        }));
      })
      .catch((err) => {
        setContext(() => ({
          ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
        }));
      });
  }

  // Funzione attivata al click del conferma nella modale di aggiungi CTP
  const onConfirmCtp = () => {
    const newCompetenze = [];
    competenzeListSelected.forEach((el) => {
      const keyOldCompetenze = stateProfiloProfessionale?.fk_sequ_competenze_totali?.map(
        (el1) => el1.fk_sequ_competenza,
      );
      if (keyOldCompetenze && !keyOldCompetenze.includes(el.fk_sequ_competenza)) {
        newCompetenze.push(el);
      }
    });
    setStateProfiloProfessionale({
      ...stateProfiloProfessionale,
      fk_sequ_competenze: competenzeListSelected,
      fk_sequ_competenze_totali: [
        ...stateProfiloProfessionale.fk_sequ_competenze_totali,
        ...newCompetenze,
      ],
    });
    validateModificaProfiloProfessionale(
      {
        ...stateProfiloProfessionale,
        fk_sequ_competenze: competenzeListSelected,
        fk_sequ_competenze_totali: [
          ...stateProfiloProfessionale.fk_sequ_competenze_totali,
          ...newCompetenze,
        ],
      },
      errors,
    );
    setModalShowCtp(false);
  };

  // Funzione attivata al click del chiudi nella modale di aggiungi CTP
  const onCloseCtp = () => {
    const initialComps = stateProfiloProfessionale.fk_sequ_competenze || [];
    setStateProfiloProfessionale({
      ...stateProfiloProfessionale,
      fk_sequ_competenze: initialComps,
    });
    setCompetenzeListSelected(initialComps);
    setModalShowCtp(false);
  };

  // Funzione attivata al click del conferma nella modale di aggiungi CC
  const onConfirmCc = () => {
    const newCompetenze = [];
    competenzeListSelected.forEach((el) => {
      const keyOldCompetenze = stateProfiloProfessionale?.fk_sequ_competenze_totali?.map(
        (el1) => el1.fk_sequ_competenza,
      );
      if (keyOldCompetenze && !keyOldCompetenze.includes(el.fk_sequ_competenza)) {
        newCompetenze.push(el);
      }
    });
    setStateProfiloProfessionale({
      ...stateProfiloProfessionale,
      fk_sequ_competenze: competenzeListSelected,
      fk_sequ_competenze_totali: [
        ...stateProfiloProfessionale.fk_sequ_competenze_totali,
        ...newCompetenze,
      ],
    });
    validateModificaProfiloProfessionale(
      {
        ...stateProfiloProfessionale,
        fk_sequ_competenze: competenzeListSelected,
        fk_sequ_competenze_totali: [
          ...stateProfiloProfessionale.fk_sequ_competenze_totali,
          ...newCompetenze,
        ],
      },
      errors,
    );
    setModalShowCc(false);
  };

  // Funzione attivata al click del chiudi nella modale di aggiungi CC
  const onCloseCc = () => {
    const initialComps = stateProfiloProfessionale.fk_sequ_competenze || [];
    setStateProfiloProfessionale({
      ...stateProfiloProfessionale,
      fk_sequ_competenze: initialComps,
    });
    setCompetenzeListSelected(initialComps);
    setModalShowCc(false);
  };

  // Function attivata al click di elimina singola competenza dalla tabella
  const onDeleteCompetenza = (competenza) => {
    setCompetenzeListSelected((list) => list.filter(
      (el) => el.fk_sequ_competenza !== competenza.fk_sequ_competenza,
    ));
    setStateProfiloProfessionale((prevState) => {
      const newState = {
        ...prevState,
        fk_sequ_competenze: prevState.fk_sequ_competenze.filter(
          (el) => el.fk_sequ_competenza !== competenza.fk_sequ_competenza,
        ),
        fk_sequ_competenze_totali: prevState.fk_sequ_competenze_totali.filter(
          (el) => el.fk_sequ_competenza !== competenza.fk_sequ_competenza,
        ),
      };
      return newState;
    });
  };

  // function per avere la lista degli ambiti relativi alla famiglia professionale selezionata
  const getAmbitiRuoloList = (list, idFamigliaProfessionale) => {
    if (list.length > 0) {
      return list.filter((el) => el.fk_sequ_famiglia_professionale === idFamigliaProfessionale);
    } return [];
  };

  // function per avere la lista degli ambiti relativi alla famiglia professionale selezionata
  const getFamiglieProfessionali = (list, orginalState) => {
    if (list.length > 0) {
      const newList = { ...list };
      newList[list.length] = {
        ...orginalState.famiglia_professionale,
        d_rel_famiglia_prof_comps: orginalState.fk_sequ_competenze_iniziali.filter((comp) => comp.provenienza === 'F'),
      };
      return Object.values(newList);
    } return [];
  };

  // function per salvare le modifiche
  const onSave = () => {
    if (isNew) {
      setModalSave(true);
    } else {
      modificaProfiloProfessionale(stateProfiloProfessionale);
    }
  };

  // Function per gestire la stampa delle informazioni di dettaglio del profilo
  const handlePrint = (stateInfo) => {
    // Recupero array delle competenze collegate al profilo
    const competenzeTecniche = stateInfo.fk_sequ_competenze?.filter((item) => item.codi_tipo_competenza === 'CTP') ?? [];
    const competenzeComportamentali = stateInfo.fk_sequ_competenze?.filter((item) => item.codi_tipo_competenza === 'CC') ?? [];
    const competenzeCTP = competenzeTecniche.map((el) => ({
      ...el,
      titolo_grading: gradingList.filter((el1) => el1.codi_valore === el.grading && el1.codi_tipo_competenza === 'CTP')[0]?.desc_titolo,
    }));
    const competenzeCC = competenzeComportamentali.map((el) => ({
      ...el,
      titolo_grading: gradingList.filter((el1) => el1.codi_valore === el.grading && el1.codi_tipo_competenza === 'CC')[0]?.desc_titolo,
    }));
    const generateTableRows = (data) => data.map((item) => `
        <tr>
          <td>${item.desc_codice}</td>
          <td>${item.desc_titolo}</td>
          <td>${item.grading} - ${item.titolo_grading}</td>
          <td>
            ${item.provenienza !== 'P' ? (item.provenienza === 'F' ? 'Famiglia Professionale' : 'Dimensione Professionale') : (' ')}
          </td>
        </tr>
    `).join('');

    // Recupero array degli ambiti di ruolo collegati al profilo professionale
    const optionsAmbiti = getAmbitiRuoloList(
      ambitiRuoloList,
      stateInfo.fk_sequ_famiglia_professionale,
    );
    const ambitiSelected = stateInfo.fk_sequ_ambiti_ruoli ? (
      optionsAmbiti.filter(
        (el) => stateInfo.fk_sequ_ambiti_ruoli?.includes(el.sequ_k_ambito_ruolo),
      )
    ) : (
      []
    );
    const ambitiRuolo = ambitiSelected.length > 0 ? (
      ambitiSelected.map((el) => `
        <li>${el.desc_titolo}</li>
      `).join(' ')
    ) : (
      '<p>Non vi sono ambiti di ruolo collegati.</p>'
    );

    // Recupero array dei profili di ruolo collegati al profilo professionale
    const profiliRuolo = stateInfo.d_profilo_ruolos.length > 0 ? (
      stateInfo.d_profilo_ruolos.map((prof) => `
        <li>${prof.desc_codice} - ${prof.desc_titolo}</li>
      `).join(' ')
    ) : (
      '<p>Non vi sono profili di ruolo collegati.</p>'
    );

    const windowPrint = window.open(stateInfo.codice, '', 'width=2000,height=1000');

    windowPrint.document.write(`
      <html>
        <head>
          <title>Profilo Professionale ${stateInfo.desc_codice}</title>
          <style>
            body { font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; margin: 0 20px; }
            .section { margin-bottom: 20px; }
            .label { font-weight: bold; }
            table { width: 100%; border-collapse: collapse; }
            th, td { border: 1px solid #ddd; padding: 8px; text-align: left; }
            th { background-color: #f2f2f2; }
            .table-title { font-weight: bold; margin-top: 20px; }
          </style>
        </head>
        <body>
          <h1 style="text-align: center;">Profilo Professionale ${stateInfo.desc_codice}</h1>
          <div class="section">
            <span class="label">Codice:</span> ${stateInfo.desc_codice}
          </div>
          <div class="section">
            <span class="label">Nome:</span> ${stateInfo.desc_titolo}
          </div>
          <div class="section">
            <span class="label">Profilo Cerniera:</span> ${stateInfo.flag_cerniera ? 'Sì' : 'No'}
          </div>
          <div class="section">
            <span class="label">Comparto:</span> ${stateInfo.fk_sequ_comparto_d_comparto.desc_comparto}
          </div>
          <div class="section">
            <span class="label">Area Contrattuale CCNL:</span> ${stateInfo.fk_sequ_area_contrattuale_d_area_contrattuale.desc_codice} - ${stateInfo.fk_sequ_area_contrattuale_d_area_contrattuale?.desc_titolo ?? '-'}
          </div>
          <div class="section">
            <span class="label">Dimensione Professionale:</span> ${stateInfo.dimensione_professionale?.desc_codice ?? '-'} - ${stateInfo.dimensione_professionale.desc_titolo}
          </div>
          <div class="section">
            <span class="label">Famiglia Professionale:</span> ${stateInfo.famiglia_professionale.desc_codice} - ${stateInfo.famiglia_professionale.desc_titolo}
          </div>
          <div class="section">
            <span class="label">Scopo Organizzativo:</span> ${stateInfo.desc_scopo}
          </div>
          <div class="section">
            <span class="label">Macro attività caratterizzanti il Profilo Professionale:</span> ${stateInfo.desc_attivita}
          </div>
          <div class="table-title">Competenze Tecnico Professionali</div>
            <table>
              <thead>
                <tr>
                  <th>Codice</th>
                  <th>Competenza</th>
                  <th>Grading</th>
                  <th>Ereditato da</th> 
                </tr>
              </thead>
              <tbody>
                ${generateTableRows(competenzeCTP)}
              </tbody>
            </table>
          </div>
          <div>
            <div class="table-title">Competenze Comportamentali</div>
            <table>
              <thead>
                <tr>
                  <th>Codice</th>
                  <th>Competenza</th>
                  <th>Grading</th>
                  <th>Ereditato da</th>
                </tr>
              </thead>
              <tbody>
                ${generateTableRows(competenzeCC)}
              </tbody>
            </table>
          </div>
          <br />
          <div class="section">
            <span class="label">Ambito di Ruolo:</span>
          </div>
          <ul>
            ${ambitiRuolo}
          </ul>
          <div class="section">
            <span class="label">Profili di Ruolo collegati:</span>
          </div>
          <ul>
            ${profiliRuolo}
          </ul>
        </body>
      </html>
    `);

    windowPrint.document.close();
    setTimeout(() => {
      windowPrint.focus();
      windowPrint.print();
      windowPrint.close();
    }, 1000);
  };

  // useEffect per gestire l'inserimento nei filtri delle competenze per l'AI
  useEffect(() => {
    const competenzeUpdated = competenzeListSelected.map(
      (el) => el.desc_codice,
    );
    setFiltri((prevState) => ({
      ...prevState,
      competences: competenzeUpdated,
    }));
  }, [competenzeListSelected]);

  return (
    isLoading
      ? (
        <SpinnerBody />
      )
      : (
        <>
          <Container className="p-0">
            <TitlePage
              title="Profilo Professionale"
              showModify={!isModify && controlla('X25') && idAmministrazione === stateProfiloProfessionale?.fk_codi_amministrazione}
              functionModify={() => setIsModify(true)}
              showSave={isModify}
              functionSave={() => onSave()}
              showView={isModify && isNew === false}
              functionView={() => setIsModify(false)}
              showGeneral={!isModify && controlla('X25') && idAmministrazione === stateProfiloProfessionale?.fk_codi_amministrazione}
              functionGeneral={() => setModalShowDuplicate(true)}
              titleGeneral="DUPLICA"
              iconGeneral="copy"
              variantGeneral="primary"
              showIndietro
              functionIndietro={() => history.push(
                '/area-riservata/profili-professionali',
                { modalShow: false },
              )}
              showAI={isNew}
              variantAI="outline-AI"
              functionAI={() => setModalAIRicerca(true)}
              imageAI={<Image src={logoAI} width="22.5" height="22.5" />}
              titleAI="VERIFICA PROFILI SIMILI con AI"
            />
            {!isModify && (
            <Col className="col-lg-auto text-end">
              <Button
                style={{ color: 'white' }}
                variant="success"
                onClick={() => handlePrint(stateProfiloProfessionale)}
              >
                {' '}
                <FontAwesomeIcon aria-hidden="true" icon={['fas', 'print']} />
                {' '}
                STAMPA
                {' '}
              </Button>
            </Col>
            )}
            <FormProfiloProfessionale
              stateProfiloProfessionale={stateProfiloProfessionale}
              onChange={onChange}
              optionsDimensioniProfessionali={dimensioniProfessionaliList}
              optionsFamiglieProfessionali={isNew
                ? famiglieProfessionaliList
                : getFamiglieProfessionali(
                  famiglieProfessionaliFilteredList,
                  stateProfiloProfessionale,
                )}
              optionsAreeContrattuali={areeContrattualiList}
              optionsAmbitiRuolo={getAmbitiRuoloList(
                ambitiRuoloList,
                stateProfiloProfessionale.fk_sequ_famiglia_professionale,
              )}
              optionsGrading={gradingList}
              isDisabled={!isModify}
              setModalShowCtp={setModalShowCtp}
              setModalShowCc={setModalShowCc}
              setModalProfiloRuolo={setModalProfiloRuolo}
              setStateProfiloRuolo={setStateProfiloRuolo}
              onDeleteCompetenza={onDeleteCompetenza}
              errors={errors}
              handleTableChange={handleTableChange}
            />
          </Container>
          <ModaleCentroVerticale
            modalTitle="Competenze Tecnico Professionali"
            modalBody={(
              <FormSelezioneCompetenze
                tipoCompetenza="CTP"
                competenzeListSelected={competenzeListSelected.map((el) => el.fk_sequ_competenza)}
                setCompetenzeListSelected={setCompetenzeListSelected}
              />
          )}
            buttonType="submit"
            show={modalShowCtp}
            onConfirm={() => onConfirmCtp()}
            labelConfirm="Aggiungi competenze"
            onClose={() => onCloseCtp()}
            size="xl"
          />
          <ModaleCentroVerticale
            modalTitle="Competenze Comportamentali"
            modalBody={(
              <FormSelezioneCompetenze
                tipoCompetenza="CC"
                competenzeListSelected={competenzeListSelected.map((el) => el.fk_sequ_competenza)}
                setCompetenzeListSelected={setCompetenzeListSelected}
              />
          )}
            buttonType="submit"
            show={modalShowCc}
            onConfirm={() => onConfirmCc()}
            labelConfirm="Aggiungi competenze"
            onClose={() => onCloseCc()}
            size="xl"
          />
          <ModaleCentroVerticale
            modalTitle="Crea Profilo di Ruolo"
            modalBody={(
              <FormProfiliRuolo
                stateProfiloRuolo={stateProfiloRuolo}
                stateProfiloProfessionale={stateProfiloProfessionale}
                isNew
                onChange={onChangeProfiloRuolo}
                errors={errorsRuolo}
              />
            )}
            buttonType="submit"
            show={modalProfiloRuolo}
            onConfirm={() => nuovoProfiloRuolo()}
            labelConfirm="Salva e aggiungi competenze"
            onClose={() => setModalProfiloRuolo(false)}
          />
          <ModaleCentroVerticale
            modalTitle="Duplicazione Profilo Professionale"
            modalBody={(
              <FormDuplicazione
                stateProfiloProfessionale={stateProfiloProfessionale}
                optionsFamiglieProfessionali={famiglieProfessionaliFilteredList}
                onChange={onChange}
              />
            )}
            buttonType="submit"
            show={modalShowDuplicate}
            onConfirm={() => duplicaProfiloProfessionale()}
            labelConfirm="Duplica"
            onClose={() => setModalShowDuplicate(false)}
          />
          <ModaleCentroVerticale
            modalTitle="Verifica Profili Simili con AI"
            modalBody={(
              <RicercaSimiliAI
                filtri={filtri}
              />
            )}
            show={modalAIRicerca}
            disabledConfirm
            onClose={() => setModalAIRicerca(false)}
            size="xl"
          />
          <ModaleConferma
            title="Conferma salvataggio dati Profilo Professionale"
            body="Vuoi verificare l'esistenza di profili simili,
            prima di inserire il nuovo profilo?"
            labelBottone="SALVA"
            coloreAzione="primary"
            show={modalSave}
            handleClose={() => setModalSave(false)}
            handleOk={() => {
              nuovoProfiloProfessionale();
              setModalSave(false);
            }}
            showAI
            variantAI="outline-AI"
            functionAI={() => {
              setModalSave(false);
              setModalAIRicerca(true);
            }}
            imageAI={<Image src={logoAI} width="22.5" height="22.5" />}
            titleAI="VERIFICA con AI"
          />
        </>
      )
  );
}

export default ProfiloProfessionaleDettaglio;
