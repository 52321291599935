const CCFields = () => (
  [
    {
      dataField: 'Codice',
      text: 'Codice',
      classes: 'column-data',
      headerStyle: () => ({ width: '10%' }),
    },
    {
      dataField: 'Competenza',
      text: 'Competenza Comportamentale',
      classes: 'column-data',
      headerStyle: () => ({ width: '20%' }),
    },
    {
      dataField: 'Descrizione',
      text: 'Descrizione',
      classes: 'column-data',
      headerStyle: () => ({ width: '30%' }),
    },
    {
      dataField: 'Descrittori Comportamentali',
      text: 'Descrittori Comportamentali',
      classes: 'column-data',
      headerStyle: () => ({ width: '30%' }),
    },
    {
      dataField: 'Area',
      text: 'Area',
      classes: 'column-data',
      headerStyle: () => ({ width: '10%' }),
    },
  ]
);

export default CCFields;
