import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Image } from 'react-bootstrap';
import themeColors from '../../../../utils/contants/theme-colors';
import logoAI from '../../../../images/LogoAi.svg';

const fieldsProfiliProfessionaliAI = (onView) => (
  [
    {
      dataField: 'profile_id',
      text: 'Codice',
      classes: 'column-data',
      headerStyle: () => ({ width: '10%' }),
    },
    {
      dataField: 'title',
      text: 'Titolo',
      classes: 'column-data',
      headerStyle: () => ({ width: '25%' }),
    },
    {
      dataField: 'comparto',
      text: 'Comparto',
      classes: 'column-data',
      headerStyle: () => ({ width: '15%' }),
    },
    {
      dataField: 'area_contrattuale',
      text: 'Area Contrattuale',
      classes: 'column-data',
      headerStyle: () => ({ width: '10%' }),
    },
    {
      dataField: 'dimensione_professionale',
      text: 'Dimensione Professionale',
      classes: 'column-data',
      headerStyle: () => ({ width: '10%' }),
    },
    {
      dataField: 'famiglia_professionale',
      text: 'Famiglia Professionale',
      classes: 'column-data',
      headerStyle: () => ({ width: '10%' }),
    },
    {
      text: 'Banca Dati Unica',
      classes: 'column-data',
      headerStyle: () => ({ width: '10%' }),
      formatter: (e, row) => (
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          {row.codice_amministrazione === '' && (
            <FontAwesomeIcon
              aria-hidden="true"
              icon={['fas', 'check']}
              size="sm"
            />
          )}
        </div>
      ),
    },
    {
      dataField: 'similarity_score',
      text: 'Affinità',
      classes: 'column-data',
      headerStyle: () => ({ width: '25%' }),
      formatter: (e, row) => (
        <span>
          {(row.similarity_score * 100).toFixed(2)}
          %
          {' '}
          <Image src={logoAI} width="20" height="20" />
        </span>
      ),
    },
    {
      dataField: 'df1',
      isDummyField: true,
      text: 'Azioni',
      headerStyle: () => ({ width: '10%' }),
      formatter: (e, row) => (
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <FontAwesomeIcon
            aria-hidden="true"
            icon={['fas', 'search']}
            size="lg"
            title="Visualizza"
            id={`Visualizza${row.sequ_k_competenza}`}
            style={{ color: themeColors.primary }}
            onClick={() => onView(row)}
          />
        </div>
      ),
    },
  ]
);

export default fieldsProfiliProfessionaliAI;
