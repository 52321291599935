/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import BreadcrumbComponent from '../../components/Breadcrumb';
import immagineRiVa from '../../images/RiVaImage1.jpg';

function Progetto() {
  return (
    <Container className="justify-content-center col-md-10">
      <Row xs={12}>
        <BreadcrumbComponent listItem={[{ name: 'Il progetto', pathname: '/progetto', active: true }]} />
      </Row>
      <Row className="justify-content-center">
        <Col className="my-3">
          <h1>Il Progetto</h1>
        </Col>
      </Row>
      <Row>
        <div style={{ fontSize: '24px' }}>
          Il Toolkit RiVa nasce nell’ambito del
          {' '}
          <b>
            Progetto RiVa - La gestione strategica delle risorse umane
            per creare Valore Pubblico
          </b>
          , affidato a Formez dal Dipartimento della Funzione Pubblica
          e finanziato dal PNRR &quot;Sub-Riforma 2.3.1
          Riforma del mercato del lavoro della PA&quot;.
        </div>
        <div style={{ fontSize: '24px' }}>
          Obiettivo generale di RiVa è la progettazione, costruzione, sperimentazione
          e sviluppo di un modello HRM Competency Based per l’implementazione del
          processo di programmazione e gestione strategica, valido per tutte le PPAA e
          adattato in funzione delle peculiarità dei diversi comparti.
        </div>
        <div style={{ fontSize: '24px' }}>
          Il progetto intende
          {' '}
          <b>
            individuare una metodologia unitaria ed integrata di gestione delle risorse
            umane in chiave strategica
          </b>
          , basata su modelli di competenze, anche al fine di favorire l’evoluzione
          del mercato del lavoro e lo sviluppo di carriera nella pubblica amministrazione.
        </div>
        <div style={{ fontSize: '24px' }}>
          <b>Il Toolkit RiVa</b>
          {' '}
          è una piattaforma interattiva a disposizione delle Pubbliche Amministrazioni
          per facilitare l’insieme di attività di pianificazione, gestione e sviluppo
          del personale.
        </div>
      </Row>
      <Row className="p-md-5 pt-3">
        <span className="fw-bold h3">Guarda il video:</span>
        <video width="100%" controls preload="none" poster={immagineRiVa}>
          <source src="/content/video-tutorial.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </Row>
    </Container>
  );
}

export default Progetto;
