import React from 'react';
import {
  Form, Row, Button, Col, Accordion,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { controlla } from '../../utils/verificaPermessi';

function FiltriRicercaFaq({
  filtri,
  onChange,
  getListaFaq,
}) {
  return (
    <Accordion className="mb-3">
      <Accordion.Item eventKey="0">
        <Accordion.Header>Filtri di ricerca</Accordion.Header>
        <Accordion.Body>
          <Form>
            <Row>
              <Col className="mb-3">
                <Form.Group as={Col} lg controlId="testo">
                  <Form.Label>Ricerca per testo (domanda, risposta, argomento)</Form.Label>
                  <Form.Control placeholder="Inserisci il testo da ricercare" onChange={onChange} value={filtri.testo} />
                </Form.Group>
              </Col>
              {controlla('W5') && (
              <Col>
                <Form.Check
                  type="switch"
                  id="profonditaStorica"
                  label="Visualizza profondità storica"
                  onChange={(e) => onChange(e, 'checkbox')}
                  value={filtri.profonditaStorica}
                />
              </Col>
              )}
            </Row>
          </Form>
          <Button variant="primary" onClick={() => getListaFaq(0, 1000)}>
            <FontAwesomeIcon aria-hidden="true" icon={['fas', 'search']} style={{ marginRight: '2px' }} />
            CERCA
          </Button>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default FiltriRicercaFaq;
