import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown } from 'react-bootstrap';

function ExportButton({
  functionExport,
  id,
}) {
  return (
    <div>
      <Dropdown>
        <Dropdown.Toggle id={`dropdown-esporta-${id}`}>
          <span>
            <FontAwesomeIcon aria-hidden="true" icon="file-download" />
            {' '}
            ESPORTA
          </span>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item onClick={() => functionExport(id, 'pdf')}>PDF</Dropdown.Item>
          <Dropdown.Item onClick={() => functionExport(id, 'json')}>JSON</Dropdown.Item>
          <Dropdown.Item onClick={() => functionExport(id, 'csv')}>CSV</Dropdown.Item>
          <Dropdown.Item onClick={() => functionExport(id, 'xml')}>XML</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}

export default ExportButton;
