import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import formatCentinaia from '../../../../../utils/formatCentinaia';

const fieldsFullShowScelteCopertura = (
  setTotaleUnitaAllocate,
  setTotaleUnitaCessazione,
  setTtotaleUnitaRichieste,
  calcolaCostoInTable,
  calcolaTotaleCostiInTable,
  calcolaTotaleUnitaProgrammate,
  calcolaUnitaProgrammate,
  tableData,
  processoState,
  blockTable,
) => (
  [
    {
      dataField: 'desc_titolo',
      text: 'Profilo di Ruolo',
      classes: 'column-data',
      style: { verticalAlign: 'middle' },
      footer: '',
      footerStyle: () => ({
        borderBottom: 'hidden', borderLeft: 'hidden', borderRight: 'hidden', textAlign: 'center',
      }),
      headerStyle: () => ({
        width: '9%', minWidth: '140px', position: 'relative', border: 'hidden',
      }),
      headerFormatter: (column) => (
        <div style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
        }}
        >
          <div className="h-25" />
          <div className="d-flex align-items-center justify-content-center h-75 p-2 border">
            {column.text}
          </div>
        </div>
      ),
    },
    {
      dataField: 'unita_allocate',
      text: 'Unità allocate',
      classes: 'column-data',
      align: 'center',
      style: { verticalAlign: 'middle' },
      footer: (columnData) => {
        const totale = columnData.reduce((acc, item) => (acc + item), 0);
        setTotaleUnitaAllocate(totale);
        return (totale);
      },
      footerStyle: () => ({
        borderBottom: 'hidden', borderLeft: 'hidden', borderRight: 'hidden', textAlign: 'center',
      }),
      headerStyle: () => ({
        width: '5%', minWidth: '90px', height: '100px', position: 'relative', border: 'hidden',
      }),
      headerFormatter: (column) => (
        <div style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
        }}
        >
          <div className="h-25" />
          <div className="d-flex align-items-center justify-content-center h-75 p-2 border">
            {column.text}
          </div>
        </div>
      ),
    },
    {
      dataField: 'unita_cessazione',
      text: 'Unità in cessazione',
      classes: 'column-data',
      align: 'center',
      style: { verticalAlign: 'middle' },
      footer: (columnData) => {
        const totale = columnData.reduce((acc, item) => (acc + item), 0);
        setTotaleUnitaCessazione(totale);
        return (totale);
      },
      footerStyle: () => ({
        borderBottom: 'hidden', borderLeft: 'hidden', borderRight: 'hidden', textAlign: 'center',
      }),
      headerStyle: () => ({
        width: '5%', minWidth: '90px', height: '100px', position: 'relative', borderLeft: 'hidden', borderTop: 'hidden', borderBottom: 'hidden',
      }),
      headerFormatter: (column) => (
        <div style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
        }}
        >
          <div className="h-25" />
          <div className="d-flex align-items-center justify-content-center h-75 p-2 border">
            {column.text}
          </div>
        </div>
      ),
    },
    {
      dataField: 'unita_richieste',
      text: 'Unità richieste',
      classes: 'column-data',
      align: 'center',
      style: { verticalAlign: 'middle' },
      footer: (columnData) => {
        const totale = columnData.reduce((acc, item) => (acc + item), 0);
        setTtotaleUnitaRichieste(totale);
        return (totale);
      },
      footerStyle: () => ({
        borderBottom: 'hidden', borderLeft: 'hidden', borderRight: 'hidden', textAlign: 'center',
      }),
      headerStyle: () => ({ minWidth: '90px', position: 'relative', border: 'hidden' }),
      headerFormatter: (column) => (
        <div
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
          }}
        >
          <div className="d-flex justify-content-end align-items-center h-25 text-end border-start border-top border-bottom" />
          <div className="d-flex align-items-center h-75 p-2 border-end border-bottom">
            {column.text}
          </div>
        </div>
      ),
    },
    {
      dataField: 'ipotesi_copertura',
      text: 'Possibili ipotesi di copertura',
      classes: 'column-data',
      style: { verticalAlign: 'middle' },
      footer: '',
      footerStyle: () => ({
        borderBottom: 'hidden', borderLeft: 'hidden', borderRight: 'hidden', textAlign: 'center',
      }),
      headerStyle: () => ({ minWidth: '100px', position: 'relative', border: 'hidden' }),
      headerFormatter: (column) => (
        <div style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
        }}
        >
          <div className="d-flex justify-content-end align-items-center h-25 text-center border-top border-bottom">
            Da Richiesta
          </div>
          <div className="d-flex align-items-center h-75 p-2 border-end border-bottom">
            {column.text}
          </div>
        </div>
      ),
    },
    {
      dataField: 'motivazioni_richiesta',
      text: 'Motivazioni RPS',
      classes: 'column-data',
      footer: '',
      footerStyle: () => ({
        borderBottom: 'hidden', borderLeft: 'hidden', borderRight: 'hidden', textAlign: 'center',
      }),
      headerStyle: () => ({ minWidth: '300px', position: 'relative', border: 'hidden' }),
      headerFormatter: (column) => (
        <div style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
        }}
        >
          <div className="d-flex align-items-center h-25 border-top border-bottom border-end">
            &nbsp;Fabbisogni SO
          </div>
          <div className="d-flex justify-content-center align-items-center h-75 p-2 border-end border-bottom">
            {column.text}
          </div>
        </div>
      ),
    },
    {
      text: 'Concorsi',
      classes: 'column-data',
      align: 'center',
      style: { verticalAlign: 'middle' },
      footer: '',
      footerStyle: () => ({
        borderBottom: 'hidden', borderLeft: 'hidden', borderRight: 'hidden', textAlign: 'center',
      }),
      headerStyle: () => ({ minWidth: '150px', position: 'relative', border: 'hidden' }),
      headerFormatter: (column) => (
        <div style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
        }}
        >
          <div className="d-flex justify-content-center align-items-center h-25 border-top border-bottom border-end">
            [1]
          </div>
          <div className="d-flex align-items-center justify-content-center h-75 p-2 border-end border-bottom">
            {column.text}
          </div>
        </div>
      ),
      formatter: (e, row) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Form.Control
            placeholder={!blockTable && 'Inserisci valore'}
            className="border-custom"
            defaultValue={
              tableData.filter(
                (el) => el._id === row._id,
              )[0].scelte_copertura.find((copertura) => copertura.ordine === 1)?.unita
            }
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                event.target.blur();
              }
            }}
            name="1"
            type="number"
            min={0}
            disabled={blockTable}
          />
        </div>
      ),
    },
    {
      dataField: 'mobilita_esterna',
      text: 'Mobilità esterna',
      classes: 'column-data',
      align: 'center',
      style: { verticalAlign: 'middle' },
      footer: '',
      footerStyle: () => ({
        borderBottom: 'hidden', borderLeft: 'hidden', borderRight: 'hidden', textAlign: 'center',
      }),
      headerStyle: () => ({ minWidth: '150px', position: 'relative', border: 'hidden' }),
      headerFormatter: (column) => (
        <div style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
        }}
        >
          <div className="d-flex justify-content-center align-items-center h-25 border-top border-bottom border-end">
            [2]
          </div>
          <div className="d-flex align-items-center h-75 p-2 border-end border-bottom">
            {column.text}
          </div>
        </div>
      ),
      formatter: (e, row) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Form.Control
            placeholder={!blockTable && 'Inserisci valore'}
            className="border-custom"
            defaultValue={
              tableData.filter(
                (el) => el._id === row._id,
              )[0].scelte_copertura.find((copertura) => copertura.ordine === 2)?.unita
            }
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                event.target.blur();
              }
            }}
            name="2"
            type="number"
            min={0}
            disabled={blockTable}
          />
        </div>
      ),
    },
    {
      dataField: 'stabilizzazioni',
      text: 'Stabilizzazioni',
      classes: 'column-data',
      align: 'center',
      style: { verticalAlign: 'middle' },
      footer: '',
      footerStyle: () => ({
        borderBottom: 'hidden', borderLeft: 'hidden', borderRight: 'hidden', textAlign: 'center',
      }),
      headerStyle: () => ({ minWidth: '150px', position: 'relative', border: 'hidden' }),
      headerFormatter: (column) => (
        <div style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
        }}
        >
          <div className="d-flex justify-content-center align-items-center h-25 border-top border-bottom border-end">
            [3]
          </div>
          <div className="d-flex justify-content-center align-items-center h-75 p-2 border-end border-bottom">
            {column.text}
          </div>
        </div>
      ),
      formatter: (e, row) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Form.Control
            placeholder={!blockTable && 'Inserisci valore'}
            className="border-custom"
            defaultValue={
              tableData.filter(
                (el) => el._id === row._id,
              )[0].scelte_copertura.find((copertura) => copertura.ordine === 3)?.unita
            }
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                event.target.blur();
              }
            }}
            name="3"
            type="number"
            min={0}
            disabled={blockTable}
          />
        </div>
      ),
    },
    {
      dataField: 'ricorso_forme_flessibili_lavoro',
      text: 'Lavoro flessibile',
      classes: 'column-data',
      align: 'center',
      style: { verticalAlign: 'middle' },
      footer: '',
      footerStyle: () => ({
        borderBottom: 'hidden', borderLeft: 'hidden', borderRight: 'hidden', textAlign: 'center',
      }),
      headerStyle: () => ({ minWidth: '150px', position: 'relative', border: 'hidden' }),
      headerFormatter: (column) => (
        <div style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
        }}
        >
          <div className="d-flex justify-content-center align-items-center h-25 border-top border-bottom border-end">
            [4]
          </div>
          <div className="d-flex justify-content-center align-items-center h-75 p-2 border-end border-bottom">
            {column.text}
          </div>
        </div>
      ),
      formatter: (e, row) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Form.Control
            placeholder={!blockTable && 'Inserisci valore'}
            className="border-custom"
            defaultValue={
              tableData.filter(
                (el) => el._id === row._id,
              )[0].scelte_copertura.find((copertura) => copertura.ordine === 4)?.unita
            }
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                event.target.blur();
              }
            }}
            name="4"
            type="number"
            min={0}
            disabled={blockTable}
          />
        </div>
      ),
    },
    {
      dataField: 'meccanismi_progressione',
      text: 'Progressione interna',
      classes: 'column-data',
      align: 'center',
      style: { verticalAlign: 'middle' },
      footer: '',
      footerStyle: () => ({
        borderBottom: 'hidden', borderLeft: 'hidden', borderRight: 'hidden', textAlign: 'center',
      }),
      headerStyle: () => ({ minWidth: '150px', position: 'relative', border: 'hidden' }),
      headerFormatter: (column) => (
        <div style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
        }}
        >
          <div className="d-flex justify-content-center align-items-center h-25 border-top border-bottom border-end">
            [5]
          </div>
          <div className="d-flex justify-content-center align-items-center h-75 p-2 border-end border-bottom">
            {column.text}
          </div>
        </div>
      ),
      formatter: (e, row) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Form.Control
            placeholder={!blockTable && 'Inserisci valore'}
            className="border-custom"
            defaultValue={
              tableData.filter(
                (el) => el._id === row._id,
              )[0].scelte_copertura.find((copertura) => copertura.ordine === 5)?.unita
            }
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                event.target.blur();
              }
            }}
            name="5"
            type="number"
            min={0}
            disabled={blockTable}
          />
        </div>
      ),
    },
    {
      dataField: 'mobilita_interna',
      text: 'Mobilità interna',
      classes: 'column-data',
      align: 'center',
      style: { verticalAlign: 'middle' },
      footer: '',
      footerStyle: () => ({
        borderBottom: 'hidden', borderLeft: 'hidden', borderRight: 'hidden', textAlign: 'center',
      }),
      headerStyle: () => ({ minWidth: '150px', position: 'relative', border: 'hidden' }),
      headerFormatter: (column) => (
        <div style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
        }}
        >
          <div className="d-flex justify-content-center align-items-center h-25 border-top border-bottom border-end">
            [6]
          </div>
          <div className="d-flex justify-content-center align-items-center h-75 p-2 border-end border-bottom">
            {column.text}
          </div>
        </div>
      ),
      formatter: (e, row) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Form.Control
            placeholder={!blockTable && 'Inserisci valore'}
            className="border-custom"
            defaultValue={
              tableData.filter(
                (el) => el._id === row._id,
              )[0].scelte_copertura.find((copertura) => copertura.ordine === 6)?.unita
            }
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                event.target.blur();
              }
            }}
            name="6"
            type="number"
            min={0}
            disabled={blockTable}
          />
        </div>
      ),
    },
    {
      dataField: 'job_enlargement',
      text: 'Revisione profili',
      classes: 'column-data',
      align: 'center',
      style: { verticalAlign: 'middle' },
      footer: '',
      footerStyle: () => ({
        borderBottom: 'hidden', borderLeft: 'hidden', borderRight: 'hidden', textAlign: 'center',
      }),
      headerStyle: () => ({ minWidth: '150px', position: 'relative', border: 'hidden' }),
      headerFormatter: (column) => (
        <div style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
        }}
        >
          <div className="d-flex justify-content-center align-items-center h-25 border-top border-bottom border-end">
            [7]
          </div>
          <div className="d-flex justify-content-center align-items-center h-75 p-2 border-end border-bottom">
            {column.text}
          </div>
        </div>
      ),
      formatter: (e, row) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Form.Control
            placeholder={!blockTable && 'Inserisci valore'}
            className="border-custom"
            defaultValue={
              tableData.filter(
                (el) => el._id === row._id,
              )[0].scelte_copertura.find((copertura) => copertura.ordine === 7)?.unita
            }
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                event.target.blur();
              }
            }}
            name="7"
            type="number"
            min={0}
            disabled={blockTable}
          />
        </div>
      ),
    },
    {
      dataField: 'riqualificazione_funzionale',
      text: 'Riqualificazione funzionale',
      classes: 'column-data',
      align: 'center',
      style: { verticalAlign: 'middle' },
      footer: '',
      footerStyle: () => ({
        borderBottom: 'hidden', borderLeft: 'hidden', borderRight: 'hidden', textAlign: 'center',
      }),
      headerStyle: () => ({ minWidth: '150px', position: 'relative', border: 'hidden' }),
      headerFormatter: (column) => (
        <div style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
        }}
        >
          <div className="d-flex justify-content-center align-items-center h-25 border-top border-bottom border-end">
            [8]
          </div>
          <div className="d-flex justify-content-center align-items-center h-75 p-2 border-end border-bottom">
            {column.text}
          </div>
        </div>
      ),
      formatter: (e, row) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Form.Control
            placeholder={!blockTable && 'Inserisci valore'}
            className="border-custom"
            defaultValue={
              tableData.filter(
                (el) => el._id === row._id,
              )[0].scelte_copertura.find((copertura) => copertura.ordine === 8)?.unita
            }
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                event.target.blur();
              }
            }}
            name="8"
            type="number"
            min={0}
            disabled={blockTable}
          />
        </div>
      ),
    },
    {
      dataField: 'unita_programmate',
      text: 'Unità programmate',
      classes: 'column-data',
      align: 'center',
      style: (e, row) => ({
        verticalAlign: 'middle',
        backgroundColor: `${(calcolaUnitaProgrammate(row) > row.unita_richieste) ? '#f5c000' : ''}`,
        color: `${(calcolaUnitaProgrammate(row) > row.unita_richieste) ? 'white' : ''}`,
      }),
      footer: calcolaTotaleUnitaProgrammate(),
      footerStyle: () => ({
        borderLeft: 'hidden', borderRight: 'hidden', borderBottom: 'hidden', textAlign: 'center',
      }),
      headerStyle: () => ({ minWidth: '120px', position: 'relative', border: 'hidden' }),
      headerFormatter: (column) => (
        <div style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
        }}
        >
          <div className="d-flex justify-content-center align-items-center h-25 border-bottom" />
          <div className="d-flex justify-content-center align-items-center h-75 p-2 border-end border-bottom">
            {column.text}
          </div>
        </div>
      ),
      formatter: (e, row) => (
        <>
          {calcolaUnitaProgrammate(row, true)}
          {(calcolaUnitaProgrammate(row) > row.unita_richieste) && (
          <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={(
              <Tooltip id="button-tooltip">
                Sono state programmate più unità rispetto a quelle richieste
              </Tooltip>
)}
          >
            <FontAwesomeIcon icon="fa-solid fa-circle-info" className="ms-1" size="lg" aria-label="Apri tooltip" />
          </OverlayTrigger>
          )}
        </>
      ),
    },
    {
      dataField: 'costo_totale_opzioni_copertura',
      text: 'Costo totale delle opzioni di copertura scelte',
      classes: 'column-data',
      align: 'center',
      style: { verticalAlign: 'middle' },
      footer: `${formatCentinaia(calcolaTotaleCostiInTable())}€`,
      footerStyle: () => ({
        borderLeft: 'hidden', borderRight: 'hidden', borderBottom: 'hidden', textAlign: 'center',
      }),
      headerStyle: () => ({ minWidth: '150px', position: 'relative', border: 'hidden' }),
      headerFormatter: (column) => (
        <div style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
        }}
        >
          <div className="d-flex justify-content-center align-items-center h-25 border-bottom" />
          <div className="d-flex justify-content-center align-items-center h-75 p-2 border-end border-bottom">
            {column.text}
          </div>
        </div>
      ),
      formatter: (e, row) => (
        `${formatCentinaia((calcolaCostoInTable(row)))}€`
      ),
    },
  ]
);

export default fieldsFullShowScelteCopertura;
