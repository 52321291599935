/* eslint-disable react/destructuring-assignment */
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

function ModaleRifiutoRichiesta({
  richiestaIdentita,
  onChange,
  evasione,
  handleStateModale,
  show,
}) {
  return (
    <Modal
      role="dialog"
      size="lg"
      centered
      show={show}
      onHide={handleStateModale}
    >
      <Modal.Header closeButton closeLabel="Chiudi">
        <Modal.Title id="contained-modal-title-vcenter">
          Rifiuto richiesta
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group className="mb-3" controlId="desc_msg_risposta">
          <Form.Label>Inserisci il motivo di rifiuto della richiesta</Form.Label>
          <Form.Control type="text" placeholder="Inserisci risposta" value={richiestaIdentita.desc_msg_risposta} onChange={onChange} />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="grey" onClick={handleStateModale}>Chiudi</Button>
        <Button variant="primary" onClick={evasione}>Rifiuta</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModaleRifiutoRichiesta;
