import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useEffect, useState } from 'react';
import {
  Row, Button, Col,
} from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import Title from '../../../../components/GestisciUtenti/TitoloPagina';
import ModaleConferma from '../../../../components/modali/ModaleConferma';
import FormRichiestaIdentita from '../../../../components/RichiesteIdentita/Form';
import richiestIdentitaService from '../../../../services/richiestaIdentitaService';
import { Context } from '../../../../utils/Context';
import history from '../../../../utils/history';

function ModificaRichiestaIdentitaPerUtente() {
  const { idRichiesta } = useParams();
  const [context, setContext] = useContext(Context);
  const [richiestaIdentita, setRichiestaIdentita] = useState({});
  const [openCloseModaleConferma, setOpenCloseModaleConferma] = useState(false);
  const [openCloseModaleConfermaInvio, setOpenCloseModaleConfermaInvio] = useState(false);
  const [options, setOptions] = useState([]);
  const [stati, setStati] = useState([]);
  function handleStateModaleConferma() {
    setOpenCloseModaleConferma(!openCloseModaleConferma);
  }

  function handleStateModaleConfermaInvio() {
    setOpenCloseModaleConfermaInvio(!openCloseModaleConfermaInvio);
  }

  async function modificaRichiesta() {
    richiestIdentitaService.salvaRichiesta(richiestaIdentita)
      .then((res) => {
        setContext(() => ({
          ...context, openConferma: true, testo: res.message,
        }));
        history.push('/area-riservata/richiesta-identita');
      })
      .catch((err) => {
        setOpenCloseModaleConferma();
        setContext(() => ({
          ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
        }));
      });
  }

  async function inviaRichiesta() {
    richiestIdentitaService.inviaRichiesta(richiestaIdentita.sequ_k_richiesta)
      .then((res) => {
        setContext(() => ({
          ...context, openConferma: true, testo: res.message,
        }));
        history.push('/area-riservata/richiesta-identita');
      })
      .catch((err) => {
        setOpenCloseModaleConferma();
        setContext(() => ({
          ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
        }));
      });
  }

  async function getRichiestaIdentita() {
    const res = await richiestIdentitaService.getRichiestaIdentitaById(idRichiesta);
    setRichiestaIdentita(res);
    if (res.codi_ipa) {
      const dettaglioIpa = await richiestIdentitaService
        .getIpaByCodi(res.codi_ipa);
      setOptions([{ cod_amm: res.codi_ipa, des_amm: dettaglioIpa.des_amm }]);
    }
  }

  async function onChange(e, config) {
    // Verifica se è una Select di react-select
    if (config) {
      setRichiestaIdentita({
        ...richiestaIdentita,
        [config.name]: e?.cod_amm,
      });
    } else {
      const { value } = e.target;
      const { id } = e.target;
      setRichiestaIdentita({
        ...richiestaIdentita,
        [id]: value,
      });
    }
  }

  function campiCompilatiPdf() {
    if (richiestaIdentita.desc_email && richiestaIdentita.codi_ipa
      && richiestaIdentita.desc_nome_responsabile && richiestaIdentita.desc_cognome_responsabile
      && richiestaIdentita.desc_cf_responsabile && richiestaIdentita.desc_qualifica_richiedente
      && richiestaIdentita.desc_qualifica_responsabile) {
      return true;
    }
    return false;
  }

  function campiCompilatiInvio() {
    if (richiestaIdentita.desc_email && richiestaIdentita.codi_ipa
      && richiestaIdentita.desc_nome_responsabile && richiestaIdentita.desc_cognome_responsabile
      && richiestaIdentita.desc_cf_responsabile && richiestaIdentita.codi_pdf_minio
      && richiestaIdentita.desc_qualifica_richiedente
      && richiestaIdentita.desc_qualifica_responsabile) {
      return true;
    }
    return false;
  }

  async function getStatiRichieste() {
    try {
      setStati(await richiestIdentitaService.getStati());
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  useEffect(() => {
    getRichiestaIdentita();
    getStatiRichieste();
  }, []);

  return (
    <Row id="form-richiesta-identita">
      <Row>
        <Title
          title="Modifica richiesta di accreditamento"
          subtitle="È possibile modificare una richiesta precedentemente salvata"
          showIndietro
          functionIndietro={() => history.push('/area-riservata/richiesta-identita')}
        />
      </Row>
      <FormRichiestaIdentita
        richiestaIdentita={richiestaIdentita}
        context={context}
        setContext={setContext}
        onChange={onChange}
        campiCompilati={campiCompilatiPdf}
        setRichiestaIdentita={setRichiestaIdentita}
        disabled={!!richiestaIdentita.codi_pdf_minio}
        isModify
        stati={stati}
        options={options}
        setOptions={setOptions}
      />
      <Row>
        <Col className="d-flex justify-content-end">
          <Button variant="primary" className="text-uppercase me-3" onClick={handleStateModaleConferma}>
            <FontAwesomeIcon aria-hidden="true" icon="save" />
            {' '}
            Salva
          </Button>
          <ModaleConferma
            labelBottone="Conferma"
            coloreAzione="primary"
            title="Sei sicuro di procedere?"
            body={'L\'operazione salverà la richiesta di accreditamento'}
            show={openCloseModaleConferma}
            handleClose={handleStateModaleConferma}
            handleOk={() => modificaRichiesta(false)}
          />
          {campiCompilatiInvio() && (
          <>
            <Button variant="primary" className="text-uppercase" onClick={handleStateModaleConfermaInvio}>
              <FontAwesomeIcon aria-hidden="true" icon="arrow-right" />
              {' '}
              Invia
            </Button>
            <ModaleConferma
              labelBottone="Conferma"
              coloreAzione="primary"
              title="Sei sicuro di procedere?"
              body={'L\'operazione invierà la richiesta di accreditamento e non sarà più possibile modificarla'}
              show={openCloseModaleConfermaInvio}
              handleClose={handleStateModaleConfermaInvio}
              handleOk={inviaRichiesta}
            />
          </>
          )}
        </Col>
      </Row>
    </Row>
  );
}

export default ModificaRichiestaIdentitaPerUtente;
