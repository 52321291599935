import * as date from 'date-fns';

export const formatoDataEOra = (data) => {
  let dataFormattata = null;
  try {
    if (data) { dataFormattata = date.format(new Date(data), 'dd/MM/yyyy HH:mm'); } else { dataFormattata = '-'; }
    return (dataFormattata.endsWith(' 00:00 ')) ? dataFormattata.substring(0, 10) : dataFormattata;
  } catch (e) {
    // gestione safari che non parsa le Date
    try {
      dataFormattata = date.format(date.parseISO(data), 'dd/MM/yyyy HH:mm');
      return (dataFormattata.endsWith(' 00:00 ')) ? dataFormattata.substring(0, 10) : dataFormattata;
    } catch (e1) {
      return 'errore';
    }
  }
};

export const formatoData = (data) => {
  let dataFormattata = null;
  try {
    if (data) { dataFormattata = date.format(new Date(data), 'dd/MM/yyyy'); } else { dataFormattata = '-'; }
    return (dataFormattata.endsWith(' 00:00')) ? dataFormattata.substring(0, 10) : dataFormattata;
  } catch (e) {
    // gestione safari che non parsa le Date
    try {
      dataFormattata = date.format(date.parseISO(data), 'dd/MM/yyyy');
      return (dataFormattata.endsWith(' 00:00')) ? dataFormattata.substring(0, 10) : dataFormattata;
    } catch (e1) {
      return 'errore';
    }
  }
};

export const formatForBusiness = (data) => {
  const ora = new Date(data).getUTCHours();
  const dataNuova = new Date(data).setUTCHours(ora - 2);
  if (data) { return date.format(new Date(dataNuova), 'yyyy-MM-dd kk:mm:ss'); }
  return null;
};

export const formatForBusinessDate = (data) => {
  const ora = new Date(data).getUTCHours();
  const dataNuova = new Date(data).setUTCHours(ora - 2);
  if (data) { return date.format(new Date(dataNuova), "yyyy-MM-dd'T'HH:mm"); }
  return null;
};

export const toDate = (data) => {
  try {
    if (data) {
      const dateRet = new Date(data);
      if (dateRet.getTime()) {
        return dateRet;
      }
      return date.parseISO(data);
    }
    return null;
  } catch (e) {
    return null;
  }
};

// Function per aggiungere giorni alla data attuale
export function addTodaysDate(days) {
  const today = new Date();
  const result = today.setDate(today.getDate() + days);

  return date.format(result, 'yyyy-MM-dd');
}

// Function per formattare la data per colloqui assessment
export const formatColloquioDate = (data) => {
  const utcDate = new Date(data);
  const dataNuova = new Date(utcDate.getTime() + utcDate.getTimezoneOffset() * 60000);
  if (data) { return date.format(dataNuova, "yyyy-MM-dd'T'HH:mm"); }
  return null;
};
