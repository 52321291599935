import { Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import optionsTipoCompetenza from './constants/optionsTipoCompetenza';
import CustomSelect from '../../../components/Select/CustomSelect';

function FormCompetenza({
  stateCompetenza,
  onChange,
  disabled = false,
  optionsAreeCompetenze,
  errors,
}) {
  return (
    <Form.Group as={Col} lg controlId="competenza">
      <Form.Label>
        Titolo Competenza
      </Form.Label>
      <Form.Control
        name="desc_titolo"
        value={stateCompetenza.desc_titolo}
        placeholder="Inserisci il titolo"
        onChange={onChange}
        maxLength={100}
        required
        disabled={disabled}
        isInvalid={errors?.desc_titolo}
      />
      {errors?.desc_titolo && (
      <Form.Control.Feedback type="invalid">
        *Campo Nome obbligatorio
      </Form.Control.Feedback>
      )}
      <Form.Label>
        Descrizione Competenza
      </Form.Label>
      <Form.Control
        id="desc_descrizione"
        name="desc_descrizione"
        value={stateCompetenza.desc_descrizione}
        placeholder="Inserisci la descrizione"
        onChange={onChange}
        as="textarea"
        rows={3}
        required
        disabled={disabled}
        isInvalid={errors?.desc_descrizione}
      />
      {errors?.desc_descrizione && (
      <Form.Control.Feedback id="desc_descrizione" type="invalid">
        *Campo Descrizione obbligatorio
      </Form.Control.Feedback>
      )}
      {stateCompetenza.codi_tipo_competenza === 'CC' && (
        <>
          <Form.Label>
            Descrittori Comportamentali
          </Form.Label>
          <Form.Control
            name="desc_descrittore"
            value={stateCompetenza.desc_descrittore}
            placeholder="Inserisci i descrittori comportamentali"
            onChange={onChange}
            as="textarea"
            rows={3}
            required
            disabled={disabled}
          />
        </>
      )}
      <Form.Label>
        Tipo Competenza
      </Form.Label>
      <Select
        id="codi_tipo_competenza"
        name="codi_tipo_competenza"
        options={optionsTipoCompetenza}
        placeholder="Scegli tipo competenza"
        getOptionLabel={(option) => option.desc_tipo_competenza}
        getOptionValue={(option) => option.codi_tipo_competenza}
        value={optionsTipoCompetenza?.filter(
          (el) => el.codi_tipo_competenza === stateCompetenza.codi_tipo_competenza,
        )?.[0]}
        onChange={(selectedOption, config) => { onChange(selectedOption, config); }}
        isDisabled
      />
      <Form.Label>
        Area delle Competenze
      </Form.Label>
      <CustomSelect
        id="fk_sequ_area_competenze"
        name="fk_sequ_area_competenze"
        options={optionsAreeCompetenze}
        placeholder="Scegli area delle competenze"
        getOptionLabel={(option) => option.desc_descrizione}
        getOptionValue={(option) => option.sequ_k_area_competenze}
        value={optionsAreeCompetenze?.filter(
          (el) => el.sequ_k_area_competenze === stateCompetenza.fk_sequ_area_competenze,
        )?.[0]}
        onChange={(selectedOption, config) => { onChange(selectedOption, config); }}
        isDisabled={disabled}
        isClearable
      />
    </Form.Group>
  );
}

export default FormCompetenza;
