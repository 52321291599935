import { formatoData } from '../../../../utils/formatData';

const fieldsStoricoProfili = () => ([
  {
    dataField: 'desc_titolo',
    text: 'Profilo',
    classes: 'column-data',
    headerStyle: () => ({ width: '60%' }),
  },
  {
    dataField: 'data_inizio_validita',
    text: 'Data inizio validità',
    classes: 'column-data',
    headerStyle: () => ({ width: '20%' }),
    formatter: (e, row) => formatoData(row.data_inizio_validita),
  },
  {
    dataField: 'data_fine_validita',
    text: 'Data fine validità',
    classes: 'column-data',
    headerStyle: () => ({ width: '20%' }),
    formatter: (e, row) => (
      row.data_fine_validita
        ? formatoData(row.data_fine_validita)
        : '-'
    ),
  },
]);

export default fieldsStoricoProfili;
