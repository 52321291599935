import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import utenteService from '../../../../../services/utentiService';
import { optionsStatoElaborazione, optionsTipoBatch } from '../constants/optionsBatch';
import { formatoData } from '../../../../../utils/formatData';
import TitlePage from '../../../../../components/GestisciUtenti/TitoloPagina';
import history from '../../../../../utils/history';
import Table from '../../../../../components/Shared/Table';
import EmptyState from '../../../../../components/EmptyState';
import fieldsDettaglioUtentiMassivo from './fieldsTableDettaglio';

function DettaglioUtentiMassivo() {
  const { idBatch } = useParams();
  const [stateBatch, setStateBatch] = useState({});
  const [listaErrori, setListaErrori] = useState([]);

  // Function per la trasformazione del json contenente le informazioni
  // degli errori riscontrati durante il caricamento del file
  const transformData = (data) => {
    if (data) {
      const dataJson = JSON.parse(data);
      return Object.keys(dataJson).map(
        (key) => ({
          idRiga: Number(key) + 1,
          tecnico: dataJson[key].tecnico || [],
          utente: dataJson[key].utente || [],
          identita: dataJson[key].identita || [],
        }),
      );
    }
    return [];
  };

  // Function collegata al servizio per ottenere l'utente in base al CF
  async function getBatchById(id) {
    const response = await utenteService.getBatchById(id);
    setStateBatch(response);
    if (response.desc_esito_utente) {
      setListaErrori(transformData(response.desc_esito_utente));
    }
  }

  // Function useEffect al primo rendering
  useEffect(() => {
    getBatchById(idBatch);
  }, []);

  return (
    <>
      <TitlePage
        title={'Dettaglio caricamento numero '.concat(stateBatch.sequ_k_elaborazione_batch)}
        showIndietro
        functionIndietro={() => history.push(
          '/area-riservata/amministrazione/gestione-utenti-massiva',
        )}
      />
      <Form.Label style={{ fontWeight: 'bold' }}>
        Nome File
      </Form.Label>
      <Form.Control
        name="codi_excel_minio"
        value={stateBatch.codi_excel_minio}
        disabled
      />
      <Form.Label style={{ fontWeight: 'bold' }}>
        Stato Elaborazione
      </Form.Label>
      <Form.Control
        name="codi_stato_elaborazione"
        value={optionsStatoElaborazione?.filter(
          (el) => el.codi_stato_elaborazione === stateBatch.codi_stato_elaborazione,
        )?.[0]?.stato_elaborazione}
        disabled
      />
      <Form.Label style={{ fontWeight: 'bold' }}>
        Tipo Caricamento
      </Form.Label>
      <Form.Control
        name="codi_stato_elaborazione"
        value={optionsTipoBatch?.filter(
          (el) => el.codi_tipo_batch === stateBatch.codi_tipo_batch,
        )?.[0]?.tipo_batch}
        disabled
      />
      <Form.Label style={{ fontWeight: 'bold' }}>
        Data Creazione
      </Form.Label>
      <Form.Control
        name="data_creazione"
        value={formatoData(stateBatch.data_creazione)}
        disabled
      />
      <Form.Label style={{ fontWeight: 'bold' }}>
        Data Avvio Esecuzione
      </Form.Label>
      <Form.Control
        name="data_avvio_esecuzione"
        value={formatoData(stateBatch.data_avvio_esecuzione)}
        disabled
      />
      <Form.Label style={{ fontWeight: 'bold' }}>
        Data Fine Esecuzione
      </Form.Label>
      <Form.Control
        name="data_fine_esecuzione"
        value={formatoData(stateBatch.data_fine_esecuzione)}
        disabled
      />
      {listaErrori.length > 0 ? (
        <Table
          title="Errori riscontrati"
          data={listaErrori}
          fields={fieldsDettaglioUtentiMassivo}
          keyField="ordinamento"
        />
      ) : (
        stateBatch.codi_stato_elaborazione === 3 && (
        <EmptyState
          title="Caricamento avvenuto correttamente"
          subtitle="Non sono stati riscontrati errori durante la lettura del file"
          icon={<FontAwesomeIcon icon="fa-solid fa-circle-check" size="2x" />}
        />
        )
      )}
      {stateBatch.codi_stato_elaborazione === 2 && (
        <EmptyState
          title="Caricamento in corso"
          subtitle="Analisi di eventuali errori in corso"
          icon={<FontAwesomeIcon icon="fa-solid fa-loader" size="2x" />}
        />
      )}
      {stateBatch.codi_stato_elaborazione === 1 && (
        <EmptyState
          title="Caricamento da eseguire"
          subtitle="Non ci sono errori da visualizzare"
        />
      )}
    </>
  );
}

export default DettaglioUtentiMassivo;
