import formatCentinaia from '../../../../../utils/formatCentinaia';

const fieldsProgressioneVerticale = () => [
  {
    dataField: 'nomeDa',
    text: 'PROGRESSIONE DA',
    classes: 'column-data',
    headerStyle: () => ({ width: '25%' }),
  },
  {
    dataField: 'nomeA',
    text: 'A',
    classes: 'column-data',
    headerStyle: () => ({ width: '25%' }),
  },
  {
    dataField: 'differenza',
    text: 'COSTO',
    classes: 'column-data',
    headerStyle: () => ({ width: '25%' }),
    formatter: (e, row) => (
      <div className="float-end">
        {formatCentinaia(row.differenza)}
        €
      </div>
    ),
  },
  {
    dataField: 'costoDa',
    text: 'Risparmio rispetto ad assunzione',
    classes: 'column-data',
    headerStyle: () => ({ width: '25%' }),
    formatter: (e, row) => (
      <div className="float-end">
        {formatCentinaia(row.costoDa)}
        €
      </div>
    ),
  },
];

export default fieldsProgressioneVerticale;
