/* eslint-disable react/no-unknown-property */
import React, { useState } from 'react';
import {
  Button, Col, Collapse,
} from 'react-bootstrap';
import Table from '../../../../components/Shared/Table';
import fieldsStoricoProfili from '../fields/fieldsStoricoProfili';

function StoricoProfili({
  profiliRuoloStorico,
}) {
  const [open, setOpen] = useState(false);

  return (
    <div as={Col}>
      <Button
        onClick={() => setOpen(!open)}
        aria-expanded={open}
        size="sm"
      >
        STORICO ASSEGNAZIONI
      </Button>
      <Collapse in={open}>
        <div>
          <Table
            data={profiliRuoloStorico}
            fields={fieldsStoricoProfili()}
            keyField="desc_titolo"
          />
        </div>
      </Collapse>

    </div>
  );
}

export default StoricoProfili;
