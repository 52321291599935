import React from 'react';
import { Button } from 'react-bootstrap';
import './index.css';

function SimpleButton({
  label,
  alt,
  icon,
  href,
  variant,
  bgColor,
  width,
}) {
  return (
    <Button
      className="simple-button"
      type="button"
      variant={variant ?? 'primary'}
      href={href}
      style={{ backgroundColor: bgColor ?? 'none', width: width ?? '100%' }}
    >
      <div className="d-flex justify-content-flex-start align-items-center">
        {icon && (
          <img width={30} height={30} src={icon} alt={alt ?? label} aria-hidden="true" />
        )}
        <p className="ms-3 mb-0 me-3">{label}</p>
      </div>
    </Button>
  );
}

export default SimpleButton;
