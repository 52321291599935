import { Button, Modal } from 'react-bootstrap';
import './index.css';

// PER RIUTILIZZABILITA': i props vengono definiti come segue:
// modalTitle: rappresenta il TITOLO dell'heading del modale
// modalBody: nome componente da utilizzare/visualizzare all'interno del modale
// buttonType: tipologia del bottone inserito nel modale
// onConfirm: function attivata al click del pulsante di conferma
// labelConfirm: label da visualizzare sul pulsante di conferma
// onClose: function attivata al click del pulsante di chiusura

function ModaleCentroVerticale({
  modalTitle,
  modalBody,
  buttonType,
  onConfirm,
  disabledConfirm = false,
  labelConfirm,
  onClose,
  size,
  modalStyle,
  fullScreen = false,
  ...props
}) {
  return (
    <Modal
      {...props}
      size={fullScreen ? undefined : size || 'lg'}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      fullscreen={fullScreen}
      style={modalStyle}
    >
      <Modal.Header closeButton onHide={onClose}>
        <Modal.Title id="contained-modal-title-vcenter">
          {modalTitle}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {modalBody}
      </Modal.Body>
      <Modal.Footer className="modalFooter">
        <Button
          variant="outline-danger"
          type={buttonType}
          onClick={onClose}
        >
          Chiudi
        </Button>
        {!disabledConfirm && (
        <Button
          variant="outline-primary"
          type={buttonType}
          onClick={onConfirm}
        >
          {labelConfirm}
        </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default ModaleCentroVerticale;
