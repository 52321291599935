/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/control-has-associated-label */
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import valoriQualitativi from '../../data/valoriQualitativi';

function BodyModello({
  data,
  onChange,
  isDisabled,
}) {
  return (
    <tbody style={{ textAlign: 'center', verticalAlign: 'middle' }}>
      {data.map(
        (row, ind) => {
          const criteriQuantitativi = row.criteri.filter(
            (crt) => crt.tipologia === 'Quantitativi',
          );
          const criteriQualitativi = row.criteri.filter(
            (crt) => crt.tipologia === 'Qualitativi',
          );

          return (
            <tr key={ind}>
              <td>{row.struttura_organizzativa}</td>
              {criteriQuantitativi?.map(
                (crt) => (
                  <td key={crt.ordine - 1}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <Form.Control
                        placeholder={!isDisabled && 'Inserisci valore'}
                        className="border-custom"
                        id={`${crt.sequ_k_criterio},${row.struttura_organizzativa}`}
                        type="text"
                        maxLength={15}
                        value={crt.valore_criterio}
                        onChange={(e) => onChange(e)}
                        size="sm"
                        disabled={isDisabled}
                      />
                    </div>
                  </td>
                ),
              )}
              {criteriQualitativi?.map(
                (crt) => (
                  <td key={crt.ordine + 1}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <Select
                        placeholder={!isDisabled && 'Inserisci valore'}
                        className="border-custom"
                        name={`${crt.sequ_k_criterio},${row.struttura_organizzativa}`}
                        options={valoriQualitativi}
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.value}
                        value={valoriQualitativi.find(
                          (val) => val.value === crt.valore_criterio,
                        )}
                        onChange={(selectedOption, config) => { onChange(selectedOption, config); }}
                        isDisabled={isDisabled}
                      />
                    </div>
                  </td>
                ),
              )}
              <td>{row.punteggio_so}</td>
            </tr>
          );
        },
      )}
    </tbody>
  );
}

export default BodyModello;
