import { Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import optionsTipoCompetenza from '../competenze/constants/optionsTipoCompetenza';
import CustomSelect from '../../../components/Select/CustomSelect';

function FormAreaCompetenze({
  stateAreaCompetenze,
  onChange,
  disabled = false,
  isNew = false,
  errors,
}) {
  return (
    <Form.Group as={Col} lg controlId="aree-competenze">
      { !isNew && (
        <>
          <Form.Label>
            Codice
          </Form.Label>
          <Form.Control
            name="desc_codice"
            value={stateAreaCompetenze.desc_codice}
            onChange={onChange}
            maxLength={100}
            disabled
          />
        </>
      )}
      <Form.Label>
        Descrizione
      </Form.Label>
      <Form.Control
        name="desc_descrizione"
        value={stateAreaCompetenze.desc_descrizione}
        placeholder="Inserisci la descrizione"
        onChange={onChange}
        as="textarea"
        rows={3}
        required
        disabled={disabled}
      />
      <Form.Label>
        Tipologia Competenza
      </Form.Label>
      <CustomSelect
        id="codi_tipo_competenza"
        name="codi_tipo_competenza"
        options={optionsTipoCompetenza}
        placeholder="Seleziona la Tipologia Competenza"
        getOptionLabel={(option) => option.desc_tipo_competenza}
        getOptionValue={(option) => option.codi_tipo_competenza}
        value={optionsTipoCompetenza?.filter(
          (el) => el.codi_tipo_competenza === stateAreaCompetenze.codi_tipo_competenza,
        )?.[0]}
        onChange={(selectedOption, config) => { onChange(selectedOption, config); }}
        isClearable={isNew}
        errors={errors}
        errorMessage="* Campo Tipologia Competenza obbligatorio"
        isDisabled={disabled}
      />
    </Form.Group>
  );
}

export default FormAreaCompetenze;
