import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './index.css';
import logoEu from '../../images/logo-eu.png';
import logoDfp from '../../images/funzione-pubblica.svg';
import logoPnrr from '../../images/logo-pnrr.png';
import logoFormez from '../../images/logo-formez.png';

function FooterHtml() {
  return (
    <footer id="footer">
      <div className="container">
        <section className="footer-logo">
          <div className="row clearfix">
            <div className="col-sm-12 intestazione">
              <div className="logo-long mb-3">
                <img height="70px" className="me-3 mb-3" src={logoEu} alt="Logo Lungo" />
                <img height="70px" className="me-3 mb-3" src={logoDfp} alt="Logo Lungo" />
                <img height="70px" className="me-3 mb-3" src={logoPnrr} alt="Logo Lungo" />
                <img height="70px" className="me-3 mb-3" src={logoFormez} alt="Logo Lungo" />
              </div>
            </div>
          </div>
        </section>
        <section className="lista-linkutili">
          <div className="row">
            <div className="col-lg-3 col-md-3 col-sm-6 footer-contatti">
              <h1>
                CONTATTI
              </h1>
              <p>
                <strong>Ministro per la Pubblica amministrazione</strong>
                <br />
                Corso Vittorio Emanuele II - 00186 Roma
              </p>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 footer-contatti">
              <h1>
                CONTATTI
              </h1>
              <p>
                <strong>Dipartimento della Funzione Pubblica</strong>
                <br />
                Corso Vittorio Emanuele II - 00186 Roma
              </p>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 footer-contatti">
              <h1>
                CONTATTI
              </h1>
              <p>
                <strong>Informazioni</strong>
                <br />
                <a href="mailto:progettoriva@formez.it">progettoriva@formez.it</a>
              </p>
              <p>
                <strong>FAQ</strong>
                <br />
                <Link to="/faq" title="Vai alla pagina: FAQ - Domande e risposte">FAQ - Domande e risposte</Link>
              </p>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 footer-seguici">
              <h1> SEGUICI SU </h1>
              <ul className="list-inline text-start social">
                <li className="list-inline-item">
                  <a
                    href="https://www.facebook.com/DipartimentoFunzionePubblica"
                    target="_blank"
                    title="Collegamento a sito esterno - Facebook - si apre una nuova finestra"
                    rel="noreferrer"
                    aria-label="Facebook"
                  >
                    <FontAwesomeIcon aria-hidden="true" icon={['fab', 'facebook']} size="lg" />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    href="https://twitter.com/FunzPub"
                    target="_blank"
                    title="Collegamento a sito esterno - Twitter - si apre una nuova finestra"
                    rel="noreferrer"
                    aria-label="Twitter"
                  >
                    <FontAwesomeIcon aria-hidden="true" icon={['fab', 'x-twitter']} size="lg" />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    href="https://www.instagram.com/dipartimento_funzione_pubblica/"
                    target="_blank"
                    title="Collegamento a sito esterno - Instagram - si apre una nuova finestra"
                    rel="noreferrer"
                    aria-label="Instagram"
                  >
                    <FontAwesomeIcon aria-hidden="true" icon={['fab', 'instagram']} size="lg" color="white" />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    href="https://www.youtube.com/channel/UCIGLJG5C6ghZp-V-AgWwGCg"
                    target="_blank"
                    title="Collegamento a sito esterno - Youtube - si apre una nuova finestra"
                    rel="noreferrer"
                    aria-label="YouTube"
                  >
                    <FontAwesomeIcon aria-hidden="true" icon={['fab', 'youtube']} size="lg" />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    href="https://it.linkedin.com/company/dipartimento-della-funzione-pubblica"
                    target="_blank"
                    title="Collegamento a sito esterno - LinkedIn - si apre una nuova finestra"
                    rel="noreferrer"
                    aria-label="LinkedIn"
                  >
                    <FontAwesomeIcon aria-hidden="true" icon={['fab', 'linkedin']} size="lg" />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    href="https://web.telegram.org/#/im?p=@funzionepubblica"
                    target="_blank"
                    title="Collegamento a sito esterno - Telegram - si apre una nuova finestra"
                    rel="noreferrer"
                    aria-label="Telegram"
                  >
                    <FontAwesomeIcon aria-hidden="true" icon={['fab', 'telegram']} size="lg" color="white" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </section>
        <div className="postFooter clearfix">
          <div className="link_piede_footer">
            <a
              href="https://www.lavoropubblico.gov.it/privacy-policy"
              target="_blank"
              title="Collegamento a sito esterno - Privacy policy"
              rel="noreferrer"
              aria-label="Vai alla pagina: Privacy policy"
            >
              Privacy policy
            </a>
            |
            <a
              href="https://www.lavoropubblico.gov.it/note-legali"
              target="_blank"
              title="Collegamento a sito esterno - Note legali"
              rel="noreferrer"
              aria-label="Vai alla pagina: Note legali"
            >
              Note legali
            </a>
            |
            <Link
              to="/dichiarazione-accessibilita"
              title="Vai alla pagina: Dichiarazione di accessibilità"
              aria-label="Vai alla pagina: Dichiarazione di accessibilità"
            >
              Dichiarazione di accessibilità
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default FooterHtml;
