/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React from 'react';

export const customTotal = (from, to, size) => (
  <span
    className="react-bootstrap-table-pagination-total"
    style={{ fontSize: '0.875rem' }}
  >
    {' '}
    {' '}
    Visualizzati dal
    {' '}
    {from < 0 ? 1 : from}
    {' '}
    al
    {' '}
    {to === 0 ? size <= 10 ? size : 10 : to}
    {' '}
    di
    {' '}
    {size}
    {' '}
    Elementi
  </span>
);

const sizePerPageRenderer = ({
  options,
  currSizePerPage,
  onSizePerPageChange,
}) => (
  <div className="btn-group btn-group-sm" role="group">
    {
      options.map((option) => {
        const isSelect = currSizePerPage === `${option.page}`;
        return (
          <button
            key={option.text}
            type="button"
            onClick={() => onSizePerPageChange(option.page)}
            className={`btn ${isSelect ? 'btn-primary' : 'btn-outline-secondary'}`}
            style={{ padding: '5px 6px' }}
          >
            { option.text }
          </button>
        );
      })
    }
  </div>
);

const pageButtonRenderer = ({
  page,
  active,
  disable,
  title,
  onPageChange,
}) => {
  const handleClick = (e) => {
    e.preventDefault();
    onPageChange(page);
  };

  return (
    <div className="justify-content-end">
      <li
        className={active
          ? 'active page-item border border-primary rounded-1 bg-primary'
          : disable ? 'disable page-item border border-primary rounded-1'
            : 'page-item border border-primary rounded-1'}
        title={title}
        style={{ padding: '3px 8px', margin: '0 2px' }}
        key={page}
      >
        <a
          href="#"
          onClick={handleClick}
          className={active ? 'text-white' : null}
          style={{ fontSize: '0.875rem' }}
        >
          {' '}
          { page }
        </a>
      </li>
    </div>
  );
};

export const options = {
  // paginationSize: 5,
  pageStartIndex: 1,
  sizePerPage: 10,
  sizePerPageList: [10, 20, 30, 40, 50],
  alwaysShowAllBtns: true,
  hidePageListOnlyOnePage: true,
  firstPageText: 'Prima',
  prePageText: 'Prec.',
  nextPageText: 'Succ.',
  lastPageText: 'Ultima',
  nextPageTitle: 'Pagina Successiva',
  prePageTitle: 'Pagina Precedente',
  firstPageTitle: 'Prima Pagina',
  lastPageTitle: 'Ultima Pagina',
  showTotal: true,
  paginationTotalRenderer: customTotal,
  sizePerPageRenderer,
  pageButtonRenderer,
};
