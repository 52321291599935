function InfoModelli({
    modelliList,
    dataInfo,
}) {
    return (
        <div>
            <ol>
                {dataInfo.map(
                    (modello, index) => {

                        const criteriQuantitativi = modello.criteri.filter(
                            (el) => el.tipologia === 'Quantitativi'
                        );
                        const criteriQualitativi = modello.criteri.filter(
                            (el) => el.tipologia === 'Qualitativi'
                        );
                        
                        return (
                            <div key={index} style={{ marginBottom: '20px' }}>
                                <li>
                                    <h4>
                                        {modelliList.filter(
                                        (el) => el.sequ_k_modello === modello.sequ_k_modello)[0].desc_nome}
                                    </h4>
                                </li>
                                {criteriQuantitativi.length > 0 && (
                                    <>
                                    <h5>Quantitativi</h5>
                                        <ul>
                                            {criteriQuantitativi.map(
                                                (criterio, idx) => (
                                                    <li key={idx}>
                                                        {criterio.criterio} - <b>{criterio.valore_percentuale}%</b>
                                                    </li>
                                                )
                                            )}
                                        </ul>
                                    </>
                                )}
                                {criteriQualitativi.length > 0 && (
                                    <>
                                        <h5>Qualitativi</h5>
                                        <ul>
                                            {criteriQualitativi.map(
                                                (criterio, idx) => (
                                                    <li key={idx}>
                                                        {criterio.criterio} - <b>{criterio.valore_percentuale}%</b>
                                                    </li>
                                                )
                                            )}
                                        </ul>
                                    </>
                                )}
                        </div>    
                    )}
                )}
            </ol>
        </div>
    );
};

export default InfoModelli;