import React, { useContext, useEffect, useState } from 'react';
import {
  Accordion, Col, Container, ListGroup, Row,
} from 'react-bootstrap';
import faqService from '../../services/faqService';
import { Context } from '../../utils/Context';
import BreadcrumbComponent from '../../components/Breadcrumb';
import RenderHTML from '../../components/Shared/RenderHTML';
import './index.css';
import FiltriRicercaFaq from '../../components/Faq/FiltriRicerca';
import trimContent from '../../utils/trimContent';
import EmptyState from '../../components/EmptyState';

function FaqPubblica() {
  const [context, setContext] = useContext(Context);
  const [listaFaq, setListaFaq] = useState([]);
  const [argomenti, setArgomenti] = useState([]);
  const [showMore, setShowMore] = useState({});
  const [filtri, setFiltri] = useState({
    testo: '',
    profonditaStorica: false,
  });

  // Function collegata al servizio per ottenere la lista delle FAQ
  async function getListaFaqPubbliche(offset, limit) {
    window.scrollTo({ behavior: 'smooth', top: '0px' });
    try {
      const response = await faqService.getListaFaqPubbliche(filtri, offset, limit);
      setListaFaq(response?.listaFaq || []);
      const argomentiTemp = [];
      response?.listaFaq.forEach((faq) => {
        if (!argomentiTemp.includes(faq.argomento)) {
          argomentiTemp.push(faq.argomento);
        }
      });
      setArgomenti(argomentiTemp);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // useEffect
  useEffect(() => {
    getListaFaqPubbliche(0, 1000);
  }, []);

  // fucntion per la gestione dei filtri
  function onChangeFiltri(e, type) {
    const { value, id, checked } = e.target;
    setFiltri({
      ...filtri,
      [id]: type === 'checkbox' ? checked : value,
    });
  }

  return (
    <Container className="justify-content-center col-md-10">
      <Row xs={12}>
        <BreadcrumbComponent listItem={[{ name: 'FAQ', pathname: '/faq', active: true }]} />
      </Row>
      <Row className="justify-content-center">
        <Col className="my-3">
          <h1>FAQ - Domande e risposte</h1>
        </Col>
        <Col xs={12} className="mb-3">
          <FiltriRicercaFaq
            filtri={filtri}
            getListaFaq={getListaFaqPubbliche}
            onChange={onChangeFiltri}
          />
        </Col>
        {argomenti.length > 0 ? (
          <Accordion>
            {argomenti.map((argomento, index) => (
              <Accordion.Item className="my-3 border accordionItem" eventKey={index} key={argomento}>
                <Accordion.Header>{argomento}</Accordion.Header>
                <Accordion.Body className="backgroundColorInterno">
                  {listaFaq.length > 0 && (
                  <ListGroup numbered>
                    {listaFaq.filter((el) => el.argomento === argomento).map((faq) => (
                      <ListGroup.Item
                        key={faq._id}
                        className="d-flex justify-content-between align-items-start border-0 backgroundColorInterno"
                      >
                        <div className="ms-2 me-auto backgroundColorInterno">
                          <div className="fw-bold">{faq.domanda}</div>
                          {faq.risposta.length > 500 ? (
                            <>
                              <RenderHTML
                                html={
                                  showMore[faq._id] ? faq.risposta : trimContent(faq.risposta, 500)
                                }
                              />
                              {showMore[faq._id] ? (
                                <button className="buttonShow btn p-0" type="button" onClick={() => setShowMore({ ...showMore, [faq._id]: false })}>
                                  Riduci
                                </button>
                              ) : (
                                <button className="buttonShow btn p-0" type="button" onClick={() => setShowMore({ ...showMore, [faq._id]: true })}>
                                  Mostra di più...
                                </button>
                              )}
                            </>
                          ) : (
                            <RenderHTML html={faq.risposta} />
                          )}
                        </div>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                  )}
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        ) : (
          <EmptyState subtitle="Nessuna FAQ presente" />
        )}
      </Row>
    </Container>
  );
}

export default FaqPubblica;
