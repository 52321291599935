import { Accordion, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactECharts from 'echarts-for-react';
import { useRef } from 'react';
import EmptyState from '../../EmptyState';

function AccordionCharts({
  eventKey,
  onClick,
  title,
  data,
  optionsChart,
  filtriBody,
  isPrintable = false,
}) {
  const chartRef = useRef(null);
  const handlePrint = () => {
    if (chartRef.current) {
      const chart = chartRef.current.getEchartsInstance();
      const url = chart.getDataURL({
        type: 'jpeg',
        pixelRatio: 2,
        backgroundColor: '#fff',
      });

      const windowPrint = window.open('', '', 'width=1000,height=800');
      windowPrint.document.write(`
        <html>
          <body>
            <h1 style={{ textAlign: 'center' }}>Reporting finale</h1>
            <br>
            <img id="chart-image" src="${url}" style="width:100%;"/>
          </body>
        </html>
      `);

      windowPrint.document.close();

      setTimeout(() => {
        windowPrint.focus();
        windowPrint.print();
        windowPrint.close();
      }, 500);
    }
  };
  return (
    <Accordion.Item
      eventKey={eventKey}
      className="mb-3 border accordionItem"
      onClick={onClick}
    >
      <Accordion.Header>{title}</Accordion.Header>
      <Accordion.Body className="backgroundColorInterno">
        {data?.length > 0 && (
        <div style={{ display: 'flex', gap: '10px', margin: '5px' }}>
          {isPrintable && (
          <Button
            variant="primary"
            size="sm"
            onClick={handlePrint}
          >
            <FontAwesomeIcon aria-hidden="true" icon="print" size="sm" />
            {' '}
            Stampa
          </Button>
          )}
        </div>
        )}
        {filtriBody}
        {data?.length > 0 ? (
          <ReactECharts
            ref={chartRef}
            option={optionsChart(data)}
            style={{ height: 1000 }}
          />
        ) : (
          <EmptyState
            subtitle="Nessun dato disponibile da visualizzare."
            marginTop="3rem"
          />
        )}
      </Accordion.Body>
    </Accordion.Item>
  );
}

export default AccordionCharts;
