import { useContext, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Pagination from '../../../components/News/Pagination';
import TitlePage from '../../../components/GestisciUtenti/TitoloPagina';
import ModaleConferma from '../../../components/modali/ModaleConferma';
import FiltriRicercaNews from '../../../components/News/FiltriRicerca';
import newsService from '../../../services/newsService';
import { Context } from '../../../utils/Context';
import history from '../../../utils/history';
import CardBasic from '../../../components/CardBasic';
import EmptyState from '../../../components/EmptyState';
import noImage from '../../../images/noImage.png';

const newsUrl = process.env.REACT_APP_NEWS_FAQ_URL;

function GestioneNewsEsterne() {
  const [context, setContext] = useContext(Context);
  const [news, setNews] = useState([]);
  const [idNews, setIdNews] = useState();
  const [openModaleConferma, setOpenModaleConferma] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    sizePerPage: 5,
    totalSize: 0,
  });
  const [filtri, setFiltri] = useState({
    titolo: '',
    contenuto: '',
    profonditaStorica: false,
  });

  // Function per la gestione dello state della modale di conferma
  function handleStateModaleConferma(id) {
    setOpenModaleConferma(!openModaleConferma);
    setIdNews(id);
  }

  // Function gestione filtri
  function onChangeFiltri(e, type) {
    const { value, id, checked } = e.target;
    setFiltri({
      ...filtri,
      [id]: type === 'checkbox' ? checked : value,
    });
  }

  // Function collegata al servizio di get della lista delle news pubbliche
  async function getListaNewsPubbliche(offset, limit) {
    window.scrollTo({ behavior: 'smooth', top: '0px' });
    try {
      const response = await newsService.getListaNewsPubbliche(filtri, offset, limit);
      setPagination((prevPag) => ({
        ...prevPag,
        page: (limit + offset) % limit,
        sizePerPage: limit,
        totalSize: response.totalRowCount,
      }));
      setNews(response?.listaNews || []);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // useEffect
  useEffect(() => {
    getListaNewsPubbliche(0, 6);
  }, []);

  // Function collegata al servizio di rimozione di una News interna
  async function deleteNewsPubblica() {
    await newsService.deleteNewsPubblica(idNews)
      .then((res) => {
        setContext(() => ({
          ...context, openConferma: true, testo: res.message || 'Operazione eseguita correttamente',
        }));
        getListaNewsPubbliche(0, 6);
      })
      .catch((err) => {
        setContext(() => ({
          ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
        }));
      })
      .finally(() => {
        handleStateModaleConferma();
      });
  }

  // Functin attivata al click dell'icona di modifica News
  async function onEdit(newsId) {
    history.push(`/area-riservata/gestione-news-esterne/modifica/${newsId}`);
  }

  return (
    <div>
      <TitlePage
        title="Gestione News Esterne"
        subtitle="In questa sezione è possibile gestire le notizie esterne."
        showAdd
        functionAdd={() => history.push('/area-riservata/gestione-news-esterne/aggiungi')}
      />
      <FiltriRicercaNews
        filtri={filtri}
        getListaNews={getListaNewsPubbliche}
        onChange={onChangeFiltri}
      />

      {news.length > 0 ? (
        <>
          <Row xs={1} md={2} className="p-0">
            {news.map((el) => (
              <Col key={el._id} className="mb-3">
                <CardBasic
                  id={el._id}
                  href={`/area-riservata/gestione-news-esterne/dettaglio/${el._id}`}
                  image={el.pathMinioImmagine ? `${newsUrl}/news/immagine/${encodeURIComponent(el.pathMinioImmagine)}` : noImage}
                  badge={el.inEvidenza}
                  title={el.titolo}
                  autore={el.creatore}
                  dataCreazione={el.dataCreazione}
                  showButtons={!el.dataFineValidita}
                  functionEdit={onEdit}
                  functionDelete={handleStateModaleConferma}
                  isModify
                />
              </Col>
            ))}
          </Row>
          {pagination.totalSize !== 0 && (
          <Pagination
            functionShow={getListaNewsPubbliche}
            pagination={pagination}
            customSizeButton
          />
          )}
        </>
      ) : (
        <EmptyState marginTop="3rem" subtitle="Nessuna news pubblica presente" />
      )}
      <ModaleConferma
        labelBottone="Conferma"
        coloreAzione="primary"
        title="Sei sicuro di procedere?"
        body="L'operazione eliminerà la news selezionata"
        show={openModaleConferma}
        handleClose={handleStateModaleConferma}
        handleOk={() => deleteNewsPubblica()}
      />
    </div>
  );
}

export default GestioneNewsEsterne;
