import { Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import { getItem } from '../../../utils/storage';

function FormAreaContrattuale({
  stateAreaContrattuale,
  onChange,
  disabled = false,
  isNew = false,
}) {
  const options = JSON.parse(getItem('comparti'));

  return (
    <Form.Group as={Col} lg controlId="desc_titolo">
      { !isNew && (
        <>
          <Form.Label>
            Codice
          </Form.Label>
          <Form.Control
            name="desc_codice"
            value={stateAreaContrattuale.desc_codice}
            onChange={onChange}
            maxLength={100}
            disabled
          />
        </>
      )}
      <Form.Label>
        Titolo
      </Form.Label>
      <Form.Control
        name="desc_titolo"
        value={stateAreaContrattuale.desc_titolo}
        placeholder="Inserisci il titolo"
        onChange={onChange}
        maxLength={100}
        required
        disabled={disabled}
      />
      <Form.Label>
        Suggerimento per Macro Attività
      </Form.Label>
      <Form.Control
        name="desc_suggerimento"
        value={stateAreaContrattuale.desc_suggerimento}
        placeholder="Inserisci il suggerimento per la Macro Attività"
        onChange={onChange}
        as="textarea"
        rows={3}
        required
        disabled={disabled}
      />
      <Form.Label>
        Comparto
      </Form.Label>
      <Select
        id="fk_sequ_comparto"
        name="fk_sequ_comparto"
        options={options}
        isDisabled
        placeholder="Scegli comparto"
        getOptionLabel={(option) => option.desc_comparto}
        getOptionValue={(option) => option.sequ_k_comparto}
        value={options?.filter(
          (el) => el.sequ_k_comparto === stateAreaContrattuale.fk_sequ_comparto,
        )?.[0]}
        onChange={(selectedOption, config) => { onChange(selectedOption, config); }}
      />
    </Form.Group>
  );
}

export default FormAreaContrattuale;
