import React, { useContext, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { Context } from '../../../utils/Context';
import { getItem, setItem } from '../../../utils/storage';
import SistemaProfessionaleService from '../../../services/sistemaProfessionaleService';
import checkStorage from '../../../utils/checkSessionStorage';
import TitlePage from '../../../components/GestisciUtenti/TitoloPagina';
import Table from '../../../components/Shared/Table';
import fieldsAreeCompetenze from './fieldsTable';
import ModaleCentroVerticale from '../../../components/modali/ModaleCentroVerticale';
import ModaleConferma from '../../../components/modali/ModaleConferma';
import FormAreaCompetenze from './FormAreaCompetenze';
import FiltriAreeCompetenze from './filtriAreeCompetenze';
import EmptyState from '../../../components/EmptyState';
import { controlla } from '../../../utils/verificaPermessi';

function AreeCompetenze() {
  const [context, setContext] = useContext(Context);
  const [areeCompetenzeList, setAreeCompetenzeList] = useState([]);
  const [areaCompetenzeSelected, setAreaCompetenzeSelected] = useState();
  const [modalShow, setModalShow] = useState(false);
  const [modalShowView, setModalShowView] = useState(false);
  const [modalShowModify, setModalShowModify] = useState(false);
  const [modalShowDelete, setModalShowDelete] = useState(false);
  const [errors, setErrors] = useState({});
  const { idAmministrazione } = JSON.parse(getItem('identita'));
  const [stateAreaCompetenze, setStateAreaCompetenze] = useState({
    desc_descrizione: '',
    codi_tipo_competenza: '',
  });

  const [filtri, setFiltri] = useState({});
  const [pagination, setPagination] = useState({
    page: 1,
    sizePerPage: 10,
    totalSize: 0,
  });

  // Validazione per la creazione di un profilo professionale
  const validateCreaAreeCompentenze = (state, error) => {
    const temp = { ...error };
    temp.codi_tipo_competenza = state.codi_tipo_competenza === '';
    setErrors((prevState) => ({
      ...prevState,
      ...temp,
    }));
    return Object.values(temp).every((x) => x === false);
  };

  // function collegata al servizio per ottenere la lista delle aree delle competenze
  async function getAreeCompetenze(offset, limit, page, filters) {
    try {
      const filterSession = filters || filtri;
      const response = await SistemaProfessionaleService
        .getAreeCompetenze(filterSession, offset, limit);
      setAreeCompetenzeList(response.rows);
      setPagination((prevPag) => ({
        ...prevPag,
        page,
        sizePerPage: limit,
        totalSize: response.totalRowCount,
      }));
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // function attivata al cambio pagina della tabella
  const handleTableChange = (type, {
    page, sizePerPage, sortField, sortOrder,
  }) => {
    const newFilters = {
      ...filtri,
      sortField: type === 'sort' ? sortField : 'desc_codice',
      sortOrder: type === 'sort' ? sortOrder : 'asc',
    };
    let currentIndex;
    if (page !== 0) {
      currentIndex = (page - 1) * sizePerPage;
    }
    getAreeCompetenze(
      currentIndex,
      sizePerPage,
      page,
      newFilters,
    );
  };

  // useEffect
  useEffect(() => {
    let offset = 0;
    const storage = checkStorage(filtri, setFiltri, setPagination);
    if (storage.page > 1) {
      handleTableChange(null, { page: storage.page, sizePerPage: pagination.sizePerPage });
      offset = (storage.page - 1) * pagination.sizePerPage;
    }
    getAreeCompetenze(offset, 10, storage.page, storage.filtri);
  }, []);

  // useEffect per settare nello storage la pagina corrente
  useEffect(() => {
    setItem('page', pagination.page);
  }, [pagination.page]);

  // useEffect per caricare i dati presenti nella tabella.
  useEffect(() => {
    getAreeCompetenze(0, 10, 1, {});
  }, []);

  // function per modificare lo stato dell'area delle competenze che l'utente sta modificando.
  const onChange = (e, config) => {
    const newState = { ...stateAreaCompetenze };
    if (config) {
      newState[config.name] = e?.codi_tipo_competenza;
    } else {
      const { name, value } = e.target;
      newState[name] = value;
    }
    setStateAreaCompetenze(newState);
    validateCreaAreeCompentenze(newState, errors);
  };

  // function per modificare lo stato che gestisce i filtri
  function onChangeFiltri(e, config) {
    if (config) {
      if (config.name === 'codi_tipo_competenza') {
        setFiltri({
          ...filtri, [config.name]: e?.codi_tipo_competenza,
        });
      } if (config.name === 'banca_dati_unica') {
        setFiltri({
          ...filtri, [config.name]: e?.value,
        });
      }
    } else {
      const { value, name } = e.target;
      setFiltri({
        ...filtri,
        [name]: value,
      });
    }
  }

  // function per effettuare il refresh della pagina
  function refresh() {
    getAreeCompetenze(0, 10, 1, {});
  }

  // function collegata al servizio di creazione di una nuova area delle competenze
  async function nuovaAreaCompetenze() {
    if (validateCreaAreeCompentenze(stateAreaCompetenze, errors)) {
      SistemaProfessionaleService.nuovaAreaCompetenze(stateAreaCompetenze)
        .then((res) => {
          setContext(() => ({
            ...context, openConferma: true, testo: res.message,
          }));
          setModalShow(false);
          refresh();
        })
        .catch((err) => {
          setContext(() => ({
            ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
          }));
        });
    }
  }

  // function collegata al servizio di eliminazione di una area delle competenze
  async function eliminaAreaCompetenze(idAreaCompetenze) {
    SistemaProfessionaleService.eliminaAreaCompetenze(idAreaCompetenze)
      .then((res) => {
        setContext(() => ({
          ...context, openConferma: true, testo: res.message,
        }));
        setModalShowDelete(false);
        refresh();
      })
      .catch((err) => {
        setContext(() => ({
          ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
        }));
      });
  }

  // function collegata al servizio di modifica di una area delle competenze
  async function modificaAreaCompetenze(idAreaCompetenze) {
    SistemaProfessionaleService.modificaAreaCompetenze(
      idAreaCompetenze,
      stateAreaCompetenze,
    )
      .then((res) => {
        setContext(() => ({
          ...context, openConferma: true, testo: res.message,
        }));
        setModalShowModify(false);
        refresh();
      })
      .catch((err) => {
        setContext(() => ({
          ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
        }));
      });
  }

  // function collegata al servizio di get di una particolare area delle competenze
  async function getAreaCompetenzeById(id) {
    try {
      const response = await SistemaProfessionaleService.getAreaCompetenzeById(id);
      setStateAreaCompetenze({
        desc_descrizione: response.desc_descrizione,
        desc_codice: response.desc_codice,
        codi_tipo_competenza: response.codi_tipo_competenza,
      });
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // Funzione attivata al click dell'icona di eliminazione
  const onDelete = (areaCompetenze) => {
    setModalShowDelete(true);
    setAreaCompetenzeSelected(areaCompetenze);
  };

  // Funzione attivata al click dell'icona di visualizzazione
  const onView = (areaCompetenze) => {
    getAreaCompetenzeById(areaCompetenze.id);
    setAreaCompetenzeSelected(areaCompetenze);
    setModalShowView(true);
  };

  // Funzione attivata al click dell'icona di modifica
  const onModify = (modify, areaCompetenze) => {
    getAreaCompetenzeById(areaCompetenze.id);
    setAreaCompetenzeSelected(areaCompetenze);
    if (modify) {
      setModalShowModify(true);
    } else {
      setModalShowView(false);
      setModalShowModify(true);
    }
  };

  return (
    <>
      <Container className="p-0">
        <TitlePage
          title="Aree delle Competenze"
          showRefresh
          functionRefresh={refresh}
          showAdd={controlla('X27')}
          functionAdd={() => setModalShow(true)}
        />
        <FiltriAreeCompetenze
          filtri={filtri}
          setFiltri={setFiltri}
          onChangeFiltri={onChangeFiltri}
          getAreeCompetenze={getAreeCompetenze}
        />
        {areeCompetenzeList.length > 0 ? (
          <Table
            data={areeCompetenzeList}
            fields={fieldsAreeCompetenze(onDelete, onView, onModify, idAmministrazione)}
            pagination={pagination}
            onTableChange={handleTableChange}
            keyField="aree_competenze_list"
          />
        ) : (
          <EmptyState marginTop="3rem" />
        )}
      </Container>
      <ModaleCentroVerticale
        modalTitle="Crea una nuova Area delle Competenze"
        modalBody={(
          <FormAreaCompetenze
            stateAreaCompetenze={stateAreaCompetenze}
            onChange={onChange}
            isNew
            errors={errors}
          />
        )}
        buttonType="submit"
        show={modalShow}
        onConfirm={() => nuovaAreaCompetenze()}
        labelConfirm="Aggiungi"
        onClose={() => setModalShow(false)}
      />
      <ModaleConferma
        labelBottone="Elimina"
        coloreAzione="primary"
        title="Sei sicuro di procedere?"
        body={`L'operazione eliminerà l'area delle competenze ${areaCompetenzeSelected?.codice}`}
        show={modalShowDelete}
        handleClose={() => setModalShowDelete(false)}
        handleOk={() => eliminaAreaCompetenze(areaCompetenzeSelected.id)}
      />
      <ModaleCentroVerticale
        modalTitle="Area delle Competenze"
        modalBody={(
          <FormAreaCompetenze
            stateAreaCompetenze={stateAreaCompetenze}
            disabled
          />
        )}
        buttonType="submit"
        show={modalShowView}
        onConfirm={() => onModify(false, areaCompetenzeSelected)}
        disabledConfirm={(!controlla('X27') || idAmministrazione !== areaCompetenzeSelected?.idAmministrazione)}
        labelConfirm="Modifica"
        onClose={() => setModalShowView(false)}
      />
      <ModaleCentroVerticale
        modalTitle="Modifica Area delle Competenze"
        modalBody={(
          <FormAreaCompetenze
            stateAreaCompetenze={stateAreaCompetenze}
            onChange={onChange}
            errors={errors}
          />
        )}
        buttonType="submit"
        show={modalShowModify}
        onConfirm={() => modificaAreaCompetenze(areaCompetenzeSelected.id)}
        labelConfirm="Conferma"
        onClose={() => setModalShowModify(false)}
      />
    </>
  );
}

export default AreeCompetenze;
