import React from 'react';
import { Modal, Button, Col } from 'react-bootstrap';

// labelBottone: scritta pulsante di destra ad esempio "salva", "elimina"
// coloreAzione: colore del pulsante ad esempio "primary", "danger", ecc.
function ModaleConferma({
  title,
  body,
  labelBottone,
  coloreAzione,
  show,
  handleClose,
  handleOk,
  showAI,
  imageAI,
  titleAI,
  variantAI,
  functionAI,
}) {
  return (
    <Modal show={show} onHide={handleClose} centered role="dialog" size="lg">
      <Modal.Header closeButton closeLabel="Chiudi">
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer>
        <Col className="col-lg-auto text-end d-flex align-items-center gap-2">
          <Button variant="grey" onClick={handleClose}>
            Chiudi
          </Button>
          <Button variant={coloreAzione} onClick={handleOk}>
            {labelBottone}
          </Button>
          {showAI
            ? (
              <Button
                style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
                variant={variantAI ?? 'info'}
                onClick={functionAI}
              >
                {imageAI}
                {titleAI ?? 'Info'}
              </Button>
            ) : null}
        </Col>
      </Modal.Footer>
    </Modal>
  );
}

export default ModaleConferma;
