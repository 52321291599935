import { Container } from 'react-bootstrap';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import CompetenzeProfilo from '../components/CompetenzeProfilo';
import CompetenzeAggiuntive from '../components/CompetenzeAggiuntive';
import TitlePage from '../../../../components/GestisciUtenti/TitoloPagina';
import EmptyState from '../../../../components/EmptyState';
import { Context } from '../../../../utils/Context';
import AssessmentService from '../../../../services/assessmentService';
import SistemaProfessionaleService from '../../../../services/sistemaProfessionaleService';
import history from '../../../../utils/history';
import { controlla } from '../../../../utils/verificaPermessi';

function SchedaCompetenze() {
  const [context, setContext] = useContext(Context);
  const { idDipendente } = useParams();
  const { state } = useLocation();
  const [schedaCompetenzeProfilo, setSchedaCompetenzeProfilo] = useState([]);
  const [schedaCompetenzeAggiuntive, setSchedaCompetenzeAggiuntive] = useState([]);
  const [storicoProfilo, setStoricoProfilo] = useState([]);
  const [profiloRuoloState, setProfiloRuoloState] = useState('');
  const [gradingList, setGradingList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [competenzeList, setCompetenzeList] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    sizePerPage: 10,
    totalSize: 0,
  });
  const [filtri, setFiltri] = useState({
    desc_codice: '',
    desc_titolo: '',
    codi_tipo_competenza: '',
  });

  // Function collegata al servizio per ottenere lo storico dei profili ruolo dell'utente
  async function getStoricoProfiliRuolo() {
    try {
      const response = await AssessmentService
        .getStoricoProfiliRuolo();
      setStoricoProfilo(response);
      setProfiloRuoloState(response.find(
        (prof) => prof.data_fine_validita === null,
      )?.desc_titolo);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // Function collegata al servizio per ottenere la scheda competenze dell'utente
  async function getSchedaCompetenze() {
    try {
      const response = await AssessmentService
        .getSchedaCompetenze();
      setSchedaCompetenzeProfilo(response.schedaCompetenzeAssessment);
      const competenzeCustomAdaptable = response.schedaCompetenzeCustom.map(
        (scheda) => ({
          ...scheda,
          azione: '',
          desc_codice: scheda.codice_competenza,
          desc_titolo: scheda.titolo_competenza,
        }),
      );
      setSchedaCompetenzeAggiuntive(competenzeCustomAdaptable);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // function collegata al servizio per ottenere la lista delle competenze
  async function getCompetenze(offset, limit, page, filters) {
    try {
      const filterSession = filters || filtri;
      const response = await SistemaProfessionaleService
        .getCompetenze(filterSession, offset, limit);
      const competenzeDaEscludere = schedaCompetenzeProfilo?.map(
        (competenza) => competenza?.codice_competenza,
      );
      const competenzeFiltered = response?.rows?.filter(
        (competenza) => !competenzeDaEscludere.includes(competenza?.desc_codice),
      );
      setCompetenzeList(competenzeFiltered);
      setPagination((prevPag) => ({
        ...prevPag,
        page,
        sizePerPage: limit,
        totalSize: response.totalRowCount,
      }));
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // Function collegata al servizio per recuperare i dati della scheda dell'utente dipendente
  async function getSchedaCompetenzeByDipendente() {
    try {
      const response = await AssessmentService
        .getSchedaCompetenzeByDipendente(idDipendente);
      setSchedaCompetenzeProfilo(response.schedaCompetenzeAssessment);
      const competenzeCustomAdaptable = response.schedaCompetenzeCustom.map(
        (scheda) => ({
          ...scheda,
          azione: '',
          desc_codice: scheda.codice_competenza,
          desc_titolo: scheda.titolo_competenza,
        }),
      );
      setSchedaCompetenzeAggiuntive(competenzeCustomAdaptable);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // Function collegata al servizio per ottenere lo storico dei profili ruolo dell'utente
  async function getStoricoProfiliRuoloByDipendente() {
    try {
      const response = await AssessmentService
        .getStoricoProfiliRuoloByDipendente(idDipendente);
      setStoricoProfilo(response);
      setProfiloRuoloState(response.find(
        (prof) => prof.data_fine_validita === null,
      )?.desc_titolo);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // function collegata al servizio per ottenere la lista dei grading
  async function getGrading(offset, limit, filters) {
    try {
      const response = await SistemaProfessionaleService
        .getGrading(filters, offset, limit);
      setGradingList(response.rows);
      setIsLoading(false);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // function per effettuare il refresh della pagina
  function refresh() {
    getSchedaCompetenze();
    getStoricoProfiliRuolo();
    getGrading(0, 1000, {});
  }

  // function che salva nel db le competenze aggiuntive
  async function saveCompetenzeAggiuntive() {
    try {
      const dataFormatted = schedaCompetenzeAggiuntive.map((scheda) => ({
        azione: scheda.azione,
        fk_sequ_competenza: scheda.sequ_k_competenza,
        desc_anno: scheda.desc_anno,
        desc_note: scheda.desc_note,
        desc_fonte: scheda.desc_fonte,
        codi_grading: scheda.codi_grading,
      }));
      AssessmentService.aggiornaSchedaCompetenze(dataFormatted)
        .then(async (res) => {
          setContext(() => ({
            ...context, openConferma: true, testo: res.message,
          }));
          refresh();
        })
        .catch((err) => {
          setContext(() => ({
            ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: 400,
          }));
        });
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // Function attivata al cambio pagina della tabella
  const handleTableChange = (type, {
    page, sizePerPage,
  }) => {
    let currentIndex;
    if (page !== 0) {
      currentIndex = (page - 1) * sizePerPage;
    }
    getCompetenze(
      currentIndex,
      sizePerPage,
      page,
      {},
    );
  };

  useEffect(() => {
    if (state?.isView) { // UTILIZZARE QUESTO PARAMETRO PER IL VISUALIZZA DELLE AGGIUNTIVE
      getSchedaCompetenzeByDipendente();
      getStoricoProfiliRuoloByDipendente();
    } else {
      getSchedaCompetenze();
      getStoricoProfiliRuolo();
    }
    getGrading(0, 1000, {});
  }, []);

  useEffect(() => {
    getCompetenze(0, 10, 1, {});
  }, [schedaCompetenzeProfilo]);

  return (
    isLoading
      ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
          <div className="inline-block align-middle">
            <h1 className="font-weight-normal lead" id="desc">Caricamento...</h1>
          </div>
        </div>
      )
      : (
        <Container className="p-0">
          <TitlePage
            title="Scheda delle Competenze"
            subtitle="Visualizzazione dello storico dei Profili di Ruolo assegnati,
            competenze associate al Profilo di Ruolo corrente ed aggiunta di nuove
            competenze da diverse fonti."
            showSave={controlla('Z32')}
            functionSave={() => saveCompetenzeAggiuntive()}
            showIndietro={state?.isView}
            functionIndietro={() => history.goBack()}
          />
          {state?.isView && (
            <>
              <h3>Dipendente</h3>
              <h5>
                <p style={{ fontWeight: 'lighter', fontStyle: 'italic' }}>
                  {state.nomeDipendente}
                  {' '}
                  {state.cognomeDipendente}
                </p>
              </h5>
            </>
          )}
          {profiloRuoloState ? (
            <>
              <CompetenzeProfilo
                profiloRuolo={profiloRuoloState}
                profiliRuoloStorico={storicoProfilo}
                competenzeList={schedaCompetenzeProfilo}
                optionsGrading={gradingList}
              />
              <CompetenzeAggiuntive
                competenzeAggiuntive={schedaCompetenzeAggiuntive}
                setCompetenzeAggiuntive={setSchedaCompetenzeAggiuntive}
                gradingList={gradingList}
                competenzeList={competenzeList}
                setCompetenzeList={setCompetenzeList}
                pagination={pagination}
                handleTableChange={handleTableChange}
                getCompetenze={getCompetenze}
                filtri={filtri}
                setFiltri={setFiltri}
              />
            </>
          ) : (
            <EmptyState
              title="Attenzione"
              subtitle="Non è possibile accedere a questa pagina in quanto non ti
              è stato ancora assegnato il Profilo di Ruolo. Contatta il tuo
              responsabile per maggiori informazioni."
              marginBottom="3rem"
              marginTop="3rem"
            />
          )}
        </Container>
      )
  );
}

export default SchedaCompetenze;
