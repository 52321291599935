import React, { useEffect, useState } from 'react';
import { getItem } from '../../utils/storage';
import ReportSistemaProfessionale from '../../components/Report/sistemaProfessionale';
import { controlla } from '../../utils/verificaPermessi';
import ReportAssessment from '../../components/Report/assessment';
import SpinnerBody from '../../components/Shared/SpinnerBody';

function Home() {
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
      setIsLoading(false);
    }, 500);
  }, []);

  return (
    isLoading
      ? (
        <SpinnerBody />
      )
      : (
        <div className="m-2" style={{ textAlign: 'center' }}>
          <h1 className="mb-2">{(JSON.parse(getItem('identita'))).amministrazione}</h1>
          <p className="h4">Benvenuto nella tua area riservata.</p>
          <p id="desc">Scegli una delle funzioni dal menu di lato.</p>
          {show && controlla('X21') && <ReportSistemaProfessionale />}
          {show && controlla('X22') && <ReportAssessment />}
        </div>
      )
  );
}

export default Home;
