import { useState } from 'react';
import Table from '../../../../../../components/Shared/Table';
import fieldsCriteri from '../../fields/fieldsCriteri';

function ModificaCriteri({
  criteriList,
  setModello,
}) {
  const [criteri, setCriteri] = useState(criteriList);

  const onChange = (e, row) => {
    const { value } = e.target;
    setCriteri((prevState) => {
      const listaCriteri = prevState.map(
        (crt) => {
          if (crt.sequ_k_criterio === row.sequ_k_criterio) {
            return {
              ...crt,
              valore_percentuale: parseInt(value, 10) || '',
            };
          }

          return crt;
        },
      );
      setModello((prevStateModello) => ({
        ...prevStateModello,
        criteri: listaCriteri,
      }));
      return listaCriteri;
    });
  };

  return (
    <>
      <p>
        E&apos; possibile modificare le percentuali del modello selezionato.
        Procedere con le modifiche da effettuare oppure confermare le
        percentuali di base del modello.
      </p>
      <Table
        data={criteri}
        fields={fieldsCriteri(onChange, criteri)}
        keyField="sequ_k_criterio"
      />
    </>
  );
}

export default ModificaCriteri;
