import React, { useContext, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { Context } from '../../../utils/Context';
import history from '../../../utils/history';
import questionariService from '../../../services/questionariService';
import TitlePage from '../../../components/GestisciUtenti/TitoloPagina';
import TabsBar from '../../../components/Questionari/TabsBar';
import CardGroup from '../../../components/Questionari/CardGroup';
import Pagination from '../../../components/Questionari/Pagination';
import FiltriRicercaQuestionari from '../../../components/Questionari/filtriRicerca';

const questionariUrl = process.env.REACT_APP_QUESTIONARI_URL;

function Questionari() {
  const [context, setContext] = useContext(Context);
  const [questionari, setQuestionari] = useState([]);
  const [filtri, setFiltri] = useState({
    titolo: '',
    stato: '',
  });
  const [pagination, setPagination] = useState({
    page: 1,
    sizePerPage: 5,
    totalSize: 0,
  });

  // Function per gestire la modifica della sezione dei Filtri di ricerca
  function onChangeFiltri(e) {
    const { value, id } = e.target;
    setFiltri({
      ...filtri,
      [id]: value,
    });
  }

  // Function collegata al servizio per recuperare la lista dei questionari
  async function getQuestionari(offset, limit) {
    try {
      const response = await questionariService.getQuestionari(filtri, offset, limit);
      setPagination((prevPag) => ({
        ...prevPag,
        page: (limit + offset) % limit,
        sizePerPage: limit,
        totalSize: response.totalRowCount,
      }));
      setQuestionari(response.listaQuestionari);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // Function collegata al servizio per recuperare la lista dei template disponibili
  async function getTemplateDisponibili(offset, limit) {
    try {
      const response = await questionariService.getTemplateDisponibili(filtri, offset, limit);
      setPagination((prevPag) => ({
        ...prevPag,
        page: (limit + offset) % limit,
        sizePerPage: limit,
        totalSize: response.totalRowCount,
      }));
      setQuestionari(response.listaTemplateDisponibili);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // Function cambiamento pagina indirizzata al componente
  // per la compilazione del questionario desiderato
  function compileQuestionario(id) {
    history.push(`/area-riservata/questionari/compila-questionario/${id}`);
  }

  // Function cambiamento pagina indirizzata al componente
  // per la visualizzazione del questionario desiderato
  function showQuestionario(id) {
    history.push(`/area-riservata/questionari/visualizza-questionario/${id}`);
  }

  // Function utilizzata per l'esportazione del questionario desiderato
  function exportQuestionario(id, formato) {
    const a = document.createElement('A');
    a.href = `${questionariUrl}/interni/${id}/esporta?formato=${formato}`;
    a.target = '_blank';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  // Function collegata al servizio per creare un nuovo questionario
  async function createQuestionario(idTemplate) {
    try {
      const res = await questionariService.createQuestionario(idTemplate);
      setContext(() => ({
        ...context, openConferma: true, testo: res.message || 'Operazione eseguita correttamente',
      }));
      history.push(`/area-riservata/questionari/compila-questionario/${res.id}`);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // useEffect
  useEffect(() => {
    getTemplateDisponibili(0, 5);
  }, []);
  return (
    <Container className="p-0">
      <TitlePage
        title="Questionari"
        subtitle="Gestione questionari"
      />
      <FiltriRicercaQuestionari
        filtri={filtri}
        getElements={filtri.stato === '' ? getTemplateDisponibili : getQuestionari}
        onChange={onChangeFiltri}
        onlyTitle
      />
      <TabsBar
        title1="DISPONIBILI"
        title2="IN CORSO"
        title3="COMPLETATI"
        function1={() => {
          filtri.titolo = ''; filtri.stato = ''; getTemplateDisponibili(0, 5);
        }}
        function2={() => {
          filtri.titolo = ''; filtri.stato = 'bozza'; getQuestionari(0, 5);
        }}
        function3={() => {
          filtri.titolo = ''; filtri.stato = 'completato'; getQuestionari(0, 5);
        }}
      />
      <CardGroup
        questionari={questionari}
        functionCompile={compileQuestionario}
        functionExport={exportQuestionario}
        functionShow={showQuestionario}
        functionAdd={createQuestionario}
      />
      {pagination.totalSize !== 0 && (
        <Pagination
          functionShow={filtri.stato === '' ? getTemplateDisponibili : getQuestionari}
          pagination={pagination}
        />
      )}
    </Container>
  );
}

export default Questionari;
