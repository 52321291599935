import React, { useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { useCookies } from 'react-cookie';
import { Col, Row } from 'react-bootstrap';
import LoginSviluppoModal from '../../components/LoginSviluppoModal';
import TitlePage from '../../components/GestisciUtenti/TitoloPagina';
import { clear } from '../../utils/storage';
import './index.css';
import spidIcoCircleBB from '../../images/spid-ico-circle-bb.png';
import SimpleButton from '../../components/SimpleButton';

const accessoSviluppo = process.env.REACT_APP_ACCESSO_SVILUPPO;
const urlAccessoSpid = process.env.REACT_APP_URL_ACCESSO_SPID;
const urlAccessoCie = process.env.REACT_APP_URL_ACCESSO_CIE;
const urlAccessoCns = process.env.REACT_APP_URL_ACCESSO_CNS;

function Login() {
  const [modalShow, setModalShow] = React.useState(false);
  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies(['cookie-name']);

  useEffect(() => {
    clear();
    removeCookie('accessoOspite');
  }, []);

  return (
    <div className="mx-4">
      <TitlePage title="Pagina di accesso" subtitle="Scegli uno dei metodi di autenticazione presenti." />

      <Row xs={1} md={2} lg={3} className="g-5 d-flex justify-content-center">
        <Col key={1}>
          <Card id="card">
            <Card.Header id="cardHeader">
              <div className="d-flex">
                <div className="flag">SPID</div>
              </div>
            </Card.Header>
            <Card.Body id="cardBody">
              <Card.Text>
                SPID è l&apos;identità digitale pubblica composta da una coppia di credenziali
                (username e password), strettamente personali, con cui accedi ai servizi
                online della pubblica amministrazione e dei privati aderenti.
              </Card.Text>
            </Card.Body>
            <Card.Footer id="cardFooter">
              <Row className="px-3">
                <SimpleButton
                  label="Entra con SPID"
                  alt="Logo SPID"
                  icon={spidIcoCircleBB}
                  href={urlAccessoSpid}
                  bgColor="#06c"
                />
              </Row>
            </Card.Footer>
          </Card>
        </Col>
        <Col key={2}>
          <Card id="card">
            <Card.Header id="cardHeader">
              <div className="d-flex">
                <div className="flag">CIE</div>
              </div>
            </Card.Header>
            <Card.Body id="cardBody">
              <Card.Text>
                La Carta di identità elettronica permette al cittadino l&apos;autenticazione
                con i massimi livelli di sicurezza nei servizi online degli enti che ne
                consentono l utilizzo, Pubbliche Amministrazioni e soggetti privati.
              </Card.Text>
            </Card.Body>
            <Card.Footer id="cardFooter">
              <Row className="px-3">
                <SimpleButton
                  label="Entra con CIE"
                  alt="Logo CIE"
                  icon={spidIcoCircleBB}
                  href={urlAccessoCns}
                  bgColor="#06c"
                />
              </Row>
            </Card.Footer>
          </Card>
        </Col>
        <Col key={3}>
          <Card id="card">
            <Card.Header id="cardHeader">
              <div className="d-flex">
                <div className="flag">CNS/TS</div>
              </div>
            </Card.Header>
            <Card.Body id="cardBody">
              <Card.Text>
                Accesso con Carta Nazionale dei Servizi o Tessera Sanitaria. È necessario
                un lettore di smart card, o token USB, opportunamente configurato nel browser.
                Al momento dell&apos;autenticazione inserire il PIN della carta.
              </Card.Text>
            </Card.Body>
            <Card.Footer id="cardFooter">
              <Row className="px-3">
                <SimpleButton
                  label="Entra con CNS/TS"
                  alt="Logo CNS/TS"
                  icon={spidIcoCircleBB}
                  href={urlAccessoCns}
                  bgColor="#06c"
                />
              </Row>
            </Card.Footer>
          </Card>
        </Col>
        {accessoSviluppo === 'true' ? (
          <Col key={4}>
            <Card id="card">
              <Card.Header id="cardHeader">
                <div className="d-flex">
                  <div className="flag">SVILUPPO</div>
                </div>
              </Card.Header>
              <Card.Body id="cardBody">
                <Card.Text>
                  Accedi con codice identificativo
                </Card.Text>
              </Card.Body>
              <Card.Footer id="cardFooter">
                <Row className="px-3">
                  <Button variant="primary" onClick={() => setModalShow(true)}>
                    Accedi
                  </Button>
                </Row>
              </Card.Footer>
            </Card>
          </Col>
        ) : null}
      </Row>
      <LoginSviluppoModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </div>
  );
}

export default Login;
