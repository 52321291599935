import { Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

function FormGestioneUtenti({
  stateUtente,
  onChange,
  errors,
  isNew = false,
}) {
  return (
    <Form.Group as={Col} lg controlId="form_aggiungi_utente">
      {isNew
        ? (
          <p>
            Inserisci i campi richiesti.
          </p>
        ) : (
          <p>
            Verifica l&apos;esistenza dell&apos;utente attraverso il Codice Fiscale.
            Se presente nel sistema, verrai reindirizzato alla pagina di modifica
            dell&apos;utente.
          </p>
        )}
      <Form.Label style={{ fontWeight: 'bold' }}>
        Codice Fiscale
      </Form.Label>
      <Form.Control
        name="codi_id_fiscale"
        onChange={onChange}
        value={stateUtente.codi_id_fiscale}
        maxLength={16}
        isInvalid={errors?.codi_id_fiscale}
        required
        disabled={isNew}
      />
      <Form.Control.Feedback type="invalid">
        *Campo Codice Fiscale obbligatorio
      </Form.Control.Feedback>
      {isNew && (
        <>
          <Form.Label style={{ fontWeight: 'bold' }}>
            Nome
          </Form.Label>
          <Form.Control
            name="desc_nome"
            value={stateUtente.desc_nome}
            onChange={onChange}
            maxLength={100}
            isInvalid={errors?.desc_nome}
            required
          />
          <Form.Control.Feedback type="invalid">
            *Campo Nome obbligatorio
          </Form.Control.Feedback>
          <Form.Label style={{ fontWeight: 'bold' }}>
            Cognome
          </Form.Label>
          <Form.Control
            name="desc_cognome"
            onChange={onChange}
            value={stateUtente.desc_cognome}
            maxLength={100}
            isInvalid={errors?.desc_cognome}
            required
          />
          <Form.Control.Feedback type="invalid">
            *Campo Cognome obbligatorio
          </Form.Control.Feedback>
          <Form.Label style={{ fontWeight: 'bold' }}>
            Indirizzo E-mail
          </Form.Label>
          <Form.Control
            name="desc_email"
            onChange={onChange}
            value={stateUtente.desc_email}
            maxLength={100}
            isInvalid={errors?.desc_email}
            required
          />
          <Form.Control.Feedback type="invalid">
            *Campo E-mail obbligatorio
          </Form.Control.Feedback>
          <Form.Label style={{ fontWeight: 'bold' }}>
            Area Contrattuale
          </Form.Label>
          <Form.Control
            name="desc_area_contrattuale"
            onChange={onChange}
            value={stateUtente.desc_area_contrattuale}
            isInvalid={errors?.desc_area_contrattuale}
            required
            maxLength={100}
          />
          <Form.Control.Feedback type="invalid">
            *Campo Area Contrattuale obbligatorio
          </Form.Control.Feedback>
          <Form.Label style={{ fontWeight: 'bold' }}>
            Profilo di Accesso
          </Form.Label>
          <Form.Control
            name="desc_profilo_accesso"
            onChange={onChange}
            value={stateUtente.desc_profilo_accesso}
            maxLength={100}
          />
          <Form.Label style={{ fontWeight: 'bold' }}>
            Struttura Organizzativa di I Livello
          </Form.Label>
          <Form.Control
            name="desc_struttura_organizzativa_i_liv"
            onChange={onChange}
            value={stateUtente.desc_struttura_organizzativa_i_liv}
            isInvalid={errors?.desc_struttura_organizzativa_i_liv}
            required
            maxLength={100}
          />
          <Form.Control.Feedback type="invalid">
            *Campo Struttura Organizzativa I Livello obbligatoria
          </Form.Control.Feedback>
          <Form.Label style={{ fontWeight: 'bold' }}>
            Struttura Organizzativa di II Livello
          </Form.Label>
          <Form.Control
            name="desc_struttura_organizzativa_ii_liv"
            onChange={onChange}
            value={stateUtente.desc_struttura_organizzativa_ii_liv}
            maxLength={100}
          />
        </>
      )}
    </Form.Group>
  );
}

export default FormGestioneUtenti;
