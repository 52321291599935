import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useEffect, useState } from 'react';
import {
  Col, Form, Row, Button,
} from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import { fieldsProfonditaStoricaIdentita, fieldsNoProfonditaStoricaIdentita } from '../../../../../components/Ruoli/fieldsTableIdentita';
import ModaleRicercaIdentitaModifica from '../../../../../components/Identita/ModaleRicercaIdentitaModifica';
import ModaleConferma from '../../../../../components/modali/ModaleConferma';
import Table from '../../../../../components/Shared/Table';
import identitaService from '../../../../../services/identitaService';
import utenteService from '../../../../../services/utentiService';
import { Context } from '../../../../../utils/Context';
import history from '../../../../../utils/history';
import useQuery from '../../../../../utils/useQuery';
import TitlePage from '../../../../../components/GestisciUtenti/TitoloPagina';
import EmptyState from '../../../../../components/EmptyState';
import { formatoData } from '../../../../../utils/formatData';
import { getItem } from '../../../../../utils/storage';
import canaliService from '../../../../../services/canaliService';
import SistemaProfessionaleService from '../../../../../services/sistemaProfessionaleService';
import fieldsStoricoProfili from '../../../scheda-competenze/fields/fieldsStoricoProfili';
import AssessmentService from '../../../../../services/assessmentService';

function ModificaUtentePerAmministratore() {
  const query = useQuery();
  const { idUtente } = useParams();
  const { idAmministrazione, amministrazione, idIdentita } = JSON.parse(getItem('identita'));
  const { sequ_k_comparto } = getItem('compartoSelected') ? JSON.parse(getItem('compartoSelected')) : 0;
  const [context, setContext] = useContext(Context);
  const [utente, setUtente] = useState({});
  const [errors, setErrors] = useState({});
  const [openCloseModaleConferma, setOpenCloseModaleConferma] = useState(false);
  const [identitaScelte, setIdentitaScelte] = useState([]);
  const [identitaAggiunte, setIdentitaAggiunte] = useState([]);
  const [identitaRimosse, setIdentitaRimosse] = useState([]);
  const [showModalIdentita, setShowModalIdentita] = useState(false);
  const [listaIdentita, setListaIdentita] = useState([]);
  const [listaResponsabili, setListaResponsabili] = useState([]);
  const [profiliRuoloList, setProfiliRuoloList] = useState([]);
  const [storicoProfilo, setStoricoProfilo] = useState([]);
  const [paginationIdentita, setPaginationIdentita] = useState({
    page: 1,
    sizePerPage: 10,
    totalSize: 0,
  });
  const [filtriIdentita, setFiltriIdentita] = useState({
    fk_sequ_tag_identita: null,
    desc_nome_identita: '',
    fk_codi_tipo_identita: null,
    profondita_storica: false,
  });
  const [filtriUtente, setFiltriUtente] = useState({
    profondita_storica_connettori: 'false',
    profondita_storica_identita: 'false',
  });

  // Validazione per la modifica dei dati dell'utente
  const validateModificaUtente = (state, error) => {
    const temp = { ...error };
    temp.desc_email = state.desc_email === '';
    temp.desc_area_contrattuale = state.desc_area_contrattuale === '' || state.desc_area_contrattuale === null;
    temp.desc_struttura_organizzativa_i_liv = state.desc_struttura_organizzativa_i_liv === '' || state.desc_struttura_organizzativa_i_liv === null;
    setErrors((prevState) => ({
      ...prevState,
      ...temp,
    }));
    return Object.values(temp).every((x) => x === false);
  };

  function handleStateModaleConferma() {
    if (validateModificaUtente(utente, errors)) {
      setOpenCloseModaleConferma(!openCloseModaleConferma);
    }
  }

  // Function per gestione filtri Identità
  function onChangeFiltriIdentita(e) {
    const { value } = e.target;
    setFiltriIdentita({
      ...filtriIdentita,
      desc_nome_identita: value,
    });
  }

  // Function per gestione filtri Utente
  function onChangeFiltriUtente(e) {
    const { id } = e.target;
    const { value } = e.target;
    setFiltriUtente({
      ...filtriUtente,
      [id]: value,
    });
  }

  // Function per gestire la modifica della modale per l'aggiungi di un nuovo utente
  const onChangeModifica = (e, config, row) => {
    if (config?.name === 'responsabile') {
      if (row.nome_utente_responsabile) {
        const identitaFiltrate = identitaAggiunte.filter((el) => el.sequ_k_identita !== 6);
        identitaFiltrate.push({
          ...row,
          fk_sequ_utente_trusted_responsabile: e.sequ_k_utente_trusted,
          azione: 'modifica',
        });
        setIdentitaAggiunte(identitaFiltrate);
      } else {
        let identitaDipendente = identitaAggiunte.filter(
          (el) => el.sequ_k_identita === 6,
        ).map((el1) => ({
          ...el1,
          fk_sequ_utente_trusted_responsabile: e.sequ_k_utente_trusted,
          azione: 'aggiungi',
        }))[0];
        if (!identitaDipendente) {
          // eslint-disable-next-line prefer-destructuring
          identitaDipendente = identitaScelte.filter(
            (el) => el.sequ_k_identita === 6,
          ).map((el1) => ({
            ...el1,
            fk_sequ_utente_trusted_responsabile: e.sequ_k_utente_trusted,
            azione: 'aggiungi',
          }))[0];
        }
        setIdentitaAggiunte((prevState) => [
          ...prevState.filter((el) => el.sequ_k_identita !== 6),
          identitaDipendente]);
      }
    } else if (config?.name === 'fk_sequ_profilo_ruolo') {
      setUtente({ ...utente, fk_sequ_profilo_ruolo: e.sequ_k_profilo_ruolo });
    } else {
      const { name, value } = e.target;
      setUtente({ ...utente, [name]: value });
    }
  };

  // Function collegata al servizio di modifica identità utente
  async function modificaUtente() {
    const identitaBody = [];
    const nuoveAggiunte = [...new Set(identitaAggiunte)];
    const nuoveRimosse = [...new Set(identitaRimosse)];
    nuoveRimosse.forEach((el) => {
      identitaBody.push({ ...el, azione: 'elimina' });
    });
    nuoveAggiunte.forEach((el) => {
      identitaBody.push({ ...el, azione: el?.azione !== 'modifica' ? 'aggiungi' : 'modifica' });
    });
    utenteService.modificaUtente({
      id: utente.sequ_k_utente_trusted,
      trusted: true,
      identita: identitaBody,
      desc_email: utente.desc_email,
      desc_area_contrattuale: utente.desc_area_contrattuale,
      desc_profilo_accesso: utente.desc_profilo_accesso,
      desc_struttura_organizzativa_i_liv: utente.desc_struttura_organizzativa_i_liv,
      desc_struttura_organizzativa_ii_liv: utente.desc_struttura_organizzativa_ii_liv,
      fk_sequ_profilo_ruolo: utente.fk_sequ_profilo_ruolo,
    })
      .then((res) => {
        setContext(() => ({
          ...context, openConferma: true, testo: res.message,
        }));
        history.goBack();
      })
      .catch((err) => {
        setOpenCloseModaleConferma();
        setContext(() => ({
          ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
        }));
      });
  }

  // Function collegata al servizio per ottenere lo storico dei profili ruolo dell'utente
  async function getStoricoProfiliRuoloByDipendente(idDipendente) {
    try {
      const response = await AssessmentService
        .getStoricoProfiliRuoloByDipendente(idDipendente);
      setStoricoProfilo(response);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // Function collegata al servizio di get di un utente
  async function getUtente() {
    const response = await utenteService.getUtenteById(
      idUtente,
      { trusted: query.get('trusted'), ...filtriUtente },
    );
    setUtente({
      ...response,
      fk_sequ_profilo_ruolo: response.profilo_ruolo_associato?.fk_sequ_profilo_ruolo ?? null,
    });
    setIdentitaScelte(response.identita_associate);
    if (idAmministrazione) {
      getStoricoProfiliRuoloByDipendente(response.sequ_k_utente_trusted);
    }
  }

  // Function collegata al servizio di get della lista delle identità
  async function getListaIdentita(offset, limit, page) {
    const response = await identitaService
      .getIdentita({ ...filtriIdentita }, offset, limit);
    setListaIdentita(response.data);
    setPaginationIdentita({
      page,
      sizePerPage: limit,
      totalSize: response.totalRowCount,
    });
  }

  // Function collegata al servizio di get della lista dei responsabili
  async function getListaResponsabili() {
    const response = await canaliService
      .getUtentiByCanali({ showResponsabile: true }, 0, 10);
    setListaResponsabili(response.data);
  }

  // Function collegata al servizio per ottenere la lista dei Profili di Ruolo
  async function getProfiliRuolo(offset, limit, comparto) {
    try {
      const filterSession = { fk_sequ_comparto: comparto };
      const response = await SistemaProfessionaleService
        .getProfiliRuolo(filterSession, offset, limit);
      setProfiliRuoloList(response.rows);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  function handleModaleIdentitaStatus() {
    setFiltriIdentita({
      fk_sequ_tag_identita: null,
      desc_nome_identita: '',
      fk_codi_tipo_identita: null,
      profondita_storica: false,
    });
    getListaIdentita(0, 10, 1);
    setShowModalIdentita(!showModalIdentita);
  }

  // Function per gestire l'handleChange della tabella delle identità associate
  const handleTableChangeIdentita = (type, { page, sizePerPage }) => {
    let currentIndex;
    if (page !== 0) {
      currentIndex = (page - 1) * sizePerPage;
    }
    getListaIdentita(currentIndex, sizePerPage, page);
  };

  // useEffect
  useEffect(() => {
    getListaIdentita(0, 10, 1);
    getListaResponsabili();
    getUtente();
    getProfiliRuolo(0, 1000, sequ_k_comparto); // IMPOSTATO LIMIT 1000
  }, []);

  // useEffect
  useEffect(() => {
    getUtente();
  }, [filtriUtente]);

  return (
    <>
      <TitlePage
        title="Modifica identità utente"
        subtitle="È possibile modificare le identità ad esso associate"
        showIndietro
        functionIndietro={() => history.goBack()}
      />
      <Form className="mt-3">
        <Row className="mb-3">
          <Form.Group as={Col} lg controlId="desc_nome">
            <Form.Label style={{ fontWeight: 'bold' }}>
              Nome
            </Form.Label>
            <Form.Control
              name="desc_nome"
              placeholder="Nome"
              value={utente.desc_nome}
              disabled
            />
          </Form.Group>

          <Form.Group as={Col} lg controlId="desc_cognome">
            <Form.Label style={{ fontWeight: 'bold' }}>
              Cognome
            </Form.Label>
            <Form.Control
              name="desc_cognome"
              placeholder="Cognome"
              value={utente.desc_cognome}
              disabled
            />
          </Form.Group>
          <Form.Group as={Col} lg controlId="codi_id_fiscale">
            <Form.Label style={{ fontWeight: 'bold' }}>
              Codice fiscale
            </Form.Label>
            <Form.Control
              name="codi_id_fiscale"
              placeholder="Codice fiscale"
              value={utente.codi_id_fiscale}
              disabled
            />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} lg controlId="data_registrazione">
            <Form.Label style={{ fontWeight: 'bold' }}>
              Data registrazione
            </Form.Label>
            <Form.Control
              name="data_registrazione"
              placeholder="Data registrazione"
              value={formatoData(utente.data_registrazione)}
              disabled
            />
          </Form.Group>
          <Form.Group as={Col} lg controlId="desc_email">
            <Form.Label style={{ fontWeight: 'bold' }}>
              Indirizzo email
            </Form.Label>
            <Form.Control
              name="desc_email"
              placeholder="Indirizzo email"
              value={utente.desc_email}
              onChange={(e) => onChangeModifica(e)}
              isInvalid={errors?.desc_email}
              required
              disabled={idIdentita === 3}
            />
            <Form.Control.Feedback type="invalid">
              *Campo E-mail obbligatorio
            </Form.Control.Feedback>
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} lg controlId="">
            <Form.Label style={{ fontWeight: 'bold' }}>
              Area Contrattuale
            </Form.Label>
            <Form.Control
              name="desc_area_contrattuale"
              placeholder="-"
              value={utente.desc_area_contrattuale}
              onChange={onChangeModifica}
              isInvalid={errors?.desc_area_contrattuale}
              required
              disabled={idIdentita === 3}
            />
            <Form.Control.Feedback type="invalid">
              *Campo Area Contrattuale obbligatorio
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} lg controlId="">
            <Form.Label style={{ fontWeight: 'bold' }}>
              Profilo di Accesso
            </Form.Label>
            <Form.Control
              name="desc_profilo_accesso"
              placeholder="-"
              value={utente.desc_profilo_accesso}
              onChange={onChangeModifica}
              disabled={idIdentita === 3}
            />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} lg controlId="">
            <Form.Label style={{ fontWeight: 'bold' }}>
              Struttura Organizzativa di I Livello
            </Form.Label>
            <Form.Control
              name="desc_struttura_organizzativa_i_liv"
              placeholder="-"
              value={utente.desc_struttura_organizzativa_i_liv}
              onChange={onChangeModifica}
              isInvalid={errors?.desc_struttura_organizzativa_i_liv}
              required
              disabled={idIdentita === 3}
            />
            <Form.Control.Feedback type="invalid">
              *Campo Struttura Organizzativa I Livello obbligatoria
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} lg controlId="">
            <Form.Label style={{ fontWeight: 'bold' }}>
              Struttura Organizzativa di II Livello
            </Form.Label>
            <Form.Control
              name="desc_struttura_organizzativa_ii_liv"
              placeholder="-"
              value={utente.desc_struttura_organizzativa_ii_liv}
              onChange={onChangeModifica}
              disabled={idIdentita === 3}
            />
          </Form.Group>
        </Row>

        {idAmministrazione && (
          <Row className="mb-3">
            <Form.Group as={Col} lg controlId="">
              <p className="fw-bold fs-3">
                Profilo di Ruolo associato all&apos;utente
              </p>
              <Form.Label style={{ fontWeight: 'bold' }}>
                Profilo di Ruolo corrente
              </Form.Label>
              <Select
                id="fk_sequ_profilo_ruolo"
                name="fk_sequ_profilo_ruolo"
                options={profiliRuoloList}
                placeholder="Scegli profilo di ruolo"
                getOptionLabel={(option) => option.desc_titolo}
                getOptionValue={(option) => option.sequ_k_profilo_ruolo}
                value={profiliRuoloList?.filter(
                  (el) => el.sequ_k_profilo_ruolo === utente.fk_sequ_profilo_ruolo,
                )?.[0]}
                onChange={(selectedOption, config) => onChangeModifica(selectedOption, config)}
                isDisabled={idIdentita === 2}
              />
            </Form.Group>
            <Form.Label style={{ fontWeight: 'bold', marginTop: '1rem' }}>
              Storico dei Profili di Ruolo dell&apos;utente
            </Form.Label>
            {storicoProfilo.filter((el) => el.data_fine_validita !== null).length > 0
              ? (
                <Table
                  data={storicoProfilo.filter((el) => el.data_fine_validita !== null)}
                  fields={fieldsStoricoProfili()}
                  keyField="desc_titolo"
                />
              ) : (
                <EmptyState
                  title="Nessun Profilo di Ruolo trovato"
                  subtitle="L'utente non possiede nessun storico profili"
                />
              )}
          </Row>
        )}

        <Row className="mb-3">
          <span>
            <Form.Label style={{ fontWeight: 'bold' }}>
              Identità da associare all&apos;utente*
            </Form.Label>
            <Button
              onClick={handleModaleIdentitaStatus}
              size="sm"
              disabled={idIdentita === 3}
            >
              <FontAwesomeIcon icon="fa-solid fa-plus" style={{ marginRight: '4px' }} />
              Aggiungi o rimuovi identità
            </Button>
          </span>
          {((identitaScelte.length > 0 && listaResponsabili.length > 0 && idAmministrazione)
          || (identitaScelte.length > 0 && !idAmministrazione)) ? (
            <>
              <p className="fw-bold fs-3">
                Elenco identità associate
              </p>
              <Table
                data={identitaScelte}
                fields={filtriUtente.profondita_storica_identita === 'true'
                  ? fieldsProfonditaStoricaIdentita(
                    onChangeModifica,
                    idAmministrazione,
                    idIdentita,
                    amministrazione,
                    listaResponsabili,
                    true,
                  )
                  : fieldsNoProfonditaStoricaIdentita(
                    onChangeModifica,
                    idAmministrazione,
                    idIdentita,
                    amministrazione,
                    listaResponsabili,
                    true,
                  )}
                keyField="sequ_k_rel_trusted_identita"
                profonditaStorica={(
                  <Form.Group controlId="profondita_storica_identita">
                    <Form.Label style={{ fontWeight: 'bold' }}>
                      Visualizza profondità storica
                    </Form.Label>
                    <Form.Select
                      onChange={onChangeFiltriUtente}
                      value={filtriUtente.profondita_storica_identita}
                    >
                      <option value="true">Sì</option>
                      <option value="false">No</option>
                    </Form.Select>
                  </Form.Group>
              )}
                onTableChange={(e, { sortField, sortOrder, data }) => {
                  data.sort((a, b) => {
                    if (sortOrder === 'asc') {
                      return a[sortField] > b[sortField] ? 1 : -1;
                    }
                    if (sortOrder === 'desc') {
                      return a[sortField] < b[sortField] ? 1 : -1;
                    }
                    return 0;
                  });
                }}
              />
            </>
            ) : (
              <EmptyState
                title="Nessuna identità scelta"
                subtitle="Associare le identità desiderate dal pulsante di modifica"
                marginBottom="3rem"
                marginTop="3rem"
              />
            )}
        </Row>
        <div className="d-flex justify-content-end">
          <Button
            variant="primary"
            className="text-uppercase"
            onClick={handleStateModaleConferma}
          >
            <FontAwesomeIcon aria-hidden="true" icon="save" />
            {' '}
            Salva
          </Button>
        </div>
        <ModaleConferma
          labelBottone="Conferma"
          coloreAzione="primary"
          title="Sei sicuro di procedere?"
          body={`L'operazione modificherà le identità dell'utente ${utente.codi_id_fiscale}`}
          show={openCloseModaleConferma}
          handleClose={handleStateModaleConferma}
          handleOk={modificaUtente}
        />
        <ModaleRicercaIdentitaModifica
          show={showModalIdentita}
          handleClose={handleModaleIdentitaStatus}
          identita={listaIdentita}
          getListaIdentita={getListaIdentita}
          identitaScelte={identitaScelte}
          setIdentitaScelte={setIdentitaScelte}
          handleTableChangeIdentita={handleTableChangeIdentita}
          paginationIdentita={paginationIdentita}
          setPaginationIdentita={setPaginationIdentita}
          onChangeFiltriIdentita={onChangeFiltriIdentita}
          identitaAggiunte={identitaAggiunte}
          identitaRimosse={identitaRimosse}
          setIdentitaAggiunte={setIdentitaAggiunte}
          setIdentitaRimosse={setIdentitaRimosse}
        />
      </Form>
    </>
  );
}

export default ModificaUtentePerAmministratore;
