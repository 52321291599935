import { Container, ProgressBar } from 'react-bootstrap';
import Select from 'react-select';
import getVariantColor from '../../../../utils/getVariantColor';

const fieldsCompetenzeProfilo = (
  competenzeList,
  optionsGrading,
) => {
  const regEx = /^[A-Z]+/;

  // Function per calcolare il numero di competenze per la tipologia
  const getNumberCompetenze = (tipo) => {
    const competenzeFiltered = optionsGrading.filter(
      (opt) => opt?.codi_tipo_competenza === tipo,
    );
    return competenzeFiltered.length;
  };

  return ([
    {
      dataField: 'codice_competenza',
      text: 'Codice',
      classes: 'column-data',
      headerStyle: () => ({ width: '10%' }),
    },
    {
      dataField: 'titolo_competenza',
      text: 'Competenza',
      classes: 'column-data',
      headerStyle: () => ({ width: '45%' }),
    },
    {
      dataField: 'codi_grading_assegnato',
      text: 'Grading',
      classes: 'column-data',
      headerStyle: () => ({ width: '45%' }),
      formatter: (e, row) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Select
            className="w-100"
            id="grading"
            name="grading"
            options={competenzeList}
            getOptionLabel={(option) => (
              <Container className="p-0">
                {option.titolo_grading}
                <ProgressBar
                  variant={
                    getVariantColor(
                      (option.codi_grading_assegnato / getNumberCompetenze(
                        row?.codice_competenza?.match(regEx)?.[0],
                      )) * 100,
                    )
                  }
                  label={option.codi_grading_assegnato}
                  now={(option.codi_grading_assegnato / getNumberCompetenze(
                    row?.codice_competenza?.match(regEx)?.[0],
                  )) * 100}
                />
              </Container>
            )}
            getOptionValue={(option) => option.codi_grading_assegnato}
            value={competenzeList.filter(
              (el) => el.codice_competenza === row.codice_competenza,
            )[0]}
            isDisabled
          />
        </div>
      ),
    },
  ]);
};

export default fieldsCompetenzeProfilo;
