const demoElencoAssessment = [
  {
    desc_dipendente: 'Mario Rossi',
    desc_stato: 'In auto-valutazione',
    desc_titolo: 'ISTRUTTORE GIURIDICO – AMMINISTRATIVO-Gestione giuridica ed economica',
  },
  {
    desc_dipendente: 'Giovanni Bianchi',
    desc_stato: 'In etero-valutazione',
    desc_titolo: 'ISTRUTTORE GIURIDICO – AMMINISTRATIVO-Gestione giuridica ed economica',
  },
  {
    desc_dipendente: 'Anna Verdi',
    desc_stato: 'Completo',
    desc_titolo: 'ISTRUTTORE GIURIDICO – AMMINISTRATIVO-Gestione giuridica ed economica',
  },
];

export default demoElencoAssessment;
