const optionsTipoCompetenza = [
  {
    codi_tipo_competenza: 'CTP',
    desc_tipo_competenza: 'Competenza Tecnico Professionale',
  },
  {
    codi_tipo_competenza: 'CTS',
    desc_tipo_competenza: 'Competenza Tecnico Specialistica',
  },
  {
    codi_tipo_competenza: 'CC',
    desc_tipo_competenza: 'Competenza Comportamentale',
  },
];

export default optionsTipoCompetenza;
