import { Form } from 'react-bootstrap';

const fieldsSelezioneCompetenze = (onCheck, competenzeListSelected) => (
  [
    {
      dataField: 'desc_codice',
      text: 'Codice',
      sort: true,
      classes: 'column-data',
      headerStyle: () => ({ width: '15%' }),
      formatter: (e, row) => (
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          {competenzeListSelected.includes(row.sequ_k_competenza) ? (
            <Form.Check
              checked
              disabled
              type="checkbox"
              color="success"
            />
          ) : (
            <Form.Check
              type="checkbox"
              id="fk_sequ_competenza"
              name="fk_sequ_competenza"
              onClick={(event) => onCheck(event, row)}
            />
          )}
          {row.desc_codice}
        </div>
      ),
    },
    {
      dataField: 'desc_titolo',
      text: 'Titolo',
      sort: true,
      classes: 'column-data',
      headerStyle: () => ({ width: '25%' }),
    },
    {
      dataField: 'desc_descrizione',
      text: 'Descrizione',
      sort: true,
      classes: 'column-data',
      headerStyle: () => ({ width: '60%' }),
    },
  ]
);

export default fieldsSelezioneCompetenze;
