const valoriQualitativi = [
  {
    label: 1,
    value: 0.2,
  },
  {
    label: 2,
    value: 0.4,
  },
  {
    label: 3,
    value: 0.6,
  },
  {
    label: 4,
    value: 0.8,
  },
  {
    label: 5,
    value: 1.0,
  },
];

export default valoriQualitativi;
