const demoGradingList = 
[
    {
        "sequ_k_grading": 7,
        "desc_titolo": "Competenza in maniera limitata",
        "desc_descrizione": "Possiede la competenza in maniera limitata tale da essere in grado di operare con autonomia parziale e sotto supervisione costante",
        "codi_valore": 1,
        "codi_tipo_competenza": "CTS"
    },
    {
        "sequ_k_grading": 1,
        "desc_titolo": "Competenza in maniera limitata",
        "desc_descrizione": "Possiede la competenza in maniera limitata tale da essere in grado di operare con autonomia parziale e sotto supervisione costante",
        "codi_valore": 1,
        "codi_tipo_competenza": "CTP"
    },
    {
        "sequ_k_grading": 13,
        "desc_titolo": "Mai o raramente",
        "desc_descrizione": "Agisce mai o raramente i comportamenti descritti",
        "codi_valore": 1,
        "codi_tipo_competenza": "CC"
    },
    {
        "sequ_k_grading": 8,
        "desc_titolo": "Competenza ad un livello di base",
        "desc_descrizione": "Possiede la competenza ad un livello di base tale da consentire la gestione di processi operativi con autonomia nelle situazioni conosciute",
        "codi_valore": 2,
        "codi_tipo_competenza": "CTS"
    },
    {
        "sequ_k_grading": 2,
        "desc_titolo": "Competenza ad un livello di base",
        "desc_descrizione": "Possiede la competenza ad un livello di base tale da consentire la gestione di processi operativi con autonomia nelle situazioni conosciute",
        "codi_valore": 2,
        "codi_tipo_competenza": "CTP"
    },
    {
        "sequ_k_grading": 14,
        "desc_titolo": "Sporadicamente",
        "desc_descrizione": "Agisce i comportamenti descritti sporadicamente, in condizioni generalmente favorevoli e a seguito di stimoli e sollecitazioni",
        "codi_valore": 2,
        "codi_tipo_competenza": "CC"
    },
    {
        "sequ_k_grading": 9,
        "desc_titolo": "Discreto livello della competenza",
        "desc_descrizione": "Dimostra un discreto livello della competenza, tale da essere in grado di gestire situazioni di complessità moderata con minima supervisione e con autonomia crescente in contesti standard",
        "codi_valore": 3,
        "codi_tipo_competenza": "CTS"
    },
    {
        "sequ_k_grading": 3,
        "desc_titolo": "Discreto livello della competenza",
        "desc_descrizione": "Dimostra un discreto livello della competenza, tale da essere in grado di gestire situazioni di complessità moderata con minima supervisione e con autonomia crescente in contesti standard",
        "codi_valore": 3,
        "codi_tipo_competenza": "CTP"
    },
    {
        "sequ_k_grading": 15,
        "desc_titolo": "Occasionalmente",
        "desc_descrizione": "Agisce occasionalmente i comportamenti descritti, nei contesti conosciuti, anche senza una diretta sollecitazione",
        "codi_valore": 3,
        "codi_tipo_competenza": "CC"
    },
    {
        "sequ_k_grading": 10,
        "desc_titolo": "Avanzato livello della competenza",
        "desc_descrizione": "Evidenzia un avanzato livello della competenza, integrando aspetti specifici e vista di insieme, tale da agire efficacemente con un buon livello di autonomia in situazioni anche non note e mediamente complesse",
        "codi_valore": 4,
        "codi_tipo_competenza": "CTS"
    },
    {
        "sequ_k_grading": 4,
        "desc_titolo": "Avanzato livello della competenza",
        "desc_descrizione": "Evidenzia un avanzato livello della competenza, integrando aspetti specifici e vista di insieme, tale da agire efficacemente con un buon livello di autonomia in situazioni anche non note e mediamente complesse",
        "codi_valore": 4,
        "codi_tipo_competenza": "CTP"
    },
    {
        "sequ_k_grading": 16,
        "desc_titolo": "Frequentemente",
        "desc_descrizione": "Agisce i comportamenti descritti frequentemente, anche in contesti nuovi o mediamente complessi",
        "codi_valore": 4,
        "codi_tipo_competenza": "CC"
    },
    {
        "sequ_k_grading": 11,
        "desc_titolo": "Eccellente padronanza",
        "desc_descrizione": "Mostra un'eccellente padronanza degli aspetti generali della competenza e una approfondita padronanza rispetto a quelli specifici della stessa, tali da essere in grado di agire con autonomia situazioni complesse e nuove, fungere da riferimento per altri,  in contesti standard e complessi",
        "codi_valore": 5,
        "codi_tipo_competenza": "CTS"
    },
    {
        "sequ_k_grading": 5,
        "desc_titolo": "Eccellente padronanza",
        "desc_descrizione": "Mostra un'eccellente padronanza degli aspetti generali della competenza e una approfondita padronanza rispetto a quelli specifici della stessa, tali da essere in grado di agire con autonomia situazioni complesse e nuove, fungere da riferimento per altri,  in contesti standard e complessi",
        "codi_valore": 5,
        "codi_tipo_competenza": "CTP"
    },
    {
        "sequ_k_grading": 17,
        "desc_titolo": "Costantemente",
        "desc_descrizione": "Agisce i comportamenti descritti costantemente, nelle situazioni standard e nei contesti complessi, anche sotto pressione o in situazioni imprevedibili",
        "codi_valore": 5,
        "codi_tipo_competenza": "CC"
    },
    {
        "sequ_k_grading": 6,
        "desc_titolo": "Padronanza completa",
        "desc_descrizione": "Presenta padronanza completa della competenza, è capace di affrontare in completa autonomia situazioni complesse, nuove e impreviste. Costituisce un punto di riferimento avanzato e un mentore per gli altri, supportandone lo sviluppo e identificando e diffondendo buone pratiche",
        "codi_valore": 6,
        "codi_tipo_competenza": "CTP"
    },
    {
        "sequ_k_grading": 18,
        "desc_titolo": "Sempre",
        "desc_descrizione": "Agisce sempre i comportamenti descritti, indipendentemente dalla complessità o novità della situazione, ne promuove attivamente l'adozione, fungendo da punto di riferimento per gli altri",
        "codi_valore": 6,
        "codi_tipo_competenza": "CC"
    },
    {
        "sequ_k_grading": 12,
        "desc_titolo": "Padronanza completa",
        "desc_descrizione": "Presenta padronanza completa della competenza, è capace di affrontare in completa autonomia situazioni complesse, nuove e impreviste. Costituisce un punto di riferimento avanzato e un mentore per gli altri, supportandone lo sviluppo e identificando e diffondendo buone pratiche",
        "codi_valore": 6,
        "codi_tipo_competenza": "CTS"
    }
]

export default demoGradingList;