import React from 'react';
import { Breadcrumb } from 'react-bootstrap';
import './index.css';

function BreadcrumbComponent({
  listItem,
}) {
  return (
    <Breadcrumb>
      <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
      {listItem.map((item) => (
        <Breadcrumb.Item href={item.pathname} active={item.active}>
          {item.name}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
}

export default BreadcrumbComponent;
