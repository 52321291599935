/* eslint-disable no-lonely-if */
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Table from '../../../../../components/Shared/Table';
import fieldsFullShowScelteCopertura from '../fields/fieldsFullShowScelteCopertura';
import formatCentinaia from '../../../../../utils/formatCentinaia';

function ScelteCoperturaFullShowPolicyMaker({
  tableData,
  setTableData,
  processoState,
  blockTable,
}) {
  const [totaleCostoRichiesta, setTotaleCostoRichiesta] = useState(0);
  const [totaleUnitaAllocate, setTotaleUnitaAllocate] = useState(0);
  const [totaleUnitaCessazione, setTotaleUnitaCessazione] = useState(0);
  const [totaleUnitaRichieste, setTotaleUnitaRichieste] = useState(0);

  // PER CALCOLARE IL COSTO TOTALE DELLA RIGA
  const calcolaCostoInTable = (row) => {
    const totale = row.scelte_copertura
      .reduce(
        (acc, item) => (
          acc + (
            (item.ordine <= 4)
              ? item.unita * row.costo_annuale
              : (item.ordine === 5) ? item.unita * row.differenza
                : 0
          )
        ),
        0,
      );

    return totale;
  };

  // PER CALCOLARE IL TOTALE DEI COSTI DELLA TABELLA
  const calcolaTotaleCostiInTable = () => {
    const totale = tableData
      .reduce(
        (acc, item) => acc + (
          calcolaCostoInTable(item)
        ),
        0,
      );
    return totale;
  };

  // // PER CALCOLARE IL TOTALE DLLE UNITA' PROGRAMMATE
  const calcolaTotaleUnitaProgrammate = () => {
    let totale = 0;
    tableData.forEach(
      (profilo) => profilo.scelte_copertura.forEach(
        (scelta) => { totale += scelta.unita; },
      ),
    );
    return `${totale.toString()}`;
  };

  // PER CALCOLARE LE UNITA' PROGRAMMATE DI UNA RIGA DI TABELLA
  const calcolaUnitaProgrammate = (row, isUpdatingState) => {
    const unitaProgrammate = row.scelte_copertura.reduce(
      (acc, item) => acc + (item.unita),
      0,
    );
    if (isUpdatingState) {
      setTableData((prevState) => prevState.map(
        (el) => (el.desc_codice === row.desc_codice ? {
          ...el,
          unita_programmate: unitaProgrammate,
        } : el),
      ));
    }
    return unitaProgrammate;
  };

  // PER CALCOLARE IL TOTALE DEI COSTI DELLE RICHIESTE
  const calcolaTotaleCostiRichieste = () => {
    let totale = 0;
    processoState?.strutture_organizzative?.forEach(
      (struttura) => { totale += struttura.totale_costo_richiesta; },
    );
    return totale;
  };

  useEffect(() => {
    const totaleCostiRichieste = calcolaTotaleCostiRichieste();
    setTotaleCostoRichiesta(totaleCostiRichieste);
  });

  return (
    <>
      <div className="">
        <div className="d-flex gap-5 flex-wrap flex-lg-nowrap">
          <div className="d-flex flex-column gap-4 mb-5">
            <table className="table-border-thin">
              <tbody>
                <tr>
                  <td className="border border-dark border-2 p-1 fw-bold">Budget Assunzionale</td>
                  <td className="border border-dark border-2 p-1 fw-bold">Costo totale delle richieste</td>
                  <td className="border border-dark border-2 p-1 fw-bold">Scostamento richiesta</td>
                  <td className="border border-dark border-2 p-1 fw-bold">Costo totale delle opzioni di copertura</td>
                  <td className="border border-dark border-2 p-1 fw-bold">Residuo CA</td>
                </tr>
                <tr>
                  <td style={{ minWidth: '6rem' }} className="border border-dark border-2 p-1 text-center fw-bold">
                    {formatCentinaia(processoState.budget_assunzionale)}
                    &euro;
                  </td>
                  <td style={{ minWidth: '6rem' }} className="border border-dark border-2 p-1 text-center fw-bold">
                    {formatCentinaia(totaleCostoRichiesta)}
                    &euro;
                  </td>
                  <td style={{ minWidth: '6rem' }} className="border border-dark border-2 p-1 text-center fw-bold">
                    <span className="text-start">
                      {((processoState.budget_assunzionale - totaleCostoRichiesta) > 0) ? (<FontAwesomeIcon icon="fa-solid fa-up-long" style={{ color: '#198754' }} />)
                        : ((processoState.budget_assunzionale - totaleCostoRichiesta) < 0) ? (<FontAwesomeIcon icon="fa-solid fa-down-long" style={{ color: '#dc3545' }} />)
                          : (<FontAwesomeIcon icon="fa-solid fa-equals" style={{ color: '#3c9dff' }} />) }
                    </span>
                    <span className="text-center">
                      {formatCentinaia(processoState.budget_assunzionale - totaleCostoRichiesta)}
                      €
                    </span>
                  </td>
                  <td style={{ minWidth: '6rem' }} className="border border-dark border-2 p-1 text-center fw-bold">
                    {formatCentinaia(calcolaTotaleCostiInTable())}
                    &euro;
                  </td>
                  <td style={{ minWidth: '6rem' }} className="border border-dark border-2 p-1 text-center fw-bold">
                    <span className="text-start">
                      {((processoState.budget_assunzionale - calcolaTotaleCostiInTable()) > 0) ? (<FontAwesomeIcon icon="fa-solid fa-up-long" style={{ color: '#198754' }} />)
                        : ((processoState.budget_assunzionale - calcolaTotaleCostiInTable()) < 0) ? (<FontAwesomeIcon icon="fa-solid fa-down-long" style={{ color: '#dc3545' }} />)
                          : (<FontAwesomeIcon icon="fa-solid fa-equals" style={{ color: '#3c9dff' }} />) }
                    </span>
                    <span className="text-center">
                      {formatCentinaia(processoState.budget_assunzionale
                        - calcolaTotaleCostiInTable())}
                      &euro;
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div>
          <table className="table-border-thin">
            <tbody>
              <tr>
                <td className="border border-dark border-2 p-1 fw-bold">Unità di personale allocate presso la struttura</td>
                <td className="border border-dark border-2 p-1 fw-bold">Unità di personale in uscita nel triennio oggetto di programmmazione</td>
                <td className="border border-dark border-2 p-1 fw-bold">Unità di personale richieste</td>
                <td className="border border-dark border-2 p-1 fw-bold">Unità programmate</td>
              </tr>
              <tr>
                <td style={{ minWidth: '6rem' }} className="border border-dark border-2 p-1 text-center fw-bold">{totaleUnitaAllocate}</td>
                <td style={{ minWidth: '6rem' }} className="border border-dark border-2 p-1 text-center fw-bold">{totaleUnitaCessazione}</td>
                <td style={{ minWidth: '6rem' }} className="border border-dark border-2 p-1 text-center fw-bold">{totaleUnitaRichieste}</td>
                <td style={{ minWidth: '6rem' }} className="border border-dark border-2 p-1 text-center fw-bold">{calcolaTotaleUnitaProgrammate()}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="mt-5">
        <h4 className="fw-bold mb-3">Profili Richiesti</h4>
        <div className="overflow-x-auto">
          <Table
            data={tableData}
            fields={fieldsFullShowScelteCopertura(
              setTotaleUnitaAllocate,
              setTotaleUnitaCessazione,
              setTotaleUnitaRichieste,
              calcolaCostoInTable,
              calcolaTotaleCostiInTable,
              calcolaTotaleUnitaProgrammate,
              calcolaUnitaProgrammate,
              tableData,
              processoState,
              blockTable,
            )}
            keyField="_id"
          />
        </div>
      </div>
    </>
  );
}

export default ScelteCoperturaFullShowPolicyMaker;
