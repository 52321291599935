function BodyModaleSollecito({
  listaSolleciti,
}) {
  return (
    <>
      <h5>Attenzione:</h5>
      <p>
        Attraverso questa schermata è possibile inviare un sollecito
        all&apos;utente selezionato. Cliccare
        {' '}
        <b>chiudi</b>
        {' '}
        per annullare l&apos;operazione.
      </p>
      {listaSolleciti.length > 0 && (
      <>
        <p>
          Ulteriori solleciti sono stati inviati nelle seguenti date:
        </p>
        <ul>
          {listaSolleciti.map((item) => (
            <li>{item.data_creazione}</li>
          ))}
        </ul>
      </>
      )}
      <p>
        Clicca
        <b> invia</b>
        {' '}
        per procedere con l&apos;invio di un sollecito.
      </p>
    </>
  );
}

export default BodyModaleSollecito;
