import React from 'react';
import './index.css';

function SpinnerBody() {
  return (
    <div className="lds-container d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
      <div className="inline-block align-middle text-center">
        <div className="lds-ring">
          <div />
          <div />
          <div />
          <div />
        </div>
        <h1 className="font-weight-normal lead mt-3" id="desc">Caricamento...</h1>
      </div>
    </div>

  );
}

export default SpinnerBody;
