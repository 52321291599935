import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import themeColors from '../../../utils/contants/theme-colors';
import { controlla } from '../../../utils/verificaPermessi';

const fieldsFamiglieProfessionali = (onDelete, onView, onModify, idAmministrazione) => (
  [
    {
      dataField: 'desc_codice',
      text: 'Codice',
      sort: true,
      classes: 'column-data',
      headerStyle: () => ({ width: '10%' }),
    },
    {
      dataField: 'desc_titolo',
      text: 'Titolo',
      sort: true,
      classes: 'column-data',
      headerStyle: () => ({ width: '30%' }),
    },
    {
      dataField: 'fk_sequ_comparto_d_comparto.desc_comparto',
      text: 'Comparto',
      classes: 'column-data',
      headerStyle: () => ({ width: '20%' }),
    },
    {
      text: 'Banca Dati Unica',
      classes: 'column-data',
      headerStyle: () => ({ width: '15%' }),
      formatter: (e, row) => (
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          {row.fk_codi_amministrazione === null && (
            <FontAwesomeIcon
              aria-hidden="true"
              icon={['fas', 'check']}
              size="sm"
            />
          )}
        </div>
      ),
    },
    {
      dataField: 'df1',
      isDummyField: true,
      text: 'Azioni',
      headerStyle: () => ({ width: '15%' }),
      formatter: (e, row) => (
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <FontAwesomeIcon
            aria-hidden="true"
            icon={['fas', 'search']}
            size="lg"
            title="Visualizza"
            id={`Visualizza${row.sequ_k_famiglia_professionale}`}
            style={{ color: themeColors.primary }}
            onClick={() => onView(row.sequ_k_famiglia_professionale)}
          />
          {(controlla('X43') && row.fk_codi_amministrazione === idAmministrazione) && (
            <FontAwesomeIcon
              aria-hidden="true"
              icon={['fas', 'edit']}
              size="lg"
              title="Modifica"
              id={`Modifica${row.sequ_k_famiglia_professionale}`}
              style={{ color: themeColors.primary }}
              onClick={() => onModify(row.sequ_k_famiglia_professionale)}
            />
          )}
          {(controlla('X43') && row.fk_codi_amministrazione === idAmministrazione) && (
            <FontAwesomeIcon
              aria-hidden="true"
              icon={['fas', 'trash']}
              size="lg"
              title="Elimina"
              id={`Visualizza${row.sequ_k_famiglia_professionale}`}
              style={{ color: themeColors.danger }}
              onClick={() => onDelete(
                { id: row.sequ_k_famiglia_professionale, codice: row.desc_codice },
              )}
            />
          )}
        </div>
      ),
    },
  ]
);

export default fieldsFamiglieProfessionali;
