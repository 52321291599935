import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';

function TabsBar({
  title1,
  title2,
  title3,
  function1,
  function2,
  function3,
}) {
  const [btnColor1, setBtnColor1] = useState('link');
  const [btnColor2, setBtnColor2] = useState('white');
  const [btnColor3, setBtnColor3] = useState('white');

  return (
    <div>
      <Row className="justify-content-center">
        <Col className="col-auto">
          <Button
            variant={btnColor1}
            onClick={() => {
              setBtnColor1('link');
              setBtnColor2('white');
              setBtnColor3('white');
              function1();
            }}
          >
            <FontAwesomeIcon aria-hidden="true" icon="list-alt" className="me-2" />
            {title1}
          </Button>
        </Col>
        <Col className="col-auto">
          <Button
            variant={btnColor2}
            onClick={() => {
              setBtnColor1('white');
              setBtnColor2('link');
              setBtnColor3('white');
              function2();
            }}
          >
            <FontAwesomeIcon aria-hidden="true" icon="tasks" className="me-2" />
            {title2}
          </Button>
        </Col>
        <Col className="col-auto">
          <Button
            variant={btnColor3}
            onClick={() => {
              setBtnColor1('white');
              setBtnColor2('white');
              setBtnColor3('link');
              function3();
            }}
          >
            <FontAwesomeIcon aria-hidden="true" icon="check-square" className="me-2" />
            {title3}
          </Button>
        </Col>
      </Row>
      <div className="separator border-1 border-bottom" />
    </div>
  );
}

export default TabsBar;
