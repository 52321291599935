import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import LoginInfo from '../../../components/Account/LoginInfo/index';
import UserContacts from '../../../components/Account/UserContacts/index';
import UserInfo from '../../../components/Account/UserInfo';
import Title from '../../../components/GestisciUtenti/TitoloPagina/index';
import utenteService from '../../../services/utentiService';
import { Context } from '../../../utils/Context';
import history from '../../../utils/history';
import Regex from '../../../utils/regex';
import { getItem } from '../../../utils/storage';
import Table from '../../../components/Shared/Table';
import fields from './fields';

function UserInformation() {
  const [context, setContext] = useContext(Context);
  const [user, setUser] = useState({});

  async function salvaUtente() {
    if (Regex.mail.test(user.desc_email)) {
      const body = {};
      body.trusted = (JSON.parse(getItem('user'))).trusted;
      body.desc_email = user.desc_email;
      body.untrusted = user.utenti_untrusted_associati;
      utenteService.modificaUtentePerUtente(body)
        .then(async (res) => {
          setContext(() => ({
            ...context, openConferma: true, testo: res.message,
          }));
          setUser(await utenteService.getUtenteByIdUtente());
        })
        .catch((err) => {
          setContext(() => ({
            ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: 400,
          }));
        });
    } else {
      setContext(() => ({
        ...context, open: true, testoErrore: 'Mail non in un formato valido', statusCode: 400,
      }));
    }
  }

  function onChange(e) {
    const { id } = e.target;
    const { value } = e.target;
    setUser({
      ...user,
      [id]: value,
    });
  }

  async function start() {
    setUser(await utenteService.getUtenteByIdUtente());
  }

  useEffect(() => {
    start();
  }, []);

  return (
    <div>
      <Title
        title="Informazioni utente"
        showIndietro
        functionIndietro={() => history.goBack()}
      />
      <LoginInfo user={user} />
      <hr />
      <UserContacts user={user} onChange={onChange} />
      <hr />
      <UserInfo user={user} />
      <hr />
      <h3 style={{ fontSize: '1.75rem' }} className="mb-3">Elenco identità</h3>
      {user.identita_associate && (
      <Table
        data={user.identita_associate}
        fields={fields}
        keyField="sequ_k_utente_untrusted"
      />
      )}
      <div className="d-flex justify-content-end">
        <Button variant="primary" className="text-uppercase" onClick={salvaUtente}>
          <FontAwesomeIcon aria-hidden="true" icon="save" />
          {' '}
          Salva
        </Button>
      </div>
    </div>
  );
}

export default UserInformation;
