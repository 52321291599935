export default function formatCentinaia(value, noDecimal) {
  let valueChanged = value;
  if (!value && value !== 0) {
    return null;
  }
  if (noDecimal) {
    valueChanged = value.toString().replace(/\./g, '');
  }
  // controlla che non siano inseriti valori non numerici
  if (!(/^-?\d+(,\d+)?$/.test(valueChanged))) {
    valueChanged = value.toString().replace(/[^0-9]/g, '');
  }
  // Assicura che il valore sia sempre un numero (inclusi valori negativi)
  const numero = typeof valueChanged === 'number' ? valueChanged : parseFloat(valueChanged);
  if (isNaN(numero)) {
    throw new Error('Il valore fornito non è un numero valido.');
  }
  // Divide la parte intera e la parte decimale
  const [parteIntera, parteDecimale] = numero.toFixed(2).split('.');
  // Formatta la parte intera con i punti
  const parteInteraFormattata = parseInt(parteIntera, 10).toLocaleString('it-IT');
  if (noDecimal) {
    return parteInteraFormattata;
  }

  return parteDecimale ? `${parteInteraFormattata},${parteDecimale}` : parteInteraFormattata;
}
