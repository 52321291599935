import api from '../utils/api';

const accreditamentoUrl = process.env.REACT_APP_ACCREDITAMENTO_URL;

async function getRichiesteIdentita(filtri, offset, limit) {
  let params = '';
  if (filtri.codi_stato_richiesta) {
    params += `codi_stato_richiesta=${filtri.codi_stato_richiesta}&`;
  }
  if (filtri.data_richiesta_from) {
    params += `data_richiesta_from=${filtri.data_richiesta_from}&`;
  }
  if (filtri.data_richiesta_to) {
    params += `data_richiesta_to=${filtri.data_richiesta_to}&`;
  }
  if (offset || offset === 0) {
    params += `offset=${offset}&`;
  }
  if (limit) {
    params += `limit=${limit}&`;
  }
  return api.get(`${accreditamentoUrl}/gestione-richiesta-identita-admin?${params}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function getRichiestaIdentitaById(id) {
  return api.get(`${accreditamentoUrl}/gestione-richiesta-identita-utente/${id}`)
    .then((res) => res && res.data[0])
    .catch((err) => Promise.reject(err.response));
}

async function getRichiestaIdentitaByIdAdmin(id) {
  return api.get(`${accreditamentoUrl}/gestione-richiesta-identita-admin/${id}`)
    .then((res) => res && res.data[0])
    .catch((err) => Promise.reject(err.response));
}

async function presaIncaricoRichiesta(id) {
  return api.patch(`${accreditamentoUrl}/gestione-richiesta-identita-admin/${id}/carico`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function evadiRichiesta(id, idComparto) {
  return api.patch(`${accreditamentoUrl}/gestione-richiesta-identita-admin/${id}/evadi`, {
    fk_sequ_comparto: idComparto,
  })
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function negaRichiesta(id, risposta) {
  return api.patch(`${accreditamentoUrl}/gestione-richiesta-identita-admin/${id}/nega`, { desc_msg_risposta: risposta })
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function getRichiesteIdentitaPerUtente(filtri, offset, limit) {
  let params = '';
  if (filtri.codi_stato_richiesta) {
    params += `codi_stato_richiesta=${filtri.codi_stato_richiesta}&`;
  }
  if (filtri.data_richiesta_from) {
    params += `data_richiesta_from=${filtri.data_richiesta_from}&`;
  }
  if (filtri.data_richiesta_to) {
    params += `data_richiesta_to=${filtri.data_richiesta_to}&`;
  }
  if (offset || offset === 0) {
    params += `offset=${offset}&`;
  }
  if (limit) {
    params += `limit=${limit}&`;
  }
  return api.get(`${accreditamentoUrl}/gestione-richiesta-identita-utente/?${params}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function deleteRichiesta(id) {
  return api.delete(`${accreditamentoUrl}/gestione-richiesta-identita-utente/${id}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function salvaRichiesta(richiesta) {
  return api.patch(`${accreditamentoUrl}/gestione-richiesta-identita-utente/salva`, richiesta)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function getListaIpa(denominazione) {
  return api.get(`${accreditamentoUrl}/gestione-richiesta-identita-utente/ipa/list/${denominazione}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function getListaIpaAdmin(denominazione) {
  return api.get(`${accreditamentoUrl}/gestione-richiesta-identita-admin/ipa/list/${denominazione}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function uploadPdf(id, file) {
  const formData = new FormData();
  formData.append('file', file);
  return api.post(`${accreditamentoUrl}/gestione-richiesta-identita-utente/${id}/upload-file`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function deleteFileRichiesta(id) {
  return api.delete(`${accreditamentoUrl}/gestione-richiesta-identita-utente/${id}/elimina-file`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function inviaRichiesta(id) {
  return api.patch(`${accreditamentoUrl}/gestione-richiesta-identita-utente/${id}/invia`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function getStati() {
  return api.get(`${accreditamentoUrl}/gestione-stato-richiesta-identita`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function attivaRichiesta(hash) {
  return api.patch(`${accreditamentoUrl}/gestione-richiesta-identita-utente/${hash}/attiva`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function getIpaByCodi(ipa) {
  return api.get(`${accreditamentoUrl}/gestione-richiesta-identita-utente/ipa/${ipa}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function getIpaByCodiAdmin(ipa) {
  return api.get(`${accreditamentoUrl}/gestione-richiesta-identita-admin/ipa/${ipa}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

const richiestIdentitaService = {
  getRichiesteIdentita,
  getRichiestaIdentitaById,
  getRichiestaIdentitaByIdAdmin,
  presaIncaricoRichiesta,
  evadiRichiesta,
  negaRichiesta,
  getRichiesteIdentitaPerUtente,
  deleteRichiesta,
  salvaRichiesta,
  getListaIpa,
  getListaIpaAdmin,
  deleteFileRichiesta,
  uploadPdf,
  inviaRichiesta,
  getStati,
  attivaRichiesta,
  getIpaByCodi,
  getIpaByCodiAdmin,
};

export default richiestIdentitaService;
