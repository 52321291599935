import api from '../utils/api';
import createQueryString from '../utils/createQueryString';

const faqUrl = process.env.REACT_APP_NEWS_FAQ_URL;

// Functions per le chiamate alle FAQ INTERNE
async function getListaFaq(filtri, offset, limit) {
  const filters = {
    ...filtri, offset, limit,
  };
  const params = createQueryString(filters);
  return api.get(`${faqUrl}/faq?${params}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function getFaqById(id) {
  return api.get(`${faqUrl}/faq/${id}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function createFaq(faq) {
  return api.post(`${faqUrl}/faq`, faq)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function deleteFaq(faqId) {
  return api.delete(`${faqUrl}/faq/${faqId}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function editFaq(id, body) {
  return api.patch(`${faqUrl}/faq/${id}`, body)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

// Functions per le chiamate alle FAQ ESTERNE (pubbliche)
async function getListaFaqPubbliche(filtri, offset, limit) {
  const filters = {
    ...filtri, offset, limit,
  };
  const params = createQueryString(filters);
  return api.get(`${faqUrl}/faq-esterne?${params}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function getFaqPubblicaById(id) {
  return api.get(`${faqUrl}/faq-esterne/${id}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function createFaqPubblica(faq) {
  return api.post(`${faqUrl}/faq-esterne`, faq)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function deleteFaqPubblica(faqId) {
  return api.delete(`${faqUrl}/faq-esterne/${faqId}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

async function editFaqPubblica(id, body) {
  return api.patch(`${faqUrl}/faq-esterne/${id}`, body)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

const faqService = {
  getListaFaq,
  getFaqById,
  createFaq,
  deleteFaq,
  editFaq,
  getListaFaqPubbliche,
  getFaqPubblicaById,
  createFaqPubblica,
  deleteFaqPubblica,
  editFaqPubblica,
};

export default faqService;
