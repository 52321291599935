const optionsProfiliPerArea = (valori) => ({
  title: {
    text: 'Profili Professionali per Area Contrattuale',
    top: '5%',
    left: 'center',
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'shadow',
    },
  },
  legend: {
    data: [
      'Numero Profili Professionali Banca Dati Amministrazioni',
      'Numero Profili Professionali Banca Dati Unica',
    ],
    bottom: '0%',
  },
  grid: {
    top: '20%',
    left: '3%',
    right: '4%',
    bottom: '15%',
    containLabel: true,
  },
  xAxis: {
    type: 'category',
    data: valori.map((el) => el['Area Contrattuale']),
    axisLabel: {
      rotate: 30,
      interval: 0,
    },
  },
  yAxis: {
    type: 'value',
  },
  series: [
    {
      name: 'Numero Profili Professionali Banca Dati Amministrazioni',
      type: 'bar',
      data: valori.map((el) => ({
        value: parseInt(el['Numero Profili Professionali Banca Dati Amministrazioni'], 10),
        name: el['Area Contrattuale'],
      })),
      itemStyle: {
        emphasis: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)',
        },
      },
    },
    {
      name: 'Numero Profili Professionali Banca Dati Unica',
      type: 'bar',
      data: valori.map((el) => ({
        value: parseInt(el['Numero Profili Professionali Banca Dati Unica'], 10),
        name: el['Area Contrattuale'],
      })),
      itemStyle: {
        emphasis: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)',
        },
      },
    },
  ],

});

export default optionsProfiliPerArea;
