import { useContext, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { Context } from '../../../../utils/Context';
import { getItem } from '../../../../utils/storage';
import TitlePage from '../../../../components/GestisciUtenti/TitoloPagina';
import SistemaProfessionaleService from '../../../../services/sistemaProfessionaleService';
import FormFamigliaProfessionale from '../FormFamigliaProfessionale';
import ModaleCentroVerticale from '../../../../components/modali/ModaleCentroVerticale';
import FormSelezioneCompetenze from '../../../../components/Form/SelezioneCompetenze';
import history from '../../../../utils/history';
import checkCompsModified from '../../../../utils/checkCompsModified';
import { controlla } from '../../../../utils/verificaPermessi';

function FamigliaProfessionaliDettaglio() {
  const [context, setContext] = useContext(Context);
  const { idFamigliaProfessionale } = useParams();
  const { state } = useLocation();
  const [stateFamigliaProfessionale, setStateFamigliaProfessionale] = useState({});
  const [compsOriginal, setCompsOriginal] = useState({});
  const [gradingList, setGradingList] = useState([]);
  const [competenzeListSelected, setCompetenzeListSelected] = useState([]);
  const [isModify, setIsModify] = useState(false);
  const [modalShowCtp, setModalShowCtp] = useState(false);
  const [modalShowCc, setModalShowCc] = useState(false);
  const [errors, setErrors] = useState({});
  const { sequ_k_comparto } = getItem('compartoSelected') ? JSON.parse(getItem('compartoSelected')) : 0;
  const { idAmministrazione } = JSON.parse(getItem('identita'));

  // Validazione per la creazione di una famiglia professionale
  const validateFamigliaProfessionale = (stato, error) => {
    const temp = { ...error };
    temp.desc_titolo = stato.desc_titolo === '';
    temp.fk_sequ_competenze = stato.fk_sequ_competenze.some((el) => el.grading === 0);
    setErrors((prevState) => ({
      ...prevState,
      ...temp,
    }));
    return Object.values(temp).every((x) => x === false);
  };

  // function collegata al servizio di get di un particolare profilo professionale
  async function getFamigliaProfessionaleById(id) {
    try {
      const response = await SistemaProfessionaleService.getFamigliaProfessionaleById(id);
      const listCompetenzeMap = response.d_rel_famiglia_prof_comps.map(
        (el) => ({
          ...el.competenza,
          fk_sequ_competenza: el.fk_sequ_competenza,
          grading: el.codi_grading,
        }),
      );
      const responseMap = {
        desc_titolo: response.desc_titolo,
        desc_codice: response.desc_codice,
        fk_sequ_comparto: response.fk_sequ_comparto,
        fk_sequ_competenze: listCompetenzeMap,
        fk_codi_amministrazione: response.fk_codi_amministrazione,
      };
      setCompsOriginal(responseMap);
      setStateFamigliaProfessionale(responseMap);
      setCompetenzeListSelected(listCompetenzeMap);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // function collegata al servizio per ottenere la lista dei grading
  async function getGrading(offset, limit, filters) {
    try {
      const response = await SistemaProfessionaleService
        .getGrading(filters, offset, limit);
      setGradingList(response.rows);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // useEffect per caricare i dati presenti nella tabella.
  useEffect(() => {
    if (idFamigliaProfessionale) {
      getFamigliaProfessionaleById(idFamigliaProfessionale);
      setIsModify(state.isModify ?? false);
    } else {
      setStateFamigliaProfessionale({
        desc_titolo: '',
        fk_sequ_comparto: sequ_k_comparto,
        fk_sequ_competenze: [],
      });
    }
    // IMPOSTATO LIMIT 1000
    getGrading(0, 1000, {});
  }, [idFamigliaProfessionale]);

  // function per effettuare il refresh della pagina
  function refresh() {
    getFamigliaProfessionaleById(idFamigliaProfessionale);
    setIsModify(false);
    window.scrollTo({ behavior: 'smooth', top: '0px' });
  }

  // function collegata al servizio di creazione di una nuova famiglia professionale
  async function nuovaFamigliaProfessionale() {
    if (validateFamigliaProfessionale(stateFamigliaProfessionale, errors)) {
      SistemaProfessionaleService.nuovaFamigliaProfessionale(stateFamigliaProfessionale)
        .then((res) => {
          setContext(() => ({
            ...context, openConferma: true, testo: res.message,
          }));
          history.push('/area-riservata/famiglie-professionali');
        })
        .catch((err) => {
          setContext(() => ({
            ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
          }));
        });
    } else if (errors.fk_sequ_competenze) {
      setContext(() => ({
        ...context, open: true, testoErrore: 'Errore: associare un grading ad ogni competenza aggiunta', statusCode: 400,
      }));
    }
  }

  // function collegata al servizio di modifica di una famiglia professionale
  async function modificaFamigliaProfessionale(newState) {
    if (validateFamigliaProfessionale(newState, errors)) {
      const newFamiglia = checkCompsModified(newState, compsOriginal.fk_sequ_competenze);
      SistemaProfessionaleService.modificaFamigliaProfessionale(
        idFamigliaProfessionale,
        newFamiglia,
      )
        .then((res) => {
          setContext(() => ({
            ...context, openConferma: true, testo: res.message,
          }));
          refresh();
        })
        .catch((err) => {
          setContext(() => ({
            ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
          }));
        });
    } else if (errors.fk_sequ_competenze) {
      setContext(() => ({
        ...context, open: true, testoErrore: 'Errore: associare un grading ad ogni competenza aggiunta', statusCode: 400,
      }));
    }
  }

  // function per modificare lo stato del profilo professionale che l'utente sta modificando.
  const onChange = (e, config, row) => {
    if (config) {
      if (config.name === 'grading') {
        setCompetenzeListSelected((prevState) => prevState.map((comp) => {
          if (comp.fk_sequ_competenza === row.fk_sequ_competenza) {
            return ({
              ...comp,
              grading: e.codi_valore,
            });
          }
          return comp;
        }));
        setStateFamigliaProfessionale((prevState) => ({
          ...prevState,
          fk_sequ_competenze: prevState.fk_sequ_competenze.map(
            (el) => {
              if (el.fk_sequ_competenza === row.fk_sequ_competenza) {
                return ({
                  ...el,
                  grading: e.codi_valore,
                });
              } return el;
            },
          ),
        }));
      }
    } else {
      const { name, value } = e.target;
      setStateFamigliaProfessionale({ ...stateFamigliaProfessionale, [name]: value });
      validateFamigliaProfessionale({
        ...stateFamigliaProfessionale,
        [name]: value,
      }, errors);
    }
  };

  // Function attivata al click di elimina singola competenza dalla tabella
  const onDeleteCompetenza = (competenza) => {
    setCompetenzeListSelected((list) => list.filter(
      (el) => el.fk_sequ_competenza !== competenza.fk_sequ_competenza,
    ));
    setStateFamigliaProfessionale((prevState) => {
      const newState = {
        ...prevState,
        fk_sequ_competenze: prevState.fk_sequ_competenze.filter(
          (el) => el.fk_sequ_competenza !== competenza.fk_sequ_competenza,
        ),
      };
      return newState;
    });
  };

  // Funzione attivata al click del conferma nella modale di aggiungi CTP
  const onConfirmCtp = () => {
    const newState = {
      ...stateFamigliaProfessionale,
      fk_sequ_competenze: competenzeListSelected,
    };
    setStateFamigliaProfessionale(newState);
    validateFamigliaProfessionale(newState, errors);
    setModalShowCtp(false);
  };

  // Funzione attivata al click del chiudi nella modale di aggiungi CTP
  const onCloseCtp = () => {
    const initialComps = stateFamigliaProfessionale.fk_sequ_competenze || [];
    setStateFamigliaProfessionale({
      ...stateFamigliaProfessionale,
      fk_sequ_competenze: initialComps,
    });
    setCompetenzeListSelected(initialComps);
    setModalShowCtp(false);
  };

  // Funzione attivata al click del conferma nella modale di aggiungi CC
  const onConfirmCc = () => {
    const newState = {
      ...stateFamigliaProfessionale,
      fk_sequ_competenze: competenzeListSelected,
    };
    setStateFamigliaProfessionale(newState);
    validateFamigliaProfessionale(newState, errors);
    setModalShowCc(false);
  };

  // Funzione attivata al click del chiudi nella modale di aggiungi CC
  const onCloseCc = () => {
    const initialComps = stateFamigliaProfessionale.fk_sequ_competenze || [];
    setStateFamigliaProfessionale({
      ...stateFamigliaProfessionale,
      fk_sequ_competenze: initialComps,
    });
    setCompetenzeListSelected(initialComps);
    setModalShowCc(false);
  };

  return (
    <>
      <Container className="p-0">
        <TitlePage
          title={idFamigliaProfessionale ? 'Famiglia Professionale' : 'Crea una nuova Famiglia Professionale'}
          showModify={!isModify
            && idFamigliaProfessionale
            && stateFamigliaProfessionale.fk_codi_amministrazione === idAmministrazione && controlla('X43')}
          functionModify={() => setIsModify(true)}
          showSave={isModify && idFamigliaProfessionale}
          functionSave={() => modificaFamigliaProfessionale(stateFamigliaProfessionale)}
          showView={isModify && idFamigliaProfessionale}
          functionView={() => setIsModify(false)}
          showAdd={!idFamigliaProfessionale}
          functionAdd={() => nuovaFamigliaProfessionale()}
          showIndietro
          functionIndietro={() => history.goBack()}
        />
        <FormFamigliaProfessionale
          stateFamigliaProfessionale={stateFamigliaProfessionale}
          onChange={onChange}
          disabled={!isModify && idFamigliaProfessionale}
          isNew={!idFamigliaProfessionale}
          optionsGrading={gradingList}
          setModalShowCtp={setModalShowCtp}
          setModalShowCc={setModalShowCc}
          onDeleteCompetenza={onDeleteCompetenza}
          errors={errors}
        />
      </Container>
      <ModaleCentroVerticale
        modalTitle="Competenze Tecnico Professionali"
        modalBody={(
          <FormSelezioneCompetenze
            tipoCompetenza="CTP"
            competenzeListSelected={competenzeListSelected.map((el) => el.fk_sequ_competenza)}
            setCompetenzeListSelected={setCompetenzeListSelected}
          />
      )}
        buttonType="submit"
        show={modalShowCtp}
        onConfirm={() => onConfirmCtp()}
        labelConfirm="Aggiungi competenze"
        onClose={() => onCloseCtp()}
        size="xl"
      />
      <ModaleCentroVerticale
        modalTitle="Competenze Comportamentali"
        modalBody={(
          <FormSelezioneCompetenze
            tipoCompetenza="CC"
            competenzeListSelected={competenzeListSelected.map((el) => el.fk_sequ_competenza)}
            setCompetenzeListSelected={setCompetenzeListSelected}
          />
          )}
        buttonType="submit"
        show={modalShowCc}
        onConfirm={() => onConfirmCc()}
        labelConfirm="Aggiungi competenze"
        onClose={() => onCloseCc()}
        size="xl"
      />
    </>
  );
}

export default FamigliaProfessionaliDettaglio;
