import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Col, Form, Row, Button, Accordion,
  Container,
} from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { setItem } from '../../../utils/storage';
import CustomSelect from '../../../components/Select/CustomSelect';

function FiltriCompetenze({
  filtri,
  setFiltri,
  onChangeFiltri,
  getCompetenze,
  optionsAreeCompetenze,
}) {
  // State per gestire il re-rendering del form e pulizia dei campi
  const [formKey, setFormKey] = useState(0);

  const initialState = {
    ...filtri,
    desc_codice: '',
    desc_titolo: '',
    fk_sequ_area_competenze: 0,
  };

  // Function per il reset dei filtri
  const resetFiltri = () => {
    setFiltri(initialState);
    setFormKey((prevKey) => prevKey + 1);
    getCompetenze(0, 10, 1, initialState);
  };

  useEffect(() => {
    resetFiltri();
  }, []);

  return (
    <Accordion className="mb-3">
      <Accordion.Item eventKey="0">
        <Accordion.Header>Filtri di ricerca</Accordion.Header>
        <Accordion.Body>
          <Form className="mt-3" key={formKey}>
            <Row className="mb-3">
              <Form.Group as={Col} lg>
                <Form.Label>
                  Codice
                </Form.Label>
                <Form.Control
                  name="desc_codice"
                  value={filtri.desc_codice}
                  placeholder="Filtra per codice"
                  onChange={onChangeFiltri}
                  maxLength={100}
                  required
                />
              </Form.Group>
              <Form.Group as={Col} lg>
                <Form.Label>
                  Titolo
                </Form.Label>
                <Form.Control
                  name="desc_titolo"
                  value={filtri.desc_titolo}
                  placeholder="Filtra per titolo"
                  onChange={onChangeFiltri}
                  maxLength={100}
                  required
                />
              </Form.Group>
              <Form.Group as={Col} lg>
                <Form.Label>
                  Area delle Competenze
                </Form.Label>
                <CustomSelect
                  id="fk_sequ_area_competenze"
                  name="fk_sequ_area_competenze"
                  options={optionsAreeCompetenze}
                  placeholder="Scegli area delle competenze"
                  getOptionLabel={(option) => option.desc_descrizione}
                  getOptionValue={(option) => option.sequ_k_area_competenze}
                  value={optionsAreeCompetenze?.filter(
                    (el) => el.sequ_k_area_competenze === filtri.fk_sequ_area_competenze,
                  )?.[0]}
                  onChange={(selectedOption, config) => { onChangeFiltri(selectedOption, config); }}
                  isClearable
                />
              </Form.Group>
            </Row>
          </Form>
          <Container style={{ padding: 0, textAlign: 'end' }}>
            <Button
              variant="outline-dark"
              onClick={() => resetFiltri()}
            >
              <FontAwesomeIcon aria-hidden="true" icon={['fas', 'eraser']} style={{ marginRight: '2px' }} />
              PULISCI CAMPI
            </Button>
            {' '}
            <Button
              variant="outline-primary"
              onClick={() => {
                setItem('filtri', JSON.stringify(filtri));
                getCompetenze(0, 10, 1, filtri);
              }}
            >
              <FontAwesomeIcon aria-hidden="true" icon={['fas', 'search']} style={{ marginRight: '2px' }} />
              CERCA
            </Button>
          </Container>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default FiltriCompetenze;
