const optionsTorta = (valori) => ({
  title: {
    text: 'Assessment',
    x: 'center',
  },
  tooltip: {
    trigger: 'item',
    formatter: '{a} <br/>{b} : {c} ({d}%)',
  },
  legend: {
    orient: 'vertical',
    left: 'left',
    data: valori.map((el) => el.desc_stato),
  },
  series: [
    {
      name: 'Assessment',
      type: 'pie',
      radius: '55%',
      center: ['50%', '60%'],
      data: valori.map((el) => ({ value: el.numero, name: el.desc_stato })),
      itemStyle: {
        emphasis: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)',
        },
      },
    },
  ],
});

export default optionsTorta;
