/* eslint-disable react/no-unknown-property */
import {
  Button,
  Col, Container, Form, Image,
  Row,
} from 'react-bootstrap';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useContext, useEffect, useState } from 'react';
import logoAI from '../../../../images/LogoAi.svg';
import TitlePage from '../../../../components/GestisciUtenti/TitoloPagina';
import optionsTipoCompetenza from '../constants/optionsTipoCompetenza';
import SistemaProfessionaleService from '../../../../services/sistemaProfessionaleService';
import { Context } from '../../../../utils/Context';
import Table from '../../../../components/Shared/Table';
import EmptyState from '../../../../components/EmptyState';
import fieldsCompetenzeAI from './fieldsCompetenzeAI';
import ModaleCentroVerticale from '../../../../components/modali/ModaleCentroVerticale';
import FormCompetenza from '../FormCompetenza';
import history from '../../../../utils/history';
import SpinnerBody from '../../../../components/Shared/SpinnerBody';

function RicercaAICompetenze() {
  const [context, setContext] = useContext(Context);
  const { tipoCompetenza } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [modalShow, setModalShow] = useState(false);
  const [titoloPagina, setTitoloPagina] = useState('');
  const [filtri, setFiltri] = useState({
    query: '',
    tipologia: [tipoCompetenza],
  });
  const [competenzeList, setCompetenzeList] = useState([]);
  const [competenzaSelected, setCompetenzaSelected] = useState({});
  const [areeCompetenzeList, setAreeCompetenzeList] = useState([]);

  // Function collegata al servizio per ottenere la lista delle competenze
  async function getCompetenzeAI(filters) {
    try {
      const response = await SistemaProfessionaleService
        .getCompetenzeAI(filters);
      setCompetenzeList(response.hits);
      // setCompetenzeList([
      //   {
      //     title: 'Diritto Unione Europea e internazionale',
      //     descriptor: 'Conoscenza delle norme che regolano il...',
      //     competence_id: 'CTP001',
      //     tipologia: 'CTP',
      //     similarity_score: 84.73,
      //   },
      //   {
      //     title: 'Diritto Pubblico',
      //     descriptor: 'Conoscenza delle fonti normative nazionali e ..',
      //     competence_id: 'CTP002',
      //     tipologia: 'CTP',
      //     similarity_score: 76.80,
      //   },
      // ]);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // function collegata al servizio per ottenere la lista delle aree delle competenze
  async function getAreeCompetenze(offset, limit, filters) {
    try {
      const response = await SistemaProfessionaleService
        .getAreeCompetenze(filters, offset, limit);
      setAreeCompetenzeList(response.rows);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // Function per modificare lo stato della ricerca
  const onChange = (e) => {
    const { name, value } = e.target;
    setFiltri({ ...filtri, [name]: value });
  };

  // Function per la ricerca dei dati
  const onCercaCompetenze = () => {
    getCompetenzeAI(filtri);
  };

  // Function per settare il titolo della pagina
  const getTitoloPagina = (tipo) => optionsTipoCompetenza.find(
    (el) => el.codi_tipo_competenza === tipo,
  ).desc_tipo_competenza;

  // Function per visualizzare il dettaglio della competenze
  const onView = async (row) => {
    try {
      const response = await SistemaProfessionaleService.getCompetenzaById(row.codice_riva);
      setCompetenzaSelected({
        desc_titolo: response.desc_titolo,
        desc_descrizione: response.desc_descrizione,
        desc_descrittore: response.desc_descrittore ?? '',
        codi_tipo_competenza: response.codi_tipo_competenza,
        fk_sequ_area_competenze: response.fk_sequ_area_competenze,
      });
      setModalShow(true);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  };

  useEffect(() => {
    setTitoloPagina(getTitoloPagina(tipoCompetenza));
    getAreeCompetenze(0, 1000, { codi_tipo_competenza: tipoCompetenza });
    setIsLoading(false);
  }, []);

  return (
    isLoading ? (
      <SpinnerBody />
    ) : (
      <Container className="p-0">
        <TitlePage
          title={(
            <>
              Ricerca
              {' '}
              {titoloPagina}
              {' '}
              <Image src={logoAI} width="30" height="30" />
            </>
          )}
          showIndietro
          functionIndietro={() => history.goBack()}
        />
        <Row className="justify-content-center mt-3">
          <div as={Col} style={{ width: '70%' }} className="d-flex flex-column align-items-center">
            <Form.Group className="w-100 mb-3">
              <Form.Control
                name="query"
                value={filtri.query}
                type="text"
                placeholder="Inserisci un suggerimento per la ricerca... "
                maxLength={500}
                onChange={onChange}
                style={{ width: '100%' }}
              />
            </Form.Group>
            <Button
              variant="primary"
              onClick={() => onCercaCompetenze()}
              disabled={filtri.query.length === 0}
            >
              CERCA
            </Button>
          </div>
        </Row>
        <Row className="justify-content-center mt-3">
          {competenzeList.length > 0 ? (
            <Table
              data={competenzeList}
              fields={fieldsCompetenzeAI(onView)}
              keyField="competence_id"
            />
          ) : (
            <EmptyState
              subtitle="Procedi con la ricerca per visualizzare i dati compatibili."
            />
          )}
        </Row>
        <ModaleCentroVerticale
          modalTitle="Competenza"
          modalBody={(
            <FormCompetenza
              stateCompetenza={competenzaSelected}
              optionsAreeCompetenze={areeCompetenzeList}
              disabled
            />
          )}
          show={modalShow}
          onClose={() => setModalShow(false)}
          disabledConfirm
        />
      </Container>
    )
  );
}

export default RicercaAICompetenze;
