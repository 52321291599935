const optionsStati = [
  {
    titolo: 'Bozza',
    valore: 'bozza',
  },
  {
    titolo: 'Pubblicato',
    valore: 'pubblicato',
  },
  {
    titolo: 'Ritirato',
    valore: 'ritirato',
  },
];

export default optionsStati;
