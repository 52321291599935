import {
  Button, Col, Container, Dropdown,
  DropdownButton,
  ListGroup,
} from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { getItem } from '../../../utils/storage';
import Table from '../../../components/Shared/Table';
import { fieldsCompetenzeModify, fieldsCompetenzeView } from './dettaglio/fieldsTable';
import EmptyState from '../../../components/EmptyState';
import history from '../../../utils/history';
import './index.css';
import CustomSelect from '../../../components/Select/CustomSelect';
import { controlla } from '../../../utils/verificaPermessi';
import SpinnerBody from '../../../components/Shared/SpinnerBody';

function FormProfiloProfessionale({
  stateProfiloProfessionale,
  onChange,
  optionsDimensioniProfessionali = [],
  optionsFamiglieProfessionali = [],
  optionsAreeContrattuali = [],
  optionsAmbitiRuolo = [],
  optionsGrading = [],
  isDisabled = false,
  isNew = false,
  setModalShowCtp,
  setModalShowCc,
  setModalProfiloRuolo,
  setStateProfiloRuolo,
  onDeleteCompetenza,
  errors,
  handleTableChange,
  isAI = false,
}) {
  const options = JSON.parse(getItem('comparti'));
  const [isLoading, setIsLoading] = useState(true);

  // function per avere la lista delle competenze selezionate in base al tipo
  const getCompetenzeSelectedList = (tipo, state) => {
    if (state.fk_sequ_competenze) {
      return state.fk_sequ_competenze.filter(
        (el) => el.codi_tipo_competenza === tipo,
      );
    } return [];
  };

  // function per avere la lista dei grading associati ad un particolare tipo di competenza
  const getGradingList = (tipo) => {
    if (optionsGrading.length > 0) {
      return optionsGrading.filter(
        (el) => el.codi_tipo_competenza === tipo,
      );
    } return [];
  };

  // function per avere la lista degli ambiti già inclusi nel profilo professionale
  const getAmbitiSelectedList = () => {
    if (stateProfiloProfessionale.fk_sequ_ambiti_ruoli) {
      return optionsAmbitiRuolo.filter(
        (el) => stateProfiloProfessionale.fk_sequ_ambiti_ruoli?.includes(
          el.sequ_k_ambito_ruolo,
        ),
      );
    } return [];
  };

  // function per avere la lista degli ambiti disponibili per aggiungere un profilo di ruolo
  const getAmbitiForAddProfiloRuoloList = () => {
    if (stateProfiloProfessionale.d_rel_profilo_prof_ambitos) {
      const idAmbitiRuoliSaved = stateProfiloProfessionale.d_rel_profilo_prof_ambitos.map(
        (ambito) => ambito.fk_sequ_ambito_ruolo,
      );
      const idProfiliRuoloSaved = stateProfiloProfessionale.d_profilo_ruolos.map(
        (profilo) => profilo.fk_sequ_ambito_ruolo,
      );
      return optionsAmbitiRuolo.filter(
        (el) => idAmbitiRuoliSaved?.includes(el.sequ_k_ambito_ruolo),
      ).filter((element) => !idProfiliRuoloSaved?.includes(element.sequ_k_ambito_ruolo));
    } return [];
  };

  // function attivata al click di aggiungi profilo di ruolo
  const onClickAddProfiloRuolo = (idAmbitoRuolo, nomeAmbitoRuolo) => {
    setStateProfiloRuolo((prevState) => ({
      ...prevState,
      ambito_ruolo: nomeAmbitoRuolo,
      fk_sequ_ambito_ruolo: idAmbitoRuolo,
      fk_sequ_profilo_professionale: stateProfiloProfessionale.sequ_k_profilo_professionale,
      desc_suggerimento: stateProfiloProfessionale
        .fk_sequ_area_contrattuale_d_area_contrattuale.desc_suggerimento,
      desc_attivita: stateProfiloProfessionale
        .fk_sequ_area_contrattuale_d_area_contrattuale.desc_suggerimento,
    }));
    setModalProfiloRuolo(true);
  };

  // function per determinare se un ambito di ruolo può essere eliminato o meno
  const isClearableAmbitoRuolo = (ambito) => {
    const ambitiProfiliRuolo = stateProfiloProfessionale?.d_profilo_ruolos?.map(
      (el) => el.fk_sequ_ambito_ruolo,
    ) || [];
    return ambitiProfiliRuolo.includes(ambito.sequ_k_ambito_ruolo);
  };

  useEffect(() => {
    setIsLoading(false);
  }, [
    stateProfiloProfessionale,
    optionsDimensioniProfessionali,
    optionsFamiglieProfessionali,
    optionsAreeContrattuali,
    optionsAmbitiRuolo,
    optionsGrading,
  ]);

  return (
    isLoading
      ? (
        <SpinnerBody />
      )
      : (
        <Form.Group as={Col} lg controlId="profilo-professionale">
          {isDisabled && (
          <>
            <Form.Label style={{ fontWeight: 'bold' }}>
              Codice
            </Form.Label>
            <Form.Control
              name="desc_codice"
              value={stateProfiloProfessionale.desc_codice}
              onChange={onChange}
              maxLength={100}
              disabled
            />
          </>
          )}
          <Form.Label style={{ fontWeight: 'bold' }}>
            Nome
          </Form.Label>
          <Form.Control
            name="desc_titolo"
            value={stateProfiloProfessionale.desc_titolo}
            placeholder="Inserisci il nome del Profilo Professionale"
            onChange={onChange}
            maxLength={100}
            required
            disabled={isDisabled}
            isInvalid={errors?.desc_titolo}
          />
          <Form.Control.Feedback type="invalid">
            *Campo Nome obbligatorio
          </Form.Control.Feedback>
          <Form.Label style={{ fontWeight: 'bold' }}>
            Profilo Cerniera
          </Form.Label>
          <Form.Check
            type="switch"
            id="flag_cerniera"
            name="flag_cerniera"
            checked={stateProfiloProfessionale.flag_cerniera}
            onChange={onChange}
            disabled={isDisabled}
          />
          <Form.Label style={{ fontWeight: 'bold' }}>
            Comparto
          </Form.Label>
          <Select
            id="fk_sequ_comparto"
            name="fk_sequ_comparto"
            options={options}
            isDisabled
            placeholder="Scegli comparto"
            getOptionLabel={(option) => option.desc_comparto}
            getOptionValue={(option) => option.sequ_k_comparto}
            value={options?.filter(
              (el) => el.sequ_k_comparto === stateProfiloProfessionale.fk_sequ_comparto,
            )?.[0]}
            onChange={(selectedOption, config) => { onChange(selectedOption, config); }}
          />
          <Form.Label style={{ fontWeight: 'bold' }}>
            Area Contrattuale CCNL
          </Form.Label>
          {isDisabled ? (
            <Form.Control
              name="fk_sequ_area_contrattuale"
              value={stateProfiloProfessionale.fk_sequ_area_contrattuale_d_area_contrattuale
                ? `${stateProfiloProfessionale.fk_sequ_area_contrattuale_d_area_contrattuale.desc_codice} - ${stateProfiloProfessionale.fk_sequ_area_contrattuale_d_area_contrattuale.desc_titolo}`
                : ''}
              disabled={isDisabled}
            />
          ) : (
            <CustomSelect
              id="fk_sequ_area_contrattuale"
              name="fk_sequ_area_contrattuale"
              options={optionsAreeContrattuali}
              placeholder="Scegli Area Contrattuale"
              getOptionLabel={(option) => `${option.desc_codice} - ${option.desc_titolo}`}
              getOptionValue={(option) => option.sequ_k_area_contrattuale}
              value={optionsAreeContrattuali?.filter(
                (el) => el.sequ_k_area_contrattuale
          === stateProfiloProfessionale.fk_sequ_area_contrattuale,
              )?.[0]}
              onChange={(selectedOption, config) => { onChange(selectedOption, config); }}
              isClearable={isNew}
              errors={errors}
              errorMessage="*Campo Area contrattuale obbligatorio"
            />
          )}
          {(stateProfiloProfessionale.fk_sequ_comparto === 1
      || stateProfiloProfessionale.fk_sequ_comparto === 2) && (
        <>
          <Form.Label style={{ fontWeight: 'bold' }}>
            Dimensione Professionale
          </Form.Label>
          {isDisabled ? (
            <Form.Control
              name="fk_sequ_dimensione_professionale"
              value={stateProfiloProfessionale.dimensione_professionale
                ? `${stateProfiloProfessionale.dimensione_professionale.desc_codice} - ${stateProfiloProfessionale.dimensione_professionale.desc_titolo}`
                : ''}
              disabled={isDisabled}
            />
          ) : (
            <CustomSelect
              id="fk_sequ_dimensione_professionale"
              name="fk_sequ_dimensione_professionale"
              options={optionsDimensioniProfessionali}
              placeholder="Scegli Dimensione Professionale"
              getOptionLabel={(option) => `${option.desc_codice} - ${option.desc_titolo}`}
              getOptionValue={(option) => option.sequ_k_dimensione_professionale}
              value={optionsDimensioniProfessionali?.filter(
                (el) => el.sequ_k_dimensione_professionale
                    === stateProfiloProfessionale.fk_sequ_dimensione_professionale,
              )?.[0]}
              onChange={(selectedOption, config) => { onChange(selectedOption, config); }}
              isClearable={isNew}
              errors={errors}
              errorMessage="*Campo Dimensione Professionale obbligatorio"
            />
          )}
        </>
          )}
          <Form.Label style={{ fontWeight: 'bold' }}>
            Famiglia Professionale
          </Form.Label>
          {(isNew
          || (stateProfiloProfessionale?.fk_sequ_ambiti_ruoli?.length === 0 && !isDisabled)) ? (
            <CustomSelect
              id="fk_sequ_famiglia_professionale"
              name="fk_sequ_famiglia_professionale"
              options={optionsFamiglieProfessionali}
              placeholder="Scegli Famiglia Professionale"
              getOptionLabel={(option) => `${option.desc_codice} - ${option.desc_titolo}`}
              getOptionValue={(option) => option.sequ_k_famiglia_professionale}
              value={optionsFamiglieProfessionali?.filter(
                (el) => el.sequ_k_famiglia_professionale
                  === stateProfiloProfessionale.fk_sequ_famiglia_professionale,
              )?.[0]}
              onChange={(selectedOption, config) => { onChange(selectedOption, config); }}
              isClearable={isNew}
              errors={errors}
              errorMessage="*Campo Famiglia Professionale obbligatorio"
            />
            ) : (
              <Form.Control
                name="fk_sequ_famiglia_professionale"
                value={stateProfiloProfessionale.famiglia_professionale
                  ? `${stateProfiloProfessionale.famiglia_professionale.desc_codice} - ${stateProfiloProfessionale.famiglia_professionale.desc_titolo}`
                  : ''}
                disabled
              />
            )}
          <Form.Label style={{ fontWeight: 'bold' }}>
            Scopo Organizzativo
          </Form.Label>
          <Form.Control
            name="desc_scopo"
            value={stateProfiloProfessionale.desc_scopo}
            placeholder="Inserisci lo scopo organizzativo"
            onChange={onChange}
            as="textarea"
            rows={3}
            required
            disabled={isDisabled}
            isInvalid={errors?.desc_scopo}
          />
          {errors.desc_scopo && (
          <Form.Control.Feedback type="invalid">
            *Campo Scopo Organizzativo obbligatorio
          </Form.Control.Feedback>
          )}
          <Form.Label style={{ fontWeight: 'bold' }}>
            Macro attività caratterizzanti il Profilo Professionale
          </Form.Label>
          <Form.Control
            name="desc_attivita"
            value={stateProfiloProfessionale.desc_attivita}
            placeholder="Inserisci le macro attività"
            onChange={onChange}
            as="textarea"
            rows={3}
            required
            disabled={isDisabled}
            isInvalid={errors?.desc_attivita}
          />
          {errors.desc_attivita && (
          <Form.Control.Feedback type="invalid">
            *Campo Macro Attività obbligatorio
          </Form.Control.Feedback>
          )}
          {!isNew && (
          <>
            <Container className="d-flex justify-content-between p-0 mt-3">
              <Form.Label style={{ fontWeight: 'bold' }}>
                Competenze Tecnico Professionali
              </Form.Label>
              <Button
                className="float-left"
                disabled={isDisabled}
                variant="primary"
                size="sm"
                onClick={() => setModalShowCtp(true)}
              >
                <FontAwesomeIcon className="me-1" aria-hidden="true" icon={['fas', 'plus']} />
                Aggiungi Competenza Tecnico Professionale
              </Button>
            </Container>
            {(getCompetenzeSelectedList('CTP', stateProfiloProfessionale).length > 0
          && getGradingList('CTP').length > 0
            ) ? (
              <Table
                data={getCompetenzeSelectedList('CTP', stateProfiloProfessionale)}
                fields={isDisabled
                  ? fieldsCompetenzeView(
                    getGradingList('CTP'),
                    onChange,
                    getCompetenzeSelectedList('CTP', stateProfiloProfessionale),
                  )
                  : fieldsCompetenzeModify(
                    onDeleteCompetenza,
                    getGradingList('CTP'),
                    onChange,
                    getCompetenzeSelectedList('CTP', stateProfiloProfessionale),
                  )}
                keyField="competenze_ctp_list"
                onTableChange={handleTableChange}
              />
              ) : (
                <EmptyState
                  marginTop="3rem"
                  subtitle="Nessuna competenza tecnico professionale associata al profilo professionale"
                />
              )}
            <Container className="d-flex justify-content-between p-0 mt-3">
              <Form.Label style={{ fontWeight: 'bold' }}>
                Competenze Comportamentali
              </Form.Label>
              <Button
                className="float-left"
                disabled={isDisabled}
                variant="primary"
                size="sm"
                onClick={() => setModalShowCc(true)}
              >
                <FontAwesomeIcon className="me-1" aria-hidden="true" icon={['fas', 'plus']} />
                Aggiungi Competenza Comportamentale
              </Button>
            </Container>
            {(getCompetenzeSelectedList('CC', stateProfiloProfessionale).length > 0
          && getGradingList('CC').length > 0
            ) ? (
              <Table
                data={getCompetenzeSelectedList('CC', stateProfiloProfessionale)}
                fields={isDisabled
                  ? fieldsCompetenzeView(
                    getGradingList('CC'),
                    onChange,
                    getCompetenzeSelectedList('CC', stateProfiloProfessionale),
                  )
                  : fieldsCompetenzeModify(
                    onDeleteCompetenza,
                    getGradingList('CC'),
                    onChange,
                    getCompetenzeSelectedList('CC', stateProfiloProfessionale),
                  )}
                keyField="competenze_cc_list"
                onTableChange={handleTableChange}
              />
              ) : (
                <EmptyState
                  marginTop="3rem"
                  subtitle="Nessuna competenza comportamentale associata al profilo professionale"
                />
              )}
            <Form.Label style={{ fontWeight: 'bold' }}>
              Ambito di Ruolo
            </Form.Label>
            {isDisabled ? (
              <div>
                {getAmbitiSelectedList().length > 0 ? (
                  <ListGroup key="lista-ambiti-ruolo" horizontal="lg">
                    {getAmbitiSelectedList().map((el) => (
                      <ListGroup.Item>{el.desc_titolo}</ListGroup.Item>
                    ))}
                  </ListGroup>
                ) : (
                  <EmptyState
                    marginTop="3rem"
                    subtitle="Nessun ambito di ruolo associato al profilo professionale"
                  />
                )}
              </div>
            ) : (
              <Select
                isMulti
                className="basic-multi-select"
                classNamePrefix="select"
                id="fk_sequ_ambiti_ruoli"
                name="fk_sequ_ambiti_ruoli"
                options={optionsAmbitiRuolo}
                placeholder="Scegli Ambito di ruolo"
                getOptionLabel={(option) => option.desc_titolo}
                getOptionValue={(option) => option.sequ_k_ambito_ruolo}
                value={getAmbitiSelectedList()}
                onChange={(selectedOption, config) => { onChange(selectedOption, config); }}
                isClearable={false}
                styles={{
                  multiValueRemove: (base, state) => (isClearableAmbitoRuolo(state.data) ? { ...base, display: 'none' } : base),
                }}
              />
            )}
            <Container className="d-flex justify-content-between p-0 mt-3">
              <Form.Label style={{ fontWeight: 'bold' }}>
                Profili di Ruolo collegati
              </Form.Label>
              {controlla('X26') && (
              <DropdownButton
                id="dropdown-basic-button"
                key="dropdown-add-profilo-ruolo"
                disabled={isDisabled || getAmbitiForAddProfiloRuoloList().length === 0}
                variant="primary"
                size="sm"
                title="+ Aggiungi Profilo di Ruolo"
              >
                {getAmbitiForAddProfiloRuoloList().map((el) => (
                  <Dropdown.Item
                    onClick={() => onClickAddProfiloRuolo(el.sequ_k_ambito_ruolo, el.desc_titolo)}
                  >
                    {el.desc_titolo}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
              )}
            </Container>
            {stateProfiloProfessionale.d_profilo_ruolos
          && stateProfiloProfessionale.d_profilo_ruolos.length > 0 ? (
            <ListGroup as="ol">
              {stateProfiloProfessionale.d_profilo_ruolos.map((profiloRuolo) => (
                <ListGroup.Item
                  as="li"
                  className="d-flex justify-content-between align-items-center"
                >
                  {`${profiloRuolo.desc_codice} - ${profiloRuolo.desc_titolo}`}
                  {!isAI && (
                  <FontAwesomeIcon
                    className="ms-3"
                    onClick={() => history.push(`/area-riservata/profili-ruolo/${profiloRuolo.sequ_k_profilo_ruolo}`)}
                    aria-hidden="true"
                    icon={['fas', 'search']}
                    title="Visualizza"
                    id={`Visualizza${profiloRuolo.sequ_k_profilo_ruolo}`}
                  />
                  )}
                </ListGroup.Item>
              ))}
            </ListGroup>
              ) : (
                <EmptyState
                  marginTop="3rem"
                  subtitle="Nessun profilo di ruolo associato al profilo professionale"
                />
              )}
          </>
          )}
        </Form.Group>
      )
  );
}

export default FormProfiloProfessionale;
