import React from 'react';
import {
  Form, Row, Button, Col, Accordion,
  Container,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from 'react-select';
import { setItem } from '../../../../utils/storage';
import { optionsStatoElaborazione, optionsTipoBatch } from './constants/optionsBatch';

function FiltriUtentiMassiva({
  filtri,
  setFiltri,
  getBatch,
  onChangeFiltri,
}) {
  const initialState = {
    ...filtri,
    codi_tipo_batch: undefined,
    codi_stato_elaborazione: undefined,
  };
  return (
    <Accordion className="mb-3">
      <Accordion.Item eventKey="0">
        <Accordion.Header>Filtri di ricerca</Accordion.Header>
        <Accordion.Body>
          <Form className="mt-3">
            <Row className="mb-3">
              <Form.Group as={Col} lg>
                <Form.Label>
                  Tipo Caricamento
                </Form.Label>
                <Select
                  id="codi_tipo_batch"
                  name="codi_tipo_batch"
                  value={optionsTipoBatch?.filter(
                    (el) => el.codi_tipo_batch === filtri.codi_tipo_batch,
                  )}
                  options={optionsTipoBatch}
                  placeholder="Seleziona "
                  getOptionLabel={(option) => option.tipo_batch}
                  getOptionValue={(option) => option.codi_tipo_batch}
                  onChange={(selectedOption, config) => onChangeFiltri(selectedOption, config)}
                  isClearable
                />
              </Form.Group>
              <Form.Group as={Col} lg>
                <Form.Label>
                  Stato Elaborazione
                </Form.Label>
                <Select
                  id="codi_stato_elaborazione"
                  name="codi_stato_elaborazione"
                  value={optionsStatoElaborazione?.filter(
                    (el) => el.codi_stato_elaborazione === filtri.codi_stato_elaborazione,
                  )}
                  options={optionsStatoElaborazione}
                  placeholder="Seleziona "
                  getOptionLabel={(option) => option.stato_elaborazione}
                  getOptionValue={(option) => option.codi_stato_elaborazione}
                  onChange={(selectedOption, config) => onChangeFiltri(selectedOption, config)}
                  isClearable
                />
              </Form.Group>
            </Row>
          </Form>
          <Container style={{ padding: 0, textAlign: 'end' }}>
            <Button
              variant="outline-dark"
              onClick={() => {
                setFiltri(initialState);
              }}
            >
              <FontAwesomeIcon aria-hidden="true" icon={['fas', 'eraser']} style={{ marginRight: '2px' }} />
              PULISCI CAMPI
            </Button>
            {' '}
            <Button
              variant="primary"
              onClick={() => {
                setItem('filtri', JSON.stringify(filtri));
                getBatch(0, 10, 1);
              }}
            >
              <FontAwesomeIcon aria-hidden="true" icon={['fas', 'search']} style={{ marginRight: '2px' }} />
              CERCA
            </Button>
          </Container>

        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default FiltriUtentiMassiva;
