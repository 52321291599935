import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import BreadcrumbComponent from '../../components/Breadcrumb';

function DichiarazioneAccessibilita() {
  return (
    <Container className="justify-content-center col-md-10">
      <Row xs={12}>
        <BreadcrumbComponent listItem={[{ name: 'Dichiarazione di accessibilità', pathname: '/dichiarazione-accessibilita', active: true }]} />
      </Row>
      <Row className="justify-content-center">
        <Col className="my-3">
          <h1>Dichiarazione di accessibilità</h1>
        </Col>
      </Row>
      <Row>
        <div>
          <div style={{ fontSize: '24px' }}>
            In attesa della dichiarazione di accessibilità
          </div>
        </div>
      </Row>
    </Container>
  );
}

export default DichiarazioneAccessibilita;
