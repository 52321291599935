import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const fieldsRichiesteFabbisogni = (
  isAllCompleted,
  onModify,
) => (
  [
    {
      dataField: 'struttura_organizzativa',
      text: 'Struttura Organizzativa',
      classes: 'column-data',
      headerStyle: () => ({ width: '90%' }),
    },
    {
      dataField: 'df1',
      text: 'Azioni',
      style: { verticalAlign: 'middle' },
      headerStyle: () => ({ width: '10%' }),
      formatter: (e, row) => (
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          {row.richiesta_inviata && (
          <FontAwesomeIcon
            aria-hidden="true"
            icon="fa-solid fa-check"
            size="lg"
            title="Visualizzato"
            style={{ color: '#28a745' }}
          />
          )}
          {isAllCompleted && (
            <FontAwesomeIcon
              aria-hidden="true"
              onClick={() => onModify(row)}
              icon="fa-solid fa-pen-to-square"
              size="lg"
              title="Modifica"
              style={{ color: '#0066cc' }}
            />
          )}
        </div>
      ),
    },
  ]
);

export default fieldsRichiesteFabbisogni;
