import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import themeColors from '../../../utils/contants/theme-colors';
import { controlla } from '../../../utils/verificaPermessi';

const fieldsProfiliProfessionali = (onDelete, onView, onModify, idAmministrazione) => (
  [
    {
      dataField: 'desc_codice',
      text: 'Codice',
      sort: true,
      classes: 'column-data',
      headerStyle: () => ({ width: '10%' }),
    },
    {
      dataField: 'desc_titolo',
      text: 'Titolo',
      sort: true,
      classes: 'column-data',
      headerStyle: () => ({ width: '20%' }),
    },
    {
      dataField: 'fk_sequ_comparto_d_comparto.desc_comparto',
      text: 'Comparto',
      classes: 'column-data',
      headerStyle: () => ({ width: '10%' }),
    },
    {
      dataField: 'famiglia_professionale.desc_titolo',
      text: 'Famiglia Professionale',
      classes: 'column-data',
      headerStyle: () => ({ width: '20%' }),
    },
    {
      dataField: 'fk_codi_amministrazione_d_amministrazione.desc_amministrazione',
      text: 'Ente',
      classes: 'column-data',
      headerStyle: () => ({ width: '12.5%' }),
      formatter: (e, row) => (row.fk_codi_amministrazione ? (
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          trigger="hover"
          overlay={(
            <Tooltip>
              {row.fk_codi_amministrazione_d_amministrazione
                .desc_amministrazione}
            </Tooltip>
          )}
        >
          <div>
            {row.fk_codi_amministrazione_d_amministrazione
              .desc_amministrazione.substring(0, 30)}
            ...
          </div>
        </OverlayTrigger>
      ) : (
        <>-</>
      )),
    },
    {
      text: 'Banca Dati Unica',
      classes: 'column-data',
      headerStyle: () => ({ width: '12.5%' }),
      formatter: (e, row) => (
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          {row.fk_codi_amministrazione === null && (
            <FontAwesomeIcon
              aria-hidden="true"
              icon={['fas', 'check']}
              size="sm"
            />
          )}
        </div>
      ),
    },
    {
      dataField: 'df1',
      isDummyField: true,
      text: 'Azioni',
      headerStyle: () => ({ width: '15%' }),
      formatter: (e, row) => (
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <FontAwesomeIcon
            aria-hidden="true"
            icon={['fas', 'search']}
            size="lg"
            title="Visualizza"
            id={`Visualizza${row.sequ_k_profilo_professionale}`}
            style={{ color: themeColors.primary }}
            onClick={() => onView(row.sequ_k_profilo_professionale)}
          />
          {(controlla('X25') && row.fk_codi_amministrazione === idAmministrazione) && (
          <FontAwesomeIcon
            aria-hidden="true"
            icon={['fas', 'edit']}
            size="lg"
            title="Modifica"
            id={`Modifica${row.sequ_k_grading}`}
            style={{ color: themeColors.primary }}
            onClick={() => onModify(row.sequ_k_profilo_professionale)}
          />
          )}
          {(controlla('X25') && row.fk_codi_amministrazione === idAmministrazione) && (
          <FontAwesomeIcon
            aria-hidden="true"
            icon={['fas', 'trash']}
            size="lg"
            title="Elimina"
            id={`Elimina${row.sequ_k_profilo_professionale}`}
            style={{ color: themeColors.danger }}
            onClick={() => onDelete(
              { id: row.sequ_k_profilo_professionale, codice: row.desc_codice },
            )}
          />
          )}
        </div>
      ),
    },
  ]
);

export default fieldsProfiliProfessionali;
