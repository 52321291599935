import { format } from 'date-fns';
import { Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

function FormNewFabbisogno({
  stateFabbisogno,
  onChange,
  formError,
}) {
  return (
    <Form.Group as={Col} lg>
      <Form.Label>
        Nome Piano
      </Form.Label>
      <Form.Control
        name="nome_processo"
        value={stateFabbisogno.nome_processo}
        placeholder="Inserisci il nome"
        onChange={onChange}
        isInvalid={!!formError.nome_processo}
        maxLength={100}
        required
      />
      <Form.Control.Feedback type="invalid">
        Scegli un nome corretto
      </Form.Control.Feedback>

      <Form.Label>
        Anno di Riferimento
      </Form.Label>
      <Form.Control
        type="number"
        name="anno_riferimento"
        placeholder="Inserisci anno del fabbisogno"
        value={stateFabbisogno.anno_riferimento}
        onChange={onChange}
        isInvalid={!!formError.date}
      />
      <Form.Control.Feedback type="invalid">
        {`Inserisci una data corretta (da ${format(new Date(), 'yyyy')} a 9999)`}
      </Form.Control.Feedback>
    </Form.Group>
  );
}

export default FormNewFabbisogno;
