import { Container, ProgressBar } from 'react-bootstrap';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import getVariantColor from '../../../../utils/getVariantColor';
import themeColors from '../../../../utils/contants/theme-colors';
import { controlla } from '../../../../utils/verificaPermessi';

const fieldsCompetenzeAggiuntive = (
  onModalShow,
  onDelete,
  optionsGrading,
) => {
  const regEx = /^[A-Z]+/;
  // Function per calcolare il numero di competenze per la tipologia
  const getNumberCompetenze = (tipo) => {
    const competenzeFiltered = optionsGrading.filter(
      (opt) => opt?.codi_tipo_competenza === tipo,
    );
    return competenzeFiltered.length;
  };
  // Function per ritornare le competenze filtrate
  const getCompetenzeFiltered = (tipo) => {
    const competenzeFiltered = optionsGrading.filter(
      (opt) => opt?.codi_tipo_competenza === tipo,
    );
    return competenzeFiltered;
  };
  return (
    [
      {
        dataField: 'desc_codice',
        text: 'Codice',
        classes: 'column-data',
        headerStyle: () => ({ width: '17%' }),
      },
      {
        dataField: 'desc_titolo',
        text: 'Competenza',
        classes: 'column-data',
        headerStyle: () => ({ width: '17%' }),
      },
      {
        dataField: 'desc_anno',
        text: 'Anno',
        classes: 'column-data',
        headerStyle: () => ({ width: '17%' }),
      },
      {
        dataField: 'desc_fonte',
        text: 'Evento',
        classes: 'column-data',
        headerStyle: () => ({ width: '17%' }),
      },
      {
        dataField: 'desc_note',
        text: 'Note',
        classes: 'column-data',
        headerStyle: () => ({ width: '17%' }),
      },
      {
        dataField: '',
        text: 'Grading',
        headerStyle: () => ({ width: '17%' }),
        formatter: (e, row) => (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Select
              className="w-100"
              id="grading"
              name="codi_grading"
              placeholder="Associa un grading"
              options={getCompetenzeFiltered(row?.desc_codice?.match(regEx)?.[0])}
              getOptionLabel={(option) => (
                <Container className="p-0">
                  {option.desc_titolo}
                  <ProgressBar
                    variant={
                    getVariantColor(
                      (option.codi_valore / getNumberCompetenze(
                        row?.desc_codice?.match(regEx)?.[0],
                      )) * 100,
                    )
                  }
                    label={option.codi_valore}
                    now={(option.codi_valore / getNumberCompetenze(
                      row?.desc_codice?.match(regEx)?.[0],
                    )) * 100}
                  />
                </Container>
              )}
              getOptionValue={(option) => option.codi_valore}
              value={getCompetenzeFiltered(row?.desc_codice?.match(regEx)?.[0]).filter(
                (
                  competenza,
                ) => competenza.codi_valore === row.codi_grading,
              )}
              isDisabled
            />
          </div>
        ),
      },
      {
        dataField: 'df1',
        isDummyField: true,
        text: 'Azioni',
        style: { verticalAlign: 'middle' },
        headerStyle: () => ({ width: '20%' }),
        formatter: (e, row) => (
          <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            {controlla('Z32') && (
            <FontAwesomeIcon
              aria-hidden="true"
              icon={['fas', 'edit']}
              size="lg"
              title="Modifica"
              id={`Modifica${row.desc_codice}`}
              style={{ color: themeColors.primary }}
              onClick={() => onModalShow(row)}
            />
            )}
            {controlla('Z32') && (
            <FontAwesomeIcon
              aria-hidden="true"
              icon={['fas', 'trash']}
              size="lg"
              title="Elimina"
              id={`Elimina${row.desc_codice}`}
              style={{ color: themeColors.danger }}
              onClick={() => onDelete(row)}
            />
            )}
            {controlla('Z33') && (
            <FontAwesomeIcon
              aria-hidden="true"
              icon={['fas', 'search']}
              size="lg"
              title="Visualizza"
              id={`Visualizza${row.desc_codice}`}
              style={{ color: themeColors.primary }}
              onClick={() => onModalShow(row)}
            />
            )}
          </div>
        ),
      },
    ]
  );
};

export default fieldsCompetenzeAggiuntive;
