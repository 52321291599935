export const getItem = (key) => {
  let item = localStorage.getItem(key);
  try {
    if (key === 'user' && item) {
      if (item.startsWith('{')) {
        return item;
      }
      item = atob(item);
      if (item.startsWith('{')) {
        return item;
      }
      // eslint-disable-next-line no-use-before-define
      clear();
      return undefined;
    }
    return item;
  } catch (e) {
    return '{}';
  }
};
export const setItem = (key, value) => {
  let item;
  try {
    if (key === 'user' && value) {
      item = value ? btoa(value) : value;
    } else {
      item = value;
    }
  } catch (e) {
    item = value;
  }
  return localStorage.setItem(key, item);
};
export const removeItem = (key) => localStorage.removeItem(key);
export const clear = () => localStorage.clear();
