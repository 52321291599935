import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import themeColors from '../../../../utils/contants/theme-colors';

const fieldsListaDipendenti = (onView) => ([
  {
    dataField: 'desc_nome',
    text: 'Dipendente',
    classes: 'column-data',
    sort: true,
    headerStyle: () => ({ width: '80%' }),
    formatter: (e, row) => `${row.desc_nome} ${row.desc_cognome}`,
  },
  {
    dataField: 'df1',
    isDummyField: true,
    text: 'Azioni',
    headerStyle: () => ({ width: '15%' }),
    formatter: (e, row) => (
      <div style={{ display: 'flex', justifyContent: 'space-around' }}>
        <FontAwesomeIcon
          aria-hidden="true"
          icon={['fas', 'search']}
          size="lg"
          title="Visualizza"
          id={`Visualizza${row.id}`}
          style={{ color: themeColors.primary }}
          onClick={() => onView(row.id, row.desc_nome, row.desc_cognome)}
        />
      </div>
    ),
  },
]);

export default fieldsListaDipendenti;
