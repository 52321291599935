const optionsInstogramma = (valori) => ({
  title: {
    text: 'Numero Profili di Ruolo per Ambito',
    x: 'center',
  },
  tooltip: {},
  legend: {
    orient: 'vertical',
    left: 'left',
    data: valori.map((el) => el.desc_titolo),
  },
  xAxis: {
    data: valori.map((el) => el.desc_titolo),
    axisLabel: {
      rotate: 45,
      interval: 0,
    },
  },
  yAxis: {

  },
  series: [
    {
      name: 'Ambito',
      type: 'bar',
      radius: '55%',
      center: ['50%', '60%'],
      data: valori.map((el) => el.numero),
      itemStyle: {
        emphasis: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)',
        },
      },
    },
  ],
});

export default optionsInstogramma;
