/* eslint-disable react/no-unknown-property */
import { Col } from 'react-bootstrap';
import { useEffect } from 'react';
import Table from '../../../../../../components/Shared/Table';
import fieldsRanking from '../../fields/fieldsRanking';

function Ranking({
  struttureList,
  budgetTotale,
  statePiano,
  setStatePiano,
}) {
  // Calcolo del punteggio totale di tutte le strutture
  const punteggioTotale = struttureList.reduce((acc, so) => acc + so.punteggio_so, 0);

  // Filtraggio e mappatura dati necessari per la visualizzazione
  const rankingData = struttureList.map(
    (so) => ({
      struttura_organizzativa: so.struttura_organizzativa,
      punteggio_so: so.punteggio_so,
      coefficiente: parseFloat(((so.punteggio_so / punteggioTotale) * 100).toFixed(2)),
      pds: budgetTotale * parseFloat((so.punteggio_so / punteggioTotale).toFixed(2)),
    }),
  ).sort(
    (a, b) => b.punteggio_so - a.punteggio_so,
  ).map(
    (item, idx) => ({
      ...item,
      posizione: `${idx + 1}`,
    }),
  );

  // useEffect per aggiornare lo state
  useEffect(() => {
    const struttureUpdated = statePiano.strutture_organizzative.map(
      (so) => ({
        ...so,
        pds: rankingData.find((el) => el.struttura_organizzativa
        === so.struttura_organizzativa).pds,
      }),
    );
    setStatePiano((prevState) => ({
      ...prevState,
      strutture_organizzative: struttureUpdated,
    }));
  }, []);

  return (
    <div as={Col}>
      <h4>Ranking e Budget Preventivo</h4>
      <Table
        data={rankingData}
        fields={fieldsRanking()}
        keyField="posizione"
      />
    </div>
  );
}

export default Ranking;
