const fieldsCompetenzeNoGrading = () => ([
  {
    dataField: 'codice_competenza',
    text: 'Codice',
    classes: 'column-data',
    headerStyle: () => ({ width: '10%' }),
  },
  {
    dataField: 'titolo_competenza',
    text: 'Competenza',
    classes: 'column-data',
    headerStyle: () => ({ width: '45%' }),
  },
]);

export default fieldsCompetenzeNoGrading;
