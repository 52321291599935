import React, { useContext, useEffect, useState } from 'react';
import {
  Col, Container, Image, Row,
} from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import BreadcrumbComponent from '../../../components/Breadcrumb';
import newsService from '../../../services/newsService';
import { Context } from '../../../utils/Context';
import { formatoData } from '../../../utils/formatData';
import RenderHTML from '../../../components/Shared/RenderHTML';

const newsUrl = process.env.REACT_APP_NEWS_FAQ_URL;

function ApprofondimentiDettaglio() {
  const [context, setContext] = useContext(Context);
  const { idNews } = useParams();
  const [news, setNews] = useState({});

  // Function collegata al servizio per ottenere il dettaglio di una News
  async function getNews() {
    try {
      const response = await newsService.getNewsPubblicaById(idNews);
      setNews(response);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // useEffect
  useEffect(() => {
    getNews();
  }, []);

  return (
    <Container className="justify-content-center col-md-10">
      <Row xs={12}>
        <BreadcrumbComponent
          listItem={[
            { name: 'Approfondimenti', pathname: '/approfondimenti', active: false },
            { name: `${news.titolo}`, pathname: '/Dettaglio', active: true },
          ]}
        />
      </Row>
      <Row className="justify-content-center">
        <Col xs={12} className="my-3">
          <h1>{news.titolo}</h1>
        </Col>
        <Col xs={12} className="mb-3">
          <h6 className="text-muted">
            <strong>{formatoData(news.dataUltimaModifica ?? news.dataCreazione)}</strong>
          </h6>
        </Col>
      </Row>
      {news?.pathMinioImmagine && (
      <Row>
        <Image src={news && `${newsUrl}/news-esterne/immagine/${encodeURIComponent(news.pathMinioImmagine)}`} className="w-100" alt="Immagine notizia" />
      </Row>
      )}
      {news.contenuto && (
        <Row>
          <div>
            <article id={`contenuto_${idNews}`} className="my-5" style={{ minHeight: '60vh' }}>
              <RenderHTML html={news.contenuto} />
            </article>
          </div>
        </Row>
      )}
    </Container>
  );
}

export default ApprofondimentiDettaglio;
