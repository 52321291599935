import React from 'react';
import {
  Form, Row, Button, Col, Accordion,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from 'react-select';
import { controlla } from '../../utils/verificaPermessi';
import optionsStati from './constants/optionsStati';

function FiltriRicercaQuestionari({
  filtri,
  onChange,
  getElements,
  onlyTitle,
}) {
  return (
    <Accordion className="mb-3">
      <Accordion.Item eventKey="0">
        <Accordion.Header>Filtri di ricerca</Accordion.Header>
        <Accordion.Body>
          <Form>
            <Row>
              <Col className="mb-3">
                <Form.Group as={Col} lg controlId="titolo">
                  <Form.Label>Titolo</Form.Label>
                  <Form.Control
                    placeholder="Inserisci il titolo"
                    onChange={onChange}
                    value={filtri.titolo}
                  />
                </Form.Group>
              </Col>
              <Col className="mb-3">
                {!onlyTitle && (
                  <Form.Group as={Col} lg controlId="stato">
                    <Form.Label>
                      Stato
                    </Form.Label>
                    <Select
                      id="stato"
                      name="stato"
                      options={optionsStati}
                      placeholder="Filtra stato"
                      getOptionLabel={(option) => option.titolo}
                      getOptionValue={(option) => option.valore}
                      value={optionsStati?.filter(
                        (el) => el.valore === filtri.stato,
                      )?.[0]}
                      onChange={(selectedOption, config) => onChange(selectedOption, config)}
                      isClearable
                    />
                  </Form.Group>
                )}
              </Col>
              {controlla('W3') && (
                <Col>
                  <Form.Check
                    type="switch"
                    id="profonditaStorica"
                    label="Visualizza profondità storica"
                    onChange={(e) => onChange(e, 'checkbox')}
                    value={filtri.profonditaStorica}
                  />
                </Col>
              )}
            </Row>
          </Form>
          <Button variant="primary" onClick={() => getElements(0, 5)}>
            <FontAwesomeIcon aria-hidden="true" icon={['fas', 'search']} style={{ marginRight: '2px' }} />
            CERCA
          </Button>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default FiltriRicercaQuestionari;
