import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {
  Button,
  ButtonGroup,
  Card, Col, Container, Row,
} from 'react-bootstrap';
import { formatoDataEOra } from '../../utils/formatData';
import { controlla } from '../../utils/verificaPermessi';
import ExportButton from '../ExportButton/ExportButton';
import EmptyState from '../EmptyState/index';

function CardGroup({
  questionari,
  functionCompile,
  functionExport,
  functionShow,
  functionAdd,
}) {
  return (
    <Container className="p-0">
      <Row lg={1} xs={1} className="g-4 mt-2 mb-2">
        {questionari.length !== 0 ? questionari.map((el) => (
          <Col key={el._id}>
            <Card>
              <Card.Header>
                <Row className="justify-content-between align-items-center">
                  <Col xs="auto">
                    <h3>{el?.titolo}</h3>
                    {el.stato === 'pubblicato' ? (
                      <div>
                        Creato da
                        <strong>
                          {` ${el?.creatore?.nome} ${el?.creatore?.cognome}`}
                        </strong>
                        {' '}
                        il
                        {' '}
                        <strong>{formatoDataEOra(el.dataCreazione)}</strong>
                      </div>
                    ) : (
                      <div>
                        Data inizio Compilazione:
                        {' '}
                        <strong>
                          {formatoDataEOra(el.dataInizioCompilazione)}
                        </strong>
                      </div>
                    )}
                  </Col>
                  <Col xs="auto">
                    <ButtonGroup className="float-end" aria-label={`Azioni disponibili per il questionario ${el.titolo}`}>
                      {el.stato === 'bozza' && controlla('X39') && (
                        <Button
                          className="me-2"
                          onClick={() => functionCompile(el._id)}
                          title={`Compila il questionario ${el.titolo}`}
                        >
                          <FontAwesomeIcon aria-hidden="true" icon="edit" />
                        </Button>
                      )}
                      {el.stato === 'bozza' && controlla('X41') && (
                        <Button
                          className="me-2"
                          onClick={() => functionShow(el._id)}
                          title={`Visualizza il questionario ${el.titolo}`}
                        >
                          <FontAwesomeIcon aria-hidden="true" icon="search" />
                        </Button>
                      )}
                      {el.stato === 'pubblicato' && controlla('X38') && (
                        <Button
                          className="me-2"
                          onClick={() => functionAdd(el._id)}
                          title={`Inizia la compilazione del questionario ${el.titolo}`}
                        >
                          <FontAwesomeIcon aria-hidden="true" icon="file-pen" />
                        </Button>
                      )}
                    </ButtonGroup>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body>
                <Row className="justify-content-between align-items-center">
                  <Col xs="auto">
                    <strong>Descrizione: </strong>
                    {el?.descrizione}
                    <br />
                    <strong>Domande: </strong>
                    {el?.totaleDomande}
                    <br />
                    <strong>Stato: </strong>
                    {el.stato}
                  </Col>
                  {el.stato !== 'pubblicato' && (
                    <Col xs="auto">
                      <ExportButton
                        id={el._id}
                        functionExport={functionExport}
                      />
                    </Col>
                  )}
                </Row>
              </Card.Body>
              <Card.Footer>
                {el.stato === 'pubblicato' ? (
                  <small className="text-muted">
                    Ultima modifica:
                    <strong>
                      {' '}
                      {`${el?.autoreUltimaModifica?.nome} ${el?.autoreUltimaModifica?.cognome}`}
                      {' '}
                    </strong>
                    {' '}
                    il
                    {' '}
                    <strong>{formatoDataEOra(el.dataUltimaModifica)}</strong>
                  </small>
                ) : (
                  <small className="text-muted">
                    {el?.dataFineCompilazione ? (
                      <>
                        Data fine compilazione:
                        {' '}
                        <strong>{formatoDataEOra(el.dataFineCompilazione)}</strong>
                      </>
                    ) : (
                      <>
                        Ultima modifica:
                        {' '}
                        <strong>{formatoDataEOra(el.dataUltimaModifica)}</strong>
                      </>
                    )}
                  </small>
                )}
              </Card.Footer>
            </Card>
          </Col>
        )) : (
          <EmptyState
            subtitle="Non sono ancora presenti questionari."
          />
        )}
      </Row>
    </Container>
  );
}

export default CardGroup;
