export const fieldsUtenti = [
  {
    dataField: 'codi_id_fiscale',
    text: 'Codice fiscale',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '30%' }),
  },
  {
    dataField: 'desc_nome',
    text: 'Nome',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '30%' }),
  },
  {
    dataField: 'desc_cognome',
    text: 'Cognome',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '35%' }),
  },
];

export const selectRows = (setUtenteSelected) => {
  const selectRow = {
    mode: 'radio',
    clickToSelect: true,
    hideSelectAll: true,
    selectColumnPosition: 'left',
    onSelect: (row) => {
      const event = {
        target: {
          value: row.codi_id_fiscale,
          codi_listmonk_user_id: row.codi_listmonk_user_id,
        },
      };
      setUtenteSelected(event);
    },
  };
  return selectRow;
};
