const optionsFonte = [
  {
    label: 'Titoli di studio',
    value: 'Titoli di studio',
  },
  {
    label: 'Incarichi professionali/istituzionali',
    value: 'Incarichi professionali/istituzionali',
  },
  {
    label: 'Progetti',
    value: 'Progetti',
  },
  {
    label: 'Corsi di formazione, seminari, workshop e conferenze',
    value: 'Corsi di formazione, seminari, workshop e conferenze',
  },
  {
    label: 'Certificazioni professionali',
    value: 'Certificazioni professionali',
  },
  {
    label: 'Volontariato e attività sociali',
    value: 'Volontariato e attività sociali',
  },
  {
    label: 'Esperienze lavorative precedenti e/o esterne',
    value: 'Esperienze lavorative precedenti e/o esterne',
  },
  {
    label: 'Altro',
    value: 'Altro',
  },

];

export default optionsFonte;
