import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import themeColors from '../../../../../utils/contants/theme-colors';

const fieldsDemoElencoAssessment = (onView) => (
  [
    {
      dataField: 'desc_dipendente',
      text: 'Dipendente',
      // sort: true,
      classes: 'column-data',
      headerStyle: () => ({ width: '30%' }),
    },
    {
      dataField: 'desc_stato',
      text: 'Stato',
      // sort: true,
      classes: 'column-data',
      headerStyle: () => ({ width: '20%' }),
    },
    {
      dataField: 'desc_titolo',
      text: 'Profilo di Ruolo',
      // sort: true,
      classes: 'column-data',
      headerStyle: () => ({ width: '30%' }),
    },
    {
      dataField: 'df1',
      text: 'Azioni',
      classes: 'column-data',
      headerStyle: () => ({ width: '20%' }),
      formatter: (e, row) => (
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          {row.desc_stato === 'Completo' && (
          <FontAwesomeIcon
            aria-hidden="true"
            icon={['fas', 'search']}
            size="lg"
            title="Visualizza"
            id={`Visualizza_${row.fk_sequ_utente_trusted}`}
            style={{ color: themeColors.primary }}
            onClick={() => onView(row.desc_stato)}
          />
          ) }
          {(row.desc_stato === 'In etero-valutazione' || row.desc_stato === 'In auto-valutazione') && (
          <FontAwesomeIcon
            aria-hidden="true"
            icon={['fas', 'file-pen']}
            size="lg"
            title="Compila Etero Valutazione"
            id={`Compila_etero_${row.fk_sequ_utente_trusted}`}
            style={{ color: themeColors.primary }}
            onClick={() => onView(row.desc_stato)}
          />
          )}
        </div>
      ),
    },
  ]
);

export default fieldsDemoElencoAssessment;
