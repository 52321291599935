import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import themeColors from '../../../../utils/contants/theme-colors';

const fieldsDipendentiAnalisiEsitiAssessment = (onView, isWithAssessment) => {
  if (isWithAssessment) {
    return (
      [
        {
          dataField: 'desc_dipendente',
          text: 'Dipendente',
          // sort: true,
          classes: 'column-data',
          headerStyle: () => ({ width: '25%' }),
        },
        {
          dataField: 'desc_stato',
          text: 'Stato',
          // sort: true,
          classes: 'column-data',
          headerStyle: () => ({ width: '25%' }),
        },
        {
          dataField: 'responsabile',
          text: 'Responsabile',
          // sort: true,
          classes: 'column-data',
          headerStyle: () => ({ width: '15%' }),
          formatter: (e, row) => `${row.desc_nome_responsabile} ${row.desc_cognome_responsabile}`,
        },
        {
          dataField: 'desc_titolo',
          text: 'Profilo di Ruolo',
          // sort: true,
          classes: 'column-data',
          headerStyle: () => ({ width: '30%' }),
        },
        {
          dataField: 'df1',
          text: 'Azioni',
          classes: 'column-data',
          headerStyle: () => ({ width: '15%' }),
          formatter: (e, row) => (
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
              <FontAwesomeIcon
                aria-hidden="true"
                icon={['fas', 'search']}
                size="lg"
                title="Visualizza"
                id={`Visualizza_${row.fk_sequ_utente_trusted}`}
                style={{ color: themeColors.primary }}
                onClick={() => onView(row.fk_sequ_utente_trusted)}
              />
            </div>
          ),
        },
      ]
    );
  } return (
    [
      {
        dataField: 'desc_dipendente',
        text: 'Dipendente',
        // sort: true,
        classes: 'column-data',
        headerStyle: () => ({ width: '25%' }),
      },
      {
        dataField: 'desc_stato',
        text: 'Stato',
        // sort: true,
        classes: 'column-data',
        headerStyle: () => ({ width: '25%' }),
      },
      {
        dataField: 'responsabile',
        text: 'Responsabile',
        // sort: true,
        classes: 'column-data',
        headerStyle: () => ({ width: '15%' }),
        formatter: (e, row) => `${row.desc_nome_responsabile} ${row.desc_cognome_responsabile}`,
      },
      {
        dataField: 'desc_titolo',
        text: 'Profilo di Ruolo',
        // sort: true,
        classes: 'column-data',
        headerStyle: () => ({ width: '45%' }),
      },
    ]
  );
};

export default fieldsDipendentiAnalisiEsitiAssessment;
