/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import BreadcrumbComponent from '../../components/Breadcrumb';
import './index.css';
import SimpleButton from '../../components/SimpleButton';
import spidIcoCircleBB from '../../images/spid-ico-circle-bb.png';
import immagineRiVa from '../../images/RiVaImage1.jpg';

function AreaAmministrazioni() {
  return (
    <Container className="justify-content-center col-md-10">
      <Row xs={12}>
        <BreadcrumbComponent listItem={[{ name: 'Area Amministrazioni', pathname: '/area-amministrazioni', active: true }]} />
      </Row>
      <Row className="justify-content-center">
        <Col className="my-3">
          <h1>Area Amministrazioni</h1>
        </Col>
      </Row>
      <Row>
        <div style={{ fontSize: '24px' }}>
          Per accedere al Toolkit e utilizzare le relative funzionalità, occorre che ogni
          Amministrazione aderisca attraverso un processo di &quot;accreditamento&quot;
          con il supporto del Dipartimento della Funzione Pubblica della Presidenza del Consiglio
          dei Ministri.
        </div>
        <div style={{ fontSize: '24px' }}>
          Prima della registrazione ciascuna Amministrazione deve individuare e nominare un
          Responsabile per il Toolkit RiVa.
        </div>
        <div style={{ fontSize: '24px' }}>
          Accedi per eseguire la procedura di accreditamento.
        </div>
      </Row>
      <Row className="justify-content-center text-align mt-5">
        <div className="p-0 text-format">
          <strong>Accedi all&apos;Area riservata dedicata alle Amministrazioni</strong>
        </div>
      </Row>
      <Row className="justify-content-center text-align mt-3">
        <SimpleButton
          label="Accedi"
          alt="Logo Account"
          icon={spidIcoCircleBB}
          href="/pagina-login"
          bgColor="#06c"
          width="auto"
        />
        <a href="/content/manuale-accreditamento.pdf" target="_blank" className="mt-5 fw-bold fs-5">Scarica il manuale utente</a>
      </Row>
      <Row className="p-md-5 pt-3">
        <span className="fw-bold h3">Guarda il video:</span>
        <video width="100%" controls preload="none" poster={immagineRiVa}>
          <source src="/content/procedura-accreditamento.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </Row>
      <Row className="p-md-5 pt-3">
        <span className="fw-bold h3">Guarda il video:</span>
        <video width="100%" controls preload="none" poster={immagineRiVa}>
          <source src="/content/procedura-accreditamento.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </Row>
    </Container>
  );
}

export default AreaAmministrazioni;
