const CTSFields = () => (
  [
    {
      dataField: 'Codice',
      text: 'Codice',
      classes: 'column-data',
      headerStyle: () => ({ width: '10%' }),
    },
    {
      dataField: 'Competenza',
      text: 'Competenza Tecnico Specialistica',
      classes: 'column-data',
      headerStyle: () => ({ width: '20%' }),
    },
    {
      dataField: 'Descrizione',
      text: 'Descrizione',
      classes: 'column-data',
      headerStyle: () => ({ width: '40%' }),
    },
    {
      dataField: 'Area',
      text: 'Area',
      classes: 'column-data',
      headerStyle: () => ({ width: '10%' }),
    },
  ]
);

export default CTSFields;
