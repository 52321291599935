import React, { useContext, useEffect, useState } from 'react';
import {
  Col, Container, Row,
} from 'react-bootstrap';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import TitlePage from '../../../../components/GestisciUtenti/TitoloPagina';
import passiAggiungi from './passiAggiungi';
import TimelineBpm from './components/timelineBpm';
import SistemaProfessionaleService from '../../../../services/sistemaProfessionaleService';
import { getItem } from '../../../../utils/storage';
import history from '../../../../utils/history';
import { Context } from '../../../../utils/Context';
import Prioritizzazione from './pagine/Prioritizzazione';
import DefinizioneCosti from './pagine/DefinizioneCosti';
import AssessmentService from '../../../../services/assessmentService';
import RichiestaFabbisogniDirettore from './pagine/RichiestaFabbisogniDirettore';
import RichiesteFabbisogniPolicyMaker from './pagine/RichiesteFabbisogniPolicyMaker';
import ScelteCoperturaFullShowPolicyMaker from './pagine/ScelteCoperturaFullShowPolicyMaker';
import ScelteCoperturaPolicyMaker from './pagine/ScelteCoperturaPolicyMaker';
import nomiProcessi from './data/nomiProcessi';
import ModaleConferma from '../../../../components/modali/ModaleConferma';
import SpinnerBody from '../../../../components/Shared/SpinnerBody';

function AggiungiPiano() {
  // State variabile implementato per gestire le funzionalità all'interno dei processi
  // 0: bottone non cliccato - 1: bottone cliccato visualizza tabella - 2: visualizzazione tabella
  const [isRanking, setIsRanking] = useState(0);
  const [context, setContext] = useContext(Context);
  const { state } = useLocation();
  const { sequ_k_comparto } = getItem('compartoSelected') ? JSON.parse(getItem('compartoSelected')) : 0;
  const { idIdentita } = JSON.parse(getItem('identita'));
  const [step, setStep] = useState(state.step);
  const [isDone, setIsDone] = useState(false);
  const [processoState, setProcessoState] = useState({});
  const [areeContrattualiList, setAreeContrattualiList] = useState([]);
  const [struttureOrganizzativeList, setStruttureOrganizzativeList] = useState([]);
  const [profiliProfRuolo, setProfiliProfRuolo] = useState([]);
  const [strutturaSelected, setStrutturaSelected] = useState({});
  const [blockTable, setBlockTable] = useState(false);
  const [showBigTable, setShowBigTable] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // function collegata al servizio per ottenere la lista delle aree contrattuali
  async function getAreaContrattuale(offset, limit, filters) {
    try {
      const filterSession = filters;
      const response = await SistemaProfessionaleService
        .getAreaContrattuale(filterSession, offset, limit);
      const listaAree = response.rows;
      const areeContrattualiMapped = listaAree.map(
        (el) => ({
          id_area: el.desc_codice,
          nome_area: el.desc_titolo,
        }),
      );
      setAreeContrattualiList(areeContrattualiMapped);
      return areeContrattualiMapped;
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
      return null;
    }
  }

  // function collegata al servizio per ottenere la lista delle aree contrattuali
  async function getStruttureOrganizzative() {
    try {
      const response = await SistemaProfessionaleService
        .getStrutturaOrganizzativa();
      setStruttureOrganizzativeList(response);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // Funzione per ottenere il piano fabbisogno con un id specifico
  async function getPianoFabbisognoById() {
    try {
      const response = await AssessmentService.getProcessoById(state._id);
      await getStruttureOrganizzative();
      // Controllo condizione per il caricamento delle aree contrattuali
      if ((step === 0 || step === 1) && !response.aree_contrattuali.length) {
        const aree_contrattuali = await getAreaContrattuale(0, 1000, { sequ_k_comparto });
        setProcessoState({ ...response, aree_contrattuali });
        setIsLoading(false);
      } else {
        if (step === 2) {
          const struttureFiltered = response.strutture_organizzative.filter(
            (strut) => strut.struttura_organizzativa === state.utente.utenteSO,
          );
          const profiliStrutture = struttureFiltered.map(
            (struttura) => struttura.profili,
          );
          const profiliFormatted = profiliStrutture.flat();
          setProfiliProfRuolo(profiliFormatted);
        }
        setProcessoState(response);
        await getAreaContrattuale(0, 1000, { sequ_k_comparto });
        setIsLoading(false);
      }
    } catch (err) {
      // Gestione errori nel contesto
      setContext((prevContext) => ({
        ...prevContext,
        open: true,
        testoErrore: err?.data?.message || 'Errore',
        statusCode: err?.status,
      }));
    }
  }

  // function collegata al servizio per aggiungere un nuovo piano di fabbisogno
  async function modificaProcesso(notifica, redirect, processo) {
    AssessmentService.aggiornaProcesso(processo._id, processo, notifica)
      .then((res) => {
        setContext(() => ({
          ...context, openConferma: true, testo: res.message,
        }));
        if (redirect) history.push('/area-riservata/demo-bpm/');
      })
      .catch((err) => {
        setContext(() => ({
          ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
        }));
      });
  }

  // Function procedi tra le varie fasi/step del workflows
  const handleProcedi = () => {
    if (step === 0) {
      setIsDone(false);
      setStep(step + 1);
      const processo = {
        ...processoState,
        stato_processo: `${nomiProcessi[0]} - ${passiAggiungi[1]}`,
        fase: 1,
      };
      modificaProcesso('', false, processo);
    }
    if (step === 1 && isRanking === 1) {
      setProcessoState((prevState) => ({
        ...prevState,
        budget_assunzionale: typeof prevState.budget_assunzionale === 'number' ? prevState.budget_assunzionale : parseInt(prevState?.budget_assunzionale?.replace(/[.]/g, ''), 10) || 0,
      }));
      setIsRanking(isRanking + 1);
    }
    if (step === 2) {
      modificaProcesso('');
    }
    if (step === 4) {
      const profiliStrutture = processoState.strutture_organizzative.map(
        (struttura) => struttura.profili,
      );
      const profiliFormatted = profiliStrutture.flat();
      setBlockTable(true);
      setShowBigTable(true);
      setProfiliProfRuolo(profiliFormatted);
      modificaProcesso('', false, processoState);
    }
  };

  // Function per la gestione del processo di prioritizzazione
  const handleConsolida = () => {
    if (isRanking === 2) {
      setStep(step + 1);
      const processo = {
        ...processoState,
        stato_processo: `${nomiProcessi[1]} - ${passiAggiungi[2]}`,
        fase: 2,
      };
      modificaProcesso('pm', true, processo);
    }
    if (state.idIdentita === 10) {
      const strutturaAggiornata = {
        ...strutturaSelected,
        richiesta_inviata: true,
      };
      const struttureUpdated = processoState.strutture_organizzative.map(
        (so) => (so.struttura_organizzativa
          === strutturaSelected.struttura_organizzativa ? strutturaAggiornata : so),
      );
      const processo = {
        ...processoState,
        stato_processo: `${nomiProcessi[1]} - ${passiAggiungi[3]}`,
        fase: 3,
        strutture_organizzative: struttureUpdated,
      };
      modificaProcesso(strutturaSelected.struttura_organizzativa, true, processo);
    }
    if (step === 4 && showBigTable) {
      setModalShow(true);
    }
  };

  // useEffect
  useEffect(() => {
    getPianoFabbisognoById();
  }, []);

  return (
    isLoading
      ? <SpinnerBody />
      : (
        <Container className="p-0">
          <TitlePage
            title={passiAggiungi[step]}
            showSave={(step <= 2 && isRanking < 2)}
            disabledSave={!blockTable && step === 2}
            functionSave={() => modificaProcesso('', false, processoState)}
            showGeneral={(isDone && isRanking < 2) || (step === 4 && !showBigTable && blockTable)}
            titleGeneral={(isRanking === 1 && step === 1) ? 'VEDI RANKING' : (step === 4) ? 'VEDI SINTESI COPERTURA' : 'PROCEDI'}
            iconGeneral="arrow-right"
            variantGeneral="primary"
            functionGeneral={() => handleProcedi()}
            showSchede={(step === 4 && showBigTable)}
            titleSchede="RITORNA ALLE SCELTE"
            functionSchede={() => {
              const { _id } = processoState;
              setStep(3);
              setShowBigTable(false);
              setBlockTable(false);
              history.push('/area-riservata/demo-bpm/aggiungi-piano', { _id, idIdentita });
            }}
            showConsolida={(isRanking === 2
            || (state.idIdentita === 10 && blockTable))
            || (step === 4 && showBigTable)}
            titleConsolida={isRanking === 2 ? 'CONSOLIDA ED INVIA NOTIFICHE' : 'CONSOLIDA'}
            functionConsolida={() => handleConsolida()}
            showIndietro={step === 1}
            functionIndietro={step === 1 && isRanking <= 1
              ? () => setStep(0) : step === 1 && isRanking === 2
                ? () => { setIsRanking(0); setIsDone(false); } : null}
          />
          <Row>
            <Col className="mb-4">
              <TimelineBpm step={step} />
            </Col>
          </Row>
          <Row>
            <Col className="mb-4">
              {(step === 0 && state.idIdentita === 3) && (
              <DefinizioneCosti
                statePiano={processoState}
                setStatePiano={setProcessoState}
                areeContrattuali={areeContrattualiList}
                setIsDone={setIsDone}
              />
              )}
              {(step === 1 && state.idIdentita === 3) && (
              <Prioritizzazione
                statePiano={processoState}
                setStatePiano={setProcessoState}
                struttureOrganizzative={struttureOrganizzativeList}
                isRanking={isRanking}
                setIsRanking={setIsRanking}
                setIsDone={setIsDone}
              />
              )}
              {(step === 2 && state.idIdentita === 10) && (
              <RichiestaFabbisogniDirettore
                tableData={profiliProfRuolo}
                setTableData={setProfiliProfRuolo}
                strutturaSelected={strutturaSelected}
                setStrutturaSelected={setStrutturaSelected}
                processoState={processoState}
                setProcessoState={setProcessoState}
                blockTable={blockTable}
                setBlockTable={setBlockTable}
              />
              )}
              {(step === 3 && state.idIdentita === 3) && (
              <RichiesteFabbisogniPolicyMaker
                setStep={setStep}
              />
              )}
              {(step === 4 && state.idIdentita === 3 && !showBigTable) && (
              <ScelteCoperturaPolicyMaker
                tableData={profiliProfRuolo}
                setTableData={setProfiliProfRuolo}
                strutturaSelected={strutturaSelected}
                setStrutturaSelected={setStrutturaSelected}
                processoState={processoState}
                setProcessoState={setProcessoState}
                blockTable={blockTable}
                setBlockTable={setBlockTable}
              />
              )}
              {(step === 4 && state.idIdentita === 3 && showBigTable) && (
              <ScelteCoperturaFullShowPolicyMaker
                tableData={profiliProfRuolo}
                setTableData={setProfiliProfRuolo}
                processoState={processoState}
                blockTable={blockTable}
              />
              )}
            </Col>
          </Row>
          <ModaleConferma
            title="Consolidamento Scelte di Copertura"
            body={`Confermi l'inserimento dei dati complessivi di tutte le Strutture
              Organizzative del Piano di Fabbisogno ${processoState.nome_processo}?
              L'azione NON è reversibile. Per modificare i dati inseriti, cliccare
              sul bottone "SCHEDE" disponibile.`}
            labelBottone="CONSOLIDA"
            coloreAzione="primary"
            show={modalShow}
            handleClose={() => setModalShow(false)}
            handleOk={() => {
              const processo = {
                ...processoState,
                fase: 5,
                stato_processo: nomiProcessi[2],
              };
              modificaProcesso('', true, processo);
            }}
          />
        </Container>
      )
  );
}

export default AggiungiPiano;
