import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {
  Button, ButtonGroup, Card, Col, Row,
} from 'react-bootstrap';
import EmptyState from '../EmptyState';

function CardGroupSezioni({
  sezioni,
  setEditedSezione,
  functionEdit,
  functionClone,
  functionDelete,
  functionShow,
  handleStateModaleConferma,
  setFunzioneModaleConferma,
  setTestoModaleConferma,
  readOnly,
}) {
  return (
    <div className="mt-3">
      <Row xs={1} md={2} className="g-4">
        {sezioni ? Object.keys(sezioni).map((el) => (
          <Col key={sezioni[el].titolo}>
            <Card>
              <Card.Header>
                <Card.Title className="mb-0">
                  {sezioni[el].titolo}
                </Card.Title>
              </Card.Header>
              <Card.Body>
                <Card.Text>
                  <p>
                    Descrizione:
                    {' '}
                    <strong>{sezioni[el].descrizione}</strong>
                    <br />
                    Numero domande:
                    {' '}
                    {sezioni[el].totaleDomande || 0}
                  </p>
                </Card.Text>
                {readOnly ? (
                  <Button
                    className="me-2 float-end"
                    onClick={() => { setEditedSezione(sezioni[el]); functionShow(); }}
                    title={`Visualizza la sezione ${sezioni[el].titolo}`}
                  >
                    <FontAwesomeIcon aria-hidden="true" icon="search" />
                  </Button>
                ) : (
                  <ButtonGroup className="float-end" aria-label={`Azioni disponibili per la sezione ${sezioni[el].titolo}`}>
                    <Button
                      className="me-2"
                      onClick={() => { setEditedSezione(sezioni[el]); functionShow(); }}
                      title={`Modifica le domande della sezione ${sezioni[el].titolo}`}
                    >
                      <FontAwesomeIcon aria-hidden="true" icon="question" />
                    </Button>
                    <Button
                      className="me-2"
                      onClick={() => { functionEdit(sezioni[el]); }}
                      title={`Modifica i dettagli della sezione ${sezioni[el].titolo}`}
                    >
                      <FontAwesomeIcon aria-hidden="true" icon="edit" />
                    </Button>
                    <Button
                      className="me-2"
                      onClick={() => { functionClone(sezioni[el].titolo); }}
                      title={`Clona la sezione ${sezioni[el].titolo}`}
                    >
                      <FontAwesomeIcon aria-hidden="true" icon="clone" />
                    </Button>
                    <Button
                      className="me-2"
                      variant="danger"
                      title={`Elimina la sezione ${sezioni[el].titolo}`}
                      onClick={() => {
                        setEditedSezione(sezioni[el]);
                        setFunzioneModaleConferma(() => functionDelete);
                        setTestoModaleConferma('eliminerà');
                        handleStateModaleConferma();
                      }}
                    >
                      <FontAwesomeIcon aria-hidden="true" icon="trash-alt" />
                    </Button>
                  </ButtonGroup>
                )}
              </Card.Body>
            </Card>
          </Col>
        )) : (
          <EmptyState marginTop="3rem" subtitle="Nessuna sezione ancora presente" />
        )}
      </Row>
    </div>
  );
}

export default CardGroupSezioni;
