/* eslint-disable react/no-array-index-key */
function HeaderModello({
  criteriList,
}) {
  // Raccoglimento dati e definizioni dei criteri inseriti per la personalizzazione della tabella
  const criteriQuantitativi = criteriList.filter(
    (el) => el.tipologia === 'Quantitativi',
  );
  const criteriQualitativi = criteriList.filter(
    (el) => el.tipologia === 'Qualitativi',
  );

  return (
    <thead style={{ textAlign: 'center', verticalAlign: 'middle' }}>
      <tr>
        <th rowSpan="4">Nome SO</th>
        <th colSpan={criteriQuantitativi.length + criteriQualitativi.length}>Criteri</th>
        <th rowSpan="4">Punteggio complessivo di SO</th>
      </tr>
      <tr>
        <th colSpan={criteriQuantitativi.length}>Criteri Quantitativi/Correttivi</th>
        <th colSpan={criteriQualitativi.length}>Criteri Qualitativi</th>
      </tr>
      <tr>
        {criteriQuantitativi.map(
          (el, ind) => (
            <th key={ind}>
              {el.criterio}
            </th>
          ),
        )}
        {criteriQualitativi.map(
          (el, ind) => (
            <th key={ind}>
              {el.criterio}
            </th>
          ),
        )}
      </tr>
      <tr>
        {criteriQuantitativi.map(
          (el, ind) => (
            <th key={ind} style={{ fontWeight: 'lighter' }}>
              {el.valore_percentuale}
              %
            </th>
          ),
        )}
        {criteriQualitativi.map(
          (el, ind) => (
            <th key={ind} style={{ fontWeight: 'lighter' }}>
              {el.valore_percentuale}
              %
            </th>
          ),
        )}
      </tr>
    </thead>
  );
}

export default HeaderModello;
