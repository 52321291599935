import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useState } from 'react';
import {
  Row, Button,
} from 'react-bootstrap';
import Title from '../../../components/GestisciUtenti/TitoloPagina';
import ModaleConferma from '../../../components/modali/ModaleConferma';
import FormRichiestaIdentita from '../../../components/RichiesteIdentita/Form';
import richiestIdentitaService from '../../../services/richiestaIdentitaService';
import { Context } from '../../../utils/Context';
import history from '../../../utils/history';
import { getItem } from '../../../utils/storage';

function NuovaRichiestaIdentitaPerUtente() {
  const [context, setContext] = useContext(Context);
  const [richiestaIdentita, setRichiestaIdentita] = useState({
    data_richiesta: new Date(),
    desc_nome: (JSON.parse(getItem('user'))).desc_nome,
    desc_cognome: (JSON.parse(getItem('user'))).desc_cognome,
  });
  const [openCloseModaleConferma, setOpenCloseModaleConferma] = useState(false);
  const [options, setOptions] = useState([]);
  function handleStateModaleConferma() {
    setOpenCloseModaleConferma(!openCloseModaleConferma);
  }

  async function nuovaRichiesta() {
    richiestIdentitaService.salvaRichiesta(richiestaIdentita)
      .then((res) => {
        setContext(() => ({
          ...context, openConferma: true, testo: res.message,
        }));
        history.push(`/area-riservata/richiesta-identita/${res.sequ_k_richiesta}/modifica`);
      })
      .catch((err) => {
        setOpenCloseModaleConferma();
        setContext(() => ({
          ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
        }));
      });
  }

  async function onChange(e, config) {
    // Verifica se è una Select di react-select
    if (config) {
      setRichiestaIdentita({
        ...richiestaIdentita,
        [config.name]: e?.cod_amm,
      });
    } else {
      const { value } = e.target;
      const { id } = e.target;
      setRichiestaIdentita({
        ...richiestaIdentita,
        [id]: value,
      });
    }
  }

  return (
    <Row id="form-richiesta-identita">
      <Row>
        <Title
          title="Nuova richiesta di accreditamento"
          subtitle="È possibile inserire una nuova richiesta"
          showIndietro
          functionIndietro={() => history.push('/area-riservata/richiesta-identita')}
        />
      </Row>
      <FormRichiestaIdentita
        richiestaIdentita={richiestaIdentita}
        onChange={onChange}
        disabledRichiedente
        context={context}
        setContext={setContext}
        options={options}
        setOptions={setOptions}
      />
      <>
        <div className="d-flex justify-content-end">
          <Button variant="primary" className="text-uppercase" onClick={handleStateModaleConferma}>
            <FontAwesomeIcon aria-hidden="true" icon="save" />
            {' '}
            Salva
          </Button>
        </div>
        <ModaleConferma
          labelBottone="Conferma"
          coloreAzione="primary"
          title="Sei sicuro di procedere?"
          body={'L\'operazione salverà una nuova richiesta di accreditamento'}
          show={openCloseModaleConferma}
          handleClose={handleStateModaleConferma}
          handleOk={nuovaRichiesta}
        />
      </>
    </Row>
  );
}

export default NuovaRichiestaIdentitaPerUtente;
