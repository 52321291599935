import React, { useContext, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import ModaleCentroVerticale from '../../../components/modali/ModaleCentroVerticale';
import FormAreaContrattuale from './FormAreaContrattuale';
import TitlePage from '../../../components/GestisciUtenti/TitoloPagina';
import Table from '../../../components/Shared/Table';
import fieldsAreaContrattuale from './fieldsTable';
import SistemaProfessionaleService from '../../../services/sistemaProfessionaleService';
import { Context } from '../../../utils/Context';
import { getItem, setItem } from '../../../utils/storage';
import ModaleConferma from '../../../components/modali/ModaleConferma';
import checkStorage from '../../../utils/checkSessionStorage';
import FiltriAreaContrattuale from './filtriAreaContrattuale';
import EmptyState from '../../../components/EmptyState';
import { controlla } from '../../../utils/verificaPermessi';

function AreaContrattuale() {
  const [context, setContext] = useContext(Context);
  const [areaContrattualeList, setAreaContrattualeList] = useState([]);
  const [areaContrattualeSelected, setAreaContrattualeSelected] = useState();
  const [modalShow, setModalShow] = useState(false);
  const [modalShowView, setModalShowView] = useState(false);
  const [modalShowModify, setModalShowModify] = useState(false);
  const [modalShowDelete, setModalShowDelete] = useState(false);
  const { sequ_k_comparto } = getItem('compartoSelected') ? JSON.parse(getItem('compartoSelected')) : 0;
  const { idAmministrazione } = JSON.parse(getItem('identita'));
  const [stateAreaContrattuale, setStateAreaContrattuale] = useState({
    desc_titolo: '',
    fk_sequ_comparto: sequ_k_comparto,
  });

  const [filtri, setFiltri] = useState({});
  const [pagination, setPagination] = useState({
    page: 1,
    sizePerPage: 10,
    totalSize: 0,
  });

  // function collegata al servizio per ottenere la lista delle aree contrattuali
  async function getAreaContrattuale(offset, limit, page, filters) {
    try {
      const filterSession = filters || filtri;
      const response = await SistemaProfessionaleService
        .getAreaContrattuale(filterSession, offset, limit);
      setAreaContrattualeList(response.rows);
      setPagination((prevPag) => ({
        ...prevPag,
        page,
        sizePerPage: limit,
        totalSize: response.totalRowCount,
      }));
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // function attivata al cambio pagina della tabella
  const handleTableChange = (type, {
    page, sizePerPage, sortField, sortOrder,
  }) => {
    const newFilters = {
      ...filtri,
      fk_sequ_comparto: sequ_k_comparto,
      sortField: type === 'sort' ? sortField : 'desc_codice',
      sortOrder: type === 'sort' ? sortOrder : 'asc',
    };
    let currentIndex;
    if (page !== 0) {
      currentIndex = (page - 1) * sizePerPage;
    }
    getAreaContrattuale(
      currentIndex,
      sizePerPage,
      page,
      newFilters,
    );
  };

  // useEffect
  useEffect(() => {
    let offset = 0;
    const storage = checkStorage(filtri, setFiltri, setPagination);
    if (storage.page > 1) {
      handleTableChange(null, { page: storage.page, sizePerPage: pagination.sizePerPage });
      offset = (storage.page - 1) * pagination.sizePerPage;
    }
    getAreaContrattuale(
      offset,
      10,
      storage.page,
      { ...storage.filtri, fk_sequ_comparto: sequ_k_comparto },
    );
  }, []);

  // useEffect per settare nello storage la pagina corrente
  useEffect(() => {
    setItem('page', pagination.page);
  }, [pagination.page]);

  // useEffect per caricare i dati presenti nella tabella.
  useEffect(() => {
    setFiltri({ ...filtri, fk_sequ_comparto: sequ_k_comparto });
    getAreaContrattuale(0, 10, 1, { ...filtri, fk_sequ_comparto: sequ_k_comparto });
    setStateAreaContrattuale({ ...stateAreaContrattuale, fk_sequ_comparto: sequ_k_comparto });
  }, [sequ_k_comparto]);

  // function per modificare lo stato dell'area contrattuale che l'utente sta modificando.
  const onChange = (e, config) => {
    if (config) {
      setStateAreaContrattuale({ ...stateAreaContrattuale, [config.name]: e?.sequ_k_comparto });
    } else {
      const { name, value } = e.target;
      setStateAreaContrattuale({ ...stateAreaContrattuale, [name]: value });
    }
  };

  // function per modificare lo stato che gestisce i filtri
  function onChangeFiltri(e) {
    const { value, name } = e.target;
    setFiltri({
      ...filtri, [name]: value,
    });
  }

  // function per effettuare il refresh della pagina
  function refresh() {
    getAreaContrattuale(0, 10, 1, { ...filtri, fk_sequ_comparto: sequ_k_comparto });
  }

  // function collegata al servizio di creazione di una nuova area contrattuale
  async function nuovaAreaContrattuale() {
    SistemaProfessionaleService.nuovaAreaContrattuale(stateAreaContrattuale)
      .then((res) => {
        setContext(() => ({
          ...context, openConferma: true, testo: res.message,
        }));
        setModalShow(false);
        refresh();
      })
      .catch((err) => {
        setContext(() => ({
          ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
        }));
      });
  }

  // function collegata al servizio di eliminazione di una area contrattuale
  async function eliminaAreaContrattuale(idAreaContrattuale) {
    SistemaProfessionaleService.eliminaAreaContrattuale(idAreaContrattuale)
      .then((res) => {
        setContext(() => ({
          ...context, openConferma: true, testo: res.message,
        }));
        setModalShowDelete(false);
        refresh();
      })
      .catch((err) => {
        setContext(() => ({
          ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
        }));
      });
  }

  // function collegata al servizio di modifica di una area contrattuale
  async function modificaAreaContrattuale(idAreaContrattuale) {
    SistemaProfessionaleService.modificaAreaContrattuale(
      idAreaContrattuale,
      stateAreaContrattuale,
    )
      .then((res) => {
        setContext(() => ({
          ...context, openConferma: true, testo: res.message,
        }));
        setModalShowModify(false);
        refresh();
      })
      .catch((err) => {
        setContext(() => ({
          ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
        }));
      });
  }

  // function collegata al servizio di get di una particolare area contrattuale
  async function getAreaContrattualeById(id) {
    try {
      const response = await SistemaProfessionaleService
        .getAreaContrattualeById(id);
      setStateAreaContrattuale({
        desc_titolo: response.desc_titolo,
        desc_suggerimento: response.desc_suggerimento,
        fk_sequ_comparto: response.fk_sequ_comparto,
        desc_codice: response.desc_codice,
      });
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // Funzione attivata al click dell'icona di eliminazione
  const onDelete = (areaContrattuale) => {
    setModalShowDelete(true);
    setAreaContrattualeSelected(areaContrattuale);
  };

  // Funzione attivata al click dell'icona di eliminazione
  const onView = (areaContrattuale) => {
    getAreaContrattualeById(areaContrattuale.id);
    setAreaContrattualeSelected(areaContrattuale);
    setModalShowView(true);
  };

  // Funzione attivata al click dell'icona di eliminazione
  const onModify = (modify, areaContrattuale) => {
    getAreaContrattualeById(areaContrattuale.id);
    setAreaContrattualeSelected(areaContrattuale);
    if (modify) {
      setModalShowModify(true);
    } else {
      setModalShowView(false);
      setModalShowModify(true);
    }
  };

  return (
    <>
      <Container className="p-0">
        <TitlePage
          title="Area Contrattuale CCNL"
          showRefresh
          functionRefresh={refresh}
          showAdd={controlla('X23') && sequ_k_comparto}
          functionAdd={() => setModalShow(true)}
        />
        <FiltriAreaContrattuale
          filtri={filtri}
          setFiltri={setFiltri}
          onChangeFiltri={onChangeFiltri}
          getAreaContrattuale={getAreaContrattuale}
        />
        {areaContrattualeList.length > 0 ? (
          <Table
            data={areaContrattualeList}
            fields={fieldsAreaContrattuale(onDelete, onView, onModify, idAmministrazione)}
            pagination={pagination}
            onTableChange={handleTableChange}
            keyField="area_contrattuale_list"
          />
        ) : (
          <EmptyState marginTop="3rem" />
        )}
      </Container>
      <ModaleCentroVerticale
        modalTitle="Crea una nuova Area Contrattuale CCNL"
        modalBody={(
          <FormAreaContrattuale
            stateAreaContrattuale={stateAreaContrattuale}
            onChange={onChange}
            isNew
          />
        )}
        buttonType="submit"
        show={modalShow}
        onConfirm={() => nuovaAreaContrattuale()}
        labelConfirm="Aggiungi"
        onClose={() => setModalShow(false)}
      />
      <ModaleConferma
        labelBottone="Elimina"
        coloreAzione="primary"
        title="Sei sicuro di procedere?"
        body={`L'operazione eliminerà l'area contrattuale ${areaContrattualeSelected?.codice}`}
        show={modalShowDelete}
        handleClose={() => setModalShowDelete(false)}
        handleOk={() => eliminaAreaContrattuale(areaContrattualeSelected.id)}
      />
      <ModaleCentroVerticale
        modalTitle="Area Contrattuale CCNL"
        modalBody={(
          <FormAreaContrattuale
            stateAreaContrattuale={stateAreaContrattuale}
            disabled
          />
        )}
        buttonType="submit"
        show={modalShowView}
        onConfirm={() => onModify(false, areaContrattualeSelected)}
        labelConfirm="Modifica"
        disabledConfirm={(!controlla('X23') || idAmministrazione !== areaContrattualeSelected?.idAmministrazione)}
        onClose={() => setModalShowView(false)}
      />
      <ModaleCentroVerticale
        modalTitle="Modifica Area Contrattuale CCNL"
        modalBody={(
          <FormAreaContrattuale
            stateAreaContrattuale={stateAreaContrattuale}
            onChange={onChange}
          />
        )}
        buttonType="submit"
        show={modalShowModify}
        onConfirm={() => modificaAreaContrattuale(areaContrattualeSelected.id)}
        labelConfirm="Conferma"
        onClose={() => setModalShowModify(false)}
      />
    </>
  );
}

export default AreaContrattuale;
