import api from '../utils/api';

const statisticheURL = process.env.REACT_APP_REPORTING_URL;

// Function chiamata generale di estrapolazione dati
async function getStatistiche(
  fk_sequ_comparto,
  type,
  offset,
  limit,
  filters,
) {
  let params = '';
  if (fk_sequ_comparto) {
    params += `fk_sequ_comparto=${fk_sequ_comparto}&`;
  }
  if (offset || offset === 0) {
    params += `offset=${offset}&`;
  }
  if (limit) {
    params += `limit=${limit}&`;
  }
  if (filters.famiglia_professionale) {
    params += `famiglia_professionale=${filters.famiglia_professionale}&`;
  }
  if (filters.area_contrattuale) {
    params += `area_contrattuale=${filters.area_contrattuale}&`;
  }
  if (filters.dimensione_professionale) {
    params += `dimensione_professionale=${filters.dimensione_professionale}&`;
  }
  if (filters.ambito_ruolo) {
    params += `ambito_ruolo=${filters.ambito_ruolo}&`;
  }
  return api.get(`${statisticheURL}/${type}/?${params}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

// Function chiamata per le esportazioni
async function getStatisticheExport(
  fk_sequ_comparto,
  type,
  typeExport,
  offset,
  limit,
  filters,
) {
  let params = '';
  if (fk_sequ_comparto) {
    params += `fk_sequ_comparto=${fk_sequ_comparto}&`;
  }
  if (offset || offset === 0) {
    params += `offset=${offset}&`;
  }
  if (limit) {
    params += `limit=${limit}&`;
  }
  if (filters.famiglia_professionale) {
    params += `famiglia_professionale=${filters.famiglia_professionale}&`;
  }
  if (filters.area_contrattuale) {
    params += `area_contrattuale=${filters.area_contrattuale}&`;
  }
  if (filters.dimensione_professionale) {
    params += `dimensione_professionale=${filters.dimensione_professionale}&`;
  }
  return api.get(`${statisticheURL}/${type}/${typeExport}/?${params}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

const StatisticheService = {
  getStatistiche,
  getStatisticheExport,
};

export default StatisticheService;
