import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Container, Form } from 'react-bootstrap';
import { Context } from '../../../../utils/Context';
import AssessmentService from '../../../../services/assessmentService';
import TitlePage from '../../../../components/GestisciUtenti/TitoloPagina';
import Table from '../../../../components/Shared/Table';
import history from '../../../../utils/history';
import fieldsDipendentiEteroAssessment from './fieldsTable';
import EmptyState from '../../../../components/EmptyState';
import ModaleCentroVerticale from '../../../../components/modali/ModaleCentroVerticale';
import BodyModaleSollecito from './sollecito';
import BodyModaleColloquio from './colloquio';

function EteroAssessmentDettaglioDipendenti() {
  const [context, setContext] = useContext(Context);
  const { idCicloAssessment } = useParams();
  const { state, pathname } = useLocation();
  const [listaDipendenti, setListaDipendenti] = useState([]);
  const [listaSolleciti, setListaSolleciti] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [modalInterview, setModalInterview] = useState(false);
  const [modalViewInterview, setModalViewInterview] = useState(false);
  const [dipendenteSelected, setDipendenteSelected] = useState({});
  const [idAssessment, setIdAssessment] = useState(0);
  const [paginationWithAssessment, setPaginationWithAssessment] = useState({
    page: 1,
    sizePerPage: 10,
    totalSize: 0,
  });
  const [paginationWithoutAssessment, setPaginationWithoutAssessment] = useState({
    page: 1,
    sizePerPage: 10,
    totalSize: 0,
  });

  // Function collegata al servizio di get della lista di dipendenti di un ciclo di assessment
  async function getListAssessmentResponsabileById(id, offset, limit, page) {
    try {
      const response = await AssessmentService.getListAssessmentResponsabileById(id, offset, limit);
      setListaDipendenti([
        ...response.userWithAssessment.data.map((el) => ({
          ...el,
          desc_dipendente: `${el.desc_nome} ${el.desc_cognome}`,
          withAssessment: true,
        })),
        ...response.userWithoutAssessment.data.map((el) => ({
          desc_stato: 'Da iniziare',
          ...el,
          desc_dipendente: `${el.desc_nome} ${el.desc_cognome}`,
          withAssessment: false,
        })),
      ]);
      setPaginationWithAssessment((prevPag) => ({
        ...prevPag,
        page,
        sizePerPage: limit,
        totalSize: response.totalRowCount,
      }));
      setPaginationWithoutAssessment((prevPag) => ({
        ...prevPag,
        page,
        sizePerPage: limit,
        totalSize: response.totalRowCount,
      }));
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // Function collegata al servizio di get della lista dei solleciti di un ciclo di assessment
  async function getListSolleciti(idDipendente) {
    try {
      const response = await AssessmentService.getListSolleciti(idCicloAssessment, idDipendente);
      setListaSolleciti(response);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // Function collegata al servizio di get della lista dei solleciti di un ciclo di assessment
  async function nuovoSollecito(idDipendente) {
    try {
      const response = await AssessmentService.nuovoSollecito(idCicloAssessment, idDipendente);
      setContext(() => ({
        ...context, openConferma: true, testo: response.message,
      }));
      setModalShow(false);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // Function collegata al servizio di put per fissare la data del colloquio richiesto
  async function fissaColloquio(id, dataColloquio) {
    try {
      const response = await AssessmentService.fissaColloquio(id, dataColloquio);
      setContext(() => ({
        ...context, openConferma: true, testo: response.message,
      }));
      setModalInterview(false);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // function attivata al cambio pagina della tabella
  const handleTableChangeWithAssessment = (type, { page, sizePerPage }) => {
    let currentIndex;
    if (page !== 0) {
      currentIndex = (page - 1) * sizePerPage;
    }
    getListAssessmentResponsabileById(idCicloAssessment, currentIndex, sizePerPage, page, {});
  };

  // function attivata al cambio pagina della tabella
  const handleTableChangeWithoutAssessment = (type, { page, sizePerPage }) => {
    let currentIndex;
    if (page !== 0) {
      currentIndex = (page - 1) * sizePerPage;
    }
    getListAssessmentResponsabileById(idCicloAssessment, currentIndex, sizePerPage, page, {});
  };

  // useEffect per caricare i dati presenti nella tabella.
  useEffect(() => {
    getListAssessmentResponsabileById(idCicloAssessment);
  }, []);

  // Funzione attivata al click dell'icona di visualizzazione
  const onView = (idUser) => {
    history.push(`${pathname}/${idUser}`, { titleCicloAssessment: state.titleCicloAssessment });
  };

  // Funzione attivata al click dell'icona di compilazione
  const onCompile = (idUser) => {
    history.push(`${pathname}/${idUser}`, { titleCicloAssessment: state.titleCicloAssessment });
  };

  // Funzione attivata al click dell'icona di invio sollecito
  const onReminder = (id) => {
    getListSolleciti(id);
    setModalShow(true);
  };

  // Funzione attivata al click dell'icona di conferma colloquio
  const onInterview = () => {
    setModalInterview(true);
  };

  // Funzione attivata al click dell'icona di colloquio confermato
  const onViewInterview = () => {
    setModalViewInterview(true);
  };

  // function per modificare l'inserimento della data del colloquio richiesto
  const onChange = (e) => {
    const { name, value } = e.target;
    setDipendenteSelected({ ...dipendenteSelected, [name]: value });
  };

  return (
    <Container className="p-0">
      <TitlePage
        title={`Etero valutazione - ${state.titleCicloAssessment}`}
        showIndietro
        functionIndietro={() => history.goBack()}
        showRefresh
        functionRefresh={() => getListAssessmentResponsabileById(idCicloAssessment)}
      />
      <Form.Label style={{ fontWeight: 'bold' }}>
        Dipendenti con Assessment
      </Form.Label>
      {listaDipendenti.filter((el) => el.withAssessment).length > 0 ? (
        <Table
          data={listaDipendenti.filter((el) => el.withAssessment)}
          fields={fieldsDipendentiEteroAssessment(
            onView,
            onCompile,
            onReminder,
            onInterview,
            onViewInterview,
            true,
            setDipendenteSelected,
            setIdAssessment,
          )}
          pagination={paginationWithAssessment}
          onTableChange={handleTableChangeWithAssessment}
          keyField="dipendenti_with_assessment"
        />
      ) : (
        <EmptyState
          subtitle="Non ci sono dipendenti con assegnazioni"
          marginTop="3rem"
        />
      )}
      <Form.Label style={{ fontWeight: 'bold' }}>
        Dipendenti senza Assessment
      </Form.Label>
      {listaDipendenti.filter((el) => !el.withAssessment).length > 0 ? (
        <Table
          data={listaDipendenti.filter((el) => !el.withAssessment)}
          fields={fieldsDipendentiEteroAssessment(
            onView,
            onCompile,
            onReminder,
            onInterview,
            onViewInterview,
            false,
            setDipendenteSelected,
            setIdAssessment,
          )}
          pagination={paginationWithoutAssessment}
          onTableChange={handleTableChangeWithoutAssessment}
          keyField="dipendenti_without_assessment"
        />
      ) : (
        <EmptyState
          title=""
          subtitle="Nessun dipendente deve iniziare il ciclo di assessment"
          marginTop="3rem"
        />
      )}
      <ModaleCentroVerticale
        modalTitle="Invio sollecito utente"
        modalBody={(
          <BodyModaleSollecito
            listaSolleciti={listaSolleciti}
          />
        )}
        show={modalShow}
        buttonType="button"
        labelConfirm="Invia"
        onConfirm={() => nuovoSollecito(dipendenteSelected.fk_sequ_utente_trusted)}
        onClose={() => setModalShow(false)}
      />
      <ModaleCentroVerticale
        modalTitle="Conferma colloquio"
        modalBody={(
          <BodyModaleColloquio
            stateColloquio={dipendenteSelected}
            onChange={onChange}
          />
        )}
        show={modalInterview}
        buttonType="button"
        labelConfirm="Invia conferma"
        onConfirm={() => {
          fissaColloquio(
            idAssessment,
            { data_colloquio: dipendenteSelected.data_colloquio },
          );
          getListAssessmentResponsabileById(idCicloAssessment);
        }}
        onClose={() => setModalInterview(false)}
      />
      <ModaleCentroVerticale
        modalTitle="Riepilogo colloquio"
        modalBody={(
          <BodyModaleColloquio
            stateColloquio={dipendenteSelected}
            onChange={onChange}
            isDisabled
          />
        )}
        disabledConfirm
        show={modalViewInterview}
        onClose={() => setModalViewInterview(false)}
      />
    </Container>
  );
}

export default EteroAssessmentDettaglioDipendenti;
