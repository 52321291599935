import React from 'react';
import {
  Form, Row, Button, Col,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function FiltriRicercaUtentiChat({
  onChangeFiltriUtenti,
  getListaUtenti,
}) {
  const handleFormSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submission behavior
    getListaUtenti(0, 5, 1);
  };

  return (
    <Row id="form-cerca-utenti">
      <Form onSubmit={handleFormSubmit}>
        <Row className="mb-3">
          <Form.Group as={Col} lg controlId="testo">
            <Form.Label style={{ fontWeight: 'bold' }}>
              Ricerca utenti
            </Form.Label>
            <Form.Control
              placeholder="Ricerca per nome, cognome, codice fiscale"
              onChange={onChangeFiltriUtenti}
            />
          </Form.Group>
        </Row>
        <Button
          type="submit"
          variant="primary"
          className="float-end"
          aria-label="Ricerca le informazioni secondo i filtri inseriti"
        >
          <FontAwesomeIcon aria-hidden="true" icon="search" className="me-2" />
          CERCA
        </Button>
      </Form>
    </Row>
  );
}

export default FiltriRicercaUtentiChat;
