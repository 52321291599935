import { Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import { getItem } from '../../../utils/storage';
import CustomSelect from '../../../components/Select/CustomSelect';

function FormAmbitoRuolo({
  stateAmbitoRuolo,
  onChange,
  disabled = false,
  optionsFamigliaProfessionale,
  isNew = false,
  errors = {},
}) {
  const options = JSON.parse(getItem('comparti'));

  return (
    <Form.Group as={Col} lg controlId="ambiti-ruolo">
      { !isNew && (
        <>
          <Form.Label>
            Codice
          </Form.Label>
          <Form.Control
            name="desc_codice"
            value={stateAmbitoRuolo.desc_codice}
            onChange={onChange}
            maxLength={100}
            disabled
          />
        </>
      ) }
      <Form.Label>
        Nome
      </Form.Label>
      <Form.Control
        name="desc_titolo"
        value={stateAmbitoRuolo.desc_titolo}
        placeholder="Inserisci il nome"
        onChange={onChange}
        maxLength={100}
        required
        disabled={disabled}
      />
      <Form.Label>
        Comparto
      </Form.Label>
      <Select
        id="fk_sequ_comparto"
        name="fk_sequ_comparto"
        options={options}
        isDisabled
        placeholder="Scegli comparto"
        getOptionLabel={(option) => option.desc_comparto}
        getOptionValue={(option) => option.sequ_k_comparto}
        value={options?.filter(
          (el) => el.sequ_k_comparto === stateAmbitoRuolo.fk_sequ_comparto,
        )?.[0]}
        onChange={(selectedOption, config) => { onChange(selectedOption, config); }}
      />
      {(stateAmbitoRuolo.fk_sequ_comparto === 1
        || stateAmbitoRuolo.fk_sequ_comparto === 2) && (
          <>
            <Form.Label>
              Famiglia Professionale
            </Form.Label>
            <CustomSelect
              id="fk_sequ_famiglia_professionale"
              name="fk_sequ_famiglia_professionale"
              options={optionsFamigliaProfessionale}
              placeholder="Scegli la famiglia professionale"
              isDisabled={disabled}
              isClearable
              getOptionLabel={(option) => option.desc_titolo}
              getOptionValue={(option) => option.sequ_k_famiglia_professionale}
              value={optionsFamigliaProfessionale?.filter(
                (el) => el.sequ_k_famiglia_professionale
              === stateAmbitoRuolo.fk_sequ_famiglia_professionale,
              )?.[0]}
              onChange={(selectedOption, config) => { onChange(selectedOption, config); }}
              errors={errors}
              errorMessage="*Campo Famiglia Professionale obbligatorio"
            />
          </>
      )}
    </Form.Group>
  );
}

export default FormAmbitoRuolo;
