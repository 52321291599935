import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useEffect, useState } from 'react';
import {
  Card, Col, Row,
} from 'react-bootstrap';
import Board from '../../../components/Chat/Board';
import ManageRoom from '../../../components/Chat/ManageRoom';
import NewRoomModal from '../../../components/Chat/NewRoomModal';
import RoomsList from '../../../components/Chat/RoomsList';
import TextBox from '../../../components/Chat/TextBox';
import themeColors from '../../../utils/contants/theme-colors';
import styles from './index.module.scss';
import Title from '../../../components/GestisciUtenti/TitoloPagina/index';
import { client } from '../../../utils/matrix';
import { Context } from '../../../utils/Context';
import chatService from '../../../services/chatService';
import { removeItem } from '../../../utils/storage';

function Chat() {
  const [context, setContext] = useContext(Context);
  const [showNewRoomModal, setShowNewRoomModal] = useState(false);
  const [boardOrManageRoom, setBoardOrManageRoom] = useState('board');
  const [rooms, setRooms] = useState([]);
  const [clickedRoom, setClickedRoom] = useState();

  // Funzione che scrolla alla fine dei messaggi
  function scrollToEnd() {
    const container = window.document.getElementById('messages-scroll');
    if (container) {
      container.scrollTop = container.scrollHeight;
    }
  }

  function inviaNotificaLettura(room) {
    // Ultimo evento lettura
    const eventsLength = room.getLiveTimeline().getEvents().length;
    // Invio ricevuta di lettura
    setTimeout(() => {
      client?.sendReadReceipt(
        room.getLiveTimeline().getEvents()[eventsLength - 1],
      );
    }, 1000);
  }

  // Funzione richiamata alla selezione della room
  function onChangeRoom(roomSelected) {
    setClickedRoom(null);
    setTimeout(() => {
      setClickedRoom(roomSelected);
      scrollToEnd();
      inviaNotificaLettura(roomSelected);
    }, 300);
  }

  function hideNewRoomModal() {
    setShowNewRoomModal(false);
  }

  function openNewRoomModal() {
    setShowNewRoomModal(true);
  }

  useEffect(() => setBoardOrManageRoom('board'), [clickedRoom]);

  // Funziona che restituisce la lisya delle room
  async function getRoomsList() {
    const tsOfNewestEvent = function tsOfNewestEvent(room) {
      if (room.timeline.length) {
        return room.timeline[room.timeline.length - 1].getTs();
      }
      return Number.MAX_SAFE_INTEGER;
    };

    const joinedRooms = (await client.getJoinedRooms()).joined_rooms;
    const stanze = [];
    joinedRooms.forEach((id) => {
      stanze.push(client?.getRoom(id));
    });

    setRooms(stanze.sort((a, b) => tsOfNewestEvent(b) - tsOfNewestEvent(a)) || []);
  }
  function clickShowBoardOrManageRoom() {
    setBoardOrManageRoom(boardOrManageRoom === 'board' ? 'manageRoom' : 'board');
  }

  // Funzione che invia i messaggi di testo
  function sendTextMessage(message) {
    const content = {
      body: message.trim(),
      msgtype: 'm.text',
    };
    if (content.body !== '') client?.sendEvent(clickedRoom.roomId, 'm.room.message', content);
  }

  function sendFile(file) {
    client.uploadContent(file)
      .then((res) => {
        const content = {
          body: file.name,
          info: { size: file.size, mimetype: file.type },
          msgtype: file.type.includes('image') ? 'm.image' : 'm.file',
          url: res,
        };
        client?.sendEvent(clickedRoom.roomId, 'm.room.message', content);
      });
  }

  // Funzione che elimina i messaggi inviati
  function deleteMessage(eventId) {
    client?.redactEvent(clickedRoom.roomId, eventId);
  }

  // Funzione che crea una nuova stanza
  function addRoom(room) {
    client?.createRoom({ name: room.name, topic: room.argument });
    hideNewRoomModal();
  }

  // Funzione che permette l'uscita da una stanza
  function exitFromRoom() {
    client?.leave(clickedRoom.roomId)
      .then(() => {
        setTimeout(() => {
          setClickedRoom(null);
        }, 500);
        setContext(() => ({
          ...context, openConferma: true, testo: 'Sei uscito correttamente dalla stanza',
        }));
      })
      .catch(() => {
        setContext(() => ({
          ...context, open: true, testoErrore: 'Si è verificato un problema.', statusCode: 400,
        }));
      });
  }

  // Funzione che fa uscire un utente dalla stanza
  function kickUser(userId) {
    client?.kick(clickedRoom.roomId, userId)
      .then(() => {
        setContext(() => ({
          ...context, openConferma: true, testo: 'Utente eliminato correttamente',
        }));
        removeItem('utenteDaEliminare');
      })
      .catch(() => {
        setContext(() => ({
          ...context, open: true, testoErrore: 'Si è verificato un problema.', statusCode: 400,
        }));
      });
  }

  // Funzione che permette l'eliminazione della stanza
  function deleteRoom() {
    chatService.eliminaStanza(clickedRoom.roomId, encodeURIComponent(client?.getHomeserverUrl()))
      .then(() => {
        setTimeout(() => {
          setClickedRoom(null);
          getRoomsList();
        }, 500);
        setContext(() => ({
          ...context, openConferma: true, testo: 'Stanza eliminata correttamente',
        }));
      })
      .catch(() => {
        setContext(() => ({
          ...context, open: true, testoErrore: 'Si è verificato un problema.', statusCode: 400,
        }));
      });
  }

  useEffect(() => {
    getRoomsList();
    client?.on('event', () => {
      getRoomsList();
      setTimeout(() => {
        scrollToEnd();
      }, 200);
      // eslint-disable-next-line no-new
      // new Notification('Hey');
    });
  }, []);

  return (
    <section id="chat">
      <Title
        title="Chat"
        subtitle="Servizio chat per la comunicazione istantanea."
      />
      <Row>
        <Col lg={3}>
          <RoomsList
            onClickRoom={onChangeRoom}
            clickedRoom={clickedRoom}
            rooms={rooms}
            onOpenNewRoomModal={openNewRoomModal}
          />
        </Col>
        { clickedRoom ? (
          <Col lg={9}>
            <Card className={`d-flex flex-row p-3 justify-content-between ${styles.infoOptionCard}`}>
              <div>
                <strong>{ clickedRoom.name }</strong>
                {' '}
                {clickedRoom.currentState.getStateEvents('m.room.topic', '')?.getContent().topic}
              </div>
              <span>
                <button type="button" className="bg-transparent border-0" onClick={clickShowBoardOrManageRoom}>
                  <FontAwesomeIcon
                    aria-hidden="true"
                    icon={['fas', boardOrManageRoom === 'board' ? 'info-circle' : 'times-circle']}
                    size="1x"
                    title={boardOrManageRoom === 'board' ? 'Info stanza' : 'Torna indietro'}
                    id={boardOrManageRoom === 'board' ? 'info-stanza-icon' : 'torna-indietro-icon'}
                    style={{ color: themeColors.dark }}
                  />
                  { boardOrManageRoom === 'board' ? ' informazioni stanza' : ' torna indietro' }
                </button>
              </span>
            </Card>
            {
           {
             board:
            <>
              <Board
                onDeleteMessage={deleteMessage}
                room={clickedRoom}
                getRoomsList={getRoomsList}
              />
              <TextBox sendTextMessage={sendTextMessage} room={clickedRoom} sendFile={sendFile} />
            </>,
             manageRoom: <ManageRoom
               onDeleteRoom={deleteRoom}
               room={clickedRoom}
               exitFromRoom={exitFromRoom}
               kickUser={kickUser}
             />,
           }[boardOrManageRoom]
          }
          </Col>
        ) : null }
      </Row>
      {showNewRoomModal ? (
        <NewRoomModal
          show={showNewRoomModal}
          onHide={hideNewRoomModal}
          onAddRoom={addRoom}
        />
      ) : null}
    </section>
  );
}

export default Chat;
