import React, { useContext, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import BreadcrumbComponent from '../../components/Breadcrumb';
import CardBasic from '../../components/CardBasic';
import { Context } from '../../utils/Context';
import newsService from '../../services/newsService';
import { formatoData } from '../../utils/formatData';
import noImage from '../../images/noImage.png';
import Pagination from '../../components/News/Pagination';
import FiltriRicercaNews from '../../components/News/FiltriRicerca';
import EmptyState from '../../components/EmptyState';

const newsUrl = process.env.REACT_APP_NEWS_FAQ_URL;

function Approfondimenti() {
  const [context, setContext] = useContext(Context);
  const [listaNews, setListaNews] = useState([]);
  const { pathname } = useLocation();
  const [pagination, setPagination] = useState({
    page: 1,
    sizePerPage: 5,
    totalSize: 0,
  });
  const [filtri, setFiltri] = useState({
    titolo: '',
    contenuto: '',
    profonditaStorica: false,
  });

  // Function collegata al servizio per ottenere la lista delle News
  async function getListaNewsPubbliche(offset, limit) {
    window.scrollTo({ behavior: 'smooth', top: '0px' });
    try {
      const response = await newsService.getListaNewsPubbliche(filtri, offset, limit);
      setPagination((prevPag) => ({
        ...prevPag,
        page: (limit + offset) % limit,
        sizePerPage: limit,
        totalSize: response.totalRowCount,
      }));
      setListaNews(response?.listaNews || []);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // useEffect
  useEffect(() => {
    getListaNewsPubbliche(0, 6);
  }, []);

  // fucntion per la gestione dei filtri
  function onChangeFiltri(e, type) {
    const { value, id, checked } = e.target;
    setFiltri({
      ...filtri,
      [id]: type === 'checkbox' ? checked : value,
    });
  }

  return (
    <Container className="justify-content-center col-md-10">
      <Row xs={12}>
        <BreadcrumbComponent listItem={[{ name: 'Approfondimenti', pathname: '/approfondimenti', active: true }]} />
      </Row>
      <Row className="justify-content-center">
        <Col className="my-3">
          {/* <h1>Notizie</h1> */}
        </Col>
        <Col xs={12} className="mb-3">
          <FiltriRicercaNews
            filtri={filtri}
            getListaNews={getListaNewsPubbliche}
            onChange={onChangeFiltri}
          />
        </Col>
        {listaNews.length > 0 ? (
          <>
            <Row xs={1} md={3} className="p-0">
              {listaNews.map((news) => (
                <Col key={news._id} className="mb-3">
                  <CardBasic
                    href={`${pathname}/${news._id}`}
                    image={news.pathMinioImmagine ? `${newsUrl}/news-esterne/immagine/${encodeURIComponent(news.pathMinioImmagine)}` : noImage}
                    badge={news.inEvidenza}
                    subtitle={formatoData(news.dataUltimaModifica ?? news.dataCreazione)}
                    title={news.titolo}
                  />
                </Col>
              ))}
            </Row>
            {pagination.totalSize !== 0 && (
            <Pagination
              functionShow={getListaNewsPubbliche}
              pagination={pagination}
              customSizeButton
            />
            )}
          </>
        ) : (
          <EmptyState marginTop="3rem" subtitle="Nessuna news presente" />
        )}
      </Row>
    </Container>
  );
}

export default Approfondimenti;
