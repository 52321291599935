/* eslint-disable no-unused-expressions */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button } from 'react-bootstrap';

const accreditamentoUrl = process.env.REACT_APP_ACCREDITAMENTO_URL;

function UploadComponent({
  deleteFile, richiestaIdentita, inputRef, uploadPdf, isDetail,
}) {
  const handleUpload = () => {
    inputRef.current?.click();
  };

  const handleDisplayFileDetails = () => {
    uploadPdf(inputRef?.current?.files[0]?.name);
  };

  return (
    <div>
      <div className="d-flex justify-content-center">
        <input
          ref={inputRef}
          onChange={handleDisplayFileDetails}
          className="d-none"
          type="file"
          accept="application/pdf, application/pkcs7-mime"
        />
        {!isDetail && (
        <button
          type="button"
          onClick={handleUpload}
          disabled={!!richiestaIdentita.codi_pdf_minio}
          className={`mb-3 btn btn-${
            richiestaIdentita.codi_pdf_minio ? 'primary' : 'primary'
          } ${richiestaIdentita.codi_pdf_minio ? 'text-white' : ''}`}
        >
          {richiestaIdentita.codi_pdf_minio || 'Carica atto di nomina firmato'}
        </button>
        )}
        {isDetail && richiestaIdentita.codi_pdf_minio && (
        <button
          type="button"
          onClick={handleUpload}
          disabled={!!richiestaIdentita.codi_pdf_minio}
          className={`mb-3 btn btn-${
            richiestaIdentita.codi_pdf_minio ? 'primary' : 'primary'
          } ${richiestaIdentita.codi_pdf_minio ? 'text-white' : ''}`}
        >
          {richiestaIdentita.codi_pdf_minio || 'Carica atto di nomina firmato'}
        </button>
        )}
      </div>
      {richiestaIdentita.codi_pdf_minio && (
        <div className="d-flex justify-content-center">
          {!isDetail && (
          <Button variant="danger" className="me-3 mb-3" onClick={async () => { await deleteFile(); }}>
            <FontAwesomeIcon aria-hidden="true" icon={['fa', 'trash']} />
            {' '}
            Elimina
          </Button>
          )}
          <Button variant="success" className="mb-3">
            <a target="_blank" href={`${accreditamentoUrl}/gestione-richiesta-identita-utente/${richiestaIdentita.sequ_k_richiesta}/download-file`} style={{ color: 'white', textDecoration: 'none' }} rel="noreferrer">
              <FontAwesomeIcon aria-hidden="true" icon={['fa', 'download']} />
              {' '}
              Download
            </a>
          </Button>
        </div>
      )}
    </div>
  );
}

export default UploadComponent;
