import React, { useContext, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import TitlePage from '../../../components/GestisciUtenti/TitoloPagina';
import SistemaProfessionaleService from '../../../services/sistemaProfessionaleService';
import { Context } from '../../../utils/Context';
import checkStorage from '../../../utils/checkSessionStorage';
import { getItem, setItem } from '../../../utils/storage';
import fieldsAmbitiRuolo from './fieldsTable';
import Table from '../../../components/Shared/Table';
import ModaleCentroVerticale from '../../../components/modali/ModaleCentroVerticale';
import FormAmbitoRuolo from './FormAmbitoRuolo';
import ModaleConferma from '../../../components/modali/ModaleConferma';
import FiltriAmbitiRuolo from './filtriAmbitiRuolo';
import EmptyState from '../../../components/EmptyState';
import { controlla } from '../../../utils/verificaPermessi';

function AmbitiRuolo() {
  const [context, setContext] = useContext(Context);
  const [ambitiRuoloList, setAmbitiRuoloList] = useState([]);
  const [famiglieProfessionaliList, setFamiglieProfessionaliList] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [modalShowDelete, setModalShowDelete] = useState(false);
  const [ambitoRuoloSelected, setAmbitoRuoloSelected] = useState();
  const [modalShowModify, setModalShowModify] = useState(false);
  const [modalShowView, setModalShowView] = useState(false);
  const [errors, setErrors] = useState({});
  const { sequ_k_comparto } = getItem('compartoSelected') ? JSON.parse(getItem('compartoSelected')) : 0;
  const { idAmministrazione } = JSON.parse(getItem('identita'));
  const [stateAmbitoRuolo, setStateAmbitoRuolo] = useState({
    desc_titolo: '',
    desc_descrizione: '',
    fk_sequ_comparto: sequ_k_comparto,
    fk_sequ_famiglia_professionale: 0,
  });

  const [filtri, setFiltri] = useState({});
  const [pagination, setPagination] = useState({
    page: 1,
    sizePerPage: 10,
    totalSize: 0,
  });

  // Validazione per la creazione di un ambito di ruolo
  const validateAmbitoRuolo = (stato, error) => {
    const temp = { ...error };
    temp.fk_sequ_famiglia_professionale = stato.fk_sequ_famiglia_professionale === 0
    || !stato.fk_sequ_famiglia_professionale;
    setErrors((prevState) => ({
      ...prevState,
      ...temp,
    }));
    return Object.values(temp).every((x) => x === false);
  };

  // Function collegata al servizio per ottenere la lista degli ambiti di ruolo
  async function getAmbitiRuolo(offset, limit, page, filters) {
    try {
      const filterSession = filters || filtri;
      const response = await SistemaProfessionaleService
        .getAmbitiRuolo(filterSession, offset, limit);
      setAmbitiRuoloList(response.rows);
      setPagination((prevPag) => ({
        ...prevPag,
        page,
        sizePerPage: limit,
        totalSize: response.totalRowCount,
      }));
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // Function collegata al servizio per ottenere la lista delle famiglie professionali
  async function getFamiglieProfessionali(offset, limit, filters) {
    try {
      const response = await SistemaProfessionaleService
        .getFamiglieProfessionali(filters, offset, limit);
      setFamiglieProfessionaliList(response.rows);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // function attivata al cambio pagina della tabella
  const handleTableChange = (type, {
    page, sizePerPage, sortField, sortOrder,
  }) => {
    const newFilters = {
      ...filtri,
      fk_sequ_comparto: sequ_k_comparto,
      sortField: type === 'sort' ? sortField : 'desc_codice',
      sortOrder: type === 'sort' ? sortOrder : 'asc',
    };
    let currentIndex;
    if (page !== 0) {
      currentIndex = (page - 1) * sizePerPage;
    }
    getAmbitiRuolo(
      currentIndex,
      sizePerPage,
      page,
      newFilters,
    );
  };

  // useEffect
  useEffect(() => {
    let offset = 0;
    const storage = checkStorage(filtri, setFiltri, setPagination);
    if (storage.page > 1) {
      handleTableChange(null, { page: storage.page, sizePerPage: pagination.sizePerPage });
      offset = (storage.page - 1) * pagination.sizePerPage;
    }
    getAmbitiRuolo(
      offset,
      10,
      storage.page,
      { ...storage.filtri, fk_sequ_comparto: sequ_k_comparto },
    );
  }, []);

  // useEffect per settare nello storage la pagina corrente
  useEffect(() => {
    setItem('page', pagination.page);
  }, [pagination.page]);

  // useEffect per caricare i dati presenti nella tabella + la lista delle famiglie professionali
  useEffect(() => {
    setFiltri({ ...filtri, fk_sequ_comparto: sequ_k_comparto });
    getAmbitiRuolo(0, 10, 1, { ...filtri, fk_sequ_comparto: sequ_k_comparto });
    setStateAmbitoRuolo({ ...stateAmbitoRuolo, fk_sequ_comparto: sequ_k_comparto });
    // IMPOSTATO LIMIT 1000
    getFamiglieProfessionali(0, 1000, {
      ...filtri,
      fk_sequ_comparto: sequ_k_comparto,
      banca_dati_unica: !idAmministrazione ? true : undefined,
    });
  }, [sequ_k_comparto]);

  // Function per modificare lo stato dell'ambito ruolo che l'utente sta modificando
  const onChange = (e, config) => {
    if (config) {
      if (e?.sequ_k_comparto) {
        setStateAmbitoRuolo({
          ...stateAmbitoRuolo, [config.name]: e?.sequ_k_comparto,
        });
      } else {
        const newState = { ...stateAmbitoRuolo, [config.name]: e?.sequ_k_famiglia_professionale };
        setStateAmbitoRuolo(newState);
        validateAmbitoRuolo(newState, errors);
      }
    } else {
      const { name, value } = e.target;
      setStateAmbitoRuolo({ ...stateAmbitoRuolo, [name]: value });
    }
  };

  // function per modificare lo stato che gestisce i filtri
  function onChangeFiltri(e, config) {
    if (config) {
      setFiltri({
        ...filtri, [config.name]: e?.sequ_k_famiglia_professionale,
      });
    } else {
      const { value, name, checked } = e.target;
      if (name === 'banca_dati_unica') {
        filtri[name] = checked;
      } else {
        setFiltri({
          ...filtri,
          [name]: value,
        });
      }
    }
  }

  // Function per effettuare il refresh della pagina
  function refresh() {
    getAmbitiRuolo(0, 10, 1, { ...filtri, fk_sequ_comparto: sequ_k_comparto });
  }

  // Function collegata al servizio di creazione di un nuovo ambito di ruolo
  async function nuovoAmbitoRuolo() {
    if (validateAmbitoRuolo(stateAmbitoRuolo, errors)) {
      SistemaProfessionaleService.nuovoAmbitoRuolo(stateAmbitoRuolo)
        .then((res) => {
          setContext(() => ({
            ...context, openConferma: true, testo: res.message,
          }));
          setModalShow(false);
          refresh();
        })
        .catch((err) => {
          setContext(() => ({
            ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
          }));
        });
    }
  }

  // Function collegata al servizio di eliminazione di un ambito di ruolo
  async function eliminaAmbitoRuolo(idAmbitoRuolo) {
    SistemaProfessionaleService.eliminaAmbitoRuolo(idAmbitoRuolo)
      .then((res) => {
        setContext(() => ({
          ...context, openConferma: true, testo: res.message,
        }));
        setModalShowDelete(false);
        refresh();
      })
      .catch((err) => {
        setContext(() => ({
          ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
        }));
      });
  }

  // Function collegata al servizio di modifica di un ambito di ruolo
  async function modificaAmbitoRuolo(idAmbitoRuolo) {
    if (validateAmbitoRuolo(stateAmbitoRuolo, errors)) {
      SistemaProfessionaleService.modificaAmbitoRuolo(
        idAmbitoRuolo,
        stateAmbitoRuolo,
      )
        .then((res) => {
          setContext(() => ({
            ...context, openConferma: true, testo: res.message,
          }));
          setModalShowModify(false);
          refresh();
        })
        .catch((err) => {
          setContext(() => ({
            ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
          }));
        });
    }
  }

  // Function collegata al servizio di get di un particolare ambito di ruolo
  async function getAmbitoRuoloById(id) {
    try {
      const response = await SistemaProfessionaleService.getAmbitoRuoloById(id);
      setStateAmbitoRuolo({
        desc_titolo: response.desc_titolo,
        desc_descrizione: response.desc_descrizione,
        fk_sequ_comparto: response.fk_sequ_comparto,
        fk_sequ_famiglia_professionale: response.fk_sequ_famiglia_professionale,
        desc_codice: response.desc_codice,
      });
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // Function attivata al click dell'icona di eliminazione
  const onDelete = (ambitoRuolo) => {
    setModalShowDelete(true);
    setAmbitoRuoloSelected(ambitoRuolo);
  };

  // Function attivata al click dell'icona di visualizzazione
  const onView = (ambitoRuolo) => {
    getAmbitoRuoloById(ambitoRuolo.id);
    setAmbitoRuoloSelected(ambitoRuolo);
    setModalShowView(true);
  };

  // Function attivata al click dell'icona modifica
  const onModify = (modify, ambitoRuolo) => {
    getAmbitoRuoloById(ambitoRuolo.id);
    setAmbitoRuoloSelected(ambitoRuolo);
    if (modify) {
      setModalShowModify(true);
    } else {
      setModalShowView(false);
      setModalShowModify(true);
    }
  };

  return (
    <>
      <Container className="p-0">
        <TitlePage
          title="Ambiti di Ruolo"
          showRefresh
          functionRefresh={refresh}
          showAdd={controlla('X44') && sequ_k_comparto}
          functionAdd={() => setModalShow(true)}
        />
        <FiltriAmbitiRuolo
          filtri={filtri}
          setFiltri={setFiltri}
          onChangeFiltri={onChangeFiltri}
          getAmbitiRuolo={getAmbitiRuolo}
          optionsFamigliaProfessionale={famiglieProfessionaliList}
        />
        {ambitiRuoloList.length > 0 ? (
          <Table
            data={ambitiRuoloList}
            fields={fieldsAmbitiRuolo(onDelete, onView, onModify, idAmministrazione)}
            pagination={pagination}
            onTableChange={handleTableChange}
            keyField="ambiti_ruolo_list"
          />
        ) : (
          <EmptyState marginTop="3rem" />
        )}
      </Container>
      <ModaleCentroVerticale
        modalTitle="Crea un nuovo Ambito di Ruolo"
        modalBody={(
          <FormAmbitoRuolo
            stateAmbitoRuolo={stateAmbitoRuolo}
            onChange={onChange}
            optionsFamigliaProfessionale={famiglieProfessionaliList}
            isNew
            errors={errors}
          />
          )}
        buttonType="submit"
        show={modalShow}
        onConfirm={() => nuovoAmbitoRuolo()}
        labelConfirm="Aggiungi"
        onClose={() => setModalShow(false)}
      />
      <ModaleConferma
        labelBottone="Elimina"
        coloreAzione="primary"
        title="Sei sicuro di procedere?"
        body={`L'operazione eliminerà l'ambito di ruolo ${ambitoRuoloSelected?.codice}`}
        show={modalShowDelete}
        handleClose={() => setModalShowDelete(false)}
        handleOk={() => eliminaAmbitoRuolo(ambitoRuoloSelected.id)}
      />
      <ModaleCentroVerticale
        modalTitle="Ambito di Ruolo"
        modalBody={(
          <FormAmbitoRuolo
            stateAmbitoRuolo={stateAmbitoRuolo}
            disabled
            optionsFamigliaProfessionale={famiglieProfessionaliList}
          />
          )}
        buttonType="submit"
        show={modalShowView}
        onConfirm={() => onModify(false, ambitoRuoloSelected)}
        disabledConfirm={(!controlla('X44') || idAmministrazione !== ambitoRuoloSelected?.idAmministrazione)}
        labelConfirm="Modifica"
        onClose={() => setModalShowView(false)}
      />
      <ModaleCentroVerticale
        modalTitle="Modifica Ambito di Ruolo"
        modalBody={(
          <FormAmbitoRuolo
            stateAmbitoRuolo={stateAmbitoRuolo}
            onChange={onChange}
            optionsFamigliaProfessionale={famiglieProfessionaliList}
            errors={errors}
          />
          )}
        buttonType="submit"
        show={modalShowModify}
        onConfirm={() => modificaAmbitoRuolo(ambitoRuoloSelected.id)}
        labelConfirm="Conferma"
        onClose={() => setModalShowModify(false)}
      />
    </>
  );
}

export default AmbitiRuolo;
