/* eslint-disable react/no-array-index-key */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import Select from 'react-select';
import EmptyState from '../../../../../../components/EmptyState';
import themeColors from '../../../../../../utils/contants/theme-colors';

function SelezioneSO({
  options,
  stateSelected,
  setStateSelected,
}) {
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [isLoadingSelect, setIsLoadingSelect] = useState(false);

  // Function per caricare la lista delle opzioni durante il caricamento
  const loadOptions = (input) => {
    if (input.length >= 3) {
      setIsLoadingSelect(true);
      const filtered = options.filter(
        (el) => el.struttura_organizzativa.toLowerCase().includes(input.toLowerCase())
        && !stateSelected.some((el2) => el2.struttura_organizzativa === el.struttura_organizzativa),
      );
      setFilteredOptions(filtered);
      setIsLoadingSelect(false);
    } else {
      setFilteredOptions(
        options.filter(
          (el) => !stateSelected.some(
            (el1) => (el1.struttura_organizzativa) === el.struttura_organizzativa,
          ),
        ),
      );
      setIsLoadingSelect(false);
    }
  };

  // Function per la selezione della struttura all'interno della select
  const onChange = (e, config) => {
    if (config) {
      setStateSelected((prevState) => [
        ...prevState,
        { [config.name]: e?.struttura_organizzativa },
      ]);
      setFilteredOptions((prevOptions) => prevOptions.filter(
        (option) => option.struttura_organizzativa !== e?.struttura_organizzativa,
      ));
    }
  };

  // Function per rimuovere eventuali strutture dalla tabella
  const handleRemoveStruttura = (struttura) => {
    setStateSelected((prevState) => prevState.filter(
      (el) => el.struttura_organizzativa !== struttura.struttura_organizzativa,
    ));
    setFilteredOptions((prevState) => [...prevState, struttura]);
  };

  useEffect(() => {
    loadOptions('');
  }, []);

  return (
    <>
      {console.log('SELEZIONATE', stateSelected)}
      {/* {console.log('TUTTE', options)} */}
      <p>Inserisci le Strutture Organizzative:</p>
      <Select
        id="struttura_organizzativa"
        name="struttura_organizzativa"
        options={filteredOptions}
        onInputChange={(input) => {
          loadOptions(input);
        }}
        onChange={(selectedOption, config) => { onChange(selectedOption, config); }}
        placeholder="Inserisci almeno 3 caratteri"
        isLoading={isLoadingSelect}
        getOptionLabel={(option) => option.struttura_organizzativa}
        getOptionValue={(option) => option.struttura_organizzativa}
        noOptionsMessage={() => 'Nessun risultato trovato.'}
      />
      <br />
      {stateSelected.length > 0 ? (
        <Table bordered>
          <thead>
            <tr>
              <th>Rimuovi</th>
              <th>Nome Struttura Organizzativa</th>
            </tr>
          </thead>
          <tbody>
            {stateSelected.map(
              (str, ind) => (
                <tr key={ind}>
                  <td>
                    <FontAwesomeIcon
                      icon="fa-solid fa-trash-can"
                      size="sm"
                      style={{ color: themeColors.danger }}
                      onClick={() => handleRemoveStruttura(str)}
                    />
                  </td>
                  <td>{str.struttura_organizzativa}</td>
                </tr>
              ),
            )}
          </tbody>
        </Table>
      ) : (
        <EmptyState
          title="Nessuna Struttura Organizzativa selezionata"
          subtitle="Ricerca le strutture ed aggiungile per visionarle."
        />
      )}
    </>
  );
}

export default SelezioneSO;
