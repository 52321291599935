/* eslint-disable jsx-a11y/label-has-associated-control */
import { Editor } from '@tinymce/tinymce-react';
import {
  useContext, useEffect, useRef, useState,
} from 'react';
import {
  Button, Col, Form, Row,
} from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import tiny from '../../../../utils/TinyMCEConfiguration';
import TitlePage from '../../../../components/GestisciUtenti/TitoloPagina';
import history from '../../../../utils/history';
import newsService from '../../../../services/newsService';
import { Context } from '../../../../utils/Context';
import ModaleConferma from '../../../../components/modali/ModaleConferma';

function ModificaNewsEsterna() {
  const [context, setContext] = useContext(Context);
  const { idNews } = useParams();
  const [openModaleConferma, setOpenModaleConferma] = useState(false);
  const buttonCreateDisabled = useRef(true);
  const [nuovaNewsEsterna, setNuovaNewsEsterna] = useState({
    contenuto: '',
    titolo: '',
    inEvidenza: false,
    nomeImmagine: '',
    nomeImmagineEdit: '',
  });

  // Function per la gestione del cambio state Title
  function onChangeTitle(event) {
    buttonCreateDisabled.current = false;
    setNuovaNewsEsterna({ ...nuovaNewsEsterna, titolo: event.target.value });
  }

  // Function per la gestione del cambio state Evidenza
  function onChangeEvidenza(event) {
    buttonCreateDisabled.current = false;
    setNuovaNewsEsterna({ ...nuovaNewsEsterna, inEvidenza: event.target.checked });
  }

  // Function per la gestione dell'EditorChange
  function handleEditorChange(newValue) {
    buttonCreateDisabled.current = false;
    setNuovaNewsEsterna({ ...nuovaNewsEsterna, contenuto: newValue });
  }

  // Function per la gestione dell'Upload di una immagine
  const onUpload = (e) => {
    buttonCreateDisabled.current = false;
    const { id, value, files } = e.target;
    setNuovaNewsEsterna({
      ...nuovaNewsEsterna,
      [id]: value,
      fileCaricato: files[0],
    });
  };

  // Function per gestire la modale di conferma
  function handleStateModaleConferma() {
    setOpenModaleConferma(!openModaleConferma);
  }

  // Function collegata al servizio di get della news pubblica selezionata
  async function getNewsPubblica() {
    const response = await newsService.getNewsPubblicaById(idNews);
    setNuovaNewsEsterna(response);
  }

  // Function collegata al servizio di modifica della news pubblica selezionata
  async function editNewsPubblica() {
    await newsService.editNewsPubblica(idNews, nuovaNewsEsterna)
      .then((res) => {
        setContext(() => ({
          ...context, openConferma: true, testo: res.message || 'Operazione eseguita correttamente',
        }));
        history.goBack();
      })
      .catch((err) => {
        setContext(() => ({
          ...context, open: true, testoErrore: err?.data || 'Errore', statusCode: err?.status,
        }));
      })
      .finally(() => {
        handleStateModaleConferma();
      });
  }

  // useEffect
  useEffect(() => {
    getNewsPubblica();
  }, []);

  return (
    <section id="aggiungi-news">
      <TitlePage
        title="Modifica notizia"
        subtitle="Utilizza l'editor presente per modificare la notizia."
        showIndietro
        functionIndietro={() => history.push('/area-riservata/gestione-news-esterne')}
      />
      <Form.Group className="mb-3" controlId="title">
        <Form.Label>Titolo *</Form.Label>
        <Form.Control value={nuovaNewsEsterna.titolo} onChange={onChangeTitle} />
      </Form.Group>
      <label htmlFor="content-html" className="mb-1">Contenuto *</label>
      <Editor
        id="content-html"
        value={nuovaNewsEsterna.contenuto}
        init={{
          plugins: tiny.plugins,
          toolbar: tiny.toolbar,
        }}
        apiKey={tiny.apiKey}
        onEditorChange={handleEditorChange}
      />
      <Row>
        <Form.Group className="mt-3" as={Col} controlId="nomeImmagine">
          <Form.Label>Immagine</Form.Label>
          <Form.Control value={nuovaNewsEsterna.nomeImmagineEdit} type="file" size="sm" onChange={onUpload} accept="image/jpeg, image/png, image/gif" />
          <p>
            Immagine attualmente scelta:
            {' '}
            <strong>{nuovaNewsEsterna?.nomeImmagine?.split('\\')?.at(-1) || 'nessuna'}</strong>
          </p>
          <Form.Text muted>
            È possibile inserire soltanto file di dimensioni non superiori a 5 MB.
            Sono supportati soltanto i seguenti formati: .jpg, .png, .gif
          </Form.Text>
        </Form.Group>
        <Col>
          <div className="d-flex flex-column align-items-end mt-5">
            <strong>Scegli se inserire la notizia in evidenza</strong>
            {' '}
            <span>
              <label>
                <input type="checkbox" checked={nuovaNewsEsterna.inEvidenza} onChange={onChangeEvidenza} />
                {' '}
                in Evidenza
              </label>
            </span>
          </div>
        </Col>
      </Row>
      <Button
        disabled={buttonCreateDisabled.current}
        onClick={handleStateModaleConferma}
        className="float-end mt-3"
      >
        <FontAwesomeIcon aria-hidden="true" icon="save" />
        {' '}
        SALVA
      </Button>
      <ModaleConferma
        labelBottone="Conferma"
        coloreAzione="primary"
        title="Sei sicuro di procedere?"
        body={`L'operazione modificherà la notizia "${nuovaNewsEsterna.titolo}"`}
        show={openModaleConferma}
        handleClose={handleStateModaleConferma}
        handleOk={editNewsPubblica}
      />
    </section>
  );
}

export default ModificaNewsEsterna;
