import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import styles from './index.module.css';
import avatar from '../../../images/default-avatar.jpg';

function LoginInfo({ user }) {
  return (
    <div>
      <Row xs={1} md={2} lg={2}>
        <Col className="col-lg-auto col-md-auto text-center">
          <img className={`${styles.img}`} alt="Foto profilo" src={avatar} />
          <br />
          {/* <span className="text-center ">
            <FontAwesomeIcon aria-hidden="true" icon="edit" />
            {' '}
            Cambia foto
          </span> */}
        </Col>
        <Col>
          <h2>Il riepilogo delle tue informazioni</h2>
          <Form className="my-3">
            <Form.Group>
              <Form.Label htmlFor="codice-fiscale">Codice fiscale</Form.Label>
              <Form.Control value={user.codi_id_fiscale} disabled id="codice-fiscale" />
            </Form.Group>
          </Form>
        </Col>
      </Row>
    </div>
  );
}

export default LoginInfo;
