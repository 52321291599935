import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import ModaleCentroVerticale from '../../../../../../components/modali/ModaleCentroVerticale';

function ModaleInfo({
  variantButton,
  titleBody,
  body,
}) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <FontAwesomeIcon icon="fa-solid fa-circle-info" onClick={handleShow} size="lg" aria-label="Verifica i modelli disponibili" />
      <ModaleCentroVerticale
        modalTitle={titleBody}
        modalBody={body}
        show={show}
        onClose={handleClose}
        disabledConfirm
      />
    </>
  );
}

export default ModaleInfo;
