import formatCentinaia from '../../../../../utils/formatCentinaia';

const fieldsRanking = () => (
  [
    {
      dataField: 'posizione',
      text: 'Posizione',
      classes: 'column-data',
      footer: '',
      headerStyle: () => ({ width: '10%' }),
      footerStyle: () => ({
        borderBottom: 'hidden', borderLeft: 'hidden', borderRight: 'hidden', textAlign: 'center',
      }),
      formatter: (e, row) => `${row.posizione}°`,
    },
    {
      dataField: 'struttura_organizzativa',
      text: 'SO',
      classes: 'column-data',
      footer: '',
      headerStyle: () => ({ width: '20%' }),
      footerStyle: () => ({
        borderBottom: 'hidden', borderLeft: 'hidden', borderRight: 'hidden', textAlign: 'center',
      }),
    },
    {
      dataField: 'punteggio_so',
      text: 'Punteggio Complessivo di SO',
      classes: 'column-data',
      footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0),
      headerStyle: () => ({ width: '20%' }),
      footerStyle: () => ({
        borderBottom: 'hidden', borderLeft: 'hidden', borderRight: 'hidden', textAlign: 'center',
      }),
    },
    {
      dataField: 'coefficiente',
      text: 'Coefficiente individuazione delle potenziali disponibilità di spesa',
      classes: 'column-data',
      footer: (columnData) => `${columnData.reduce((acc, item) => acc + item, 0)}%`,
      headerStyle: () => ({ width: '30%' }),
      footerStyle: () => ({
        borderBottom: 'hidden', borderLeft: 'hidden', borderRight: 'hidden', textAlign: 'center',
      }),
      formatter: (e, row) => `${row.coefficiente}%`,
    },
    {
      dataField: 'pds',
      text: 'Potenziali disponibilità di spesa (PDS)',
      classes: 'column-data',
      footer: (columnData) => (
        <div className="float-end">
          {formatCentinaia(columnData.reduce((acc, item) => acc + item, 0))}
          €
        </div>
      ),
      headerStyle: () => ({ width: '20%' }),
      footerStyle: () => ({
        borderBottom: 'hidden', borderLeft: 'hidden', borderRight: 'hidden', textAlign: 'center',
      }),
      formatter: (e, row) => (
        <div className="float-end">
          {formatCentinaia(row.pds)}
          €
        </div>
      ),
    },
  ]
);

export default fieldsRanking;
