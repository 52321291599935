import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Col, Container, Form } from 'react-bootstrap';
import { Context } from '../../../../utils/Context';
import SistemaProfessionaleService from '../../../../services/sistemaProfessionaleService';
import TitlePage from '../../../../components/GestisciUtenti/TitoloPagina';
import history from '../../../../utils/history';
import AssessmentService from '../../../../services/assessmentService';
import Table from '../../../../components/Shared/Table';
import fieldsCompetenzeEteroAssessment from './fieldsTable';
import SpinnerBody from '../../../../components/Shared/SpinnerBody';

function EteroAssessmentDettaglio() {
  const [context, setContext] = useContext(Context);
  const { idCicloAssessment, idUser } = useParams();
  const { state } = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [stateEteroAssessment, setStateEteroAssessment] = useState({});
  const [gradingList, setGradingList] = useState([]);

  // function collegata al servizio di get del ciclo di assessment in cui fare l'eterovalutazione
  async function getUserResponsabileById(cicloId, userId) {
    try {
      const response = await AssessmentService.getUserResponsabileById(cicloId, userId);
      setStateEteroAssessment({
        ...response,
        utente_dipendente: {
          ...response.utente_dipendente,
          ...response.utente_dipendente.dettaglio[0],
        },
        utente_responsabile: {
          ...response.utente_responsabile,
          ...response.utente_responsabile.dettaglio[0],
        },
        rel_competenza_assessment: response.d_rel_competenza_assesments.map(
          (competenza) => ({
            fk_sequ_competenza: competenza.fk_sequ_competenza,
            sequ_k_competenza_assesment: competenza.sequ_k_competenza_assesment,
            codi_grading_atteso: competenza.codi_grading_atteso,
            codi_grading_assegnato: competenza.codi_grading_assegnato,
            codi_grading_autovalutazione: competenza.codi_grading_autovalutazione,
            comp_desc_codice: competenza.fk_sequ_competenza_d_competenza.desc_codice,
            comp_desc_descrizione: competenza.fk_sequ_competenza_d_competenza.desc_descrizione,
            comp_tipo_competenza: competenza.fk_sequ_competenza_d_competenza.codi_tipo_competenza,
          }),
        ),
      });
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    } finally {
      setIsLoading(false);
    }
  }

  // function collegata al servizio per ottenere la lista dei grading
  async function getGrading(offset, limit, filters) {
    try {
      const response = await SistemaProfessionaleService.getGrading(filters, offset, limit);
      setGradingList(response.rows);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // useEffect per caricare i dati presenti nella tabella.
  useEffect(() => {
    getGrading(0, 1000, {}); // IMPOSTATO LIMIT 1000
    getUserResponsabileById(idCicloAssessment, idUser);
  }, []);

  // function collegata al servizio di salvataggio dell'eteroassessment
  async function salvaEteroValutazione(cicloId, userId, newState) {
    const { rel_competenza_assessment } = newState;
    AssessmentService.salvaEteroValutazione(
      cicloId,
      userId,
      { rel_competenza_assessment },
    )
      .then(() => {
        setContext(() => ({
          ...context, openConferma: true, testo: 'Salvataggio Eterovalutazione eseguito correttamente',
        }));
        history.goBack();
      })
      .catch((err) => {
        setContext(() => ({
          ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
        }));
      });
  }

  // function collegata al servizio di consolida Etero Valutazione
  async function consolidaEteroValutazione(cicloId, userId, newState) {
    const { rel_competenza_assessment } = newState;
    AssessmentService.salvaEteroValutazione(
      cicloId,
      userId,
      { rel_competenza_assessment },
    )
      .then(() => {
        AssessmentService.consolidaEteroValutazione(cicloId, userId)
          .then(() => {
            setContext(() => ({
              ...context, openConferma: true, testo: 'Autovalutazione eseguita correttamente',
            }));
            history.goBack();
          })
          .catch((err) => {
            setContext(() => ({
              ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
            }));
          });
      })
      .catch((err) => {
        setContext(() => ({
          ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
        }));
      });
  }

  // function collegata al servizio di riapri dell'eteroassessment
  async function riapriEteroValutazione(cicloId, userId) {
    AssessmentService.riapriEteroValutazione(cicloId, userId)
      .then((res) => {
        setContext(() => ({
          ...context, openConferma: true, testo: res.message,
        }));
        history.goBack();
      })
      .catch((err) => {
        setContext(() => ({
          ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
        }));
      });
  }

  // function per modificare lo stato del ciclo di assessment con il grading di eterovalutazione
  const onChange = (e, row) => {
    setStateEteroAssessment((prevState) => ({
      ...prevState,
      rel_competenza_assessment: prevState.rel_competenza_assessment.map(
        (el) => {
          if (el.fk_sequ_competenza === row.fk_sequ_competenza) {
            return ({
              ...el,
              codi_grading_assegnato: e.codi_valore,
            });
          } return el;
        },
      ),
    }));
  };

  // Function per controllare la visualizzazione del bottone Consolida Eterovalutazione
  const isShowConsolida = (statoEteroAssessment) => {
    const allGradingSet = !stateEteroAssessment?.rel_competenza_assessment?.some(
      (el) => !el.codi_grading_assegnato,
    );
    if (allGradingSet && statoEteroAssessment === 2) return true;
    return false;
  };

  return (
    isLoading
      ? (
        <SpinnerBody />
      )
      : (
        <Container className="p-0">
          <TitlePage
            title={`Etero valutazione - ${state.titleCicloAssessment}`}
            showIndietro
            functionIndietro={() => history.goBack()}
            showSave={stateEteroAssessment.fk_codi_stato === 2}
            functionSave={() => salvaEteroValutazione(
              idCicloAssessment,
              idUser,
              stateEteroAssessment,
            )}
            showConsolida={isShowConsolida(stateEteroAssessment.fk_codi_stato)}
            functionConsolida={() => consolidaEteroValutazione(
              idCicloAssessment,
              idUser,
              stateEteroAssessment,
            )}
            titleConsolida="CONSOLIDA ETEROVALUTAZIONE"
            showGeneral={stateEteroAssessment.fk_codi_stato === 3}
            functionGeneral={() => riapriEteroValutazione(idCicloAssessment, idUser)}
            titleGeneral="RIAPRI ETEROVALUTAZIONE"
            iconGeneral="reply"
            variantGeneral="primary"
          />
          <Form.Group as={Col} lg controlId="etero_assessment">
            <Form.Label>
              Ciclo di Assessment
            </Form.Label>
            <Form.Control
              name="fk_sequ_ciclo_assesment_d_ciclo_assesment.desc_titolo"
              value={stateEteroAssessment?.fk_sequ_ciclo_assesment_d_ciclo_assesment?.desc_titolo}
              disabled
            />
            <Form.Label>
              Stato
            </Form.Label>
            <Form.Control
              name="fk_codi_stato_d_stato_assesment.desc_stato"
              value={stateEteroAssessment?.fk_codi_stato_d_stato_assesment?.desc_stato}
              disabled
            />
            <Form.Label>
              Dipendente
            </Form.Label>
            <Form.Control
              name="utente_dipendente"
              value={`${stateEteroAssessment?.utente_dipendente?.desc_nome} ${stateEteroAssessment?.utente_dipendente?.desc_cognome}`}
              disabled
            />
            <Form.Label>
              Responsabile
            </Form.Label>
            <Form.Control
              name="utente_responsabile"
              value={`${stateEteroAssessment?.utente_responsabile?.desc_nome} ${stateEteroAssessment?.utente_responsabile?.desc_cognome}`}
              disabled
            />
            <Form.Label>
              Profilo di ruolo
            </Form.Label>
            <Form.Control
              name="fk_sequ_profilo_ruolo_d_profilo_ruolo.desc_titolo"
              value={stateEteroAssessment?.fk_sequ_profilo_ruolo_d_profilo_ruolo?.desc_titolo}
              disabled
            />
            {(gradingList.length > 0 && stateEteroAssessment?.rel_competenza_assessment?.length > 0)
        && (
        <>
          <Form.Label>
            Etero Valutazione
          </Form.Label>
          <Table
            data={stateEteroAssessment.rel_competenza_assessment}
            fields={fieldsCompetenzeEteroAssessment(
              stateEteroAssessment.rel_competenza_assessment,
              gradingList,
              onChange,
              stateEteroAssessment.fk_codi_stato,
            )}
            keyField="competenze_list_etero_assessment"
          />
        </>
        )}
          </Form.Group>
        </Container>
      )
  );
}

export default EteroAssessmentDettaglio;
