import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Table from '../../../../../components/Shared/Table';
import fieldsRichiesteFabbisogni from '../fields/fieldsRichiesteFabbisogni';
import AssessmentService from '../../../../../services/assessmentService';
import { Context } from '../../../../../utils/Context';
import { getItem } from '../../../../../utils/storage';
import history from '../../../../../utils/history';

function RichiesteFabbisogniPolicyMaker({
  setStep,
}) {
  const { state } = useLocation();
  const [context, setContext] = useContext(Context);
  const [tableData, setTableData] = useState([]);
  const [isAllCompleted, setIsAllCompleted] = useState(false);
  const { idIdentita } = JSON.parse(getItem('identita'));

  // Function collegata al servizio per ottenere il piano fabbisogno idFabbisogno
  async function getPianoFabbisognoById() {
    try {
      const response = await AssessmentService.getProcessoById(state._id);
      setTableData(response.strutture_organizzative);
      const richieste = response.strutture_organizzative.filter(
        (struttura) => !struttura?.richiesta_inviata,
      );
      if (richieste?.length === 0) {
        setIsAllCompleted(true);
      }
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  const onModify = (row) => {
    setStep(4);
    history.push(
      '/area-riservata/demo-bpm/aggiungi-piano',
      {
        idIdentita, row,
      },
    );
  };

  useEffect(() => {
    getPianoFabbisognoById();
  }, []);

  return (
    <div className="d-flex gap-5 flex-column">
      <div className="d-flex justify-content-end">
        <Button
          className="me-2"
          size="md"
          onClick={() => getPianoFabbisognoById()}
          title="Aggiorna la pagina"
        >
          <FontAwesomeIcon aria-hidden="true" icon="sync-alt" />
          &nbsp;
          AGGIORNA
        </Button>
      </div>
      <Table
        data={tableData}
        fields={fieldsRichiesteFabbisogni(isAllCompleted, onModify)}
        keyField="struttura_organizzativa"
      />
    </div>
  );
}

export default RichiesteFabbisogniPolicyMaker;
