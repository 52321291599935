import React from 'react';
import { Form } from 'react-bootstrap';
import Select from 'react-select';

function CustomSelect({
  id,
  name,
  options,
  placeholder,
  isDisabled = false,
  isClearable = false,
  getOptionLabel,
  getOptionValue,
  value,
  onChange,
  errors = {},
  errorMessage,
}) {
  return (
    <>
      <Select
        id={id}
        name={name}
        options={options}
        placeholder={placeholder}
        isDisabled={isDisabled}
        isClearable={isClearable}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        value={value}
        onChange={onChange}
        styles={{
          control: (styles) => (errors[id]
            ? {
              ...styles, borderColor: 'red', boxShadow: 'none', ':hover': { borderColor: 'red' },
            }
            : styles
          ),
        }}
      />
      {errors[id] && (
      <Form.Control.Feedback className="d-block" type="invalid">
        {errorMessage}
      </Form.Control.Feedback>
      )}
    </>
  );
}

export default CustomSelect;
