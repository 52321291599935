/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
import React, { useContext, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import history from '../../utils/history';
import { setItem } from '../../utils/storage';

import { Context } from '../../utils/Context';
import authProviderService from '../../services/authProviderService';
import api from '../../utils/api';
import { globalVariables } from '../../utils/refreshToken';
import { setAndStartMatrix } from '../../utils/matrix';
import chatService from '../../services/chatService';

function LoginSviluppoModal(props) {
  const [identificativo, setIdentificativo] = useState('');
  const [value, setValue] = useContext(Context);
  const onChange = (e) => {
    setIdentificativo(e.target.value);
  };

  const loginSviluppo = async () => {
    try {
      // if (Regex.cf.test(codiceFiscale)) {
      const response = await authProviderService.login(identificativo);
      if (response) {
        setItem('user', JSON.stringify(response.utente));
        api.defaults.headers.common['X-CSRF-Token'] = response.csrfToken;
        globalVariables.refreshToken = response.refresh_token;
        // const responseMatrix = await chatService.loginMatrix(identificativo);
        // setAndStartMatrix(responseMatrix);
        history.push('/area-riservata/scelta-identita');
      }
      // } else { throw new Error('Inserisci un formato valido'); }
    } catch (err) {
      setValue(() => ({
        ...value, open: true, testoErrore: err?.data?.message, statusCode: err?.status,
      }));
    }
  };

  return (
    <Modal
      role="dialog"
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton closeLabel="Chiudi">
        <Modal.Title id="contained-modal-title-vcenter">
          LOGIN SVILUPPO
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Inserisci il codice identificativo per effettuare l&apos;accesso</h4>
        <Form.Group className="mb-3">
          <Form.Label htmlFor="codice_identificativo">Codice identificativo</Form.Label>
          <Form.Control id="codice_identificativo" type="text" placeholder="Inserisci codice identificativo" value={identificativo} onChange={onChange} />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="grey" onClick={props.onHide}>Chiudi</Button>
        <Button variant="primary" onClick={loginSviluppo}>Accedi</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default LoginSviluppoModal;
