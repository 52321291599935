import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button } from 'react-bootstrap';
import Table from '../../../../../../components/Shared/Table';
import fieldsProfiliRichiesti from '../../fields/fieldsProfiliRichiesti';

function TabellaDirettore({
  setShowModalConfirm,
  tableData,
  ipotesiCopertura,
  onChange,
  openModaleDelete,
  totaleCostiInTable,
  totaleUnitaAllocate,
  totaleUnitaCessazione,
  totaleUnitaRichieste,
  processoState,
  blockTable,
}) {
  return (
    <>
      <Button
        className="me-2 mb-1"
        size="sm"
        onClick={() => setShowModalConfirm(true)}
        title="Conferma Scelta dei Profili"
        disabled={blockTable}
      >
        <FontAwesomeIcon aria-hidden="true" icon="check" />
        &nbsp;
        CONFERMA SCELTA
      </Button>
      <div className="overflow-x-auto">
        <Table
          data={tableData}
          fields={fieldsProfiliRichiesti(
            ipotesiCopertura,
            onChange,
            openModaleDelete,
            tableData,
            totaleCostiInTable,
            totaleUnitaAllocate,
            totaleUnitaCessazione,
            totaleUnitaRichieste,
            processoState,
            blockTable,
          )}
          // pagination={pagination}
          // onTableChange={handleTableChange}
          keyField="desc_codice"
        />
      </div>
    </>
  );
}

export default TabellaDirettore;
