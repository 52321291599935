import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import canaliService from '../../../services/canaliService';
import { fieldsUtenti, selectRows } from './fieldsUtenti';
import Table from '../../Shared/Table';
import FiltriRicercaUtentiChat from '../../Chat/NewPeopleModal/FiltriRicercaUtentiChat';
import EmptyState from '../../EmptyState';

function ModaleRicercaUtenti({
  show,
  handleClose,
  idIdentita,
  listaIdentita,
  onChangeAutorizzazioni,
  indexAutorizzazioneScelta,
}) {
  const [listaUtenti, setListaUtenti] = useState([]);
  const [utenteSelected, setUtenteSelected] = useState({});
  const [filtri, setFiltri] = useState({
    testo: '',
    identita: idIdentita,
  });
  const [pagination, setPagination] = useState({
    page: 1,
    sizePerPage: 5,
    totalSize: 0,
  });

  // Function per gestire i filtri
  function onChangeFiltri(e) {
    const { value, id } = e.target;
    setFiltri({
      ...filtri,
      [id]: value,
    });
  }

  // Function collegata al servizio di get della Lista degli utenti
  async function getListaUtenti(offset, limit, page) {
    const response = await canaliService.getUtentiByCanali(filtri, offset, limit);
    setListaUtenti(response.data);
    setPagination((prevPag) => ({
      ...prevPag,
      page,
      sizePerPage: limit,
      totalSize: response.totalRowCount,
    }));
  }

  // Function per gestire i filtri e l'ordinamento della tabella
  const handleTableChange = (type, { page, sizePerPage }) => {
    let currentIndex;
    if (page !== 0) {
      currentIndex = (page - 1) * sizePerPage;
    }
    getListaUtenti(currentIndex, sizePerPage, page);
  };

  // Function attivata al click di aggiungi nella modale Ricerca Utenti.
  const onAddUtente = (user, index) => {
    onChangeAutorizzazioni('codiceFiscale', user, index);
    handleClose();
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      size="xl"
      role="dialog"
      aria-labelledby="modale-scelta-utente"
    >
      <Modal.Header closeButton closeLabel="Chiudi">
        <Modal.Title>Seleziona l&apos;utente</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FiltriRicercaUtentiChat
          listaIdentitaCanali={listaIdentita}
          getListaUtenti={getListaUtenti}
          onChangeFiltriUtenti={onChangeFiltri}
          notShowSelectIdentita
        />
        {listaUtenti.length > 0 ? (
          <Table
            title="Utenti"
            data={listaUtenti}
            fields={fieldsUtenti}
            pagination={pagination}
            onTableChange={handleTableChange}
            keyField="codi_matrix_user_id"
            selectRow={selectRows(setUtenteSelected)}
          />
        ) : (
          <EmptyState subtitle="Nessun risultato coerente con i criteri di ricerca. Rimuovi i filtri di ricerca o effettua una nuova ricerca." />
        )}
      </Modal.Body>
      <Modal.Footer className="justify-content-between">
        <Button
          variant="danger"
          onClick={handleClose}
        >
          Annulla
        </Button>
        <Button
          variant="primary"
          onClick={() => onAddUtente(utenteSelected, indexAutorizzazioneScelta)}
          disabled={!utenteSelected.target}
        >
          Aggiungi
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModaleRicercaUtenti;
