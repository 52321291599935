import { Button, Col, Container } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getItem } from '../../../utils/storage';
import Table from '../../../components/Shared/Table';
import fieldsCompetenze from './dettaglio/fieldsTable';
import EmptyState from '../../../components/EmptyState';

function FormFamigliaProfessionale({
  stateFamigliaProfessionale,
  onChange,
  disabled = false,
  isNew = false,
  optionsGrading = [],
  setModalShowCtp,
  setModalShowCc,
  onDeleteCompetenza,
  errors,
}) {
  const options = JSON.parse(getItem('comparti'));

  // function per avere la lista delle competenze selezionate in base al tipo
  const getCompetenzeSelectedList = (tipo, state) => {
    if (state.fk_sequ_competenze) {
      return state.fk_sequ_competenze.filter(
        (el) => el.codi_tipo_competenza === tipo,
      );
    } return [];
  };

  // function per avere la lista dei grading associati ad un particolare tipo di competenza
  const getGradingList = (tipo) => {
    if (optionsGrading.length > 0) {
      return optionsGrading.filter(
        (el) => el.codi_tipo_competenza === tipo,
      );
    } return [];
  };

  return (
    <Form.Group as={Col} lg controlId="famiglia-professionale">
      { !isNew && (
        <>
          <Form.Label>
            Codice
          </Form.Label>
          <Form.Control
            name="desc_codice"
            value={stateFamigliaProfessionale.desc_codice}
            onChange={onChange}
            maxLength={100}
            disabled
          />
        </>
      )}
      <Form.Label>
        Titolo
      </Form.Label>
      <Form.Control
        name="desc_titolo"
        value={stateFamigliaProfessionale.desc_titolo}
        placeholder="Inserisci il titolo"
        onChange={onChange}
        maxLength={100}
        required
        disabled={disabled}
        isInvalid={errors?.desc_titolo}
      />
      <Form.Control.Feedback type="invalid">
        *Campo Titolo obbligatorio
      </Form.Control.Feedback>
      <Form.Label>
        Comparto
      </Form.Label>
      <Select
        id="fk_sequ_comparto"
        name="fk_sequ_comparto"
        options={options}
        isDisabled
        placeholder="Scegli comparto"
        getOptionLabel={(option) => option.desc_comparto}
        getOptionValue={(option) => option.sequ_k_comparto}
        value={options?.filter(
          (el) => el.sequ_k_comparto === stateFamigliaProfessionale.fk_sequ_comparto,
        )?.[0]}
        onChange={(selectedOption, config) => { onChange(selectedOption, config); }}
      />
      <Container className="d-flex justify-content-between p-0 mt-3">
        <Form.Label style={{ fontWeight: 'bold' }}>
          Competenze Tecnico Professionali
        </Form.Label>
        <Button
          className="float-left"
          disabled={disabled}
          variant="primary"
          size="sm"
          onClick={() => setModalShowCtp(true)}
        >
          <FontAwesomeIcon className="me-1" aria-hidden="true" icon={['fas', 'plus']} />
          Aggiungi Competenza Tecnico Professionale
        </Button>
      </Container>
      {(getCompetenzeSelectedList('CTP', stateFamigliaProfessionale).length > 0
          && getGradingList('CTP').length > 0
      ) ? (
        <Table
          data={getCompetenzeSelectedList('CTP', stateFamigliaProfessionale)}
          fields={fieldsCompetenze(
            onDeleteCompetenza,
            getGradingList('CTP'),
            onChange,
            getCompetenzeSelectedList('CTP', stateFamigliaProfessionale),
            disabled,
          )}
          keyField="competenze_ctp_list"
        />
        ) : (
          <EmptyState
            marginTop="3rem"
            subtitle="Nessuna competenza tecnico professionale associata al profilo professionale"
          />
        )}
      <Container className="d-flex justify-content-between p-0 mt-3">
        <Form.Label style={{ fontWeight: 'bold' }}>
          Competenze Comportamentali
        </Form.Label>
        <Button
          className="float-left"
          disabled={disabled}
          variant="primary"
          size="sm"
          onClick={() => setModalShowCc(true)}
        >
          <FontAwesomeIcon className="me-1" aria-hidden="true" icon={['fas', 'plus']} />
          Aggiungi Competenza Comportamentale
        </Button>
      </Container>
      {(getCompetenzeSelectedList('CC', stateFamigliaProfessionale).length > 0
          && getGradingList('CC').length > 0
      ) ? (
        <Table
          data={getCompetenzeSelectedList('CC', stateFamigliaProfessionale)}
          fields={fieldsCompetenze(
            onDeleteCompetenza,
            getGradingList('CC'),
            onChange,
            getCompetenzeSelectedList('CC', stateFamigliaProfessionale),
            disabled,
          )}
          keyField="competenze_cc_list"
        />
        ) : (
          <EmptyState
            marginTop="3rem"
            subtitle="Nessuna competenza comportamentale associata al profilo professionale"
          />
        )}
    </Form.Group>
  );
}

export default FormFamigliaProfessionale;
