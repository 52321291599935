import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form } from 'react-bootstrap';
import formatCentinaia from '../../../../../utils/formatCentinaia';

const fieldsProfiliRichiesti = (
  ipotesiCoperturaList,
  onChange,
  openModaleDelete,
  tableData,
  getTotaleCostoRichiesta,
  totaleUnitaAllocate,
  totaleUnitaCessazione,
  totaleUnitaRichieste,
  processoState,
  blockTable,
) => (
  [
    {
      dataField: 'df1',
      isDummyField: true,
      text: '',
      align: 'center',
      style: { verticalAlign: 'middle' },
      footer: '',
      footerStyle: () => ({
        borderBottom: 'hidden', borderLeft: 'hidden', borderRight: 'hidden', textAlign: 'center',
      }),
      headerStyle: () => ({
        width: '9%', borderTop: 'hidden', borderLeft: 'hidden', borderBottom: `${tableData.length === 0 ? 'hidden' : ''}`,
      }),
      formatter: (e, row) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {!blockTable && (
            <FontAwesomeIcon
              aria-hidden="true"
              icon="fa-solid fa-trash-can"
              size="lg"
              title="Elimina Riga"
              style={{ color: '#dc3545' }}
              onClick={(event) => openModaleDelete(event, row)}
            />
          )}
        </div>
      ),
    },
    {
      dataField: 'desc_codice',
      text: 'ID',
      classes: 'column-data',
      align: 'center',
      style: { verticalAlign: 'middle' },
      footer: '',
      footerStyle: () => ({
        borderBottom: 'hidden', borderLeft: 'hidden', borderRight: 'hidden', textAlign: 'center',
      }),
      headerStyle: () => ({ width: '9%' }),
    },
    {
      dataField: 'desc_titolo',
      text: 'Profilo Professionale/di Ruolo',
      classes: 'column-data',
      style: { verticalAlign: 'middle' },
      footer: '',
      footerStyle: () => ({
        borderBottom: 'hidden', borderLeft: 'hidden', borderRight: 'hidden', textAlign: 'center',
      }),
      headerStyle: () => ({ width: '9%' }),
    },
    {
      dataField: 'area_contrattuale',
      text: 'Area CCNL',
      classes: 'column-data',
      style: { verticalAlign: 'middle' },
      footer: '',
      footerStyle: () => ({
        borderBottom: 'hidden', borderLeft: 'hidden', borderRight: 'hidden', textAlign: 'center',
      }),
      headerStyle: () => ({ minWidth: '100px', width: '9%' }),
      formatter: (e, row) => processoState.aree_contrattuali.find(
        (areaContrattuale) => areaContrattuale.id_area === row.area_contrattuale,
      )?.nome_area,
    },
    {
      dataField: 'costo_annuale',
      text: 'Costo annuale',
      classes: 'column-data',
      align: 'center',
      style: { verticalAlign: 'middle' },
      footer: '',
      footerStyle: () => ({
        borderBottom: 'hidden', borderLeft: 'hidden', borderRight: 'hidden', textAlign: 'center',
      }),
      headerStyle: () => ({ width: '9%' }),
      formatter: (e, row) => (
        <div className="float-end">
          {formatCentinaia(row.costo_annuale)}
          €
        </div>
      ),
    },
    {
      dataField: 'unita_allocate',
      text: 'Unità di personale allocate presso la struttura',
      classes: 'column-data',
      align: 'center',
      style: { verticalAlign: 'middle' },
      footer: totaleUnitaAllocate.toString(),
      footerStyle: () => ({
        borderBottom: 'hidden', borderLeft: 'hidden', borderRight: 'hidden', textAlign: 'center',
      }),
      headerStyle: () => ({ width: '9%' }),
      formatter: (e, row) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Form.Control
            placeholder={!blockTable && 'Inserisci valore'}
            className="border-custom"
            onBlur={(event) => onChange(event, row)}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                event.target.blur();
              }
            }}
            name="unita_allocate"
            type="number"
            min={0}
            defaultValue={tableData.filter(
              (el) => el.desc_codice === row.desc_codice,
            )[0].unita_allocate}
            disabled={blockTable}
          />
        </div>
      ),
    },
    {
      dataField: 'unita_cessazione',
      text: 'Unità in cessazione (periodo di riferimento)',
      classes: 'column-data',
      style: { verticalAlign: 'middle' },
      footer: totaleUnitaCessazione.toString(),
      footerStyle: () => ({
        borderBottom: 'hidden', borderLeft: 'hidden', borderRight: 'hidden', textAlign: 'center',
      }),
      headerStyle: () => ({ width: '9%' }),
      formatter: (e, row) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Form.Control
            placeholder={!blockTable && 'Inserisci valore'}
            className="border-custom"
            onBlur={(event) => onChange(event, row)}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                event.target.blur();
              }
            }}
            name="unita_cessazione"
            type="number"
            min={0}
            defaultValue={tableData.filter(
              (el) => el.desc_codice === row.desc_codice,
            )[0].unita_cessazione}
            disabled={blockTable}
          />
        </div>
      ),
    },
    {
      dataField: 'unita_richieste',
      text: 'Unità richieste',
      classes: 'column-data',
      style: { verticalAlign: 'middle' },
      footer: totaleUnitaRichieste.toString(),
      footerStyle: () => ({
        borderBottom: 'hidden', borderLeft: 'hidden', borderRight: 'hidden', textAlign: 'center',
      }),
      headerStyle: () => ({ minWidth: '100px' }),
      formatter: (e, row) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Form.Control
            placeholder={!blockTable && 'Inserisci valore'}
            className="border-custom"
            onBlur={(event) => onChange(event, row)}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                event.target.blur();
              }
            }}
            name="unita_richieste"
            type="number"
            min="0"
            defaultValue={tableData.filter(
              (el) => el.desc_codice === row.desc_codice,
            )[0].unita_richieste}
            disabled={blockTable}
          />
        </div>
      ),
    },
    {
      dataField: 'ipotesi_copertura',
      text: 'Possibili ipotesi di copertura',
      classes: 'column-data',
      style: { verticalAlign: 'middle' },
      footer: '',
      footerStyle: () => ({
        borderBottom: 'hidden', borderLeft: 'hidden', borderRight: 'hidden', textAlign: 'center',
      }),
      headerStyle: () => ({ minWidth: '200px', width: '9%' }),
      formatter: (e, row) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Form.Select
            placeholder={!blockTable && 'Inserisci valore'}
            className="border-custom"
            onChange={(event) => onChange(event, row)}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                event.target.blur();
              }
            }}
            name="ipotesi_copertura"
            defaultValue={tableData.filter(
              (el) => el.desc_codice === row.desc_codice,
            )[0].ipotesi_copertura}
            disabled={blockTable}
            value={tableData.find(
              (el) => el.desc_codice === row.desc_codice,
            ).ipotesi_copertura}
          >
            <option value=" ">Seleziona ipotesi</option>
            {ipotesiCoperturaList.map((el) => (
              <option value={el}>{el}</option>
            ))}
          </Form.Select>
        </div>
      ),
    },
    {
      dataField: 'motivazioni_richiesta',
      text: 'Motivazioni della richiesta',
      classes: 'column-data',
      footer: '',
      footerStyle: () => ({
        borderBottom: 'hidden', borderLeft: 'hidden', borderRight: 'hidden', textAlign: 'center',
      }),
      headerStyle: () => ({ width: '9%' }),
      formatter: (e, row) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Form.Control
            placeholder={!blockTable && 'Inserisci motivazione'}
            className="border-custom"
            as="textarea"
            name="motivazioni_richiesta"
            onBlur={(event) => onChange(event, row)}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                event.target.blur();
              }
            }}
            style={{ height: '120px', minWidth: '240px' }}
            defaultValue={tableData.filter(
              (el) => el.desc_codice === row.desc_codice,
            )[0].motivazioni_richiesta}
            disabled={blockTable}
          />
        </div>

      ),
    },
    {
      dataField: 'costo_profili_richiesti',
      text: 'Costo dei profili richiesti',
      classes: 'column-data',
      align: 'center',
      style: { verticalAlign: 'middle' },
      footer: `${formatCentinaia(getTotaleCostoRichiesta())}€`,
      footerStyle: () => ({
        borderBottom: 'hidden', borderLeft: 'hidden', borderRight: 'hidden', textAlign: 'center',
      }),
      headerStyle: () => ({ width: '9%' }),
      formatter: (e, row) => `${
        formatCentinaia(row.ipotesi_copertura === 'Meccanismi di progressione di carriera interni'
          ? parseInt(row.unita_richieste * row.differenza, 10)
          : parseInt(row.unita_richieste * row.costo_annuale, 10))
      }€`,
    },
  ]
);

export default fieldsProfiliRichiesti;
