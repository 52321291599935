import { Form } from 'react-bootstrap';

const fieldsCriteri = (
  onChange,
  modello,
) => (
  [
    {
      dataField: 'criterio',
      text: 'Criterio',
      classes: 'column-data',
      headerStyle: () => ({ width: '80%' }),
    },
    {
      dataField: 'valore_percentuale',
      text: 'Percentuale %',
      classes: 'column-data',
      headerStyle: () => ({ width: '20%' }),
      formatter: (e, row) => (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Form.Control
            placeholder="Inserisci valore"
            className="border-custom"
            size="sm"
            type="number"
            min="0"
            name="valore_percentuale"
            value={modello.find(
              (crt) => crt.sequ_k_criterio === row.sequ_k_criterio,
            )?.valore_percentuale}
            onChange={(event) => onChange(event, row)}
            autoFocus
          />
          %
        </div>
      ),
    },
  ]);

export default fieldsCriteri;
