const CTPFields = () => (
  [
    {
      dataField: 'Codice',
      text: 'Codice',
      classes: 'column-data',
      headerStyle: () => ({ width: '10%' }),
    },
    {
      dataField: 'Competenza',
      text: 'Competenza Tecnico Professionale',
      classes: 'column-data',
      headerStyle: () => ({ width: '20%' }),
    },
    {
      dataField: 'Descrizione',
      text: 'Descrizione',
      classes: 'column-data',
      headerStyle: () => ({ width: '50%' }),
    },
    {
      dataField: 'Area',
      text: 'Area',
      classes: 'column-data',
      headerStyle: () => ({ width: '20%' }),
    },
  ]
);

export default CTPFields;
