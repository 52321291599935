import passiAggiungi from '../passiAggiungi';
import themeColors from '../../../../../utils/contants/theme-colors';

function TimelineBpm({ step }) {
  // Gestione della fase "Scelte di copertura" che è stata modificata
  const newStep = step === 4 ? 3 : step;
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <span style={{ textAlign: 'center' }}>
        {passiAggiungi.map((el, ind) => (
          <p
            key={ind}
            style={{
              color: themeColors.primary,
              display: 'inline',
              marginRight: '5px',
              fontWeight: newStep === ind ? 'bold' : 'lighter',
            }}
          >
            {el}
            {ind < passiAggiungi.length - 1 && (
            <span
              style={{
                color: themeColors.primary,
                fontWeight: 'lighter',
              }}
            >
              {' '}
              {' '}
              /
            </span>
            )}
          </p>
        ))}
      </span>
    </div>
  );
}

export default TimelineBpm;
