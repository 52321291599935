import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const profiliRuoloFields = () => (
  [
    {
      dataField: 'Codice',
      text: 'Codice',
      classes: 'column-data',
      headerStyle: () => ({ width: '10%' }),
    },
    {
      dataField: 'Profilo di Ruolo',
      text: 'Profilo di Ruolo',
      classes: 'column-data',
      headerStyle: () => ({ width: '24%' }),
    },
    {
      dataField: 'Ambito di Ruolo',
      text: 'Ambito di Ruolo',
      classes: 'column-data',
      headerStyle: () => ({ width: '24%' }),
    },
    {
      dataField: 'Famiglia Professionale',
      text: 'Famiglia Professionale',
      classes: 'column-data',
      headerStyle: () => ({ width: '16%' }),
    },
    {
      dataField: 'Comparto',
      text: 'Comparto',
      classes: 'column-data',
      headerStyle: () => ({ width: '10%' }),
    },
    {
      dataField: 'Banca Dati Unica',
      text: 'Banca Dati Unica',
      classes: 'column-data',
      headerStyle: () => ({ width: '16%' }),
      formatter: (e, row) => (
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          {row['Banca Dati Unica'] === 'SI' && (
            <FontAwesomeIcon
              aria-hidden="true"
              icon={['fas', 'check']}
              size="sm"
            />
          )}
        </div>
      ),
    },
  ]
);

export default profiliRuoloFields;
