import React from 'react';
import { Container, Form } from 'react-bootstrap';
import TitlePage from '../../../../../components/GestisciUtenti/TitoloPagina';
import Table from '../../../../../components/Shared/Table';
import fieldsDemoCicliAssessment from './fieldsTable';
import demoElencoAssessment from '../../data/demoElencoAssessment';
import history from '../../../../../utils/history';
import EmptyState from '../../../../../components/EmptyState';

function DemoDipendentiAssessment() {
  const pagination = {
    page: 1,
    sizePerPage: 10,
    totalSize: 0,
  };

  const handleTableChange = () => {
  };

  // Funzione attivata al click dell'icona di visualizzazione
  const onView = (desc_stato) => {
    if (desc_stato === 'In auto-valutazione') {
      history.push('/area-riservata/demo-assessment/auto-assessment/');
    }
    if (desc_stato === 'In etero-valutazione') {
      history.push('/area-riservata/demo-assessment/etero-assessment');
    }
    if (desc_stato === 'Completo') {
      history.push('/area-riservata/demo-assessment/assessment-completo');
    }
  };

  return (
    <Container className="p-0">
      <TitlePage
        title="Elenco Assessment"
        showRefresh
        showIndietro
        functionIndietro={() => { history.goBack(); }}
      />
      <Form.Label style={{ fontWeight: 'bold' }}>
        Dipendenti con Assessment
      </Form.Label>
      <Table
        data={demoElencoAssessment}
        fields={fieldsDemoCicliAssessment(onView)}
        pagination={pagination}
        onTableChange={handleTableChange}
        keyField="cicli_assessment_list"
      />
      <Form.Label style={{ fontWeight: 'bold' }}>
        Dipendenti senza Assessment
      </Form.Label>
      <EmptyState
        title=""
        subtitle="Nessun dipendente deve iniziare il ciclo di assessment"
        marginTop="3rem"
      />
    </Container>
  );
}

export default DemoDipendentiAssessment;
