import React from 'react';
import {
  Badge, Button, ButtonGroup, Card,
} from 'react-bootstrap';
import './index.css';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatoData } from '../../utils/formatData';

function CardBasic({
  id,
  image,
  subtitle,
  badge,
  title,
  text,
  href,
  autore,
  dataCreazione,
  showButtons = false,
  functionEdit,
  functionDelete,
  isModify = false,
}) {
  return (
    <Card className="cardDiv">
      {image && (
        <Card.Img variant="top" src={image} alt="Immagine notizia" />
      )}
      <Card.Body>
        <div className="h-100 d-flex flex-column justify-content-between">
          <div>
            <Card.Subtitle className="cardSubtitle d-flex align-items-center justify-content-between mb-2 text-muted">
              {isModify ? (
                <ButtonGroup className="float-end" aria-label={`Azioni disponibili per la news ${title}`}>
                  {showButtons && (
                  <>
                    <Button
                      className="me-2"
                      onClick={() => functionEdit(id)}
                      title={`Modifica la news ${title}`}
                    >
                      <FontAwesomeIcon aria-hidden="true" icon="edit" />
                    </Button>
                    <Button
                      variant="danger"
                      onClick={() => functionDelete(id)}
                      title={`Elimina la news ${title}`}
                    >
                      <FontAwesomeIcon aria-hidden="true" icon="trash" />
                    </Button>
                  </>
                  )}
                </ButtonGroup>
              ) : subtitle}
              {badge && (
              <Badge bg="primary">In evidenza</Badge>
              )}
            </Card.Subtitle>
            <Card.Title className="cardTitle">{title}</Card.Title>
            {text && (
            <Card.Text className="cardText">
              {text}
            </Card.Text>
            )}
          </div>
          <div>
            <Link className="cardLink" to={href}>
              <div className="d-flex justify-content-flex-start align-items-center">
                Leggi di piu
                <svg className="icon icon-sm"><use href="/navbar-icons/sprites.svg#it-arrow-right" /></svg>
              </div>
            </Link>
            {autore && (
            <>
              <div className="separator border-1 border-bottom mt-2 mb-2" />
              <div>
                Pubblicato da
                <strong>
                  {' '}
                  {autore?.nome}
                  {' '}
                  {autore?.cognome}
                </strong>
                {' '}
                il
                {' '}
                <strong>{formatoData(dataCreazione)}</strong>
              </div>
            </>
            )}
          </div>
        </div>
      </Card.Body>
    </Card>
  );
}

export default CardBasic;
