import React, { useState } from 'react';
import {
  Button, Col, Container, OverlayTrigger, Row, Tooltip,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TitlePage from '../../../../components/GestisciUtenti/TitoloPagina';
import Table from '../../../../components/Shared/Table';
import ModaleCentroVerticale from '../../../../components/modali/ModaleCentroVerticale';
import fieldsDemoCicliAssessment from './fieldsTable';
import demoCicloAssessment from '../data/demoCicloAssessment';
import DemoFormCicloAssessment from './FormDemoCicloAssessment';

function DemoAssessment() {
  const [modalShowView, setModalShowView] = useState(false);
  const pagination = {
    page: 1,
    sizePerPage: 10,
    totalSize: 0,
  };

  const handleTableChange = () => {
  };

  // Funzione attivata al click dell'icona di visualizzazione
  const onView = () => {
    setModalShowView(true);
  };

  return (
    <>
      <Container className="p-0">
        <div className="w-100">
          <div className="mb-3">
            <h1>
              {' '}
              Cicli Assessment
              {' '}
            </h1>
          </div>
          <Row className="position-relative mb-2">
            <Col>
              <Row className="float-end">
                <Col className="col-lg-auto text-end">
                  <Button variant="primary">
                    {' '}
                    <FontAwesomeIcon aria-hidden="true" icon={['fas', 'sync-alt']} />
                    {' '}
                    AGGIORNA
                    {' '}
                  </Button>
                </Col>
                <Col className="col-lg-auto text-end">
                  <OverlayTrigger
                    placement="top"
                    overlay={(
                      <Tooltip id="tooltip-disabled">
                        Funzione per creare ed aggiungere un nuovo ciclo di Assessment.
                        Tale funzionalità è legata all&apos;identità Policy Maker.
                      </Tooltip>
                )}
                  >
                    <span className="d-inline-block">
                      <Button
                        style={{ color: 'white', pointerEvents: 'none' }}
                        variant="primary"
                        disabled
                      >
                        {' '}
                        <FontAwesomeIcon aria-hidden="true" icon={['fas', 'clipboard-question']} />
                        {' '}
                        AGGIUNGI
                        {' '}
                      </Button>
                    </span>
                  </OverlayTrigger>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <Table
          data={[demoCicloAssessment]}
          fields={fieldsDemoCicliAssessment(onView)}
          pagination={pagination}
          onTableChange={handleTableChange}
          keyField="cicli_assessment_list"
        />
      </Container>
      <ModaleCentroVerticale
        modalTitle="Ciclo di Assessment"
        modalBody={(
          <DemoFormCicloAssessment
            stateCicloAssessment={demoCicloAssessment}
            disabled
          />
        )}
        show={modalShowView}
        disabledConfirm
        onClose={() => setModalShowView(false)}
      />
    </>
  );
}

export default DemoAssessment;
