/* eslint-disable prefer-destructuring */
import React, { useContext, useEffect } from 'react';
import authProviderService from '../services/authProviderService';
import chatService from '../services/chatService';
import api from '../utils/api';
import { Context } from '../utils/Context';
import history from '../utils/history';
import { setAndStartMatrix } from '../utils/matrix';
import { globalVariables } from '../utils/refreshToken';
import { setItem } from '../utils/storage';
import useQuery from '../utils/useQuery';

function LoginSpid() {
  const [value, setValue] = useContext(Context);
  const query = useQuery();

  const login = async () => {
    const authResponse = query.get('authResponse');

    authProviderService.spidLogin(authResponse)
      .then(async (response) => {
        setItem('user', JSON.stringify(response.utente));
        api.defaults.headers.common['X-CSRF-Token'] = response.csrfToken;
        globalVariables.refreshToken = response.refresh_token;
        const responseMatrix = await chatService.loginMatrix(response.utente.identificativo);
        setAndStartMatrix(responseMatrix);
        history.push('/area-riservata/scelta-identita');
      })
      .catch((err) => {
        setValue(() => ({
          ...value, open: true, testoErrore: 'Ops... qualcosa è andato storto', statusCode: err?.status,
        }));
        // history.push('/');
      });
  };
  useEffect(() => {
    login();
  }, []);

  return (
    <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
      <div className="inline-block align-middle">
        <h1 className="font-weight-normal lead" id="desc">Caricamento...</h1>
      </div>
    </div>
  );
}

export default LoginSpid;
