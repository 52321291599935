export const fieldsUtenti = [
  {
    dataField: 'codi_id_fiscale',
    text: 'Codice fiscale',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '30%' }),
  },
  {
    dataField: 'desc_nome',
    text: 'Nome',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '30%' }),
  },
  {
    dataField: 'desc_cognome',
    text: 'Cognome',
    sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '35%' }),
  },
];

export const selectRows = (setUtentiScelti, utentiScelti, utentiSceltiCopia) => {
  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    selected: [...utentiScelti].map((el) => el.codi_matrix_user_id),
    nonSelectable: [...utentiSceltiCopia].map((el) => el.codi_matrix_user_id),
    hideSelectAll: true,
    selectColumnPosition: 'left',
    onSelect: (row, isSelect) => {
      if (isSelect) {
        setUtentiScelti([...utentiScelti, row]);
      } else {
        setUtentiScelti(utentiScelti.filter(
          (utente) => utente.codi_matrix_user_id !== row.codi_matrix_user_id,
        ));
      }
    },

  };
  return selectRow;
};
