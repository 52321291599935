import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button, Container, Table,
} from 'react-bootstrap';
import { formatoDataEOra } from '../../utils/formatData';

function CardGroupStatisticheQuestionari({
  questionari,
  functionShow,
}) {
  return (
    <Container className="p-0 mb-3">
      <Table responsive bordered>
        <thead>
          <tr>
            <th>Utente</th>
            <th>Data inzio compilazione</th>
            <th>Data Invio</th>
            <th>Azioni</th>
          </tr>
        </thead>
        <tbody>
          {questionari && Object.values(questionari.listaQuestionari).map((questionario) => (
            <tr>
              <td>
                {questionario?.autore?.nome ? (
                  <>
                    {questionario?.autore?.cognome}
                    {' '}
                    {questionario?.autore?.nome}
                  </>
                ) : questionario?.autore?.identificativo}
              </td>
              <td>
                {formatoDataEOra(questionario.dataInizioCompilazione)}
              </td>
              <td>
                {formatoDataEOra(questionario.dataUltimaModifica)}
              </td>
              <td className="d-flex justify-content-center">
                <Button
                  onClick={() => functionShow(questionario._id)}
                  title={`Visualizza il questionario di ${questionario.autore.cognome} ${questionario.autore.nome}`}
                  size="sm"
                >
                  <FontAwesomeIcon aria-hidden="true" icon="search" />
                  {' '}
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>

  );
}

export default CardGroupStatisticheQuestionari;
