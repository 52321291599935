import {
  React,
} from 'react';
import { ListGroup } from 'react-bootstrap';
import TitlePage from '../../../../components/GestisciUtenti/TitoloPagina';
import entiData from './constants';
import history from '../../../../utils/history';

function ElencoEnti() {
  return (
    <>
      <TitlePage
        title="Elenco Amministrazioni"
        subtitle="È possibile visualizzare l'elenco delle Amministrazioni aderenti al progetto"
        showIndietro
        functionIndietro={() => history.push('/area-riservata/amministrazione')}
      />
      <ListGroup as="ol" numbered>
        {entiData.map((ente) => (
          <ListGroup.Item
            as="li"
            className="d-flex justify-content-between align-items-start"
          >
            <div className="ms-2 me-auto w-100 d-flex justify-content-between">
              <div className="fw-bold">{ente.titolo}</div>
              <div>{ente.codice}</div>
            </div>
          </ListGroup.Item>
        ))}
      </ListGroup>
    </>
  );
}

export default ElencoEnti;
