// Function per generare l'icona risultato desiderata
export function getIcon(valoreAtteso, valoreInserito) {
  if (valoreAtteso < valoreInserito) return 'up-long';
  if (valoreAtteso > valoreInserito) return 'down-long';
  return 'equals';
}

// Function per generare il colore dell'icona risultato desiderata
export function getColor(valoreAtteso, valoreInserito) {
  if (valoreAtteso < valoreInserito) return 'green';
  if (valoreAtteso > valoreInserito) return 'red';
  return 'orange';
}
