import { Col, Form } from 'react-bootstrap';
import { formatColloquioDate } from '../../../../../utils/formatData';

function BodyModaleColloquio({
  stateColloquio,
  onChange,
  isDisabled,
  isDipendente = false,
}) {
  return (
    <>
      {isDisabled ? (
        <h6>
          È stato fissato un colloquio nella seguente data:
        </h6>
      ) : (
        <h6>
          Seleziona una data utile per fissare il colloquio richiesto.
        </h6>
      )}
      <Form.Group as={Col} lg controlId="conferma_colloquio">
        <Form.Control
          type="datetime-local"
          name="data_colloquio"
          value={!isDipendente
            ? stateColloquio.data_colloquio : formatColloquioDate(stateColloquio.data_pianificata)}
          onChange={onChange}
          disabled={isDisabled}
        />
      </Form.Group>
    </>
  );
}

export default BodyModaleColloquio;
