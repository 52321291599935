import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import themeColors from '../../../utils/contants/theme-colors';
import { controlla } from '../../../utils/verificaPermessi';

const fieldsAreeCompetenze = (onDelete, onView, onModify, idAmministrazione) => (
  [
    {
      dataField: 'desc_codice',
      text: 'Codice',
      sort: true,
      classes: 'column-data',
      headerStyle: () => ({ width: '10%' }),
    },
    {
      dataField: 'desc_descrizione',
      text: 'Descrizione',
      sort: true,
      classes: 'column-data',
      headerStyle: () => ({ width: '30%' }),
    },
    {
      dataField: 'codi_tipo_competenza',
      text: 'Tipologia Competenza',
      classes: 'column-data',
      headerStyle: () => ({ width: '20%' }),
    },
    {
      text: 'Banca Dati Unica',
      classes: 'column-data',
      headerStyle: () => ({ width: '20%' }),
      formatter: (e, row) => (
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          {row.fk_codi_amministrazione === null && (
            <FontAwesomeIcon
              aria-hidden="true"
              icon={['fas', 'check']}
              size="sm"
            />
          )}
        </div>
      ),
    },
    {
      dataField: 'df1',
      isDummyField: true,
      text: 'Azioni',
      headerStyle: () => ({ width: '20%' }),
      formatter: (e, row) => (
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <FontAwesomeIcon
            aria-hidden="true"
            icon={['fas', 'search']}
            size="lg"
            title="Visualizza"
            id={`Visualizza${row.sequ_k_area_competenze}`}
            style={{ color: themeColors.primary }}
            onClick={() => onView({
              id: row.sequ_k_area_competenze,
              codice: row.desc_codice,
              idAmministrazione: row.fk_codi_amministrazione,
            })}
          />
          {(controlla('X27') && row.fk_codi_amministrazione === idAmministrazione) && (
          <FontAwesomeIcon
            aria-hidden="true"
            icon={['fas', 'edit']}
            size="lg"
            title="Modifica"
            id={`Modifica${row.sequ_k_area_competenze}`}
            style={{ color: themeColors.primary }}
            onClick={() => onModify(
              true,
              { id: row.sequ_k_area_competenze, codice: row.desc_codice },
            )}
          />
          )}
          {(controlla('X27') && row.fk_codi_amministrazione === idAmministrazione) && (
          <FontAwesomeIcon
            aria-hidden="true"
            icon={['fas', 'trash']}
            size="lg"
            title="Elimina"
            id={`Visualizza${row.sequ_k_area_competenze}`}
            style={{ color: themeColors.danger }}
            onClick={() => onDelete({ id: row.sequ_k_area_competenze, codice: row.desc_codice })}
          />
          )}
        </div>
      ),
    },
  ]
);

export default fieldsAreeCompetenze;
