/* eslint-disable react/no-unknown-property */
import {
  Button,
  Col, Container, Form, Image,
  Row,
} from 'react-bootstrap';
import { useContext, useEffect, useState } from 'react';
import logoAI from '../../../../images/LogoAi.svg';
import TitlePage from '../../../../components/GestisciUtenti/TitoloPagina';
import SistemaProfessionaleService from '../../../../services/sistemaProfessionaleService';
import { Context } from '../../../../utils/Context';
import Table from '../../../../components/Shared/Table';
import EmptyState from '../../../../components/EmptyState';
import history from '../../../../utils/history';
import { getItem } from '../../../../utils/storage';
import ModaleCentroVerticale from '../../../../components/modali/ModaleCentroVerticale';
import SpinnerBody from '../../../../components/Shared/SpinnerBody';
import FiltriAIProfiliRuolo from './filtriAIProfiloRuolo';
import fieldsProfiliRuoloAI from './fieldsProfiloRuoloAI';
import FormProfiliRuolo from '../FormProfiliRuolo';

function RicercaAIProfiloRuolo() {
  const [context, setContext] = useContext(Context);
  const { sequ_k_comparto, desc_comparto } = getItem('compartoSelected') ? JSON.parse(getItem('compartoSelected')) : 0;
  const [isLoading, setIsLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [isCerca, setIsCerca] = useState(false);
  const [filtri, setFiltri] = useState({
    query: '',
    nome_comparto: desc_comparto,
    competences: [],
    ambito_ruolo: [],
  });
  const [profiliRuoloList, setProfiliRuoloList] = useState([]);
  const [stateProfiloProfessionale, setStateProfiloProfessionale] = useState({});
  const [stateProfiloRuolo, setStateProfiloRuolo] = useState({});
  const [gradingList, setGradingList] = useState([]);
  const [ambitiRuoloList, setAmbitiRuoloList] = useState([]);
  const [competenzeList, setCompetenzeList] = useState([]);

  // Function collegata al servizio per ottenere la lista delle competenze
  async function getProfiliRuoloAI(filters) {
    try {
      const response = await SistemaProfessionaleService
        .getProfiliRuoloAI(filters);
      setProfiliRuoloList(response.hits);
      // setProfiliRuoloList([
      //   {
      //     title: 'Project Manager - Senior - Logistica e servizi operativi',
      //     descriptor: 'Controlla tutto.',
      //     role_id: 'ELL_PP0001-AR017',
      //     ambito_ruolo: 'Logistica e servizi operativi',
      //     similarity_score: 84.73,
      //     codice_riva: 1,
      //     comparto: 'Enti Locali',
      //     codice_amministrazione: null,
      //   },
      //   {
      //     title: 'Insegnante Matematica - Infrastrutture tecnologiche',
      //     descriptor: 'Controlla tutto.',
      //     role_id: 'ELL_PP0003-AR013',
      //     ambito_ruolo: 'Infrastrutture tecnologiche',
      //     similarity_score: 70.50,
      //     codice_riva: 2,
      //     comparto: 'Enti Locali',
      //     codice_amministrazione: 'arpam',
      //   },
      // ]);
      setIsCerca(true);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // function collegata al servizio per ottenere la lista dei grading
  async function getGrading(offset, limit, filters) {
    try {
      const response = await SistemaProfessionaleService
        .getGrading(filters, offset, limit);
      setGradingList(response.rows);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // Function collegata al servizio per ottenere la lista degli ambiti di ruolo
  async function getAmbitiRuolo(offset, limit, filters) {
    try {
      const response = await SistemaProfessionaleService
        .getAmbitiRuolo(filters, offset, limit);
      const ambitiMapped = response.rows.map(
        (el) => ({
          ambito_ruolo: el.desc_titolo,
        }),
      );
      setAmbitiRuoloList(ambitiMapped);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // Function per rimuovere le competenze duplicate.
  function removeDuplicatesComps(list) {
    const compsMap = {};
    list.forEach((el) => {
      if (
        !compsMap[el.fk_sequ_competenza] || compsMap[el.fk_sequ_competenza].grading < el.grading
      ) {
        compsMap[el.fk_sequ_competenza] = el;
      }
    });
    return Object.values(compsMap);
  }

  // Function collegata al servizio per ottenere il profilo professionale utilizzando l'id
  async function getProfiloProfessionaleById(idProfiloProfessionale) {
    try {
      const response = await SistemaProfessionaleService
        .getProfiloProfessionaleById(idProfiloProfessionale);
      const listCompetenzeMap = response.d_rel_profilo_prof_comps.map(
        (el) => ({
          ...el.fk_sequ_competenza_d_competenza,
          fk_sequ_competenza: el.fk_sequ_competenza,
          grading: el.codi_grading,
          grading_base: el.codi_grading_base,
          provenienza: el.provenienza,
        }),
      );
      setStateProfiloProfessionale({
        ...response,
        fk_sequ_competenze: removeDuplicatesComps(listCompetenzeMap),
      });
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // Function collegata al servizio per ottenere il profilo di ruolo utilizzando l'id
  async function getProfiloRuoloById(id) {
    try {
      const response = await SistemaProfessionaleService.getProfiloRuoloById(id);
      getProfiloProfessionaleById(response.fk_sequ_profilo_professionale);
      setStateProfiloRuolo({
        ...response,
        fk_sequ_competenze: response.d_rel_profilo_ruolo_comps.map(
          (el) => ({
            ...el.fk_sequ_competenza_d_competenza,
            fk_sequ_competenza: el.fk_sequ_competenza,
            grading: el.codi_grading,
          }),
        ),
      });
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // Function collegata al servizio per ottenere la lista delle competenze
  async function getCompetenze(offset, limit, page, filters) {
    try {
      const filterSession = filters || filtri;
      const response = await SistemaProfessionaleService
        .getCompetenze(filterSession, offset, limit);
      const competenzeMapped = response.rows.map(
        (el) => ({
          competences: `${el.desc_codice} - ${el.desc_titolo}`,
        }),
      );
      setCompetenzeList(competenzeMapped);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // Function per modificare lo stato della ricerca
  const onChange = (e, config) => {
    if (config) {
      if (config.name === 'competences') {
        if (config.action === 'select-option') {
          setFiltri({ ...filtri, [config.name]: e?.map((el) => el?.[config.name].split(' - ')[0]) });
        }
        if (config.action === 'remove-value') {
          const valueUpdated = filtri[config.name].filter(
            (el) => el !== config.removedValue[config.name].split(' - ')[0],
          );
          setFiltri({ ...filtri, [config.name]: valueUpdated });
        }
      } else {
        if (config.action === 'select-option') {
          setFiltri({ ...filtri, [config.name]: e?.map((el) => el?.[config.name]) });
        }
        if (config.action === 'remove-value') {
          const valueUpdated = filtri[config.name].filter(
            (el) => el !== config.removedValue[config.name],
          );
          setFiltri({ ...filtri, [config.name]: valueUpdated });
        }
      }
      if (config.action === 'clear') {
        setFiltri({ ...filtri, [config.name]: [] });
      }
    } else {
      const { name, value } = e.target;
      setFiltri({ ...filtri, [name]: value });
    }
  };

  // Function per la ricerca dei dati
  const onCercaProfili = () => {
    getProfiliRuoloAI(filtri);
  };

  // Function per visualizzare il dettaglio della competenze
  const onView = (row) => {
    getProfiloRuoloById(row.codice_riva);
    setModalShow(true);
  };

  useEffect(() => {
    getCompetenze(0, 1000, 1, {});
    getAmbitiRuolo(0, 1000, { fk_sequ_comparto: sequ_k_comparto });
    getGrading(0, 1000, {});
    setIsLoading(false);
  }, []);

  return (
    isLoading ? (
      <SpinnerBody />
    ) : (
      <Container className="p-0">
        <TitlePage
          title={(
            <>
              Ricerca Profili di Ruolo
              {' '}
              <Image src={logoAI} width="30" height="30" />
            </>
              )}
          showIndietro
          functionIndietro={() => history.goBack()}
        />
        <Row className="justify-content-center mt-3">
          <div as={Col} style={{ width: '70%' }} className="d-flex flex-column align-items-center">
            <Form.Group className="w-100 mb-3">
              <Form.Control
                name="query"
                value={filtri.query}
                type="text"
                placeholder="Inserisci un suggerimento per la ricerca... "
                maxLength={500}
                onChange={onChange}
                style={{ width: '100%' }}
              />
            </Form.Group>
            <Button
              variant="primary"
              onClick={() => onCercaProfili()}
              disabled={filtri.query.length === 0}
            >
              CERCA
            </Button>
          </div>
        </Row>
        <Row className="justify-content-center mt-3">
          {isCerca && (
            <FiltriAIProfiliRuolo
              filtri={filtri}
              setFiltri={setFiltri}
              onChangeFiltri={onChange}
              getProfiliRuoloAI={getProfiliRuoloAI}
              optionsAmbitoRuolo={ambitiRuoloList}
              optionsCompetenze={competenzeList}
            />
          )}
          {profiliRuoloList.length > 0 ? (
            <Table
              data={profiliRuoloList}
              fields={fieldsProfiliRuoloAI(onView)}
              keyField="similarity_score"
            />
          ) : (
            <EmptyState
              subtitle="Procedi con la ricerca per visualizzare i dati compatibili."
            />
          )}
        </Row>
        <ModaleCentroVerticale
          modalTitle="Profilo di Ruolo"
          modalBody={(
            <FormProfiliRuolo
              stateProfiloRuolo={stateProfiloRuolo}
              stateProfiloProfessionale={stateProfiloProfessionale}
              onChange={() => {}}
              setModalShowCts={() => {}}
              gradingList={gradingList}
              onDeleteCompetenza={() => {}}
              handleTableChange={() => {}}
            />
            )}
          size="xl"
          show={modalShow}
          disabledConfirm
          onClose={() => setModalShow(false)}
        />
      </Container>
    )
  );
}

export default RicercaAIProfiloRuolo;
