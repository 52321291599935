import React, { useContext, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { Context } from '../../../utils/Context';
import SistemaProfessionaleService from '../../../services/sistemaProfessionaleService';
import checkStorage from '../../../utils/checkSessionStorage';
import { setItem } from '../../../utils/storage';
import TitlePage from '../../../components/GestisciUtenti/TitoloPagina';
import Table from '../../../components/Shared/Table';
import ModaleCentroVerticale from '../../../components/modali/ModaleCentroVerticale';
import ModaleConferma from '../../../components/modali/ModaleConferma';
import fieldsGrading from './fieldsTable';
import FormGrading from './FormGrading';
import FiltriGrading from './filtriGrading';
import EmptyState from '../../../components/EmptyState';
import { controlla } from '../../../utils/verificaPermessi';

function Grading() {
  const [context, setContext] = useContext(Context);
  const [gradingList, setGradingList] = useState([]);
  const [gradingSelected, setGradingSelected] = useState();
  const [modalShow, setModalShow] = useState(false);
  const [modalShowView, setModalShowView] = useState(false);
  const [modalShowModify, setModalShowModify] = useState(false);
  const [modalShowDelete, setModalShowDelete] = useState(false);
  const [stateGrading, setStateGrading] = useState({
    desc_titolo: '',
    desc_descrizione: '',
    codi_valore: 0,
    codi_tipo_competenza: '',
  });

  const [filtri, setFiltri] = useState({});
  const [pagination, setPagination] = useState({
    page: 1,
    sizePerPage: 10,
    totalSize: 0,
  });

  // function collegata al servizio per ottenere la lista dei grading
  async function getGrading(offset, limit, page, filters) {
    try {
      const filterSession = filters || filtri;
      const response = await SistemaProfessionaleService
        .getGrading(filterSession, offset, limit);
      setGradingList(response.rows);
      setPagination((prevPag) => ({
        ...prevPag,
        page,
        sizePerPage: limit,
        totalSize: response.totalRowCount,
      }));
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // function attivata al cambio pagina della tabella
  const handleTableChange = (type, {
    page, sizePerPage, sortField, sortOrder,
  }) => {
    const newFilters = {
      ...filtri,
      sortField: type === 'sort' ? sortField : 'desc_titolo',
      sortOrder: type === 'sort' ? sortOrder : 'asc',
    };
    let currentIndex;
    if (page !== 0) {
      currentIndex = (page - 1) * sizePerPage;
    }
    getGrading(
      currentIndex,
      sizePerPage,
      page,
      newFilters,
    );
  };

  // useEffect
  useEffect(() => {
    let offset = 0;
    const storage = checkStorage(filtri, setFiltri, setPagination);
    if (storage.page > 1) {
      handleTableChange(null, { page: storage.page, sizePerPage: pagination.sizePerPage });
      offset = (storage.page - 1) * pagination.sizePerPage;
    }
    getGrading(offset, 10, storage.page, storage.filtri);
  }, []);

  // useEffect per settare nello storage la pagina corrente
  useEffect(() => {
    setItem('page', pagination.page);
  }, [pagination.page]);

  // useEffect per caricare i dati presenti nella tabella.
  useEffect(() => {
    getGrading(0, 10, 1, {});
  }, []);

  // function per modificare lo stato del grading che l'utente sta modificando.
  const onChange = (e, config) => {
    if (config) {
      setStateGrading({
        ...stateGrading, [config.name]: e?.codi_tipo_competenza,
      });
    } else {
      const { name, value } = e.target;
      if (name === 'codi_valore') setStateGrading({ ...stateGrading, [name]: +value });
      else setStateGrading({ ...stateGrading, [name]: value });
    }
  };

  // function per modificare lo stato che gestisce i filtri
  function onChangeFiltri(e, config) {
    if (config) {
      setFiltri({
        ...filtri, [config.name]: e?.codi_tipo_competenza,
      });
    } else {
      const { value, name } = e.target;
      setFiltri({
        ...filtri, [name]: value,
      });
    }
  }

  // function per effettuare il refresh della pagina
  function refresh() {
    getGrading(0, 10, 1, {});
  }

  // function collegata al servizio di creazione di un nuovo grading
  async function nuovoGrading() {
    SistemaProfessionaleService.nuovoGrading(stateGrading)
      .then((res) => {
        setContext(() => ({
          ...context, openConferma: true, testo: res.message,
        }));
        setModalShow(false);
        refresh();
      })
      .catch((err) => {
        setContext(() => ({
          ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
        }));
      });
  }

  // function collegata al servizio di eliminazione di un grading
  async function eliminaGrading(idGrading) {
    SistemaProfessionaleService.eliminaGrading(idGrading)
      .then((res) => {
        setContext(() => ({
          ...context, openConferma: true, testo: res.message,
        }));
        setModalShowDelete(false);
        refresh();
      })
      .catch((err) => {
        setContext(() => ({
          ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
        }));
      });
  }

  // function collegata al servizio di modifica di un grading
  async function modificaGrading(idGrading) {
    SistemaProfessionaleService.modificaGrading(
      idGrading,
      stateGrading,
    )
      .then((res) => {
        setContext(() => ({
          ...context, openConferma: true, testo: res.message,
        }));
        setModalShowModify(false);
        refresh();
      })
      .catch((err) => {
        setContext(() => ({
          ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
        }));
      });
  }

  // function collegata al servizio di get di un particolare grading
  async function getGradingById(id) {
    try {
      const response = await SistemaProfessionaleService.getGradingById(id);
      setStateGrading({
        desc_titolo: response.desc_titolo,
        desc_descrizione: response.desc_descrizione,
        codi_valore: response.codi_valore,
        codi_tipo_competenza: response.codi_tipo_competenza,
      });
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // Funzione attivata al click dell'icona di eliminazione
  const onDelete = (grading) => {
    setModalShowDelete(true);
    setGradingSelected(grading);
  };

  // Funzione attivata al click dell'icona di visualizzazione
  const onView = (grading) => {
    getGradingById(grading.id);
    setGradingSelected(grading);
    setModalShowView(true);
  };

  // Funzione attivata al click dell'icona di modifica
  const onModify = (modify, grading) => {
    getGradingById(grading.id);
    setGradingSelected(grading);
    if (modify) {
      setModalShowModify(true);
    } else {
      setModalShowView(false);
      setModalShowModify(true);
    }
  };

  return (
    <>
      <Container className="p-0">
        <TitlePage
          title="Grading"
          showRefresh
          functionRefresh={refresh}
          showAdd={controlla('X45')}
          functionAdd={() => setModalShow(true)}
        />
        <FiltriGrading
          filtri={filtri}
          setFiltri={setFiltri}
          onChangeFiltri={onChangeFiltri}
          getGrading={getGrading}
        />
        {gradingList.length > 0 ? (
          <Table
            data={gradingList}
            fields={fieldsGrading(onDelete, onView, onModify)}
            pagination={pagination}
            onTableChange={handleTableChange}
            keyField="grading_list"
          />
        ) : (
          <EmptyState marginTop="3rem" />
        )}
      </Container>
      <ModaleCentroVerticale
        modalTitle="Crea un nuovo grading"
        modalBody={(
          <FormGrading
            stateGrading={stateGrading}
            onChange={onChange}
            isNew
          />
          )}
        buttonType="submit"
        show={modalShow}
        onConfirm={() => nuovoGrading()}
        labelConfirm="Aggiungi"
        onClose={() => setModalShow(false)}
      />
      <ModaleConferma
        labelBottone="Elimina"
        coloreAzione="primary"
        title="Sei sicuro di procedere?"
        body={`L'operazione eliminerà il grading ${gradingSelected?.codice}`}
        show={modalShowDelete}
        handleClose={() => setModalShowDelete(false)}
        handleOk={() => eliminaGrading(gradingSelected.id)}
      />
      <ModaleCentroVerticale
        modalTitle="Grading"
        modalBody={(
          <FormGrading
            stateGrading={stateGrading}
            disabled
          />
          )}
        buttonType="submit"
        show={modalShowView}
        onConfirm={() => onModify(false, gradingSelected)}
        disabledConfirm={(!controlla('X45'))}
        labelConfirm="Modifica"
        onClose={() => setModalShowView(false)}
      />
      <ModaleCentroVerticale
        modalTitle="Modifica Grading"
        modalBody={(
          <FormGrading
            stateGrading={stateGrading}
            onChange={onChange}
          />
          )}
        buttonType="submit"
        show={modalShowModify}
        onConfirm={() => modificaGrading(gradingSelected.id)}
        labelConfirm="Conferma"
        onClose={() => setModalShowModify(false)}
      />
    </>
  );
}

export default Grading;
