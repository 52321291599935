/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext, useState } from 'react';
import {
  Form,
} from 'react-bootstrap';
import { Editor } from '@tinymce/tinymce-react';
import { Context } from '../../../../utils/Context';
import faqService from '../../../../services/faqService';
import TitlePage from '../../../../components/GestisciUtenti/TitoloPagina';
import tiny from '../../../../utils/TinyMCEConfiguration';
import ModaleConferma from '../../../../components/modali/ModaleConferma';
import history from '../../../../utils/history';

function AggiungiFaqPubblica() {
  const [faq, setFaq] = useState({
    autorizzazioni: [],
    argomento: '',
    domanda: '',
    risposta: '',
  });
  const [showModaleConferma, setShowModaleConferma] = useState(false);
  const [context, setContext] = useContext(Context);

  const handleCloseModaleConferma = () => setShowModaleConferma(!showModaleConferma);

  async function createFaqPubblica() {
    const mapFaq = {
      ...faq,
      argomento: faq.argomento.toUpperCase(),
    };
    await faqService.createFaqPubblica(mapFaq)
      .then((res) => {
        setContext(() => ({
          ...context, openConferma: true, testo: res.message || 'Operazione eseguita correttamente',
        }));
        history.goBack();
      })
      .catch((err) => {
        setContext(() => ({
          ...context, open: true, testoErrore: err?.data || 'Errore', statusCode: err?.status,
        }));
      })
      .finally(() => {
        handleCloseModaleConferma();
      });
  }

  const onChange = (e) => {
    const { value, id } = e.target;
    setFaq({
      ...faq,
      [id]: value,
    });
  };

  const handleEditorChange = (newValue) => {
    setFaq({ ...faq, risposta: newValue });
  };

  return (
    <>
      <TitlePage
        title="Aggiungi FAQ"
        subtitle="In questa sezione puoi aggiungere una FAQ (Frequently Asked Question)."
        showIndietro
        showSave
        functionIndietro={() => {
          history.push('/area-riservata/gestione-faq-esterne');
        }}
        functionSave={handleCloseModaleConferma}
      />
      <Form>
        <Form.Group className="mb-3" controlId="argomento">
          <Form.Label>Argomento*</Form.Label>
          <Form.Control placeholder="Inserisci l'argomento" value={faq.argomento} onChange={onChange} />
        </Form.Group>

        <Form.Group className="mb-3" controlId="domanda">
          <Form.Label>Domanda*</Form.Label>
          <Form.Control placeholder="Inserisci la domanda" value={faq.domanda} onChange={onChange} />
        </Form.Group>

        <label htmlFor="risposta" className="mb-1">Risposta*</label>
        <Editor
          id="risposta"
          value={faq.risposta}
          init={{
            plugins: tiny.plugins,
            toolbar: tiny.toolbar,
          }}
          apiKey={tiny.apiKey}
          onEditorChange={handleEditorChange}
        />
      </Form>

      <div className="separator border-1 border-bottom mt-1" />

      <ModaleConferma
        labelBottone="Conferma"
        coloreAzione="primary"
        title="Sei sicuro di procedere?"
        body="L'operazione creerà una nuova FAQ"
        show={showModaleConferma}
        handleClose={handleCloseModaleConferma}
        handleOk={createFaqPubblica}
      />
    </>
  );
}

export default AggiungiFaqPubblica;
