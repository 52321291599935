/* eslint-disable react/no-unknown-property */
import React, { useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import StoricoProfili from './StoricoProfili';
import Table from '../../../../components/Shared/Table';
import fieldsCompetenzeProfilo from '../fields/fieldsCompetenzeProfilo';
import EmptyState from '../../../../components/EmptyState';
import fieldsCompetenzeNoGrading from '../fields/fieldsCompetenzeNoGrading';

function CompetenzeProfilo({
  profiloRuolo,
  profiliRuoloStorico,
  competenzeList,
  optionsGrading,
}) {
  const [isCompleted, setIsCompleted] = useState(false);

  useEffect(() => {
    const gradingsList = competenzeList.map(
      (el) => el.codi_grading_assegnato,
    );
    setIsCompleted(!gradingsList.includes(null));
  }, []);

  return (
    <div as={Col}>
      <h3>Competenze del Profilo di Ruolo</h3>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <h5>
          Profilo
          <p style={{ fontWeight: 'lighter', fontStyle: 'italic' }}>
            {profiloRuolo}
          </p>
        </h5>
        <div style={{ alignSelf: 'flex-start' }}>
          <StoricoProfili
            profiliRuoloStorico={profiliRuoloStorico}
          />
        </div>
      </div>
      {competenzeList.length > 0 ? (
        <Table
          data={competenzeList}
          fields={isCompleted
            ? fieldsCompetenzeProfilo(competenzeList, optionsGrading)
            : fieldsCompetenzeNoGrading()}
          keyField="codice_competenza"
        />
      ) : (
        <EmptyState
          title="Attenzione"
          subtitle="Nessun assessment completato presente per l'utente."
        />
      )}

    </div>
  );
}

export default CompetenzeProfilo;
