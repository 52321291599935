import React from 'react';
import { Container } from 'react-bootstrap';
import TitlePage from '../../../../components/GestisciUtenti/TitoloPagina';
import Table from '../../../../components/Shared/Table';
import fieldsDemoCicliAssessment from './fieldsTable';
import demoCicloAssessment from '../data/demoCicloAssessment';
import history from '../../../../utils/history';

function DemoElencoAssessment() {
  const pagination = {
    page: 1,
    sizePerPage: 10,
    totalSize: 0,
  };

  const handleTableChange = () => {
  };

  // Funzione attivata al click dell'icona di visualizzazione
  const onView = () => {
    history.push('/area-riservata/demo-assessment/elenco-assessment/elenco');
  };

  return (
    <Container className="p-0">
      <TitlePage
        title="Assessment - Elenco dei cicli di Assessment"
        showRefresh
      />
      <Table
        data={[demoCicloAssessment]}
        fields={fieldsDemoCicliAssessment(onView)}
        pagination={pagination}
        onTableChange={handleTableChange}
        keyField="cicli_assessment_list"
      />
    </Container>
  );
}

export default DemoElencoAssessment;
