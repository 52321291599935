/* eslint-disable react/no-unknown-property */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Button, Col } from 'react-bootstrap';
import Table from '../../../../components/Shared/Table';
import fieldsModaleCompetenzeAggiuntive from '../fields/fieldsModaleCompetenzeAggiuntive';
import fieldsCompetenzeAggiuntive from '../fields/fieldsCompetenzeAggiuntive';
import EmptyState from '../../../../components/EmptyState';
import ModaleCentroVerticale from '../../../../components/modali/ModaleCentroVerticale';
import { controlla } from '../../../../utils/verificaPermessi';
import FiltriCompetenzeAggiuntive from './FiltriCompetenzeAggiuntive';

function CompetenzeAggiuntive({
  competenzeAggiuntive,
  setCompetenzeAggiuntive,
  gradingList,
  competenzeList,
  setCompetenzeList,
  pagination,
  handleTableChange,
  getCompetenze,
  filtri,
  setFiltri,
}) {
  const [showModalAdd, setShowModalAdd] = useState(false);
  const [showModalModify, setShowModalModify] = useState(false);
  const [errors, setErrors] = useState({});
  const [competenzaToShow, setCompetenzaToShow] = useState([]);
  const [dataSelected, setDataSelected] = useState({});
  const [codiToDelete, setCodiToDelete] = useState('');
  const [objError, setObjError] = useState({});
  const [objDataSelected, setObjDataSelected] = useState({});

  // Validazione per la creazione/modifica di una competenza.
  const validateCompetenza = (arrDataSelected) => {
    const errori = {};
    arrDataSelected.forEach((competenza) => {
      if (competenza.desc_fonte === '' || !competenza.desc_fonte) {
        errori[competenza.desc_codice] = { ...errori[competenza.desc_codice], desc_fonte: true };
      }
      if (competenza.desc_anno === '' || !competenza.desc_anno) {
        errori[competenza.desc_codice] = { ...errori[competenza.desc_codice], desc_anno: true };
      }
      if (competenza.codi_grading === '' || !competenza.codi_grading) {
        errori[competenza.desc_codice] = { ...errori[competenza.desc_codice], codi_grading: true };
      }
    });
    return errori;
  };

  // function per gestire il cambiamento dei dati nel form
  const onChange = (e, row, config) => {
    let newObjError;
    let newObjDataSelected;
    if (config) {
      if (config.name === 'desc_fonte') {
        if (config?.isModify) {
          const newCompetenzaToModify = [
            {
              ...competenzaToShow[0],
              [config.name]: e?.value,
            },
          ];
          setCompetenzaToShow(newCompetenzaToModify);
        }
        newObjDataSelected = {
          codice: row.desc_codice,
          row,
          name: config.name,
          value: e.value,
        };
        newObjError = {
          codice: row.desc_codice,
          stato: {
            desc_fonte: false,
          },
        };
      }
      if (config.name === 'codi_grading') {
        if (config?.isModify) {
          const newCompetenzaToModify = [
            {
              ...competenzaToShow[0],
              [config.name]: e?.codi_valore,
            },
          ];
          setCompetenzaToShow(newCompetenzaToModify);
        }
        newObjDataSelected = {
          codice: row.desc_codice,
          row,
          name: config.name,
          value: e.codi_valore,
        };
        newObjError = {
          codice: row.desc_codice,
          stato: {
            codi_grading: false,
          },
        };
      }
      setObjError(newObjError);
      setObjDataSelected(newObjDataSelected);
    } else {
      const { name, value, type } = e.target;
      if (type === 'number') {
        const newCompetenzaToModify = [
          {
            ...competenzaToShow[0],
            [name]: parseInt(value, 10),
          },
        ];
        setCompetenzaToShow(newCompetenzaToModify);
        newObjDataSelected = {
          codice: row.desc_codice,
          row,
          name,
          value: parseInt(value, 10),
        };
        newObjError = {
          codice: row.desc_codice,
          stato: {
            desc_anno: false,
          },
        };
        setObjError(newObjError);
        setObjDataSelected(newObjDataSelected);
        return;
      }
      if (type === 'checkbox') {
        const { checked } = e.target;
        newObjDataSelected = {
          codice: row.desc_codice,
          row,
          checked,
          isCheckbox: true,
        };
        newObjError = {
          codice: row.desc_codice,
          stato: {
            desc_note: true,
            desc_fonte: true,
            desc_anno: true,
            codi_grading: true,
          },
        };
        setObjError(newObjError);
        setObjDataSelected(newObjDataSelected);
        return;
      }
      if (type === 'textarea') {
        const newCompetenzaToModify = [
          {
            ...competenzaToShow[0],
            [name]: value,
          },
        ];
        setCompetenzaToShow(newCompetenzaToModify);
        newObjDataSelected = {
          codice: row.desc_codice,
          row,
          name,
          value,
        };
        setObjDataSelected(newObjDataSelected);
      }
    }
  };

  // function per aprire e chiudere la modale
  const handleCloseModal = () => {
    setShowModalAdd(false);
    setShowModalModify(false);
  };

  // function che trasforma i dati in un array da inserire nalla tabella
  const handleAggiungiCompetenze = (isOnModify) => {
    let competenzeUpdated;
    const arrDataSelected = Object.keys(dataSelected)
      .map((key) => dataSelected[key])
      .filter((value) => (value?.isSelected !== false));
    if (isOnModify) {
      competenzeUpdated = competenzeAggiuntive.map(
        (competenza) => (competenza.desc_codice === competenzaToShow[0].desc_codice
          && competenza.azione !== 'rimuovi'
          ? ({ ...competenzaToShow[0], azione: (competenza.azione !== 'aggiungi' ? 'modifica' : 'aggiungi') }) : ({ ...competenza })),
      );
    } else {
      const newCompetenzeAdded = arrDataSelected.map(
        (competenza) => ({
          ...competenza,
          azione: 'aggiungi',
        }),
      );
      competenzeUpdated = competenzeAggiuntive.concat(newCompetenzeAdded);
    }
    const newErrors = validateCompetenza(competenzeUpdated);
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      const updatedCompetenzeList = competenzeList.map(
        (el) => (
          Object.keys(newErrors).includes(el.desc_codice) ? { ...el, errors: newErrors } : el
        ),
      );
      setCompetenzeList(updatedCompetenzeList);
      if (isOnModify) {
        const newCompetenzaToModify = [
          {
            ...competenzaToShow[0],
            errors: newErrors,
          },
        ];
        setCompetenzaToShow(newCompetenzaToModify);
      }
      return;
    }
    setCompetenzeAggiuntive(competenzeUpdated);
    setDataSelected({});
    setErrors({});
    handleCloseModal();
  };

  // function che gestisce il bottone di modifica nella tabella
  const handleModalShow = (row) => {
    setCompetenzaToShow([{ ...row }]);
    setShowModalModify(true);
  };

  // function che gestisce il bottone di elimina nella tabella
  const handleDelete = (row) => {
    setCodiToDelete(row.desc_codice);
  };

  const filtraCompetenzeAggiuntive = (competenze) => {
    const competenzeFiltred = competenze.filter((competenza) => competenza?.azione !== 'rimuovi');
    return competenzeFiltred;
  };

  const filtraCompetenzeList = (competenze) => {
    const competenzeFiltred = competenze.filter(
      (competenza) => !competenzeAggiuntive.some(
        (competenzaAgg) => (competenzaAgg.desc_codice === competenza.desc_codice) && (competenzaAgg.azione !== 'rimuovi'),
      ),
    );
    return competenzeFiltred;
  };

  // function per modificare lo stato che gestisce i filtri
  function onChangeFiltri(e, config) {
    if (config) {
      setFiltri({
        ...filtri, [config.name]: e?.codi_tipo_competenza,
      });
    } else {
      const { value, name } = e.target;
      setFiltri({
        ...filtri, [name]: value,
      });
    }
  }

  // effetto all'eliminazione di una competenza
  useEffect(() => {
    const newDataSelected = { ...dataSelected };
    delete newDataSelected[codiToDelete];
    let findedOne = false;
    const newCompetenzeAggiuntiveArray = competenzeAggiuntive.map((competenza) => {
      if (competenza.desc_codice === codiToDelete && findedOne) {
        return null;
      }
      if (competenza.desc_codice === codiToDelete && !findedOne) {
        findedOne = true;
        return { ...competenza, azione: 'rimuovi' };
      }
      return { ...competenza };
    });
    const resultFiltered = newCompetenzeAggiuntiveArray.filter((competenza) => competenza !== null);
    setDataSelected(newDataSelected);
    setCompetenzeAggiuntive(resultFiltered);
  }, [codiToDelete]);

  // effetto all'aggiunzione di un errore nei campi di input
  useEffect(() => {
    const updatedErrors = {
      ...errors,
      [objError.codice]: {
        ...errors[objError.codice],
        ...objError.stato,
      },
    };
    const newCompetenze = competenzeList.map((competenza) => (
      competenza.desc_codice === objError.codice
        ? { ...competenza, errors: updatedErrors } : { ...competenza }
    ));
    setErrors(updatedErrors);
    setCompetenzeList(newCompetenze);
  }, [objError]);

  // effetto che cambia lo stato dei dati selezionati nella modale
  useEffect(() => {
    // IF che gestice la checkbox
    if (objDataSelected?.isCheckbox) {
      if (objDataSelected.checked) {
        setDataSelected((prevState) => ({
          ...prevState,
          [objDataSelected.codice]:
          {
            ...objDataSelected.row,
            desc_note: '',
            isSelected: objDataSelected.checked,
          },
        }));
      } else {
        const newDataSelected = { ...dataSelected };
        delete newDataSelected[objDataSelected.codice];
        setDataSelected(newDataSelected);
      }
      const newCompetenze = competenzeList.map((el) => (
        el.desc_codice === objDataSelected.codice
          ? {
            ...objDataSelected.row,
            desc_note: '',
            isSelected: objDataSelected.checked,
          } : { ...el }
      ));
      setCompetenzeList(newCompetenze);
      return;
    }
    // IF gestisce i campi di inserimento
    if (Object.keys(objDataSelected).length > 0) {
      setDataSelected((prevState) => ({
        ...prevState,
        [objDataSelected.codice]:
        {
          ...objDataSelected.row,
          isSelected: true,
          [objDataSelected.name]: objDataSelected.value,
        },
      }));
      const newCompetenze = competenzeList.map((el) => (
        el.desc_codice === objDataSelected.codice
          ? {
            ...objDataSelected.row,
            [objDataSelected.name]: objDataSelected.value,
          } : { ...el }
      ));
      setCompetenzeList(newCompetenze);
    }
  }, [objDataSelected]);

  return (
    <div as={Col}>
      <h3>Competenze Aggiuntive</h3>
      <div className="d-flex justify-content-end mb-3">
        {controlla('Z32') && (
        <Button
          className="me-2"
          size="md"
          onClick={() => setShowModalAdd(true)}
          title="Aggiorna la pagina"
        >
          <FontAwesomeIcon aria-hidden="true" icon="plus" />
          &nbsp;
          AGGIUNGI COMPETENZE
        </Button>
        )}
      </div>
      {competenzeAggiuntive?.find((competenza) => (competenza.azione !== 'rimuovi')) ? (
        <Table
          data={filtraCompetenzeAggiuntive(competenzeAggiuntive)}
          fields={fieldsCompetenzeAggiuntive(
            handleModalShow,
            handleDelete,
            gradingList,
          )}
          keyField="desc_codice"
        />
      ) : (
        <EmptyState
          title="Nessuna competenza aggiuntiva"
          subtitle="Non hai inserito nessuna competenza aggiuntiva, fai click su Aggiungi Competenze per inserirle"
          marginBottom="3rem"
          marginTop="3rem"
        />
      )}

      <ModaleCentroVerticale
        modalTitle="Aggiungi competenze aggiuntive"
        modalBody={(
          <div>
            <FiltriCompetenzeAggiuntive
              filtri={filtri}
              setFiltri={setFiltri}
              onChangeFiltri={onChangeFiltri}
              getCompetenze={getCompetenze}
            />
            {filtraCompetenzeList(competenzeList).length > 0 ? (
              <Table
                data={filtraCompetenzeList(competenzeList)}
                fields={fieldsModaleCompetenzeAggiuntive(
                  onChange,
                  dataSelected,
                  errors,
                  false,
                  gradingList,
                )}
                pagination={pagination}
                onTableChange={handleTableChange}
                keyField="desc_codice"
              />
            ) : (
              <EmptyState
                subtitle="Nessuna competenza compatibile con i filtri è stata trovata, riprovare."
                marginTop="5rem"
              />
            )}
          </div>
      )}
        fullScreen
        buttonType="submit"
        show={showModalAdd}
        onConfirm={() => handleAggiungiCompetenze()}
        labelConfirm="CONFERMA"
        onClose={() => handleCloseModal()}
      />

      <ModaleCentroVerticale
        modalTitle={controlla('Z33') ? 'Visualizza competenza aggiuntiva' : 'Modifica competenza aggiuntiva'}
        modalBody={(
          <div className="overflow-x-auto">
            <Table
              data={competenzaToShow}
              fields={fieldsModaleCompetenzeAggiuntive(
                onChange,
                dataSelected,
                errors,
                true,
                gradingList,
              )}
              keyField="desc_codice"
            />
          </div>
      )}
        disabledConfirm={controlla('Z33')}
        size="xl"
        buttonType="submit"
        show={showModalModify}
        onConfirm={() => handleAggiungiCompetenze(true)}
        labelConfirm="CONFERMA"
        onClose={() => handleCloseModal()}
      />
    </div>
  );
}

export default CompetenzeAggiuntive;
