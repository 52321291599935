import React, { useContext, useEffect, useState } from 'react';
import {
  Col, Container, Form, Row,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams } from 'react-router-dom';
import { Context } from '../../../utils/Context';
import templateService from '../../../services/templateService';
import TitlePage from '../../../components/GestisciUtenti/TitoloPagina';
import history from '../../../utils/history';
import { formatoDataEOra } from '../../../utils/formatData';
import CardGroupSezioni from '../../../components/Template/CardGroupSezioni';
import NewTemplateSection from '../../../components/Template/NewTemplateSection';
import themeColors from '../../../utils/contants/theme-colors';

function VisualizzaTemplate() {
  const { idTemplate } = useParams();
  const [context, setContext] = useContext(Context);
  const [showNewTemplateLayout, setShowNewTemplateLayout] = useState();
  const [newTemplate, setNewTemplate] = useState({
    titolo: '',
    descrizione: '',
    creatore: {
      nome: '',
      cognome: '',
    },
    dataCreazione: '',
    autoreUltimaModifica: {
      nome: '',
      cognome: '',
    },
    dataUltimaModifica: '',
    sezioni: {},
  });
  const [sezione, setSezione] = useState({
    titolo: '',
    descrizione: '',
    template: {},
  });

  // Function collegata al servizio di get di un determinato template
  async function getTemplate() {
    try {
      const response = await templateService.getTemplateById(idTemplate);
      setNewTemplate(response);
      return response;
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
      return err;
    }
  }

  // Function per gestire la visualizzazione del layout del nuovo template
  function showNewTemplateLayoutHandle() {
    setShowNewTemplateLayout(!showNewTemplateLayout);
  }

  // useEffect
  useEffect(() => {
    getTemplate();
  }, []);

  return (
    <Container className="p-0">
      <TitlePage
        title="Template"
        subtitle="In questa sezione è possibile visualizzare il dettaglio del template corrente"
        showIndietro
        functionIndietro={!showNewTemplateLayout ? () => history.push('/area-riservata/template') : showNewTemplateLayoutHandle}
      />
      <div>
        Creato da
        <strong>
          {' '}
          {`${newTemplate?.creatore.nome} ${newTemplate?.creatore.cognome}`}
          {' '}
        </strong>
        {' '}
        il
        {' '}
        <strong>{formatoDataEOra(newTemplate.dataCreazione)}</strong>
        <br />
        Ultima modifica:
        <strong>
          {' '}
          {`${newTemplate?.autoreUltimaModifica?.nome} ${newTemplate?.autoreUltimaModifica?.cognome}`}
          {' '}
        </strong>
        {' '}
        il
        {' '}
        <strong>{formatoDataEOra(newTemplate.dataUltimaModifica)}</strong>
      </div>
      <br />
      {!showNewTemplateLayout ? (
        <Container className="p-0">
          <Row>
            <Col>
              <p className="h4" style={{ lineHeight: '1.6' }}>
                Informazioni principali
              </p>
            </Col>
          </Row>
          <Form>
            <Row>
              <Form.Group as={Col} lg controlId="titolo">
                <Form.Label>Titolo</Form.Label>
                <Form.Control value={newTemplate.titolo} disabled />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} lg controlId="descrizione">
                <Form.Label>Descrizione</Form.Label>
                <Form.Control value={newTemplate.descrizione} disabled />
              </Form.Group>
            </Row>
          </Form>
          <Row>
            <Col className="mb-4">
              <Form.Group as={Col} lg controlId="tipo">
                <Form.Label>Stato</Form.Label>
                <Form.Control value={newTemplate.stato} disabled />
              </Form.Group>
            </Col>
            <Col />
          </Row>
          <Row>
            <Col>
              <p className="h4" style={{ lineHeight: '1.6' }}>
                Sezioni Template
              </p>
            </Col>
          </Row>
          <CardGroupSezioni
            sezioni={newTemplate.sezioni}
            functionShow={showNewTemplateLayoutHandle}
            setEditedSezione={setSezione}
            readOnly
          />
        </Container>
      ) : (
        <NewTemplateSection
          template={sezione.template || {}}
          functionCancel={showNewTemplateLayoutHandle}
          editedSezione={sezione}
          readOnly
        />
      )}
    </Container>
  );
}

export default VisualizzaTemplate;
