import React, { useContext, useEffect, useState } from 'react';
import * as Survey from 'survey-react';
import { useParams } from 'react-router-dom';
import 'survey-react/survey.css';
import { Container } from 'react-bootstrap';
import { Context } from '../../../../utils/Context';
import questionariService from '../../../../services/questionariService';
import TitlePage from '../../../../components/GestisciUtenti/TitoloPagina';
import CardGroupSezioni from '../../../../components/Questionari/CardGroupSezioni';
import history from '../../../../utils/history';
import useQuery from '../../../../utils/useQuery';
import questionariNonTracciatiService from '../../../../services/questionariNonTracciatiService';

Survey.StylesManager.applyTheme('blue');

Survey.surveyLocalization.currentLocale = 'it';

function VisualizzaQuestionario() {
  const [questionario, setQuestionario] = useState();
  const [sezioni, setSezioni] = useState();
  const [showSurvey, setShowSurvey] = useState(false);
  // eslint-disable-next-line prefer-const
  let [model, setModel] = useState();
  const { idQuestionario } = useParams();
  const query = useQuery();
  const [context, setContext] = useContext(Context);

  // Function collegata al servizio per recuperare un questionario in base all'id
  async function getQuestionarioById() {
    try {
      if (query.get('isEsterno') === 'true') {
        const response = await questionariNonTracciatiService.getQuestionarioById(idQuestionario);
        setQuestionario(response);
        setSezioni(response.sezioni);
      } else {
        const response = await questionariService.getQuestionarioById(idQuestionario);
        setQuestionario(response);
        setSezioni(response.sezioni);
      }
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // Function per mostrare la sezione selezionata
  function showSezione(titolo) {
    setShowSurvey(true);
    const modelSurveyJs = new Survey.Model(sezioni[titolo].template);
    modelSurveyJs.data = sezioni[titolo].domandeCompilate;
    modelSurveyJs.pages[0].readOnly = true;
    setModel(modelSurveyJs);
    setTimeout(() => {
      const completeBtn = document.getElementById('sv-nav-complete');
      if (completeBtn) { completeBtn.style.display = 'none'; }
    }, 150);
  }

  // Function attivata al click di complete
  function onComplete() {
    setShowSurvey(!showSurvey);
  }

  // useEffect
  useEffect(() => {
    getQuestionarioById();
  }, []);

  return (
    <Container className="p-0">
      <TitlePage
        title="Visualizza Questionario"
        subtitle={questionario ? questionario.titolo : null}
        showIndietro
        functionIndietro={() => history.goBack()}
      />
      {sezioni && (
        <CardGroupSezioni
          sezioni={sezioni}
          functionShow={showSezione}
          onlyView
        />
      )}
      <br />
      {showSurvey && (
        <Survey.Survey
          model={model}
          onComplete={onComplete}
        />
      )}
    </Container>
  );
}

export default VisualizzaQuestionario;
