function calcolaPunteggioSO(struttureList) {
  // Filtraggio per i criteri in base alla tipologia
  const criteriQuantitativiSO = struttureList.map(
    (so) => so.criteri.filter(
      (crt) => crt.tipologia === 'Quantitativi',
    ),
  );

  // QUANTITATIVI: calcolo dei parametri necessari per la normalizzazione
  // Recupero dei codici dei criteri quantitativi
  const criteriIdQnt = criteriQuantitativiSO[0].map(
    (crt) => crt.sequ_k_criterio,
  ).sort(
    (a, b) => a - b,
  );
  const valoriCriterioQnt = [];
  criteriIdQnt.forEach(
    (id) => {
      const criterio = {
        sequ_k_criterio: id,
        valori: [],
      };
      criteriQuantitativiSO.forEach(
        (so) => {
          const value = so.find((el) => el.sequ_k_criterio === id);
          if (value) {
            criterio.valori.push(parseFloat(value.valore_criterio.replace(/\./g, '')));
          }
        },
      );
      valoriCriterioQnt.push(criterio);
    },
  );
  const maxCriterioQnt = valoriCriterioQnt.map(
    (el) => ({
      ...el,
      massimo: Math.max(...el.valori),
    }),
  );

  // Calcolo dei punteggi finali per ogni struttura organizzativa
  const punteggiSO = [];
  struttureList.forEach(
    (so) => {
      let ptgComplessivo = 0;
      so.criteri.forEach(
        (crt) => {
          if (crt.tipologia === 'Quantitativi') {
            ptgComplessivo += (
              (parseFloat(crt.valore_criterio.replace(/\./g, '')) / maxCriterioQnt.find(
                (el) => el.sequ_k_criterio === crt.sequ_k_criterio,
              ).massimo) * (crt.valore_percentuale / 100)
            );
          } else {
            ptgComplessivo += (
              crt.valore_criterio * (crt.valore_percentuale / 100));
          }
        },
      );
      punteggiSO.push({
        struttura_organizzativa: so.struttura_organizzativa,
        punteggio: parseFloat((ptgComplessivo * 100).toFixed(2)),
      });
    },
  );

  return punteggiSO;
}

export default calcolaPunteggioSO;
