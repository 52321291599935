import React, { useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import Header from '../../components/HeaderPrivata';
import './styles.css';
import { clear, getItem, removeItem } from '../../utils/storage';
import Footer from '../../components/Footer/FooterHtml';
import { ContextProvider } from '../../utils/Context';
import ErrorBoundary from '../../components/ErrorBoundary';
import Error from '../../components/Alert';
import Sidebar from '../../components/Sidebar';
import history from '../../utils/history';
import Spinner from '../../components/Spinner';
import authProviderService from '../../services/authProviderService';
import { globalVariables } from '../../utils/refreshToken';
import api from '../../utils/api';
import { controlla } from '../../utils/verificaPermessi';
import { client, setAndStartMatrix } from '../../utils/matrix';
import chatService from '../../services/chatService';

function PrivateLayout({ component: Component, code, ...rest }) {
  const showSidebar = history.location.pathname !== '/area-riservata/scelta-identita' && history.location.pathname !== '/area-riservata/account';
  const handleOpen = () => {
    document.body.classList.toggle('sb-sidenav-toggled');
  };

  const [show, setShow] = useState(false);

  async function getNuovoRefreshToken() {
    if (!globalVariables.refreshToken || globalVariables.refreshToken === '') {
      await authProviderService.nuovoRefreshToken()
        .then((response) => {
          globalVariables.refreshToken = response.refresh_token;
        })
        .catch(() => {
          removeItem('user');
          history.push('/');
        });
    }
  }

  async function getNuovoCsrfToken() {
    if (!api.defaults.headers.common['X-CSRF-Token']) {
      await authProviderService.nuovoCsrfToken()
        .then((response) => {
          api.defaults.headers.common['X-CSRF-Token'] = response.csrfToken;
        })
        .catch(() => {
          removeItem('user');
          history.push('/');
        });
    }
  }

  async function startClientMatrix() {
    if (!client) {
      try {
        const responseMatrix = await chatService.loginMatrix((JSON.parse(getItem('user')))?.identificativo);
        setAndStartMatrix(responseMatrix);
      } catch (err) {
        console.error(err);
      }
    }
  }

  useEffect(() => {
    getNuovoRefreshToken();
    getNuovoCsrfToken();
    setTimeout(() => {
      startClientMatrix();
      setShow(true);
    }, 500);
    // if (('Notification' in window)) {
    //   Notification.requestPermission();
    // }
  }, []);

  useEffect(() => {
    if (code && !controlla(code)) {
      history.push('/area-riservata');
    }
  }, [window.location.href]);

  // useEffect necessario per effettuare il logout quando si chiude il browser
  useEffect(() => () => {
    window.addEventListener('unload', () => {
      client?.logout();
      clear();
    });
  });

  return (
    <Route
      {...rest}
      render={(matchProps) => {
        if (getItem('user')) {
          return (
            <ContextProvider>
              <div className="d-flex" id="wrapper">
                {showSidebar && show ? <Sidebar /> : null}

                <div id="page-content-wrapper">
                  <Header handleOpen={handleOpen} showSidebarButton={showSidebar} />
                  <Error />
                  <Container className="my-4" id="content" role="main">
                    <ErrorBoundary>
                      {show && <Component {...matchProps} />}
                    </ErrorBoundary>
                  </Container>
                </div>
              </div>
              <Footer />
              <Spinner />
            </ContextProvider>
          );
        }
        return (
          <Redirect
            to={{
              pathname: '/',
              state: {
                from: matchProps.location,
              },
            }}
          />
        );
      }}
    />
  );
}

export default PrivateLayout;
