import {
  React, useContext, useEffect, useState,
} from 'react';
import { Container } from 'react-bootstrap';
import Table from '../../../../components/Shared/Table';
import fields from './fieldsTable';
import history from '../../../../utils/history';
import Filtri from './filtri';
import richiestIdentitaService from '../../../../services/richiestaIdentitaService';
import checkStorage from '../../../../utils/checkSessionStorage';
import { setItem } from '../../../../utils/storage';
import { Context } from '../../../../utils/Context';
import TitlePage from '../../../../components/GestisciUtenti/TitoloPagina/index';
import EmptyState from '../../../../components/EmptyState';
import SpinnerBody from '../../../../components/Shared/SpinnerBody';

function GestioneRichiesteIdentita() {
  const initialFilters = {
    codi_k_tipo_stato: '',
    data_richiesta_from: '',
    data_richiesta_to: '',
  };
  const [context, setContext] = useContext(Context);
  const [filtri, setFiltri] = useState(initialFilters);
  const [isLoading, setIsLoading] = useState(true);
  const [richiesteIdentita, setRichiesteIdentita] = useState([]);
  const [stati, setStati] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    sizePerPage: 10,
    totalSize: 0,
  });

  async function getIpaByCodi(idIpa) {
    try {
      const response = await richiestIdentitaService
        .getIpaByCodiAdmin(idIpa);
      return response.des_amm;
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
    return null;
  }

  async function getRichiesteIdentita(offset, limit, page, filters) {
    try {
      const filterSession = filters || filtri;
      const response = await richiestIdentitaService
        .getRichiesteIdentita(filterSession, offset, limit);
      const richiesteUpdate = await Promise.all(response.data.map(
        async (el) => ({
          ...el,
          des_amm: await getIpaByCodi(el.codi_ipa),
        }),
      ));
      setRichiesteIdentita(richiesteUpdate);
      setPagination({
        page,
        sizePerPage: limit,
        totalSize: response.totalRowCount,
      });
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    } finally {
      setIsLoading(false);
    }
  }

  async function getStatiRichieste() {
    try {
      setStati(await richiestIdentitaService.getStati());
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  function onChangeFiltri(e) {
    const { value, id } = e.target;
    setFiltri({
      ...filtri,
      [id]: value,
    });
  }

  const handleTableChange = (type, { page, sizePerPage }) => {
    let currentIndex;

    if (page !== 0) {
      currentIndex = (page - 1) * sizePerPage;
    }
    getRichiesteIdentita(currentIndex, sizePerPage, page);
  };

  function refresh() {
    getRichiesteIdentita(0, 10, 1);
  }

  useEffect(() => {
    let offset = 0;
    const storage = checkStorage(filtri, setFiltri, setPagination);
    if (storage.page > 1) {
      handleTableChange(null, { page: storage.page, sizePerPage: pagination.sizePerPage });
      offset = (storage.page - 1) * pagination.sizePerPage;
    }
    getStatiRichieste();
    getRichiesteIdentita(offset, 10, storage.page, storage.filtri);
  }, []);

  useEffect(() => {
    setItem('page', pagination.page);
  }, [pagination.page]);

  return (
    isLoading
      ? (
        <SpinnerBody />
      )
      : (
        <Container className="p-0">
          <TitlePage
            title="Gestione richieste accreditamento"
            subtitle="È possibile gestire le richieste di accreditamento inviate dalle amministrazioni"
            showRefresh
            showIndietro
            functionIndietro={() => history.push('/area-riservata/amministrazione')}
            functionRefresh={refresh}
          />
          <Filtri
            filtri={filtri}
            onChangeFiltri={onChangeFiltri}
            getRichiesteIdentita={getRichiesteIdentita}
            stati={stati}
          />
          {richiesteIdentita.length > 0 ? (
            <Table
              title="Elenco richieste di accreditamento"
              data={richiesteIdentita}
              fields={fields()}
              pagination={pagination}
              onTableChange={handleTableChange}
              keyField="sequ_k_richiesta"
            />
          ) : (
            <EmptyState
              subtitle="Non sono presenti Richieste di Accreditamento"
              marginTop="4rem"
            />
          )}
        </Container>
      )
  );
}

export default GestioneRichiesteIdentita;
