const optionsBancaDatiUnica = [
  {
    label: 'Tutti',
    value: '',
  },
  {
    label: 'Si',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
];

export default optionsBancaDatiUnica;
