import { Container, ProgressBar } from 'react-bootstrap';
import Select from 'react-select';
import getVariantColor from '../../../../utils/getVariantColor';

const fieldsCompetenzeAutoAssessment = (
  competenzeList,
  optionsGrading,
  onChange,
  statoAssessment,
) => {
  // function per settare il valore corretto selezionato nella Select
  const getSelectValue = (row, type) => {
    const gradingSelected = competenzeList.filter(
      (el) => el.fk_sequ_competenza === row.fk_sequ_competenza,
    )[0]?.[type];
    return optionsGrading?.filter(
      (el) => el.codi_valore === gradingSelected,
    )?.[0];
  };

  // Function per settare le opzioni di grading corrette a seconda della competenza
  const optionsGradingByType = (type) => {
    const newList = optionsGrading.filter((grading) => grading.codi_tipo_competenza === type);
    return newList;
  };

  if (statoAssessment === 3) {
    return (
      [
        {
          dataField: 'comp_desc_codice',
          text: 'Codice',
          // sort: true,
          classes: 'column-data',
          headerStyle: () => ({ width: '10%' }),
        },
        {
          dataField: 'comp_desc_descrizione',
          text: 'Competenza',
          // sort: true,
          classes: 'column-data',
          headerStyle: () => ({ width: '50%' }),
        },
        {
          dataField: 'codi_grading_autovalutazione',
          text: 'Auto Valutazione',
          // sort: true,
          classes: 'column-data',
          headerStyle: () => ({ width: '20%' }),
          formatter: (e, row) => (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Select
                isDisabled
                styles={{ indicatorsContainer: (styles) => ({ ...styles, display: 'none' }) }}
                className="w-100"
                id="codi_grading_autovalutazione"
                name="codi_grading_autovalutazione"
                options={optionsGradingByType(row.comp_tipo_competenza)}
                getOptionLabel={(option) => (
                  <Container className="p-0">
                    {option.desc_titolo}
                    <ProgressBar
                      variant={
                        getVariantColor((
                          option.codi_valore / optionsGradingByType(row.comp_tipo_competenza).length
                        ) * 100)
                      }
                      label={option.codi_valore}
                      now={
                        (option.codi_valore / optionsGradingByType(row.comp_tipo_competenza).length)
                        * 100
                      }
                    />
                  </Container>
                )}
                getOptionValue={(option) => option.codi_valore}
                value={getSelectValue(row, 'codi_grading_autovalutazione')}
              />
            </div>
          ),
        },
        {
          dataField: 'codi_grading_assegnato',
          text: 'Etero Valutazione',
          // sort: true,
          classes: 'column-data',
          headerStyle: () => ({ width: '20%' }),
          formatter: (e, row) => (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Select
                isDisabled
                styles={{ indicatorsContainer: (styles) => ({ ...styles, display: 'none' }) }}
                className="w-100"
                id="codi_grading_assegnato"
                name="codi_grading_assegnato"
                options={optionsGradingByType(row.comp_tipo_competenza)}
                getOptionLabel={(option) => (
                  <Container className="p-0">
                    {option.desc_titolo}
                    <ProgressBar
                      variant={
                        getVariantColor((
                          option.codi_valore / optionsGradingByType(row.comp_tipo_competenza).length
                        ) * 100)
                      }
                      label={option.codi_valore}
                      now={
                        (option.codi_valore / optionsGradingByType(row.comp_tipo_competenza).length)
                        * 100
                      }
                    />
                  </Container>
                )}
                getOptionValue={(option) => option.codi_valore}
                value={getSelectValue(row, 'codi_grading_assegnato')}
              />
            </div>
          ),
        },
      ]
    );
  } return (
    [
      {
        dataField: 'comp_desc_codice',
        text: 'Codice',
        // sort: true,
        classes: 'column-data',
        headerStyle: () => ({ width: '10%' }),
      },
      {
        dataField: 'comp_desc_descrizione',
        text: 'Competenza',
        // sort: true,
        classes: 'column-data',
        headerStyle: () => ({ width: '65%' }),
      },
      {
        dataField: 'codi_grading_autovalutazione',
        text: 'Grading',
        // sort: true,
        classes: 'column-data',
        headerStyle: () => ({ width: '25%' }),
        formatter: (e, row) => (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Select
              isDisabled={(statoAssessment === 2 || statoAssessment === 3)}
              styles={{ indicatorsContainer: (styles) => ({ ...styles, display: (statoAssessment === 2 || statoAssessment === 3) && 'none' }) }}
              className="w-100"
              id="codi_grading_autovalutazione"
              name="codi_grading_autovalutazione"
              options={optionsGradingByType(row.comp_tipo_competenza)}
              placeholder="Associa un grading"
              getOptionLabel={(option) => (
                <Container className="p-0">
                  {option.desc_titolo}
                  <ProgressBar
                    variant={
                      getVariantColor(
                        (option.codi_valore / optionsGradingByType(row.comp_tipo_competenza).length)
                        * 100,
                      )
                    }
                    label={option.codi_valore}
                    now={
                      (option.codi_valore / optionsGradingByType(row.comp_tipo_competenza).length)
                      * 100
                    }
                  />
                </Container>
              )}
              getOptionValue={(option) => option.codi_valore}
              value={getSelectValue(row, 'codi_grading_autovalutazione')}
              onChange={(selectedOption) => { onChange(selectedOption, row); }}
            />
          </div>
        ),
      },
    ]
  );
};

export default fieldsCompetenzeAutoAssessment;
