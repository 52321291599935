import { useContext, useEffect, useState } from 'react';
import { Container, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import TitlePage from '../../../components/GestisciUtenti/TitoloPagina';
import { Context } from '../../../utils/Context';
import SistemaProfessionaleService from '../../../services/sistemaProfessionaleService';
import checkStorage from '../../../utils/checkSessionStorage';
import { getItem, setItem } from '../../../utils/storage';
import Table from '../../../components/Shared/Table';
import EmptyState from '../../../components/EmptyState';
import fieldsProfiliRuolo from './fieldsTable';
import history from '../../../utils/history';
import FiltriProfiliRuolo from './filtriProfiliRuolo';
import ModaleConferma from '../../../components/modali/ModaleConferma';
import ExportButtonExcel from '../../../components/ExportButton/ExportButtonExcel';
import logoAI from '../../../images/LogoAi.svg';
import { controlla } from '../../../utils/verificaPermessi';

function ProfiliRuolo() {
  const sistemaProfessionaleUrl = process.env.REACT_APP_SISTEMA_PROFESSIONALE_URL;
  const [context, setContext] = useContext(Context);
  const [profiliRuoloList, setProfiliRuoloList] = useState([]);
  const { sequ_k_comparto } = getItem('compartoSelected') ? JSON.parse(getItem('compartoSelected')) : 0;
  const [modalShowDelete, setModalShowDelete] = useState(false);
  const [profiloRuoloSelected, setProfiloRuoloSelected] = useState();
  const { idAmministrazione } = JSON.parse(getItem('identita'));

  const [stateProfiloRuolo, setStateProfiloRuolo] = useState({
    desc_attivita: '',
    fk_sequ_profilo_professionale: 0,
    fk_sequ_ambito_ruolo: 0,
  });

  const [filtri, setFiltri] = useState({});
  const [pagination, setPagination] = useState({
    page: 1,
    sizePerPage: 10,
    totalSize: 0,
  });

  // Function collegata al servizio per ottenere la lista dei Profili di Ruolo
  async function getProfiliRuolo(offset, limit, page, filters) {
    try {
      const filterSession = filters || filtri;
      const response = await SistemaProfessionaleService
        .getProfiliRuolo(filterSession, offset, limit);
      setProfiliRuoloList(response.rows);
      setPagination((prevPag) => ({
        ...prevPag,
        page,
        sizePerPage: limit,
        totalSize: response.totalRowCount,
      }));
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }
  const handleExport = () => {
    let params = '';
    if (filtri.desc_titolo) {
      params += `desc_titolo=${filtri.desc_titolo}&`;
    }
    if (filtri.desc_codice) {
      params += `desc_codice=${filtri.desc_codice}&`;
    }
    if (filtri.fk_sequ_comparto) {
      params += `fk_sequ_comparto=${filtri.fk_sequ_comparto}&`;
    }
    if (filtri.banca_dati_unica || filtri.banca_dati_unica === false) {
      params += `banca_dati_unica=${filtri.banca_dati_unica}&`;
    }
    if (filtri.sortField) {
      params += `sortField=${filtri.sortField}&`;
    }
    if (filtri.sortOrder) {
      params += `sortOrder=${filtri.sortOrder}&`;
    }
    const a = document.createElement('A');
    a.href = `${sistemaProfessionaleUrl}/profilo-ruolo/export/excel?${params}`;
    a.target = '_blank';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  // function attivata al cambio pagina della tabella
  const handleTableChange = (type, {
    page, sizePerPage, sortField, sortOrder,
  }) => {
    const newFilters = {
      ...filtri,
      fk_sequ_comparto: sequ_k_comparto,
      sortField: type === 'sort' ? sortField : 'desc_codice',
      sortOrder: type === 'sort' ? sortOrder : 'asc',
    };
    let currentIndex;
    if (page !== 0) {
      currentIndex = (page - 1) * sizePerPage;
    }
    getProfiliRuolo(
      currentIndex,
      sizePerPage,
      page,
      newFilters,
    );
  };

  // useEffect
  useEffect(() => {
    let offset = 0;
    const storage = checkStorage(filtri, setFiltri, setPagination);
    if (storage.page > 1) {
      handleTableChange(null, { page: storage.page, sizePerPage: pagination.sizePerPage });
      offset = (storage.page - 1) * pagination.sizePerPage;
    }
    getProfiliRuolo(
      offset,
      10,
      storage.page,
      { ...storage.filtri, fk_sequ_comparto: sequ_k_comparto },
    );
  }, []);

  // useEffect per settare nello storage la pagina corrente
  useEffect(() => {
    setItem('page', pagination.page);
  }, [pagination.page]);

  // useEffect per caricare i dati presenti nella tabella
  useEffect(() => {
    setFiltri({ ...filtri, fk_sequ_comparto: sequ_k_comparto });
    getProfiliRuolo(0, 10, 1, { ...filtri, fk_sequ_comparto: sequ_k_comparto });
    setStateProfiloRuolo({ ...stateProfiloRuolo, fk_sequ_comparto: sequ_k_comparto });
  }, [sequ_k_comparto]);

  // Function per effettuare il refresh della pagina
  function refresh() {
    getProfiliRuolo(0, 10, 1, { ...filtri, fk_sequ_comparto: sequ_k_comparto });
  }

  // function collegata al servizio di eliminazione di un profilo di ruolo
  async function eliminaProfiloRuolo(idProfiloRuolo) {
    SistemaProfessionaleService.eliminaProfiloRuolo(idProfiloRuolo)
      .then((res) => {
        setContext(() => ({
          ...context, openConferma: true, testo: res.message,
        }));
        setModalShowDelete(false);
        refresh();
      })
      .catch((err) => {
        setContext(() => ({
          ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
        }));
      });
  }

  // function per modificare lo stato che gestisce i filtri
  function onChangeFiltri(e) {
    const { value, name, checked } = e.target;
    if (name === 'banca_dati_unica') {
      filtri[name] = checked;
    } else {
      setFiltri({
        ...filtri,
        [name]: value,
      });
    }
  }

  // Function attivata al click dell'icona di visualizzazione
  const onView = (idProfiloRuolo) => {
    history.push(`/area-riservata/profili-ruolo/${idProfiloRuolo}`);
  };

  // Function attivata al click dell'icona di modifica
  const onModify = (idProfiloRuolo) => {
    history.push(`/area-riservata/profili-ruolo/${idProfiloRuolo}`, { isModify: true });
  };

  // Function attivata al click dell'icona di eliminazione
  const onDelete = (profiloRuolo) => {
    setModalShowDelete(true);
    setProfiloRuoloSelected(profiloRuolo);
  };

  return (
    <>
      <Container className="p-0">
        <TitlePage
          title="Profili di Ruolo"
          subtitle={(
            <div className="subtitle-profili-ruolo">
              <p>
                Costituiscono il cuore del Sistema Professionale.
                I profili descrivono tipologie di job omogenee,
                caratterizzate da competenze professionali di base e comportamentali comuni.
                I Profili di Ruolo scaturiscono dalla declinazione di un Profilo Professionale
                <Link to="/area-riservata/profili-professionali"> (vai ai profili professionali) </Link>
                in uno specifico Ambito di ruolo
                <Link to="/area-riservata/ambiti-ruolo"> (vai a ambiti di ruolo).</Link>
              </p>
            </div>
          )}
          showButtonExport
          buttonExport={(
            <ExportButtonExcel functionExport={handleExport} />
          )}
          showRefresh
          functionRefresh={refresh}
          showAI={controlla('X51')}
          variantAI="outline-AI"
          functionAI={() => history.push('/area-riservata/profili-ruolo/ricerca-ai')}
          imageAI={<Image src={logoAI} width="22.5" height="22.5" />}
          titleAI="RICERCA con AI"
        />
        <FiltriProfiliRuolo
          filtri={filtri}
          setFiltri={setFiltri}
          onChangeFiltri={onChangeFiltri}
          getProfiliRuolo={getProfiliRuolo}
        />
        {profiliRuoloList.length > 0 ? (
          <Table
            data={profiliRuoloList}
            fields={fieldsProfiliRuolo(onView, onModify, onDelete, idAmministrazione)}
            pagination={pagination}
            onTableChange={handleTableChange}
            keyField="profili_ruolo_list"
          />
        ) : (
          <EmptyState marginTop="3rem" />
        )}
      </Container>
      <ModaleConferma
        labelBottone="Elimina"
        coloreAzione="primary"
        title="Sei sicuro di procedere?"
        body={`L'operazione eliminerà il profilo professionale ${profiloRuoloSelected?.codice}`}
        show={modalShowDelete}
        handleClose={() => setModalShowDelete(false)}
        handleOk={() => eliminaProfiloRuolo(profiloRuoloSelected.id)}
      />
    </>
  );
}

export default ProfiliRuolo;
