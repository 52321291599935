const themeColors = {
  primary: '#0066cc',
  secondary: '#003183',
  success: '#28a745',
  danger: '#dc3545',
  grey: '#6c757d',
  dark: '#111',
  warning: '#f5c000',
  AI: '#ba55d3',
};

export default themeColors;
