/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TitlePage from '../../../components/GestisciUtenti/TitoloPagina';
import { client } from '../../../utils/matrix';
import { formatoDataEOra } from '../../../utils/formatData';

function Notifiche() {
  const [eventoUltimaLettura, setEventoUltimaLettura] = useState();
  const [rooms, setRooms] = useState([]);
  const [roomNotifiche, setRoomNotifiche] = useState();
  const [moreEvents, setMoreEvents] = useState(true);

  // useEffect
  useEffect(() => {
    client?.on('event', () => {
      setRooms(client?.getRooms());
    });

    const roomNotifications = client?.getRooms().filter((el) => el.name === 'Notifiche')?.[0];
    setRoomNotifiche(roomNotifications);

    // Verifica se l'utente fa parte della room dedicata alle notifiche, altrimenti lo fa entrare
    if (roomNotifications?.selfMembership === 'invite') {
      client?.joinRoom(roomNotifications?.roomId, { syncRoom: true });
    }

    // Salvataggio dell'ultima notifica di lettura per impostare il componente grafico
    // setEventoUltimaLettura(roomNotifications?.receipts['m.read'][client?.getUserId()]?.eventId);

    // Invio notifica di lettura
    setTimeout(() => {
      const eventsLength = roomNotifications?.getLiveTimeline().getEvents().length;
      if (roomNotifications) {
        client?.sendReadReceipt(
          roomNotifications?.getLiveTimeline()?.getEvents()?.[eventsLength - 1],
        );
      }
    }, 1000);
  }, []);

  // Funzione di scrollBack per caricare le notifiche meno recenti
  async function scrollBack() {
    const roomAggiornata = await client.scrollback(client.getRoom(roomNotifiche.roomId), 5);
    setMoreEvents(!!roomAggiornata?.oldState?.paginationToken);
    setRooms(client?.getRooms());
  }

  return (
    <>
      <TitlePage
        title="Notifiche"
        subtitle="Di seguito puoi trovare le notifiche."
      />
      {roomNotifiche && roomNotifiche
        .getLiveTimeline()
        .getEvents()
        .filter((el) => el.getContent()?.body?.includes('"'))
        .reverse()
        .map((el, index) => (
          <div key={el.getId()}>
            {el.getId() === eventoUltimaLettura && index !== 0 ? <hr className="readMarker" /> : null}
            <Card className="mb-2">
              <Card.Header as="h5">Notifica</Card.Header>
              <Card.Body className="d-flex justify-content-between">
                <div style={{ fontStyle: 'italic' }}>
                  <FontAwesomeIcon
                    aria-hidden="true"
                    icon={['fas', 'comment']}
                    size="lg"
                    id="notifica-icon"
                    style={{ cursor: 'text', marginRight: '0.5rem' }}
                  />
                  {el.getContent().body.replaceAll('"', '')}
                </div>
                <div className="fw-bold text-muted small">
                  {formatoDataEOra(el.getDate())}
                </div>
              </Card.Body>
            </Card>
          </div>
        ))}
      <div className="text-center">
        {moreEvents
          ? (
            <Button onClick={scrollBack}>
              Carica altre notifiche
            </Button>
          )
          : null }
      </div>
    </>
  );
}

export default Notifiche;
