import React, { useContext, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import TitlePage from '../../../../../components/GestisciUtenti/TitoloPagina';
import history from '../../../../../utils/history';
import { Context } from '../../../../../utils/Context';
import Table from '../../../../../components/Shared/Table';
import EmptyState from '../../../../../components/EmptyState';
import fieldsListaDipendenti from '../../fields/fieldsListaDipendenti';
import AssessmentService from '../../../../../services/assessmentService';
import checkStorage from '../../../../../utils/checkSessionStorage';

function ListaUtentiSchede() {
  const [context, setContext] = useContext(Context);
  const [isLoading, setIsLoading] = useState(true);
  const [dipendentiList, setDipendentiList] = useState([]);
  const [filtri, setFiltri] = useState({});
  const [pagination, setPagination] = useState({
    page: 1,
    sizePerPage: 10,
    totalSize: 0,
  });

  // Function collegata al servizio per recuperare la lista dei dipendenti
  async function getDipendentiList(offset, limit, page, filters) {
    try {
      const filterSession = filters || filtri;
      const response = await AssessmentService
        .getDipendenti(filterSession, offset, limit);
      setDipendentiList(response.data);
      setPagination((prevPag) => ({
        ...prevPag,
        page,
        sizePerPage: limit,
        totalSize: response.totalRowCount,
      }));
      setIsLoading(false);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // Function attivata al cambio pagina della tabella
  const handleTableChange = (type, {
    page, sizePerPage, sortField, sortOrder,
  }) => {
    const newFilters = {
      ...filtri,
      sortField: type === 'sort' ? sortField : 'desc_nome',
      sortOrder: type === 'sort' ? sortOrder : 'asc',
    };
    let currentIndex;
    if (page !== 0) {
      currentIndex = (page - 1) * sizePerPage;
    }
    getDipendentiList(
      currentIndex,
      sizePerPage,
      page,
      newFilters,
    );
  };

  // Function per la gestione dell'azione di visualizza della tabella
  const onView = (idDipendente, nomeDipendente, cognomeDipendente) => {
    const isView = true;
    history.push(
      `/area-riservata/scheda-competenza/${idDipendente}`,
      {
        isView, nomeDipendente, cognomeDipendente,
      },
    );
  };

  useEffect(() => {
    let offset = 0;
    const storage = checkStorage(filtri, setFiltri, setPagination);
    if (storage.page > 1) {
      handleTableChange(null, { page: storage.page, sizePerPage: pagination.sizePerPage });
      offset = (storage.page - 1) * pagination.sizePerPage;
    }
    getDipendentiList(
      offset,
      10,
      storage.page,
      { ...storage.filtri },
    );
  }, []);

  return (
    isLoading
      ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
          <div className="inline-block align-middle">
            <h1 className="font-weight-normal lead" id="desc">Caricamento...</h1>
          </div>
        </div>
      )
      : (
        <Container className="p-0">
          <TitlePage
            title="Schede delle Competenze"
            subtitle="Visualizzazione delle Schede delle Competenze delle
            identità Dipendente associate alla tua identità Responsabile."
            showRefresh
            functionRefresh={() => getDipendentiList(0, 10, 1, filtri)}
          />
          {dipendentiList.length > 0 ? (
            <Table
              data={dipendentiList}
              fields={fieldsListaDipendenti(onView)}
              pagination={pagination}
              onTableChange={handleTableChange}
              keyField="fk_sequ_utente_trusted"
              campoSort="desc_nome"
            />
          ) : (
            <EmptyState
              title="Attenzione"
              subtitle="Non è possibile accedere a questa pagina in quanto non ti
              è stato ancora assegnato un'identità Dipendente."
              marginBottom="3rem"
              marginTop="3rem"
            />
          )}
        </Container>
      )
  );
}

export default ListaUtentiSchede;
