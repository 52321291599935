const entiData = [
  {
    id: 1,
    codice: 'age',
    titolo: 'Agenzia delle Entrate',
  },
  {
    id: 2,
    codice: 'c_g478',
    titolo: 'Comune di Perugia',
  },
  {
    id: 3,
    codice: 'cmna',
    titolo: "Citta' Metropolitana di Napoli",
  },
  {
    id: 4,
    codice: 'inps',
    titolo: 'Istituto Nazionale Previdenza Sociale - INPS',
  },
  {
    id: 5,
    codice: 'm_it',
    titolo: "Ministero dell'Interno",
  },
  {
    id: 6,
    codice: 'p_LU',
    titolo: 'Provincia di Lucca',
  },
  {
    id: 7,
    codice: 'p_TN',
    titolo: 'Provincia Autonoma di Trento',
  },
  {
    id: 8,
    codice: 'r_lazio',
    titolo: 'Regione Lazio',
  },
  {
    id: 9,
    codice: 'r_piemon',
    titolo: 'Regione Piemonte',
  },
  {
    id: 10,
    codice: 'udsa_',
    titolo: "UNIVERSITA DEGLI STUDI DELL'AQUILA'",
  },
  {
    id: 11,
    codice: 'aci',
    titolo: "Automobile Club d'Italia",
  },
  {
    id: 12,
    codice: 'cmba',
    titolo: "Citta' Metropolitana di Bari",
  },
  {
    id: 13,
    codice: 'cmdca',
    titolo: "Citta' Metropolitana di Cagliari",
  },
  {
    id: 14,
    codice: 'cmge',
    titolo: "Citta' Metropolitana di Genova",
  },
  {
    id: 15,
    codice: 'cmto',
    titolo: "Citta' Metropolitana di Torino",
  },
  {
    id: 16,
    codice: 'cnr',
    titolo: 'Consiglio Nazionale delle Ricerche - CNR',
  },
  {
    id: 17,
    codice: 'c_a944',
    titolo: 'Comune di Bologna',
  },
  {
    id: 18,
    codice: 'c_d969',
    titolo: 'Comune di Genova',
  },
  {
    id: 19,
    codice: 'c_f205',
    titolo: 'Comune di Milano',
  },
  {
    id: 20,
    codice: 'c_g273',
    titolo: 'Comune di Palermo',
  },
  {
    id: 21,
    codice: 'c_l736',
    titolo: 'Comune di Venezia',
  },
  {
    id: 22,
    codice: 'c_l781',
    titolo: 'Comune di Verona',
  },
  {
    id: 23,
    codice: 'enac',
    titolo: "Ente Nazionale per l'Aviazione Civile - ENAC",
  },
  {
    id: 24,
    codice: 'm_amte',
    titolo: "Ministero dell'Ambiente e della Sicurezza Energetica",
  },
  {
    id: 25,
    codice: 'm_lps',
    titolo: 'Ministero del Lavoro e delle Politiche Sociali',
  },
  {
    id: 26,
    codice: 'UNITO',
    titolo: "Universita' degli Studi di Torino",
  },
  {
    id: 27,
    codice: 'unive',
    titolo: "Universita' Ca' Foscari di Venezia",
  },
  {
    id: 28,
    codice: 'uni_gdch',
    titolo: "Universita' Degli Studi G. d'Annunzio di Chieti",
  },
  {
    id: 29,
    codice: 'un_lsrm',
    titolo: "Universita' degli Studi di Roma La Sapienza",
  },
  {
    id: 30,
    codice: 'USBre',
    titolo: "UNIVERSITA' DEGLI STUDI DI BRESCIA",
  },
  {
    id: 31,
    codice: 'USFir',
    titolo: "Universita' degli Studi di Firenze",
  },
  {
    id: 32,
    codice: 'USIns',
    titolo: "Universita' degli Studi dell 'Insubria",
  },
  {
    id: 33,
    codice: 'USMRE',
    titolo: "Universita' degli Studi di Modena e Reggio Emilia",
  },
  {
    id: 34,
    codice: 'USSan',
    titolo: "Universita' degli Studi del Sannio",
  },
  {
    id: 35,
    codice: 'assa_042',
    titolo: "AMAP - Agenzia per l'innovazione nel settore agroalimentare e della pesca - Marche Agricoltura Pesca -",
  },
  {
    id: 36,
    codice: 'arpa_mi',
    titolo: "Agenzia Regionale per la Protezione dell'Ambiente della Lombardia",
  },
  {
    id: 37,
    codice: 'arpam',
    titolo: "Agenzia Regionale per la Protezione dell'Ambiente delle Marche",
  },
  {
    id: 38,
    codice: 'r_basili',
    titolo: 'Regione Basilicata',
  },
  {
    id: 39,
    codice: 'r_campan',
    titolo: 'Regione Campania',
  },
  {
    id: 40,
    codice: 'r_emiro',
    titolo: 'Regione Emilia-Romagna',
  },
  {
    id: 41,
    codice: 'r_liguri',
    titolo: 'Regione Liguria',
  },
  {
    id: 42,
    codice: 'r_puglia',
    titolo: 'Regione Puglia',
  },
  {
    id: 43,
    codice: 'r_sardeg',
    titolo: 'Regione Autonoma della Sardegna',
  },
  {
    id: 44,
    codice: 'r_sicili',
    titolo: 'Regione Siciliana',
  },
  {
    id: 45,
    codice: 'r_toscan',
    titolo: 'Regione Toscana',
  },
  {
    id: 46,
    codice: 'r_veneto',
    titolo: 'Regione del Veneto',
  },
  {
    id: 47,
    codice: 'p_aq',
    titolo: "Provincia di L'Aquila",
  },
  {
    id: 48,
    codice: 'p_bi',
    titolo: 'Provincia di Biella',
  },
  {
    id: 49,
    codice: 'p_cb',
    titolo: 'Provincia di Campobasso',
  },
  {
    id: 50,
    codice: 'p_fc',
    titolo: "Provincia di Forli' Cesena",
  },
  {
    id: 51,
    codice: 'p_fg',
    titolo: 'Provincia di Foggia',
  },
  {
    id: 52,
    codice: 'p_FM',
    titolo: 'Provincia di Fermo',
  },
  {
    id: 53,
    codice: 'p_mb',
    titolo: 'Provincia di Monza e della Brianza',
  },
  {
    id: 54,
    codice: 'p_pr',
    titolo: 'Provincia di Parma',
  },
  {
    id: 55,
    codice: 'p_sa',
    titolo: 'Provincia di Salerno',
  },
  {
    id: 56,
    codice: 'p_SS',
    titolo: 'Provincia di Sassari',
  },
  {
    id: 57,
    codice: 'p_ta',
    titolo: 'Provincia di Taranto',
  },
  {
    id: 58,
    codice: 'p_tv',
    titolo: 'Provincia di Treviso',
  },
];

export default entiData;
