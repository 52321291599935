const areeContrattualiFields = () => (
  [
    {
      dataField: 'Codice',
      text: 'Codice',
      classes: 'column-data',
      headerStyle: () => ({ width: '20%' }),
    },
    {
      dataField: 'Area Contrattuale',
      text: 'Area Contrattuale',
      classes: 'column-data',
      headerStyle: () => ({ width: '50%' }),
    },
    {
      dataField: 'Comparto',
      text: 'Comparto',
      classes: 'column-data',
      headerStyle: () => ({ width: '30%' }),
    },
  ]
);

export default areeContrattualiFields;
