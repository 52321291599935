import { Accordion, Button, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect } from 'react';
import EmptyState from '../../EmptyState';
import Table from '../../Shared/Table';
import { setItem } from '../../../utils/storage';
import checkStorage from '../../../utils/checkSessionStorage';

function AccordionTable({
  eventKey,
  onClick,
  title,
  data,
  fieldsData,
  filtri,
  setFiltri,
  setPagination,
  filtriBody,
  pagination,
  keyField,
  handleExportCsv,
  handleExportJson,
  handlePrint,
  functionTable,
  sequ_k_comparto,
  typeStatistica,
  isPrintable = false,
  isExportable = false,
}) {
  const handleTableChange = (type, { page, sizePerPage }) => {
    let currentIndex;
    if (page !== 0) {
      currentIndex = (page - 1) * sizePerPage;
    }
    functionTable(
      sequ_k_comparto,
      typeStatistica,
      currentIndex,
      sizePerPage,
      page,
      filtri,
    );
  };

  const handlePageChange = () => {
    let offset = 0;
    const storage = checkStorage(filtri, setFiltri, setPagination);
    if (storage.page > 1) {
      handleTableChange(null, { page: parseInt(storage.page, 10), sizePerPage: parseInt(pagination.sizePerPage, 10) });
      offset = (parseInt(storage.page, 10) - 1) * parseInt(pagination.sizePerPage, 10);
    }
    functionTable(
      sequ_k_comparto,
      typeStatistica,
      offset,
      10,
      parseInt(storage.page, 10),
      storage.filtri,
    );
  };

  useEffect(() => {
    setItem('page', parseInt(pagination.page, 10));
  }, [pagination.page]);

  return (
    <Accordion.Item
      eventKey={eventKey}
      className="mb-3 border accordionItem"
    >
      <Accordion.Header
        onClick={() => {
          setItem('page', 1);
          setItem('filtri', JSON.stringify({
            area_contrattuale: '',
            dimensione_professionale: '',
            famiglia_professionale: '',
            ambito_ruolo: '',
          }));
          setFiltri({
            area_contrattuale: '',
            dimensione_professionale: '',
            famiglia_professionale: '',
            ambito_ruolo: '',
          });
          onClick();
          handlePageChange();
        }}
      >
        {title}
      </Accordion.Header>
      <Accordion.Body
        className="backgroundColorInterno"
      >
        <>
          {data?.length > 0 && (
            <div style={{ display: 'flex', gap: '10px', margin: '5px' }}>
              {isPrintable && (
                <Button
                  variant="primary"
                  size="sm"
                  onClick={handlePrint}
                >
                  <FontAwesomeIcon aria-hidden="true" icon="print" size="sm" />
                  {' '}
                  Stampa
                </Button>
              )}
              {isExportable && (
                <Dropdown>
                  <Dropdown.Toggle variant="primary" className="text-white" size="sm">
                    <span>
                      <FontAwesomeIcon aria-hidden="true" icon="file-arrow-down" size="sm" />
                      {' '}
                      ESPORTA
                    </span>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item onClick={handleExportCsv}>Formato .csv</Dropdown.Item>
                    <Dropdown.Item onClick={handleExportJson}>Formato .json</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </div>
          )}
          {filtriBody}
          {data?.length > 0 ? (
            <Table
              data={data}
              fields={fieldsData}
              pagination={pagination}
              onTableChange={handleTableChange}
              keyField={keyField}
            />
          ) : (
            <EmptyState
              subtitle="Nessun dato disponibile da visualizzare."
              marginTop="3rem"
            />
          )}
        </>
      </Accordion.Body>
    </Accordion.Item>
  );
}

export default AccordionTable;
