import React, { useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import { getItem } from '../../utils/storage';
import SistemaProfessionaleService from '../../services/sistemaProfessionaleService';
import optionsTorta from './optionsTortaCompetenze';
import optionsInstogramma from './optionsIstogrammaProfili';

function ReportSistemaProfessionale() {
  const [profiliProfessionali, setProfiliProfessionali] = useState([]);
  const [profiliRuolo, setProfiliRuolo] = useState([]);
  const [competenze, setCompetenze] = useState([]);
  const [compPerTip, setCompPerTip] = useState([]);
  const [profPerAmb, setProfPerAmb] = useState([]);
  const comparto = JSON.parse(getItem('compartoSelected'))?.sequ_k_comparto;
  const { idAmministrazione } = JSON.parse(getItem('identita'));
  async function fetchReportData() {
    try {
      setProfiliProfessionali(await SistemaProfessionaleService
        .getReportProfiliProfessionali(comparto));
      setProfiliRuolo(await SistemaProfessionaleService.getReportProfiliRuolo(comparto));
      setCompetenze(await SistemaProfessionaleService.getReportCompetenze());
      setCompPerTip(await SistemaProfessionaleService.getReportCompetenzePerTipologia());
      setProfPerAmb(await SistemaProfessionaleService.getReportProfiliRuoloPerAmbito(comparto));
    } catch (error) {
      console.error('Errore durante il recupero dei dati del report:', error);
    }
  }
  useEffect(() => {
    fetchReportData();
  }, [comparto]);

  return (
    <div className="container mt-5">
      <div className="row mt-5 mb-5">
        <div className="h5 mb-2">Banca Dati Unica</div>
        {profiliProfessionali?.dfp && (
          <div className="col-md-4 mb-3">
            <div className="card bg-primary text-center text-white">
              <div className="card-body">
                <h4 className="card-title font-weight-bold">Profili Professionali</h4>
                <h1 className="card-text display-4">{profiliProfessionali?.dfp}</h1>
              </div>
            </div>
          </div>
        )}
        {profiliRuolo?.dfp && (
        <div className="col-md-4 mb-3">
          <div className="card bg-primary text-center text-white">
            <div className="card-body">
              <h4 className="card-title font-weight-bold">Profili di Ruolo</h4>
              <h1 className="card-text display-4">{profiliRuolo?.dfp}</h1>
            </div>
          </div>
        </div>
        )}
        {competenze?.dfp && (
        <div className="col-md-4 mb-3">
          <div className="card bg-primary text-center text-white">
            <div className="card-body">
              <h4 className="card-title font-weight-bold">Competenze</h4>
              <h1 className="card-text display-4">{competenze?.dfp}</h1>
            </div>
          </div>
        </div>
        )}
      </div>
      <div className="row mt-5 mb-5 justify-content-center">
        <div className="h5 mb-2">
          {idAmministrazione ? 'Banca Dati Amministrazione' : 'Banca Dati Amministrazioni'}
        </div>
        {profiliProfessionali?.dfp && (
          <div className="col-md-4 mb-3">
            <div className="card bg-primary text-center text-white">
              <div className="card-body">
                <h4 className="card-title font-weight-bold">Profili Professionali</h4>
                <h1 className="card-text display-4">{profiliProfessionali?.other}</h1>
              </div>
            </div>
          </div>
        )}
        {profiliRuolo?.dfp && (
        <div className="col-md-4 mb-3">
          <div className="card bg-primary text-center text-white">
            <div className="card-body">
              <h4 className="card-title font-weight-bold">
                Profili di Ruolo
              </h4>
              <h1 className="card-text display-4">{profiliRuolo?.other}</h1>
            </div>
          </div>
        </div>
        )}
      </div>
      {!!compPerTip.length && (
      <div className="row mt-5 mb-5">
        <ReactECharts
          option={optionsTorta(compPerTip)}
          style={{ height: 400 }}
        />
      </div>
      )}
      {!!profPerAmb.length && (
      <div className="row mt-5 mb-5">
        <ReactECharts
          option={optionsInstogramma(profPerAmb)}
          style={{ height: 400 }}
        />
      </div>
      )}
    </div>
  );
}

export default ReportSistemaProfessionale;
