/* eslint-disable no-lonely-if */
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { Button } from 'react-bootstrap';
import Table from '../../../../../components/Shared/Table';
import fieldsScelteCopertura from '../fields/fieldsScelteCopertura';
import ModaleConferma from '../../../../../components/modali/ModaleConferma';
import formatCentinaia from '../../../../../utils/formatCentinaia';

function ScelteCoperturaPolicyMaker({
  tableData,
  setTableData,
  strutturaSelected,
  setStrutturaSelected,
  processoState,
  setProcessoState,
  blockTable,
  setBlockTable,
}) {
  const { state } = useLocation();
  const [totaleCostoRichiesta, setTotaleCostoRichiesta] = useState(0);
  const [totaleUnitaAllocate, setTotaleUnitaAllocate] = useState(0);
  const [totaleUnitaCessazione, setTotaleUnitaCessazione] = useState(0);
  const [totaleUnitaRichieste, setTotaleUnitaRichieste] = useState(0);
  const [showModalConfirm, setShowModalConfirm] = useState(false);

  // PER OTTENERE I DATI DA INSERIRE NELLA TABELLA E NELLA PAGINA
  async function getTableData() {
    setStrutturaSelected(state.row);
    setTableData(state.row.profili);
    setTotaleCostoRichiesta(state.row.totale_costo_richiesta);
    let newTotaleUnitaAllocate = 0;
    let newTotaleUnitaCessazione = 0;
    let newTotaleUnitaRichieste = 0;
    state.row.profili.forEach((profilo) => {
      newTotaleUnitaAllocate += profilo.unita_allocate;
      newTotaleUnitaCessazione += profilo.unita_cessazione;
      newTotaleUnitaRichieste += profilo.unita_richieste;
    });
    setTotaleUnitaAllocate(newTotaleUnitaAllocate);
    setTotaleUnitaCessazione(newTotaleUnitaCessazione);
    setTotaleUnitaRichieste(newTotaleUnitaRichieste);
  }

  // PER GESTIRE IL CAMBIAMENTO DEI DATI ALL'INTERNO DELLA TABELLA
  const onChange = (e, row) => {
    const { name, value } = e.target;

    const profUpdate = tableData.find(
      (data) => data.desc_codice === row.desc_codice,
    ).scelte_copertura;
    let scelteUpdate = [];
    if (profUpdate.length === 0) {
      scelteUpdate = [{
        ordine: parseInt(name, 10),
        unita: parseInt(value, 10) || 0,
      }];
    } else {
      if (profUpdate.find((el) => el.ordine === parseInt(name, 10))) {
        scelteUpdate = profUpdate.map(
          (scelta) => {
            if (scelta.ordine === parseInt(name, 10)) {
              return ({
                ordine: parseInt(name, 10),
                unita: parseInt(value, 10) || 0,
              });
            }
            return scelta;
          },
        );
      } else {
        scelteUpdate = [
          ...profUpdate,
          {
            ordine: parseInt(name, 10),
            unita: parseInt(value, 10) || 0,
          }];
      }
    }
    setTableData((prevState) => prevState.map(
      (el) => {
        if (el.desc_codice === row.desc_codice) {
          return ({
            ...el,
            scelte_copertura: scelteUpdate,
          });
        }
        return el;
      },
    ));
    setBlockTable(false);
  };

  // PER CALCOLARE IL COSTO TOTALE DELLA RIGA
  const calcolaCostoInTable = (row) => {
    const totale = row.scelte_copertura
      .reduce(
        (acc, item) => (
          acc + (
            (item.ordine <= 4)
              ? item.unita * row.costo_annuale
              : (item.ordine === 5) ? item.unita * row.differenza
                : 0
          )
        ),
        0,
      );

    return totale;
  };

  // PER CALCOLARE IL TOTALE DEI COSTI DELLA TABELLA
  const calcolaTotaleCostiInTable = () => {
    const totale = tableData
      .reduce(
        (acc, item) => acc + (
          calcolaCostoInTable(item)
        ),
        0,
      );
    return totale;
  };

  // PER CALCOLARE IL TOTALE DLLE UNITA' PROGRAMMATE
  const calcolaTotaleUnitaProgrammate = () => {
    const totale = tableData.reduce(
      (acc, item) => acc + (item.unita_programmate),
      0,
    );
    return `${totale.toString()}`;
  };

  // PER CALCOLARE LE UNITA' PROGRAMMATE DI UNA RIGA DI TABELLA
  const calcolaUnitaProgrammate = (row, isUpdatingState) => {
    const unitaProgrammate = row.scelte_copertura.reduce(
      (acc, item) => acc + (item.unita),
      0,
    );
    if (isUpdatingState) {
      setTableData((prevState) => prevState.map(
        (el) => (el.desc_codice === row.desc_codice ? {
          ...el,
          unita_programmate: unitaProgrammate,
        } : el),
      ));
    }
    return unitaProgrammate;
  };

  const handleConfirmScelta = () => {
    const strutturaAggiornata = {
      ...strutturaSelected,
      profili: tableData,
      totale_costo_copertura: calcolaTotaleCostiInTable(),
    };
    const struttureUpdated = processoState.strutture_organizzative.map(
      (so) => (so.struttura_organizzativa
        === strutturaSelected.struttura_organizzativa ? strutturaAggiornata : so),
    );
    setProcessoState((prevState) => ({
      ...prevState,
      // stato_processo: `${nomiProcessi[1]} - ${passiAggiungi[3]}`,
      // fase: 4,
      strutture_organizzative: struttureUpdated,
    }));
    // aggiorno lo stato dei dati della table di bootstrap per bloccarla
    const newTableDataBlocked = tableData.map((row) => ({ ...row, tabellaPoliMakerBlocked: true }));
    setTableData(newTableDataBlocked);
    setBlockTable(true);
    setShowModalConfirm(false);
  };

  useEffect(() => {
    getTableData();
  }, []);

  return (
    <>
      <div className="">
        <h4 className="fw-bold">{strutturaSelected.struttura_organizzativa}</h4>
        <div className="d-flex flex-column gap-4 mb-5">
          <table className="table-border-thin">
            <tbody>
              <tr>
                <td className="border border-dark border-2 p-1 fw-bold">Potenziali disponibilità di spesa (PDS)</td>
                <td className="border border-dark border-2 p-1 fw-bold">Costo totale della richiesta</td>
                <td className="border border-dark border-2 p-1 fw-bold">Scostamento richiesta</td>
                <td className="border border-dark border-2 p-1 fw-bold">Costo totale delle opzioni di copertura scelte</td>
                <td className="border border-dark border-2 p-1 fw-bold">Disponibilità residua</td>
              </tr>
              <tr>
                <td style={{ minWidth: '6rem' }} className="border border-dark border-2 p-1 text-center fw-bold">
                  {formatCentinaia(strutturaSelected.pds)}
                  &euro;
                </td>
                <td style={{ minWidth: '6rem' }} className="border border-dark border-2 p-1 text-center fw-bold">
                  {formatCentinaia(totaleCostoRichiesta)}
                  &euro;
                </td>
                <td style={{ minWidth: '6rem' }} className="border border-dark border-2 p-1 text-center fw-bold">
                  <span className="text-start">
                    {((strutturaSelected.pds - totaleCostoRichiesta) > 0) ? (<FontAwesomeIcon icon="fa-solid fa-up-long" style={{ color: '#198754' }} />)
                      : ((strutturaSelected.pds - totaleCostoRichiesta) < 0) ? (<FontAwesomeIcon icon="fa-solid fa-down-long" style={{ color: '#dc3545' }} />)
                        : (<FontAwesomeIcon icon="fa-solid fa-equals" style={{ color: '#3c9dff' }} />) }
                  </span>
                  <span className="text-center">
                    {formatCentinaia(strutturaSelected.pds - totaleCostoRichiesta)}
                    €
                  </span>
                </td>
                <td style={{ minWidth: '6rem' }} className="border border-dark border-2 p-1 text-center fw-bold">
                  {formatCentinaia(calcolaTotaleCostiInTable())}
                  &euro;
                </td>
                <td style={{ minWidth: '6rem' }} className="border border-dark border-2 p-1 text-center fw-bold">
                  <span className="text-start">
                    {((strutturaSelected.pds - calcolaTotaleCostiInTable()) > 0) ? (<FontAwesomeIcon icon="fa-solid fa-up-long" style={{ color: '#198754' }} />)
                      : ((strutturaSelected.pds - calcolaTotaleCostiInTable()) < 0) ? (<FontAwesomeIcon icon="fa-solid fa-down-long" style={{ color: '#dc3545' }} />)
                        : (<FontAwesomeIcon icon="fa-solid fa-equals" style={{ color: '#3c9dff' }} />) }
                  </span>
                  <span className="text-center">
                    {formatCentinaia(strutturaSelected.pds - calcolaTotaleCostiInTable())}
                    &euro;
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          <table className="table-border-thin">
            <tbody>
              <tr>
                <td className="border border-dark border-2 p-1 fw-bold">Unità di personale allocate presso la struttura</td>
                <td className="border border-dark border-2 p-1 fw-bold">Unità di personale in uscita nel triennio oggetto di programmmazione</td>
                <td className="border border-dark border-2 p-1 fw-bold">Unità di personale richieste</td>
                <td className="border border-dark border-2 p-1 fw-bold">Unità programmate</td>
              </tr>
              <tr>
                <td style={{ minWidth: '6rem' }} className="border border-dark border-2 p-1 text-center fw-bold">{totaleUnitaAllocate}</td>
                <td style={{ minWidth: '6rem' }} className="border border-dark border-2 p-1 text-center fw-bold">{totaleUnitaCessazione}</td>
                <td style={{ minWidth: '6rem' }} className="border border-dark border-2 p-1 text-center fw-bold">{totaleUnitaRichieste}</td>
                <td style={{ minWidth: '6rem' }} className="border border-dark border-2 p-1 text-center fw-bold">{calcolaTotaleUnitaProgrammate()}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="mt-5">
        <h4 className="fw-bold mb-3">Profili Richiesti</h4>
        <Button
          className="me-2"
          size="sm"
          onClick={() => setShowModalConfirm(true)}
          title="Conferma Scelta dei Profili"
          disabled={blockTable}
        >
          <FontAwesomeIcon aria-hidden="true" icon="check" />
          &nbsp;
          CONFERMA SCELTA
        </Button>
        <div className="overflow-x-auto">
          <Table
            data={tableData}
            fields={fieldsScelteCopertura(
              onChange,
              totaleUnitaAllocate,
              totaleUnitaCessazione,
              totaleUnitaRichieste,
              calcolaCostoInTable,
              calcolaTotaleCostiInTable,
              calcolaTotaleUnitaProgrammate,
              tableData,
              calcolaUnitaProgrammate,
              processoState,
            )}
            keyField="_id"
          />
        </div>
      </div>

      <ModaleConferma
        title="Conferma inserimento dei Dati"
        body="Confermi l'inserimento dei dati? Verifica
        l'inserimento di tutti i campi disponibili prima di procedere."
        labelBottone="CONFERMA"
        coloreAzione="primary"
        show={showModalConfirm}
        handleClose={() => setShowModalConfirm(false)}
        handleOk={() => handleConfirmScelta()}
      />
    </>
  );
}

export default ScelteCoperturaPolicyMaker;
