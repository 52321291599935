function parseNote(item) {
  const notes = [];

  if (Array.isArray(item)) {
    // Caso in cui l'item è un array di note
    notes.push(...item);
  } else if (typeof item === 'object' && item !== null) {
    // Caso in cui l'item è un oggetto
    Object.keys(item).forEach((key) => {
      if (Object.prototype.hasOwnProperty.call(item, key)) {
        if (Array.isArray(item[key])) {
          // Se l'elemento è un array, aggiungilo direttamente
          let errorMessageStr = item[key][0];
          const word = 'Il campo';
          const positionOfWord = errorMessageStr.indexOf(word);
          errorMessageStr = `${errorMessageStr.substr(positionOfWord, word.length)} ${key}${errorMessageStr.substr(positionOfWord + word.length)}`;
          notes.push(errorMessageStr);
        } else if (typeof item[key] === 'object' && item[key] !== null) {
          // Se l'elemento è un oggetto, itera su di esso
          Object.keys(item[key]).forEach((subKey) => {
            if (Object.prototype.hasOwnProperty.call(item[key], subKey)) {
              let errorMessageStr = item[key][subKey][0];
              const word = 'Il campo';
              const positionOfWord = errorMessageStr.indexOf(word);
              errorMessageStr = `${errorMessageStr.substr(positionOfWord, word.length)} ${subKey}${errorMessageStr.substr(positionOfWord + word.length)}`;
              notes.push(errorMessageStr);
            }
          });
        }
      }
    });
  }

  // Creare l'elenco puntato come stringa HTML
  return (
    <div>
      <ul>
        {notes.map((note) => <li>{note}</li>)}
      </ul>
    </div>
  );
}

const fieldsDettaglioUtentiMassivo = [
  {
    dataField: 'idRiga',
    text: 'Riga File',
    // sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '10%' }),
  },
  {
    dataField: 'tecnico',
    text: 'Errore Tecnico',
    classes: 'column-data',
    headerStyle: () => ({ width: '30%' }),
    formatter: (e) => parseNote(e),
  },
  {
    dataField: 'utente',
    text: 'Errore Utente',
    classes: 'column-data',
    headerStyle: () => ({ width: '30%' }),
    formatter: (e) => parseNote(e),
  },
  {
    dataField: 'identita',
    text: 'Errore Identità',
    classes: 'column-data',
    headerStyle: () => ({ width: '30%' }),
    formatter: (e) => parseNote(e),
  },
];

export default fieldsDettaglioUtentiMassivo;
