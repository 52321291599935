import React, { useContext, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { Context } from '../../../utils/Context';
import AssessmentService from '../../../services/assessmentService';
import checkStorage from '../../../utils/checkSessionStorage';
import { setItem } from '../../../utils/storage';
import TitlePage from '../../../components/GestisciUtenti/TitoloPagina';
import FiltriCicliAssessment from '../cicli-assessment/filtriCicliAssessment';
import Table from '../../../components/Shared/Table';
import EmptyState from '../../../components/EmptyState';
import fieldsAnalisiEsitiAssessment from './fieldsTable';
import history from '../../../utils/history';

function AnalisiEsitiAssessment() {
  const [context, setContext] = useContext(Context);
  const [cicliAssessmentList, setCicliAssessmentList] = useState([]);
  const [statoCicloAssessmentList, setStatoCicloAssessmentList] = useState([]);

  const [filtri, setFiltri] = useState({});
  const [pagination, setPagination] = useState({
    page: 1,
    sizePerPage: 10,
    totalSize: 0,
  });

  // function collegata al servizio per ottenere la lista dei cicli di assessment
  async function getCicliAssessmentAdmin(offset, limit, page, filters) {
    try {
      const filterSession = filters || filtri;
      const response = await AssessmentService
        .getCicliAssessmentAdmin(filterSession, offset, limit);
      setCicliAssessmentList(response.rows);
      setPagination((prevPag) => ({
        ...prevPag,
        page,
        sizePerPage: limit,
        totalSize: response.totalRowCount,
      }));
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // function collegata al servizio per ottenere la lista degli stati per i cicli di assessment
  async function getStatoCicloAssessment() {
    try {
      const response = await AssessmentService.getStatoCicloAssessment();
      setStatoCicloAssessmentList(response.rows);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // function attivata al cambio pagina della tabella
  const handleTableChange = (type, { page, sizePerPage }) => {
    let currentIndex;
    if (page !== 0) {
      currentIndex = (page - 1) * sizePerPage;
    }
    getCicliAssessmentAdmin(currentIndex, sizePerPage, page, {});
  };

  // useEffect
  useEffect(() => {
    let offset = 0;
    const storage = checkStorage(filtri, setFiltri, setPagination);
    if (storage.page > 1) {
      handleTableChange(null, { page: storage.page, sizePerPage: pagination.sizePerPage });
      offset = (storage.page - 1) * pagination.sizePerPage;
    }
    getCicliAssessmentAdmin(offset, 10, storage.page, storage.filtri);
  }, []);

  // useEffect per settare nello storage la pagina corrente
  useEffect(() => {
    setItem('page', pagination.page);
  }, [pagination.page]);

  // useEffect per caricare i dati presenti nella tabella.
  useEffect(() => {
    getCicliAssessmentAdmin(0, 10, 1, {});
    getStatoCicloAssessment();
  }, []);

  // function per modificare lo stato che gestisce i filtri
  function onChangeFiltri(e, config) {
    if (config) {
      setFiltri({
        ...filtri, [config.name]: e?.codi_k_stato,
      });
    } else {
      const { value, name } = e.target;
      setFiltri({
        ...filtri, [name]: value,
      });
    }
  }

  // function per effettuare il refresh della pagina
  function refresh() {
    getCicliAssessmentAdmin(0, 10, 1, {});
  }

  // Funzione attivata al click dell'icona di visualizzazione
  const onView = (idCicloAssessment, titleCicloAssessment) => {
    history.push(`/area-riservata/analisi-assessment/${idCicloAssessment}`, { titleCicloAssessment });
  };

  return (
    <Container className="p-0">
      <TitlePage
        title="Analisi Esiti Cicli Assessment"
        showRefresh
        functionRefresh={refresh}
      />
      <FiltriCicliAssessment
        filtri={filtri}
        onChangeFiltri={onChangeFiltri}
        getCicliAssessmentAdmin={getCicliAssessmentAdmin}
        optionsStati={statoCicloAssessmentList}
      />
      {cicliAssessmentList.length > 0 ? (
        <Table
          data={cicliAssessmentList}
          fields={fieldsAnalisiEsitiAssessment(onView)}
          pagination={pagination}
          onTableChange={handleTableChange}
          keyField="analisi_esiti_cicli_assessment_list"
        />
      ) : (
        <EmptyState marginTop="3rem" />
      )}
    </Container>
  );
}

export default AnalisiEsitiAssessment;
