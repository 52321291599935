import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import themeColors from '../../../utils/contants/theme-colors';

const fieldsAutoAssessment = (
  onCompile,
  onInterview,
  onRiepilogo,
  setStateAssessmentSelected,
) => (
  [
    {
      dataField: 'fk_codi_stato_d_stato_ciclo_assesment.desc_stato',
      text: 'Stato',
      // sort: true,
      classes: 'column-data',
      headerStyle: () => ({ width: '15%' }),
    },
    {
      dataField: 'desc_titolo',
      text: 'Titolo',
      // sort: true,
      classes: 'column-data',
      headerStyle: () => ({ width: '50%' }),
    },
    {
      dataField: 'd_assesments.desc_stato',
      text: 'Stato Assessment',
      classes: 'column-data',
      headerStyle: () => ({ width: '20%' }),
    },
    {
      dataField: 'df1',
      isDummyField: true,
      text: 'Azioni',
      headerStyle: () => ({ width: '15%' }),
      formatter: (e, row) => (
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          {((row.d_assesments.codi_k_stato === 0 && row.fk_codi_stato === 2)
           || row.d_assesments.codi_k_stato === 1) && (
           <FontAwesomeIcon
             aria-hidden="true"
             icon={['fas', 'file-pen']}
             size="lg"
             title="Compila Auto Valutazione"
             id={`Compila_auto_${row.sequ_k_ciclo_assesment}`}
             style={{ color: themeColors.primary }}
             onClick={() => onCompile(row.sequ_k_ciclo_assesment)}
           />
          )}
          {(row.d_assesments.codi_k_stato === 2 || row.d_assesments.codi_k_stato === 3) && (
            <FontAwesomeIcon
              aria-hidden="true"
              icon={['fas', 'search']}
              size="lg"
              title="Visualizza"
              id={`Visualizza_${row.sequ_k_ciclo_assesment}`}
              style={{ color: themeColors.primary }}
              onClick={() => onCompile(row.sequ_k_ciclo_assesment)}
            />
          )}
          {(row.d_assesments.codi_k_stato === 3 && row.d_colloquio_assesments.length === 0) && (
            <FontAwesomeIcon
              aria-hidden="true"
              icon={['fas', 'calendar']}
              size="lg"
              title="Richiesta colloquio"
              id={`Richiesta_colloquio_${row.fk_sequ_utente_trusted}`}
              style={{ color: themeColors.primary }}
              onClick={() => {
                setStateAssessmentSelected(row);
                onInterview();
              }}
            />
          )}
          {((row.d_assesments.codi_k_stato === 3 && row.d_colloquio_assesments.length > 0)
            && row.d_colloquio_assesments?.[0]?.data_pianificata === null) && (
            <OverlayTrigger
              placement="top"
              overlay={(
                <Tooltip id="tooltip-disabled">
                  Richiesta di colloquio inviata. In attesa di conferma
                  da parte del responsabile. Potrai visualizzare il riepilogo
                  del colloquio quando verrà fissato.
                </Tooltip>
                  )}
            >
              <span className="d-inline-block">
                <FontAwesomeIcon
                  aria-hidden="true"
                  size="lg"
                  icon={['fas', 'calendar']}
                  style={{ color: themeColors.warning }}
                />
              </span>
            </OverlayTrigger>
          )}
          {((row.d_assesments.codi_k_stato === 3 && row.d_colloquio_assesments.length > 0)
            && row.d_colloquio_assesments?.[0]?.data_pianificata !== null) && (
              <FontAwesomeIcon
                aria-hidden="true"
                icon={['fas', 'calendar']}
                size="lg"
                title="Visualizza data colloquio"
                id={`Visualizza_data_colloquio_${row.fk_sequ_utente_trusted}`}
                style={{ color: themeColors.success }}
                onClick={() => {
                  setStateAssessmentSelected(row);
                  onRiepilogo();
                }}
              />
          )}
        </div>
      ),
    },
  ]
);

export default fieldsAutoAssessment;
