import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import themeColors from '../../../utils/contants/theme-colors';

const fieldsFabbisogni = (
  onView,
  onModify,
  idIdentita,
  nomeSO,
  onContinue,
) => (
  [
    {
      dataField: 'nome_processo',
      text: 'Nome Piano',
      classes: 'column-data',
      headerStyle: () => ({ width: '25%' }),
    },
    {
      dataField: 'anno_riferimento',
      text: 'Anno di Riferimento',
      classes: 'column-data',
      headerStyle: () => ({ width: '25%' }),
    },
    {
      dataField: 'stato_processo',
      text: 'Stato Processo',
      classes: 'column-data',
      headerStyle: () => ({ width: '25%' }),
    },
    {
      dataField: 'df1',
      isDummyField: true,
      text: 'Azioni',
      headerStyle: () => ({ width: '25%' }),
      formatter: (e, row) => (
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          {(row.fase === 5
          || (row.fase === 3 && idIdentita === 10
            && row.strutture_organizzative.find(
              (so) => so.struttura_organizzativa === nomeSO,
            )?.richiesta_inviata)) && (
            <FontAwesomeIcon
              aria-hidden="true"
              icon={['fas', 'search']}
              size="lg"
              title="Visualizza"
              id={`Visualizza${row._id}`}
              style={{ color: themeColors.primary }}
              onClick={() => onView(row._id)}
            />
          )}
          {(row.fase === 2
          || (row.fase === 3 && idIdentita === 3)
          || (row.fase === 4 && idIdentita === 3)
          || (row.fase === 3 && idIdentita === 10
            && !row.strutture_organizzative.find(
              (so) => so.struttura_organizzativa === nomeSO,
            )?.richiesta_inviata)) && (
            <FontAwesomeIcon
              aria-hidden="true"
              icon={['fas', 'edit']}
              size="lg"
              title={idIdentita === 3 ? 'Visualizza Richieste' : 'Compila Richiesta'}
              id={`Visualizza${row._id}`}
              style={{ color: themeColors.primary }}
              onClick={() => onModify(row._id)}
            />
          )}
          {(row.fase === 0 || row.fase === 1) && (
            <FontAwesomeIcon
              aria-hidden="true"
              icon={['fas', 'edit']}
              size="lg"
              title="Continua compilazione"
              id={`Continua compilazione${row._id}`}
              style={{ color: themeColors.warning }}
              onClick={() => onContinue(row._id, row.fase)}
            />
          )}
        </div>
      ),
    },
  ]
);

export default fieldsFabbisogni;
