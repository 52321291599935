import React, {
  useEffect, useRef, useState, useContext,
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from 'react-select';
import { Button } from 'react-bootstrap';
import SistemaProfessionaleService from '../../../../../services/sistemaProfessionaleService';
import { getItem } from '../../../../../utils/storage';
import ModaleConferma from '../../../../../components/modali/ModaleConferma';
import EmptyState from '../../../../../components/EmptyState';
import AssessmentService from '../../../../../services/assessmentService';
import { Context } from '../../../../../utils/Context';
import utenteService from '../../../../../services/utentiService';
import TabellaDirettore from '../components/richiestaFabbisogniDirettore/TabellaDirettore';
import formatCentinaia from '../../../../../utils/formatCentinaia';

function RichiestaFabbisogniDirettore({
  tableData,
  setTableData,
  strutturaSelected,
  setStrutturaSelected,
  processoState,
  setProcessoState,
  blockTable,
  setBlockTable,
}) {
  const [context, setContext] = useContext(Context);
  const [totaliCostiRicheste, setTotaliCostiRicheste] = useState({});
  const [totaleUnitaAllocate, setTotaleUnitaAllocate] = useState(0);
  const [totaleUnitaCessazione, setTotaleUnitaCessazione] = useState(0);
  const [totaleUnitaRichieste, setTotaleUnitaRichieste] = useState(0);
  const [listaProfiliProfessionali, setListaProfiliProfessionali] = useState([]);
  const [ipotesiCopertura, setIpotesiCopertura] = useState([]);
  const [selectedProfili, setSelectedProfili] = useState([]);
  const [showModaleDelete, setShowModaleDelete] = useState(false);
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [codiceToDelete, setCodiceToDelete] = useState('');
  const selectInputRef = useRef(null);
  const { sequ_k_comparto } = getItem('compartoSelected') ? JSON.parse(getItem('compartoSelected')) : 0;

  // Function chiamata API per ottenere la lista delle scelte di copertura
  async function getSceltaCopertura() {
    try {
      const response = await AssessmentService.getSceltaCopertura();
      const listaSceltaCopertura = response.map(
        (el) => el.descrizione,
      );
      const newTableData = tableData.map(
        (profilo) => ({ ...profilo, options_ipotesi: listaSceltaCopertura }),
      );
      setTableData(newTableData);
      setIpotesiCopertura(listaSceltaCopertura);
    } catch (error) {
      setContext(() => ({
        ...context, open: true, testoErrore: error?.data?.message || 'Errore', statusCode: error?.status,
      }));
    }
  }

  // PER OTTENERE I PROFILI PROFESSIONALI/DI RUOLO
  async function getProfiliProfessionaliRuolo() {
    try {
      const response = await SistemaProfessionaleService
        .getProfiliProfessionaliRuolo(sequ_k_comparto);
      setListaProfiliProfessionali(response);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // PER CALCOALRE I TOTALI DELLE UNITA'
  function calcolaTotaliUtente(profili) {
    let newTotaleAllocate = 0;
    let newTotaleCessazione = 0;
    let newTotaleRichieste = 0;
    profili.forEach((profilo) => {
      newTotaleAllocate += profilo.unita_allocate;
      newTotaleCessazione += profilo.unita_cessazione;
      newTotaleRichieste += profilo.unita_richieste;
    });
    setTotaleUnitaAllocate(newTotaleAllocate);
    setTotaleUnitaCessazione(newTotaleCessazione);
    setTotaleUnitaRichieste(newTotaleRichieste);
  }

  // Function collegata al servizio per ottenere la struttura organizzativa dell'utente
  async function getStrutturaSelected(processo) {
    try {
      const response = await utenteService.getUtenteByIdUtente();
      const utenteSO = response.desc_struttura_organizzativa_ii_liv
          ?? response.desc_struttura_organizzativa_i_liv;
      if (Object.keys(processo).length > 0) {
        const responseFiltered = processo?.strutture_organizzative.find(
          (so) => so.struttura_organizzativa === utenteSO,
        );
        const newTotaliCostiRicheste = responseFiltered.profili.reduce(
          (acc, profilo) => (
            {
              ...acc,
              [profilo.desc_codice]: profilo.ipotesi_copertura === 'Meccanismi di progressione di carriera interni'
                ? profilo.differenza * profilo.unita_richieste
                : profilo.costo_annuale * profilo.unita_richieste,
            }),
          {},
        );
        setTotaliCostiRicheste(newTotaliCostiRicheste);
        calcolaTotaliUtente(responseFiltered.profili);
        setStrutturaSelected(responseFiltered);
      }
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // PER GESTIRE IL CAMBIAMENTO DEI DATI ALL'INTERNO DELLA TABELLA
  const onChange = (e, row) => {
    const { name, value } = e.target;
    if (name === 'motivazioni_richiesta' || name === 'ipotesi_copertura') {
      setTableData((prevState) => prevState.map(
        (el) => (el.desc_codice === row.desc_codice ? { ...el, [name]: value } : el),
      ));
    } else {
      setTableData((prevState) => prevState.map(
        (el) => (el.desc_codice === row.desc_codice ? { ...el, [name]: parseInt(value === '' ? 0 : value, 10) } : el),
      ));
    }
    if (name === 'unita_richieste') {
      const scostamento = parseInt(value === '' ? 0 : value, 10) - row.unita_richieste;
      setTotaleUnitaRichieste((prevState) => prevState + scostamento);
      setTotaliCostiRicheste((prevState) => (
        {
          ...prevState,
          [row.desc_codice]: row.ipotesi_copertura === 'Meccanismi di progressione di carriera interni'
            ? row.differenza * parseInt(value === '' ? 0 : value, 10)
            : row.costo_annuale * parseInt(value === '' ? 0 : value, 10),
        }
      ));
    }
    if (name === 'unita_cessazione') {
      const scostamento = parseInt(value === '' ? 0 : value, 10) - row.unita_cessazione;
      setTotaleUnitaCessazione((prevState) => prevState + scostamento);
    }
    if (name === 'unita_allocate') {
      const scostamento = parseInt(value === '' ? 0 : value, 10) - row.unita_allocate;
      setTotaleUnitaAllocate((prevState) => prevState + scostamento);
    }
    setBlockTable(false);
  };

  // PER APRIRE LA MODALE DI CONFERMA ELIMINAZIONE
  const openModaleDelete = (event, row) => {
    setCodiceToDelete({ codice: row.desc_codice, titolo: row.desc_titolo });
    setShowModaleDelete(!showModaleDelete);
  };

  // PER ELIMINARE LA RIGA SCELTA DELLA TABELLA
  const deleteTableRow = () => {
    let unitaCessazione;
    let unitaAllocate;
    let unitaRichieste;
    const newData = tableData.filter((el) => {
      if (el.desc_codice === codiceToDelete.codice) {
        unitaCessazione = el.unita_cessazione;
        unitaAllocate = el.unita_allocate;
        unitaRichieste = el.unita_richieste;
      }
      return (el.desc_codice !== codiceToDelete.codice);
    });
    const newTotali = { ...totaliCostiRicheste };
    delete newTotali[codiceToDelete.codice];
    setTotaliCostiRicheste(newTotali);
    setTotaleUnitaAllocate((prevState) => prevState - unitaAllocate);
    setTotaleUnitaCessazione((prevState) => prevState - unitaCessazione);
    setTotaleUnitaRichieste((prevState) => prevState - unitaRichieste);
    setTableData(newData);
    setShowModaleDelete(!showModaleDelete);
  };

  // PER RESETTARE AL CLICK DEL BOTTONE LA SELECT
  const clearSelect = () => {
    selectInputRef.current.clearValue();
  };

  // PER AGGIUNGERE NUOVE RIGHE ALLA TABELLA
  const addNewDataInTable = () => {
    const newDataFromSelectedProfili = selectedProfili.map((profilo) => {
      const area = processoState.aree_contrattuali.find(
        (el) => el.id_area === profilo.area_contrattuale,
      );
      return (
        {
          ...profilo,
          unita_allocate: parseInt(profilo.unita_allocate, 10),
          unita_cessazione: 0,
          unita_richieste: 0,
          costo_annuale: area.costo_area,
          differenza: area.differenza || 0,
        }
      );
    });
    const newTableData = tableData.concat(newDataFromSelectedProfili);
    const newTotaleUnitaAllocate = newTableData.reduce((acc, row) => acc + row.unita_allocate, 0);
    setTotaleUnitaAllocate(newTotaleUnitaAllocate);
    setTableData(newTableData);
    clearSelect();
  };

  // PER CALCOALRE IL TOTALE DEL COSTO DELLA RICHIESTA
  const getTotaleCostoRichiesta = () => {
    const somma = Object.keys(totaliCostiRicheste)
      .reduce((acc, key) => acc + totaliCostiRicheste[key], 0);
    return somma;
  };

  // PER CALCOLARE IL TOTALE DEI COSTI DELLA TABELLA
  const totaleCostiInTable = () => {
    const totale = tableData
      .reduce(
        (acc, item) => (item.ipotesi_copertura === 'Meccanismi di progressione di carriera interni'
          ? acc + item.differenza * item.unita_richieste
          : acc + item.costo_annuale * item.unita_richieste),
        0,
      );
    return `${totale.toString()}`;
  };

  const handleConfirmScelta = () => {
    const strutturaAggiornata = {
      ...strutturaSelected,
      profili: tableData,
      totale_costo_richiesta: getTotaleCostoRichiesta(),
    };
    const struttureUpdated = processoState.strutture_organizzative.map(
      (so) => (so.struttura_organizzativa
        === strutturaSelected.struttura_organizzativa ? strutturaAggiornata : so),
    );
    setProcessoState((prevState) => ({
      ...prevState,
      strutture_organizzative: struttureUpdated,
    }));
    // aggiorno lo stato dei dati della table di bootstrap per bloccarla
    const newTableDataBlocked = tableData.map((row) => ({ ...row, tabellaDirettoreBlocked: true }));
    setTableData(newTableDataBlocked);
    setBlockTable(true);
    setShowModalConfirm(false);
  };

  // PER FARE VISUALIZZARE LE GIUSTE OPZIONI ALLA SELECT
  function getSelectOptions() {
    const options = [];
    let founded = false;
    for (let i = 0; i < listaProfiliProfessionali.length; i += 1) {
      founded = false;
      for (let j = 0; j < tableData.length; j += 1) {
        if (listaProfiliProfessionali[i].desc_codice === tableData[j].desc_codice) {
          founded = true;
        }
      }
      if (!founded) {
        options.push({ ...listaProfiliProfessionali[i] });
      }
    }
    return options;
  }

  useEffect(() => {
    getProfiliProfessionaliRuolo();
    getSceltaCopertura();
  }, []);

  useEffect(() => {
    getStrutturaSelected(processoState);
  }, [processoState]);

  return (
    <>
      <div className="d-flex gap-5 flex-column">
        <div>
          <h4 className="fw-bold">{strutturaSelected?.struttura_organizzativa || ''}</h4>
          <table>
            <tbody>
              <tr>
                <td className="border border-2 p-1 fw-bold">Potenziali disponibilità di spesa (PDS)</td>
                <td className="border border-2 p-1 fw-bold">Costo totale della richiesta</td>
                <td className="border border-2 p-1 fw-bold">Scostamento</td>
              </tr>
              <tr>
                <td style={{ minWidth: '6rem' }} className="border border-2 p-1 text-end text-center">
                  {formatCentinaia(strutturaSelected.pds)}
                  &euro;
                </td>
                <td style={{ minWidth: '6rem' }} className="border border-2 p-1 text-end text-center">
                  {formatCentinaia(getTotaleCostoRichiesta())}
                  &euro;
                </td>
                <td style={{ minWidth: '6rem' }} className="border border-2 p-1 text-end text-center">
                  <span className="text-start">
                    {((strutturaSelected.pds - getTotaleCostoRichiesta()) > 0) ? (<FontAwesomeIcon icon="fa-solid fa-up-long" style={{ color: '#198754' }} />)
                      : ((strutturaSelected.pds - getTotaleCostoRichiesta()) < 0) ? (<FontAwesomeIcon icon="fa-solid fa-down-long" style={{ color: '#dc3545' }} />)
                        : (<FontAwesomeIcon icon="fa-solid fa-equals" style={{ color: '#3c9dff' }} />) }
                  </span>
                  <span className="text-end">
                    {formatCentinaia(strutturaSelected.pds - getTotaleCostoRichiesta())}
                    €
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          <table>
            <tbody>
              <tr>
                <td className="border border-2 p-1 fw-bold">Unità di personale allocate presso la struttura</td>
                <td className="border border-2 p-1 fw-bold">Unità di personale in uscita nel triennio oggetto di programmmazione</td>
                <td className="border border-2 p-1 fw-bold">Unità di personale richieste</td>
              </tr>
              <tr>
                <td style={{ minWidth: '6rem' }} className="border border-2 p-1 text-center">{totaleUnitaAllocate}</td>
                <td style={{ minWidth: '6rem' }} className="border border-2 p-1 text-center">{totaleUnitaCessazione}</td>
                <td style={{ minWidth: '6rem' }} className="border border-2 p-1 text-center">{totaleUnitaRichieste}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="mt-5">
        <h4 className="fw-bold">Profili Richiesti</h4>
        <div className="d-flex mb-3">
          <p className="mb-0 d-flex justify-content-center align-items-center">Seleziona uno o più profili professionali/di ruolo:</p>
          &nbsp;
          <div style={{ minWidth: '20rem' }}>
            <Select
              ref={selectInputRef}
              isMulti
              className="basic-multi-select"
              classNamePrefix="select"
              id="desc_codice"
              name="desc_codice"
              options={getSelectOptions()}
              placeholder="Scegli profilo Professionale/di ruolo"
              getOptionLabel={(option) => option.desc_titolo}
              getOptionValue={(option) => option.desc_codice}
              onChange={(selectedOption) => { setSelectedProfili(selectedOption); }}
              isClearable={false}
            />
          </div>
          <Button
            className="me-2 ms-1"
            size="sm"
            onClick={() => addNewDataInTable()}
            title="Aggiungi Profili"
          >
            <FontAwesomeIcon aria-hidden="true" icon="add" />
            &nbsp;
            AGGIUNGI
          </Button>
        </div>
        <div>
          { tableData?.length > 0
            ? (
              <TabellaDirettore
                setShowModalConfirm={setShowModalConfirm}
                tableData={tableData}
                ipotesiCopertura={ipotesiCopertura}
                onChange={onChange}
                openModaleDelete={openModaleDelete}
                totaleCostiInTable={totaleCostiInTable}
                totaleUnitaAllocate={totaleUnitaAllocate}
                totaleUnitaCessazione={totaleUnitaCessazione}
                totaleUnitaRichieste={totaleUnitaRichieste}
                processoState={processoState}
                // blockTable={blockTable}
              />
            )
            : (
              <EmptyState
                marginTop="3rem"
                title="Nessun profilo professionale/di ruolo inserito"
                subtitle="Seleziona uno o più profili di ruolo per calcolare la richiesta dei fabbisogni"
              />
            )}
        </div>
      </div>

      <ModaleConferma
        title="Conferma di eliminazione"
        show={showModaleDelete}
        body={`Sei sicuro di voler eliminare il profilo: ${codiceToDelete.titolo}?`}
        labelBottone="Elimina"
        coloreAzione="danger"
        handleClose={() => { setShowModaleDelete(!showModaleDelete); }}
        handleOk={deleteTableRow}
      />
      <ModaleConferma
        title="Conferma inserimento dei Profili Professionali di Ruolo"
        body="Confermi l'inserimento dei dati? Verifica
        l'inserimento di tutti i campi disponibili prima di procedere."
        labelBottone="CONFERMA"
        coloreAzione="primary"
        show={showModalConfirm}
        handleClose={() => setShowModalConfirm(false)}
        handleOk={() => handleConfirmScelta()}
      />
    </>
  );
}

export default RichiestaFabbisogniDirettore;
