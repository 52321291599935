export const fields = [
  {
    dataField: 'sequ_k_identita',
    text: 'ID',
    classes: 'column-data',
    headerStyle: () => ({ width: '20%' }),
  },
  {
    dataField: 'desc_nome_identita',
    text: 'Nome',
    classes: 'column-data',
    headerStyle: () => ({ width: '80%' }),
  },
];

export const selectRows = (
  setIdentitaScelte,
  identitaScelte,
  identitaRimosse,
  setIdentitaRimosse,
  identitaAggiunte,
  setIdentitaAggiunte,
) => {
  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    selected: [...identitaScelte].filter((el) => !el.data_fine_validita)
      .map((el) => el.sequ_k_identita),
    hideSelectAll: true,
    selectColumnPosition: 'left',
    onSelect: (row, isSelect) => {
      if (isSelect) {
        setIdentitaAggiunte([...identitaAggiunte, row]);
        setIdentitaScelte([...identitaScelte, row]);
      } else {
        setIdentitaRimosse([...identitaRimosse, row]);
        setIdentitaScelte(identitaScelte.filter(
          (funzione) => funzione.sequ_k_identita !== row.sequ_k_identita,
        ));
      }
    },

  };
  return selectRow;
};
