import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Container, Form } from 'react-bootstrap';
import { Context } from '../../../../utils/Context';
import AssessmentService from '../../../../services/assessmentService';
import TitlePage from '../../../../components/GestisciUtenti/TitoloPagina';
import history from '../../../../utils/history';
import Table from '../../../../components/Shared/Table';
import EmptyState from '../../../../components/EmptyState';
import checkStorage from '../../../../utils/checkSessionStorage';
import { setItem } from '../../../../utils/storage';
import fieldsDipendentiAnalisiEsitiAssessment from './fieldsTable';

function AnalisiEsitiAssessmentDettaglioDipendenti() {
  const [context, setContext] = useContext(Context);
  const { idCicloAssessment } = useParams();
  const { state, pathname } = useLocation();
  const [listaDipendenti, setListaDipendenti] = useState([]);

  const [filtri, setFiltri] = useState({});
  const [pagination, setPagination] = useState({
    page: 1,
    sizePerPage: 10,
    totalSize: 0,
  });

  // function collegata al servizio di get della lista di dipendenti di un ciclo di assessment
  async function getAssessmentsAdmin(id, offset, limit, page, filters) {
    try {
      const filterSession = filters || filtri;
      const response = await AssessmentService.getAssessmentsAdmin(
        id,
        filterSession,
        offset,
        limit,
      );
      setListaDipendenti([
        ...response.userWithAssessment.data.map((el) => ({
          ...el,
          desc_dipendente: `${el.desc_nome} ${el.desc_cognome}`,
          withAssessment: true,
        })),
        ...response.userWithoutAssessment.data.map((el) => ({
          desc_stato: 'Da iniziare',
          ...el,
          desc_dipendente: `${el.desc_nome} ${el.desc_cognome}`,
          withAssessment: false,
        })),
      ]);
      setPagination((prevPag) => ({
        ...prevPag,
        page,
        sizePerPage: limit,
        totalSize: response.totalRowCount,
      }));
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // function attivata al cambio pagina della tabella
  const handleTableChange = (type, { page, sizePerPage }) => {
    let currentIndex;
    if (page !== 0) {
      currentIndex = (page - 1) * sizePerPage;
    }
    getAssessmentsAdmin(idCicloAssessment, currentIndex, sizePerPage, page, {});
  };

  // useEffect
  useEffect(() => {
    let offset = 0;
    const storage = checkStorage(filtri, setFiltri, setPagination);
    if (storage.page > 1) {
      handleTableChange(null, { page: storage.page, sizePerPage: pagination.sizePerPage });
      offset = (storage.page - 1) * pagination.sizePerPage;
    }
    getAssessmentsAdmin(idCicloAssessment, offset, 10, storage.page, storage.filtri);
  }, []);

  // useEffect per settare nello storage la pagina corrente
  useEffect(() => {
    setItem('page', pagination.page);
  }, [pagination.page]);

  // useEffect per caricare i dati presenti nella tabella.
  useEffect(() => {
    getAssessmentsAdmin(idCicloAssessment, 0, 10, 1, {});
  }, []);

  // function per modificare lo stato che gestisce i filtri
  function onChangeFiltri(e, config) {
    if (config) {
      setFiltri({
        ...filtri, [config.name]: e?.codi_k_stato,
      });
    } else {
      const { value, name } = e.target;
      setFiltri({
        ...filtri, [name]: value,
      });
    }
  }

  // function per effettuare il refresh della pagina
  function refresh() {
    getAssessmentsAdmin(idCicloAssessment, 0, 10, 1, {});
  }

  // Funzione attivata al click dell'icona di visualizzazione
  const onView = (idUser) => {
    history.push(`${pathname}/${idUser}`);
  };

  return (
    <Container className="p-0">
      <TitlePage
        title={state.titleCicloAssessment}
        showIndietro
        functionIndietro={() => history.goBack()}
        showRefresh
        functionRefresh={refresh}
      />
      <Form.Label style={{ fontWeight: 'bold' }}>
        Dipendenti con Assessment
      </Form.Label>
      {listaDipendenti.filter((el) => el.withAssessment).length > 0 ? (
        <Table
          data={listaDipendenti.filter((el) => el.withAssessment)}
          fields={fieldsDipendentiAnalisiEsitiAssessment(onView, true)}
          // pagination={pagination}
          // onTableChange={handleTableChange}
          keyField="dipendenti_with_assessment"
        />
      ) : (
        <EmptyState
          subtitle="Non ci sono dipendenti con assegnazioni"
          marginTop="3rem"
        />
      )}
      <Form.Label style={{ fontWeight: 'bold' }}>
        Dipendenti senza Assessment
      </Form.Label>
      {listaDipendenti.filter((el) => !el.withAssessment).length > 0 ? (
        <Table
          data={listaDipendenti.filter((el) => !el.withAssessment)}
          fields={fieldsDipendentiAnalisiEsitiAssessment(onView, false)}
          // pagination={pagination}
          // onTableChange={handleTableChange}
          keyField="dipendenti_without_assessment"
        />
      ) : (
        <EmptyState
          title=""
          subtitle="Nessun dipendente deve iniziare il ciclo di assessment"
          marginTop="3rem"
        />
      )}
    </Container>
  );
}

export default AnalisiEsitiAssessmentDettaglioDipendenti;
