const optionsRiepilogoGrafico = (valori) => ({
    title: {
        text: 'Grading Assessment rispetto al Grading Atteso sulle Competenze',
        top: '5%',
        left: 'center',
    },
    tooltip: {
        trigger: 'axis',
        axisPointer: {
        type: 'shadow',
        },
    },
    legend: {
        data: ['Esito Assessment'],
        bottom: '0%',
    },
    grid: {
        top: '20%',
        left: '3%',
        right: '4%',
        bottom: '15%',
        containLabel: true,
    },
    xAxis: {
        type: 'value',
    },
    yAxis: {
        type: 'category',
        data: valori.map((el) => el['Competenza']),
    },
    series: [
        {
        name: 'Esito Assessment',
        type: 'bar',
        data: valori.map((el) => ({
            value: parseInt(el['Esito Assessment'], 10),
            name: el['Competenza'],
        })),
        itemStyle: {
            emphasis: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
        },
        },
    ],

});

export default optionsRiepilogoGrafico;
  