/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/no-unknown-property */
import { useContext, useEffect, useState } from 'react';
import {
  Alert, Button, Col, Form, InputGroup,
} from 'react-bootstrap';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Context } from '../../../../../utils/Context';
import InfoModelli from '../components/prioritizzazione/InfoModelli';
import AssessmentService from '../../../../../services/assessmentService';
import EmptyState from '../../../../../components/EmptyState';
import ModaleInfo from '../components/prioritizzazione/ModaleInfo';
import ModaleCentroVerticale from '../../../../../components/modali/ModaleCentroVerticale';
import SelezioneSO from '../components/prioritizzazione/SelezioneSO';
import ModificaCriteri from '../components/prioritizzazione/ModificaCriteri';
import ModaleConferma from '../../../../../components/modali/ModaleConferma';
import calcolaPunteggioSO from '../components/prioritizzazione/calcolaPunteggioSO';
import Ranking from '../components/prioritizzazione/Ranking';
import TablePrioritizzazione from '../components/prioritizzazione/TablePrioritizzazione';
import formatCentinaia from '../../../../../utils/formatCentinaia';

function Prioritizzazione({
  statePiano,
  setStatePiano,
  struttureOrganizzative,
  isRanking,
  setIsRanking,
  setIsDone,
}) {
  const [context, setContext] = useContext(Context);
  const [errore, setErrore] = useState(false);
  const [modelliList, setModelliList] = useState([]);
  const [modelloSelected, setModelloSelected] = useState({
    sequ_k_modello: statePiano.sequ_k_modello || 0,
    criteri: [],
  });
  const [struttureOrganizzativeSelected, setStruttureOrganizzativeSelected] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [modalCriteri, setModalCriteri] = useState(false);
  const [modalCalcola, setModalCalcola] = useState(false);
  const [isModello, setIsModello] = useState(false);
  const [dataInfoFiltered, setDataInfoFiltered] = useState([]);

  // Function collegata alla chiamata per ottenere la lista dei modelli
  async function getModelliList() {
    try {
      const response = await AssessmentService.getModelli();
      setModelliList(response);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // Function collegata alla chiamata per ottenere il modello selezionato
  async function getModello(id) {
    try {
      const response = await AssessmentService.getModelloById(id);
      return response;
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
    return null;
  }

  // Function per ricavare le informazioni di ogni modello disponibile
  const getDataInfo = async (lista) => {
    let newState = [...dataInfoFiltered];
    for (const el of lista) {
      const response = await getModello(el.sequ_k_modello);
      const infoModello = {
        sequ_k_modello: el.sequ_k_modello,
        criteri: response,
      };
      newState = newState.filter(
        (info) => info.sequ_k_modello !== el.sequ_k_modello,
      );
      newState.push(infoModello);
    }
    setDataInfoFiltered(newState);
  };

  // Function per gestire l'inserimento del budget e modello all'interno dello state
  const onChange = (e, config) => {
    if (config) {
      if (config.name === 'sequ_k_modello') {
        const modello = dataInfoFiltered.filter(
          (el) => el.sequ_k_modello === e.sequ_k_modello,
        )[0];
        const responseParsed = modello.criteri.map(
          (el) => ({
            ...el,
            valore_percentuale: parseInt(el.valore_percentuale, 10),
          }),
        );
        setModelloSelected((prevState) => ({
          ...prevState,
          sequ_k_modello: e?.sequ_k_modello,
          criteri: responseParsed,
        }));
        setModalCriteri(true);
      }
    } else {
      const { name, value } = e.target;
      setStatePiano({
        ...statePiano,
        [name]: formatCentinaia(value, true),
      });
    }
  };

  // Function per gestire l'inserimento dei campi all'interno dei valori dei criteri del modello
  const onChangeModello = (e, config) => {
    setIsDone(false);
    setIsRanking(0);
    const [idCriterio, nomeSO] = config
      ? config.name.split(',') : e.target.id.split(',');

    const value = config ? e.value : formatCentinaia(e.target.value, true);
    const struttura = statePiano.strutture_organizzative.find(
      (el) => el.struttura_organizzativa === nomeSO,
    );

    if (struttura) {
      const criteriUpdated = struttura.criteri.map(
        (el) => {
          if (el.sequ_k_criterio === parseInt(idCriterio, 10)) {
            return {
              ...el,
              valore_criterio: value || '',
            };
          }
          return el;
        },
      );
      const struttureUpdated = statePiano.strutture_organizzative.map(
        (el) => (el.struttura_organizzativa === nomeSO ? { ...el, criteri: criteriUpdated } : el),
      );
      setStatePiano({
        ...statePiano,
        strutture_organizzative: struttureUpdated,
      });
    }
  };

  // Function per l'inserimento delle strutture organizzative selezionate con relativi criteri
  const onConfirmSO = () => {
    const struttureOrganizzativeUpdated = struttureOrganizzativeSelected.map((el) => {
      // Cerca la corrispondenza in oggetto1 basato su `struttura_organizzativa`
      const corrispondente = statePiano.strutture_organizzative
        .find((item) => item.struttura_organizzativa === el.struttura_organizzativa);

      return {
        struttura_organizzativa: el.struttura_organizzativa,
        punteggio_so: corrispondente ? corrispondente.punteggio_so : el.punteggio_so || 0,
        criteri: corrispondente ? corrispondente.criteri : modelloSelected.criteri.map((el1) => ({
          ...el1,
          valore_criterio: el1.valore_criterio || 0,
        })),
      };
    });

    setStatePiano((prevState) => ({
      ...prevState,
      strutture_organizzative: struttureOrganizzativeUpdated,
    }));
    setModalShow(false);
  };
  // Function per il salvataggio delle percentuali dei criteri modificati
  const onConfirmCriteri = () => {
    const totale = modelloSelected.criteri.reduce((acc, crt) => acc + crt.valore_percentuale, 0);
    if (totale === 100) {
      setErrore(false);
      setIsModello(true);
      setModalCriteri(false);
      setStatePiano((prevState) => ({
        ...prevState,
        modello: modelloSelected,
      }));
      setContext(() => ({
        ...context, openConferma: true, testo: 'Modello selezionato correttamente. È ora possibile selezionare le strutture organizzative',
      }));
    } else {
      setErrore(true);
    }
  };

  // Function per il calcolo e salvataggio del punteggio complessivo delle strutture organizzative
  const onConfirmCalcola = () => {
    const punteggiSO = calcolaPunteggioSO(statePiano.strutture_organizzative);
    const struttureUpdate = statePiano.strutture_organizzative.map(
      (so) => {
        const el = punteggiSO
          .find((ptg) => ptg.struttura_organizzativa === so.struttura_organizzativa);
        if (el) {
          return ({
            ...so,
            punteggio_so: el.punteggio,
          });
        }
        return so;
      },
    );
    setStatePiano((prevState) => ({
      ...prevState,
      strutture_organizzative: struttureUpdate,
      sequ_k_modello: modelloSelected.sequ_k_modello,
    }));
    setIsDone(true);
    setIsRanking(isRanking + 1);
    setModalCalcola(false);
  };

  // Funzione per controllare se disabilitare il pulsante calcola punteggi
  function shouldDisableButtonCalcolaPunteggi() {
    return statePiano.strutture_organizzative
      .some((obj) => obj.criteri.some((criterio) => criterio.valore_criterio === 0 || criterio.valore_criterio === ''));
  }

  // useEffect per le chiamate del primo rendering
  useEffect(() => {
    getModelliList();
    if (statePiano?.modello?.sequ_k_modello) {
      setModelloSelected(statePiano.modello);
      setIsModello(true);
    }
    if (statePiano?.strutture_organizzative) {
      setStruttureOrganizzativeSelected(statePiano.strutture_organizzative);
    }
  }, []);

  // useEffect per il caricamento della lista dei criteri per ogni modello
  useEffect(() => {
    getDataInfo(modelliList);
  }, [modelliList]);

  return (
    <>
      {isRanking <= 1 && (
        <>
          <div as={Col} style={{ width: '40%' }}>
            <Form.Label style={{ fontWeight: 'bold' }}>
              Budget Assunzionale
            </Form.Label>
            <InputGroup className="mb-3">
              <InputGroup.Text>€</InputGroup.Text>
              <Form.Control
                type="text"
                maxLength={15}
                name="budget_assunzionale"
                placeholder="Inserisci costo in Euro"
                value={statePiano.budget_assunzionale || ''}
                onChange={(e) => onChange(e)}
              />
              <InputGroup.Text>,00</InputGroup.Text>
            </InputGroup>
            <Form.Label style={{ fontWeight: 'bold' }}>
              Modello
            </Form.Label>
            <Select
              id="sequ_k_modello"
              name="sequ_k_modello"
              options={modelliList}
              placeholder="Scegli il tipo di modello da adottare"
              isDisabled={isModello}
              getOptionLabel={(option) => option.desc_nome}
              getOptionValue={(option) => option.sequ_k_modello}
              value={modelliList?.filter(
                (el) => el.sequ_k_modello === modelloSelected.sequ_k_modello,
              )?.[0]}
              onChange={(selectedOption, config) => { onChange(selectedOption, config); }}
            />
            <div className="d-flex">
              <p style={{ fontWeight: 'lighter' }}>
                Visualizza i modelli disponibili:
                {' '}
                <ModaleInfo
                  // variantButton="outline-warning"
                  titleBody="Modelli disponibili"
                  body={(
                    <InfoModelli
                      modelliList={modelliList}
                      dataInfo={dataInfoFiltered}
                    />
              )}
                />
              </p>
            </div>
          </div>
          <div as={Col}>
            {isModello && (
            <div>
              <Form.Label style={{ fontWeight: 'bold' }}>
                Strutture organizzative
              </Form.Label>
              <p>
                Seleziona le strutture organizzative:
                {statePiano.strutture_organizzative?.length > 0 ? (
                  <Button
                    onClick={() => setModalShow(true)}
                    size="sm"
                    className="ms-2"
                  >
                    <FontAwesomeIcon aria-hidden="true" icon="fa-solid fa-edit" style={{ marginRight: '4px' }} size="sm" />
                    AGGIUNGI/RIMUOVI
                  </Button>

                ) : (
                  <Button
                    onClick={() => setModalShow(true)}
                    size="sm"
                    className="ms-2"
                  >
                    <FontAwesomeIcon aria-hidden="true" icon="fa-solid fa-plus" style={{ marginRight: '4px' }} size="sm" />
                    AGGIUNGI
                  </Button>
                )}

              </p>
            </div>
            )}
            {(modelloSelected.criteri?.length > 0
              && statePiano.strutture_organizzative?.length > 0) ? (
                <>
                  <div className="d-flex mb-2">
                    <Button
                      onClick={() => setModalCalcola(true)}
                      size="sm"
                      disabled={isRanking === 1 || shouldDisableButtonCalcolaPunteggi()}
                    >
                      <FontAwesomeIcon aria-hidden="true" icon="fa-solid fa-calculator" style={{ marginRight: '4px' }} size="sm" />
                      CALCOLA PUNTEGGI
                    </Button>
                  </div>
                  <TablePrioritizzazione
                    criteriList={modelloSelected.criteri}
                    data={statePiano.strutture_organizzative}
                    onChange={onChangeModello}
                    // isDisabled={isCompilato}
                  />
                </>
              ) : (
                <EmptyState
                  title="Nessun modello selezionato"
                  subtitle="Selezionare un modello per procedere con la compilazione."
                />
              )}
          </div>
        </>
      )}
      {isRanking === 2 && (
        <Ranking
          struttureList={statePiano.strutture_organizzative}
          budgetTotale={statePiano.budget_assunzionale}
          statePiano={statePiano}
          setStatePiano={setStatePiano}
        />
      )}
      <ModaleCentroVerticale
        modalTitle="Strutture Organizzative disponibili"
        modalBody={(
          <SelezioneSO
            options={struttureOrganizzative}
            stateSelected={struttureOrganizzativeSelected}
            statePiano={statePiano}
            setStateSelected={setStruttureOrganizzativeSelected}
          />
        )}
        show={modalShow}
        labelConfirm="CONFERMA"
        onConfirm={() => onConfirmSO()}
        onClose={() => setModalShow(false)}
      />
      <ModaleCentroVerticale
        modalTitle={`Modifica modello selezionato - ${modelliList.filter(
          (el) => el.sequ_k_modello === modelloSelected.sequ_k_modello,
        )[0]?.desc_nome}`}
        modalBody={(
          <>
            <ModificaCriteri
              criteriList={modelloSelected.criteri}
              setModello={setModelloSelected}
            />
            {errore && (
              <Alert variant="danger">
                Errore: la somma delle percentuali deve essere pari a 100.
                Modificare i valori e cliccare CONFERMA.
              </Alert>
            )}
          </>
        )}
        show={modalCriteri}
        labelConfirm="CONFERMA"
        onConfirm={() => onConfirmCriteri()}
        onClose={() => setModalCriteri(false)}
      />
      <ModaleConferma
        title="Calcolo del Punteggio Complessivo"
        body="Confermi l'inserimento dei dati e del successivo calcolo del
        punteggio complessivo di ogni Struttura Organizzativa? Verifica
        l'inserimento di tutti i campi disponibili prima di procedere."
        labelBottone="CALCOLA"
        coloreAzione="primary"
        show={modalCalcola}
        handleClose={() => setModalCalcola(false)}
        handleOk={() => onConfirmCalcola()}
      />
    </>
  );
}

export default Prioritizzazione;
