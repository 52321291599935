import { Container, Form, ProgressBar } from 'react-bootstrap';
import Select from 'react-select';
import optionsFonte from '../data/optionsFonte';
import getVariantColor from '../../../../utils/getVariantColor';
import { controlla } from '../../../../utils/verificaPermessi';

const fieldsModaleCompetenzeAggiuntive = (
  onChange,
  dataSelected,
  errors,
  isOnModify,
  optionsGrading,
) => {
  const regEx = /^[A-Z]+/;
  // Function per calcolare il numero di competenze per la tipologia
  const getNumberCompetenze = (tipo) => {
    const competenzeFiltered = optionsGrading.filter(
      (opt) => opt?.codi_tipo_competenza === tipo,
    );
    return competenzeFiltered.length;
  };
  // Function per ritornare le competenze filtrate
  const getCompetenzeFiltered = (tipo) => {
    const competenzeFiltered = optionsGrading.filter(
      (opt) => opt?.codi_tipo_competenza === tipo,
    );
    return competenzeFiltered;
  };
  return (!isOnModify ? [
    {
      dataField: 'select',
      text: '',
      classes: 'column-data',
      style: { verticalAlign: 'middle' },
      headerStyle: () => ({ width: '5%' }),
      formatter: (e, row) => (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Form.Check
            type="checkbox"
            name={row.desc_codice}
            onChange={(event) => onChange(event, row)}
            checked={dataSelected[row.desc_codice]?.isSelected || false}
          />
        </div>
      ),
    },
    {
      dataField: 'desc_codice',
      text: 'Codice',
      classes: 'column-data',
      headerStyle: () => ({ width: '5%' }),
    },
    {
      dataField: 'desc_titolo',
      text: 'Titolo',
      classes: 'column-data',
      headerStyle: () => ({ width: '8%' }),
    },
    {
      dataField: 'desc_descrizione',
      text: 'Descrizione',
      classes: 'column-data',
      headerStyle: () => ({ width: '18.5%', minWidth: '250px' }),
      formatter: (e, row) => (
        <div className="overflow-y-auto" style={{ maxHeight: '16rem' }}>
          {row.desc_descrizione}
        </div>
      ),
    },
    {
      dataField: 'desc_anno',
      text: 'Anno',
      classes: 'column-data',
      headerStyle: () => ({ width: '8%', minWidth: '150px' }),
      formatter: (e, row) => (
        <div style={{
          display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap',
        }}
        >
          {(dataSelected[row.desc_codice]?.isSelected) ? (
            <>
              <Form.Control
                isInvalid={errors[row.desc_codice]?.desc_anno === true}
                type="number"
                placeholder="Es. 2024"
                min={0}
                defaultValue={(dataSelected[row.desc_codice]?.desc_anno)}
                onBlur={(event) => onChange(event, row)}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    event.target.blur();
                  }
                }}
                name="desc_anno"
              />
              <Form.Control.Feedback type="invalid">
                Campo anno obbligatorio
              </Form.Control.Feedback>
            </>
          ) : (
            <Form.Control
              type="number"
              value=""
              placeholder="Es. 2024"
              disabled
            />
          )}
        </div>
      ),
    },
    {
      dataField: 'desc_fonte',
      text: 'Evento',
      classes: 'column-data',
      headerStyle: () => ({ width: '16.5%', minWidth: '200px' }),
      formatter: (e, row) => (
        <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
          {(dataSelected[row.desc_codice]?.isSelected) ? (
            <>
              <Select
                className={`w-100 ${errors[row.desc_codice]?.desc_fonte ? 'error-state-select' : ''}`}
                id="desc_fonte"
                name="desc_fonte"
                options={optionsFonte}
                placeholder="Inserisci un evento"
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.value}
                value={optionsFonte.find(
                  (option) => option.value === dataSelected[row.desc_codice]?.desc_fonte,
                ) || ''}
                onChange={(selectedOption, config) => { onChange(selectedOption, row, config); }}
              />
              {errors[row.desc_codice]?.desc_fonte && (
              <Form.Control.Feedback className="d-block" type="invalid">
                Campo evento obbligatorio
              </Form.Control.Feedback>
              )}
            </>
          ) : (
            <Select
              className="w-100"
              placeholder="Inserisci un evento"
              value=""
              isDisabled
            />
          )}

        </div>
      ),
    },
    {
      dataField: 'desc_note',
      text: 'Note',
      classes: 'column-data',
      headerStyle: () => ({ width: '22.5%', minWidth: '250px' }),
      formatter: (e, row) => (
        <div style={{
          display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap',
        }}
        >
          {(dataSelected[row.desc_codice]?.isSelected) ? (
            <Form.Control
              as="textarea"
              placeholder="Inserisci una nota"
              rows={10}
              defaultValue={(dataSelected[row.desc_codice]?.desc_note)}
              onBlur={(event) => onChange(event, row)}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  event.target.blur();
                }
              }}
              name="desc_note"
            />
          ) : (
            <Form.Control
              as="textarea"
              placeholder="Inserisci una nota"
              rows={10}
              value=""
              disabled
            />
          )}

        </div>
      ),
    },
    {
      dataField: '',
      text: 'Grading',
      classes: 'column-data',
      headerStyle: () => ({ width: '16.5%', minWidth: '200px' }),
      formatter: (e, row) => (
        <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
          {(dataSelected[row.desc_codice]?.isSelected) ? (
            <>
              <Select
                className={`w-100 ${errors[row.desc_codice]?.codi_grading ? 'error-state-select' : ''}`}
                id="grading"
                name="codi_grading"
                placeholder="Associa un grading"
                options={getCompetenzeFiltered(row?.desc_codice?.match(regEx)?.[0])}
                getOptionLabel={(option) => (
                  <Container className="p-0">
                    {option.desc_titolo}
                    <ProgressBar
                      variant={
                    getVariantColor(
                      (option.codi_valore / getNumberCompetenze(
                        row?.desc_codice?.match(regEx)?.[0],
                      )) * 100,
                    )
                  }
                      label={option.codi_valore}
                      now={(option.codi_valore / getNumberCompetenze(
                        row?.desc_codice?.match(regEx)?.[0],
                      )) * 100}
                    />
                  </Container>
                )}
                getOptionValue={(option) => option.codi_valore}
                value={getCompetenzeFiltered(row?.desc_codice?.match(regEx)?.[0]).filter(
                  (
                    competenza,
                  ) => competenza.codi_valore === dataSelected[row.desc_codice].codi_grading,
                ) || ''}
                onChange={(selectedOption, config) => { onChange(selectedOption, row, config); }}
              />
              {errors[row.desc_codice]?.codi_grading && (
              <Form.Control.Feedback className="d-block" type="invalid">
                Campo grading obbligatorio
              </Form.Control.Feedback>
              )}
            </>
          ) : (
            <Select
              className="w-100"
              id="grading"
              name="codi_grading"
              placeholder="Associa un grading"
              options={getCompetenzeFiltered(row?.desc_codice?.match(regEx)?.[0])}
              getOptionLabel={(option) => (
                <Container className="p-0">
                  {option.desc_titolo}
                  <ProgressBar
                    variant={
                      getVariantColor((option.codi_valore / getNumberCompetenze(
                        row?.desc_codice?.match(regEx)?.[0],
                      )) * 100)
                    }
                    label={option.codi_valore}
                    now={(option.codi_valore / getNumberCompetenze(
                      row?.desc_codice?.match(regEx)?.[0],
                    )) * 100}
                  />
                </Container>
              )}
              getOptionValue={(option) => option.codi_valore}
              value=""
              onChange={(selectedOption, config) => { onChange(selectedOption, row, config); }}
              isDisabled
            />
          )}
        </div>
      ),
    },
  ] : [
    {
      dataField: 'desc_codice',
      text: 'Codice',
      classes: 'column-data',
      headerStyle: () => ({ width: '5%' }),
    },
    {
      dataField: 'desc_titolo',
      text: 'Titolo',
      classes: 'column-data',
      headerStyle: () => ({ width: '8%' }),
    },
    {
      dataField: 'desc_descrizione',
      text: 'Descrizione',
      classes: 'column-data',
      headerStyle: () => ({ width: '18.5%', minWidth: '250px' }),
      formatter: (e, row) => (
        <div className="overflow-y-auto" style={{ maxHeight: '16rem' }}>
          {row.desc_descrizione}
        </div>
      ),
    },
    {
      dataField: 'desc_anno',
      text: 'Anno',
      classes: 'column-data',
      headerStyle: () => ({ width: '8%', minWidth: '150px' }),
      formatter: (e, row) => (
        <div style={{
          display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap',
        }}
        >
          <Form.Control
            isInvalid={errors[row.desc_codice]?.desc_anno === true}
            type="number"
            placeholder="Es. 2024"
            min={0}
            defaultValue={(row?.desc_anno)}
            onBlur={(event) => onChange(event, row)}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                event.target.blur();
              }
            }}
            name="desc_anno"
            disabled={controlla('Z33')}
          />
          <Form.Control.Feedback type="invalid">
            Campo anno obbligatorio
          </Form.Control.Feedback>
        </div>
      ),
    },
    {
      dataField: 'desc_fonte',
      text: 'Evento',
      classes: 'column-data',
      headerStyle: () => ({ width: '16.5%', minWidth: '200px' }),
      formatter: (e, row) => (
        <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
          <Select
            className={`w-100 ${errors[row.desc_codice]?.desc_fonte ? 'error-state-select' : ''}`}
            id="desc_fonte"
            name="desc_fonte"
            options={optionsFonte}
            placeholder="Inserisci un evento"
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
            value={optionsFonte.find(
              (option) => option.value === row?.desc_fonte,
            ) || ''}
            onChange={(selectedOption, config) => {
              const modifiedConfig = { ...config, isModify: true };
              onChange(selectedOption, row, modifiedConfig);
            }}
            isDisabled={controlla('Z33')}
          />
          {errors[row.desc_codice]?.desc_fonte && (
          <Form.Control.Feedback className="d-block" type="invalid">
            Campo evento obbligatorio
          </Form.Control.Feedback>
          )}
        </div>
      ),
    },
    {
      dataField: 'desc_note',
      text: 'Note',
      classes: 'column-data',
      headerStyle: () => ({ width: '22.5%', minWidth: '250px' }),
      formatter: (e, row) => (
        <div style={{
          display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap',
        }}
        >
          <Form.Control
            as="textarea"
            placeholder="Inserisci una nota"
            rows={10}
            defaultValue={row?.desc_note}
            onBlur={(event) => onChange(event, row)}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                event.target.blur();
              }
            }}
            name="desc_note"
            disabled={controlla('Z33')}
          />
        </div>
      ),
    },
    {
      dataField: '',
      text: 'Grading',
      classes: 'column-data',
      headerStyle: () => ({ width: '16.5%', minWidth: '200px' }),
      formatter: (e, row) => (
        <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
          <Select
            className={`w-100 ${errors[row.desc_codice]?.codi_grading ? 'error-state-select' : ''}`}
            id="grading"
            name="codi_grading"
            placeholder="Associa un grading"
            options={getCompetenzeFiltered(row?.desc_codice?.match(regEx)?.[0])}
            getOptionLabel={(option) => (
              <Container className="p-0">
                {option.desc_titolo}
                <ProgressBar
                  variant={
                    getVariantColor(
                      (option.codi_valore / getNumberCompetenze(
                        row?.desc_codice?.match(regEx)?.[0],
                      )) * 100,
                    )
                  }
                  label={option.codi_valore}
                  now={(option.codi_valore / getNumberCompetenze(
                    row?.desc_codice?.match(regEx)?.[0],
                  )) * 100}
                />
              </Container>
            )}
            getOptionValue={(option) => option.codi_valore}
            value={getCompetenzeFiltered(row?.desc_codice?.match(regEx)?.[0]).filter(
              (
                competenza,
              ) => competenza.codi_valore === row?.codi_grading,
            ) || ''}
            onChange={(selectedOption, config) => {
              const modifiedConfig = { ...config, isModify: true };
              onChange(selectedOption, row, modifiedConfig);
            }}
            isDisabled={controlla('Z33')}
          />
          {errors[row.desc_codice]?.codi_grading && (
          <Form.Control.Feedback className="d-block" type="invalid">
            Campo grading obbligatorio
          </Form.Control.Feedback>
          )}
        </div>
      ),
    },
  ]);
};

export default fieldsModaleCompetenzeAggiuntive;
