import { Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

function DemoFormCicloAssessment({
  stateCicloAssessment,
  disabled = false,
}) {
  return (
    <Form.Group as={Col} lg controlId="desc_titolo">
      <Form.Label>
        Titolo
      </Form.Label>
      <Form.Control
        name="desc_titolo"
        value={stateCicloAssessment.desc_titolo}
        disabled={disabled}
      />
      <Form.Label>
        Stato
      </Form.Label>
      <Form.Control
        name="desc_stato"
        value={stateCicloAssessment.fk_codi_stato_d_stato_ciclo_assesment.desc_stato}
        disabled={disabled}
      />
      <Form.Label>
        Data Avvio
      </Form.Label>
      <Form.Control
        type="date"
        name="data_avvio"
        value={stateCicloAssessment.data_avvio}
        disabled={disabled}
      />
      <Form.Label>
        Data Chiusura
      </Form.Label>
      <Form.Control
        type="date"
        name="data_chiusura"
        value={stateCicloAssessment.data_chiusura}
        disabled={disabled}
      />
    </Form.Group>
  );
}

export default DemoFormCicloAssessment;
