import { useContext, useEffect, useState } from 'react';
import {
  Col, Form, Row,
} from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import { fieldsProfonditaStoricaIdentita, fieldsNoProfonditaStoricaIdentita } from '../../../../../components/Ruoli/fieldsTableIdentita';
import Table from '../../../../../components/Shared/Table';
import utenteService from '../../../../../services/utentiService';
import history from '../../../../../utils/history';
import useQuery from '../../../../../utils/useQuery';
import TitlePage from '../../../../../components/GestisciUtenti/TitoloPagina';
import EmptyState from '../../../../../components/EmptyState';
import { formatoData } from '../../../../../utils/formatData';
import { getItem } from '../../../../../utils/storage';
import SistemaProfessionaleService from '../../../../../services/sistemaProfessionaleService';
import { Context } from '../../../../../utils/Context';
import AssessmentService from '../../../../../services/assessmentService';
import fieldsStoricoProfili from '../../../scheda-competenze/fields/fieldsStoricoProfili';

function DettaglioUtentePerAmministratore() {
  const { idUtente } = useParams();
  const { idAmministrazione } = JSON.parse(getItem('identita'));
  const { amministrazione } = JSON.parse(getItem('identita'));
  const [context, setContext] = useContext(Context);
  const { sequ_k_comparto } = getItem('compartoSelected') ? JSON.parse(getItem('compartoSelected')) : 0;
  const query = useQuery();
  const [utente, setUtente] = useState({});
  const [stateTrusted, setStateTrusted] = useState(false);
  const [profiliRuoloList, setProfiliRuoloList] = useState([]);
  const [storicoProfilo, setStoricoProfilo] = useState([]);
  const [filtriUtente, setFiltriUtente] = useState({
    profondita_storica_connettori: 'false',
    profondita_storica_identita: 'false',
  });

  // Function collegata al servizio per ottenere lo storico dei profili ruolo dell'utente
  async function getStoricoProfiliRuoloByDipendente(idDipendente) {
    try {
      const response = await AssessmentService
        .getStoricoProfiliRuoloByDipendente(idDipendente);
      setStoricoProfilo(response);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // Function collegata al servizio per ottenere i dati dell'utente selezionato
  async function getUtente() {
    setStateTrusted(query.get('trusted'));
    const response = await utenteService.getUtenteById(
      idUtente,
      { trusted: query.get('trusted'), ...filtriUtente },
    );
    if (idAmministrazione) {
      getStoricoProfiliRuoloByDipendente(response.sequ_k_utente_trusted);
    }
    setUtente({
      ...response,
      fk_sequ_profilo_ruolo: response.profilo_ruolo_associato?.fk_sequ_profilo_ruolo ?? null,
    });
  }

  // Function per gestion dei filtri utente
  function onChangeFiltriUtente(e) {
    const { id } = e.target;
    const { value } = e.target;
    setFiltriUtente({
      ...filtriUtente,
      [id]: value,
    });
  }

  // Function collegata al servizio per ottenere la lista dei Profili di Ruolo
  async function getProfiliRuolo(offset, limit, comparto) {
    try {
      const filterSession = { fk_sequ_comparto: comparto };
      const response = await SistemaProfessionaleService
        .getProfiliRuolo(filterSession, offset, limit);
      setProfiliRuoloList(response.rows);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // useEffect
  useEffect(() => {
    getUtente();
    getProfiliRuolo(0, 1000, sequ_k_comparto); // IMPOSTATO LIMIT 1000
  }, []);

  // useEffect
  useEffect(() => {
    getUtente();
  }, [filtriUtente]);

  return (
    <>
      <TitlePage
        title="Dettaglio utente"
        subtitle="È possibile visualizzare i dettagli dell'utente"
        showIndietro
        functionIndietro={() => history.push('/area-riservata/amministrazione/gestione-utenti')}
      />
      {stateTrusted
        ? (
          <Form className="mt-3">
            <Row className="mb-3">
              <Form.Group as={Col} lg controlId="desc_nome">
                <Form.Label style={{ fontWeight: 'bold' }}>
                  Nome
                </Form.Label>
                <Form.Control
                  name="desc_nome"
                  placeholder="Nome"
                  value={utente.desc_nome}
                  disabled
                />
              </Form.Group>

              <Form.Group as={Col} lg controlId="desc_cognome">
                <Form.Label style={{ fontWeight: 'bold' }}>
                  Cognome
                </Form.Label>
                <Form.Control
                  name="desc_cognome"
                  placeholder="Cognome"
                  value={utente.desc_cognome}
                  disabled
                />
              </Form.Group>
              <Form.Group as={Col} lg controlId="codi_id_fiscale">
                <Form.Label style={{ fontWeight: 'bold' }}>
                  Codice fiscale
                </Form.Label>
                <Form.Control
                  name="codi_id_fiscale"
                  placeholder="Codice fiscale"
                  value={utente.codi_id_fiscale}
                  disabled
                />
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} lg controlId="data_registrazione">
                <Form.Label style={{ fontWeight: 'bold' }}>
                  Data registrazione
                </Form.Label>
                <Form.Control
                  name="data_registrazione"
                  placeholder="Data registrazione"
                  value={formatoData(utente.data_registrazione)}
                  disabled
                />
              </Form.Group>
              <Form.Group as={Col} lg controlId="desc_email">
                <Form.Label style={{ fontWeight: 'bold' }}>
                  Indirizzo email
                </Form.Label>
                <Form.Control
                  name="desc_email"
                  placeholder="Indirizzo email"
                  value={utente.desc_email}
                  disabled
                />
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} lg controlId="">
                <Form.Label style={{ fontWeight: 'bold' }}>
                  Area Contrattuale
                </Form.Label>
                <Form.Control
                  name="desc_area_contrattuale"
                  placeholder="-"
                  value={utente.desc_area_contrattuale}
                  disabled
                />
              </Form.Group>
              <Form.Group as={Col} lg controlId="">
                <Form.Label style={{ fontWeight: 'bold' }}>
                  Profilo di Accesso
                </Form.Label>
                <Form.Control
                  name="desc_profilo_accesso"
                  placeholder="-"
                  value={utente.desc_profilo_accesso}
                  disabled
                />
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} lg controlId="">
                <Form.Label style={{ fontWeight: 'bold' }}>
                  Struttura Organizzativa di I Livello
                </Form.Label>
                <Form.Control
                  name="desc_struttura_organizzativa_i_liv"
                  placeholder="-"
                  value={utente.desc_struttura_organizzativa_i_liv}
                  disabled
                />
              </Form.Group>
              <Form.Group as={Col} lg controlId="">
                <Form.Label style={{ fontWeight: 'bold' }}>
                  Struttura Organizzativa di II Livello
                </Form.Label>
                <Form.Control
                  name="desc_struttura_organizzativa_ii_liv"
                  placeholder="-"
                  value={utente.desc_struttura_organizzativa_ii_liv}
                  disabled
                />
              </Form.Group>
            </Row>

            {idAmministrazione && (
              <Row className="mb-3">
                <p className="fw-bold fs-3">
                  Profilo di Ruolo associato all&apos;utente
                </p>
                <Form.Group as={Col} lg controlId="">
                  <Form.Label style={{ fontWeight: 'bold' }}>
                    Profilo di Ruolo corrente
                  </Form.Label>
                  {utente.fk_sequ_profilo_ruolo ? (
                    <Select
                      id="fk_sequ_profilo_ruolo"
                      name="fk_sequ_profilo_ruolo"
                      options={profiliRuoloList}
                      placeholder="Scegli profilo di ruolo"
                      getOptionLabel={(option) => option.desc_titolo}
                      getOptionValue={(option) => option.sequ_k_profilo_ruolo}
                      value={profiliRuoloList?.filter(
                        (el) => el.sequ_k_profilo_ruolo === utente.fk_sequ_profilo_ruolo,
                      )?.[0]}
                      isDisabled
                    />
                  ) : (
                    <Form.Control
                      name="fk_sequ_profilo_ruolo"
                      placeholder="-"
                      disabled
                    />
                  )}
                </Form.Group>
                <Form.Label style={{ fontWeight: 'bold', marginTop: '1rem' }}>
                  Storico dei Profili di Ruolo dell&apos;utente
                </Form.Label>
                {storicoProfilo.filter((el) => el.data_fine_validita !== null).length > 0
                  ? (
                    <Table
                      data={storicoProfilo.filter((el) => el.data_fine_validita !== null)}
                      fields={fieldsStoricoProfili()}
                      keyField="desc_titolo"
                    />
                  ) : (
                    <EmptyState
                      title="Nessun Profilo di Ruolo trovato"
                      subtitle="L'utente non possiede nessun storico profili"
                    />
                  )}
              </Row>
            )}

            <Row className="mb-3">
              {utente.identita_associate?.length > 0 ? (
                <>
                  <p className="fw-bold fs-3">
                    Elenco identità associate
                  </p>
                  <Table
                    data={utente.identita_associate}
                    fields={filtriUtente.profondita_storica_identita === 'true'
                      ? fieldsProfonditaStoricaIdentita(
                        idAmministrazione,
                        amministrazione,
                      ) : fieldsNoProfonditaStoricaIdentita(
                        idAmministrazione,
                        amministrazione,
                      )}
                    keyField="sequ_k_rel_trusted_identita"
                    profonditaStorica={(
                      <Form.Group controlId="profondita_storica_identita">
                        <Form.Label style={{ fontWeight: 'bold' }}>
                          Visualizza profondità storica
                        </Form.Label>
                        <Form.Select
                          onChange={onChangeFiltriUtente}
                          value={filtriUtente.profondita_storica_identita}
                        >
                          <option value="true">Sì</option>
                          <option value="false">No</option>
                        </Form.Select>
                      </Form.Group>
                  )}
                    onTableChange={(e, { sortField, sortOrder, data }) => {
                      data.sort((a, b) => {
                        if (sortOrder === 'asc') {
                          return a[sortField] > b[sortField] ? 1 : -1;
                        }
                        if (sortOrder === 'desc') {
                          return a[sortField] < b[sortField] ? 1 : -1;
                        }
                        return 0;
                      });
                    }}
                  />
                </>
              ) : (
                <EmptyState
                  title="Elenco identità associate"
                  subtitle={'Non ci sono identità associate a '.concat(utente.codi_id_fiscale)}
                  marginBottom="3rem"
                  marginTop="3rem"
                />
              )}
            </Row>
          </Form>
        )
        : (
          <Form className="mt-3">
            <Row className="mb-3">
              <Form.Group as={Col} lg controlId="desc_nome">
                <Form.Label style={{ fontWeight: 'bold' }}>
                  Nome
                </Form.Label>
                <Form.Control
                  name="desc_nome"
                  placeholder="Nome"
                  value={utente.desc_nome}
                  disabled
                />
              </Form.Group>

              <Form.Group as={Col} lg controlId="desc_cognome">
                <Form.Label style={{ fontWeight: 'bold' }}>
                  Cognome
                </Form.Label>
                <Form.Control
                  name="desc_cognome"
                  placeholder="Cognome"
                  value={utente.desc_cognome}
                  disabled
                />
              </Form.Group>
              <Form.Group as={Col} lg controlId="codi_id_connettore_utente">
                <Form.Label style={{ fontWeight: 'bold' }}>
                  Identificativo
                </Form.Label>
                <Form.Control
                  name="codi_id_connettore_utente"
                  placeholder="Identificativo"
                  value={utente.codi_id_connettore_utente}
                  disabled
                />
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} lg controlId="data_registrazione">
                <Form.Label style={{ fontWeight: 'bold' }}>
                  Data registrazione
                </Form.Label>
                <Form.Control
                  name="data_registrazione"
                  placeholder="Data registrazione"
                  value={utente.data_registrazione}
                  disabled
                />
              </Form.Group>
              <Form.Group as={Col} lg controlId="desc_tipo_connettore">
                <Form.Label style={{ fontWeight: 'bold' }}>
                  Tipo connettore
                </Form.Label>
                <Form.Control
                  name="desc_tipo_connettore"
                  placeholder="Tipo connettore"
                  value={utente.desc_tipo_connettore}
                  disabled
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              {utente.identita_associate?.length > 0 ? (
                <>
                  <p className="fw-bold fs-3">
                    Elenco identità associate
                  </p>
                  <Table
                    data={utente.identita_associate}
                    fields={filtriUtente.profondita_storica_identita === 'true'
                      ? fieldsProfonditaStoricaIdentita(idAmministrazione, true)
                      : fieldsNoProfonditaStoricaIdentita(idAmministrazione, true)}
                    keyField="sequ_k_identita"
                    profonditaStorica={(
                      <Form.Group controlId="profondita_storica_identita">
                        <Form.Label style={{ fontWeight: 'bold' }}>
                          Visualizza profondità storica
                        </Form.Label>
                        <Form.Select
                          onChange={onChangeFiltriUtente}
                          value={filtriUtente.profondita_storica_identita}
                        >
                          <option value="true">Sì</option>
                          <option value="false">No</option>
                        </Form.Select>
                      </Form.Group>
                  )}
                    onTableChange={(e, { sortField, sortOrder, data }) => {
                      data.sort((a, b) => {
                        if (sortOrder === 'asc') {
                          return a[sortField] > b[sortField] ? 1 : -1;
                        }
                        if (sortOrder === 'desc') {
                          return a[sortField] < b[sortField] ? 1 : -1;
                        }
                        return 0;
                      });
                    }}
                  />
                </>
              ) : (
                <EmptyState
                  title="Elenco identità associate"
                  subtitle={'Non ci sono identità associate a '.concat(utente.codi_id_fiscale)}
                  marginBottom="3rem"
                  marginTop="3rem"
                />
              )}
            </Row>

          </Form>
        )}
    </>
  );
}

export default DettaglioUtentePerAmministratore;
