import { Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import optionsTipoCompetenza from '../competenze/constants/optionsTipoCompetenza';

function FormGrading({
  stateGrading,
  onChange,
  disabled = false,
  isNew = false,
}) {
  return (
    <Form.Group as={Col} lg controlId="grading">
      <Form.Label>
        Titolo
      </Form.Label>
      <Form.Control
        name="desc_titolo"
        value={stateGrading.desc_titolo}
        placeholder="Inserisci il titolo"
        onChange={onChange}
        maxLength={100}
        required
        disabled={disabled}
      />
      <Form.Label>
        Descrizione
      </Form.Label>
      <Form.Control
        name="desc_descrizione"
        value={stateGrading.desc_descrizione}
        placeholder="Inserisci la descrizione"
        onChange={onChange}
        as="textarea"
        rows={3}
        required
        disabled={disabled}
      />
      <Form.Label>
        Valore
      </Form.Label>
      <Form.Control
        name="codi_valore"
        value={stateGrading.codi_valore}
        placeholder="Inserisci il valore"
        onChange={onChange}
        maxLength={100}
        required
        disabled={disabled}
        type="number"
      />
      <Form.Label>
        Tipo Competenza
      </Form.Label>
      <Select
        id="codi_tipo_competenza"
        name="codi_tipo_competenza"
        options={optionsTipoCompetenza}
        placeholder="Scegli tipo competenza"
        isDisabled={!isNew}
        getOptionLabel={(option) => option.desc_tipo_competenza}
        getOptionValue={(option) => option.codi_tipo_competenza}
        value={optionsTipoCompetenza?.filter(
          (el) => el.codi_tipo_competenza === stateGrading.codi_tipo_competenza,
        )?.[0]}
        onChange={(selectedOption, config) => { onChange(selectedOption, config); }}
        isClearable
      />
    </Form.Group>
  );
}

export default FormGrading;
