/* eslint-disable react/no-unknown-property */
/* eslint-disable no-plusplus */
import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { Col, Container } from 'react-bootstrap';
import AssessmentService from '../../../../services/assessmentService';
import { Context } from '../../../../utils/Context';
import TitlePage from '../../../../components/GestisciUtenti/TitoloPagina';
import history from '../../../../utils/history';
import fieldsDefinizioneCosti from '../aggiungi-piano/fields/fieldsDefinizioneCosti';
import Table from '../../../../components/Shared/Table';
import fieldsProgressioneVerticale from '../aggiungi-piano/fields/fieldsProgressioneVerticale';
import TablePrioritizzazione from '../aggiungi-piano/components/prioritizzazione/TablePrioritizzazione';
import Ranking from '../aggiungi-piano/components/prioritizzazione/Ranking';
import fieldsProfiliRichiesti from '../aggiungi-piano/fields/fieldsProfiliRichiesti';
import ScelteCoperturaFullShowPolicyMaker from '../aggiungi-piano/pagine/ScelteCoperturaFullShowPolicyMaker';
import SpinnerBody from '../../../../components/Shared/SpinnerBody';
import formatCentinaia from '../../../../utils/formatCentinaia';

function VisualizzaPiano() {
  const [stato, setState] = useState(useLocation().state);
  const [context, setContext] = useContext(Context);
  const [processoState, setProcessoState] = useState({});
  const [areeContrattuali, setAreeContrattuali] = useState([]);
  const [progressioneList, setProgressioneList] = useState([]);
  const [modelloSelected, setModelloSelected] = useState([]);
  const [profiliTotali, setProfiliTotali] = useState([]);
  const [ipotesiCopertura, setIpotesiCopertura] = useState([]);
  const [profiliDirettore, setProfiliDirettore] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // Function collegata alla chiamata per ottenere il modello selezionato
  async function getModello(id) {
    try {
      const response = await AssessmentService.getModelloById(id);
      setModelloSelected(response);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // Function collegata al servizio per ottenere il piano fabbisogno idFabbisogno
  async function getPianoFabbisognoById() {
    try {
      const response = await AssessmentService.getProcessoById(stato._id);
      setProcessoState(response);
      setAreeContrattuali(response.aree_contrattuali);
      if (stato.idIdentita === 10) {
        const struttura = response.strutture_organizzative.find(
          (so) => so.struttura_organizzativa === stato.utente.utenteSO,
        );
        setModelloSelected(struttura);
        setProfiliDirettore(struttura.profili);
      }
      getModello(response?.modello?.sequ_k_modello);
      const profiliTot = response.strutture_organizzative.map(
        (so) => so.profili,
      ).flat();
      setProfiliTotali(profiliTot);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  async function getSceltaCopertura() {
    try {
      const response = await AssessmentService.getSceltaCopertura();
      const listaSceltaCopertura = response.map(
        (el) => el.descrizione,
      );
      setIpotesiCopertura(listaSceltaCopertura);
      setIsLoading(false);
    } catch (error) {
      setContext(() => ({
        ...context, open: true, testoErrore: error?.data?.message || 'Errore', statusCode: error?.status,
      }));
    }
  }

  // Function per calcolare la progressione delle aree contrattuali
  const getProgressioneVerticale = (listaAree) => {
    const areeSorted = [...listaAree].sort(
      (a, b) => b.costo_area - a.costo_area,
    );

    const progressione = [];
    for (let ind = 0; ind < areeSorted.length - 1; ind++) {
      progressione.push({
        nomeDa: areeSorted[ind + 1].nome_area,
        costoDa: areeSorted[ind + 1].costo_area,
        nomeA: areeSorted[ind].nome_area,
        costoA: areeSorted[ind].costo_area,
        differenza: areeSorted[ind].costo_area - areeSorted[ind + 1].costo_area,
      });
    }

    setProgressioneList(progressione);
  };

  const totaleCostiInTable = () => {
    const totale = profiliDirettore
      .reduce((acc, item) => acc + item.costo_annuale * item.unita_richieste, 0);
    return `${totale.toString()}`;
  };

  useEffect(() => {
    getPianoFabbisognoById();
    getSceltaCopertura();
  }, []);

  useEffect(() => {
    getProgressioneVerticale(areeContrattuali);
  }, [areeContrattuali]);

  return (
    isLoading
      ? (
        <SpinnerBody />
      )
      : (
        <Container className="p-0">
          <TitlePage
            title={`${processoState.nome_processo} - ${processoState.anno_riferimento}`}
            showIndietro
            functionIndietro={() => history.push('/area-riservata/demo-bpm/')}
          />
          {stato.idIdentita === 3 && (
          <>
            <div as={Col}>
              <h4>Definizione dei Costi</h4>
              <p style={{ fontWeight: 'bold' }}>
                Costo per Area Contrattuale
              </p>
              <Table
                data={areeContrattuali}
                fields={fieldsDefinizioneCosti(areeContrattuali, () => {}, true)}
                keyField="nome_area"
              />
            </div>
            <p style={{ fontWeight: 'bold' }}>
              Costo per progressione verticale
            </p>
            <div as={Col}>
              <Table
                data={progressioneList}
                fields={fieldsProgressioneVerticale()}
                keyField="costo_progressione_verticale"
              />
            </div>
            <hr />
            <div as={Col}>
              <h4>Prioritizzazione</h4>
              <p>
                <b>Budget Assunzionale</b>
                {' '}
                {formatCentinaia(processoState.budget_assunzionale)}
                €
              </p>
              {modelloSelected?.length > 0 && (
              <TablePrioritizzazione
                criteriList={modelloSelected}
                data={processoState.strutture_organizzative}
                isDisabled
              />
              )}
              {Object.keys(processoState).length > 0 && (
              <Ranking
                struttureList={processoState.strutture_organizzative}
                budgetTotale={processoState.budget_assunzionale}
                statePiano={processoState}
                setStatePiano={() => {}}
              />
              )}
            </div>
            <hr />
            <div as={Col}>
              <h4>Scelte di Copertura</h4>
              {processoState && (
              <ScelteCoperturaFullShowPolicyMaker
                tableData={profiliTotali || []}
                setTableData={() => {}}
                processoState={processoState}
                blockTable
              />
              )}
            </div>
          </>
          )}
          {stato.idIdentita === 10 && (
          <>
            <h4>Richieste di Fabbisogno</h4>
            <Table
              data={profiliDirettore}
              fields={fieldsProfiliRichiesti(
                ipotesiCopertura,
                () => {},
                () => {},
                profiliDirettore,
                totaleCostiInTable,
                ' ',
                ' ',
                ' ',
                processoState,
                true,
              )}
              keyField="desc_codice"
            />
          </>
          )}
        </Container>
      )
  );
}

export default VisualizzaPiano;
