import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import themeColors from '../../../utils/contants/theme-colors';
import { controlla } from '../../../utils/verificaPermessi';

const fieldsGrading = (onDelete, onView, onModify) => (
  [
    {
      dataField: 'desc_titolo',
      text: 'Titolo',
      sort: true,
      classes: 'column-data',
      headerStyle: () => ({ width: '20%' }),
    },
    {
      dataField: 'desc_descrizione',
      text: 'Descrizione',
      sort: true,
      classes: 'column-data',
      headerStyle: () => ({ width: '50%' }),
    },
    {
      dataField: 'codi_valore',
      text: 'Valore',
      classes: 'column-data',
      headerStyle: () => ({ width: '10%' }),
    },
    {
      dataField: 'codi_tipo_competenza',
      text: 'Tipo competenza',
      classes: 'column-data',
      headerStyle: () => ({ width: '10%' }),
    },
    {
      dataField: 'df1',
      isDummyField: true,
      text: 'Azioni',
      headerStyle: () => ({ width: '20%' }),
      formatter: (e, row) => (
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <FontAwesomeIcon
            aria-hidden="true"
            icon={['fas', 'search']}
            size="lg"
            title="Visualizza"
            id={`Visualizza${row.sequ_k_grading}`}
            style={{ color: themeColors.primary }}
            onClick={() => onView({
              id: row.sequ_k_grading,
              codice: row.desc_titolo,
              idAmministrazione: row.fk_codi_amministrazione,
            })}
          />
          {controlla('X45') && (
            <FontAwesomeIcon
              aria-hidden="true"
              icon={['fas', 'edit']}
              size="lg"
              title="Modifica"
              id={`Modifica${row.sequ_k_grading}`}
              style={{ color: themeColors.primary }}
              onClick={() => onModify(
                true,
                { id: row.sequ_k_grading, codice: row.desc_titolo },
              )}
            />
          )}
          {controlla('X45') && (
            <FontAwesomeIcon
              aria-hidden="true"
              icon={['fas', 'trash']}
              size="lg"
              title="Elimina"
              id={`Visualizza${row.sequ_k_grading}`}
              style={{ color: themeColors.danger }}
              onClick={() => onDelete(
                { id: row.sequ_k_grading, codice: row.desc_titolo },
              )}
            />
          )}
        </div>
      ),
    },
  ]
);

export default fieldsGrading;
