import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import themeColors from '../../../utils/contants/theme-colors';
import { formatoDataEOra } from '../../../utils/formatData';
import { client } from '../../../utils/matrix';
import parseNome from '../../../utils/parseNomeMatrix';
import { controlla } from '../../../utils/verificaPermessi';
import styles from './index.module.scss';

function Board({ room, onDeleteMessage, getRoomsList }) {
  const [moreEvents, setMoreEvents] = useState(true);
  const [eventoUltimaLettura, setEventoUltimaLettura] = useState();

  // Funzione di scrollBack per caricare le notifiche meno recenti
  async function scrollBack() {
    const roomAggiornata = await client.scrollback(client.getRoom(room.roomId), 5);
    setMoreEvents(!!roomAggiornata?.oldState?.paginationToken);
    getRoomsList();
  }

  useEffect(() => {
    // Salvataggio dell'ultima notifica di lettura per impostare il componente grafico
    // console.log(room);
    // setEventoUltimaLettura(room?.receipts['m.read'][client.getUserId()].eventId);
  }, []);

  useEffect(() => () => {
    const eventsLength = room.getLiveTimeline().getEvents().length;
    // Invio ricevuta di lettura
    client?.sendReadReceipt(
      room.getLiveTimeline().getEvents()[eventsLength - 1],
    );
  }, []);

  return (
    <Card className={`p-3 my-3 ${styles.boardCard}`}>
      <Card.Body className={`${styles.bodyBoardCard}`} id="messages-scroll">
        {moreEvents
          ? (
            <div className="text-center">
              <Button onClick={scrollBack}>
                Carica altri messaggi...
              </Button>
            </div>
          )
          : null }
        {room
          .getLiveTimeline()
          .getEvents()
          .filter((el) => el.getType() !== 'm.room.power_levels' && el.getType() !== 'm.room.join_rules'
          && el.getType() !== 'm.room.history_visibility' && el.getType() !== 'm.room.redaction')
          .map((messageElement, index) => (
            <div key={messageElement.getId()}>
              <div className="d-flex justify-content-between my-3">
                <div className="d-flex">
                  <span className={`text-uppercase text-center mr-5 ${styles.badgeUserCircle} p-2`}>
                    {messageElement.getType() === 'm.room.create'
                      ? parseNome(room.getMember(messageElement.getContent().creator).name)
                      : parseNome(messageElement.sender.name)}
                  </span>
                  <span className="m-2">
                    <p>
                      <strong>
                        {messageElement.getType() === 'm.room.create'
                          ? room.getMember(messageElement.getContent().creator).name
                          : messageElement.sender.name}
                      </strong>
                    </p>
                    {messageElement.getType() === 'm.room.message'
                      ? messageElement.isRedacted()
                        ? <p>Messaggio eliminato</p>
                        : messageElement.getContent().msgtype === 'm.file' ? (
                          <a href={client.mxcUrlToHttp(messageElement.getContent().url)} target="_blank" rel="noreferrer">
                            {messageElement.getContent().body}
                          </a>
                        ) : messageElement.getContent().msgtype === 'm.image'
                          ? (
                            <a href={client.mxcUrlToHttp(messageElement.getContent().url)} target="_blank" rel="noreferrer">
                              <img src={client.mxcUrlToHttp(messageElement.getContent().url, 50, 37, 'scale')} alt={messageElement.getContent().body} />
                            </a>
                          )
                          : messageElement.getContent().msgtype === 'm.text'
                            ? (
                              <p>
                                {messageElement.getContent().body}
                              </p>
                            )
                            : null
                      : messageElement.getType() === 'm.room.member'
                        ? messageElement.getContent().membership === 'join'
                          ? (
                            <div>
                              { messageElement.getContent().displayname }
                              {' '}
                              è entrato nella stanza
                            </div>
                          )
                          : messageElement.getContent().membership === 'leave'
                            ? (
                              <div>
                                { messageElement.getContent().displayname }
                                {' '}
                                ha lasciato la stanza
                              </div>
                            )
                            : messageElement.getContent().membership === 'invite'
                              ? (
                                <div>
                                  Invitato
                                  {' '}
                                  { messageElement.getContent().displayname }
                                </div>
                              ) : null

                        : messageElement.getType() === 'm.room.name'
                          ? (
                            <div>
                              Il nome della stanza è cambiato in
                              {' '}
                              { messageElement.getContent().name }
                            </div>
                          )
                          : messageElement.getType() === 'm.room.create'
                            ? <div>La stanza è stata creata</div>
                            : messageElement.getType() === 'm.room.topic'
                              ? (
                                <div>
                                  Il topic della stanza è &quot;
                                  {messageElement.getContent().topic}
                                  &quot;
                                </div>
                              )
                              : messageElement.getType()}
                  </span>
                </div>
                <div className="pe-2">
                  {formatoDataEOra(messageElement.getDate())}
                  <br />
                  {messageElement.getSender() === client.getUserId()
                  && !messageElement.isRedacted()
                  && messageElement.getType() === 'm.room.message'
                    ? (
                      <button type="button" className={`border-0 ${styles.button} float-end`} onClick={() => onDeleteMessage(messageElement.getId())} title="Elimina" aria-label="Elimina">

                        <FontAwesomeIcon
                          aria-hidden="true"
                          icon={['fas', 'trash-alt']}
                          size="1x"
                          id={`elimina-icon-${messageElement.getId()}`}
                          style={{ color: themeColors.primary }}
                        />

                      </button>
                    ) : null }
                </div>
              </div>
              {messageElement.getId() === eventoUltimaLettura
              && index !== (room
                .getLiveTimeline()
                .getEvents()
                .filter((el) => el.getType() !== 'm.room.power_levels' && el.getType() !== 'm.room.join_rules'
                && el.getType() !== 'm.room.history_visibility' && el.getType() !== 'm.room.redaction').length - 1)
                ? <hr className="readMarker" />
                : <div className="separator border-1 border-bottom" />}
            </div>
          ))}
      </Card.Body>
    </Card>
  );
}
export default Board;
