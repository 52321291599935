/**
 * Funzione di verifica della modifica della lista delle competenze.
 * @param newState - (json) Contenente il nuovo State modificato con tutte
 * le proprietà, oltre l'array contenente le competenze.
 * @param compsOriginal - (array) Contenente tutte le informazioni originali
 * di partenza dell'oggetto analizzato.
 * @returns (json) Contenente tutte le informazioni da inviare in fase
 * di modifica: se le competenze non vengono modificate, vengono rimosse
 * e ritorna un json contenente tutte le informazioni generali dell'oggetto.
 */

const checkCompsModified = (newState, compsOriginal) => {
  const compsNotModified = compsOriginal.filter(
    (el) => newState.fk_sequ_competenze.some(
      (el1) => el.fk_sequ_competenza === el1.fk_sequ_competenza
        && el.grading === el1.grading,
    ),
  );
  const newObject = { ...newState };
  if (newState.fk_sequ_competenze.length === compsOriginal.length
      && compsNotModified.length === compsOriginal.length
  ) {
    delete newObject.fk_sequ_competenze;
    return newObject;
  }
  return newObject;
};

export default checkCompsModified;
