import {
  Button, Col, Container, Form, OverlayTrigger, Row, Tooltip,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Table from '../../../../components/Shared/Table';
import history from '../../../../utils/history';
import demoAutoAssessment from '../data/demoAutoAssessment';
import fieldsCompetenzeDemoAutoAssessment from './fieldsAutoAssessment';
import demoGradingList from '../data/demoGradingList';

function DemoAutoAssessment() {

  return (
    <Container className="p-0">
      {/* <TitlePage
        title={`Auto valutazione - ${demoAutoAssessment.desc_titolo}`}
        showSave
        showConsolida
        titleConsolida="CONSOLIDA AUTOVALUTAZIONE"
        showIndietro
        functionIndietro={() => { history.goBack(); }}
      /> */}
      <div className="w-100">
        <div className="mb-3">
          <h1>
            {' '}
            Auto valutazione -
            {' '}
            {demoAutoAssessment.desc_titolo}
            {' '}
          </h1>
        </div>
        <Row className="position-relative mb-2">
          <Col className="col-md-4">
            <Button variant="grey" className=" float-left" onClick={() => { history.goBack(); }}>
              {' '}
              <FontAwesomeIcon aria-hidden="true" icon={['fas', 'arrow-left']} />
              {' '}
              INDIETRO
              {' '}
            </Button>
          </Col>
          <Col>
            <Row className="float-end">
              <Col className="col-lg-auto text-end">
                <OverlayTrigger
                  placement="top"
                  overlay={(
                    <Tooltip id="tooltip-disabled">
                      Funzione per salvare le modifiche parziali o totali effettuate
                      inserendo l&apos;autovalutazione nella tabella sottostante,
                      con possibilità di modifica futura. Tale funzionalità
                      è legata all&apos;identità dipendente.
                    </Tooltip>
                )}
                >
                  <span className="d-inline-block">
                    <Button
                      style={{ color: 'white', pointerEvents: 'none' }}
                      variant="primary"
                      disabled
                    >
                      {' '}
                      <FontAwesomeIcon aria-hidden="true" icon={['fas', 'save']} />
                      {' '}
                      SALVA
                      {' '}
                    </Button>
                  </span>
                </OverlayTrigger>
              </Col>
              <Col className="col-lg-auto text-end">
                <OverlayTrigger
                  placement="top"
                  overlay={(
                    <Tooltip id="tooltip-disabled">
                      Funzione per consolidare la fase di autovalutazione,
                      tale funzionalità è legata all&apos;identità dipendente.
                    </Tooltip>
                )}
                >
                  <span className="d-inline-block">
                    <Button
                      style={{ color: 'white', pointerEvents: 'none' }}
                      variant="primary"
                      disabled
                    >
                      {' '}
                      <FontAwesomeIcon aria-hidden="true" icon={['fas', 'file-pen']} />
                      {' '}
                      CONSOLIDA AUTOVALUTAZIONE
                      {' '}
                    </Button>
                  </span>
                </OverlayTrigger>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <Form.Group as={Col} lg controlId="etero_assessment">
        <Form.Label>
          Ciclo di Assessment
        </Form.Label>
        <Form.Control
          name="fk_sequ_ciclo_assesment_d_ciclo_assesment.desc_titolo"
          value={demoAutoAssessment.desc_titolo}
          disabled
        />
        <Form.Label>
          Stato
        </Form.Label>
        <Form.Control
          name="fk_codi_stato_d_stato_assesment.desc_stato"
          value={demoAutoAssessment.desc_stato}
          disabled
        />
        <Form.Label>
          Dipendente
        </Form.Label>
        <Form.Control
          name="utente_dipendente"
          value={`${demoAutoAssessment.utente_dipendente.desc_nome} ${demoAutoAssessment.utente_dipendente.desc_cognome}`}
          disabled
        />
        <Form.Label>
          Responsabile
        </Form.Label>
        <Form.Control
          name="utente_responsabile"
          value={`${demoAutoAssessment.utente_responsabile.desc_nome} ${demoAutoAssessment.utente_responsabile.desc_cognome}`}
          disabled
        />
        <Form.Label>
          Profilo di ruolo
        </Form.Label>
        <Form.Control
          name="fk_sequ_profilo_ruolo_d_profilo_ruolo.desc_titolo"
          value={demoAutoAssessment.fk_sequ_profilo_ruolo_d_profilo_ruolo.desc_titolo}
          disabled
        />
        {demoGradingList.length > 0 && (
          <>
            <Form.Label>
              Auto Valutazione
            </Form.Label>
            <Table
              data={demoAutoAssessment.rel_competenza_assessment}
              fields={fieldsCompetenzeDemoAutoAssessment(
                demoGradingList,
              )}
              keyField="competenze_list_etero_assessment"
            />
          </>
        )}
      </Form.Group>
    </Container>
  );
}

export default DemoAutoAssessment;
