import { Accordion, Container } from 'react-bootstrap';
import { useContext, useState } from 'react';
import { Context } from '../../../../utils/Context';
import { getItem } from '../../../../utils/storage';
import StatisticheService from '../../../../services/statisticheService';
import TitlePage from '../../../../components/GestisciUtenti/TitoloPagina';
import AccordionCharts from '../../../../components/Accordion/Charts';
import optionsAssegnatiPerArea from '../optionsGrafici/optionsAssegnatiPerArea';
import optionsAssegnatiPerDimensione from '../optionsGrafici/optionsAssegnatiPerDimensione';
import optionsAssegnatiPerFamiglia from '../optionsGrafici/optionsAssegnatiPerFamiglia';
import optionsAssegnatiPerAmbito from '../optionsGrafici/optionsAssegnatiPerAmbito';
import optionsAssegnatiPerProfilo from '../optionsGrafici/optionsAssegnatiPerProfilo';
import optionsGradingCTPPerProfilo from '../optionsGrafici/optionsGradingCTPPerProfilo';
import optionsGradingCCPerProfilo from '../optionsGrafici/optionsGradingCCPerProfilo';
import optionsGradingCTSPerProfilo from '../optionsGrafici/optionsGradingCTSPerProfilo';
import optionsGradingCTP from '../optionsGrafici/optionsGradingCTP';
import optionsGradingCC from '../optionsGrafici/optionsGradingCC';
import optionsGradingCTS from '../optionsGrafici/optionsGradingCTS';
import FiltriAssegnatiPerProfessionale from '../filtriTables/filtriAssegnatiPerProfessionale';
import SistemaProfessionaleService from '../../../../services/sistemaProfessionaleService';

function ReportingAssessment() {
  const [context, setContext] = useContext(Context);
  const [statisticheList, setStatisticheList] = useState({});
  const [famiglieProfessionaliList, setFamiglieProfessionaliList] = useState([]);
  const [areeContrattualiList, setAreeContrattualiList] = useState([]);
  const { sequ_k_comparto } = getItem('compartoSelected') ? JSON.parse(getItem('compartoSelected')) : 0;
  const [filtri, setFiltri] = useState({});

  // Function chiamata API
  async function getStatistiche(fk_sequ_comparto, type, offset, limit, page, filters) {
    const typeList = type.replaceAll('-', '_');
    try {
      const response = await StatisticheService
        .getStatistiche(fk_sequ_comparto, type, offset, limit, filters);
      setStatisticheList({
        ...statisticheList,
        [typeList]: response.rows,
      });
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // Function collegata al servizio per ottenere la lista delle famiglie professionali
  async function getFamiglieProfessionali(offset, limit, filters) {
    try {
      const response = await SistemaProfessionaleService
        .getFamiglieProfessionali(filters, offset, limit);
      setFamiglieProfessionaliList(response.rows);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // Function collegata al servizio per ottenere la lista delle aree contrattuali
  async function getAreaContrattuale(offset, limit, page, filters) {
    try {
      const filterSession = filters || filtri;
      const response = await SistemaProfessionaleService
        .getAreaContrattuale(filterSession, offset, limit);
      setAreeContrattualiList(response.rows);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  return (
    <Container className="p-0">
      <TitlePage
        title="Statistiche Assessment e Gap Analysis"
        subtitle="In questa sezione è possibile visionare e scaricare in diversi
        formati tutte le statistiche disponibili."
      />
      <Accordion>
        <AccordionCharts
          eventKey="1"
          onClick={() => getStatistiche(
            sequ_k_comparto,
            'profili-assegnati-area-contrattuale',
            null,
            null,
            null,
            {},
          )}
          title="Istogramma Profili assegnati per Area Contrattuale"
          data={statisticheList.profili_assegnati_area_contrattuale}
          optionsChart={optionsAssegnatiPerArea}
        />
        <AccordionCharts
          eventKey="2"
          onClick={() => getStatistiche(
            sequ_k_comparto,
            'profili-assegnati-dimensione-professionale',
            null,
            null,
            null,
            {},
          )}
          title="Istogramma Profili assegnati per Dimensione Professionale"
          data={statisticheList.profili_assegnati_dimensione_professionale}
          optionsChart={optionsAssegnatiPerDimensione}
        />
        <AccordionCharts
          eventKey="3"
          onClick={() => getStatistiche(
            sequ_k_comparto,
            'profili-assegnati-famiglia-professionale',
            null,
            null,
            null,
            {},
          )}
          title="Istogramma Profili assegnati per Famiglia Professionale"
          data={statisticheList.profili_assegnati_famiglia_professionale}
          optionsChart={optionsAssegnatiPerFamiglia}
        />
        <AccordionCharts
          eventKey="4"
          onClick={() => getStatistiche(
            sequ_k_comparto,
            'profili-assegnati-ambito-di-ruolo',
            null,
            null,
            null,
            {},
          )}
          title="Istogramma Profili assegnati per Ambito di Ruolo"
          data={statisticheList.profili_assegnati_ambito_di_ruolo}
          optionsChart={optionsAssegnatiPerAmbito}
        />
        <AccordionCharts
          eventKey="5"
          onClick={() => {
            getStatistiche(
              sequ_k_comparto,
              'profili-assegnati-profilo-professionale',
              null,
              null,
              null,
              {},
            );
            getFamiglieProfessionali(0, 1000, { fk_sequ_comparto: sequ_k_comparto });
            getAreaContrattuale(0, 1000, { fk_sequ_comparto: sequ_k_comparto });
          }}
          title="Istogramma Profili assegnati per Profilo Professionale"
          data={statisticheList.profili_assegnati_profilo_professionale}
          optionsChart={optionsAssegnatiPerProfilo}
          filtriBody={(
            <FiltriAssegnatiPerProfessionale
              filtri={filtri}
              setFiltri={setFiltri}
              sequ_k_comparto={sequ_k_comparto}
              famiglieProfessionaliList={famiglieProfessionaliList}
              areeContrattualiList={areeContrattualiList}
              getProfiliAssegnatiPerProfessionale={getStatistiche}
            />
          )}
        />
        <AccordionCharts
          eventKey="6"
          onClick={() => getStatistiche(
            sequ_k_comparto,
            'grading-assessment-competenze-tecnico-professionali-profilo-professionale',
            null,
            null,
            null,
            {},
          )}
          title="Istogramma del Grading Assessment rispetto al Grading Atteso su Competenze Tecnico Professionali per Profilo Professionale"
          data={statisticheList
            .grading_assessment_competenze_tecnico_professionali_profilo_professionale}
          optionsChart={optionsGradingCTPPerProfilo}
        />
        <AccordionCharts
          eventKey="7"
          onClick={() => getStatistiche(
            sequ_k_comparto,
            'grading-assessment-competenze-comportamentali-profilo-professionale',
            null,
            null,
            null,
            {},
          )}
          title="Istogramma del Grading Assessment rispetto al Grading Atteso su Competenze Comportamentali per Profilo Professionale"
          data={statisticheList
            .grading_assessment_competenze_comportamentali_profilo_professionale}
          optionsChart={optionsGradingCCPerProfilo}
        />
        <AccordionCharts
          eventKey="8"
          onClick={() => getStatistiche(
            sequ_k_comparto,
            'grading-assessment-competenze-tecnico-specialistiche-profilo-di-ruolo',
            null,
            null,
            null,
            {},
          )}
          title="Istogramma del Grading Assessment rispetto al Grading Atteso su Competenze Tecnico Specialistiche per Profilo di Ruolo"
          data={statisticheList
            .grading_assessment_competenze_tecnico_specialistiche_profilo_di_ruolo}
          optionsChart={optionsGradingCTSPerProfilo}
        />
        <AccordionCharts
          eventKey="9"
          onClick={() => getStatistiche(
            sequ_k_comparto,
            'grading-assessment-competenza-tecnico-professionale',
            null,
            null,
            null,
            {},
          )}
          title="Istogramma del Grading Assessment rispetto al Grading Atteso per Competenza Tecnico Professionale"
          data={statisticheList
            .grading_assessment_competenza_tecnico_professionale}
          optionsChart={optionsGradingCTP}
        />
        <AccordionCharts
          eventKey="10"
          onClick={() => getStatistiche(
            sequ_k_comparto,
            'grading-assessment-competenza-comportamentale',
            null,
            null,
            null,
            {},
          )}
          title="Istogramma del Grading Assessment rispetto al Grading Atteso per Competenza Comportamentale"
          data={statisticheList
            .grading_assessment_competenza_comportamentale}
          optionsChart={optionsGradingCC}
        />
        <AccordionCharts
          eventKey="11"
          onClick={() => getStatistiche(
            sequ_k_comparto,
            'grading-assessment-competenza-tecnico-specialistica',
            null,
            null,
            null,
            {},
          )}
          title="Istogramma del Grading Assessment rispetto al Grading Atteso per Competenza Tecnico Specialistica"
          data={statisticheList
            .grading_assessment_competenza_tecnico_specialistica}
          optionsChart={optionsGradingCTS}
        />
      </Accordion>
    </Container>
  );
}

export default ReportingAssessment;
