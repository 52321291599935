import { Accordion, Container } from 'react-bootstrap';
import { useContext, useEffect, useState } from 'react';
import { Context } from '../../../../utils/Context';
import { getItem, setItem } from '../../../../utils/storage';
import StatisticheService from '../../../../services/statisticheService';
import SistemaProfessionaleService from '../../../../services/sistemaProfessionaleService';
import TitlePage from '../../../../components/GestisciUtenti/TitoloPagina';
import AccordionTable from '../../../../components/Accordion/Tables';
import areeContrattualiFields from '../fieldsTables/areeContrattualiFields';
import AccordionCharts from '../../../../components/Accordion/Charts';
import optionsProfiliPerArea from '../optionsGrafici/optionsProfiliPerArea';
import dimensioniProfessionaliFields from '../fieldsTables/dimensioniProfessionaliFields';
import optionsProfiliPerDimensione from '../optionsGrafici/optionsProfiliPerDimensione';
import famiglieProfessionaliFields from '../fieldsTables/famiglieProfessionaliFields';
import optionsProfiliPerFamiglia from '../optionsGrafici/optionsProfiliPerFamiglia';
import ambitiRuoloFields from '../fieldsTables/ambitiRuoloFields';
import optionsProfiliPerAmbito from '../optionsGrafici/optionsProfiliPerAmbito';
import CTPFields from '../fieldsTables/CTPFields';
import optionsProfiliPerCTP from '../optionsGrafici/optionsProfiliPerCTP';
import CCFields from '../fieldsTables/CCFields';
import optionsProfiliPerCC from '../optionsGrafici/optionsProfiliPerCC';
import CTSFields from '../fieldsTables/CTSFields';
import optionsProfiliPerCTS from '../optionsGrafici/optionsProfiliPerCTS';
import profiliProfessionaliFields from '../fieldsTables/profiliProfessionaliFields';
import FiltriProfiliProfessionali from '../filtriTables/filtriProfiliProfessionali';
import profiliRuoloFields from '../fieldsTables/profiliRuoloFields';
import FiltriProfiliRuolo from '../filtriTables/filtriProfiliRuolo';

function ReportingSistema() {
  const [context, setContext] = useContext(Context);
  const [statisticheList, setStatisticheList] = useState({});
  const [famiglieProfessionaliList, setFamiglieProfessionaliList] = useState([]);
  const [areeContrattualiList, setAreeContrattualiList] = useState([]);
  const [dimensioniProfessionaliList, setDimensioniProfessionaliList] = useState([]);
  const [ambitoRuoloList, setAmbitoRuoloList] = useState([]);
  const [filtri, setFiltri] = useState({});
  const [pagination, setPagination] = useState({
    page: 1,
    sizePerPage: 10,
    totalSize: 0,
  });
  const { sequ_k_comparto } = getItem('compartoSelected') ? JSON.parse(getItem('compartoSelected')) : 0;

  // Function chiamata API
  async function getStatistiche(fk_sequ_comparto, type, offset, limit, page, filters) {
    const typeList = type.replaceAll('-', '_');
    try {
      const response = await StatisticheService
        .getStatistiche(fk_sequ_comparto, type, offset, limit, filters);
      setStatisticheList({
        ...statisticheList,
        [typeList]: response.rows,
      });
      setPagination((prevPag) => ({
        ...prevPag,
        page,
        sizePerPage: limit,
        totalSize: response.totalRowCount,
      }));
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // Function collegata al servizio per ottenere la lista delle famiglie professionali
  async function getFamiglieProfessionali(offset, limit, filters) {
    try {
      const response = await SistemaProfessionaleService
        .getFamiglieProfessionali(filters, offset, limit);
      setFamiglieProfessionaliList(response.rows);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // Function collegata al servizio per ottenere la lista delle aree contrattuali
  async function getAreaContrattuale(offset, limit, page, filters) {
    try {
      const filterSession = filters || filtri;
      const response = await SistemaProfessionaleService
        .getAreaContrattuale(filterSession, offset, limit);
      setAreeContrattualiList(response.rows);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // Function collegata al servizio per ottenere la lista delle dimensioni professionali
  async function getDimensioniProfessionali(offset, limit, page, filters) {
    try {
      const filterSession = filters || filtri;
      const response = await SistemaProfessionaleService
        .getDimensioniProfessionali(filterSession, offset, limit);
      setDimensioniProfessionaliList(response.rows);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // Function collegata al servizio per ottenere la lista degli ambiti di ruolo
  async function getAmbitiRuolo(offset, limit, page, filters) {
    try {
      const filterSession = filters || filtri;
      const response = await SistemaProfessionaleService
        .getAmbitiRuolo(filterSession, offset, limit);
      setAmbitoRuoloList(response.rows);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // Function per gestire l'esportazione delle tabelle in formato excel
  async function handleExport(exportFunction, fileName, type) {
    try {
      let exportData = await exportFunction();
      if (type === 'application/json;charset=utf-8;') {
        exportData = JSON.stringify(exportData, null, 2);
      }
      const blob = new Blob([exportData], { type });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.setAttribute('download', fileName);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Errore durante il download del CSV:', error);
    }
  }

  // useEffect per gestire la memorizzazione delle pagine nello storage delle tabelle
  useEffect(() => {
    setItem('page', parseInt(pagination.page, 10));
  }, [pagination.page]);

  return (
    <Container className="p-0">
      <TitlePage
        title="Statistiche Sistema Professionale"
        subtitle="In questa sezione è possibile visionare e scaricare in diversi
        formati tutte le statistiche disponibili."
      />
      <Accordion>
        <AccordionTable
          eventKey="0"
          onClick={() => getStatistiche(sequ_k_comparto, 'aree-contrattuali', 0, 10, 1, {})}
          title="Aree Contrattuali"
          data={statisticheList.aree_contrattuali}
          fieldsData={areeContrattualiFields()}
          filtri={filtri}
          setFiltri={setFiltri}
          setPagination={setPagination}
          pagination={pagination}
          keyField="area_contrattuale_list"
          handleExportCsv={() => handleExport(
            () => StatisticheService.getStatisticheExport(sequ_k_comparto, 'aree-contrattuali', 'csv', null, null, filtri),
            'aree-contrattuali.csv',
            'text/csv;charset=utf-8;',
          )}
          handleExportJson={() => handleExport(
            () => StatisticheService.getStatisticheExport(sequ_k_comparto, 'aree-contrattuali', 'json', null, null, filtri),
            'aree-contrattuali.json',
            'application/json;charset=utf-8;',
          )}
          functionTable={getStatistiche}
          sequ_k_comparto={sequ_k_comparto}
          typeStatistica="aree-contrattuali"
          isExportable
        />
        <AccordionCharts
          eventKey="1"
          onClick={() => getStatistiche(
            sequ_k_comparto,
            'profili-professionali-area-contrattuale',
            null,
            null,
            null,
            {},
          )}
          title="Istogramma Profili Professionali per Area Contrattuale"
          data={statisticheList.profili_professionali_area_contrattuale}
          optionsChart={optionsProfiliPerArea}
        />
        {(sequ_k_comparto === 1 || sequ_k_comparto === 2)
        && (
        <AccordionTable
          eventKey="2"
          onClick={() => getStatistiche(sequ_k_comparto, 'dimensioni-professionali', 0, 10, 1, {})}
          title="Dimensioni Professionali"
          data={statisticheList.dimensioni_professionali}
          fieldsData={dimensioniProfessionaliFields()}
          filtri={filtri}
          setFiltri={setFiltri}
          setPagination={setPagination}
          pagination={pagination}
          keyField="dimensioni_professionali_list"
          handleExportCsv={() => handleExport(
            () => StatisticheService.getStatisticheExport(sequ_k_comparto, 'dimensioni-professionali', 'csv', null, null, filtri),
            'dimensioni-professionali.csv',
            'text/csv;charset=utf-8;',
          )}
          handleExportJson={() => handleExport(
            () => StatisticheService.getStatisticheExport(sequ_k_comparto, 'dimensioni-professionali', 'json', null, null, filtri),
            'dimensioni-professionali.json',
            'application/json;charset=utf-8;',
          )}
          functionTable={getStatistiche}
          sequ_k_comparto={sequ_k_comparto}
          typeStatistica="dimensioni-professionali"
          isExportable
        />
        )}
        {(sequ_k_comparto === 1 || sequ_k_comparto === 2)
        && (
        <AccordionCharts
          eventKey="3"
          onClick={() => getStatistiche(
            sequ_k_comparto,
            'profili-professionali-dimensione-professionale',
            null,
            null,
            null,
            {},
          )}
          title="Istogramma Profili Professionali per Dimensione Professionale"
          data={statisticheList.profili_professionali_dimensione_professionale}
          optionsChart={optionsProfiliPerDimensione}
        />
        )}
        <AccordionTable
          eventKey="4"
          onClick={() => getStatistiche(sequ_k_comparto, 'famiglie-professionali', 0, 10, 1, {})}
          title="Famiglie Professionali"
          data={statisticheList.famiglie_professionali}
          fieldsData={famiglieProfessionaliFields()}
          filtri={filtri}
          setFiltri={setFiltri}
          setPagination={setPagination}
          pagination={pagination}
          keyField="famiglie_professionali_list"
          handleExportCsv={() => handleExport(
            () => StatisticheService.getStatisticheExport(sequ_k_comparto, 'famiglie-professionali', 'csv', null, null, filtri),
            'famiglie-professionali.csv',
            'text/csv;charset=utf-8;',
          )}
          handleExportJson={() => handleExport(
            () => StatisticheService.getStatisticheExport(sequ_k_comparto, 'famiglie-professionali', 'json', null, null, filtri),
            'famiglie-professionali.json',
            'application/json;charset=utf-8;',
          )}
          functionTable={getStatistiche}
          sequ_k_comparto={sequ_k_comparto}
          typeStatistica="famiglie-professionali"
          isExportable
        />
        <AccordionCharts
          eventKey="5"
          onClick={() => getStatistiche(
            sequ_k_comparto,
            'profili-professionali-famiglia-professionale',
            null,
            null,
            null,
            {},
          )}
          title="Istogramma Profili Professionali per Famiglia Professionale"
          data={statisticheList.profili_professionali_famiglia_professionale}
          optionsChart={optionsProfiliPerFamiglia}
        />
        <AccordionTable
          eventKey="6"
          onClick={() => {
            getStatistiche(sequ_k_comparto, 'ambiti-ruolo', 0, 10, 1, {});
            getFamiglieProfessionali(0, 1000, { fk_sequ_comparto: sequ_k_comparto });
          }}
          title="Ambiti di Ruolo"
          data={statisticheList.ambiti_ruolo}
          fieldsData={ambitiRuoloFields()}
          filtri={filtri}
          setFiltri={setFiltri}
          setPagination={setPagination}
          pagination={pagination}
          keyField="ambiti_ruolo_list"
          handleExportCsv={() => handleExport(
            () => StatisticheService.getStatisticheExport(sequ_k_comparto, 'ambiti-ruolo', 'csv', null, null, filtri),
            'ambiti-ruolo.csv',
            'text/csv;charset=utf-8;',
          )}
          handleExportJson={() => handleExport(
            () => StatisticheService.getStatisticheExport(sequ_k_comparto, 'ambiti-ruolo', 'json', null, null, filtri),
            'ambiti-ruolo.json',
            'application/json;charset=utf-8;',
          )}
          functionTable={getStatistiche}
          sequ_k_comparto={sequ_k_comparto}
          typeStatistica="ambiti-ruolo"
          isExportable
        />
        <AccordionCharts
          eventKey="7"
          onClick={() => getStatistiche(
            sequ_k_comparto,
            'profili-ruolo-ambito-ruolo',
            null,
            null,
            null,
            {},
          )}
          title="Istogramma Profili di Ruolo per Ambito di Ruolo"
          data={statisticheList.profili_ruolo_ambito_ruolo}
          optionsChart={optionsProfiliPerAmbito}
        />
        <AccordionTable
          eventKey="8"
          onClick={() => getStatistiche(null, 'competenze-tecnico-professionali', 0, 10, 1, {})}
          title="Competenze Tecnico Professionali"
          data={statisticheList.competenze_tecnico_professionali}
          fieldsData={CTPFields()}
          filtri={filtri}
          setFiltri={setFiltri}
          setPagination={setPagination}
          pagination={pagination}
          keyField="competenze_tecnico_professionali_list"
          handleExportCsv={() => handleExport(
            () => StatisticheService.getStatisticheExport(null, 'competenze-tecnico-professionali', 'csv', null, null, filtri),
            'competenze-tecnico-professionali.csv',
            'text/csv;charset=utf-8;',
          )}
          handleExportJson={() => handleExport(
            () => StatisticheService.getStatisticheExport(null, 'competenze-tecnico-professionali', 'json', null, null, filtri),
            'competenze-tecnico-professionali.json',
            'application/json;charset=utf-8;',
          )}
          functionTable={getStatistiche}
          sequ_k_comparto={null}
          typeStatistica="competenze-tecnico-professionali"
          isExportable
        />
        <AccordionCharts
          eventKey="9"
          onClick={() => getStatistiche(
            sequ_k_comparto,
            'profili-professionali-competenza-tecnico-professionale',
            null,
            null,
            null,
            {},
          )}
          title="Istogramma Profili Professionali per Competenza Tecnico Professionale"
          data={statisticheList.profili_professionali_competenza_tecnico_professionale}
          optionsChart={optionsProfiliPerCTP}
        />
        <AccordionTable
          eventKey="10"
          onClick={() => getStatistiche(null, 'competenze-comportamentali', 0, 10, 1, {})}
          title="Competenze Comportamentali"
          data={statisticheList.competenze_comportamentali}
          fieldsData={CCFields()}
          filtri={filtri}
          setFiltri={setFiltri}
          setPagination={setPagination}
          pagination={pagination}
          keyField="competenze_comportamentali_list"
          handleExportCsv={() => handleExport(
            () => StatisticheService.getStatisticheExport(null, 'competenze-comportamentali', 'csv', null, null, filtri),
            'competenze-comportamentali.csv',
            'text/csv;charset=utf-8;',
          )}
          handleExportJson={() => handleExport(
            () => StatisticheService.getStatisticheExport(null, 'competenze-comportamentali', 'json', null, null, filtri),
            'competenze-comportamentali.json',
            'application/json;charset=utf-8;',
          )}
          functionTable={getStatistiche}
          sequ_k_comparto={null}
          typeStatistica="competenze-comportamentali"
          isExportable
        />
        <AccordionCharts
          eventKey="11"
          onClick={() => getStatistiche(
            sequ_k_comparto,
            'profili-professionali-competenza-comportamentale',
            null,
            null,
            null,
            {},
          )}
          title="Istogramma Profili Professionali per Competenza Comportamentale"
          data={statisticheList.profili_professionali_competenza_comportamentale}
          optionsChart={optionsProfiliPerCC}
        />
        <AccordionTable
          eventKey="12"
          onClick={() => getStatistiche(null, 'competenze-tecnico-specialistiche', 0, 10, 1, {})}
          title="Competenze Tecnico Specialistiche"
          data={statisticheList.competenze_tecnico_specialistiche}
          fieldsData={CTSFields()}
          filtri={filtri}
          setFiltri={setFiltri}
          setPagination={setPagination}
          pagination={pagination}
          keyField="competenze_tecnico_specialistiche_list"
          handleExportCsv={() => handleExport(
            () => StatisticheService.getStatisticheExport(null, 'competenze-tecnico-specialistiche', 'csv', null, null, filtri),
            'competenze-tecnico-specialistiche.csv',
            'text/csv;charset=utf-8;',
          )}
          handleExportJson={() => handleExport(
            () => StatisticheService.getStatisticheExport(null, 'competenze-tecnico-specialistiche', 'json', null, null, filtri),
            'competenze-tecnico-specialistiche.json',
            'application/json;charset=utf-8;',
          )}
          functionTable={getStatistiche}
          sequ_k_comparto={null}
          typeStatistica="competenze-tecnico-specialistiche"
          isExportable
        />
        <AccordionCharts
          eventKey="13"
          onClick={() => getStatistiche(
            sequ_k_comparto,
            'profili-professionali-competenza-tecnico-specialistica',
            null,
            null,
            null,
            {},
          )}
          title="Istogramma Profili di Ruolo per Competenza Tecnico Specialistica"
          data={statisticheList.profili_professionali_competenza_tecnico_specialistica}
          optionsChart={optionsProfiliPerCTS}
        />
        <AccordionTable
          eventKey="14"
          onClick={() => {
            getStatistiche(sequ_k_comparto, 'profili-professionali', 0, 10, 1, {});
            getAreaContrattuale(0, 1000, 1, { fk_sequ_comparto: sequ_k_comparto });
            getDimensioniProfessionali(0, 1000, 1, { fk_sequ_comparto: sequ_k_comparto });
            getFamiglieProfessionali(0, 1000, { fk_sequ_comparto: sequ_k_comparto });
          }}
          title="Profili Professionali"
          data={statisticheList.profili_professionali}
          fieldsData={profiliProfessionaliFields()}
          filtri={filtri}
          setFiltri={setFiltri}
          setPagination={setPagination}
          filtriBody={(
            <FiltriProfiliProfessionali
              filtri={filtri}
              setFiltri={setFiltri}
              sequ_k_comparto={sequ_k_comparto}
              areeContrattualiList={areeContrattualiList}
              dimensioniProfessionaliList={dimensioniProfessionaliList}
              famiglieProfessionaliList={famiglieProfessionaliList}
              getProfiliProfessionali={getStatistiche}
            />
          )}
          pagination={pagination}
          keyField="profili_professionali_list"
          handleExportCsv={() => handleExport(
            () => StatisticheService.getStatisticheExport(sequ_k_comparto, 'profili-professionali', 'csv', null, null, filtri),
            'profili-professionali.csv',
            'text/csv;charset=utf-8;',
          )}
          handleExportJson={() => handleExport(
            () => StatisticheService.getStatisticheExport(sequ_k_comparto, 'profili-professionali', 'json', null, null, filtri),
            'profili-professionali.json',
            'application/json;charset=utf-8;',
          )}
          functionTable={getStatistiche}
          sequ_k_comparto={sequ_k_comparto}
          typeStatistica="profili-professionali"
          isExportable
        />
        <AccordionTable
          eventKey="15"
          onClick={() => {
            getStatistiche(sequ_k_comparto, 'profili-ruolo', 0, 10, 1, {});
            getFamiglieProfessionali(0, 1000, { fk_sequ_comparto: sequ_k_comparto });
            getAmbitiRuolo(0, 1000, { fk_sequ_comparto: sequ_k_comparto });
          }}
          title="Profili di Ruolo"
          data={statisticheList.profili_ruolo}
          fieldsData={profiliRuoloFields()}
          filtri={filtri}
          setFiltri={setFiltri}
          setPagination={setPagination}
          filtriBody={(
            <FiltriProfiliRuolo
              filtri={filtri}
              setFiltri={setFiltri}
              sequ_k_comparto={sequ_k_comparto}
              famiglieProfessionaliList={famiglieProfessionaliList}
              ambitoRuoloList={ambitoRuoloList}
              getProfiliRuolo={getStatistiche}
            />
          )}
          pagination={pagination}
          keyField="profili_ruolo_list"
          handleExportCsv={() => handleExport(
            () => StatisticheService.getStatisticheExport(sequ_k_comparto, 'profili-ruolo', 'csv', null, null, {}),
            'profili-ruolo.csv',
            'text/csv;charset=utf-8;',
          )}
          handleExportJson={() => handleExport(
            () => StatisticheService.getStatisticheExport(sequ_k_comparto, 'profili-ruolo', 'json', null, null, {}),
            'profili-ruolo.json',
            'application/json;charset=utf-8;',
          )}
          functionTable={getStatistiche}
          sequ_k_comparto={sequ_k_comparto}
          typeStatistica="profili-ruolo"
          isExportable
        />
      </Accordion>
    </Container>
  );
}

export default ReportingSistema;
