/* eslint-disable react/no-unknown-property */
/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import { Button, Col, Collapse } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import fieldsDefinizioneCosti from '../fields/fieldsDefinizioneCosti';
import fieldsProgressioneVerticale from '../fields/fieldsProgressioneVerticale';
import Table from '../../../../../components/Shared/Table';
import formatCentinaia from '../../../../../utils/formatCentinaia';
import nomiProcessi from '../data/nomiProcessi';
import passiAggiungi from '../passiAggiungi';

function DefinizioneCosti({
  statePiano,
  setStatePiano,
  areeContrattuali,
  setIsDone,
}) {
  const [isModify, setIsModify] = useState(false);
  const [isCompiling, setIsCompiling] = useState(true);
  const [open, setOpen] = useState(false);
  const [progressioneList, setProgressioneList] = useState([]);
  const [keyTable, setKeyTable] = useState(0);

  // Function per gestire l'inserimento dei dati all'interno della tabella
  const onChangeInput = (e, row) => {
    setIsDone(false);
    const { value } = e.target;
    statePiano?.aree_contrattuali?.filter(
      (el) => el.costo_area = (
        el.nome_area === row.nome_area
          ? formatCentinaia(value, true) : (el.costo_area || null)
      ),
    );
    const checkCosti = statePiano.aree_contrattuali.map(
      (el) => el.costo_area,
    );
    if (!checkCosti.includes(null) && !checkCosti.includes(undefined)) {
      setIsCompiling(false);
    } else {
      setIsCompiling(true);
    }
    setStatePiano({
      ...statePiano,
      stato_processo: `${nomiProcessi[0]} - ${passiAggiungi[0]}`,
      fase: 0,
    });
    setKeyTable((prevKey) => prevKey += 1);
  };

  // Function per calcolare la progressione delle aree contrattuali
  const getProgressioneVerticale = (listaAree) => {
    const formattedListaAree = listaAree.map((area) => ({
      ...area,
      costo_area: typeof area.costo_area === 'number' ? area.costo_area : parseInt(area.costo_area.replace(/[.]/g, ''), 10),
    }));
    const areeSorted = [...formattedListaAree].sort(
      (a, b) => b.costo_area - a.costo_area,
    );

    const progressione = [];
    for (let ind = 0; ind < areeSorted.length - 1; ind++) {
      progressione.push({
        nomeDa: areeSorted[ind + 1].nome_area,
        costoDa: areeSorted[ind + 1].costo_area,
        nomeA: areeSorted[ind].nome_area,
        costoA: areeSorted[ind].costo_area,
        differenza: areeSorted[ind].costo_area - areeSorted[ind + 1].costo_area,
      });
    }

    const areeUpdated = statePiano.aree_contrattuali.map(
      (area) => {
        const diff = progressione.find(
          (el) => el.nomeA === area.nome_area,
        )?.differenza;
        return ({
          ...area,
          costo_area: typeof area.costo_area === 'number' ? area.costo_area : parseInt(area.costo_area.replace(/[.]/g, ''), 10),
          differenza: diff || 0,
        });
      },
    );
    setStatePiano({
      ...statePiano,
      aree_contrattuali: areeUpdated,
    });
    setProgressioneList(progressione);
  };

  useEffect(() => {
    const checkCosti = statePiano?.aree_contrattuali?.map(
      (el) => el.costo_area,
    );
    if (checkCosti?.length && !checkCosti?.includes(null) && !checkCosti?.includes(undefined)) {
      setIsCompiling(false);
    } else {
      setIsCompiling(true);
    }
  }, []);

  return (
    <>
      <div as={Col}>
        <p style={{ fontWeight: 'bold' }}>
          Nome Piano
        </p>
        <p>{statePiano.nome_processo}</p>
        <p style={{ fontWeight: 'bold' }}>
          Anno di Riferimento
        </p>
        <p>{statePiano.anno_riferimento}</p>
      </div>
      <div as={Col}>
        <p style={{ fontWeight: 'bold' }}>
          Costo per Area Contrattuale
        </p>
        {/* <p style={{ fontWeight: 'lighter' }}>
          Per procedere, cliccare il pulsante di visualizzazione dei costi di progressione.
        </p> */}
        {areeContrattuali && (
        <Table
          key={keyTable}
          data={areeContrattuali}
          fields={fieldsDefinizioneCosti(
            statePiano.aree_contrattuali,
            onChangeInput,
          )}
          keyField="nome_area"
        />
        )}
      </div>
      <div as={Col}>
        <p style={{ fontWeight: 'bold' }}>
          Costo per progressione verticale
          {' '}
          <Button
            onClick={() => {
              setIsDone(true);
              if (!isCompiling && isModify === false) {
                setIsModify(true);
              }
              if (isModify) {
                setIsModify(false);
              }
              getProgressioneVerticale(statePiano.aree_contrattuali);
              setOpen(true);
            }}
            aria-expanded={open}
            size="sm"
            disabled={isCompiling}
          >
            <FontAwesomeIcon aria-hidden="true" icon="fa-solid fa-money-bill-trend-up" style={{ marginRight: '4px' }} size="sm" />
            {open ? 'AGGIORNA PROGRESSIONE' : 'CALCOLA PROGRESSIONE'}
          </Button>
        </p>
        <Collapse in={open}>
          <div>
            <Table
              data={progressioneList}
              fields={fieldsProgressioneVerticale()}
              keyField="costo_progressione_verticale"
            />
          </div>
        </Collapse>
      </div>
    </>
  );
}

export default DefinizioneCosti;
