import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Container } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Table from '../../../components/Shared/Table';
import EmptyState from '../../../components/EmptyState';
import fieldsCompetenze from './dettaglio/fieldsTable';

function FormProfiliRuolo({
  stateProfiloRuolo,
  stateProfiloProfessionale,
  onChange,
  isModify = false,
  setModalShowCts,
  gradingList,
  isNew = false,
  onDeleteCompetenza,
  handleTableChange,
  errors,
}) {
  // function per avere la lista delle competenze selezionate in base al tipo
  const getCompetenzeSelectedList = (tipo, state) => {
    if (state.fk_sequ_competenze) {
      return state.fk_sequ_competenze.filter(
        (el) => el.codi_tipo_competenza === tipo,
      );
    } return [];
  };

  // function per avere la lista dei grading associati ad un particolare tipo di competenza
  const getGradingList = (tipo) => {
    if (gradingList.length > 0) {
      return gradingList.filter(
        (el) => el.codi_tipo_competenza === tipo,
      );
    } return [];
  };

  return (
    <Form.Group as={Col} lg controlId="profili-ruolo">
      {!isNew && (
        <>
          <Form.Label style={{ fontWeight: 'bold' }}>
            Codice
          </Form.Label>
          <Form.Control
            name="desc_codice"
            value={stateProfiloRuolo?.desc_codice}
            disabled
          />
          <Form.Label style={{ fontWeight: 'bold' }}>
            Nome
          </Form.Label>
          <Form.Control
            name="desc_titolo"
            value={stateProfiloRuolo?.desc_titolo}
            disabled
          />
        </>
      )}
      <Form.Label style={{ fontWeight: 'bold' }}>
        Comparto
      </Form.Label>
      <Form.Control
        name="fk_sequ_comparto"
        value={stateProfiloProfessionale?.fk_sequ_comparto_d_comparto?.desc_comparto}
        disabled
      />
      <Form.Label style={{ fontWeight: 'bold' }}>
        Area contrattuale CCNL
      </Form.Label>
      <Form.Control
        name="fk_sequ_area_contrattuale"
        value={
          stateProfiloProfessionale?.fk_sequ_area_contrattuale_d_area_contrattuale?.desc_titolo
        }
        disabled
      />
      {(stateProfiloProfessionale.fk_sequ_comparto === 1
      || stateProfiloProfessionale.fk_sequ_comparto === 2) && (
        <>
          <Form.Label style={{ fontWeight: 'bold' }}>
            Dimensione Professionale
          </Form.Label>
          <Form.Control
            name="fk_sequ_dimensione_professionale"
            value={stateProfiloProfessionale.dimensione_professionale
              ? `${stateProfiloProfessionale.dimensione_professionale.desc_codice} - ${stateProfiloProfessionale.dimensione_professionale.desc_titolo}`
              : ''}
            disabled
          />
        </>
      )}
      <Form.Label style={{ fontWeight: 'bold' }}>
        Famiglia Professionale
      </Form.Label>
      <Form.Control
        name="fk_sequ_famiglia_professionale"
        value={stateProfiloProfessionale.famiglia_professionale
          ? `${stateProfiloProfessionale.famiglia_professionale.desc_codice} - ${stateProfiloProfessionale.famiglia_professionale.desc_titolo}`
          : ''}
        disabled
      />
      <Form.Label style={{ fontWeight: 'bold' }}>
        Scopo Organizzativo
      </Form.Label>
      <Form.Control
        name="desc_scopo"
        value={stateProfiloProfessionale?.desc_scopo}
        disabled
        as="textarea"
        rows={3}
      />
      <Form.Label style={{ fontWeight: 'bold' }}>
        Macro attività caratterizzanti il Profilo Professionale
      </Form.Label>
      <Form.Control
        name="desc_attivita"
        value={stateProfiloProfessionale?.desc_attivita}
        disabled
        as="textarea"
        rows={3}
      />
      <Form.Label style={{ fontWeight: 'bold' }}>
        Macro attività caratterizzanti il Profilo di Ruolo
      </Form.Label>
      <Form.Control
        name="desc_attivita"
        value={stateProfiloRuolo?.desc_attivita}
        disabled={!isModify && !isNew}
        onChange={onChange}
        as="textarea"
        rows={3}
        placeholder="Inserisci le macro attività"
        required
        isInvalid={errors?.desc_attivita}
      />
      {errors?.desc_attivita && (
      <Form.Control.Feedback type="invalid">
        *Campo Macro Attività obbligatorio
      </Form.Control.Feedback>
      )}
      { !isNew && (
        <>
          <Container className="d-flex justify-content-between p-0 mt-3">
            <Form.Label style={{ fontWeight: 'bold' }}>
              Competenze Tecnico Specialistiche
            </Form.Label>
            <Button
              className="float-left"
              variant="primary"
              size="sm"
              onClick={() => setModalShowCts(true)}
              disabled={!isModify}
            >
              <FontAwesomeIcon className="me-1" aria-hidden="true" icon={['fas', 'plus']} />
              Aggiungi Competenza Tecnico Specialistica
            </Button>
          </Container>

          {(getCompetenzeSelectedList('CTS', stateProfiloRuolo).length > 0
          && getGradingList('CTS').length > 0
          ) ? (
            <Table
              data={getCompetenzeSelectedList('CTS', stateProfiloRuolo)}
              fields={fieldsCompetenze(
                onDeleteCompetenza,
                getGradingList('CTS'),
                onChange,
                getCompetenzeSelectedList('CTS', stateProfiloRuolo),
                false,
                isModify,
              )}
              keyField="competenze_tecnico_specifiche_list"
              onTableChange={handleTableChange}
            />
            ) : (
              <EmptyState marginTop="3rem" />
            )}
          <Form.Label style={{ fontWeight: 'bold' }}>
            Competenze Tecnico Professionali
          </Form.Label>
          {getCompetenzeSelectedList('CTP', stateProfiloProfessionale).length > 0 ? (
            <Table
              data={getCompetenzeSelectedList('CTP', stateProfiloProfessionale)}
              fields={fieldsCompetenze(
                onDeleteCompetenza,
                getGradingList('CTP'),
                onChange,
                getCompetenzeSelectedList('CTP', stateProfiloProfessionale),
                true,
                false,
              )}
              keyField="competenze_tecnico_professionali_list"
              onTableChange={handleTableChange}
            />
          ) : (
            <EmptyState marginTop="3rem" />
          )}
          <Form.Label style={{ fontWeight: 'bold' }}>
            Competenze Comportamentali
          </Form.Label>
          {getCompetenzeSelectedList('CC', stateProfiloProfessionale).length > 0 ? (
            <Table
              data={getCompetenzeSelectedList('CC', stateProfiloProfessionale)}
              fields={fieldsCompetenze(
                onDeleteCompetenza,
                getGradingList('CC'),
                onChange,
                getCompetenzeSelectedList('CC', stateProfiloProfessionale),
                true,
                false,
              )}
              keyField="competenze_comportamentali_list"
              onTableChange={handleTableChange}
            />
          ) : (
            <EmptyState marginTop="3rem" />
          )}
        </>
      )}

    </Form.Group>
  );
}

export default FormProfiliRuolo;
