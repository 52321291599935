import React, { useContext, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import TitlePage from '../../../components/GestisciUtenti/TitoloPagina';
import { Context } from '../../../utils/Context';
import AssessmentService from '../../../services/assessmentService';
import checkStorage from '../../../utils/checkSessionStorage';
import { setItem } from '../../../utils/storage';
import Table from '../../../components/Shared/Table';
import EmptyState from '../../../components/EmptyState';
import ModaleCentroVerticale from '../../../components/modali/ModaleCentroVerticale';
import FormCicloAssessment from './FormCicloAssessment';
import fieldsCicliAssessment from './fieldsTable';
import ModaleConferma from '../../../components/modali/ModaleConferma';
import FiltriCicliAssessment from './filtriCicliAssessment';

function CicliAssessment() {
  const [context, setContext] = useContext(Context);
  const [cicliAssessmentList, setCicliAssessmentList] = useState([]);
  const [statoCicloAssessmentList, setStatoCicloAssessmentList] = useState([]);
  const [cicloAssessmentSelected, setCicloAssessmentSelected] = useState();
  const [modalShow, setModalShow] = useState(false);
  const [modalShowView, setModalShowView] = useState(false);
  const [modalShowModify, setModalShowModify] = useState(false);
  const [modalShowDelete, setModalShowDelete] = useState(false);
  const [modalShowAvvia, setModalShowAvvia] = useState(false);
  const [modalShowChiudi, setModalShowChiudi] = useState(false);
  const [stateCicloAssessment, setStateCicloAssessment] = useState({
    desc_titolo: '',
    data_avvio: '',
    data_chiusura: '',
    fk_codi_stato: 1,
  });

  const [filtri, setFiltri] = useState({});
  const [pagination, setPagination] = useState({
    page: 1,
    sizePerPage: 10,
    totalSize: 0,
  });

  // function collegata al servizio per ottenere la lista dei cicli di assessment
  async function getCicliAssessmentAdmin(offset, limit, page, filters) {
    try {
      const filterSession = filters || filtri;
      const response = await AssessmentService
        .getCicliAssessmentAdmin(filterSession, offset, limit);
      setCicliAssessmentList(response.rows);
      setPagination((prevPag) => ({
        ...prevPag,
        page,
        sizePerPage: limit,
        totalSize: response.totalRowCount,
      }));
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // Function collegata al servizio per ottenere il particolare ciclo di assessment
  async function getCicloAssessmentById(id) {
    try {
      const response = await AssessmentService.getCicloAssessmentById(id);
      setStateCicloAssessment(response);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // function collegata al servizio per ottenere la lista degli stati per i cicli di assessment
  async function getStatoCicloAssessment() {
    try {
      const response = await AssessmentService.getStatoCicloAssessment();
      setStatoCicloAssessmentList(response.rows);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // function attivata al cambio pagina della tabella
  const handleTableChange = (type, { page, sizePerPage }) => {
    let currentIndex;
    if (page !== 0) {
      currentIndex = (page - 1) * sizePerPage;
    }
    getCicliAssessmentAdmin(currentIndex, sizePerPage, page, {});
  };

  // useEffect
  useEffect(() => {
    let offset = 0;
    const storage = checkStorage(filtri, setFiltri, setPagination);
    if (storage.page > 1) {
      handleTableChange(null, { page: storage.page, sizePerPage: pagination.sizePerPage });
      offset = (storage.page - 1) * pagination.sizePerPage;
    }
    getCicliAssessmentAdmin(offset, 10, storage.page, storage.filtri);
  }, []);

  // useEffect per settare nello storage la pagina corrente
  useEffect(() => {
    setItem('page', pagination.page);
  }, [pagination.page]);

  // useEffect per caricare i dati presenti nella tabella.
  useEffect(() => {
    getCicliAssessmentAdmin(0, 10, 1, {});
    getStatoCicloAssessment();
  }, []);

  // function per modificare lo stato del ciclo di assessment che l'utente sta modificando.
  const onChange = (e) => {
    const { name, value } = e.target;
    setStateCicloAssessment({ ...stateCicloAssessment, [name]: value });
  };

  // function per modificare lo stato che gestisce i filtri
  function onChangeFiltri(e, config) {
    if (config) {
      setFiltri({
        ...filtri, [config.name]: e?.codi_k_stato,
      });
    } else {
      const { value, name } = e.target;
      setFiltri({
        ...filtri, [name]: value,
      });
    }
  }

  // function per effettuare il refresh della pagina
  function refresh() {
    getCicliAssessmentAdmin(0, 10, 1, {});
  }

  // function collegata al servizio di creazione di un nuovo ciclo di assessment
  async function nuovoCicloAssessment() {
    AssessmentService.nuovoCicloAssessment(stateCicloAssessment)
      .then((res) => {
        setContext(() => ({
          ...context, openConferma: true, testo: res.message,
        }));
        setModalShow(false);
        refresh();
      })
      .catch((err) => {
        setContext(() => ({
          ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
        }));
      });
  }

  // function collegata al servizio di eliminazione di un ciclo di assessment
  async function eliminaCicloAssessment(idCicloAssessment) {
    AssessmentService.eliminaCicloAssessment(idCicloAssessment)
      .then((res) => {
        setContext(() => ({
          ...context, openConferma: true, testo: res.message,
        }));
        setModalShowDelete(false);
        refresh();
      })
      .catch((err) => {
        setContext(() => ({
          ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
        }));
      });
  }

  // Function collegata al servizio per modificare il ciclo di assessment
  async function modificaCicloAssessment(id) {
    AssessmentService.modificaCicloAssessment(
      id,
      stateCicloAssessment,
    )
      .then((res) => {
        setContext(() => ({
          ...context, openConferma: true, testo: res.message,
        }));
        setModalShowModify(false);
        refresh();
      })
      .catch((err) => {
        setContext(() => ({
          ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
        }));
      });
  }

  // Function collegata al servizio per avviare il ciclo di assessment
  async function avviaCicloAssessment(id) {
    AssessmentService.avviaCicloAssessment(id)
      .then((res) => {
        setContext(() => ({
          ...context, openConferma: true, testo: res.message,
        }));
        setModalShowAvvia(false);
        refresh();
      })
      .catch((err) => {
        setContext(() => ({
          ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
        }));
      });
  }

  // Function collegata al servizio per chiudere il ciclo di assessment
  async function chiudiCicloAssessment(id) {
    AssessmentService.chiudiCicloAssessment(id)
      .then((res) => {
        setContext(() => ({
          ...context, openConferma: true, testo: res.message,
        }));
        setModalShowChiudi(false);
        refresh();
      })
      .catch((err) => {
        setContext(() => ({
          ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
        }));
      });
  }

  // Funzione attivata al click dell'icona di eliminazione
  const onDelete = (cicloAssessment) => {
    setModalShowDelete(true);
    setCicloAssessmentSelected(cicloAssessment);
  };

  // Funzione attivata al click dell'icona di visualizzazione
  const onView = (cicloAssessment) => {
    getCicloAssessmentById(cicloAssessment.sequ_k_ciclo_assesment);
    setCicloAssessmentSelected(cicloAssessment);
    setModalShowView(true);
  };

  // Funzione attivata al click dell'icona di modifica
  const onModify = (modify, cicloAssessment) => {
    getCicloAssessmentById(cicloAssessment.sequ_k_ciclo_assesment);
    setCicloAssessmentSelected(cicloAssessment);
    if (modify) {
      setModalShowModify(true);
    } else {
      setModalShowView(false);
      setModalShowModify(true);
    }
  };

  // Funzione attivata al click dell'icona di play
  const onAvvia = (cicloAssessment) => {
    setModalShowAvvia(true);
    setCicloAssessmentSelected(cicloAssessment);
  };

  // Funzione attivata al click dell'icona di play
  const onChiudi = (cicloAssessment) => {
    setModalShowChiudi(true);
    setCicloAssessmentSelected(cicloAssessment);
  };

  return (
    <>
      <Container className="p-0">
        <TitlePage
          title="Cicli Assessment"
          showRefresh
          functionRefresh={refresh}
          showAdd
          functionAdd={() => setModalShow(true)}
        />
        <FiltriCicliAssessment
          filtri={filtri}
          setFiltri={setFiltri}
          onChangeFiltri={onChangeFiltri}
          getCicliAssessmentAdmin={getCicliAssessmentAdmin}
          optionsStati={statoCicloAssessmentList}
        />
        {cicliAssessmentList.length > 0 ? (
          <Table
            data={cicliAssessmentList}
            fields={fieldsCicliAssessment(onDelete, onView, onModify, onAvvia, onChiudi)}
            pagination={pagination}
            onTableChange={handleTableChange}
            keyField="cicli_assessment_list"
          />
        ) : (
          <EmptyState marginTop="3rem" />
        )}
      </Container>
      <ModaleCentroVerticale
        modalTitle="Crea un nuovo Ciclo di Assessment"
        modalBody={(
          <FormCicloAssessment
            stateCicloAssessment={stateCicloAssessment}
            optionsStati={statoCicloAssessmentList}
            onChange={onChange}
          />
        )}
        buttonType="submit"
        show={modalShow}
        onConfirm={() => nuovoCicloAssessment()}
        labelConfirm="Aggiungi"
        onClose={() => setModalShow(false)}
      />
      <ModaleConferma
        labelBottone="Elimina"
        coloreAzione="primary"
        title="Sei sicuro di procedere?"
        body={`L'operazione eliminerà il ciclo di assessment "${cicloAssessmentSelected?.desc_titolo}"`}
        show={modalShowDelete}
        handleClose={() => setModalShowDelete(false)}
        handleOk={() => eliminaCicloAssessment(cicloAssessmentSelected.sequ_k_ciclo_assesment)}
      />
      <ModaleConferma
        labelBottone="Conferma"
        coloreAzione="primary"
        title="Sei sicuro di procedere?"
        body={`L'operazione comporterà l'avvio del ciclo di assessment: "${cicloAssessmentSelected?.desc_titolo}"`}
        show={modalShowAvvia}
        handleClose={() => setModalShowAvvia(false)}
        handleOk={() => avviaCicloAssessment(cicloAssessmentSelected.sequ_k_ciclo_assesment)}
      />
      <ModaleConferma
        labelBottone="Conferma"
        coloreAzione="primary"
        title="Sei sicuro di procedere?"
        body={`L'operazione comporterà la chiusura del ciclo di assessment "${cicloAssessmentSelected?.desc_titolo}"`}
        show={modalShowChiudi}
        handleClose={() => setModalShowChiudi(false)}
        handleOk={() => chiudiCicloAssessment(cicloAssessmentSelected.sequ_k_ciclo_assesment)}
      />
      <ModaleCentroVerticale
        modalTitle="Ciclo di Assessment"
        modalBody={(
          <FormCicloAssessment
            stateCicloAssessment={stateCicloAssessment}
            optionsStati={statoCicloAssessmentList}
            disabled
          />
        )}
        buttonType="submit"
        show={modalShowView}
        onConfirm={() => onModify(false, cicloAssessmentSelected)}
        disabledConfirm={cicloAssessmentSelected && cicloAssessmentSelected.fk_codi_stato !== 1}
        labelConfirm="Modifica"
        onClose={() => setModalShowView(false)}
      />
      <ModaleCentroVerticale
        modalTitle="Modifica Ciclo di Assessment"
        modalBody={(
          <FormCicloAssessment
            stateCicloAssessment={stateCicloAssessment}
            optionsStati={statoCicloAssessmentList}
            onChange={onChange}
          />
        )}
        buttonType="submit"
        show={modalShowModify}
        onConfirm={() => modificaCicloAssessment(cicloAssessmentSelected.sequ_k_ciclo_assesment)}
        labelConfirm="Conferma"
        onClose={() => setModalShowModify(false)}
      />
    </>
  );
}

export default CicliAssessment;
