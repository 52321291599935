/* eslint-disable prefer-destructuring */
import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import history from '../utils/history';

function ErrorPage() {
  const [params, setParams] = useState({});

  const getParams = () => {
    const uri = window.location.href.split('?');
    if (uri.length === 2) {
      const vars = uri[1].split('&');
      const getVars = {};
      let tmp = '';
      vars.forEach((v) => {
        tmp = v.split('=');
        if (tmp.length === 2) { getVars[tmp[0]] = tmp[1]; }
      });
      setParams(getVars);
      // do
    }
  };
  useEffect(() => {
    getParams();
  }, []);

  return (
    <body>
      <div style={{ marginTop: '5%', marginRight: '30%', marginLeft: '30%' }}>
        <div className="content">
          Si è verificato un errore.
          <hr />
          <Button onClick={() => history.push('/')}>
            Clicca qui
            per tornare alla pagina principale
          </Button>
          <br />
          <br />
          <div>
            <p>
              <b>Tipo di errore:</b>
              samlresponse
            </p>
            <p>
              <b>Messaggio di errore: </b>
              {params.errore

                ? params.errore.split('%20').join(' ').split('%27').join("'")
                  .split('%2F')
                  .join('/')

                : <span>XML error(s) during parsing, check log for specifics</span>}
            </p>
            <p>
              <b>URL richiesta: </b>
              {params.requestURL && params.requestURL.split('%3A').join(':').split('%2F').join('/')}
            </p>
          </div>
        </div>
      </div>
    </body>
  );
}

export default ErrorPage;
