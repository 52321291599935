import { Modal } from 'react-bootstrap';

function ModaleRisposte({
  showModale,
  handleClose,
  title,
  body,
}) {
  return (
    <Modal show={showModale} onHide={handleClose} centered role="dialog">
      <Modal.Header closeButton closeLabel="Chiudi">
        <Modal.Title>
          Risposte domanda:
          {' '}
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {Object.entries(body || {}).map((el) => (
          <p>
            {el[1]}
            ,
          </p>
        ))}
      </Modal.Body>
    </Modal>
  );
}

export default ModaleRisposte;
