import React, { useEffect } from 'react';
import {
  Card, Button, Row, Col,
} from 'react-bootstrap';
import history from '../../../utils/history';
import { getItem, removeItem } from '../../../utils/storage';
import TitlePage from '../../../components/GestisciUtenti/TitoloPagina/index';
import { controlla } from '../../../utils/verificaPermessi';

function Amministrazione() {
  // Function per pulire la sessione
  const clearSession = () => {
    const filter = getItem('filtri');
    const page = getItem('page');
    if (filter) {
      removeItem('filtri');
    }
    if (page) {
      removeItem('page');
    }
  };

  // useEffect
  useEffect(() => {
    clearSession();
  }, []);

  return (
    <div>
      <TitlePage
        title="Amministrazione"
        subtitle="Scegli la voce da gestire"
      />
      <Row xs={1} md={2} lg={2} className="py-2">
        {controlla('X46') && (
          <Col>
            <Card style={{ height: '100%' }}>
              <Card.Title className="text-center m-3">
                <h3>Gestione richieste accreditamento</h3>
              </Card.Title>
              <Card.Body>
                In questa sezione è possibile gestire le richieste di accreditamento
                inviate dagli utenti per essere abilitati ad operare sulla piattaforma per conto
                dell&apos;amministrazione.
              </Card.Body>
              <Card.Footer className="text-center">
                <Button
                  onClick={() => history.push(
                    '/area-riservata/amministrazione/gestione-richieste-identita',
                  )}
                  aria-label="Vai alla gestione Richieste accreditamento"
                >
                  Entra
                </Button>
              </Card.Footer>
            </Card>
          </Col>
        )}
        {controlla('X47') && (
          <Col>
            <Card style={{ height: '100%' }}>
              <Card.Title className="text-center m-3">
                <h3>Elenco Amministrazioni</h3>
              </Card.Title>
              <Card.Body>
                In questa sezione è possibile visualizzare l&apos;elenco
                delle Amministrazioni aderenti al progetto.
              </Card.Body>
              <Card.Footer className="text-center">
                <Button
                  onClick={() => history.push(
                    '/area-riservata/amministrazione/elenco-enti',
                  )}
                  aria-label="Vai all'elenco degli Enti"
                >
                  Visualizza
                </Button>
              </Card.Footer>
            </Card>
          </Col>
        )}
      </Row>
      <Row xs={1} md={2} lg={2} className="py-2">
        {controlla('X48') && (
          <Col>
            <Card style={{ height: '100%' }}>
              <Card.Title className="text-center m-3">
                <h3>Gestione Utenti</h3>
              </Card.Title>
              <Card.Body>
                Questa sezione consente agli amministratori di visualizzare,
                aggiornare, modificare ed eliminare gli account utente esistenti
                nella piattaforma.
              </Card.Body>
              <Card.Footer className="text-center">
                <Button
                  onClick={() => history.push(
                    '/area-riservata/amministrazione/gestione-utenti',
                  )}
                  aria-label="Vai alla gestione Richieste accreditamento"
                >
                  Entra
                </Button>
              </Card.Footer>
            </Card>
          </Col>
        )}
        {controlla('X49') && (
        <Col>
          <Card style={{ height: '100%' }}>
            <Card.Title className="text-center m-3">
              <h3>Gestione Utenti Massiva</h3>
            </Card.Title>
            <Card.Body>
              Questa sezione consente agli amministratori di caricare e creare
              massivamente nuovi utenti attraverso file Excel.
            </Card.Body>
            <Card.Footer className="text-center">
              <Button
                onClick={() => history.push(
                  '/area-riservata/amministrazione/gestione-utenti-massiva',
                )}
                aria-label="Vai alla gestione Richieste accreditamento"
              >
                Entra
              </Button>
            </Card.Footer>
          </Card>
        </Col>
        )}
      </Row>
    </div>
  );
}

export default Amministrazione;
