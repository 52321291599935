const demoEteroAssessment = {
  desc_titolo: 'Assessment Demo',
  desc_stato: 'In Etero-valutazione',
  utente_dipendente: {
    desc_nome: 'Giovanni',
    desc_cognome: 'Bianchi',
  },
  utente_responsabile: {
    desc_nome: 'Marco',
    desc_cognome: 'Neri',
  },
  fk_sequ_profilo_ruolo_d_profilo_ruolo: {
    desc_titolo: 'ISTRUTTORE GIURIDICO – AMMINISTRATIVO - Gestione giuridica ed economica',
  },
  rel_competenza_assessment: [
    {
      comp_desc_codice: 'CTS0051',
      comp_tipo_competenza: 'CTS',
      comp_desc_descrizione: 'Disciplina in materia di lavoro agile',
      codi_grading_atteso: 3,
      codi_grading_autovalutazione: 4,
    },
    {
      comp_desc_codice: 'CTP0058',
      comp_tipo_competenza: 'CTP',
      comp_desc_descrizione: 'Diritto amministrativo',
      codi_grading_atteso: 3,
      codi_grading_autovalutazione: 5,
    },
    {
      comp_desc_codice: 'CC0061',
      comp_tipo_competenza: 'CC',
      comp_desc_descrizione: 'Approccio strategico e pensiero prospettico',
      codi_grading_atteso: 4,
      codi_grading_autovalutazione: 1,
    },
    {
      comp_desc_codice: 'CTS0077',
      comp_tipo_competenza: 'CTS',
      comp_desc_descrizione: 'Tecniche e strumenti di gestione istruttorie di procedimenti',
      codi_grading_atteso: 5,
      codi_grading_autovalutazione: 2,
    },
  ],
};

export default demoEteroAssessment;
