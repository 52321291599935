import React, {
  useContext,
  useEffect, useRef, useState,
} from 'react';
import { Image } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams } from 'react-router-dom';
import TitlePage from '../../../../components/GestisciUtenti/TitoloPagina';
import history from '../../../../utils/history';
import useLayoutBreakpoint from '../../../../hooks/useLayoutBreakpoint';
import './index.scss';
import scrollTo from '../../../../utils/scrollTo';
import themeColors from '../../../../utils/contants/theme-colors';
import newsService from '../../../../services/newsService';
import { Context } from '../../../../utils/Context';
import { formatoDataEOra } from '../../../../utils/formatData';
import RenderHTML from '../../../../components/Shared/RenderHTML';

const newsUrl = process.env.REACT_APP_NEWS_FAQ_URL;

function Component1({ news }) {
  return (
    <article id="descrizione" className="my-5" style={{ minHeight: '80vh' }}>
      <h1>Descrizione</h1>
      <RenderHTML html={news?.contenuto} />
    </article>
  );
}

function Component3({ news }) {
  return (
    <article id="ultimo-aggiornamento" className="my-5" style={{ minHeight: '50px' }}>
      <h1>Ultimo aggiornamento</h1>
      <p><strong>{formatoDataEOra(news?.dataUltimaModifica || news?.dataCreazione)}</strong></p>
    </article>
  );
}

function Component4({ news }) {
  return (
    <article id="title" className="mb-5" style={{ minHeight: '50px' }}>
      <h1>
        {news?.titolo}
      </h1>
      <div className="row mb-4">
        <div className="col-6 d-flex flex-column">
          <small>Data pubblicazione:</small>
          <p className="fw-bold">{formatoDataEOra(news?.dataCreazione)}</p>
        </div>
      </div>
    </article>
  );
}

// const obsvOpts = {
//   root: null,
//   rootMargin: '0px',
//   threshold: [1.0],
// };

const menuItems = [{
  id: 'a',
  label: 'Titolo',
  component: (news) => <Component4 news={news} />,
},
{
  id: 'b',
  label: 'Descrizione',
  component: (news) => <Component1 news={news} />,
},
{
  id: 'c',
  label: 'Ulteriori informazioni',
  component: (news) => <Component3 news={news} />,
},
];

function MenuNotizia({
  items,
  activeMenuItem,
  handleClickMenuItem,
  top,
}) {
  const [display, setDisplay] = useState(true);

  // function per determinare la percentuale di riempimento del menu-notizia-separator
  const getWidthSeparator = (totalItems, activeItem) => {
    if (activeItem) {
      const activeIndex = totalItems.findIndex((el) => el.id === activeItem.id) + 1;
      const percentage = (activeIndex / totalItems.length) * 100;
      return percentage;
    } return 0;
  };

  return (
    <div className="menu-notizia" style={{ top }}>
      <button className="d-flex px-3 w-100 align-items-center justify-content-center" type="button" onClick={() => setDisplay(!display)}>
        <h6 className="text-uppercase page-index-title mb-0">indice della pagina</h6>
        <FontAwesomeIcon
          style={{ color: themeColors.primary, transform: `rotate(${display ? '180deg' : '0deg'})` }}
          aria-hidden="true"
          icon={['fas', 'angle-down']}
          size="2x"
          title={display ? 'Riduci menu' : 'Espandi menu'}
          id="expand-icon"
        />
      </button>
      <div className="menu-notizia-separator">
        <div style={{ backgroundColor: 'brown', width: `${getWidthSeparator(items, activeMenuItem)}%`, height: '5px' }} />
      </div>
      {display ? (
        <ul className="list-notizia py-3 px-0">
          {items.map((item) => (
            <li key={`li-${item.id}`} className="mb-3">
              <a className={`px-3 py-2 ${activeMenuItem && activeMenuItem.id === item.id ? 'active' : ''}`} href={`#${item.id}`} onClick={handleClickMenuItem}>
                {item.label}
              </a>
            </li>
          ))}
        </ul>
      ) : null}
    </div>
  );
}

function DettaglioNewsEsterna() {
  const { id } = useParams();
  const [context, setContext] = useContext(Context);
  const [newsEsterna, setNewsEsterna] = useState();
  const [activeMenuItem, setActiveMenuItem] = useState(document.querySelector(`[id="${menuItems[0].id}"]`));
  const isScrolling = useRef(false);
  const breakpoint = useLayoutBreakpoint();

  const itHeaderNavbarWrapper = document.querySelector('.it-header-navbar-wrapper');
  const itHeaderCenterWrapper = document.querySelector('.it-header-center-wrapper');

  const headerHeigth = breakpoint === 'xs' || breakpoint === 'sm' || breakpoint === 'md'
    ? itHeaderCenterWrapper && itHeaderCenterWrapper.getBoundingClientRect().height
    : itHeaderNavbarWrapper && itHeaderNavbarWrapper.getBoundingClientRect().height;

  // function attivata al click di un item del menu di sinistra
  const handleClickMenuItem = (event) => {
    event.preventDefault();
    const href = event.target.getAttribute('href');
    const element = document.querySelector(href);
    setActiveMenuItem(element);
    isScrolling.current = true;
    scrollTo(element.offsetTop, () => {
      isScrolling.current = false;
    });
  };

  // function collegata al servizio per ottenere il dettaglio di una determinata news
  async function getNewsPubblica() {
    try {
      const response = await newsService.getNewsPubblicaById(id);
      setNewsEsterna(response);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // useEffect
  useEffect(() => {
    getNewsPubblica();
  }, []);

  return (
    <>
      <TitlePage
        showIndietro
        functionIndietro={() => {
          history.goBack();
        }}
      />
      {newsEsterna?.pathMinioImmagine && (
      <Image src={newsEsterna && `${newsUrl}/news/immagine/${encodeURIComponent(newsEsterna.pathMinioImmagine)}`} className="w-100" alt="Immagine notizia" />
      )}
      <div className={`${breakpoint && (breakpoint === 'lg' || breakpoint === 'xl') ? 'd-flex' : ''} mt-5 menu-notizia-container`}>
        {breakpoint && (breakpoint === 'lg' || breakpoint === 'xl')
          ? (
            <div className="d-block">
              <MenuNotizia
                items={menuItems}
                activeMenuItem={activeMenuItem}
                handleClickMenuItem={handleClickMenuItem}
                top={headerHeigth}
              />
            </div>
          ) : (
            <MenuNotizia
              items={menuItems}
              activeMenuItem={activeMenuItem}
              handleClickMenuItem={handleClickMenuItem}
              top={headerHeigth}
            />
          )}
        <div className="menu-items p-5">
          {newsEsterna && menuItems.map((item) => <section key={`section-${item.id}`} id={item.id}>{item.component(newsEsterna)}</section>)}
        </div>
      </div>
    </>
  );
}

export default DettaglioNewsEsterna;
