import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import {
  Accordion, Button, ButtonGroup, ListGroup,
} from 'react-bootstrap';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import FiltriRicercaFaq from '../../../components/Faq/FiltriRicerca';
import Title from '../../../components/GestisciUtenti/TitoloPagina/index';
import ModaleConferma from '../../../components/modali/ModaleConferma';
import RenderHTML from '../../../components/Shared/RenderHTML';
import faqService from '../../../services/faqService';
import { Context } from '../../../utils/Context';
import history from '../../../utils/history';
import trimContent from '../../../utils/trimContent';

function GestioneFaq() {
  const [argomentiInterni, setArgomentiInterni] = useState([]);
  const [idFaq, setIdFaq] = useState();
  const [listaFaqInterne, setListaFaqInterne] = useState(null);
  const [showModaleElimina, setShowModaleElimina] = useState(false);
  const [context, setContext] = useContext(Context);
  const [showMore, setShowMore] = useState({});
  const [filtri, setFiltri] = useState({
    domanda: '',
    risposta: '',
    argomento: '',
    profonditaStorica: false,
  });
  const { pathname } = useLocation();

  // Function gestione apertura/chiusura modale elimina
  function handleCloseModaleElimina() {
    setShowModaleElimina(!showModaleElimina);
  }

  // Function collegata al servizio di get della lista delle Faq INTERNE
  async function getListaFaqInterne(offset, limit) {
    window.scrollTo({ behavior: 'smooth', top: '0px' });
    try {
      const response = await faqService.getListaFaq(filtri, offset, limit);
      setListaFaqInterne(response?.listaFaq || []);
      const argomentiTemp = [];
      response?.listaFaq.forEach((faq) => {
        if (!argomentiTemp.includes(faq.argomento)) {
          argomentiTemp.push(faq.argomento);
        }
      });
      setArgomentiInterni(argomentiTemp);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // Function collegata al servizio di delete di una particolare Faq INTERNE
  async function deleteFaqInterna() {
    await faqService.deleteFaq(idFaq)
      .then((res) => {
        setContext(() => ({
          ...context, openConferma: true, testo: res.message || 'Operazione eseguita correttamente',
        }));
        getListaFaqInterne(0, 1000);
      })
      .catch((err) => {
        setContext(() => ({
          ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
        }));
      })
      .finally(() => {
        handleCloseModaleElimina();
      });
  }

  // useEffect
  useEffect(() => {
    getListaFaqInterne(0, 1000);
  }, []);

  // fucntion per la gestione dei filtri
  function onChangeFiltri(e, type) {
    const { value, id, checked } = e.target;
    setFiltri({
      ...filtri,
      [id]: type === 'checkbox' ? checked : value,
    });
  }

  return (
    <>
      <Title
        title={pathname.includes('gestione-faq') ? 'Gestione FAQ' : 'FAQ'}
        subtitle={pathname.includes('gestione-faq')
          ? 'In questa sezione è possibile gestire le FAQ (Frequently Asked Question).'
          : 'In questa sezione è possibile visualizzare le FAQ (Frequently Asked Question).'}
        showRefresh
        functionRefresh={() => getListaFaqInterne(0, 1000)}
        showAdd={pathname.includes('gestione-faq')}
        functionAdd={() => history.push('/area-riservata/gestione-faq/aggiungi')}
      />
      <FiltriRicercaFaq
        filtri={filtri}
        getListaFaq={getListaFaqInterne}
        onChange={onChangeFiltri}
      />
      {argomentiInterni.length > 0 && (
      <Accordion>
        {argomentiInterni.map((argomento, index) => (
          <Accordion.Item className="my-3 border accordionItem" eventKey={index} key={argomento}>
            <Accordion.Header>{argomento}</Accordion.Header>
            <Accordion.Body className="backgroundColorInterno">
              {listaFaqInterne.length > 0 && (
                <ListGroup numbered>
                  {listaFaqInterne.filter((el) => el.argomento === argomento).map((faq) => (
                    <ListGroup.Item
                      key={faq._id}
                      className="d-flex justify-content-between align-items-start border-0 backgroundColorInterno"
                    >
                      <div className="ms-2 me-auto w-100 backgroundColorInterno">
                        <div className="d-flex flex-direction-row justify-content-between fw-bold">
                          <div>{faq.domanda}</div>
                          {pathname.includes('gestione-faq') && (
                            <ButtonGroup className="float-end" aria-label={`Azioni disponibili per la FAQ ${faq.domanda}`}>
                              {!faq.dataFineValidita && (
                              <Button
                                className="me-2"
                                onClick={() => history.push(`/area-riservata/gestione-faq/modifica/${faq._id}`)}
                                title={`Modifica la FAQ ${faq.domanda}`}
                              >
                                <FontAwesomeIcon aria-hidden="true" icon="edit" />
                              </Button>
                              )}
                              {!faq.dataFineValidita && (
                              <Button
                                variant="danger"
                                onClick={() => { handleCloseModaleElimina(); setIdFaq(faq._id); }}
                                title={`Elimina la FAQ ${faq.domanda}`}
                              >
                                <FontAwesomeIcon aria-hidden="true" icon="trash" />
                              </Button>
                              )}
                            </ButtonGroup>
                          )}
                        </div>
                        {faq.risposta.length > 500 ? (
                          <>
                            <RenderHTML
                              html={
                                  showMore[faq._id] ? faq.risposta : trimContent(faq.risposta, 500)
                                }
                            />
                            {showMore[faq._id] ? (
                              <button className="buttonShow btn p-0" type="button" onClick={() => setShowMore({ ...showMore, [faq._id]: false })}>
                                Riduci
                              </button>
                            ) : (
                              <button className="buttonShow btn p-0" type="button" onClick={() => setShowMore({ ...showMore, [faq._id]: true })}>
                                Mostra di più...
                              </button>
                            )}
                          </>
                        ) : (
                          <RenderHTML html={faq.risposta} />
                        )}
                      </div>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
      )}
      <ModaleConferma
        labelBottone="Elimina"
        coloreAzione="danger"
        title="Sei sicuro di procedere?"
        body="L'operazione eliminerà la FAQ da te scelta"
        show={showModaleElimina}
        handleClose={handleCloseModaleElimina}
        handleOk={deleteFaqInterna}
      />
    </>
  );
}

export default GestioneFaq;
