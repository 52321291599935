import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {
  Button,
  ButtonGroup,
  Card, Col, Row,
} from 'react-bootstrap';
import { formatoDataEOra } from '../../utils/formatData';
import { controlla } from '../../utils/verificaPermessi';
import ExportButton from '../ExportButton/ExportButton';
import EmptyState from '../EmptyState';

function CardGroup({
  templates,
  functionShow,
  functionEdit,
  functionDelete,
  functionPublish,
  functionClone,
  functionRetire,
  functionExport,
  functionSeeStats,
  handleStateModaleConferma,
  setFunzioneModaleConferma,
  setTestoModaleConferma,
  setIdTemplate,
  copyLink,
}) {
  return (
    <div className="mt-3">
      <Row lg={1} xs={1} className="g-4 mt-2 mb-2">
        {templates.length !== 0 ? templates.map((el) => (
          <Col key={el._id}>
            <Card>
              <Card.Header>
                <Row className="justify-content-between align-items-center">
                  <Col xs="auto">
                    <h3>{el?.titolo}</h3>
                    <div>
                      Creato da
                      <strong>
                        {` ${el?.creatore?.nome} ${el?.creatore?.cognome}`}
                      </strong>
                      {' '}
                      il
                      {' '}
                      <strong>{formatoDataEOra(el.dataCreazione)}</strong>
                    </div>
                  </Col>
                  <Col xs="auto">
                    <ButtonGroup className="float-end" aria-label={`Azioni disponibili per il questionario ${el.titolo}`}>
                      {el.stato === 'bozza' && controlla('X32') && (
                      <Button
                        className="me-2"
                        onClick={() => functionEdit(el._id)}
                        title={`Modifica il template ${el.titolo}`}
                      >
                        <FontAwesomeIcon aria-hidden="true" icon="edit" />
                      </Button>
                      )}
                      {controlla('X30') && (
                      <Button
                        className="me-2"
                        onClick={() => functionShow(el._id)}
                        title={`Visualizza il template ${el.titolo}`}
                      >
                        <FontAwesomeIcon aria-hidden="true" icon="search" />
                      </Button>
                      )}
                      {el.stato === 'pubblicato' && controlla('X35') && (
                      <Button
                        variant="danger"
                        className="me-2"
                        title={`Ritira il template ${el.titolo}`}
                        onClick={() => {
                          setIdTemplate(el._id);
                          setFunzioneModaleConferma(() => functionRetire);
                          setTestoModaleConferma('ritirerà');
                          handleStateModaleConferma();
                        }}
                      >
                        <FontAwesomeIcon aria-hidden="true" icon="arrow-left" />
                      </Button>
                      )}
                      {controlla('X31') && (
                      <Button
                        className="me-2"
                        onClick={() => functionClone(el._id)}
                        title={`Clona il template ${el.titolo}`}
                      >
                        <FontAwesomeIcon aria-hidden="true" icon="clone" />
                      </Button>
                      )}
                      {(el.stato === 'bozza' || el.stato === 'ritirato') && !el.dataFineValidita && controlla('X33') && (
                      <Button
                        className="text-center me-2"
                        variant="danger"
                        title={`Elimina il template ${el.titolo}`}
                        onClick={() => {
                          setIdTemplate(el._id);
                          setFunzioneModaleConferma(() => functionDelete);
                          setTestoModaleConferma('eliminerà');
                          handleStateModaleConferma();
                        }}
                      >
                        <FontAwesomeIcon aria-hidden="true" icon={['far', 'trash-alt']} />
                      </Button>
                      )}
                      {el.stato === 'bozza' && controlla('X34') && (
                      <Button
                        className="text-center me-2"
                        title={`Pubblica il template ${el.titolo}`}
                        onClick={() => {
                          setIdTemplate(el._id);
                          setFunzioneModaleConferma(() => functionPublish);
                          setTestoModaleConferma('pubblicherà');
                          handleStateModaleConferma();
                        }}
                      >
                        <FontAwesomeIcon aria-hidden="true" icon="arrow-up" />
                      </Button>
                      )}
                      {(el.stato === 'pubblicato' || el.stato === 'ritirato') && controlla('X36') && (
                      <Button
                        className="text-center me-2"
                        onClick={() => { functionSeeStats(el._id); }}
                        title={`Visualizza statistiche di ${el.titolo}`}
                      >
                        <FontAwesomeIcon aria-hidden="true" icon="chart-line" />
                      </Button>
                      )}
                      {el.stato === 'pubblicato' && el.isEsterno && (
                      <Button
                        className="text-center me-2"
                        onClick={() => copyLink(el)}
                        title={`Copia il link di ${el.titolo}`}
                      >
                        <FontAwesomeIcon aria-hidden="true" icon="link" />
                      </Button>
                      )}
                    </ButtonGroup>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body>
                <Row className="justify-content-between">
                  <Col xs="auto">
                    <strong>Domande: </strong>
                    {el?.totaleDomande}
                    <br />
                    <strong>Stato: </strong>
                    {el.stato}
                    {' '}
                    {el.dataFineValidita && '- eliminato'}
                  </Col>
                  {controlla('X30') && (
                  <Col xs="auto">
                    <ExportButton
                      id={el._id}
                      functionExport={functionExport}
                    />
                  </Col>
                  )}
                </Row>
              </Card.Body>
              <Card.Footer>
                <small className="text-muted">
                  Ultima modifica:
                  <strong>
                    {' '}
                    {`${el?.autoreUltimaModifica?.nome} ${el?.autoreUltimaModifica?.cognome}`}
                    {' '}
                  </strong>
                  il
                  {' '}
                  <strong>{formatoDataEOra(el.dataUltimaModifica)}</strong>
                </small>
              </Card.Footer>
            </Card>
          </Col>
        )) : (
          <EmptyState marginTop="3rem" />
        )}
      </Row>
    </div>
  );
}

export default CardGroup;
