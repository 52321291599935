import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';

function UserContacts({ user, onChange }) {
  return (
    <div>
      <h3 style={{ fontSize: '1.75rem' }}>Contatti personali</h3>
      <Row xs={1} className="my-3">
        <Form.Group as={Col} className="col-lg-6 mt-2">
          <Form.Label htmlFor="desc_email">Indirizzo email</Form.Label>
          <Form.Control value={user.desc_email} id="desc_email" onChange={onChange} />
        </Form.Group>
      </Row>
    </div>
  );
}

export default UserContacts;
