import React from 'react';
import {
  Button, Card, Col, Container, Form, Row,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from 'react-select';

function Autorizzazioni({
  autorizzazioni,
  onChangeAutorizzazioni,
  listaIdentita,
  aggiungiRow,
  handleCloseModaleUtenti,
  setIndexAutorizzazioneScelta,
  eliminaRow,
  setIdentitaScelta,
}) {
  return (
    <>
      <Button variant="primary" className="mb-2" onClick={aggiungiRow}>
        <FontAwesomeIcon aria-hidden="true" icon="plus" />
        {' '}
        AGGIUNGI
      </Button>
      {autorizzazioni?.map((el, i) => (
        <Card className="mb-3">
          <Card.Header className="d-flex justify-content-between align-items-center">
            <Card.Title className="mb-0">{`Identità ${i + 1}`}</Card.Title>
            <Button
              variant="danger"
              onClick={() => eliminaRow(i)}
              title="Elimina"
            >
              <FontAwesomeIcon aria-hidden="true" icon="trash" />
            </Button>
          </Card.Header>
          <Card.Body className="py-1">
            <Card.Text>
              <Container className="p-0">
                <Row className="my-1" key={el.idIdentita}>
                  <Col>
                    <Form.Label style={{ fontWeight: 'bold' }}>
                      Lista identità
                    </Form.Label>
                    <Select
                      id="sequ_k_identita"
                      name="sequ_k_identita"
                      options={listaIdentita}
                      placeholder="Scegli identità"
                      getOptionLabel={(option) => option.desc_nome_identita}
                      getOptionValue={(option) => option.sequ_k_identita}
                      value={listaIdentita?.filter(
                        (el1) => el1.sequ_k_identita === el.idIdentita,
                      )?.[0]}
                      onChange={(e) => onChangeAutorizzazioni('idIdentita', { target: { value: e ? e.sequ_k_identita : 0 } }, i, 'int')}
                      isClearable
                    />
                  </Col>
                  <Col xs="auto" className="d-flex flex-column">
                    <Form.Label style={{ fontWeight: 'bold' }}>
                      Utente autorizzato
                    </Form.Label>
                    {el.codiceFiscale ? (
                      <Row>
                        <Col>
                          <Form.Label style={{ marginRight: '1rem' }}>
                            {el.codiceFiscale}
                          </Form.Label>
                          <Button
                            variant="danger"
                            className="mb-2"
                            onClick={() => onChangeAutorizzazioni('codiceFiscale', { target: { value: null } }, i)}
                          >
                            <FontAwesomeIcon aria-hidden="true" icon="trash" />
                          </Button>
                        </Col>
                      </Row>
                    ) : (
                      <Button
                        variant="primary"
                        className="mb-2"
                        onClick={() => {
                          setIdentitaScelta(autorizzazioni[i]?.idIdentita);
                          setIndexAutorizzazioneScelta(i); handleCloseModaleUtenti();
                        }}
                      >
                        <FontAwesomeIcon aria-hidden="true" icon="user" className="me-2" />
                        SCEGLI UTENTE
                      </Button>
                    )}
                  </Col>
                </Row>
              </Container>
            </Card.Text>
          </Card.Body>
        </Card>
      ))}
    </>
  );
}

export default Autorizzazioni;
