export const optionsTipoBatch = [
  {
    codi_tipo_batch: 'C',
    tipo_batch: 'Caricamento utenti',
  },
  {
    codi_tipo_batch: 'A',
    tipo_batch: 'Attribuzione profili',
  },
];

export const optionsStatoElaborazione = [
  {
    codi_stato_elaborazione: 1,
    stato_elaborazione: 'Da eseguire',
  },
  {
    codi_stato_elaborazione: 2,
    stato_elaborazione: 'In corso',
  },
  {
    codi_stato_elaborazione: 3,
    stato_elaborazione: 'Eseguito',
  },
  {
    codi_stato_elaborazione: 4,
    stato_elaborazione: 'Eseguito con errore',
  },
];
