import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Col, Form, Row, Button, Accordion,
  Container,
} from 'react-bootstrap';
import Select from 'react-select';
import { useEffect, useState } from 'react';
import { setItem } from '../../../../utils/storage';

function FiltriAIProfiliProfessionali({
  filtri,
  setFiltri,
  onChangeFiltri,
  getProfiliProfessionaliAI,
  optionsAreeContrattuali,
  optionsDimensioniProfessionali,
  optionsFamiglieProfessionali,
  optionsCompetenze,
}) {
  // State per gestire il re-rendering del form e pulizia dei campi
  const [formKey, setFormKey] = useState(0);

  const initialState = {
    ...filtri,
    area_contrattuale: [],
    dimensione_professionale: [],
    famiglia_professionale: [],
    competences: [],
  };

  // Function per il reset dei filtri
  const resetFiltri = () => {
    setFiltri(initialState);
    setFormKey((prevKey) => prevKey + 1);
    getProfiliProfessionaliAI(initialState);
  };

  useEffect(() => {
    resetFiltri();
  }, []);

  return (
    <Accordion className="mb-3">
      <Accordion.Item eventKey="0">
        <Accordion.Header>Campi aggiuntivi</Accordion.Header>
        <Accordion.Body>
          <Form className="mt-3">
            <Row className="mb-3">
              <Form.Group as={Col} lg>
                <Form.Label>
                  Area Contrattuale
                </Form.Label>
                <Select
                  id="area_contrattuale"
                  name="area_contrattuale"
                  options={optionsAreeContrattuali}
                  placeholder="Scegli Area Contrattuale"
                  getOptionLabel={(option) => option.area_contrattuale}
                  getOptionValue={(option) => option.area_contrattuale}
                  value={optionsAreeContrattuali?.filter(
                    (el) => el.area_contrattuale
                    === filtri.area_contrattuale,
                  )?.[0]}
                  onChange={(selectedOption, config) => { onChangeFiltri(selectedOption, config); }}
                  isClearable
                  isMulti
                  key={formKey}
                />
              </Form.Group>
              <Form.Group as={Col} lg>
                <Form.Label>
                  Dimensione Professionale
                </Form.Label>
                <Select
                  id="dimensione_professionale"
                  name="dimensione_professionale"
                  options={optionsDimensioniProfessionali}
                  placeholder="Scegli Dimensione Professionale"
                  getOptionLabel={(option) => option.dimensione_professionale}
                  getOptionValue={(option) => option.dimensione_professionale}
                  value={optionsAreeContrattuali?.filter(
                    (el) => el.dimensione_professionale
                    === filtri.dimensione_professionale,
                  )?.[0]}
                  onChange={(selectedOption, config) => { onChangeFiltri(selectedOption, config); }}
                  isClearable
                  isMulti
                  key={formKey}
                />
              </Form.Group>
              <Form.Group as={Col} lg>
                <Form.Label>
                  Famiglia Professionale
                </Form.Label>
                <Select
                  id="famiglia_professionale"
                  name="famiglia_professionale"
                  options={optionsFamiglieProfessionali}
                  placeholder="Scegli Famiglia Professionale"
                  getOptionLabel={(option) => option.famiglia_professionale}
                  getOptionValue={(option) => option.famiglia_professionale}
                  value={optionsAreeContrattuali?.filter(
                    (el) => el.famiglia_professionale
                    === filtri.famiglia_professionale,
                  )?.[0]}
                  onChange={(selectedOption, config) => { onChangeFiltri(selectedOption, config); }}
                  isClearable
                  isMulti
                  key={formKey}
                />
              </Form.Group>
              <Form.Group as={Col} lg>
                <Form.Label>
                  Competenze
                </Form.Label>
                <Select
                  id="competences"
                  name="competences"
                  options={optionsCompetenze}
                  placeholder="Scegli le Competenze"
                  getOptionLabel={(option) => option.competences}
                  getOptionValue={(option) => option.competences}
                  value={optionsCompetenze?.filter(
                    (el) => el.competences
                    === filtri.competences,
                  )?.[0]}
                  onChange={(selectedOption, config) => { onChangeFiltri(selectedOption, config); }}
                  isClearable
                  isMulti
                  key={formKey}
                />
              </Form.Group>
            </Row>
          </Form>
          <Container style={{ padding: 0, textAlign: 'end' }}>
            <Button
              variant="outline-dark"
              onClick={() => resetFiltri()}
            >
              <FontAwesomeIcon aria-hidden="true" icon={['fas', 'eraser']} style={{ marginRight: '2px' }} />
              PULISCI CAMPI
            </Button>
            {' '}
            <Button
              variant="outline-primary"
              onClick={() => {
                setItem('filtri', JSON.stringify(filtri));
                getProfiliProfessionaliAI(filtri);
              }}
            >
              <FontAwesomeIcon aria-hidden="true" icon={['fas', 'search']} style={{ marginRight: '2px' }} />
              CERCA
            </Button>
          </Container>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default FiltriAIProfiliProfessionali;
