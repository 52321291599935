const optionsAssegnatiPerProfilo = (valori) => ({
  title: {
    text: 'Profili Assegnati per Profilo Professionale',
    top: '5%',
    left: 'center',
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'shadow',
    },
  },
  legend: {
    data: ['Numero Profili Assegnati'],
    bottom: '0%',
  },
  grid: {
    top: '20%',
    left: '3%',
    right: '4%',
    bottom: '15%',
    containLabel: true,
  },
  xAxis: {
    type: 'value',
  },
  yAxis: {
    type: 'category',
    data: valori.map((el) => el['Profilo Professionale']),
  },
  series: [
    {
      name: 'Numero Profili Assegnati',
      type: 'bar',
      data: valori.map((el) => ({
        value: parseInt(el['Numero Profili Assegnati'], 10),
        name: el['Profilo Professionale'],
      })),
      itemStyle: {
        emphasis: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)',
        },
      },
    },
  ],

});

export default optionsAssegnatiPerProfilo;
