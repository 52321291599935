import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import themeColors from '../../../../utils/contants/theme-colors';

const fieldsDemoCicliAssessment = (onView) => (
  [
    {
      dataField: 'fk_codi_stato_d_stato_ciclo_assesment.desc_stato',
      text: 'Stato',
      classes: 'column-data',
      headerStyle: () => ({ width: '15%' }),
    },
    {
      dataField: 'desc_titolo',
      text: 'Titolo',
      classes: 'column-data',
      headerStyle: () => ({ width: '30%' }),
    },
    {
      dataField: 'df1',
      isDummyField: true,
      text: 'Azioni',
      headerStyle: () => ({ width: '25%' }),
      formatter: (e, row) => (
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <FontAwesomeIcon
            aria-hidden="true"
            icon={['fas', 'search']}
            size="lg"
            title="Visualizza"
            id={`Visualizza${row.sequ_k_ciclo_assesment}`}
            style={{ color: themeColors.primary }}
            onClick={() => onView()}
          />
        </div>
      ),
    },
  ]
);

export default fieldsDemoCicliAssessment;
