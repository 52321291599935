import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Col, Form, Row, Button, Accordion,
  Container,
} from 'react-bootstrap';
import Select from 'react-select';
import { useEffect, useState } from 'react';
import { setItem } from '../../../../utils/storage';

function FiltriAIProfiliRuolo({
  filtri,
  setFiltri,
  onChangeFiltri,
  getProfiliRuoloAI,
  optionsAmbitoRuolo,
  optionsCompetenze,
}) {
  // State per gestire il re-rendering del form e pulizia dei campi
  const [formKey, setFormKey] = useState(0);

  const initialState = {
    ...filtri,
    ambito_ruolo: [],
    competences: [],
  };

  // Function per il reset dei filtri
  const resetFiltri = () => {
    setFiltri(initialState);
    setFormKey((prevKey) => prevKey + 1);
    getProfiliRuoloAI(initialState);
  };

  useEffect(() => {
    resetFiltri();
  }, []);

  return (
    <Accordion className="mb-3">
      <Accordion.Item eventKey="0">
        <Accordion.Header>Campi Aggiuntivi</Accordion.Header>
        <Accordion.Body>
          <Form className="mt-3">
            <Row className="mb-3">
              <Form.Group as={Col} lg>
                <Form.Label>
                  Ambito di Ruolo
                </Form.Label>
                <Select
                  id="ambito_ruolo"
                  name="ambito_ruolo"
                  options={optionsAmbitoRuolo}
                  placeholder="Scegli Ambito di Ruolo"
                  getOptionLabel={(option) => option.ambito_ruolo}
                  getOptionValue={(option) => option.ambito_ruolo}
                  value={optionsAmbitoRuolo?.filter(
                    (el) => el.ambito_ruolo
                    === filtri.ambito_ruolo,
                  )?.[0]}
                  onChange={(selectedOption, config) => { onChangeFiltri(selectedOption, config); }}
                  isClearable
                  isMulti
                  key={formKey}
                />
              </Form.Group>
              <Form.Group as={Col} lg>
                <Form.Label>
                  Competenze
                </Form.Label>
                <Select
                  id="competences"
                  name="competences"
                  options={optionsCompetenze}
                  placeholder="Scegli le Competenze"
                  getOptionLabel={(option) => option.competences}
                  getOptionValue={(option) => option.competences}
                  value={optionsCompetenze?.filter(
                    (el) => el.competences
                    === filtri.competences,
                  )?.[0]}
                  onChange={(selectedOption, config) => { onChangeFiltri(selectedOption, config); }}
                  isClearable
                  isMulti
                  key={formKey}
                />
              </Form.Group>
            </Row>
          </Form>
          <Container style={{ padding: 0, textAlign: 'end' }}>
            <Button
              variant="outline-dark"
              onClick={() => resetFiltri()}
            >
              <FontAwesomeIcon aria-hidden="true" icon={['fas', 'eraser']} style={{ marginRight: '2px' }} />
              PULISCI CAMPI
            </Button>
            {' '}
            <Button
              variant="outline-primary"
              onClick={() => {
                setItem('filtri', JSON.stringify(filtri));
                getProfiliRuoloAI(filtri);
              }}
            >
              <FontAwesomeIcon aria-hidden="true" icon={['fas', 'search']} style={{ marginRight: '2px' }} />
              CERCA
            </Button>
          </Container>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default FiltriAIProfiliRuolo;
