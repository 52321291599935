import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as Survey from 'survey-react';
import { Container } from 'react-bootstrap';
import { Context } from '../../../../utils/Context';
import questionariService from '../../../../services/questionariService';
import TitlePage from '../../../../components/GestisciUtenti/TitoloPagina';
import CardGroupSezioni from '../../../../components/Questionari/CardGroupSezioni';
import ModaleConferma from '../../../../components/modali/ModaleConferma';
import history from '../../../../utils/history';

Survey.StylesManager.applyTheme('blue');

Survey.surveyLocalization.currentLocale = 'it';

function CompilaQuestionario() {
  const [questionario, setQuestionario] = useState();
  const [sezioni, setSezioni] = useState();
  const [sezione, setSezione] = useState();
  const [showSurvey, setShowSurvey] = useState(false);
  const [openModaleConferma, setOpenModaleConferma] = useState(false);
  // eslint-disable-next-line prefer-const
  let [model, setModel] = useState();
  const { idQuestionario } = useParams();
  const [context, setContext] = useContext(Context);

  function handleStateModaleConferma() {
    setOpenModaleConferma(!openModaleConferma);
  }

  // Function collegata al servizio per recuperare un questionario in base all'id
  async function getQuestionarioById() {
    try {
      const response = await questionariService.getQuestionarioById(idQuestionario);
      setQuestionario(response);
      setSezioni(response.sezioni);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // Function collegata al servizio per modificare un questionario
  async function editQuestionario(domandeCompilate) {
    try {
      const body = {
        domandeCompilate,
        totaleDomandeCompilate: Object.keys(domandeCompilate).length,
      };
      const res = await questionariService.editSezione(idQuestionario, sezione, body);
      setContext(() => ({
        ...context, openConferma: true, testo: res.message || 'Operazione eseguita correttamente',
      }));
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // Function collegata al servizio per chiudere un questionario
  async function closeQuestionario() {
    try {
      const res = await questionariService.closeQuestionario(idQuestionario);
      setContext(() => ({
        ...context, openConferma: true, testo: res.message || 'Operazione eseguita correttamente',
      }));
      history.push('/area-riservata/questionari');
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // Function per mostrare la sezione selezionata
  function showSezione(titolo) {
    setSezione(titolo);
    setShowSurvey(true);
    const modelSurveyJs = new Survey.Model(sezioni[titolo].template);
    modelSurveyJs.data = sezioni[titolo].domandeCompilate;
    setModel(modelSurveyJs);
  }

  function onValueChanged() {
    // console.log('value changed!');
  }

  // Function attivata al click di complete
  async function onComplete(sender) {
    await editQuestionario(sender.data);
    setShowSurvey(false);
    getQuestionarioById();
  }

  // useEffect
  useEffect(() => {
    getQuestionarioById();
  }, []);

  return (
    <>
      <Container className="p-0">
        <TitlePage
          title="Compilazione Questionario"
          subtitle={questionario ? questionario.titolo : null}
          showIndietro
          functionIndietro={() => history.push('/area-riservata/questionari')}
          showCompleta
          functionCompleta={handleStateModaleConferma}
        />
        {sezioni && (
        <CardGroupSezioni
          sezioni={sezioni}
          functionShow={showSezione}
        />
        )}
        <br />
        {showSurvey && (
        <Survey.Survey
          model={model}
          onComplete={onComplete}
          onValueChanged={onValueChanged}
        />
        )}
      </Container>
      {openModaleConferma && (
        <ModaleConferma
          labelBottone="Conferma"
          coloreAzione="primary"
          title="Sei sicuro di procedere?"
          body={'L\'operazione invierà il questionario compilato.'}
          show={openModaleConferma}
          handleClose={handleStateModaleConferma}
          handleOk={closeQuestionario}
        />
      )}
    </>
  );
}

export default CompilaQuestionario;
