/* eslint-disable react/no-unknown-property */
import {
  Container, Image,
  Row,
} from 'react-bootstrap';
import { useContext, useEffect, useState } from 'react';
import logoAI from '../../../../images/LogoAi.svg';
import SistemaProfessionaleService from '../../../../services/sistemaProfessionaleService';
import { Context } from '../../../../utils/Context';
import Table from '../../../../components/Shared/Table';
import EmptyState from '../../../../components/EmptyState';
import { getItem } from '../../../../utils/storage';
import FormProfiloProfessionale from '../FormProfiloProfessionale';
import SpinnerBody from '../../../../components/Shared/SpinnerBody';
import fieldsProfiliProfessionaliAI from '../ricerca-semantica-ai/fieldsProfiloProfessionaleAI';
import TitlePage from '../../../../components/GestisciUtenti/TitoloPagina';

function RicercaSimiliAI({
  filtri,
}) {
  const [context, setContext] = useContext(Context);
  const { sequ_k_comparto } = getItem('compartoSelected') ? JSON.parse(getItem('compartoSelected')) : 0;
  const [isLoading, setIsLoading] = useState(true);
  const [step, setStep] = useState(0);
  const [profiliProfessionaliList, setProfiliProfessionaliList] = useState([]);
  const [stateProfiloProfessionale, setStateProfiloProfessionale] = useState({});
  const [gradingList, setGradingList] = useState([]);
  const [ambitiRuoloList, setAmbitiRuoloList] = useState([]);

  // Function collegata al servizio per ottenere la lista delle competenze
  async function getProfiliProfessionaliAI(filters) {
    try {
      const response = await SistemaProfessionaleService
        .getProfiliProfessionaliAI(filters);
      setProfiliProfessionaliList(response.hits);
      setIsLoading(false);
      // setProfiliProfessionaliList([
      //   {
      //     title: 'Project Manager - Senior',
      //     descriptor: 'Controlla tutto.',
      //     profile_id: 'ELL_PP0001',
      //     famiglia_professionale: 'ELL_FP004 - Servizi di funzionamento',
      //     dimensione_professionale: 'ELL_DP003 - Controllo e valutazione',
      //     area_contrattuale: 'ELL_CCNL001 - FUNZIONARI ED E.Q.',
      //     similarity_score: 84.73,
      //     codice_riva: 1,
      //     comparto: 'Enti Locali',
      //     codice_amministrazione: null,
      //   },
      //   {
      //     title: 'IT Security',
      //     descriptor: 'Controlla tutto.',
      //     profile_id: 'ELL_PP0002',
      //     famiglia_professionale: 'ELL_FP003 - Risorse tecnologiche e transizione digitale',
      //     dimensione_professionale: 'ELL_DP003 - Controllo e valutazione',
      //     area_contrattuale: 'ELL_CCNL004 - OPERATORE',
      //     similarity_score: 70.50,
      //     codice_riva: 2,
      //     comparto: 'Enti Locali',
      //     codice_amministrazione: 'arpam',
      //   },
      // ]);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // function collegata al servizio per ottenere la lista dei grading
  async function getGrading(offset, limit, filters) {
    try {
      const response = await SistemaProfessionaleService
        .getGrading(filters, offset, limit);
      setGradingList(response.rows);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // Function collegata al servizio per ottenere la lista degli ambiti di ruolo
  async function getAmbitiRuolo(offset, limit, filters) {
    try {
      const response = await SistemaProfessionaleService
        .getAmbitiRuolo(filters, offset, limit);
      setAmbitiRuoloList(response.rows);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // Function per rimuovere le competenze duplicate.
  function removeDuplicatesComps(list) {
    const compsMap = {};
    list.forEach((el) => {
      if (
        !compsMap[el.fk_sequ_competenza] || compsMap[el.fk_sequ_competenza].grading < el.grading
      ) {
        compsMap[el.fk_sequ_competenza] = el;
      }
    });
    return Object.values(compsMap);
  }

  // Function per avere la lista degli ambiti relativi alla famiglia professionale selezionata
  const getAmbitiRuoloList = (list, idFamigliaProfessionale) => {
    if (list.length > 0) {
      return list.filter((el) => el.fk_sequ_famiglia_professionale === idFamigliaProfessionale);
    } return [];
  };

  // Function collegata al servizio di get di un particolare profilo professionale
  async function getProfiloProfessionaleById(id) {
    try {
      const response = await SistemaProfessionaleService.getProfiloProfessionaleById(id);
      const listCompetenzeMap = response.d_rel_profilo_prof_comps.map(
        (el) => ({
          ...el.fk_sequ_competenza_d_competenza,
          fk_sequ_competenza: el.fk_sequ_competenza,
          grading: el.codi_grading,
          grading_base: el.codi_grading_base,
          provenienza: el.provenienza,
        }),
      );
      setStateProfiloProfessionale({
        ...response,
        fk_sequ_competenze_totali: listCompetenzeMap,
        fk_sequ_competenze_iniziali: listCompetenzeMap,
        fk_sequ_competenze: removeDuplicatesComps(listCompetenzeMap),
        fk_sequ_ambiti_ruoli: response.d_rel_profilo_prof_ambitos.map(
          (el) => el.fk_sequ_ambito_ruolo,
        ),
      });
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // Function per visualizzare il dettaglio della competenze
  const onView = (row) => {
    getProfiloProfessionaleById(row.codice_riva);
    // setModalShow(true);
    setStep(step + 1);
  };

  useEffect(() => {
    getAmbitiRuolo(0, 1000, { fk_sequ_comparto: sequ_k_comparto });
    getGrading(0, 1000, {});
    getProfiliProfessionaliAI(filtri);
  }, []);

  return (
    isLoading ? (
      <SpinnerBody />
    ) : (
      step === 0 ? (
        <Container className="p-0">
          <h3>
            <>
              Ricerca Profili Professionali
              {' '}
              <Image src={logoAI} width="30" height="30" />
            </>
          </h3>
          <Row className="justify-content-center mt-3">
            {profiliProfessionaliList.length > 0 ? (
              <Table
                data={profiliProfessionaliList}
                fields={fieldsProfiliProfessionaliAI(onView)}
                keyField="profile_id"
              />
            ) : (
              <EmptyState
                subtitle="Nessun risultato compatibile con i dati inseriti."
              />
            )}
          </Row>
        </Container>
      ) : (
        <>
          <TitlePage
            showIndietro
            functionIndietro={() => setStep(step - 1)}
          />
          <FormProfiloProfessionale
            stateProfiloProfessionale={stateProfiloProfessionale}
            onChange={() => {}}
            optionsAmbitiRuolo={getAmbitiRuoloList(
              ambitiRuoloList,
              stateProfiloProfessionale.fk_sequ_famiglia_professionale,
            )}
            optionsGrading={gradingList}
            isDisabled
            setModalShowCtp={() => {}}
            setModalShowCc={() => {}}
            setModalProfiloRuolo={() => {}}
            setStateProfiloRuolo={() => {}}
            onDeleteCompetenza={() => {}}
            errors={{}}
            handleTableChange={() => {}}
            isAI
          />
        </>
      )
    )
  );
}

export default RicercaSimiliAI;
