import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {
  Button,
  Card, Col, Row,
} from 'react-bootstrap';

function CardGroupSezioni({
  sezioni,
  functionShow,
  onlyView,
}) {
  return (
    <div className="mt-3">
      <Row xs={1} md={3} className="g-4">
        {Object.keys(sezioni).map((el) => (
          <Col key={sezioni[el].titolo}>
            <Card>
              <Card.Body>
                <Card.Title>
                  {sezioni[el].titolo}
                </Card.Title>
                <p>
                  Descrizione:
                  {' '}
                  <strong>{sezioni[el].descrizione}</strong>
                </p>
                <p>
                  Domande Compilate:
                  {' '}
                  <strong>
                    {sezioni[el].totaleDomandeCompilate || 0}
                    /
                    {sezioni[el].template.pages
                      ? Object.entries(sezioni[el].template.pages)[0][1].elements?.length
                      : sezioni[el].template.elements?.length}
                  </strong>
                </p>
                <Button
                  className="me-2 float-end"
                  onClick={() => { functionShow(sezioni[el].titolo); }}
                  title={`Visualizza la sezione ${sezioni[el].titolo}`}
                >
                  <FontAwesomeIcon aria-hidden="true" icon={onlyView ? 'search' : 'edit'} />
                </Button>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
}

export default CardGroupSezioni;
