import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Col, Container, Form, Row,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams } from 'react-router-dom';
import { Context } from '../../../utils/Context';
import templateService from '../../../services/templateService';
import TitlePage from '../../../components/GestisciUtenti/TitoloPagina';
import history from '../../../utils/history';
import ExportButton from '../../../components/ExportButton/ExportButton';
import { formatoDataEOra } from '../../../utils/formatData';
import CardGroupStatistiche from '../../../components/Template/CardGroupStatistiche';
import CardGroupStatisticheQuestionari from '../../../components/Template/CardGroupStatisticheQuestionari';

const templateUrl = process.env.REACT_APP_QUESTIONARI_URL;

function VisualizzaStatistiche() {
  const { idTemplate } = useParams();
  const [context, setContext] = useContext(Context);
  const [showSezioneQuestionari, setShowSezioneQuestionari] = useState(false);
  const [questionari, setQuestionari] = useState();
  const [newTemplate, setNewTemplate] = useState({
    titolo: '',
    descrizione: '',
    creatore: {
      nome: '',
      cognome: '',
    },
    dataCreazione: '',
    autoreUltimaModifica: {
      nome: '',
      cognome: '',
    },
    dataUltimaModifica: '',
    sezioni: {},
  });
  const [statistiche, setStatistiche] = useState({
    titolo: '',
    descrizione: '',
    template: {},
  });

  // Function per gestione Sezione Questionari
  function handleSezioneQuestionari() {
    setShowSezioneQuestionari(!showSezioneQuestionari);
  }

  // Function collegata al servizio di get di un determinato template
  async function getTemplate() {
    try {
      const response = await templateService.getTemplateById(idTemplate);
      setNewTemplate(response);
      return response;
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
      return err;
    }
  }

  // Function collegata al servizio di get delle Statistiche di un determinato template
  async function getStatisticheTemplate() {
    try {
      const response = await templateService.getStatisticaByTemplate(idTemplate);
      setStatistiche(response);
      return response;
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
      return err;
    }
  }

  // Function collegata al servizio di get della lista dei questionari di un determinato template
  async function getListaQuestionariByTemplate() {
    try {
      const response = await templateService.getListaQuestionariByTemplate(idTemplate);
      setQuestionari(response);
      return response;
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
      return err;
    }
  }

  // Function attivata al click di esporta template
  async function exportTemplate(id, formato) {
    const a = document.createElement('A');
    a.href = `${templateUrl}/template/${id}/statistica/esporta?formato=${formato}`;
    a.target = '_blank';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  // Function attivata al click di visualizza questionario
  function showQuestionario(idQuestionario) {
    history.push(`/area-riservata/questionari/visualizza-questionario/${idQuestionario}?isEsterno=${newTemplate.isEsterno}`);
  }

  // useEffect
  useEffect(() => {
    getTemplate();
    getStatisticheTemplate();
    getListaQuestionariByTemplate();
  }, []);

  return (
    <Container className="p-0">
      <TitlePage
        title="Template"
        subtitle="In questa sezione è possibile visualizzare le statistiche del Template"
        showIndietro
        functionIndietro={() => history.push('/area-riservata/template')}
        showButtonExport
        buttonExport={(
          <ExportButton
            functionExport={exportTemplate}
            id={idTemplate}
          />
        )}
      />
      <div>
        Creato da
        <strong>
          {' '}
          {`${newTemplate?.creatore?.nome} ${newTemplate?.creatore?.cognome}`}
          {' '}
        </strong>
        {' '}
        il
        {' '}
        <strong>{formatoDataEOra(newTemplate.dataCreazione)}</strong>
        <br />
        Ultima modifica:
        <strong>
          {' '}
          {`${newTemplate?.autoreUltimaModifica?.nome} ${newTemplate?.autoreUltimaModifica?.cognome}`}
          {' '}
        </strong>
        {' '}
        il
        {' '}
        <strong>{formatoDataEOra(newTemplate.dataUltimaModifica)}</strong>
      </div>
      <br />
      <Container className="p-0">
        <Row>
          <Col>
            <p className="h4" style={{ lineHeight: '1.6' }}>
              Informazioni principali
            </p>
          </Col>
        </Row>
        <Form>
          <Row>
            <Form.Group as={Col} lg controlId="titolo">
              <Form.Label>Titolo</Form.Label>
              <Form.Control value={newTemplate.titolo} disabled />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} lg controlId="descrizione">
              <Form.Label>Descrizione</Form.Label>
              <Form.Control value={newTemplate.descrizione} disabled />
            </Form.Group>
          </Row>
        </Form>
        <Row>
          <Col className="mb-4">
            <Form.Group as={Col} lg controlId="tipo">
              <Form.Label>Stato</Form.Label>
              <Form.Control value={newTemplate.stato} disabled />
            </Form.Group>
          </Col>
          <Col />
        </Row>
        <div className="separator border-1 border-bottom" />
        <Row className="mt-3 justify-content-between align-items-center">
          <Col xs="auto">
            <p className="h4" style={{ lineHeight: '1.6' }}>
              Questionari
            </p>
          </Col>
          <Col xs="auto">
            <Button
              variant="primary"
              onClick={handleSezioneQuestionari}
            >
              <FontAwesomeIcon aria-hidden="true" icon="file-alt" className="me-2" />
              {!showSezioneQuestionari ? 'VISUALIZZA QUESTIONARI' : 'NASCONDI QUESTIONARI'}
            </Button>
          </Col>
        </Row>
        <Row className="justify-content-between align-items-start">
          <Col xs="auto" className="mb-3">
            <strong>
              Numero questionari compilati:
            </strong>
            {' '}
            {statistiche.numeroQuestionariCompilati}
            <br />
            <strong>
              Numero questionari non completati:
            </strong>
            {' '}
            {statistiche.numeroQuestionariNonCompletati}
          </Col>
        </Row>
        {showSezioneQuestionari && (
          <CardGroupStatisticheQuestionari
            questionari={questionari}
            functionShow={showQuestionario}
          />
        )}
        <div className="separator border-1 border-bottom" />
        <Row className="mt-3">
          <Col>
            <p className="h4" style={{ lineHeight: '1.6' }}>
              Sezioni
            </p>
          </Col>
        </Row>
        <CardGroupStatistiche
          sezioni={newTemplate.sezioni}
          statistiche={statistiche.sezioni}
          numQuestionari={statistiche.numeroQuestionariCompilati}
        />
      </Container>
    </Container>
  );
}

export default VisualizzaStatistiche;
