import React, { useContext, useEffect, useState } from 'react';
import {
  Button, Col, Container, Form, Row,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams } from 'react-router-dom';
import { Context } from '../../../utils/Context';
import templateService from '../../../services/templateService';
import canaliService from '../../../services/canaliService';
import TitlePage from '../../../components/GestisciUtenti/TitoloPagina';
import ModaleConferma from '../../../components/modali/ModaleConferma';
import ModaleRicercaUtenti from '../../../components/modali/ModaleRicercaUtenti';
import Autorizzazioni from '../../../components/Template/Autorizzazioni';
import NewTemplateSection from '../../../components/Template/NewTemplateSection';
import CardGroupSezioni from '../../../components/Template/CardGroupSezioni';
import history from '../../../utils/history';
import NewFormSection from '../../../components/Template/NewFormSection';

function GestioneTemplate() {
  const { idTemplate } = useParams();
  const [context, setContext] = useContext(Context);
  const [showNewFormSezione, setShowNewFormSezione] = useState();
  const [showNewTemplateLayout, setShowNewTemplateLayout] = useState();
  const [openModaleConferma, setOpenModaleConferma] = useState(false);
  const [testoModaleConferma, setTestoModaleConferma] = useState('');
  const [funzioneModaleConferma, setFunzioneModaleConferma] = useState();
  const [showModaleUtenti, setShowModaleUtenti] = useState(false);
  const [identitaScelta, setIdentitaScelta] = useState();
  const [listaIdentita, setListaIdentita] = useState([]);
  const [indexAutorizzazioneScelta, setIndexAutorizzazioneScelta] = useState();
  const [addOrEdit, setAddOrEdit] = useState(true);
  const [newQuestionario, setNewQuestionario] = useState({
    titolo: '',
    descrizione: '',
    visibilita: [],
    sezioni: {},
    isEsterno: false,
    isTracciato: false,
  });
  const [newSezione, setNewSezione] = useState({
    titolo: '',
    descrizione: '',
    template: {},
  });
  const [editedSezione, setEditedSezione] = useState({
    titolo: '',
    descrizione: '',
    template: {},
  });

  // Function per gestione della modale di conferma
  function handleStateModaleConferma() {
    setOpenModaleConferma(!openModaleConferma);
  }

  // Function per gestione della modale degli utenti
  function handleCloseModaleUtenti() {
    setShowModaleUtenti(!showModaleUtenti);
  }

  // Function per gestire la compilazione del form (testo,descrizione)
  async function onChangeTemplate(e) {
    const {
      id, value, checked, type,
    } = e.target;
    setNewQuestionario({
      ...newQuestionario,
      [id]: type === 'checkbox' ? checked : value,
    });

    // La riga di codice serve per fare "uncheck" dell'operazione C quando si fa uncheck di M
    if (document.getElementById('isEsterno').checked) {
      setNewQuestionario((prevState) => ({
        ...prevState,
        visibilita: [],
      }));
    }
  }

  // Function per gestire la variazione della sezione del form
  async function onChangeSezione(e) {
    const { id, value } = e.target;
    if (addOrEdit) {
      setNewSezione({
        ...newSezione,
        [id]: value,
      });
    } else {
      setEditedSezione({
        ...editedSezione,
        [id]: value,
      });
    }
  }

  // Function collegata al servizio di get di un determinato template
  async function getTemplate() {
    try {
      const response = await templateService.getTemplateById(idTemplate);
      setNewQuestionario(response);
      return response;
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
      return err;
    }
  }

  // Function collegata al servizio di get delle sezioni di un template
  async function getSezioni() {
    try {
      const response = await templateService.getTemplateById(idTemplate);
      setNewQuestionario({
        ...newQuestionario,
        sezioni: response.sezioni,
      });
      return response;
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
      return err;
    }
  }

  // Function collegata al servizio di modifica di un template
  async function editTemplate() {
    try {
      const res = await templateService.editTemplate(idTemplate, newQuestionario);
      setContext(() => ({
        ...context, openConferma: true, testo: res.message || 'Operazione eseguita correttamente',
      }));
      history.push('/area-riservata/template');
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // Function per mostrare il form di una nuova sezione
  function showNewFormSezioneHandle() {
    setShowNewFormSezione(!showNewFormSezione);
    setAddOrEdit(true);
    setNewSezione({
      titolo: '',
      descrizione: '',
      template: {},
    });
  }

  // Function per mostrare il layout di una nuovo tmeplate
  function showNewTemplateLayoutHandle() {
    setShowNewTemplateLayout(!showNewTemplateLayout);
  }

  // Function collegata al servizio di creazione di una sezione del template
  async function addSezione() {
    try {
      const res = await templateService.creaSezioneTemplate(newQuestionario._id, newSezione);
      setContext(() => ({
        ...context, openConferma: true, testo: res.message || 'Operazione eseguita correttamente',
      }));
      getSezioni();
      showNewFormSezioneHandle();
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // Function collegata al servizio di eliminazione di una sezione del template
  async function deleteSezione(titoloSezione) {
    try {
      const res = await templateService.eliminaSezioneTemplate(newQuestionario._id, titoloSezione);
      setContext(() => ({
        ...context, openConferma: true, testo: res.message || 'Operazione eseguita correttamente',
      }));
      getSezioni();
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // Function collegata al servizio di clonazione di una sezione del template
  async function cloneSezione(titoloSezione) {
    try {
      const res = await templateService.clonaSezioneTemplate(newQuestionario._id, titoloSezione);
      setContext(() => ({
        ...context, openConferma: true, testo: res.message || 'Operazione eseguita correttamente',
      }));
      getSezioni();
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // Function attivata al click di modifica Sezione
  function handleEditSezione(el) {
    if (!showNewFormSezione) showNewFormSezioneHandle();
    setNewSezione(el);
    setEditedSezione(el);
    setAddOrEdit(false);
  }

  // Aggiunge le domande ,create nel layout NewTemplateSection.js, nella sezione scelta
  async function modificaSezione() {
    try {
      const res = await templateService.modificaSezioneTemplate(
        newQuestionario._id,
        newSezione.titolo,
        editedSezione,
      );
      setContext(() => ({
        ...context, openConferma: true, testo: res.message || 'Operazione eseguita correttamente',
      }));
      setShowNewFormSezione(false);
      getSezioni();
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // Function collegata al servizio di modifica della sezione di un template
  async function modificaTemplateSezione(newTemplate, numeroTotaleDomande) {
    editedSezione.totaleDomande = numeroTotaleDomande;
    editedSezione.template = newTemplate;
    try {
      const res = await templateService.modificaSezioneTemplate(
        newQuestionario._id,
        editedSezione.titolo,
        editedSezione,
      );
      setContext(() => ({
        ...context, openConferma: true, testo: res.message || 'Operazione eseguita correttamente',
      }));
      setShowNewTemplateLayout(false);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // Function per aggiungere un elemento di visibilità nelle autorizzazioni
  function aggiungiRow() {
    setNewQuestionario({
      ...newQuestionario,
      visibilita: [
        ...newQuestionario.visibilita,
        {
          idIdentita: 0,
          codiceFiscale: null,
        },
      ],
    });
  }

  // Function per rimuovere un elemento di visibilità nelle autorizzazioni
  function eliminaRow(index) {
    setNewQuestionario({
      ...newQuestionario,
      visibilita: [
        ...newQuestionario.visibilita.filter((el, indexEl) => indexEl !== index)],
    });
  }

  // Function per gestire le variazioni nelle autorizzazioni
  const onChangeAutorizzazioni = (prop, event, index, type) => {
    const old = newQuestionario.visibilita[index];
    const updated = { ...old, [prop]: type === 'int' ? parseInt(event.target?.value, 10) : event.target?.value };
    const clone = [...newQuestionario.visibilita];
    clone[index] = updated;
    setNewQuestionario({
      ...newQuestionario,
      visibilita: clone,
    });
  };

  // Function collegata al servizio di get della Lista delle Identità
  async function getListaIdentita() {
    const response = await canaliService.getIdentitaByCanali();
    setListaIdentita(response.data);
  }

  // useEffect
  useEffect(() => {
    getTemplate();
    getListaIdentita();
  }, []);

  return (
    <>
      <Container className="p-0">
        <TitlePage
          title="Gestione Template"
          subtitle="In questa sezione è possibile gestire il template corrente."
          showIndietro
          functionIndietro={!showNewTemplateLayout ? () => history.push('/area-riservata/template') : showNewTemplateLayoutHandle}
          showSave={!showNewTemplateLayout}
          functionSave={editTemplate}
        />
        {!showNewTemplateLayout ? (
          <Container className="p-0 mt-3">
            <div className="separator border-1 border-bottom" />
            <Row>
              <Col>
                <p className="h4 m-0" style={{ lineHeight: 1.6 }}>
                  Informazioni principali
                </p>
              </Col>
            </Row>
            <Form>
              <Row>
                <Form.Group as={Col} lg controlId="titolo">
                  <Form.Label>Titolo *</Form.Label>
                  <Form.Control
                    placeholder="Inserisci il titolo del template"
                    onChange={onChangeTemplate}
                    value={newQuestionario.titolo}
                  />
                </Form.Group>
              </Row>
              <Row className="mb-4">
                <Form.Group as={Col} lg controlId="descrizione">
                  <Form.Label>Descrizione *</Form.Label>
                  <Form.Control
                    placeholder="Inserisci la descrizione del template"
                    onChange={onChangeTemplate}
                    value={newQuestionario.descrizione}
                  />
                </Form.Group>
              </Row>
            </Form>
            <div className="separator border-1 border-bottom" />
            <Row>
              <Col>
                <p className="h4" style={{ lineHeight: 1.6 }}>
                  Regole di visibilità
                </p>
                <p className="h6" style={{ lineHeight: 1.6 }}>
                  Si può scegliere se il template potrà essere compilato dagli utenti
                  in piattaforma o da utenti esterni.
                </p>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col>
                <Form>
                  <Form.Check className="d-flex align-items-center" type="checkbox">
                    <Form.Check.Input
                      type="checkbox"
                      id="isEsterno"
                      onChange={onChangeTemplate}
                      value={newQuestionario.isEsterno}
                      checked={newQuestionario.isEsterno}
                    />
                    <Form.Check.Label className="ms-2">
                      Il questionario è esterno?
                    </Form.Check.Label>
                  </Form.Check>
                </Form>
              </Col>
            </Row>
            {!newQuestionario.isEsterno && (
            <Row className="mb-4">
              <Col xs={12}>
                <p className="h6" style={{ lineHeight: 1.6 }}>
                  Essendo un questionario interno, si può procedere scegliendo
                  le identità o le coppie identità-utenti che possono compilare
                  il template. Nessuna valorizzazione permetterà a tutti gli
                  utenti dell&apos;organizzazione la compilazione del template.
                </p>
              </Col>
              <Col xs={12}>
                <Autorizzazioni
                  autorizzazioni={newQuestionario.visibilita}
                  listaIdentita={listaIdentita}
                  aggiungiRow={aggiungiRow}
                  onChangeAutorizzazioni={onChangeAutorizzazioni}
                  handleCloseModaleUtenti={handleCloseModaleUtenti}
                  setIndexAutorizzazioneScelta={setIndexAutorizzazioneScelta}
                  eliminaRow={eliminaRow}
                  setIdentitaScelta={setIdentitaScelta}
                />
              </Col>
            </Row>
            )}
            <div className="separator border-1 border-bottom" />
            <Row>
              <Col>
                <p className="h4" style={{ lineHeight: '1.6' }}>
                  Sezioni Template
                </p>
              </Col>
            </Row>
            {showNewFormSezione ? (
              <NewFormSection
                showNewFormSezioneHandle={showNewFormSezioneHandle}
                onChangeSezione={onChangeSezione}
                addOrEdit={addOrEdit}
                newSezione={newSezione}
                editedSezione={editedSezione}
                addSezione={addSezione}
                setFunzioneModaleConferma={setFunzioneModaleConferma}
                setTestoModaleConferma={setTestoModaleConferma}
                handleStateModaleConferma={handleStateModaleConferma}
                modificaSezione={modificaSezione}
              />
            ) : (
              <Row>
                <Col className="col-lg-auto mt-2 text-end">
                  <Button variant="primary" onClick={showNewFormSezioneHandle}>
                    <FontAwesomeIcon aria-hidden="true" icon={['fas', 'plus']} />
                    {' '}
                    Aggiungi Nuova Sezione
                  </Button>
                </Col>
              </Row>
            )}
            <CardGroupSezioni
              sezioni={newQuestionario.sezioni}
              setEditedSezione={setEditedSezione}
              functionEdit={handleEditSezione}
              functionClone={cloneSezione}
              functionDelete={deleteSezione}
              handleStateModaleConferma={handleStateModaleConferma}
              functionShow={showNewTemplateLayoutHandle}
              setFunzioneModaleConferma={setFunzioneModaleConferma}
              setTestoModaleConferma={setTestoModaleConferma}
            />
          </Container>
        ) : (
          <NewTemplateSection
            template={editedSezione.template || {}}
            functionCancel={showNewTemplateLayoutHandle}
            functionAdd={modificaTemplateSezione}
            editedSezione={editedSezione}
          />
        )}
      </Container>
      {openModaleConferma && (
      <ModaleConferma
        labelBottone="Conferma"
        coloreAzione="primary"
        title="Sei sicuro di procedere?"
        body={`L'operazione ${testoModaleConferma} la sezione selezionata`}
        show={openModaleConferma}
        handleClose={handleStateModaleConferma}
        handleOk={() => {
          funzioneModaleConferma(editedSezione.titolo);
          handleStateModaleConferma();
        }}
      />
      )}
      {showModaleUtenti && (
      <ModaleRicercaUtenti
        show={showModaleUtenti}
        handleClose={handleCloseModaleUtenti}
        idIdentita={identitaScelta}
        listaIdentita={listaIdentita}
        onChangeAutorizzazioni={onChangeAutorizzazioni}
        indexAutorizzazioneScelta={indexAutorizzazioneScelta}
      />
      )}
    </>
  );
}

export default GestioneTemplate;
