import { Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';

function FormCicloAssessment({
  stateCicloAssessment,
  optionsStati,
  onChange,
  disabled = false,
}) {
  return (
    <Form.Group as={Col} lg controlId="desc_titolo">
      <Form.Label>
        Titolo
      </Form.Label>
      <Form.Control
        name="desc_titolo"
        value={stateCicloAssessment.desc_titolo}
        placeholder="Inserisci titolo Ciclo di Assessment"
        onChange={onChange}
        maxLength={100}
        disabled={disabled}
      />
      <Form.Label>
        Stato
      </Form.Label>
      <Select
        id="stato"
        name="stato"
        options={optionsStati}
        isDisabled
        getOptionLabel={(option) => option.desc_stato}
        getOptionValue={(option) => option.codi_k_stato}
        value={optionsStati?.filter(
          (el) => el.codi_k_stato === stateCicloAssessment.fk_codi_stato,
        )?.[0]}
      />
      <Form.Label>
        Data Avvio
      </Form.Label>
      <Form.Control
        type="date"
        name="data_avvio"
        value={stateCicloAssessment.data_avvio}
        onChange={onChange}
        disabled={disabled}
      />
      <Form.Label>
        Data Chiusura
      </Form.Label>
      <Form.Control
        type="date"
        name="data_chiusura"
        value={stateCicloAssessment.data_chiusura}
        onChange={onChange}
        disabled={disabled}
      />
    </Form.Group>
  );
}

export default FormCicloAssessment;
