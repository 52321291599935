import Select from 'react-select';
import { formatoData } from '../../utils/formatData';

export const fieldsProfonditaStoricaIdentita = (
  onChange,
  idAmministrazione,
  idIdentita,
  amministrazione,
  listaResponsabili,
  isModify = false,
) => (
  [
    {
      dataField: 'sequ_k_identita',
      text: 'ID',
      sort: true,
      classes: 'column-data',
      headerStyle: () => ({ width: '10%' }),
    },
    {
      dataField: 'desc_nome_identita',
      text: 'Nome',
      sort: true,
      classes: 'column-data',
      headerStyle: () => ({ width: '18%' }),
    },
    {
      dataField: 'nome_utente_responsabile',
      text: 'Responsabile',
      classes: 'column-data',
      headerStyle: () => ({ width: '18%' }),
      formatter: (cell, row) => {
        if (row.nome_utente_responsabile || row.cognome_utente_responsabile) {
          return `${row.nome_utente_responsabile} ${row.cognome_utente_responsabile}`;
        }
        return '-';
      },
      hidden: !idAmministrazione,
    },
    {
      dataField: 'nome_amministrazione',
      text: 'Amministrazione',
      classes: 'column-data',
      headerStyle: () => ({ width: '18%' }),
      formatter: (e, row) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {(row.desc_organizzazione === 'ente' && isModify) && (
            <p>{amministrazione}</p>
          )}
          {(row.desc_organizzazione === 'ente' && !isModify) && (
            <p>{row.nome_amministrazione}</p>
          )}
          {(row.desc_organizzazione === 'formez') && (
          <p>Formez</p>
          )}
        </div>
      ),
    },
    {
      dataField: 'data_inizio_validita',
      text: 'Data inizio validità',
      sort: true,
      classes: 'column-data',
      headerStyle: () => ({ width: '18%' }),
      formatter: (e) => formatoData(e),
    },
    {
      dataField: 'data_fine_validita',
      text: 'Data fine validità',
      sort: true,
      classes: 'column-data',
      headerStyle: () => ({ width: '18%' }),
      formatter: (e) => formatoData(e),
    },
  ]
);

export const fieldsNoProfonditaStoricaIdentita = (
  onChange,
  idAmministrazione,
  idIdentita,
  amministrazione,
  listaResponsabili,
  isModify = false,
) => (
  [
    {
      dataField: 'sequ_k_identita',
      text: 'ID',
      sort: true,
      classes: 'column-data',
      headerStyle: () => ({ width: '10%' }),
    },
    {
      dataField: 'desc_nome_identita',
      text: 'Nome',
      sort: true,
      classes: 'column-data',
      headerStyle: () => ({ width: '22.5%' }),
    },
    {
      dataField: 'nome_utente_responsabile',
      text: 'Responsabile',
      classes: 'column-data',
      headerStyle: () => ({ width: '22.5%' }),
      formatter: (cell, row) => {
        if (isModify && row.sequ_k_identita === 6) {
          return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Select
                className="w-100"
                id="responsabile"
                name="responsabile"
                options={listaResponsabili}
                placeholder={row.nome_utente_responsabile
                  ? row.nome_utente_responsabile.concat(' ').concat(row.cognome_utente_responsabile) : 'Scegli il Responsabile'}
                getOptionLabel={(option) => (
                  `${option.desc_nome} ${option.desc_cognome}`
                )}
                getOptionValue={(option) => option.sequ_k_utente_trusted}
                value={listaResponsabili?.filter(
                  (el) => el.sequ_k_utente_trusted === row.fk_sequ_utente_trusted_responsabile,
                )?.[0]}
                onChange={(selectedOption, config) => { onChange(selectedOption, config, row); }}
                isDisabled={idIdentita === 3}
              />
            </div>
          );
        }
        if (row.nome_utente_responsabile || row.cognome_utente_responsabile) {
          return `${row.nome_utente_responsabile} ${row.cognome_utente_responsabile}`;
        }
        return '-';
      },
      hidden: !idAmministrazione,
    },
    {
      dataField: 'nome_amministrazione',
      text: 'Amministrazione',
      classes: 'column-data',
      headerStyle: () => ({ width: '22.5%' }),
      formatter: (e, row) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {(row.desc_organizzazione === 'ente' && isModify) && (
            <p>{amministrazione}</p>
          )}
          {(row.desc_organizzazione === 'ente' && !isModify) && (
            <p>{row.nome_amministrazione}</p>
          )}
          {(row.desc_organizzazione === 'formez') && (
            <p>Formez</p>
          )}
        </div>
      ),
    },
    {
      dataField: 'data_inizio_validita',
      text: 'Data inizio validità',
      sort: true,
      classes: 'column-data',
      headerStyle: () => ({ width: '22.5%' }),
      formatter: (e) => formatoData(e),
    },
  ]
);
