/* eslint-disable prefer-destructuring */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import richiestIdentitaService from '../../services/richiestaIdentitaService';

function AttivaRichiestaAccreditamentoByHash() {
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState('');
  const { hash } = useParams();

  const attivaRichiesta = async () => {
    try {
      const response = await richiestIdentitaService.attivaRichiesta(hash);
      setMessage(response.message);
    } catch (err) {
      setMessage(err?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    attivaRichiesta();
  }, []);
  return (
    isLoading ? (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <div className="inline-block align-middle">
          <h1 className="font-weight-normal lead" id="desc">Caricamento...</h1>
        </div>
      </div>
    )
      : (
        <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
          <div className="inline-block align-middle">
            <h1 id="desc">{message}</h1>
          </div>
        </div>
      )
  );
}

export default AttivaRichiestaAccreditamentoByHash;
