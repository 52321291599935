// eslint-disable-next-line import/no-cycle
import api from '../utils/api';

const accreditamentoUrl = process.env.REACT_APP_ACCREDITAMENTO_URL;

async function getPermessiByIdentita(idIdentita, idAmministrazione) {
  return api.get(`${accreditamentoUrl}/gestione-permesso/identita/${idIdentita}/?idAmministrazione=${idAmministrazione}`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

const permessoService = {
  getPermessiByIdentita,
};

export default permessoService;
