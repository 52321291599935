import { Table } from 'react-bootstrap';
import HeaderModello from './HeaderModello';
import BodyModello from './BodyModello';

function TablePrioritizzazione({
  criteriList,
  data,
  onChange,
  isDisabled,
}) {
  return (
    <Table bordered>
      <HeaderModello
        criteriList={criteriList}
      />
      <BodyModello
        data={data}
        onChange={onChange}
        isDisabled={isDisabled}
      />
    </Table>
  );
}

export default TablePrioritizzazione;
