import { formatoData } from '../../../utils/formatData';

const fields = [
  {
    dataField: 'desc_nome_identita',
    text: 'Nome',
    // sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '20%' }),
  },
  {
    dataField: 'nome_amministrazione',
    text: 'Amministrazione',
    // sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '30%' }),
    formatter: (e) => e || '-',
  },
  {
    dataField: 'responsabile',
    text: 'Responsabile',
    // sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '20%' }),
    formatter: (e, row) => (row.nome_utente_responsabile ? `${row.nome_utente_responsabile} ${row.cognome_utente_responsabile}` : '-'),
  },
  {
    dataField: 'data_inizio_validita',
    text: 'Data inizio validità',
    // sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '15%' }),
    formatter: (e) => formatoData(e),
  },
  {
    dataField: 'data_fine_validita',
    text: 'Data fine validità',
    // sort: true,
    classes: 'column-data',
    headerStyle: () => ({ width: '15%' }),
    formatter: (e) => formatoData(e),
  },
];

export default fields;
