import api from '../utils/api';

const accreditamentoUrl = process.env.REACT_APP_ACCREDITAMENTO_URL;

async function getAllComparti() {
  return api.get(`${accreditamentoUrl}/gestione-comparti`)
    .then((res) => res && res.data)
    .catch((err) => Promise.reject(err.response));
}

const compartiService = {
  getAllComparti,
};

export default compartiService;
