import React, { useContext, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { Context } from '../../../utils/Context';
import AssessmentService from '../../../services/assessmentService';
import checkStorage from '../../../utils/checkSessionStorage';
import { setItem } from '../../../utils/storage';
import TitlePage from '../../../components/GestisciUtenti/TitoloPagina';
import Table from '../../../components/Shared/Table';
import EmptyState from '../../../components/EmptyState';
import fieldsAutoAssessment from './fieldsTable';
import history from '../../../utils/history';
import FiltriAutoAssessment from './filtriAutoAssessment';
import ModaleConferma from '../../../components/modali/ModaleConferma';
import ModaleCentroVerticale from '../../../components/modali/ModaleCentroVerticale';
import BodyModaleColloquio from '../etero-assessment/dettaglioDipendenti/colloquio';

function AutoAssessment() {
  const [context, setContext] = useContext(Context);
  const [cicliAssessmentList, setCicliAssessmentList] = useState([]);
  const [statoCicloAssessmentList, setStatoCicloAssessmentList] = useState([]);
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [modalInvio, setModalInvio] = useState(false);
  const [modalRiepilogo, setModalRiepilogo] = useState(false);
  const [stateAssessmentSelected, setStateAssessmentSelected] = useState({});

  const [filtri, setFiltri] = useState({});
  const [pagination, setPagination] = useState({
    page: 1,
    sizePerPage: 10,
    totalSize: 0,
  });

  // function collegata al servizio per ottenere la lista dei cicli di assessment
  async function getCicliAssessmentUser(offset, limit, page, filters) {
    try {
      const filterSession = filters || filtri;
      const response = await AssessmentService.getCicliAssessmentUser(filterSession, offset, limit);
      setCicliAssessmentList(
        response.rows.map((row) => ({
          ...row,
          sequ_k_assesment: row.d_assesments.length > 0
            ? row.d_assesments[0].sequ_k_assesment
            : null,
          d_colloquio_assesments: row.d_assesments.length > 0
            ? row.d_assesments[0].d_colloquio_assesments
            : [],
          d_assesments: row.d_assesments.length > 0
            ? row.d_assesments[0].fk_codi_stato_d_stato_assesment
            : {
              codi_k_stato: 0,
              desc_stato: 'Da iniziare',
            },
        })),
      );
      setPagination((prevPag) => ({
        ...prevPag,
        page,
        sizePerPage: limit,
        totalSize: response.totalRowCount,
      }));
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // function collegata al servizio per ottenere la lista degli stati per i cicli di assessment
  async function getStatoCicloAssessment() {
    try {
      const response = await AssessmentService.getStatoCicloAssessment();
      setStatoCicloAssessmentList(response.rows);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // function per verificare se l'utente loggato è associato ad un profilo di ruolo
  async function getUserProfiloRuolo(offset, limit, page, filters) {
    try {
      const response = await AssessmentService.getUserProfiloRuolo();
      if (response.data) {
        getCicliAssessmentUser(offset, limit, page, filters);
        getStatoCicloAssessment();
      } else {
        setErrorMessage(response.message);
      }
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // function attivata al cambio pagina della tabella
  const handleTableChange = (type, { page, sizePerPage }) => {
    let currentIndex;
    if (page !== 0) {
      currentIndex = (page - 1) * sizePerPage;
    }
    getCicliAssessmentUser(currentIndex, sizePerPage, page, {});
  };

  // function per verificare se l'utente loggato è associato ad un profilo di ruolo
  async function richiediColloquio(id) {
    try {
      const response = await AssessmentService.richiediColloquio(id);
      setContext(() => ({
        ...context, openConferma: true, testo: response.message,
      }));
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // useEffect
  useEffect(() => {
    let offset = 0;
    const storage = checkStorage(filtri, setFiltri, setPagination);
    if (storage.page > 1) {
      handleTableChange(null, { page: storage.page, sizePerPage: pagination.sizePerPage });
      offset = (storage.page - 1) * pagination.sizePerPage;
    }
    getUserProfiloRuolo(offset, 10, storage.page, storage.filtri);
  }, []);

  // useEffect per settare nello storage la pagina corrente
  useEffect(() => {
    setItem('page', pagination.page);
  }, [pagination.page]);

  // useEffect per caricare i dati presenti nella tabella.
  useEffect(() => {
    getUserProfiloRuolo(0, 10, 1, {});
  }, []);

  // function per modificare lo stato che gestisce i filtri
  function onChangeFiltri(e, config) {
    if (config) {
      setFiltri({
        ...filtri, [config.name]: e?.codi_k_stato,
      });
    } else {
      const { value, name } = e.target;
      setFiltri({
        ...filtri, [name]: value,
      });
    }
  }

  // function per effettuare il refresh della pagina
  function refresh() {
    getCicliAssessmentUser(0, 10, 1, {});
    getUserProfiloRuolo();
  }

  // Funzione attivata al click dell'icona di compilazione
  const onCompile = (idCicloAssessment) => {
    history.push(`/area-riservata/auto-assessment/${idCicloAssessment}`);
  };

  // Funzione per la gestione della visualizzazione modale di invio richiesta colloquio
  const onInterview = () => {
    setModalInvio(true);
  };

  // Funzione per la gestione della visualizzazione modale di riepilogo colloquio
  const onRiepilogo = () => {
    setModalRiepilogo(true);
  };

  return (
    <Container className="p-0">
      <TitlePage
        title="Auto Valutazione"
        showRefresh
        functionRefresh={refresh}
      />
      <FiltriAutoAssessment
        filtri={filtri}
        setFiltri={setFiltri}
        onChangeFiltri={onChangeFiltri}
        getCicliAssessmentUser={getCicliAssessmentUser}
        optionsStati={statoCicloAssessmentList}
      />
      {errorMessage ? (
        <EmptyState
          marginTop="3rem"
          title="Attenzione"
          subtitle={errorMessage}
        />
      ) : (
        cicliAssessmentList.length > 0 ? (
          <Table
            data={cicliAssessmentList}
            fields={fieldsAutoAssessment(
              onCompile,
              onInterview,
              onRiepilogo,
              setStateAssessmentSelected,
            )}
            pagination={pagination}
            onTableChange={handleTableChange}
            keyField="cicli_assessment_list_auto"
          />
        ) : (
          <EmptyState marginTop="3rem" />
        )
      )}
      <ModaleConferma
        title="Invio richiesta colloquio"
        body={`Stai per inviare una richiesta di colloquio al tuo responsabile.
        Ti preghiamo di confermare se desideri procedere con l'invio.`}
        labelBottone="Invia richiesta"
        coloreAzione="primary"
        show={modalInvio}
        handleClose={() => setModalInvio(false)}
        handleOk={() => {
          richiediColloquio(stateAssessmentSelected?.sequ_k_assesment);
          setModalInvio(false);
          refresh();
        }}
      />
      <ModaleCentroVerticale
        modalTitle="Riepilogo Colloquio"
        modalBody={(
          <BodyModaleColloquio
            stateColloquio={stateAssessmentSelected.d_colloquio_assesments?.[0]}
            isDisabled
            isDipendente
          />
        )}
        show={modalRiepilogo}
        onClose={() => setModalRiepilogo(false)}
        disabledConfirm
      />
    </Container>
  );
}

export default AutoAssessment;
