import React, { useContext, useEffect, useState } from 'react';
import { Col, Container, Form } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import TitlePage from '../../../../components/GestisciUtenti/TitoloPagina';
import Table from '../../../../components/Shared/Table';
import { Context } from '../../../../utils/Context';
import AssessmentService from '../../../../services/assessmentService';
import SistemaProfessionaleService from '../../../../services/sistemaProfessionaleService';
import fieldsCompetenzeAutoAssessment from './fieldsTable';
import history from '../../../../utils/history';
import SpinnerBody from '../../../../components/Shared/SpinnerBody';

function AutoAssessmentDettaglio() {
  const [context, setContext] = useContext(Context);
  const { idCicloAssessment } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [stateAutoAssessment, setStateAutoAssessment] = useState({});
  const [gradingList, setGradingList] = useState([]);

  // function collegata al servizio di get del ciclo di assessment in cui fare l'autovaluzione
  async function getCicloAssessmentUserById(id) {
    try {
      const response = await AssessmentService.getCicloAssessmentUserById(id);
      setStateAutoAssessment({
        ...response,
        utente_dipendente: {
          ...response.utente_dipendente,
          ...response.utente_dipendente?.dettaglio[0],
        },
        utente_responsabile: {
          ...response.utente_responsabile,
          ...response.utente_responsabile?.dettaglio[0],
        },
        rel_competenza_assessment: response.d_rel_competenza_assesments?.map(
          (competenza) => ({
            fk_sequ_competenza: competenza.fk_sequ_competenza,
            sequ_k_competenza_assesment: competenza.sequ_k_competenza_assesment,
            codi_grading_assegnato: competenza.codi_grading_assegnato,
            codi_grading_autovalutazione: competenza.codi_grading_autovalutazione,
            comp_desc_codice: competenza.fk_sequ_competenza_d_competenza.desc_codice,
            comp_desc_descrizione: competenza.fk_sequ_competenza_d_competenza.desc_descrizione,
            comp_tipo_competenza: competenza.fk_sequ_competenza_d_competenza.codi_tipo_competenza,
          }),
        ),
      });
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    } finally {
      setIsLoading(false);
    }
  }

  // function collegata al servizio per ottenere la lista dei grading
  async function getGrading(offset, limit, filters) {
    try {
      const response = await SistemaProfessionaleService.getGrading(filters, offset, limit);
      setGradingList(response.rows);
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // useEffect per caricare i dati presenti nella tabella.
  useEffect(() => {
    getGrading(0, 1000, {}); // IMPOSTATO LIMIT 1000
    getCicloAssessmentUserById(idCicloAssessment);
  }, []);

  // function collegata al servizio di salvataggio dell'autoassessment
  async function salvaCicloAssessmentUser(id, state) {
    const { rel_competenza_assessment } = state;
    AssessmentService.salvaCicloAssessmentUser(
      id,
      { rel_competenza_assessment },
    )
      .then(() => {
        setContext(() => ({
          ...context, openConferma: true, testo: 'Salvataggio Autovalutazione eseguito correttamente',
        }));
        history.goBack();
      })
      .catch((err) => {
        setContext(() => ({
          ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
        }));
      });
  }

  // function collegata al servizio di consolida Auto Valutazione
  async function consolidaAutovalutazione(id, state) {
    const { rel_competenza_assessment } = state;
    AssessmentService.salvaCicloAssessmentUser(
      id,
      { rel_competenza_assessment },
    )
      .then(() => {
        AssessmentService.consolidaAutovalutazione(id)
          .then(() => {
            setContext(() => ({
              ...context, openConferma: true, testo: 'Autovalutazione eseguita correttamente',
            }));
            history.goBack();
          })
          .catch((err) => {
            setContext(() => ({
              ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
            }));
          });
      })
      .catch((err) => {
        setContext(() => ({
          ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
        }));
      });
  }

  // function per modificare lo stato del ciclo di assessment con il grading di autovalutazione
  const onChange = (e, row) => {
    setStateAutoAssessment((prevState) => ({
      ...prevState,
      rel_competenza_assessment: prevState.rel_competenza_assessment.map(
        (el) => {
          if (el.fk_sequ_competenza === row.fk_sequ_competenza) {
            return ({
              ...el,
              codi_grading_autovalutazione: e.codi_valore,
            });
          } return el;
        },
      ),
    }));
  };

  // Function per controllare la visualizzazione del bottone Consolida Autovalutazione
  const isShowConsolida = (statoAutoAssessment) => {
    const allGradingSet = !stateAutoAssessment?.rel_competenza_assessment?.some(
      (el) => !el.codi_grading_autovalutazione,
    );
    if (allGradingSet && statoAutoAssessment === 1) return true;
    return false;
  };
  return (
    isLoading
      ? (
        <SpinnerBody />
      ) : (
        <Container className="p-0">
          <TitlePage
            title="Auto Valutazione delle competenze"
            showIndietro
            functionIndietro={() => history.goBack()}
            showSave={stateAutoAssessment.fk_codi_stato === 1}
            functionSave={() => salvaCicloAssessmentUser(idCicloAssessment, stateAutoAssessment)}
            showConsolida={isShowConsolida(stateAutoAssessment.fk_codi_stato)}
            functionConsolida={() => consolidaAutovalutazione(
              idCicloAssessment,
              stateAutoAssessment,
            )}
            titleConsolida="CONSOLIDA AUTOVALUTAZIONE"
          />
          <Form.Group as={Col} lg controlId="auto_assessment">
            <Form.Label>
              Ciclo di Assessment
            </Form.Label>
            <Form.Control
              name="fk_sequ_ciclo_assesment_d_ciclo_assesment.desc_titolo"
              value={stateAutoAssessment?.fk_sequ_ciclo_assesment_d_ciclo_assesment?.desc_titolo}
              disabled
            />
            <Form.Label>
              Stato
            </Form.Label>
            <Form.Control
              name="fk_codi_stato_d_stato_assesment.desc_stato"
              value={stateAutoAssessment?.fk_codi_stato_d_stato_assesment?.desc_stato}
              disabled
            />
            <Form.Label>
              Dipendente
            </Form.Label>
            <Form.Control
              name="utente_dipendente"
              value={`${stateAutoAssessment?.utente_dipendente?.desc_nome} ${stateAutoAssessment?.utente_dipendente?.desc_cognome}`}
              disabled
            />
            <Form.Label>
              Responsabile
            </Form.Label>
            <Form.Control
              name="utente_responsabile"
              value={`${stateAutoAssessment?.utente_responsabile?.desc_nome} ${stateAutoAssessment?.utente_responsabile?.desc_cognome}`}
              disabled
            />
            <Form.Label>
              Profilo di ruolo
            </Form.Label>
            <Form.Control
              name="fk_sequ_profilo_ruolo_d_profilo_ruolo.desc_titolo"
              value={stateAutoAssessment?.fk_sequ_profilo_ruolo_d_profilo_ruolo?.desc_titolo}
              disabled
            />
            {(gradingList.length > 0
            && stateAutoAssessment?.rel_competenza_assessment?.length > 0) && (
            <>
              <Form.Label>
                Auto Valutazione
              </Form.Label>
              <Table
                data={stateAutoAssessment.rel_competenza_assessment}
                fields={fieldsCompetenzeAutoAssessment(
                  stateAutoAssessment.rel_competenza_assessment,
                  gradingList,
                  onChange,
                  stateAutoAssessment.fk_codi_stato,
                )}
                keyField="competenze_list_auto_assessment"
              />
            </>
            )}
          </Form.Group>
        </Container>
      )
  );
}

export default AutoAssessmentDettaglio;
