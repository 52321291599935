import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import themeColors from '../../../../utils/contants/theme-colors';

const fieldsDipendentiEteroAssessment = (
  onView,
  onCompile,
  onReminder,
  onInterview,
  onViewInterview,
  isWithAssessment,
  setDipendenteSelected,
  setIdAssessment,
) => {
  if (isWithAssessment) {
    return (
      [
        {
          dataField: 'desc_dipendente',
          text: 'Dipendente',
          // sort: true,
          classes: 'column-data',
          headerStyle: () => ({ width: '30%' }),
        },
        {
          dataField: 'desc_stato',
          text: 'Stato',
          // sort: true,
          classes: 'column-data',
          headerStyle: () => ({ width: '20%' }),
        },
        {
          dataField: 'desc_titolo',
          text: 'Profilo di Ruolo',
          // sort: true,
          classes: 'column-data',
          headerStyle: () => ({ width: '30%' }),
        },
        {
          dataField: 'df1',
          text: 'Azioni',
          classes: 'column-data',
          headerStyle: () => ({ width: '20%' }),
          formatter: (e, row) => (
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
              {(row.fk_codi_stato === 1 || row.fk_codi_stato === 3) && (
                <FontAwesomeIcon
                  aria-hidden="true"
                  icon={['fas', 'search']}
                  size="lg"
                  title="Visualizza"
                  id={`Visualizza_${row.fk_sequ_utente_trusted}`}
                  style={{ color: themeColors.primary }}
                  onClick={() => onView(row.fk_sequ_utente_trusted)}
                />
              )}
              {row.fk_codi_stato === 2 && (
                <FontAwesomeIcon
                  aria-hidden="true"
                  icon={['fas', 'file-pen']}
                  size="lg"
                  title="Compila Etero Valutazione"
                  id={`Compila_etero_${row.fk_sequ_utente_trusted}`}
                  style={{ color: themeColors.primary }}
                  onClick={() => onCompile(row.fk_sequ_utente_trusted)}
                />
              )}
              {row.fk_codi_stato === 1 && (
                <FontAwesomeIcon
                  aria-hidden="true"
                  icon={['fas', 'bell']}
                  size="lg"
                  title="Sollecito Compilazione Auto Valutazione"
                  id={`Compila_auto_${row.fk_sequ_utente_trusted}`}
                  style={{ color: themeColors.danger }}
                  onClick={() => {
                    setDipendenteSelected(row);
                    onReminder(row.fk_sequ_utente_trusted);
                  }}
                />
              )}
              {(row.richiesta_colloquio && row.data_colloquio === null) && (
                <FontAwesomeIcon
                  aria-hidden="true"
                  icon={['fas', 'calendar']}
                  size="lg"
                  title="Accetta colloquio"
                  id={`Accetta_colloquio_${row.fk_sequ_utente_trusted}`}
                  style={{ color: themeColors.warning }}
                  onClick={() => {
                    setDipendenteSelected(row);
                    setIdAssessment(row.sequ_k_assesment);
                    onInterview();
                  }}
                />
              )}
              {(row.richiesta_colloquio && row.data_colloquio !== null) && (
                <FontAwesomeIcon
                  aria-hidden="true"
                  icon={['fas', 'calendar']}
                  size="lg"
                  title="Visualizza data colloquio"
                  id={`Visualizza_data_colloquio_${row.fk_sequ_utente_trusted}`}
                  style={{ color: themeColors.success }}
                  onClick={() => {
                    setDipendenteSelected(row);
                    onViewInterview();
                  }}
                />
              )}
            </div>
          ),
        },
      ]
    );
  } return (
    [
      {
        dataField: 'desc_dipendente',
        text: 'Dipendente',
        // sort: true,
        classes: 'column-data',
        headerStyle: () => ({ width: '30%' }),
      },
      {
        dataField: 'desc_stato',
        text: 'Stato',
        // sort: true,
        classes: 'column-data',
        headerStyle: () => ({ width: '20%' }),
      },
      {
        dataField: 'desc_titolo',
        text: 'Profilo di Ruolo',
        // sort: true,
        classes: 'column-data',
        headerStyle: () => ({ width: '50%' }),
      },
      {
        dataField: 'df1',
        text: 'Azioni',
        classes: 'column-data',
        headerStyle: () => ({ width: '20%' }),
        formatter: (e, row) => (
          <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            <FontAwesomeIcon
              aria-hidden="true"
              icon={['fas', 'bell']}
              size="lg"
              title="Sollecito Compilazione Auto Valutazione"
              id={`Compila_auto_${row.fk_sequ_utente_trusted}`}
              style={{ color: themeColors.danger }}
              onClick={() => {
                setDipendenteSelected(row);
                onReminder(row.fk_sequ_utente_trusted);
              }}
            />
          </div>
        ),
      },
    ]
  );
};

export default fieldsDipendentiEteroAssessment;
